import session1Img from '../assets/session1-title.png';
import session2Img from '../assets/session2-title.png';
import session3Img from '../assets/session3-title.png';
import session4Img from '../assets/session4-title.png';
import session5Img from '../assets/session5-title.png';
import session6Img from '../assets/session6-title.png';
import session7Img from '../assets/session7-title.png';
import session8Img from '../assets/session8-title.png';
import session9Img from '../assets/session9-title.png';
import session10Img from '../assets/session10-title.png';
import session11Img from '../assets/session11-title.png';
import session12Img from '../assets/session12-title.png';
import session13Img from '../assets/session13-title.png';
import session14Img from '../assets/session14-title.png';
import session15Img from '../assets/session15-title.png';
import session16Img from '../assets/session16-title.png';
import session17Img from '../assets/session17-title.png';
import session18Img from '../assets/session18-title.png';
import session19Img from '../assets/session19-title.png';

import session20Img from '../assets/session20-title.png';
import session21Img from '../assets/session21-title.png';
import session22Img from '../assets/session22-title.png';
import session23Img from '../assets/session23-title.png';
import session24Img from '../assets/session24-title.png';
import session25Img from '../assets/session25-title.png';
import session26Img from '../assets/session26-title.png';
import session27Img from '../assets/session27-title.png';
import session28Img from '../assets/session28-title.png';
import session29Img from '../assets/session29-title.png';
import session30Img from '../assets/session30-title.png';
import session31Img from '../assets/session31-title.png';
import session32Img from '../assets/session32-title.png';
import session33Img from '../assets/session33-title.png';
import session34Img from '../assets/session34-title.png';
import session35Img from '../assets/session35-title.png';
import session36Img from '../assets/session36-title.png';
import session37Img from '../assets/session37-title.png';
import session38Img from '../assets/session38-title.png';
import session39Img from '../assets/session39-title.png';
import session40Img from '../assets/session40-title.png';
import session41Img from '../assets/session41-title.png';
import session43Img from '../assets/session43-title.png';
import session44Img from '../assets/session44-title.png';
import session45Img from '../assets/session45-title.png';
import session46Img from '../assets/session46-title.png';
import session47Img from '../assets/session47-title.png';
import session48Img from '../assets/session48-title.png';
import session49Img from '../assets/session49-title.png';
import session50Img from '../assets/session50-title.png';
import session51Img from '../assets/session51-title.png';
import session52Img from '../assets/session52-title.png';
import session53Img from '../assets/session53-title.png';
import session54Img from '../assets/session54-title.png';
import session55Img from '../assets/session55-title.png';
import session56Img from '../assets/session56-title.png';
import session57Img from '../assets/session57-title.png';
import session58Img from '../assets/session58-title.png';
import session59Img from '../assets/session59-title.png';
import session60Img from '../assets/session60-title.png';
import session61Img from '../assets/session61-title.png';
import session62Img from '../assets/session62-title.png';
import session63Img from '../assets/session63-title.png';
import session64Img from '../assets/session64-title.png';
import session65Img from '../assets/session65-title.png';
import session66Img from '../assets/session66-title.png';
import session67Img from '../assets/session67-title.png';
import session68Img from '../assets/session68-title.png';
import session69Img from '../assets/session69-title.png';
import session70Img from '../assets/session70-title.png';
import session71Img from '../assets/session71-title.png';
import session72Img from '../assets/session72-title.png';
import session73Img from '../assets/session73-title.png';
import session74Img from '../assets/session74-title.png';
import session75Img from '../assets/session75-title.png';
import session76Img from '../assets/session76-title.png';
import session77Img from '../assets/session77-title.png';
import session78Img from '../assets/session78-title.png';
import session79Img from '../assets/session79-title.png';
import session80Img from '../assets/session80-title.png';
import session81Img from '../assets/session81-title.png';
import session82Img from '../assets/session82-title.png';

import session83Img from '../assets/session83-title.png';
import session84Img from '../assets/session84-title.png';
import session85Img from '../assets/session85-title.png';
import session86Img from '../assets/session86-title.png';
import session87Img from '../assets/session87-title.png';
import session88Img from '../assets/session88-title.png';
import session89Img from '../assets/session89-title.png';
import session90Img from '../assets/session90-title.png';
import session91Img from '../assets/session91-title.png';
import session92Img from '../assets/session92-title.png';
import session93Img from '../assets/session93-title.png';

import { session18 as phoneme18 } from '../../PhonemeAwareness/data/no-data';
import { session19 as phoneme19 } from '../../PhonemeAwareness/data/no-data';
import { session20 as phoneme20 } from '../../PhonemeAwareness/data/no-data';
import { session21 as phoneme21 } from '../../PhonemeAwareness/data/no-data';
import { session22 as phoneme22 } from '../../PhonemeAwareness/data/no-data';
import { session23 as phoneme23 } from '../../PhonemeAwareness/data/no-data';
import { session24 as phoneme24 } from '../../PhonemeAwareness/data/no-data';
import { session25 as phoneme25 } from '../../PhonemeAwareness/data/no-data';
import { session26 as phoneme26 } from '../../PhonemeAwareness/data/no-data';
import { session27 as phoneme27 } from '../../PhonemeAwareness/data/no-data';
import { session28 as phoneme28 } from '../../PhonemeAwareness/data/no-data';
import { session29 as phoneme29 } from '../../PhonemeAwareness/data/no-data';
import { session30 as phoneme30 } from '../../PhonemeAwareness/data/no-data';
import { session31 as phoneme31 } from '../../PhonemeAwareness/data/no-data';
import { session32 as phoneme32 } from '../../PhonemeAwareness/data/no-data';
import { session33 as phoneme33 } from '../../PhonemeAwareness/data/no-data';
import { session34 as phoneme34 } from '../../PhonemeAwareness/data/no-data';
import { session35 as phoneme35 } from '../../PhonemeAwareness/data/no-data';
import { session36 as phoneme36 } from '../../PhonemeAwareness/data/no-data';
import { session37 as phoneme37 } from '../../PhonemeAwareness/data/no-data';
import { session38 as phoneme38 } from '../../PhonemeAwareness/data/no-data';
import { session39 as phoneme39 } from '../../PhonemeAwareness/data/no-data';
import { session40 as phoneme40 } from '../../PhonemeAwareness/data/no-data';
import { session41 as phoneme41 } from '../../PhonemeAwareness/data/no-data';
import { session42 as phoneme42 } from '../../PhonemeAwareness/data/no-data';
import { session43 as phoneme43 } from '../../PhonemeAwareness/data/no-data';
import { session44 as phoneme44 } from '../../PhonemeAwareness/data/no-data';
import { session45 as phoneme45 } from '../../PhonemeAwareness/data/no-data';
import { session46 as phoneme46 } from '../../PhonemeAwareness/data/no-data';
import { session47 as phoneme47 } from '../../PhonemeAwareness/data/no-data';
import { session48 as phoneme48 } from '../../PhonemeAwareness/data/no-data';
import { session49 as phoneme49 } from '../../PhonemeAwareness/data/no-data';
import { session50 as phoneme50 } from '../../PhonemeAwareness/data/no-data';
import { session51 as phoneme51 } from '../../PhonemeAwareness/data/no-data';
import { session52 as phoneme52 } from '../../PhonemeAwareness/data/no-data';
import { session53 as phoneme53 } from '../../PhonemeAwareness/data/no-data';
import { session54 as phoneme54 } from '../../PhonemeAwareness/data/no-data';
import { session55 as phoneme55 } from '../../PhonemeAwareness/data/no-data';
import { session56 as phoneme56 } from '../../PhonemeAwareness/data/no-data';
import { session57 as phoneme57 } from '../../PhonemeAwareness/data/no-data';
import { session58 as phoneme58 } from '../../PhonemeAwareness/data/no-data';
import { session59 as phoneme59 } from '../../PhonemeAwareness/data/no-data';
import { session60 as phoneme60 } from '../../PhonemeAwareness/data/no-data';
import { session61 as phoneme61 } from '../../PhonemeAwareness/data/no-data';
import { session62 as phoneme62 } from '../../PhonemeAwareness/data/no-data';
import { session63 as phoneme63 } from '../../PhonemeAwareness/data/no-data';
import { session64 as phoneme64 } from '../../PhonemeAwareness/data/no-data';
import { session65 as phoneme65 } from '../../PhonemeAwareness/data/no-data';
import { session66 as phoneme66 } from '../../PhonemeAwareness/data/no-data';
import { session67 as phoneme67 } from '../../PhonemeAwareness/data/no-data';
import { session68 as phoneme68 } from '../../PhonemeAwareness/data/no-data';
import { session69 as phoneme69 } from '../../PhonemeAwareness/data/no-data';
import { session70 as phoneme70 } from '../../PhonemeAwareness/data/no-data';
import { session71 as phoneme71 } from '../../PhonemeAwareness/data/no-data';
import { session72 as phoneme72 } from '../../PhonemeAwareness/data/no-data';
import { session73 as phoneme73 } from '../../PhonemeAwareness/data/no-data';
import { session74 as phoneme74 } from '../../PhonemeAwareness/data/no-data';
import { session75 as phoneme75 } from '../../PhonemeAwareness/data/no-data';
import { session76 as phoneme76 } from '../../PhonemeAwareness/data/no-data';
import { session77 as phoneme77 } from '../../PhonemeAwareness/data/no-data';
import { session78 as phoneme78 } from '../../PhonemeAwareness/data/no-data';
import { session79 as phoneme79 } from '../../PhonemeAwareness/data/no-data';
import { session80 as phoneme80 } from '../../PhonemeAwareness/data/no-data';
import { session81 as phoneme81 } from '../../PhonemeAwareness/data/no-data';
import { session82 as phoneme82 } from '../../PhonemeAwareness/data/no-data';
import { session83 as phoneme83 } from '../../PhonemeAwareness/data/no-data';
import { session84 as phoneme84 } from '../../PhonemeAwareness/data/no-data';
import { session85 as phoneme85 } from '../../PhonemeAwareness/data/no-data';
import { session86 as phoneme86 } from '../../PhonemeAwareness/data/no-data';
import { session87 as phoneme87 } from '../../PhonemeAwareness/data/no-data';
import { session88 as phoneme88 } from '../../PhonemeAwareness/data/no-data';
import { session89 as phoneme89 } from '../../PhonemeAwareness/data/no-data';
import { session90 as phoneme90 } from '../../PhonemeAwareness/data/no-data';
import { session91 as phoneme91 } from '../../PhonemeAwareness/data/no-data';
import { session92 as phoneme92 } from '../../PhonemeAwareness/data/no-data';
import { session93 as phoneme93 } from '../../PhonemeAwareness/data/no-data';

import { session1 as wordsInTheEnd1 } from '../../DaysTextRevision/data/no-data';
import { session2 as wordsInTheEnd2 } from '../../DaysTextRevision/data/no-data';
import { session3 as wordsInTheEnd3 } from '../../DaysTextRevision/data/no-data';
import { session4 as wordsInTheEnd4 } from '../../DaysTextRevision/data/no-data';
import { session5 as wordsInTheEnd5 } from '../../DaysTextRevision/data/no-data';
import { session6 as wordsInTheEnd6 } from '../../DaysTextRevision/data/no-data';
import { session7 as wordsInTheEnd7 } from '../../DaysTextRevision/data/no-data';
import { session8 as wordsInTheEnd8 } from '../../DaysTextRevision/data/no-data';
import { session9 as wordsInTheEnd9 } from '../../DaysTextRevision/data/no-data';
import { session10 as wordsInTheEnd10 } from '../../DaysTextRevision/data/no-data';
import { session11 as wordsInTheEnd11 } from '../../DaysTextRevision/data/no-data';
import { session12 as wordsInTheEnd12 } from '../../DaysTextRevision/data/no-data';
import { session13 as wordsInTheEnd13 } from '../../DaysTextRevision/data/no-data';
import { session14 as wordsInTheEnd14 } from '../../DaysTextRevision/data/no-data';
import { session15 as wordsInTheEnd15 } from '../../DaysTextRevision/data/no-data';
import { session16 as wordsInTheEnd16 } from '../../DaysTextRevision/data/no-data';
import { session17 as wordsInTheEnd17 } from '../../DaysTextRevision/data/no-data';
import { session18 as wordsInTheEnd18 } from '../../DaysTextRevision/data/no-data';
import { session19 as wordsInTheEnd19 } from '../../DaysTextRevision/data/no-data';
import { session20 as wordsInTheEnd20 } from '../../DaysTextRevision/data/no-data';
import { session21 as wordsInTheEnd21 } from '../../DaysTextRevision/data/no-data';
import { session22 as wordsInTheEnd22 } from '../../DaysTextRevision/data/no-data';
import { session23 as wordsInTheEnd23 } from '../../DaysTextRevision/data/no-data';
import { session24 as wordsInTheEnd24 } from '../../DaysTextRevision/data/no-data';
import { session25 as wordsInTheEnd25 } from '../../DaysTextRevision/data/no-data';
import { session26 as wordsInTheEnd26 } from '../../DaysTextRevision/data/no-data';
import { session27 as wordsInTheEnd27 } from '../../DaysTextRevision/data/no-data';
import { session28 as wordsInTheEnd28 } from '../../DaysTextRevision/data/no-data';
import { session29 as wordsInTheEnd29 } from '../../DaysTextRevision/data/no-data';
import { session30 as wordsInTheEnd30 } from '../../DaysTextRevision/data/no-data';
import { session31 as wordsInTheEnd31 } from '../../DaysTextRevision/data/no-data';
import { session32 as wordsInTheEnd32 } from '../../DaysTextRevision/data/no-data';
import { session33 as wordsInTheEnd33 } from '../../DaysTextRevision/data/no-data';
import { session34 as wordsInTheEnd34 } from '../../DaysTextRevision/data/no-data';
import { session35 as wordsInTheEnd35 } from '../../DaysTextRevision/data/no-data';
import { session36 as wordsInTheEnd36 } from '../../DaysTextRevision/data/no-data';
import { session37 as wordsInTheEnd37 } from '../../DaysTextRevision/data/no-data';
import { session38 as wordsInTheEnd38 } from '../../DaysTextRevision/data/no-data';
import { session39 as wordsInTheEnd39 } from '../../DaysTextRevision/data/no-data';
import { session40 as wordsInTheEnd40 } from '../../DaysTextRevision/data/no-data';
import { session41 as wordsInTheEnd41 } from '../../DaysTextRevision/data/no-data';
import { session42 as wordsInTheEnd42 } from '../../DaysTextRevision/data/no-data';
import { session43 as wordsInTheEnd43 } from '../../DaysTextRevision/data/no-data';
import { session44 as wordsInTheEnd44 } from '../../DaysTextRevision/data/no-data';
import { session45 as wordsInTheEnd45 } from '../../DaysTextRevision/data/no-data';
import { session46 as wordsInTheEnd46 } from '../../DaysTextRevision/data/no-data';
import { session47 as wordsInTheEnd47 } from '../../DaysTextRevision/data/no-data';
import { session48 as wordsInTheEnd48 } from '../../DaysTextRevision/data/no-data';
import { session49 as wordsInTheEnd49 } from '../../DaysTextRevision/data/no-data';
import { session50 as wordsInTheEnd50 } from '../../DaysTextRevision/data/no-data';
import { session51 as wordsInTheEnd51 } from '../../DaysTextRevision/data/no-data';
import { session52 as wordsInTheEnd52 } from '../../DaysTextRevision/data/no-data';
import { session53 as wordsInTheEnd53 } from '../../DaysTextRevision/data/no-data';
import { session54 as wordsInTheEnd54 } from '../../DaysTextRevision/data/no-data';
import { session55 as wordsInTheEnd55 } from '../../DaysTextRevision/data/no-data';
import { session56 as wordsInTheEnd56 } from '../../DaysTextRevision/data/no-data';
import { session57 as wordsInTheEnd57 } from '../../DaysTextRevision/data/no-data';
import { session58 as wordsInTheEnd58 } from '../../DaysTextRevision/data/no-data';
import { session59 as wordsInTheEnd59 } from '../../DaysTextRevision/data/no-data';
import { session60 as wordsInTheEnd60 } from '../../DaysTextRevision/data/no-data';
import { session61 as wordsInTheEnd61 } from '../../DaysTextRevision/data/no-data';
import { session62 as wordsInTheEnd62 } from '../../DaysTextRevision/data/no-data';
import { session63 as wordsInTheEnd63 } from '../../DaysTextRevision/data/no-data';
import { session64 as wordsInTheEnd64 } from '../../DaysTextRevision/data/no-data';
import { session65 as wordsInTheEnd65 } from '../../DaysTextRevision/data/no-data';
import { session66 as wordsInTheEnd66 } from '../../DaysTextRevision/data/no-data';
import { session67 as wordsInTheEnd67 } from '../../DaysTextRevision/data/no-data';
import { session68 as wordsInTheEnd68 } from '../../DaysTextRevision/data/no-data';
import { session69 as wordsInTheEnd69 } from '../../DaysTextRevision/data/no-data';
import { session70 as wordsInTheEnd70 } from '../../DaysTextRevision/data/no-data';
import { session71 as wordsInTheEnd71 } from '../../DaysTextRevision/data/no-data';
import { session72 as wordsInTheEnd72 } from '../../DaysTextRevision/data/no-data';
import { session73 as wordsInTheEnd73 } from '../../DaysTextRevision/data/no-data';
import { session74 as wordsInTheEnd74 } from '../../DaysTextRevision/data/no-data';
import { session75 as wordsInTheEnd75 } from '../../DaysTextRevision/data/no-data';
import { session76 as wordsInTheEnd76 } from '../../DaysTextRevision/data/no-data';

import { session77 as wordsInTheEnd77 } from '../../DaysTextRevision/data/no-data';
import { session78 as wordsInTheEnd78 } from '../../DaysTextRevision/data/no-data';
import { session79 as wordsInTheEnd79 } from '../../DaysTextRevision/data/no-data';
import { session80 as wordsInTheEnd80 } from '../../DaysTextRevision/data/no-data';
import { session81 as wordsInTheEnd81 } from '../../DaysTextRevision/data/no-data';
import { session82 as wordsInTheEnd82 } from '../../DaysTextRevision/data/no-data';
import { session83 as wordsInTheEnd83 } from '../../DaysTextRevision/data/no-data';
import { session84 as wordsInTheEnd84 } from '../../DaysTextRevision/data/no-data';
import { session85 as wordsInTheEnd85 } from '../../DaysTextRevision/data/no-data';
import { session86 as wordsInTheEnd86 } from '../../DaysTextRevision/data/no-data';
import { session87 as wordsInTheEnd87 } from '../../DaysTextRevision/data/no-data';
import { session88 as wordsInTheEnd88 } from '../../DaysTextRevision/data/no-data';
import { session89 as wordsInTheEnd89 } from '../../DaysTextRevision/data/no-data';
import { session90 as wordsInTheEnd90 } from '../../DaysTextRevision/data/no-data';
import { session91 as wordsInTheEnd91 } from '../../DaysTextRevision/data/no-data';
import { session92 as wordsInTheEnd92 } from '../../DaysTextRevision/data/no-data';
import { session93 as wordsInTheEnd93 } from '../../DaysTextRevision/data/no-data';

import mainAudio_1 from '../assets/Oppgave 1. Alle gode ting er tre.m4a';
import questions_1 from '../assets/Oppgave 2. Hva leste du nå_.m4a';
import words_1 from '../assets/Oppgave 3. Ord i teksten.m4a';
import mainAudio_2 from '../assets/session2_main_paragraph.m4a';
import questions_2 from '../assets/session2_questions.m4a';
import words_2 from '../assets/session_2_wordsAudio.m4a';

import mainAudio_3 from '../assets/Leksjon 3_Oppgave 1_Dagens tekst.m4a';
import questions_3 from '../assets/Leksjon 2_oppgave 2.m4a';
import words_3 from '../assets/Leksjon 2_oppgave 3.m4a';
import mainAudio_4 from '../assets/Leksjon 3_Dino kunne ha dødd.m4a';
import questions_4 from '../assets/Leksjon 3_oppgave 2.m4a';

import words_4 from '../assets/Leksjon 3_oppgave 3.m4a';
import mainAudio_5 from '../assets/session5_main_paragraph.m4a';
import questions_5 from '../assets/session5_questions.m4a';
import words_5 from '../assets/session_5_wordsAudio.m4a';

import mainAudio_6 from '../assets/session6_main_paragraph.m4a';
import questions_6 from '../assets/session6_questions.m4a';
import words_6 from '../assets/session_6_wordsAudio.m4a';

import mainAudio_7 from '../assets/session7_main_paragraph.m4a';
import questions_7 from '../assets/session7_questions.m4a';
import words_7 from '../assets/session_7_wordsAudio.m4a';

import mainAudio_8 from '../assets/session8_main_paragraph.m4a';
import questions_8 from '../assets/session8_questions.m4a';
import words_8 from '../assets/session_8_wordsAudio.m4a';

import mainAudio_9 from '../assets/session9_main_paragraph.m4a';
import questions_9 from '../assets/session9_questions.m4a';
import words_9 from '../assets/session_9_wordsAudio.m4a';

import mainAudio_10 from '../assets/session10_main_paragraph.m4a';
import questions_10 from '../assets/session10_questions.m4a';
import words_10 from '../assets/session_10_wordsAudio.m4a';

import mainAudio_11 from '../assets/session11_main_paragraph.m4a';
import questions_11 from '../assets/session11_questions.m4a';
import words_11 from '../assets/session_11_wordsAudio.m4a';

import mainAudio_12 from '../assets/session12_main_paragraph.m4a';
import questions_12 from '../assets/session12_questions.m4a';
import words_12 from '../assets/session_12_wordsAudio.m4a';

import mainAudio_13 from '../assets/session13_main_paragraph.m4a';
import questions_13 from '../assets/session13_questions.m4a';
import words_13 from '../assets/session_13_wordsAudio.m4a';

import mainAudio_14 from '../assets/session14_main_paragraph.m4a';
import questions_14 from '../assets/session14_questions.m4a';
import words_14 from '../assets/session_14_wordsAudio.m4a';

import mainAudio_15 from '../assets/session15_main_paragraph.m4a';
import questions_15 from '../assets/session15_questions.m4a';
import words_15 from '../assets/session_15_wordsAudio.m4a';

import mainAudio_16 from '../assets/session16_main_paragraph.m4a';
import questions_16 from '../assets/session16_questions.m4a';
import words_16 from '../assets/session_16_wordsAudio.m4a';

import mainAudio_17 from '../assets/session17_main_paragraph.m4a';
import questions_17 from '../assets/session17_questions.m4a';
import words_17 from '../assets/session_17_wordsAudio.m4a';

import mainAudio_18 from '../assets/session18_main_paragraph.m4a';
import questions_18 from '../assets/session18_questions.m4a';
import words_18 from '../assets/session_18_wordsAudio.m4a';

import mainAudio_19 from '../assets/session19_main_paragraph.m4a';
import questions_19 from '../assets/session19_questions.m4a';
import words_19 from '../assets/session_19_wordsAudio.m4a';

import mainAudio_20 from '../assets/session20_main_paragraph.m4a';
import questions_20 from '../assets/session20_questions.m4a';
import words_20 from '../assets/session_20_wordsAudio.m4a';

import mainAudio_21 from '../assets/session21_main_paragraph.m4a';
import questions_21 from '../assets/session21_questions.m4a';
import words_21 from '../assets/session_21_wordsAudio.m4a';

import mainAudio_22 from '../assets/session22_main_paragraph.m4a';
import questions_22 from '../assets/session22_questions.m4a';
import words_22 from '../assets/session_22_wordsAudio.m4a';

import mainAudio_23 from '../assets/session23_main_paragraph.m4a';
import questions_23 from '../assets/session23_questions.m4a';
import words_23 from '../assets/session_23_wordsAudio.m4a';

import mainAudio_24 from '../assets/session24_main_paragraph.m4a';
import questions_24 from '../assets/session24_questions.m4a';
import words_24 from '../assets/session_24_wordsAudio.m4a';

import mainAudio_25 from '../assets/session25_main_paragraph.m4a';
import questions_25 from '../assets/session25_questions.m4a';
import words_25 from '../assets/session_25_wordsAudio.m4a';

import mainAudio_26 from '../assets/session26_main_paragraph.m4a';
import questions_26 from '../assets/session26_questions.m4a';
import words_26 from '../assets/session_26_wordsAudio.m4a';

import mainAudio_27 from '../assets/session27_main_paragraph.m4a';
import questions_27 from '../assets/session27_questions.m4a';
import words_27 from '../assets/session_27_wordsAudio.m4a';

import mainAudio_28 from '../assets/session28_main_paragraph.m4a';
import questions_28 from '../assets/session28_questions.m4a';
import words_28 from '../assets/session_28_wordsAudio.m4a';

import mainAudio_29 from '../assets/session29_main_paragraph.m4a';
import questions_29 from '../assets/session29_questions.m4a';
import words_29 from '../assets/session_29_wordsAudio.m4a';

import mainAudio_30 from '../assets/session30_main_paragraph.m4a';
import questions_30 from '../assets/session30_questions.m4a';
import words_30 from '../assets/session_30_wordsAudio.m4a';

import mainAudio_31 from '../assets/session31_main_paragraph.m4a';
import questions_31 from '../assets/session31_questions.m4a';
import words_31 from '../assets/session_31_wordsAudio.m4a';

import mainAudio_32 from '../assets/session32_main_paragraph.m4a';
import questions_32 from '../assets/session32_questions.m4a';
import words_32 from '../assets/session_32_wordsAudio.m4a';

import mainAudio_33 from '../assets/session33_main_paragraph.m4a';
import questions_33 from '../assets/session33_questions.m4a';
import words_33 from '../assets/session_33_wordsAudio.m4a';

import mainAudio_34 from '../assets/session34_main_paragraph.m4a';
import questions_34 from '../assets/session34_questions.m4a';
import words_34 from '../assets/session_34_wordsAudio.m4a';

import mainAudio_35 from '../assets/session35_main_paragraph.m4a';
import questions_35 from '../assets/session35_questions.m4a';
import words_35 from '../assets/session_35_wordsAudio.m4a';

import mainAudio_36 from '../assets/session36_main_paragraph.m4a';
import questions_36 from '../assets/session36_questions.m4a';
import words_36 from '../assets/session_36_wordsAudio.m4a';

import mainAudio_37 from '../assets/session37_main_paragraph.m4a';
import questions_37 from '../assets/session37_questions.m4a';
import words_37 from '../assets/session_37_wordsAudio.m4a';

import mainAudio_38 from '../assets/session38_main_paragraph.m4a';
import questions_38 from '../assets/session38_questions.m4a';
import words_38 from '../assets/session_38_wordsAudio.m4a';

import mainAudio_39 from '../assets/session39_main_paragraph.m4a';
import questions_39 from '../assets/session39_questions.m4a';
import words_39 from '../assets/session_39_wordsAudio.m4a';

import mainAudio_40 from '../assets/session40_main_paragraph.m4a';
import questions_40 from '../assets/session40_questions.m4a';
import words_40 from '../assets/session_40_wordsAudio.m4a';

import mainAudio_41 from '../assets/session41_main_paragraph.m4a';
import questions_41 from '../assets/session41_questions.m4a';
import words_41 from '../assets/session_41_wordsAudio.m4a';

import mainAudio_42 from '../assets/session42_main_paragraph.m4a';
import questions_42 from '../assets/session42_questions.m4a';
import words_42 from '../assets/session_42_wordsAudio.m4a';

import mainAudio_43 from '../assets/session43_main_paragraph.m4a';
import questions_43 from '../assets/session43_questions.m4a';
import words_43 from '../assets/session_43_wordsAudio.m4a';

import mainAudio_44 from '../assets/session44_main_paragraph.m4a';
import questions_44 from '../assets/session44_questions.m4a';
import words_44 from '../assets/session_44_wordsAudio.m4a';

import mainAudio_45 from '../assets/session45_main_paragraph.m4a';
import questions_45 from '../assets/session45_questions.m4a';
import words_45 from '../assets/session_45_wordsAudio.m4a';

import mainAudio_46 from '../assets/session46_main_paragraph.m4a';
import questions_46 from '../assets/session46_questions.m4a';
import words_46 from '../assets/session_46_wordsAudio.m4a';

import mainAudio_47 from '../assets/session47_main_paragraph.m4a';
import questions_47 from '../assets/session47_questions.m4a';
import words_47 from '../assets/session_47_wordsAudio.m4a';

import mainAudio_48 from '../assets/session48_main_paragraph.m4a';
import questions_48 from '../assets/session48_questions.m4a';
import words_48 from '../assets/session_48_wordsAudio.m4a';

import mainAudio_49 from '../assets/session49_main_paragraph.m4a';
import questions_49 from '../assets/session49_questions.m4a';
import words_49 from '../assets/session_49_wordsAudio.m4a';

import mainAudio_50 from '../assets/session50_main_paragraph.m4a';
import questions_50 from '../assets/session50_questions.m4a';
import words_50 from '../assets/session_50_wordsAudio.m4a';

import mainAudio_51 from '../assets/session51_main_paragraph.m4a';
import questions_51 from '../assets/session51_questions.m4a';
import words_51 from '../assets/session_51_wordsAudio.m4a';

import mainAudio_52 from '../assets/session52_main_paragraph.m4a';
import questions_52 from '../assets/session52_questions.m4a';
import words_52 from '../assets/session_52_wordsAudio.m4a';

import mainAudio_53 from '../assets/session53_main_paragraph.m4a';
import questions_53 from '../assets/session53_questions.m4a';
import words_53 from '../assets/session_53_wordsAudio.m4a';

import mainAudio_54 from '../assets/session54_main_paragraph.m4a';
import questions_54 from '../assets/session54_questions.m4a';
import words_54 from '../assets/session_54_wordsAudio.m4a';

import mainAudio_55 from '../assets/session55_main_paragraph.m4a';
import questions_55 from '../assets/session55_questions.m4a';
import words_55 from '../assets/session_55_wordsAudio.m4a';

import mainAudio_56 from '../assets/session56_main_paragraph.m4a';
import questions_56 from '../assets/session56_questions.m4a';
import words_56 from '../assets/session_56_wordsAudio.m4a';

import mainAudio_57 from '../assets/session57_main_paragraph.m4a';
import questions_57 from '../assets/session57_questions.m4a';
import words_57 from '../assets/session_57_wordsAudio.m4a';

import mainAudio_58 from '../assets/session58_main_paragraph.m4a';
import questions_58 from '../assets/session58_questions.m4a';
import words_58 from '../assets/session_58_wordsAudio.m4a';

import mainAudio_59 from '../assets/session59_main_paragraph.m4a';
import questions_59 from '../assets/session59_questions.m4a';
import words_59 from '../assets/session_59_wordsAudio.m4a';

import mainAudio_60 from '../assets/session60_main_paragraph.m4a';
import questions_60 from '../assets/session60_questions.m4a';
import words_60 from '../assets/session_60_wordsAudio.m4a';

import mainAudio_61 from '../assets/session61_main_paragraph.m4a';
import questions_61 from '../assets/session61_questions.m4a';
import words_61 from '../assets/session_61_wordsAudio.m4a';

import mainAudio_62 from '../assets/session62_main_paragraph.m4a';
import questions_62 from '../assets/session62_questions.m4a';
import words_62 from '../assets/session_62_wordsAudio.m4a';

import mainAudio_63 from '../assets/session63_main_paragraph.m4a';
import questions_63 from '../assets/session63_questions.m4a';
import words_63 from '../assets/session_63_wordsAudio.m4a';

import mainAudio_64 from '../assets/session64_main_paragraph.m4a';
import questions_64 from '../assets/session64_questions.m4a';
import words_64 from '../assets/session_64_wordsAudio.m4a';

import mainAudio_65 from '../assets/session65_main_paragraph.m4a';
import questions_65 from '../assets/session65_questions.m4a';
import words_65 from '../assets/session_65_wordsAudio.m4a';

import mainAudio_66 from '../assets/session66_main_paragraph.m4a';
import questions_66 from '../assets/session66_questions.m4a';
import words_66 from '../assets/session_66_wordsAudio.m4a';

import mainAudio_67 from '../assets/session67_main_paragraph.m4a';
import questions_67 from '../assets/session67_questions.m4a';
import words_67 from '../assets/session_67_wordsAudio.m4a';

import mainAudio_68 from '../assets/session68_main_paragraph.m4a';
import questions_68 from '../assets/session68_questions.m4a';
import words_68 from '../assets/session_68_wordsAudio.m4a';

import mainAudio_69 from '../assets/session69_main_paragraph.m4a';
import questions_69 from '../assets/session69_questions.m4a';
import words_69 from '../assets/session_69_wordsAudio.m4a';

import mainAudio_70 from '../assets/session70_main_paragraph.m4a';
import questions_70 from '../assets/session70_questions.m4a';
import words_70 from '../assets/session_70_wordsAudio.m4a';

import mainAudio_71 from '../assets/session71_main_paragraph.m4a';
import questions_71 from '../assets/session71_questions.m4a';
import words_71 from '../assets/session_71_wordsAudio.m4a';
import mainAudio_72 from '../assets/session72_main_paragraph.m4a';
import questions_72 from '../assets/session72_questions.m4a';
import words_72 from '../assets/session_72_wordsAudio.m4a';

import mainAudio_73 from '../assets/session73_main_paragraph.m4a';
import questions_73 from '../assets/session73_questions.m4a';
import words_73 from '../assets/session_73_wordsAudio.m4a';

import mainAudio_74 from '../assets/session74_main_paragraph.m4a';
import questions_74 from '../assets/session74_questions.m4a';
import words_74 from '../assets/session_74_wordsAudio.m4a';

import mainAudio_75 from '../assets/session75_main_paragraph.m4a';
import questions_75 from '../assets/session75_questions.m4a';
import words_75 from '../assets/session_75_wordsAudio.m4a';

import mainAudio_76 from '../assets/session76_main_paragraph.m4a';
import questions_76 from '../assets/session76_questions.m4a';
import words_76 from '../assets/session_76_wordsAudio.m4a';

import mainAudio_77 from '../assets/session77_main_paragraph.m4a';
import questions_77 from '../assets/session77_questions.m4a';
import words_77 from '../assets/session_77_wordsAudio.m4a';

import mainAudio_78 from '../assets/session78_main_paragraph.m4a';
import questions_78 from '../assets/session78_questions.m4a';
import words_78 from '../assets/session_78_wordsAudio.m4a';

import mainAudio_79 from '../assets/session79_main_paragraph.m4a';
import questions_79 from '../assets/session79_questions.m4a';
import words_79 from '../assets/session_79_wordsAudio.m4a';

import mainAudio_80 from '../assets/session80_main_paragraph.m4a';
import questions_80 from '../assets/session80_questions.m4a';
import words_80 from '../assets/session_80_wordsAudio.m4a';

import mainAudio_81 from '../assets/session81_main_paragraph.m4a';
import questions_81 from '../assets/session81_questions.m4a';
import words_81 from '../assets/session_81_wordsAudio.m4a';

import mainAudio_82 from '../assets/session82_main_paragraph.m4a';
import questions_82 from '../assets/session82_questions.m4a';
import words_82 from '../assets/session_82_wordsAudio.m4a';

import mainAudio_83 from '../assets/session83_main_paragraph.m4a';
import questions_83 from '../assets/session83_questions.m4a';
import words_83 from '../assets/session_83_wordsAudio.m4a';

import mainAudio_84 from '../assets/session84_main_paragraph.m4a';
import questions_84 from '../assets/session84_questions.m4a';
import words_84 from '../assets/session_84_wordsAudio.m4a';

import mainAudio_85 from '../assets/session85_main_paragraph.m4a';
import questions_85 from '../assets/session85_questions.m4a';
import words_85 from '../assets/session_85_wordsAudio.m4a';

import mainAudio_86 from '../assets/session86_main_paragraph.m4a';
import questions_86 from '../assets/session86_questions.m4a';
import words_86 from '../assets/session_86_wordsAudio.m4a';

import mainAudio_87 from '../assets/session87_main_paragraph.m4a';
import questions_87 from '../assets/session87_questions.m4a';
import words_87 from '../assets/session_87_wordsAudio.m4a';

import mainAudio_88 from '../assets/session88_main_paragraph.m4a';
import questions_88 from '../assets/session88_questions.m4a';
import words_88 from '../assets/session_88_wordsAudio.m4a';

import mainAudio_89 from '../assets/session89_main_paragraph.m4a';
import questions_89 from '../assets/session89_questions.m4a';
import words_89 from '../assets/session_89_wordsAudio.m4a';

import mainAudio_90 from '../assets/session90_main_paragraph.m4a';
import questions_90 from '../assets/session90_questions.m4a';
import words_90 from '../assets/session_90_wordsAudio.m4a';

import mainAudio_91 from '../assets/session91_main_paragraph.m4a';
import questions_91 from '../assets/session91_questions.m4a';
import words_91 from '../assets/session_91_wordsAudio.m4a';

import mainAudio_92 from '../assets/session92_main_paragraph.m4a';
import questions_92 from '../assets/session92_questions.m4a';
import words_92 from '../assets/session_92_wordsAudio.m4a';

import mainAudio_93 from '../assets/session93_main_paragraph.m4a';
import questions_93 from '../assets/session93_questions.m4a';
import words_93 from '../assets/session_93_wordsAudio.m4a';

export const session1 = [
	{
		introAudio: mainAudio_1,
		questionAudio: questions_1,
		wordsAudio: words_1,
		mainHeading: '1. Dagens tekst',
		mainImg: session1Img,
		subHeading: 'Alle gode ting er tre.',
		easySummary: ['Unni er en hund.', 'Ylvis er en hund', 'Iris er en katt.', 'Åge er sint på I'],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd1[0].revisionWordsAndInstruction.words,
		summary: [
			'Unni er en hund.',
			'Ylvis er en hund.',
			'Åge er en hund.',
			'De løper og leker.',
			'Iris er en katt.',
			'Åge er sint på Iris.',
			'Ylvis uler som en ulv.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Unni, Ylvis og Åge bor sammen. De løper og leker hver eneste dag. Når katten
					Iris er utenfor, bjeffer alle sammen. Åge blir sint. Han hater katter og kunne
					tenkt seg og sloss med Iris. Han skulle gjerne ha jaget katten opp i et tre.
					Unni{' '}
					<span style={{ color: 'Lime' }}>
						<b>klynker</b>
					</span>{' '}
					og{' '}
					<span style={{ color: 'Lime' }}>
						<b>logrer</b>
					</span>{' '}
					usikkert med halen. Hun tenker sikkert at katter er rare dyr. Ylvis{' '}
					<span style={{ color: 'Lime' }}>
						<b>uler</b>
					</span>{' '}
					som en ulv.
				</p>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hva heter historien?',
			'- Hvem er med i fortellingen?',
			' - Hva gjør dem hver eneste dag?',
			' - hvor mange katter og hvor mange hunder er med i fortellingen?',
			' - hva er det Åge kunne tenke seg å gjøre?',
			' - Hvorfor?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- klynker', ' - logrer', ' - uler'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session2 = [
	{
		introAudio: mainAudio_2,
		questionAudio: questions_2,
		wordsAudio: words_2,
		mainHeading: '1. Dagens tekst',
		mainImg: session2Img,
		subHeading: 'Alle gode ting er tre.',
		easySummary: [
			'Ylvis uler som en ulv.',
			'Han tror at han er stor.',
			'Hunder er fine dyr. ',
			'De liker å leke og ha det gøy.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd2[0].revisionWordsAndInstruction.words,
		summary: [
			'Ylvis uler som en ulv.',
			'Han tror at han er stor',
			'men han er liten.',
			'Hunder er fine dyr.',
			'De liker å leke og ha det gøy.',
			<span>Unni, Ylvis og Åge liker å lukte der andre har tisset og bæsjet.</span>,
			<span>
				Alle tre har god{' '}
				<span style={{ color: 'Lime' }}>
					<b>luktesans</b>
				</span>
				.
			</span>,
			<span>
				Hunder har bedre{' '}
				<span style={{ color: 'Lime' }}>
					<b>luktesans</b>
				</span>{' '}
				enn mennesker.
			</span>,
			'Det er litt rart å tenke på.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Ylvis uler som en ulv. Han tror at han er veldig stor, men han er like liten som
					en{' '}
					<span style={{ color: 'Lime' }}>
						<b>støvsuger</b>
					</span>
					. Hunder er flotte dyr. De liker å leke og ha det gøy. Unni, Ylvis og Åge elsker
					å lukte der andre har tisset og bæsjet.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hva heter de som er med i fortellingen?',
			' - Hvor liten er Ylvis?',
			' - Hva liker hunder å gjøre?',
			' - Hva er det som er litt rart å tenke på?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Støvsuger', ' - Luktesans'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session3 = [
	{
		introAudio: mainAudio_3,
		questionAudio: questions_3,
		wordsAudio: words_3,
		mainHeading: '1. Dagens tekst',
		mainImg: session3Img,
		subHeading: 'Ikke helt fornøyd.',
		easySummary: ['Jeg heter Berit.', 'Jeg har bursdag i dag.', 'Jeg gleder meg.', 'Bente og Børre skal komme.'],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd3[0].revisionWordsAndInstruction.words,
		summary: [
			'Jeg heter Berit. Og har bursdag i dag.',
			'Jeg gleder meg.',
			'Berit og Børre skal komme.',
			'Jeg ønsker meg smykke og bamse.',
			'Jeg har laget ønskeliste.',
			'Jeg får gaver av Berit og Børre.',
			'Jeg får en bil og en badeand.',
			'Jeg blir lei meg. ',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Jeg heter Berit og har bursdag i dag. Jeg gleder meg til at Børre og Bente skal
					komme. Jeg har bedt Bente kjøpe et smykke til meg, og fra Børre ønsket jeg meg
					en bamse. En bamse med deilig myk pels. «Dere er sene!» Sier jeg og tar imot
					gavene. Jeg river opp papiret uten å lese kortene. Kort er for{' '}
					<span style={{ color: 'Lime' }}>
						<b>bavianer</b>
					</span>
					. «Så det er her du bor,» sier Børre. «Fint hus,» sier Bente, men jeg svarer
					ikke. Jeg står og ser på gavene. «Fikk dere ikke ønskelisten min?» sier jeg og
					kjenner at jeg blir{' '}
					<span style={{ color: 'Lime' }}>
						<b>skuffet</b>
					</span>{' '}
					og sint. «Hva er dette?» «Jo, da. Vi fikk{' '}
					<span style={{ color: 'Lime' }}>
						<b>listen</b>
					</span>
					,» sier Bente og Børre i kor. «Men hvorfor får jeg en bil og en badeand, da?» ,
				</p>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- hvem har bursdag i dag?',
			' - hva heter de to som skal komme på besøk?',
			' - hva ønsket Berit seg i bursdagsgave?',
			' - hvorfor ble Berit skuffet?',
			' - hva fikk hun i gave hos Bente og Børre?',
			' - tror du Børre og Bente har vært på besøk hos Berit tidligere?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Fornøyd', ' - Bavianer', ' - Skuffet', ' - Liste'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session4 = [
	{
		introAudio: mainAudio_4,
		questionAudio: questions_4,
		wordsAudio: words_4,
		mainHeading: '1. Dagens tekst',
		mainImg: session4Img,
		subHeading: 'Dino kunne ha dødd.',
		easySummary: [
			'Dino er en dinosaur.',
			'Dino har ingen venner.',
			'De fleste vil spise ham. ',
			'Rex dreper andre dinosaurer.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd4[0].revisionWordsAndInstruction.words,
		summary: [
			<span>Dino er en dinosaur.</span>,
			<span>Dino har ingen venner.</span>,
			<span>De fleste vil spise ham.</span>,
			<span>Rex dreper andre dinosaurer.</span>,
			<span>Han spiser dem.</span>,
			<span>
				Rex er en{' '}
				<span style={{ color: 'Lime' }}>
					<b>dust</b>
				</span>
				.
			</span>,
			<span>
				<span style={{ color: 'Lime' }}>
					<b>vulkanen</b>
				</span>{' '}
				fikk{' '}
				<span style={{ color: 'Lime' }}>
					<b>utbrudd</b>
				</span>
				.
			</span>,
			<span>Rex stikker og det er deilig å se.</span>,
			<span>
				Dino{' '}
				<span style={{ color: 'Lime' }}>
					<b>dåner</b>
				</span>{' '}
				av røyken. Dolly og Dille flyr ned for å hjelpe Dino.
			</span>,
			<span>
				<span style={{ color: 'Lime' }}>
					<b>Lavaen</b>
				</span>{' '}
				nærmer seg ungene! Er det for sent?
			</span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Dino er en dinosaur. Mange kaller ham{' '}
					<span style={{ color: 'Lime' }}>
						<b>tårnet</b>
					</span>
					, men det liker han ikke. Dino har ingen venner, for{' '}
					<span style={{ color: 'Lime' }}>
						<b>de fleste</b>
					</span>{' '}
					spiser slike som ham. Rex dreper andre dinosaurer. Han spiser alt som går på
					minst to bein. Rex er en{' '}
					<span style={{ color: 'Lime' }}>
						<b>dust</b>
					</span>
					, og hadde det ikke vært for at{' '}
					<span style={{ color: 'Lime' }}>
						<b>vulkanen</b>
					</span>{' '}
					fikk{' '}
					<span style={{ color: 'Lime' }}>
						<b>utbrudd</b>
					</span>
					, hadde Dino dødd. Rex stikker og det er deilig å se. «Ha det bra!» roper Dino
					etter ham. Han danser en liten dans han har lært på{' '}
					<span style={{ color: 'Lime' }}>
						<b>danseskolen</b>
					</span>{' '}
					for lange dinosaurer. Dolly og Dille flyr unna røyken og hoster. De drar mot
					nord. Dino{' '}
					<span style={{ color: 'Lime' }}>
						<b>labber videre</b>
					</span>
					. Røyken kveler ham så han{' '}
					<span style={{ color: 'Lime' }}>
						<b>dåner</b>
					</span>
					. Dolly og Dille flyr ned for å hjelpe Dino.{' '}
					<span style={{ color: 'Lime' }}>
						<b>Lavaen</b>
					</span>{' '}
					nærmer seg ungene! Er det for sent?,
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hvem er Dino?',
			' - Hvorfor tror du han ofte blir kalt tårnet?',
			' - Hvorfor har Dino ingen venner?',
			' - Hvem er Rex?',
			' - Hvorfor ble Dino reddet fra å bli spist av Rex?',
			' - Hvem er Dolly og Dille?',
			' - Hvorfor dåner Dino?',
			' - Tror du at ungene blir reddet, eller er det for seint?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: [
				'- Tårnet',
				' - Dust',
				' - Vulkan',
				' - Utbrudd',
				' - Danseskole',
				' - Labber videre',
				' - Dåner',
				' - Klekke',
				' - Lava',
				' - De fleste',
			],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session5 = [
	{
		introAudio: mainAudio_5,
		questionAudio: questions_5,
		wordsAudio: words_5,
		mainHeading: '1. Dagens tekst',
		mainImg: session5Img,
		subHeading: 'Bæsj, sand og jord.',
		easySummary: [
			'Fido bæsjet ved døra til Frida.',
			'«Kom deg vekk din frosk!» ropte Frida.',
			'«Du kan ikke bæsje her.»',
			'Fido så rart på Frida.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd5[0].revisionWordsAndInstruction.words,
		summary: [
			'Fido bæsjet ved døra til Frida Fisefin.',
			'«Kom deg vekk din frosk!» ropte Frida.',
			'«Du kan ikke bæsje her.',
			'Fido så rart på Frida.',
			'Da Fido var ferdig logret han med halen og sparket i grusen.',
			'«Dø!» ropte Frida og slo etter Fido med en kost.',
			'Hun bommet.',
			'Fido sprang til neste hus og tisset på alle dører han kunne finne. ',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Da Fido bæsjet ved døra til Frida Fisefin, fikk hun{' '}
					<span style={{ color: 'Lime' }}>
						<b>fnatt</b>
					</span>
					. Fido ante fred og ingen fare da døra åpnet seg, mens han satt der på{' '}
					<span style={{ color: 'Lime' }}>
						<b>huk</b>
					</span>{' '}
					og gjorde det han alltid gjorde ute. «Kom deg vekk, din frosk!» ropte Frida, og
					rev seg i håret. «Du kan ikke bæsje her, dette er da ikke et toalett!» Fido{' '}
					<span style={{ color: 'Lime' }}>
						<b>kikket</b>
					</span>{' '}
					rart på Frida, som nå hadde revet av seg flere lange hårtuster. Da Fido var
					ferdig,{' '}
					<span style={{ color: 'Lime' }}>
						<b>logret</b>
					</span>{' '}
					han med halen og sparket i grusen. Frida holdt seg for ansiktet. Hun fikk både
					bæsj, sand og jord over hele seg. «Dø!» ropte Frida Fisefin, fant en kost og slo
					etter Fido. Heldigvis for Fido, bommet hun. Han løp bare til neste hus og tisset
					på alle dører han kunne finne.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hva heter de som er med i fortellingen?',
			' - Hvem er Fido?',
			' - Hva gjorde Fido da Frida Fisefin åpnet døra?',
			' - Hvorfor kikket Fido rart på Frida?',
			' - Hva gjorde Fido da han var ferdig?',
			' - Hva fikk Frida over hele seg?',
			' - Hvorfor ropte Frida «dø» og slo etter Fido med kost, tror du?',
			' - Hvorfor blir Frida også kalt fisefin, tror du?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Fnatt ', ' - Ante fred og ingen fare', ' - Huk', ' - Logre', ' - Kikke'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session6 = [
	{
		introAudio: mainAudio_6,
		questionAudio: questions_6,
		wordsAudio: words_6,
		mainHeading: '1. Dagens tekst',
		mainImg: session6Img,
		subHeading: 'Ikke helt som andre sjiraffer',
		easySummary: [
			'Guri er ikke som andre sjiraffer.',
			'Hun er gul og gal.',
			'Geir og Gunnar digger Guri.',
			'De glaner på henne.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd6[0].revisionWordsAndInstruction.words,
		summary: [
			'Guri er ikke som andre sjiraffer.',
			'Hun er gul og gal.',
			'Geir og Gunnar digger Guri.',
			'De glaner på henne hver dag.',
			'Geir tror at Gunnar er blitt forelsket.',
			'«Jeg tror jeg har blitt syk», sier Guri.',
			'Hun hoster og nyser.',
			'Geir gremmes.',
			'Guri blir svimmel.',
			'Hun snubler og faller på bakken med et klask.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Guri er ikke som andre sjiraffer. Hun er en gul og gal sjiraff, som går med
					blått skjerf i varmen. Geir og Gunnar{' '}
					<span style={{ color: 'Lime' }}>
						<b>digger</b>
					</span>{' '}
					Guri, og de{' '}
					<span style={{ color: 'Lime' }}>
						<b>glaner</b>
					</span>{' '}
					på henne hver eneste dag. Geir tror at Gunnar har blitt forelsket, for han
					strammer selene på den lilla buksa med gule bukseknapper når hun går forbi. «Er
					noe galt, Guri?» sier Gunnar. «Du hoster så fælt!» «Jeg tror jeg har blitt syk,»
					sier Guri og nyser på genseren til Geir. «Æsj!»{' '}
					<span style={{ color: 'Lime' }}>
						<b>gnåler</b>
					</span>{' '}
					Geir og{' '}
					<span style={{ color: 'Lime' }}>
						<b>gremmes</b>
					</span>
					. Han stikker ut den blå tunga. «Du nøs på meg. «Sorry,» sier Guri og blir{' '}
					<span style={{ color: 'Lime' }}>
						<b>svimmel</b>
					</span>
					. Hun snubler i beina på Geir og lander på bakken med et klask.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hva heter de som er med i fortellingen?',
			' - Hva har Guri på seg?',
			' - Hvordan ser buksene til Gunnar ut?',
			' - Hvorfor tror Geir at Gunnar er forelsket i Guri?',
			' - Hva feiler Guri?',
			' - Hvilken farge har Geir på tunga?',
			' - Hva skjer med Guri?',
			' - Hvorfor er ikke Guri helt som andre sjiraffer, tror du?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Glane', ' - Digger', '- Gremmes', ' - Gnåle', '- Gørr', ' - Svimmel'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session7 = [
	{
		introAudio: mainAudio_7,
		questionAudio: questions_7,
		wordsAudio: words_7,
		mainHeading: '1. Dagens tekst',
		mainImg: session7Img,
		subHeading: 'Honningfellen.',
		easySummary: [
			'Zara og Ada er feer.',
			'Nå er det høstferie.',
			'Haldis vil komme på besøk.',
			'Haldis elsker honning.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd7[0].revisionWordsAndInstruction.words,
		summary: [
			'Zara og Ada er feer.',
			'Nå er det høstferie.',
			'Haldis vil komme på besøk i år også.',
			'Haldis elsker honning.',
			'I fjor stjal hun honning.',
			'Ada og Zara har gjemt seg bak en sopp.',
			'Haldis kommer.',
			'Hun tar flere glass.',
			'Det står «honning» på lappen.',
			'Det er hestemøkk i glassene.',
			'Ada og Zara lo. Der fikk hun!',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Zara og Ada er{' '}
					<span style={{ color: 'Lime' }}>
						<b>feer</b>
					</span>
					, og de flyr høyt om høsten og lavt om våren. Nå er det høstferie, og de vet at
					Haldis vil komme på besøk i år også. Haldis elsker honning, og i fjor stjal hun
					alt hun kunne bære. Det får hun ikke gjøre denne gangen, for Ada og Zara har{' '}
					<span style={{ color: 'Lime' }}>
						<b>klekket</b>
					</span>{' '}
					ut en plan. De ser at Haldis flyr over himmelen. Hun lander og banker på døra.
					Zara og Ada har gjemt seg bak en stor sopp og{' '}
					<span style={{ color: 'Lime' }}>
						<b>kniser</b>
					</span>
					. De ser at Haldis tar flere glass som de har satt fram. Det står ”honning” på
					lappene. Haldis{' '}
					<span style={{ color: 'Lime' }}>
						<b>putter</b>
					</span>{' '}
					fem glass i sekken sin og flyr høyt opp i det blå. «Hva var det i de glassene?»
					sa Ada. «Det var{' '}
					<span style={{ color: 'Lime' }}>
						<b>heslig</b>
					</span>{' '}
					hestemøkk,» sa Zara, og{' '}
					<span style={{ color: 'Lime' }}>
						<b>knakk sammen</b>
					</span>{' '}
					av latter. Ada rullet rundt og hylte av glede. Der fikk hun!,
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hva er Zara og Ada?',
			' - Hva gjør de om høsten og hva gjør de om våren?',
			' - Hvem vil komme på besøk nå når det er høstferie?',
			' - Hva elsker Haldis?',
			' - Hva gjorde Haldis i fjor når hun var på besøk?',
			' - Hvilken plan hadde Zara og Ada lagt?',
			' - Virket planen?',
			' - Hva tror du Haldis tenkte da hun åpnet glassene?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Klekket', ' - Feer', '- Kniser', ' - Putter', ' - Heslig', ' - Knekke sammen'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session8 = [
	{
		introAudio: mainAudio_8,
		questionAudio: questions_8,
		wordsAudio: words_8,
		mainHeading: '1. Dagens tekst',
		mainImg: session8Img,
		subHeading: 'En jungeltabbe.',
		easySummary: [
			'Jens angret på at han tryllet.',
			'Han tryllet seg bort.',
			'Jentene i salen jublet.',
			'Jens dukket opp i en jungel.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd8[0].revisionWordsAndInstruction.words,
		summary: [
			'Jens angret på at han tryllet.',
			'Han tryllet seg bort, som Jerry Potter.',
			'Jentene i salen jublet.',
			'Han dukket opp midt i en jungel.',
			'En tiger jaget ham opp i et tre.',
			'Heldigvis kan ikke kattedyr klatre, tenkte Jens.',
			'Der tok han feil.',
			'Tigeren klatret opp i treet.',
			'Jens hylte og veivet med tryllestaven.',
			'«Simsalabim, gå bort ditt jungelbeist!», sa han ',
			'Opp av hatten spratt en kanin. Jens svimte av. ',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Jens{' '}
					<span style={{ color: 'Lime' }}>
						<b>angret</b>
					</span>{' '}
					på at han tryllet litt for godt. Han burde ha{' '}
					<span style={{ color: 'Lime' }}>
						<b>jukset</b>
					</span>{' '}
					og ikke tryllet seg bort, som Jerry Potter. Jentene i salen{' '}
					<span style={{ color: 'Lime' }}>
						<b>jublet</b>
					</span>{' '}
					da han forsvant, men det gikk ikke som han hadde håpet på. Han dukket opp midt i
					en jungel. Det var jaggu en
					<span style={{ color: 'Lime' }}>
						<b>tabbe</b>
					</span>
					! En tiger som hadde gjemt seg,{' '}
					<span style={{ color: 'Lime' }}>
						<b>snek</b>
					</span>{' '}
					seg innpå og jaget ham opp i et tre. Heldigvis kan ikke kattedyr klatre, tenkte
					Jens, men der tok han feil. Tigeren brølte og klatret opp i treet. Jens hylte,{' '}
					<span style={{ color: 'Lime' }}>
						<b>jamret</b>
					</span>{' '}
					seg og{' '}
					<span style={{ color: 'Lime' }}>
						<b>veivet</b>
					</span>{' '}
					med tryllestaven. «Simsalabim, gå bort ditt{' '}
					<span style={{ color: 'Lime' }}>
						<b>jungelbeist</b>
					</span>
					!» sa han, men noe rart skjedde. Opp fra hatten spratt en kanin og gjorde alt
					verre. «Jammen, jammen....» sa Jens og{' '}
					<span style={{ color: 'Lime' }}>
						<b>svimte</b>
					</span>{' '}
					av. Det siste han husket var at han falt.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hva angret Jens på?',
			' - Hva hadde skjedd med Jerry Potter?',
			' - Hva gjorde jentene da Jens forsvant?',
			' - Hvem var i jungelen da Jens kom dit, og hva skjedde?',
			' - Hvorfor ble alt verre da en kanin spratt opp av hatten, tror du?',
			' - Hvorfor svimte han av, tror du?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: [
				'- Angre',
				' - Jukse',
				'- Jublet',
				' - Tabbe',
				'- Snek',
				' - Jamre',
				'- Veivet',
				' - Jungelbeist',
				' - svimte av',
			],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session9 = [
	{
		introAudio: mainAudio_9,
		questionAudio: questions_9,
		wordsAudio: words_9,
		mainHeading: '1. Dagens tekst',
		mainImg: session9Img,
		subHeading: 'Kakeland',
		easySummary: [
			'Kine elsker sukker.',
			'Kurt har ikke vært i kakeland.',
			'Der er det dører av karameller.',
			'Kaker vokser på bakken.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd9[0].revisionWordsAndInstruction.words,
		summary: [
			'Kine elsker sukker.',
			'Kurt har ikke vært i kakeland.',
			'Der er det dører av karameller.',
			'Kaker som vokser på bakken.',
			'Kurt tar litt krem med kokos fra taket på et hus, og smaker. ',
			'«Tannlegen ble sint,» sier Kine.',
			'Hun spiser litt pynt fra en kake.',
			'«Du bor jo i sukker! Ti hull på et år», sier Kurt.',
			'Kine sukker og blir flau.',
			'Mamma sier at jeg må spise sunn mat.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					«Hvorfor jeg elsker{' '}
					<span style={{ color: 'Lime' }}>
						<b>sukker</b>
					</span>
					?» spør Kine. Kurt har sikkert aldri vært i Kakeland før, tenker hun. Da hadde
					han forstått. «Er det første gang du er her?» «Ja, jeg har aldri hørt om dette
					stedet,» sier Kurt, og{' '}
					<span style={{ color: 'Lime' }}>
						<b>kakker</b>
					</span>{' '}
					den ene{' '}
					<span style={{ color: 'Lime' }}>
						<b>knoken</b>
					</span>{' '}
					på en dør av karamell. «Jeg skjønner ikke hvorfor tannlegen ble så sint,» sier
					Kine og spiser litt pynt fra en kake som vokser på bakken. «Det skjønner jeg,»
					sier Kurt, og{' '}
					<span style={{ color: 'Lime' }}>
						<b>kniser</b>
					</span>
					. Han tar litt krem med kokos fra taket på det ene huset, og smaker. «Du bor jo
					i{' '}
					<span style={{ color: 'Lime' }}>
						<b>sukker</b>
					</span>
					! Ti hull på ett år. Fikk du premie?» Kine{' '}
					<span style={{ color: 'Lime' }}>
						<b>sukker</b>
					</span>{' '}
					og blir{' '}
					<span style={{ color: 'Lime' }}>
						<b>flau</b>
					</span>
					. «Jeg liker{' '}
					<span style={{ color: 'Lime' }}>
						<b>sukker</b>
					</span>
					, men mamma sier at jeg må spise sunn mat,» sier Kine, og henger{' '}
					<span style={{ color: 'Lime' }}>
						<b>koften</b>
					</span>{' '}
					på en knagg.
				</p>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			' - Hadde Kurt vært i Kakeland før?',
			' - Hvorfor var tannlegen sint på Kine?',
			' - Beskriv huset som Kine og Kurt er i.',
			' - Ville du likt å vært i det huset?',
			' - Hva sier mammaen til Kine at hun må gjøre?',
			' - Hvorfor er godteri og sukker usunt?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Knoken', ' - kniser', '- sukker ( 2 betydninger)', ' - flau', '- kakker', ' - kofte'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session10 = [
	{
		introAudio: mainAudio_10,
		questionAudio: questions_10,
		wordsAudio: words_10,
		mainHeading: '1. Dagens tekst',
		mainImg: session10Img,
		subHeading: 'En latterlig rik lærepenge',
		easySummary: [
			'Lars, Leif og Lasse liker diamanter.',
			'De bryter løs edle steiner.',
			'De er rike, men liker ikke å gi bort penger.',
			'«Kan jeg få låne en lapp av dere?» ropte Laffen.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd10[0].revisionWordsAndInstruction.words,
		summary: [
			'Lars, Leif og Lasse liker diamanter.',
			<span>
				De bryter løst lass med{' '}
				<span style={{ color: 'Lime' }}>
					<b>edle</b>
				</span>{' '}
				steiner.
			</span>,
			'De er rike, men liker ikke å gi bort penger.',
			'«Kan jeg få låne en lapp av dere?» ropte Laffen. Jeg lover å betale tilbake!» ',
			'Han gikk i en hullete bukse og jakke.',
			'Vi lar ikke late folk få penger,» sa Lars og lo.',
			'«Jeg trenger mat. Sønnen min sulter.»',
			<span>
				«Få deg en jobb!» hylte alle i kor. Da raste{' '}
				<span style={{ color: 'Lime' }}>
					<b>gruven</b>
				</span>{' '}
				sammen.{' '}
			</span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Lars, Leif og Lasse liker{' '}
					<span style={{ color: 'Lime' }}>
						<b>diamanter</b>
					</span>
					. De bryter løst lass med{' '}
					<span style={{ color: 'Lime' }}>
						<b>edle</b>
					</span>{' '}
					<span style={{ color: 'Lime' }}>
						<b>steiner</b>
					</span>{' '}
					hver dag, og labber ut av{' '}
					<span style={{ color: 'Lime' }}>
						<b>gruven</b>
					</span>{' '}
					med sekkene fulle. De er latterlig rike, men liker ikke å gi bort penger. «Kan
					jeg få låne en lapp eller to av dere?» ropte Laffen inn i{' '}
					<span style={{ color: 'Lime' }}>
						<b>gruven</b>
					</span>
					. «Jeg lover å betale tilbake!» Han gikk i en hullete langbukse og jakke. «Er du
					lam i hodet eller? Vi lar ikke{' '}
					<span style={{ color: 'Lime' }}>
						<b>late</b>
					</span>{' '}
					og{' '}
					<span style={{ color: 'Lime' }}>
						<b>lurvete</b>
					</span>{' '}
					folk som deg få penger,» sa Lars og lo. «Få deg jobb på{' '}
					<span style={{ color: 'Lime' }}>
						<b>lageret</b>
					</span>
					, du,» sa Leif og{' '}
					<span style={{ color: 'Lime' }}>
						<b>løsnet</b>
					</span>{' '}
					en rubin. «Men jeg trenger litt loff og{' '}
					<span style={{ color: 'Lime' }}>
						<b>pålegg</b>
					</span>{' '}
					i dag. Sønnen min sulter.» Laffen gråt og så på den løse lappen på buksen. «Få
					deg en jobb!» hylte de alle i kor, og det var da{' '}
					<span style={{ color: 'Lime' }}>
						<b>gruven</b>
					</span>{' '}
					raste sammen.
				</p>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hva liker Lars, Leif og Lasse?',
			' - Hva betyr det å være rike?',
			' - Bruker de å dele med andre?',
			' - Hva mener Laffen når han spør om å få låne en lapp eller to av dem?',
			' - Hvordan var klærne til Laffen?',
			' - Hvorfor ville de ikke låne penger til Laffen?',
			' - Hva mente de at han måtte gjøre for å skaffe seg penger?',
			' - Hva er en lærepenge, og hvorfor tror du historien handler om lærepenge?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: [
				'- Edle',
				' - steiner',
				' - diamanter',
				' - rubiner',
				' - gruven',
				' - late',
				' - lurvete',
				' - lageret',
				' - pålegg',
				' - løsnet',
			],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session11 = [
	{
		introAudio: mainAudio_11,
		questionAudio: questions_11,
		wordsAudio: words_11,
		mainHeading: '1. Dagens tekst',
		mainImg: session11Img,
		subHeading: 'Milde måne',
		easySummary: [
			<span>
				Nå er ikke{' '}
				<span style={{ color: 'Lime' }}>
					<b>månen</b>
				</span>{' '}
				langt unna.
			</span>,
			<span>
				Jeg har reist i to{' '}
				<span style={{ color: 'Lime' }}>
					<b>måneder</b>
				</span>
				.
			</span>,
			'Raketten heter ”Milde Måne”.',
			'Det er bare plass til meg og hunden min.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd11[0].revisionWordsAndInstruction.words,
		summary: [
			<span>
				Nå er ikke{' '}
				<span style={{ color: 'Lime' }}>
					<b>månen</b>
				</span>{' '}
				langt unna.,
			</span>,
			<span>
				Jeg har reist i to{' '}
				<span style={{ color: 'Lime' }}>
					<b>måneder</b>
				</span>
				.
			</span>,
			<span>
				Jeg har kommet meg ut av{' '}
				<span style={{ color: 'Lime' }}>
					<b>Melkeveien</b>
				</span>
				.
			</span>,
			<span>
				<span style={{ color: 'Lime' }}>
					<b>Måneraketten</b>
				</span>{' '}
				”Milde Måne” er en merkelig liten rakett.
			</span>,
			'Det er bare plass til meg og hunden min Merkur.',
			'Det er lite mat igjen til Merkur.',
			<span>
				Mange tror at det er vann og luft på{' '}
				<span style={{ color: 'Lime' }}>
					<b>månen</b>
				</span>
				.
			</span>,
			'Jeg håper det, ellers lever jeg ikke lenge.',
			<span>
				Jeg liker ikke at den store{' '}
				<span style={{ color: 'Lime' }}>
					<b>meteoren</b>
				</span>{' '}
				kommer nærmere.
			</span>,
			<span>
				Kræsjer den, vil{' '}
				<span style={{ color: 'Lime' }}>
					<b>månen</b>
				</span>{' '}
				<span style={{ color: 'Lime' }}>
					<b>gå ut av kurs</b>
				</span>
				.
			</span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Nå er ikke{' '}
					<span style={{ color: 'Lime' }}>
						<b>månen</b>
					</span>{' '}
					langt unna. Jeg har reist i to{' '}
					<span style={{ color: 'Lime' }}>
						<b>måneder</b>
					</span>
					, og nå har jeg kommet meg ut av{' '}
					<span style={{ color: 'Lime' }}>
						<b>Melkeveien</b>
					</span>
					. Jeg ble skutt opp 17. mai, og i dag er det 17. juli.{' '}
					<span style={{ color: 'Lime' }}>
						<b>Måneraketten</b>
					</span>{' '}
					”Milde Måne” er en merkelig liten rakett. Det er bare plass til meg og hunden
					min Merkur. Det er lite mat igjen til Merkur, men hvis alt går bra, skal jeg
					lande på M-119 i morgen. Det er navnet på{' '}
					<span style={{ color: 'Lime' }}>
						<b>månen</b>
					</span>{' '}
					utenfor{' '}
					<span style={{ color: 'Lime' }}>
						<b>solsystemet</b>
					</span>{' '}
					vårt. Mange tror at det er vann og luft på{' '}
					<span style={{ color: 'Lime' }}>
						<b>månen</b>
					</span>
					. Jeg håper det, ellers lever jeg ikke lenge. Jeg har bare mat i ni dager til.
					Jeg liker ikke at den store{' '}
					<span style={{ color: 'Lime' }}>
						<b>meteoren</b>
					</span>{' '}
					kommer nærmere{' '}
					<span style={{ color: 'Lime' }}>
						<b>månen</b>
					</span>{' '}
					jeg skal lande på. Jeg har fulgt med på den siden jeg først så den. Kræsjer den,
					vil{' '}
					<span style={{ color: 'Lime' }}>
						<b>månen</b>
					</span>{' '}
					<span style={{ color: 'Lime' }}>
						<b>gå ut av kurs</b>
					</span>
					.
				</p>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hvem er med i fortellingen?',
			' - Hvor lenge har de vært i Melkeveien?',
			' - Husker du når de ble skutt ut i verdensrommet?',
			' - Hva er navnet på månen utenfor solsystemet vårt?',
			' - Hva håper de å finne på månen?',
			' - Hvorfor liker ikke astronauten at den store meteoren kommer nærmere månen de skal lande på?',
			' - Hvorfor blir det et problem dersom månen går ut av kurs?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: [
				' - Måneder',
				' – månen',
				' - Melkeveien',
				' - Månerakett',
				' - Solsystemet',
				' - Meteor',
				' - Gå ut av kurs',
				' - Astronaut',
			],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session12 = [
	{
		introAudio: mainAudio_12,
		questionAudio: questions_12,
		wordsAudio: words_12,
		mainHeading: '1. Dagens tekst',
		mainImg: session12Img,
		subHeading: 'En bølle',
		easySummary: [
			'Ikke slå, ikke slå!» ropte Nils. ',
			'Nikolas stoppet ham på vei hjem.  ',
			'«Gi meg mobilen din, » sa Nikolas.',
			'Nils ble nervøs og svimmel.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd12[0].revisionWordsAndInstruction.words,
		summary: [
			'Ikke slå, ikke slå!» ropte Nils.',
			'Nikolas stoppet ham på vei hjem.',
			<span>
				«Gi meg mobilen, din{' '}
				<span style={{ color: 'Lime' }}>
					<b>nerd</b>
				</span>
				.»
			</span>,
			<span>
				Nikolas skrek i øret til Nils, og{' '}
				<span style={{ color: 'Lime' }}>
					<b>knyttet neven</b>
				</span>
				.
			</span>,
			<span>
				Nils ble{' '}
				<span style={{ color: 'Lime' }}>
					<b>nervøs</b>
				</span>{' '}
				og svimmel.
			</span>,
			<span>
				«Jeg har den ikke med meg. Du kan få{' '}
				<span style={{ color: 'Lime' }}>
					<b>nistepakken</b>
				</span>{' '}
				min!»
			</span>,
			<span>
				«
				<span style={{ color: 'Lime' }}>
					<b>Nisten</b>
				</span>{' '}
				din?{' '}
				<span style={{ color: 'Lime' }}>
					<b>Holder du meg for narr</b>
				</span>
				, din nisse?»
			</span>,
			'«Da gir du meg alle pengene dine!» Nils nikket.',
			<span>
				«Stopp nå med en gang,» sa{' '}
				<span style={{ color: 'Lime' }}>
					<b>naboen</b>
				</span>
				, og tok Nikolas i armen. «Driver du og{' '}
				<span style={{ color: 'Lime' }}>
					<b>bøller</b>
				</span>{' '}
				i dag, også?»
			</span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Ikke slå, ikke slå!» ropte Nils. Nikolas hadde stoppet ham på vei hjem fra{' '}
					<span style={{ color: 'Lime' }}>
						<b>naboen</b>
					</span>
					. Det var midt på dagen. «Gi meg mobilen, din{' '}
					<span style={{ color: 'Lime' }}>
						<b>nerd</b>
					</span>
					.» Nikolas skrek i øret til Nils, og{' '}
					<span style={{ color: 'Lime' }}>
						<b>knyttet neven</b>
					</span>
					. Nils ble{' '}
					<span style={{ color: 'Lime' }}>
						<b>nervøs</b>
					</span>{' '}
					og svimmel som i går, da Nikolas hadde slått ham på nesen. «Nei, jeg kan ikke,»
					sa Nils. «Jeg har den ikke med meg, men du kan få{' '}
					<span style={{ color: 'Lime' }}>
						<b>nistepakken</b>
					</span>{' '}
					min!» «
					<span style={{ color: 'Lime' }}>
						<b>Nisten</b>
					</span>{' '}
					din?{' '}
					<span style={{ color: 'Lime' }}>
						<b>Holder du meg for narr</b>
					</span>{' '}
					, din nisse?» «Det er alt jeg har.{' '}
					<span style={{ color: 'Lime' }}>
						<b>Æresord</b>
					</span>
					!» sa Nils, nesten på gråten. «Da gir du meg alle pengene dine!» Nils nikket.
					Han{' '}
					<span style={{ color: 'Lime' }}>
						<b>skalv</b>
					</span>{' '}
					da han hentet opp ni kroner fra lommen. «Stopp nå med en gang,» sa{' '}
					<span style={{ color: 'Lime' }}>
						<b>naboen</b>
					</span>
					, og tok Nikolas i armen. «Driver du og{' '}
					<span style={{ color: 'Lime' }}>
						<b>bøller</b>
					</span>{' '}
					i dag, også?»
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hvem er bøllen i fortellingen?',
			' - Hvor hadde Nils vært da han ble stoppet av Nikolas?',
			' - Hva ville Nikolas ha hos Nils?',
			' - Hvorfor kunne ikke Nils gi ham den?',
			' - Hva ville han gi Nikolai istedenfor?',
			' - Hva skjedde videre?',
			' - Hva tror du skjedde med Nikolas da naboen kom til?',
			' - Når tid på dagen skjedde dette?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: [
				'- Nabo',
				' - Nerd',
				' - Knytte neven',
				' - Nervøs',
				' - Niste',
				' - nistepakke',
				' - Holde for narr',
				' - Skalv',
				' - Æresord',
				' - Bøller',
			],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session13 = [
	{
		introAudio: mainAudio_13,
		questionAudio: questions_13,
		wordsAudio: words_13,
		mainHeading: '1. Dagens tekst',
		mainImg: session13Img,
		subHeading: 'Potetgull?',
		easySummary: [
			'Petrus satte flagget i sanden.',
			'«Er du sikker på at skatten er her?» sa Pippi.',
			'«Øya er jo så stor.»',
			'«Bare se på skattekartet,» sa Petrus',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd13[0].revisionWordsAndInstruction.words,
		summary: [
			<span>
				«Sett flagget fra Perlen der,» sa{' '}
				<span style={{ color: 'Lime' }}>
					<b>piraten</b>
				</span>{' '}
				Pondus.
			</span>,
			<span>Petrus satte flagget i sanden.</span>,
			<span>«Er du sikker på at skatten er her?» sa Pippi.</span>,
			<span>«Øya er jo så stor.»</span>,
			<span>«Bare se på skattekartet,» sa Petrus og pekte med en pistol.</span>,
			<span>
				Pippi nikket. Hun startet å grave og kort tid etter{' '}
				<span style={{ color: 'Lime' }}>
					<b>smalt</b>
				</span>{' '}
				det i tre.
			</span>,
			<span>«Det må være kisten,» sa Petrus.</span>,
			<span>
				Pondus{' '}
				<span style={{ color: 'Lime' }}>
					<b>falt pladask</b>
				</span>{' '}
				med nesa først i sanden.
			</span>,
			<span>«Potet, potet, potet!» skrek Polly, da de åpnet kisten.</span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					«Sett flagget fra Perlen der,» sa{' '}
					<span style={{ color: 'Lime' }}>
						<b>piraten</b>
					</span>{' '}
					Pondus og pekte med{' '}
					<span style={{ color: 'Lime' }}>
						<b>kroken</b>
					</span>
					. Petrus satte flagget i sanden og{' '}
					<span style={{ color: 'Lime' }}>
						<b>knytta</b>
					</span>{' '}
					fast pannebåndet. «Er du sikker på at skatten er her?» sa Pippi og klødde{' '}
					<span style={{ color: 'Lime' }}>
						<b>papegøyen</b>
					</span>{' '}
					Polly på nebbet. «Øya er jo så stor.» Pondus klødde seg i pannen og{' '}
					<span style={{ color: 'Lime' }}>
						<b>prikket</b>
					</span>{' '}
					seg på kinnet. «Bare se på skattekartet,» sa Petrus og pekte med en pistol.
					Pippi nikket. Hun startet å grave og kort tid etter{' '}
					<span style={{ color: 'Lime' }}>
						<b>smalt</b>
					</span>{' '}
					det i tre. Pippi{' '}
					<span style={{ color: 'Lime' }}>
						<b>kikket</b>
					</span>{' '}
					på Petrus. «Det må være kisten,» sa Petrus. Pondus{' '}
					<span style={{ color: 'Lime' }}>
						<b>falt pladask</b>
					</span>{' '}
					med nesa først i sanden, og{' '}
					<span style={{ color: 'Lime' }}>
						<b>bannet</b>
					</span>{' '}
					på polsk. «Potet, potet, potet!» skrek Polly, da de åpnet kisten.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hvem er med i fortellingen?',
			' - Hva leter de etter?',
			' - Hvordan hørte de at de hadde truffet kista?',
			' - Hva fant de i kista?',
			' - Ble de glad eller skuffet, tror du?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: [
				'- Piraten',
				' - kroken',
				' - knytta',
				' - kikket',
				' - prikket',
				' - smalt',
				' - papegøye',
				' - falt pladask',
				' - bannet',
			],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session14 = [
	{
		introAudio: mainAudio_14,
		questionAudio: questions_14,
		wordsAudio: words_14,
		mainHeading: '1. Dagens tekst',
		mainImg: session14Img,
		subHeading: 'Blant runer og røvere',
		easySummary: [
			'Sjefen sa at vi må holde vakt her.',
			'Røverne kan komme.',
			'Vi må passe på veien til i morgen.',
			'«Dette er et ran! » roper røverne.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd14[0].revisionWordsAndInstruction.words,
		summary: [
			<span>«Hva er det du gjør?» lurer Roar på.</span>,
			<span>
				Rune{' '}
				<span style={{ color: 'Lime' }}>
					<b>risser</b>
				</span>{' '}
				<span style={{ color: 'Lime' }}>
					<b>runer</b>
				</span>{' '}
				mens Roar ser på.
			</span>,
			<span>
				Roar synes{' '}
				<span style={{ color: 'Lime' }}>
					<b>runer</b>
				</span>{' '}
				er rare greier.
			</span>,
			<span>«Vi skal ikke heller røve fra rike bønder?» sier Roar.</span>,
			<span>«Nei, sjefen sa at vi må holde vakt her,» sier Rune. </span>,
			<span>«Røverne kan komme».</span>,
			<span>Vi må passe på veien til i morgen.</span>,
			<span>
				«Dette er et{' '}
				<span style={{ color: 'Lime' }}>
					<b>ran</b>
				</span>
				! Slipp våpnene!» roper sju røvere.
			</span>,
			<span>
				«Ja, nå får vi et godt{' '}
				<span style={{ color: 'Lime' }}>
					<b>rykte</b>
				</span>
				,» sa Roar og rødmet.
			</span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					«Hva er det du gjør?» lurer Roar på. Rune{' '}
					<span style={{ color: 'Lime' }}>
						<b>risser</b>
					</span>{' '}
					<span style={{ color: 'Lime' }}>
						<b>runer</b>
					</span>{' '}
					mens Roar ser på. Roar synes{' '}
					<span style={{ color: 'Lime' }}>
						<b>runer</b>
					</span>{' '}
					er rare greier. Rune kaster tre rosa{' '}
					<span style={{ color: 'Lime' }}>
						<b>runesteiner</b>
					</span>{' '}
					på gresset og tenker. I hånden holder han en rød{' '}
					<span style={{ color: 'Lime' }}>
						<b>runepinne</b>
					</span>{' '}
					av gran og leser høyt. «Vi skal ikke heller røve fra rike bønder da?» sier Roar.
					«Dette er jo{' '}
					<span style={{ color: 'Lime' }}>
						<b>kjipt</b>
					</span>
					!» «Nei, sjefen sa at vi må holde vakt her,» sier Rune. «Røverne kan jo komme
					når som helst. Vi må passe på veien til i morgen. Vi har jo et{' '}
					<span style={{ color: 'Lime' }}>
						<b>rykte</b>
					</span>{' '}
					å ta vare på!» «Dette er et{' '}
					<span style={{ color: 'Lime' }}>
						<b>ran</b>
					</span>
					! Slipp våpnene, ellers er dere ferdige!» roper sju røvere som nå har{' '}
					<span style={{ color: 'Lime' }}>
						<b>omringet</b>
					</span>{' '}
					dem. «Ja, nå får vi et godt{' '}
					<span style={{ color: 'Lime' }}>
						<b>rykte</b>
					</span>
					,» sa Roar og rødmet.
				</p>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hvem er Roar og Rune?',
			' - Hva holder Rune på med?',
			' - Hva foreslår Roar at de skal gjøre istedenfor?',
			' - Hva hadde de fått beskjed om å gjøre?',
			' - Hva skjedde?',
			' - Hvorfor ble Roar flau og rødmet tror du?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: [
				'- Risser',
				'- Runer',
				'- Runestein',
				'- Runepinne',
				'- Kjipt',
				'- Rykte',
				'- Ran',
				'- Omringet',
			],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session15 = [
	{
		introAudio: mainAudio_15,
		questionAudio: questions_15,
		wordsAudio: words_15,
		mainHeading: '1. Dagens tekst',
		mainImg: session15Img,
		subHeading: 'Sutrekoppen tømmer rommet',
		easySummary: [
			'Svein er ikke som andre riddere.',
			'De andre svinger sverdet.',
			'Svein er feig.',
			'Han har et virus.',
			'Plutselig er alle borte.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd15[0].revisionWordsAndInstruction.words,
		summary: [
			<span>
				Svein er ikke som andre{' '}
				<span style={{ color: 'Lime' }}>
					<b>riddere</b>
				</span>{' '}
				i Sumpland.
			</span>,
			<span>De andre svinger sverdet mot skurker.</span>,
			<span>
				Svein er{' '}
				<span style={{ color: 'Lime' }}>
					<b>feig</b>
				</span>
				.
			</span>,
			<span>
				Svein er sulten, og rir mot et{' '}
				<span style={{ color: 'Lime' }}>
					<b>spisested</b>
				</span>
				.
			</span>,
			<span>
				Han har{' '}
				<span style={{ color: 'Lime' }}>
					<b>feber</b>
				</span>{' '}
				og er svett.
			</span>,
			<span>Han seg ved et stort spisebord.</span>,
			<span>Ingen liker Svein, så flere bøller lover ham bank.</span>,
			<span>«Ikke skad meg,» sier Svein.</span>,
			<span>Svein nyser så snørr lander på nesa til den stygge bøllen.</span>,
			<span>
				«Er du syk?» spør han. «Ja, jeg tror jeg har fått et{' '}
				<span style={{ color: 'Lime' }}>
					<b>virus</b>
				</span>
				,» sier Svein.
			</span>,
			<span>Plutselig er alle borte.</span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Svein er ikke som andre{' '}
					<span style={{ color: 'Lime' }}>
						<b>riddere</b>
					</span>{' '}
					i Sumpland. De andre svinger sverdet mot skurker og sinte fiender. Svein er{' '}
					<span style={{ color: 'Lime' }}>
						<b>feig</b>
					</span>
					. Han blir kalt for en{' '}
					<span style={{ color: 'Lime' }}>
						<b>sviker</b>
					</span>{' '}
					og en{' '}
					<span style={{ color: 'Lime' }}>
						<b>sutrekopp</b>
					</span>
					. Svein er sulten, og rir mot et{' '}
					<span style={{ color: 'Lime' }}>
						<b>spisested</b>
					</span>
					. Han har{' '}
					<span style={{ color: 'Lime' }}>
						<b>feber</b>
					</span>{' '}
					og er svett. Når han kommer frem setter han seg ved et stort spisebord som er
					ledig. Han får{' '}
					<span style={{ color: 'Lime' }}>
						<b>sure blikk</b>
					</span>{' '}
					fra folk rundt seg. Ingen liker Svein, så flere bøller går mot ham og lover ham
					bank etter han har spist. «Ikke skad meg,» sier Svein og{' '}
					<span style={{ color: 'Lime' }}>
						<b>skjelver</b>
					</span>{' '}
					litt. Med ett nyser han så snørr lander på nesa til den stygge bøllen med de
					store musklene. «Er du syk?» spør han. «Ja, jeg tror jeg har fått et{' '}
					<span style={{ color: 'Lime' }}>
						<b>virus</b>
					</span>
					,» sier Svein. Han hoster og{' '}
					<span style={{ color: 'Lime' }}>
						<b>sikler</b>
					</span>
					. Når han åpner øynene, er alle borte.
				</p>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hvor bor ridderne?',
			' - Hvorfor er ikke Svein som de andre ridderne?',
			' - Hva kaller de andre ham?',
			' - Hvorfor rir han mot et spisested?',
			' - Hva skjer når han kommer frem?',
			' - HvorHvorfor skjelver han?',
			' - Hvordan ser den store bøllen ut?',
			' - Hvorfor forsvinner alle ridderne?',
			' - Denne fortellingen heter «Sutrekoppen tømmer rommet». Hvorfor gjør den det, tror du?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: [
				'- ridder',
				'- sviker',
				'- feig',
				'- sutrekopp',
				'- spisested',
				'- feber',
				'- sure blikk',
				'- skjelver',
				'- virus',
				'- sikler',
			],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session16 = [
	{
		introAudio: mainAudio_16,
		questionAudio: questions_16,
		wordsAudio: words_16,
		mainHeading: '1. Dagens tekst',
		mainImg: session16Img,
		subHeading: 'Tannfeen får det travelt',
		easySummary: [
			'«Gap opp!» sa tannlegen.',
			'«Når pusset du tennene dine.»',
			'«For tolv år siden» sa Truls.',
			'«Hva er det du sier?» sa tannlegen.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd16[0].revisionWordsAndInstruction.words,
		summary: [
			<span>
				«
				<span style={{ color: 'Lime' }}>
					<b>Gap opp</b>
				</span>
				!» sa Tomas til sin{' '}
				<span style={{ color: 'Lime' }}>
					<b>pasient</b>
				</span>
				.
			</span>,
			<span>Han var tannlege.</span>,
			<span>«Når pusset du tennene dine sist?» lurte Tomas på.</span>,
			<span>
				«Sist jeg var på{' '}
				<span style={{ color: 'Lime' }}>
					<b>Tivoli»</b>
				</span>{' '}
				sa Truls «For tolv år siden.»
			</span>,
			<span>«Hva er det du sier?» sa tannlegen.</span>,
			<span>
				«Du har en tann som er{' '}
				<span style={{ color: 'Lime' }}>
					<b>råtten</b>
				</span>
				.»
			</span>,
			<span>«Du har dårlig tannhelse, alle tennene må trekkes»</span>,
			<span>Det blir travelt for tannfeen» sa Tomas trist.</span>,
			<span>
				«Avlys alle andre{' '}
				<span style={{ color: 'Lime' }}>
					<b>pasienter</b>
				</span>{' '}
				for i dag, og hent et{' '}
				<span style={{ color: 'Lime' }}>
					<b>gebiss»</b>
				</span>
				, sa tannlegen til{' '}
				<span style={{ color: 'Lime' }}>
					<b>pleieren</b>
				</span>
				.
			</span>,
			<span>«Dette vil ta minst tre timer!»</span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					«
					<span style={{ color: 'Lime' }}>
						<b>Gap opp</b>
					</span>
					!» sa Tomas til sin{' '}
					<span style={{ color: 'Lime' }}>
						<b>pasient</b>
					</span>
					. Han var en tannlege og trengte ikke å se så nøye på tennene til Truls. Han
					trakk pusten og sukket. «Når pusset du tennene dine sist med tannkrem?» lurte
					Tomas på. «Tja, sist jeg var på{' '}
					<span style={{ color: 'Lime' }}>
						<b>Tivoli</b>
					</span>
					, tror jeg,» sa Truls og gliste. «Tenker du på{' '}
					<span style={{ color: 'Lime' }}>
						<b>tivoliet</b>
					</span>{' '}
					som er i byen nå?» sa tannlegen. «Nei, det som var i byen for tolv år siden.»
					«Hva er det du sier, Truls? Du har en tann som er{' '}
					<span style={{ color: 'Lime' }}>
						<b>råtten</b>
					</span>
					.» Tomas pirket litt på den. «Du har dårlig tannhelse!» «Bare en tann?» sa
					Truls. «Ja. En er{' '}
					<span style={{ color: 'Lime' }}>
						<b>råtten</b>
					</span>
					. Men alle tenner må også trekkes. Det blir travelt for tannfeen, dette,» sa
					Tomas trist, og ba{' '}
					<span style={{ color: 'Lime' }}>
						<b>pleieren</b>
					</span>
					om te fra tekannen. «Avlys alle andre{' '}
					<span style={{ color: 'Lime' }}>
						<b>pasienter</b>
					</span>{' '}
					for i dag, og hent et{' '}
					<span style={{ color: 'Lime' }}>
						<b>gebiss</b>
					</span>
					. Dette vil ta minst tre timer!»,
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hvem er Tomas?',
			' - Hva heter pasienten?',
			' - Når pusset Truls tennene sine sist, og hvor var han da?',
			' - Hva mener tannlegen når han sier at Truls har dårlig tannhelse?',
			' - Hvorfor blir det travelt for tannfeen, tror du?',
			' - Hvorfor ber Tomas pleieren om at alle andre pasienter må avlyses den dagen?',
			' - Hvorfor må Truls få gebiss?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Pasient', '- Gap opp', '- Tivoli', '- Råtten', '- Pleieren', '- Avlyst', '- gebiss'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session17 = [
	{
		introAudio: mainAudio_17,
		questionAudio: questions_17,
		wordsAudio: words_17,
		mainHeading: '1. Dagens tekst',
		mainImg: session17Img,
		subHeading: 'En veslevoksen viktigper',
		easySummary: [
			'«Angrip veikingen.',
			'Det er ingen som holder vakt!» ropte den ene vikingen.',
			'Han var vill i blikket.',
			'De løp mot Viktor som solte seg.',
		],
		phonemeData: [],
		DaysTextWords: wordsInTheEnd17[0].revisionWordsAndInstruction.words,
		summary: [
			<span>
				«
				<span style={{ color: 'Lime' }}>
					<b>Angrip</b>
				</span>{' '}
				<span style={{ color: 'Lime' }}>
					<b>veikingen</b>
				</span>
				. Det er ingen som holder vakt!» ropte den ene{' '}
				<span style={{ color: 'Lime' }}>
					<b>vikingen</b>
				</span>
				.
			</span>,
			<span>
				Han var vill i{' '}
				<span style={{ color: 'Lime' }}>
					<b>blikket</b>
				</span>
				.
			</span>,
			<span>
				De løp mot Viktor som solte seg. «Dere er da ikke{' '}
				<span style={{ color: 'Lime' }}>
					<b>vikinger</b>
				</span>
				,» sa Viktor og lo rått.
			</span>,
			<span>
				«
				<span style={{ color: 'Lime' }}>
					<b>Vrøvl og vås</b>
				</span>
				. At du{' '}
				<span style={{ color: 'Lime' }}>
					<b>våger</b>
				</span>
				. Vi er{' '}
				<span style={{ color: 'Lime' }}>
					<b>vikinger</b>
				</span>
				!»
			</span>,
			<span>
				«En{' '}
				<span style={{ color: 'Lime' }}>
					<b>viking</b>
				</span>{' '}
				har ikke horn på hjelmen sin,» sa Viktor.
			</span>,
			<span>
				«Dere er noen{' '}
				<span style={{ color: 'Lime' }}>
					<b>vattnisser</b>
				</span>
				. Tykke er dere også.
			</span>,
			<span>Har dere spist for mye vafler?»</span>,
			<span>«For pokker da!» sa Vilde.</span>,
			<span>
				Hvorfor måtte vi møte på en liten,{' '}
				<span style={{ color: 'Lime' }}>
					<b>veslevoksen</b>
				</span>{' '}
				<span style={{ color: 'Lime' }}>
					<b>viktigper</b>
				</span>{' '}
				på vårt første{' '}
				<span style={{ color: 'Lime' }}>
					<b>tokt</b>
				</span>
				?
			</span>,
			<span>Jeg slutter!»</span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					«
					<span style={{ color: 'Lime' }}>
						<b>Angrip</b>
					</span>{' '}
					<span style={{ color: 'Lime' }}>
						<b>veikingen</b>
					</span>
					. Det er ingen som holder vakt!» ropte den ene{' '}
					<span style={{ color: 'Lime' }}>
						<b>vikingen</b>
					</span>
					, og var vill i{' '}
					<span style={{ color: 'Lime' }}>
						<b>blikket</b>
					</span>
					. De hadde gått av{' '}
					<span style={{ color: 'Lime' }}>
						<b>vikingskipet</b>
					</span>
					. Nå løp de mot Viktor som lå og solte seg på stranden i Viken. Det var som om
					de vokste seg større da de kom frem, og alle hadde{' '}
					<span style={{ color: 'Lime' }}>
						<b>mistet vettet</b>
					</span>
					. «Dere er da ikke{' '}
					<span style={{ color: 'Lime' }}>
						<b>vikinger</b>
					</span>
					,» sa Viktor og lo rått. «
					<span style={{ color: 'Lime' }}>
						<b>Vrøvl og vås</b>
					</span>
					. At du{' '}
					<span style={{ color: 'Lime' }}>
						<b>våger</b>
					</span>
					. Vi er{' '}
					<span style={{ color: 'Lime' }}>
						<b>vikinger</b>
					</span>
					!» «En{' '}
					<span style={{ color: 'Lime' }}>
						<b>viking</b>
					</span>{' '}
					har ikke horn på hjelmen sin,» sa Viktor. «Dere er noen{' '}
					<span style={{ color: 'Lime' }}>
						<b>vattnisser</b>
					</span>
					. Tykke er dere også. Har dere spist for mye vafler? Dere veier for mye.» «For
					pokker da!» sa Vilde. «Det var jo det jeg sa! Hvorfor måtte vi møte på en liten,{' '}
					<span style={{ color: 'Lime' }}>
						<b>veslevoksen</b>
					</span>{' '}
					<span style={{ color: 'Lime' }}>
						<b>viktigper</b>
					</span>{' '}
					på vårt første{' '}
					<span style={{ color: 'Lime' }}>
						<b>tokt</b>
					</span>
					? Jeg slutter!»,
				</p>
			</span>
		),

		repeatParaHeading: (
			<span className='rw-extra-text'>
				<span>
					2. Narrativ.
					<span>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hva ropte den ene vikingen da de hadde gått av vikingskipet?',
			' - Hvem var det de kalte veikingen?',
			' - Hva gjorde han som de kalte veikingen, da vikingene løp mot ham?',
			' - Hvorfor mente han at de ikke var vikinger?',
			' - Hva kalte han dem tilbake?',
			' - Hvem er Vilde, og hvorfor ville hun slutte allerede etter sitt første tokt?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: [
				'- Angrip',
				' - Veiking',
				' - Viking',
				' - Blikket',
				' - Miste vettet',
				' - Vrøvl og vås',
				' - Våger',
				' - Vattnisse',
				' - Veslevoksen',
				' - Viktigper',
				' - Tokt',
			],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session18 = [
	{
		introAudio: mainAudio_18,
		questionAudio: questions_18,
		wordsAudio: words_18,
		mainHeading: '1. Dagens tekst',
		mainImg: session18Img,
		subHeading: 'Halloween',
		phonemeData: phoneme18,
		DaysTextWords: wordsInTheEnd18[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Noen sier knask eller knep.',
			'Barna vil ha godteri.',
			'Noen barn kler seg ut.',
			'Får de ikke godteri, blir det knep.',
		],
		summary: [
			'«31 . oktober sier mange: knask eller knep!',
			'Det er dagen før allehelgen.',
			'Da feirer vi halloween.',
			'I Norge startet vi å feire halloween rundt år 2000.',
			'Halloween kom til oss fra USA.',
			'Egentlig er den fra Irland og Skottland.',
			'Mange barn kler seg ut.',
			'Noen som spøkelser, vampyrer og superhelter.',
			'De banker på og roper: Knask eller knep!',
			'Det er en trussel. Barna vil ha godteri.',
			'Har de ikke godteri, blir det knep.',
			'Mange pynter med gresskar.',
			'De som ikke vil ha besøk slukker lyset.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Hver høst, den 31 . oktober sier mange barn:{' '}
					<span style={{ color: 'Lime' }}>
						<b>knask</b>
					</span>{' '}
					eller{' '}
					<span style={{ color: 'Lime' }}>
						<b>knep</b>
					</span>
					! Det er dagen før allehelgensdag. Mange barn gleder seg hver høst. Da feirer vi
					halloween. På denne dagen feirer vi de døde. I Norge startet vi å feire
					halloween rundt år 2000. Halloween kom til oss fra USA. Men det var ikke her de
					startet med
					<span style={{ color: 'Lime' }}>
						<b> skikken</b>
					</span>
					. Egentlig kommer den fra Irland og Skottland. Mange barn kler seg ut. Noen som
					spøkelser vampyrer og superhelter. Barn går rundt og banker på dører og roper:
					Knask eller knep! Det er egentlig en{' '}
					<span style={{ color: 'Lime' }}>
						<b>trussel</b>
					</span>
					. Barn vil ha godteri, ellers får det følger. Har de ikke godteri, blir det
					knep. Da gjør de fantestreker. Mange pynter med gresskar og andre ting som skal
					skremme. De som ikke vil ha besøk slukker utelyset. Det er lurt. Mange er jo
					ikke hjemme.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hvilken dag er 31 .oktober?',
			' - Hva feirer vi på Halloween?',
			' - Hvor kommer skikken fra?',
			' - Hva gjør barn på Halloween?',
			' - Hva betyr det hvis utelyset er slukket? ',
			' - Er halloween gøy, eller kan noe også være dumt?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Skikk', ' - Knask', ' - Knep', ' - Trussel'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session19 = [
	{
		introAudio: mainAudio_19,
		questionAudio: questions_19,
		wordsAudio: words_19,
		mainHeading: '1. Dagens tekst',
		mainImg: session19Img,
		subHeading: 'Trekkfugler',
		phonemeData: phoneme19,
		DaysTextWords: wordsInTheEnd19,
		DaysTextWords: wordsInTheEnd19[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Dyr må ha mat og varme.',
			'Dagene blir kortere om vinteren.',
			'Været blir kaldere.',
			'Fuglene flyr mot sør når det blir høst.',
		],
		summary: [
			'«Dyr trenger mat og varme.',
			'Noen fugler flyr veldig langt hvert år for å finne mat og varmt vær.',
			'Vi kaller de for trekkfugler',
			<b>Hvorfor trekker fugler sørover?</b>,
			'Mange fugler kan ikke leve i nord om vinteren.',
			'Det er for kaldt. Det er mindre mat å finne også.',
			'De flyr sørover når vinteren nærmer seg.',
			<b>Hvordan vet fugler når de skal fly sørover?</b>,
			'Fuglene merker endringer i miljøet sitt.',
			'Dagene blir kortere. Været blir kaldere.',
			'Det blir vanskeligere for fuglene å finne mat.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Dyr trenger mat og varme – akkurat som mennesker. Noen fugler flyr veldig langt
					hvert år for å finne mat og varme. Vi kaller disse fuglene for{' '}
					<span style={{ color: 'Lime' }}>
						<b>trekkfugler</b>
					</span>
					. Hvorfor trekker fugler sørover?{' '}
					<span style={{ color: 'Lime' }}>
						<b>Fugletrekk</b>
					</span>{' '}
					er viktig. Det hjelper fuglene til å
					<span style={{ color: 'Lime' }}>
						<b> overleve</b>
					</span>
					. Mange fugler flyr fra nord til sør når vinteren er nær. De kan ikke bli når
					været blir for kaldt. I tillegg liker fuglene å spise ting som insekter,
					amfibier og planter. Om vinteren er det mindre av denne maten å leve av. Visste
					du? For lenge siden visste ikke folk hvor fuglene ble av. Noen sa at fuglene var
					i dvale for vinteren . Andre sa at fuglene ble til fisk! Hvordan vet fuglene når
					det er på tide å trekke sørover? Noen forskere sier at fuglene merker{' '}
					<span style={{ color: 'Lime' }}>
						<b>endringer </b>
					</span>
					i miljøet sitt. De merker at dagene blir kortere og at temperaturen synker. Det
					blir vanskeligere for fuglene å finne mat.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hvorfor heter noen fugler trekkfugler?',
			' - Kan du gi to grunner til at fuglene trekker sørover?',
			' - Hvorfor tror du det blir vanskeligere for fuglene å finne mat?',
			' - Hvordan vet fuglene at de skal trekke sørover?',
			' - Hvor tror du fuglene er om vinteren?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Trekkfugler', ' - Fugletrekk', ' - Overleve', ' - Endringer'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session20 = [
	{
		introAudio: mainAudio_20,
		questionAudio: questions_20,
		wordsAudio: words_20,
		mainHeading: '1. Dagens tekst',
		mainImg: session20Img,
		subHeading: 'Trekkfugler 2.',
		phonemeData: phoneme20,
		DaysTextWords: wordsInTheEnd20[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Fuglene flyr i flokk.',
			'De flyr i V-formasjon.',
			'De blir ikke så slitne. ',
			'Fuglene flyr hjem når det blir vår.',
		],
		summary: [
			<b>Hvordan kan fuglene fly så langt? </b>,
			'Fuglene tar ikke turen på én dag.',
			<p>
				{'De flyr i'} <b>V-formasjon</b>
			</p>,
			'Hunder er fine dyr.',
			'Den sterkeste fuglen er i front av V-en.',
			'Resten av fuglene følger etter i to linjer.',
			'Å fly på denne måten er lettere for fuglene bak.',
			'De blir ikke så slitne.',
			'Når fuglen i front blir sliten bytter den plass med en annen fugl.',
			<b>Hvorfor trekker fuglene tilbake nordover?</b>,
			'Det er mye mat til alle fuglene når det er sommer i sør.',
			'Det blir mindre mat om vinteren.',
			'Derfor flyr de nordlige fuglene hjem igjen.',
			'De flyr nordover når vinteren er over.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					<b style={{ color: 'Lime', fontSize: '23px' }}>
						Hvordan kan fuglene fly så langt?{' '}
					</b>
				</p>
				<p style={{ fontSize: '22px' }}>
					For det første tar de ikke hele reisen på ‘en dag. For det andre flyr de i
					grupper eller i{' '}
					<span style={{ color: 'Lime' }}>
						<b>flokk</b>
					</span>
					. De flyr også i en
					<b style={{ color: 'Lime', fontSize: '23px' }}>{` V-formasjon.`}</b>
				</p>
				<p style={{ fontSize: '22px' }}>
					Den sterkeste fuglen flyr først{' '}
					<span style={{ color: 'Lime' }}>
						<b>i front</b>
					</span>{' '}
					av V-en. Resten av flokken følger i to linjer bak. Å fly på denne måten hjelper
					fuglene å{' '}
					<span style={{ color: 'Lime' }}>
						<b>redusere</b>
					</span>{' '}
					luftmotstanden. Fuglene bak blir ikke så slitne. Når ledefuglen blir sliten
					bytter den plass med en annen fugl. Hvorfor trekker fuglene tilbake nordover?
					Det er rikelig med mat til alle fuglene når det er sommer i sør. Det er mindre
					mat om vinteren. Dette er grunnen til at de nordlige fuglene begynner å fly hjem
					når vinteren nærmer seg i sør. De foretrekker også å hekke i sine naturlige
					klima.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hvordan klarer fuglene å fly så langt?',
			' - Hva er V-formasjon?',
			' - Hvorfor flyr fuglene i V-formasjon?',
			' - Hva gjør ledefuglen når den blir sliten?',
			' - Hvorfor trekker fuglene tilbake nordover?',
			' - Hvorfor tror du fuglene flyr i grupper eller flokk?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Flokk', '- V-formasjon ', '- I front ', '- Redusere'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];

export const session21 = [
	{
		introAudio: mainAudio_21,
		questionAudio: questions_21,
		wordsAudio: words_21,
		mainHeading: '1. Dagens tekst',
		mainImg: session21Img,
		subHeading: 'Forente nasjoner',
		phonemeData: phoneme21,
		DaysTextWords: wordsInTheEnd21[0].revisionWordsAndInstruction.words,
		easySummary: [
			'FN har viktige oppgaver:',
			'1. Bevare fred. ',
			'2. Hjelpe land å være venner.',
			'3. Hjelpe syke og fattige mennesker.',
		],
		summary: [
			'Hva er de Forente nasjoner? ',
			'Det er vanlig å kalle dem FN.',
			'FN jobber for at alle skal ha det trygt.',
			'Det ble startet i 1945 av 51 land som ikke likte krig.',
			'De ble enige om hvordan FN skulle jobbe.',
			'Det er 193 medlemsland i FN i dag.',
			'FN har viktige oppgaver:',
			'1. Bevare fred.',
			'2. Hjelpe land å være venner.',
			'3. Hjelpe syke og fattige mennesker.',
			'4. Jobbe sammen for å løse oppgaver.',
			'Mange mennesker jobber i FN. De arbeider i grupper.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Hva er de{' '}
					<span style={{ color: 'Lime' }}>
						<b>Forente nasjoner</b>
					</span>{' '}
					? De{' '}
					<span style={{ color: 'Lime' }}>
						<b>Forente nasjoner</b>
					</span>{' '}
					nasjoner er en stor samling av stater. Det vanligste er å kalle dem FN. FN
					jobber for å gjøre veden til et tryggere sted. FN startet for lenge siden. Det
					ble startet av 51 land i 1945 som ikke likte krig og{' '}
					<span style={{ color: 'Lime' }}>
						<b>konflikter</b>
					</span>
					. De ble enige om hvordan FN skulle jobbe. Det er 193 medlemsland i FN i dag.
					Hovedkontoret ligger i New York.
				</p>
				<p style={{ fontSize: '22px' }}>
					<b>{`FN har viktige oppgaver:`}</b>
				</p>
				<p style={{ fontSize: '22px' }}>
					1.{' '}
					<span style={{ color: 'Lime' }}>
						<b>Bevare</b>
					</span>{' '}
					fred.
				</p>
				<p style={{ fontSize: '22px' }}>2. Hjelpe land å være venner.</p>
				<p style={{ fontSize: '22px' }}>3. Hjelpe land med å løse problemene sine.</p>
				<p
					style={{ fontSize: '22px' }}
				>{`4. Jobbe sammen for å løse oppgaver. Mange mennesker jobber i FN.`}</p>
				<p style={{ fontSize: '22px' }}>
					De arbeider i grupper. En gruppe bestemmer hva FN skal gjøre. En annen gruppe
					hjelper med å bevare fred og gjøre verden tryggere. Det er en gruppe som hjelper
					syke og fattige mennesker. De er også en gruppe som hjelper statene å følge
					lovene.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Hva er Forente nasjoner?',
			' - Hva er vanlig å kalle dem? ',
			' - Når ble FN startet? ',
			' - Hvorfor tror du FN ble startet?',
			' - Hva jobber FN med?',
			' - Hvordan jobber FN?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Forente', ' - Nasjoner', ' - Bevare', '- Konflikter'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session22 = [
	{
		introAudio: mainAudio_22,
		questionAudio: questions_22,
		wordsAudio: words_22,
		mainHeading: '1. Dagens tekst',
		mainImg: session22Img,
		subHeading: 'Vaksine',
		phonemeData: phoneme22,
		DaysTextWords: wordsInTheEnd22[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Det er ikke moro å være syk.',
			'Det er mye bedre å være frisk!',
			'Du kan få sprøyte.',
			'Det kan gjøre litt vondt.',
		],
		summary: [
			'Det er ikke moro å være syk. ',
			'Det er mye bedre å være frisk! ',
			'Det er mange måter å holde seg frisk på.  ',
			'En måte er å få sprøyte hos legen eller hos helsesykepleier. ',
			'Det kalles å få en injeksjon. ',
			'Det kan gjøre litt vondt. ',
			'Men det viktig for kroppen din. ',
			'Hva er en injeksjon? ',
			'Injeksjonen har en vaksine i seg.  ',
			'Det er en slags medisin. ',
			'Den gjør at farlige mikrober ikke kan skade deg.  ',
			'Farlige mikrober er virus eller bakterier som kan gjøre deg syk. ',
			'Noen vaksiner skal du drikke.  ',
			'Andre kan bli sprayet inn i nesen din.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Det er ikke moro å være syk. Det er mye bedre å være frisk! Det er mange måter å
					holde seg frisk på. En måte er å få sprøyte på et legekontor eller hos
					helsesykepleier.{' '}
					<span style={{ color: 'Lime' }}>
						<b>Istedenfor</b>
					</span>{' '}
					sprøyte kan en si at en får en injeksjon. Det kan gjøre litt vondt, men
					<span style={{ color: 'Lime' }}>
						<b> injeksjonen</b>
					</span>{' '}
					gjør en viktig jobb i kroppen din.
				</p>
				<p style={{ fontSize: '22px', color: 'Lime' }}>
					<b>
						Hva er en{' '}
						<span style={{ color: 'Lime' }}>
							<b>injeksjon </b>
						</span>
						?
					</b>
				</p>
				<p style={{ fontSize: '22px' }}>
					<span style={{ color: 'Lime' }}>
						<b>Injeksjonen</b>
					</span>{' '}
					har en vaksine i seg. Det er en slags medisin. Den gjør at farlige{' '}
					<span style={{ color: 'Lime' }}>
						<b>mikrober</b>
					</span>{' '}
					ikke kan skade deg. Skadelige{' '}
					<span style={{ color: 'Lime' }}>
						<b>mikrober</b>
					</span>{' '}
					som virus eller bakterier kan gjøre deg veldig syk. Vaksinen gjør deg ikke
					veldig syk. Kanskje får du litt feber etter
					<span style={{ color: 'Lime' }}>
						<b> injeksjonene</b>
					</span>
					, men du vil føle deg raskt bedre.
				</p>
				<p style={{ fontSize: '22px' }}>
					Vaksinene{' '}
					<span style={{ color: 'Lime' }}>
						<b>forhindrer</b>
					</span>{' '}
					<span style={{ color: 'Lime' }}>
						<b>mikrober</b>
					</span>{' '}
					i å gjøre folk syke. De holder mange barn og voksne friske.
				</p>
				<p style={{ fontSize: '22px' }}>
					Noen vaksiner skal du drikke. Andre kan bli sprayet inn i nesen din.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Vet du noen måter man kan holde seg frisk på?',
			' - Hvor kan man få sprøyte?',
			' - Hva er i injeksjonen?',
			' - Hva gjør en vaksine?',
			' - Hva kan vi kalle virus og bakterier?',
			' - Hvorfor tror du noen er litt redde for å få sprøyter?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Istedenfor', ' - Injeksjon', '- Mikrober', ' - Forhindrer'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session23 = [
	{
		introAudio: mainAudio_23,
		questionAudio: questions_23,
		wordsAudio: words_23,
		mainHeading: '1. Dagens tekst',
		mainImg: session23Img,
		subHeading: 'Været',
		phonemeData: phoneme23,
		DaysTextWords: wordsInTheEnd23[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Varmen kommer fra solen.',
			'Vanndråpene i luften danner skyer.',
			'Vannet faller ned som regn eller snø.',
			'Luft som beveger seg kaller vi for vind.',
		],
		summary: [
			'Været påvirker oss.  ',
			'Med vær mener vi temperatur, solskinn, vind, skyer og storm. ',
			'Temperaturen viser hvor varmt eller kaldt noe er. ',
			'Varmen kommer fra solen. ',
			'Varmen fra solen gjør at bitte små vanndråper stiger opp i luften.  ',
			'Vanndråpene i luften danner skyer. ',
			'Vannet faller ned som regn eller snø. ',
			'Når varm, fuktig luft stiger raskt, blir det storm. ',
			'Luft som beveger seg kaller vi for vind. ',
			'Når det er storm, regner det mye. Det blåser sterk vind under en storm.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Været påvirker hvordan vi lever. Med vær mener vi for eksempel temperatur,
					solskinn, vind, skyer og storm. Temperaturen viser hvor varmt eller kaldt noe
					er. Varmen kommer fra solen. Solen varmer ikke likt alle steder på jorden. Det
					er ulik temperatur på forskjellige steder på kloden vår. Noen steder er luften
					varm. Varm luft{' '}
					<span style={{ color: 'Lime' }}>
						<b>stiger</b>
					</span>{' '}
					opp. Kald luft kommer inn under den varme luften. Luft som{' '}
					<span style={{ color: 'Lime' }}>
						<b>beveger</b>
					</span>{' '}
					seg kaller vi for vind. Varmen fra solen gjør at bitte små vanndråper{' '}
					<span style={{ color: 'Lime' }}>
						<b>stiger</b>
					</span>{' '}
					opp i luften (
					<span style={{ color: 'Lime' }}>
						<b>fordamper</b>
					</span>
					). Når vanndråpene samler seg i luften blir det dannet skyer.{' '}
				</p>
				<p style={{ fontSize: '22px' }}>
					Når skyene blir for store, faller vannet tilbake til jorden. Vanligvis faller
					vannet ned som regn eller snø. Når varm, fuktig luft{' '}
					<span style={{ color: 'Lime' }}>
						<b>stiger</b>
					</span>{' '}
					raskt, blir det storm. Skyene{' '}
					<span style={{ color: 'Lime' }}>
						<b>danner</b>
					</span>{' '}
					seg hurtig. Når det er storm, regner det mye. Det blåser sterk vind under en
					storm.{' '}
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'- Kan du gi eksempler på noen værtyper? ',
			' - Hvordan kan været påvirke hvordan vi lever?',
			' - Hva viser temperaturen?',
			' - Hvordan dannes skyer?',
			' - Hvorfor blir det noen ganger regn og andre ganger snø?',
			' - Hva er vind?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- beveger', ' - stiger', '- danner', ' - fordamper'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session24 = [
	{
		introAudio: mainAudio_24,
		questionAudio: questions_24,
		wordsAudio: words_24,
		mainHeading: '1. Dagens tekst',
		mainImg: session24Img,
		subHeading: 'Årstider- hvorfor har vi dem?',
		phonemeData: phoneme24,
		DaysTextWords: wordsInTheEnd24[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Sommeren er varm og har lange dager.',
			'Vinteren er motsatt.',
			'Om våren blir det varmere.',
			'Om høsten endrer bladene farge.',
		],
		summary: [
			'Sommeren er varm og har lange dager. ',
			'Vinteren er motsatt. ',
			'Da er det kaldt og snø flere steder. ',
			'Våren er årstiden mellom vinter og sommer. ',
			'Høsten er årstiden mellom sommer og vinter.  ',
			'Om våren blir det varmere og blomstene rundt oss blomstrer.  ',
			'Om høsten endrer bladene farge og faller ned fra trærne.  ',
			'Hver årstid varer i tre måneder. ',
			'Jorden vår er rund.  ',
			'Jorden beveger seg rundt solen. Jordens akse står litt på skrå.',
			'Når den delen av jorden du bor på heller mot solen, er det sommer. ',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Sommeren er veldig varm, og har lange, solfylte dager. Vinteren er helt{' '}
					<span style={{ color: 'Lime' }}>
						<b>motsatt</b>
					</span>
					. Da er det kaldt og landskapet er dekket av snø flere steder.{' '}
				</p>
				<p style={{ fontSize: '22px' }}>
					Våren er årstiden mellom vinter og sommer. Høsten er årstiden mellom sommer og
					vinter. Om våren blir det varmere og alle blomstene rundt oss blomstrer. Om
					høsten endrer bladene farge og faller ned fra trærne. Hver årstid varer i tre
					måneder.
				</p>
				<p style={{ fontSize: '22px' }}>
					Jorden vår er rund som en appelsin. Jordens{' '}
					<span style={{ color: 'Lime' }}>
						<b>akse</b>
					</span>{' '}
					står også litt på skrå. Når jorden beveger seg rundt solen,{' '}
					<span style={{ color: 'Lime' }}>
						<b>heller</b>
					</span>{' '}
					jordens{' '}
					<span style={{ color: 'Lime' }}>
						<b>akse</b>
					</span>{' '}
					noen ganger mot solen. Da er nord nærmere solen enn sør. Noen ganger{' '}
					<span style={{ color: 'Lime' }}>
						<b>skråner</b>
					</span>{' '}
					jordens{' '}
					<span style={{ color: 'Lime' }}>
						<b>akse</b>
					</span>{' '}
					bort fra solen. Da er nord lenger bort fra solen.
				</p>
				<p style={{ fontSize: '22px' }}>
					Når den delen av jorden du bor på{' '}
					<span style={{ color: 'Lime' }}>
						<b>heller</b>
					</span>{' '}
					mot solen, er det sommer. Husk, solen er veldig varm. Når du lener deg mot den,
					er du nærmere. Det er derfor sommeren er varmere enn de andre årstidene vi har.
				</p>
				<p style={{ fontSize: '22px' }}>
					Når den delen av jorden du bor på{' '}
					<span style={{ color: 'Lime' }}>
						<b>heller</b>
					</span>{' '}
					bort fra solen, er det vinter. Når du lener deg bort fra den, er du lenger unna.
					Det er derfor vinteren er kaldere enn de andre årstidene vi har.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor mange årstider har vi? ',
			'2. Når er våren? ',
			'3. Når er høsten? ',
			'4. Hvor lenge varer hver årstid? ',
			'5. Hva betyr det at jordens akse står på skrå?',
			'6. Hvorfor har vi vinter når jordens akse skråner bort fra solen? ',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- motsatt', ' - akse', '- skråner', ' - heller'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session25 = [
	{
		introAudio: mainAudio_25,
		questionAudio: questions_25,
		wordsAudio: words_25,
		mainHeading: '1. Dagens tekst',
		mainImg: session25Img,
		DaysTextWords: wordsInTheEnd25[0].revisionWordsAndInstruction.words,
		subHeading: (
			<span>
				<b>
					<span style={{ color: 'Lime' }}>
						<b>Klumsete avslutning</b>
					</span>{' '}
				</b>
			</span>
		),
		phonemeData: phoneme25,
		easySummary: [
			'«Prinsesse Aina, er det deg?» sa Kai',
			'«Hvem var det som reddet deg?',
			'«Jeg ble reddet i Dubai,» sa Aina',
			'«Det var Bahai som reddet meg.»',
		],
		summary: [
			'«Prinsesse Aina, er det deg?» sa Kai og gikk ned på kne.',
			'«Hvem var det som reddet deg?',
			'Sist jeg så deg var du på kaien og fisket hai.» «Det er godt å se deg igjen, Kai.',
			'Jeg ble reddet i Dubai,» sa Aina ',
			'«Det var Bahai som reddet meg.»  ',
			'«Og jeg som trodde du var i Hawaii,» sa Kai og ga Aina en klem.  ',
			'«Takk for all hjelp, Bahai.» ',
			'«Men nå må jeg dra. ',
			'«Skal vi spise litt eplepai, Aina?  ',
			'Aina skulle til å svare, men så snublet hun, dro Kai med seg og falt av scenekanten med et klask.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					«Prinsesse Aina, er det virkelig deg?» sa Kai og gikk ned på kne. «Hvem var det
					som{' '}
					<span style={{ color: 'Lime' }}>
						<b>reddet</b>
					</span>{' '}
					deg? Sist jeg så deg var du på kaien og fisket hai.» «Det er godt å se deg
					igjen, Kai. Jeg ble{' '}
					<span style={{ color: 'Lime' }}>
						<b>reddet</b>
					</span>{' '}
					i Dubai,» sa Aina, og vinket til en gutt ved slottet. «Det var Bahai som{' '}
					<span style={{ color: 'Lime' }}>
						<b>reddet</b>
					</span>{' '}
					meg.» «Og jeg som trodde du var i Hawaii,» sa Kai og ga Aina en klem. «Takk for
					all hjelp, Bahai.» «Det skulle bare mangle,» sa Bahai. «Men nå må jeg dra. Flyet
					til Jamaika går om fem minutter.» «Skal vi spise litt eplepai, Aina? Bestemoren
					din har bakt i hele dag.» Aina skulle til å svare, men så snublet hun, dro Kai
					med seg og falt av{' '}
					<span style={{ color: 'Lime' }}>
						<b>scenekanten</b>
					</span>{' '}
					med et klask.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva handler dette om? ',
			'2. Hvem er med i fortellingen? ',
			'3. Hvem reddet Aina? ',
			'4. Hva har bestemor bakt? ',
			'5. Hva skjedde da Aina falt av scenen? ',
			'6. Hva tror du Aina følte da? ',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Klumsete', ' - Avslutning', '- Reddet', ' - Scenekanten'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session26 = [
	{
		introAudio: mainAudio_26,
		questionAudio: questions_26,
		wordsAudio: words_26,
		mainHeading: '1. Dagens tekst',
		mainImg: session26Img,
		subHeading: 'Siste stikk',
		phonemeData: phoneme26,
		DaysTextWords: wordsInTheEnd26[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Klaus skulle aldri ha prøvd skøytene.',
			'Nå lå han i en snøhaug.',
			'Han skulle tatt seg en pause.',
			'«Gikk det bra med deg, Klaus?',
		],
		summary: [
			'Klaus skulle aldri ha prøvd skøytene han fikk i august. Nå lå han på magen i en snøhaug. ',
			'Han burde tatt seg en pause for lenge siden. ',
			'«Gikk det bra med deg, Klaus?  ',
			'Er du dau?» sa piggsvinet Gaute, som lå under ham.  ',
			'«Det stikker!» «Unnskyld,» sa Gaute. ',
			'Fem av piggene hans hadde spiddet Klaus.  ',
			'«Du ser ut som en hvit nålepute. Nesten som en sau!  ',
			'«Det gjør du også,» sa Klaus  ',
			'«Nei, du ser ut som en ball med pigger.» Gaute ble flau.  ',
			'«Vil dere ha litt graut?» sa Aud. ',
			'«Jeg vil gjerne ha litt maursuppe,» sa Gaute,',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Klaus skulle aldri ha prøvd skøytene han fikk i august, for nå lå han på magen i
					en snøhaug og vred seg i{' '}
					<span style={{ color: 'Lime' }}>
						<b>smerte</b>
					</span>
					. Han burde tatt seg en pause for lenge siden.
				</p>
				<p style={{ fontSize: '22px' }}>
					«Gikk det bra med deg, Klaus? Er du dau?» sa piggsvinet Gaute, som lå under ham
					og fikk{' '}
					<span style={{ color: 'Lime' }}>
						<b>knapt</b>
					</span>{' '}
					puste.
				</p>
				<p style={{ fontSize: '22px' }}>{`«Au!» hylte Klaus og tok av seg 
		skøytene. «Det stikker!» `}</p>
				<p style={{ fontSize: '22px' }}>{`«Unnskyld,» sa Gaute og holdt seg fast i et tau. `}</p>
				<p style={{ fontSize: '22px' }}>
					Fem av piggene hans hadde{' '}
					<span style={{ color: 'Lime' }}>
						<b>spiddet</b>
					</span>{' '}
					Klaus. «Du ser ut som en hvit nålepute. Nesten som en sau! Her, ta denne
					<span style={{ color: 'Lime' }}>
						<b> baufilen</b>
					</span>
					.» «Det gjør du også,» sa Klaus og begynte å le.
				</p>
				<p
					style={{ fontSize: '22px' }}
				>{`«Nei, du ser ut som en ball med pigger.» Gaute ble flau.`}</p>
				<p
					style={{ fontSize: '22px' }}
				>{`«Vil dere ha litt graut?» sa Aud, og gikk mot huset.`}</p>
				<p style={{ fontSize: '22px' }}>{` «Jeg vil gjerne ha litt 
		maursuppe,» sa Gaute.`}</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvem er med i denne historien? ',
			'2. Hvor er de? ',
			'3. Hva gjør de ',
			'4. Hvilken årstid er det? ',
			'5. Hvordan får Klaus bort piggene som hadde spiddet han? ',
			'6. Hva skal de spise? ',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- smerte', ' - knapt', '- spiddet', ' - baufil'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session27 = [
	{
		introAudio: mainAudio_27,
		questionAudio: questions_27,
		wordsAudio: words_27,
		mainHeading: '1. Dagens tekst',
		mainImg: session27Img,
		subHeading: 'Stein står på menyen.',
		phonemeData: phoneme27,
		DaysTextWords: wordsInTheEnd27[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Ridder Stein skulle redde prinsesse Eirin.',
			'Men der tok han feil.',
			'Eirin sang at dragen skulle spise han.',
			'Dragen pustet ild mot ham.',
		],
		summary: [
			'Ridder Stein skulle redde prinsesse Eirin. ',
			'Men der tok han feil.  ',
			'Eirin sto på balkongen og sang til dragen.  ',
			'Men alt gikk skeis.',
			'Stemmen var så klein at ridder Stein ble flau.  ',
			'Hun sang om ham og veivet med lutten. ',
			'«Hei, Eirin. Er alt greit?» sa ridder Stein. ',
			'Hun neiet tilbake som svar. ',
			'Dragen snudde seg plutselig. ',
			'Eirin sang om ham. At dragen skulle spise han.  ',
			'Den hadde blitt forhekset! «Husj,» sa Stein. ',
			'«Ha deg vekk, ditt beist!» ',
			'Dragen pustet ild mot ham. ',
			'«Nei!» hylte ridder Stein, og det var siste gang noen hadde hørt fra ham. ',
		],
		para: (
			<span>
				<p
					style={{ fontSize: '22px' }}
				>{`Ridder Stein trodde han skulle redde prinsesse Eirin i steintårnet, men der 
		tok han feil. Eirin sto på balkongen og sang til dragen. Det var da hun åpnet 
		munnen at alt gikk skeis. Stemmen var så klein at ridder Stein ble flau. Hun 
		sang om ham og veivet med lutten.`}</p>
				<p style={{ fontSize: '22px' }}>{`«Hei, Eirin. Er alt greit?»`}</p>
				<p style={{ fontSize: '22px' }}>
					sa ridder Stein, og bukket. Hun{' '}
					<span style={{ color: 'Lime' }}>
						<b>neiet</b>
					</span>{' '}
					tilbake som svar, og sang videre. Dragen snudde seg plutselig, og Stein skjønte
					nå hva alt dreide seg om. Eirin sang om ham.
				</p>
				<p style={{ fontSize: '22px' }}>
					At dragen skulle spise han. Den hadde blitt{' '}
					<span style={{ color: 'Lime' }}>
						<b>forhekset</b>
					</span>
					{''}! «Husj,» sa Stein. «Ha deg vekk, ditt{' '}
					<span style={{ color: 'Lime' }}>
						<b>beist</b>
					</span>
					{''}
					!» Et eiketre knakk som en fyrstikk da dragen reiste seg opp. Den veivet med
					halen og slo den i leiren. Så pustet den ild mot ham. «Nei!» hylte ridder Stein,
					og det var siste gang noen hadde hørt fra ham.{' '}
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvem er med i denne historien? ',
			'2. Hva skal Stein gjøre? ',
			'3. Hvorfor ble ridder Stein flau? ',
			'4. Hva sang Eirin om? ',
			'5. Hvordan vet du at dragen er stor og sterk? ',
			'6. Hvor er ridder Stein nå?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Luffen', ' - Neiet', '- Beist', ' - Forhekset'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session28 = [
	{
		introAudio: mainAudio_28,
		questionAudio: questions_28,
		wordsAudio: words_28,
		mainHeading: '1. Dagens tekst',
		mainImg: session28Img,
		subHeading: 'Finner du Eføy? ',
		DaysTextWords: wordsInTheEnd28[0].revisionWordsAndInstruction.words,
		phonemeData: phoneme28,
		easySummary: [
			'Frøya bor like ved den lille byen Hisøy.',
			'I dag har Eføy rømt. ',
			'Frøya tar med seg et glass syltetøy. ',
			'Hun vet at Eføy liker alt som er søtt. ',
		],
		summary: [
			'Frøya bor på en liten høyde like ved den lille byen Hisøy.',
			'Det er en liten ferieøy som mange øyhoppere besøker.',
			'I dag har Eføy rømt.',
			'Det er hunden til Frøya, og hun er redd hun ikke finner ham.',
			'Eføy liker ikke støy, for han ser dårlig.',
			'Frøya tar med seg et glass syltetøy.',
			'Hun setter den ned ved en søyle.',
			'«Eføy, er du der!» roper hun.',
			'Hun går forbi en gammel brøytebil, og spør en mann som spiller fløyte:',
			'«Har du sett en liten, grå nakenhund?',
			'Han heter Eføy, og har hvite dusker på ørene.»',
			'Mannen har ikke sett ham.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Frøya bor på en liten høyde like ved den lille byen Hisøy. Det er en liten
					ferieøy som mange{' '}
					<span style={{ color: 'Lime' }}>
						<b> øyhoppere </b>{' '}
					</span>{' '}
					besøker hver dag det er varmt i været. I dag har Eføy rømt. Det er hunden til
					Frøya, og hun er redd hun ikke finner ham. Eføy liker ikke{' '}
					<span style={{ color: 'Lime' }}>
						<b>støy</b>
					</span>
					, for han ser dårlig. Eføy er
					<span style={{ color: 'Lime' }}>
						{' '}
						<b> enøyd </b>{' '}
					</span>{' '}
					og nesten blind.
				</p>
				<p style={{ fontSize: '22px' }}>
					Frøya tar med seg et glass syltetøy, for hun vet at Eføy liker alt som er søtt.
					Hun setter den ned ved en{' '}
					<span style={{ color: 'Lime' }}>
						<b> Søyle</b>
					</span>
					. «Eføy, er du der!» roper hun, mens hun leter i Hisøy. Hun går forbi en gammel
					brøytebil, og spør en mann som spiller fløyte: «Har du sett en liten, grå
					nakenhund? Han heter Eføy, og har hvite dusker på ørene.»{' '}
				</p>
				<p
					style={{ fontSize: '22px' }}
				>{`Mannen har ikke sett ham, men kanskje du finner Eføy?`}</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor bor Frøya?',
			'2. Hvem er Eføy?',
			'3. Hva kan du fortelle om Eføy?',
			'4. Hva gjør Frøya for å finne Eføy?',
			'5. Hva tror du er lurt å gjøre for å finne Eføy?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Øyhopper', ' - Støy', '- Enøyd', ' - Søyle'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session29 = [
	{
		introAudio: mainAudio_29,
		questionAudio: questions_29,
		wordsAudio: words_29,
		mainHeading: '1. Dagens tekst',
		mainImg: session29Img,
		subHeading: 'Julefeiring i Norge ',
		DaysTextWords: wordsInTheEnd29[0].revisionWordsAndInstruction.words,
		phonemeData: phoneme29,
		easySummary: [
			'Til Luciadagen baker man lussekatter.',
			'Gutter og jenter kler seg ut og går i tog.',
			'Vi legger gaver under juletreet.',
			'På julenatten setter mange ut grøt til nissen.',
		],
		summary: [
			'I flere nordiske land feires Luciadagen 13.desember.',
			'Gutter og jenter kler seg ut og går i tog.',
			'Fremst i toget går en jente som er Lucia.',
			'Hun har en krone i håret med lys på.',
			'Til Luciadagen baker man lussekatter.',
			'Mange pynter til jul med en bukk laget av halm, nisser og julestjerner.',
			'Vi har juletre inne i huset som vi legger gaver under.',
			'På julenatten setter mange ut grøt til nissen.',
			'Vi spiser gjerne ribbe, pinnekjøtt eller fisk på julaften.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					I flere{' '}
					<span style={{ color: 'Lime' }}>
						<b>nordiske</b>
					</span>{' '}
					land feires Luciadagen 13.desember. Denne dagen{' '}
					<span style={{ color: 'Lime' }}>
						<b>minnes</b>
					</span>{' '}
					vi St. Lucia som var god mot de fattige og gav dem mat. Gutter og jenter kler
					seg i hvite kjoler og kjortler. De går i tog. Fremst i toget går en jente som er
					Lucia. Hun har en krone i håret med lys på. Til Luciadagen baker man
					lussekatter. Lussekatter er gjærbakst som er krydret med safran, og det er
					derfor de er gule. I Norge pynter mange til jul med en bukk laget av halm og
					sløyfebånd, nisser og julestjerner. Julestjerner har røde eller hvite blomster.
					Vi har juletre inne i huset som vi legger gaver under. På julenatten setter
					mange ut grøt til nissen. Vi spiser gjerne ribbe, pinnekjøtt eller fisk på
					julaften. Mange baker ulike{' '}
					<span style={{ color: 'Lime' }}>
						<b>sorter</b>
					</span>{' '}
					julekaker før jul.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva handler denne teksten om?',
			'2. Hvorfor feirer vi Luciadagen?',
			'3. Hvordan feirer mange Luciadagen?',
			'4. Hvordan pynter mange til jul i Norge? ',
			'5. Hva spiser vi i jula? ',
			'6. Vet du om andre juletradisjoner i Norge?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Tradisjon', ' - Nordisk', '- Minnes', ' - Sorter'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session30 = [
	{
		introAudio: mainAudio_30,
		questionAudio: questions_30,
		wordsAudio: words_30,
		mainHeading: '1. Dagens tekst',
		mainImg: session30Img,
		subHeading: 'Julefeiring i England ',
		DaysTextWords: wordsInTheEnd30[0].revisionWordsAndInstruction.words,
		phonemeData: phoneme30,
		easySummary: [
			'I England åpner vi julegavene 1.juledag.',
			'Mange går fra dør til dør for å synge julesanger.',
			'Alle henge opp julesokker til julenissen.',
			'Til julemiddag spiser vi kalkun.',
		],
		summary: [
			<span>England åpner vi julegavene 1.juledag.</span>,
			<span>
				Vi pynter huset vårt med{' '}
				<span style={{ color: 'Lime' }}>
					<b>kristtorn</b>
				</span>{' '}
				og julelys.
			</span>,
			<span>I stuen har vi et juletre.</span>,
			<span>Mange er glade i å gå fra dør til dør for å synge julesanger.</span>,
			<span>Vi sender julekort til familie og venner.</span>,
			<span>Alle henger opp julesokker med godterier og små gaver til julenissen.</span>,
			<span>Vi henger opp julesokker på sengen vår som julenissen legger igjen gaver i.</span>,
			<span>
				Hvis vi ikke har vært snille får vi{' '}
				<span style={{ color: 'Lime' }}>
					<b>kull</b>
				</span>{' '}
				i dem!
			</span>,
			<span>Til julemiddag spiser vi kalkun.</span>,
			<span>
				<span style={{ color: 'Lime' }}>
					<b>Ved siden av</b>
				</span>{' '}
				hvert fat legger vi en bongbong. De smeller når vi dra i dem!
			</span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					I England åpner vi julegavene 1.juledag. Vi pynter huset vårt med{' '}
					<span style={{ color: 'Lime' }}>
						<b>kristtorn</b>
					</span>{' '}
					og julelys. I stuen har vi et juletre. Mange er glade i å gå fra dør til dør for
					å synge julesanger. Vi sender julekort til familie og venner. Tradisjonen med å
					sende julekort startet faktisk i England.{' '}
				</p>
				<p style={{ fontSize: '22px' }}>
					I England kaller vi julenissen Father Christmas. På julaften skriver vi brev til
					julenissen og ønsker oss gaver. Brevene kaster vi i peisen så de brenner opp og
					blir sendt ut pipen. Alle henger opp julesokker med godterier og små gaver til
					julenissen. Vi henger opp julesokker på sengen vår som julenissen legger igjen
					gaver i. Hvis vi ikke har vært snille får vi{' '}
					<span style={{ color: 'Lime' }}>
						<b>kull</b>
					</span>{' '}
					i dem! Hele familien{' '}
					<span style={{ color: 'Lime' }}>
						<b>samles</b>
					</span>{' '}
					til en stor julemiddag 1.juledag. Vi spiser kalkun, pølser i bacon og har
					julepudding til dessert.{' '}
					<span style={{ color: 'Lime' }}>
						<b>Ved siden av</b>
					</span>{' '}
					hvert fat legger vi en bongbong. De smeller når vi dra i dem! Hvert år har
					dronningen en tale på TV som vi ser sammen.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Når åpnes julegavene i England?',
			'2. Hva pynter de husene med?',
			'3. Hva heter julenissen i England?',
			'4. Hvordan sender barna ønskelisten til julenissen?',
			'5. Er det noen forskjell på hvordan vi og barna i England bruker julesokker/julestrømper?',
			'6. Hva spiser de til julemiddag i England?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Kristtorn', ' - Kull', '- Samles', ' - Ved siden av'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session31 = [
	{
		introAudio: mainAudio_31,
		questionAudio: questions_31,
		wordsAudio: words_31,
		mainHeading: '1. Dagens tekst',
		mainImg: session31Img,
		subHeading: 'Julefeiring i Australia ',
		DaysTextWords: wordsInTheEnd31[0].revisionWordsAndInstruction.words,
		phonemeData: phoneme31,
		easySummary: [
			'I Australia feirer vi jul 25.desember.',
			'Det sommer her på den tiden av året.',
			'Vi griller ofte på stranden og spiser middag der!',
			'Vi pynter husene våre med julebusker.',
		],
		summary: [
			<span>I Australia feirer vi jul 25.desember.</span>,
			<span>Det sommer her på den tiden av året.</span>,
			<span>Det er vanligvis veldig varmt og mye sol.</span>,
			<span>Vi griller ofte på stranden og spiser middag der!</span>,
			<span>Vi pynter husene våre med julebusker.</span>,
			<span>Buskene har røde blomster og grønne blader.</span>,
			<span>Mange av oss synger julesanger i parken på kvelden.</span>,
			<span>
				I Australia leverer julenissen ut gaver til alle på julaften, men vi åpner dem ikke før
				morgenen etter.
			</span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					I Australia feirer vi jul 25.desember. På vår side av jorden er det sommer på
					den tiden av året. De er{' '}
					<span style={{ color: 'Lime' }}>
						<b>vanligvis</b>
					</span>{' '}
					fint og varmt vær, så vi griller ofte på stranden og spiser middag der! Vi
					spiser gjerne{' '}
					<span style={{ color: 'Lime' }}>
						<b>sjømat</b>
					</span>{' '}
					,{' '}
					<span style={{ color: 'Lime' }}>
						<b>ulike typer</b>
					</span>{' '}
					kjøtt og salat til middag. Vi har en kjempegod dessert som heter Pavlova. Den
					har en sprø bunn av marengs og vaniljekrem med frukt på toppen. Vi pynter husene
					våre med julebusker. Det er en spesiell plante som vokser her i Australia. Den
					har røde blomster og grønne blader. Mange av oss synger julesanger i parken på
					kvelden, det kalles «Carols By Candlelight». I Australia{' '}
					<span style={{ color: 'Lime' }}>
						<b>leverer</b>
					</span>{' '}
					julenissen ut gaver til alle på julaften, men vi åpner dem ikke før morgenen
					etter.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Når feirer de jul i Australia? ',
			'2. Hvordan er været der da? ',
			'3. Hvorfor har de sommer i Australia i desember? ',
			'4. Kan du nevne fire ting som er forskjellige fra hvordan vi feirer jul i Norge? ',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Vanligvis', ' - Sjømat', '- Ulike typer', ' - Levere'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session32 = [
	{
		introAudio: mainAudio_32,
		questionAudio: questions_32,
		wordsAudio: words_32,
		mainHeading: '1. Dagens tekst',
		mainImg: session32Img,
		subHeading: 'Jul før og nå ',
		phonemeData: phoneme32,
		DaysTextWords: wordsInTheEnd32[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Ordet jul, er gammelt. Før het det jól.',
			'De gikk julebukk og fikk noe godt.',
			'Grøt ble satt ut til fjøsnissen.',
			'Fjøsnissen er ikke julenissen.',
		],
		summary: [
			'Ordet jul, er gammelt. Før het det jól.',
			'Julen var hedensk. En feiring der de ofret. ',
			'Hedensk betyr at julen ikke var kristen. ',
			'Jól var en fest. De feiret et godt år. ',
			'De gikk julebukk og fikk noe godt. ',
			'Grøt ble satt ut til fjøsnissen. ',
			'Fjøsnissen er ikke julenissen. ',
			'Julenissen stammer fra Sankt Nikolaus. ',
			'Det var en snill mann som ga bort gaver. ',
			'Han ga til fattige. Han forsvarte svake. ',
			'I dag er hedensk jól blandet med kristen jul. Kristne feirer Jesu bursdag.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Ordet jul, er gammelt. Før het det jól. Vi har mange gamle tradisjoner. Julen
					var{' '}
					<span style={{ color: 'Lime' }}>
						<b>hedensk</b>
					</span>{' '}
					og en feiring der de ofret mat til de{' '}
					<span style={{ color: 'Lime' }}>
						<b>norrøne</b>
					</span>{' '}
					gudene. Hedensk betyr at julen ikke var kristen.
				</p>
				<p
					style={{ fontSize: '22px' }}
				>{`Jól var en fest og de feiret et godt år. De håpte neste år skulle bli like 
					godt. Mye handlet om å spise.`}</p>
				<p style={{ fontSize: '22px' }}>{`Barn gikk på dørene og sang.`}</p>
				<p style={{ fontSize: '22px' }}>
					Da gikk de{' '}
					<span style={{ color: 'Lime' }}>
						<b>julebukk</b>
					</span>{' '}
					og fikk noe godt tilbake som premie.
				</p>
				<p style={{ fontSize: '22px' }}>
					Grøt ble satt ut til{' '}
					<span style={{ color: 'Lime' }}>
						<b>fjøsnissen</b>
					</span>{' '}
					så han ikke ble sur. Han var en av de underjordiske.{' '}
				</p>
				<p style={{ fontSize: '22px' }}>
					<span style={{ color: 'Lime' }}>
						<b>Fjøsnissen</b>
					</span>{' '}
					er ikke den samme som julenissen. I dag blandes de sammen. Julenissen stammer
					fra en helgen som heter Sankt Nikolaus. Han var snill Han ga bort gaver til
					fattige og barn. Derfor passer han som julenissen. I dag har gammel hedensk jól
					blandet seg med kristen jul. Kristne feirer Jesus sin fødselsdag.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1.  Hva het julen før?',
			'2.  Hva gjorde ungene da de gikk julebukk?',
			'3.  Hva betyr det at julen var en hedensk skikk?',
			'4.  Hva gjorde folk for fjøsnissen i jula?',
			'5.  Har du gått julebukk?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Hedensk', ' - Fjøsnisse', '- Norrøne', ' - Julebukk'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session33 = [
	{
		introAudio: mainAudio_33,
		questionAudio: questions_33,
		wordsAudio: words_33,
		mainHeading: '1. Dagens tekst',
		mainImg: session33Img,
		subHeading: 'Nyttårsfeiring i Kina',
		phonemeData: phoneme33,
		DaysTextWords: wordsInTheEnd33[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Den største høytiden vi har i Kina er nyttår.',
			'Vi kaller den også for vårfestivalen.',
			'Vi har store parader.',
			'Alle paradene har en stor drage.',
		],
		summary: [
			'Den største høytiden vi har i Kina er nyttår.',
			'Det har vi i januar og februar hvert år.',
			'Vi kaller den også for vårfestivalen.',
			'Vi pynter husene våre med fargene rødt og gull.',
			'Vi spiser godsaker som søte riskaker, appelsiner og klementiner.',
			'Fyrverkeri blir brukt for å skremme vekk ulykke.',
			'Vi har store parader.  Alle paradene har en stor drage.',
			'Den siste dagen sender vi lanterner på vannet og opp i luften!',
			'Barna får gaver som er små røde konvolutter med penger i.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Den største{' '}
					<span style={{ color: 'Lime' }}>
						<b>høytiden</b>{' '}
					</span>
					vi har i Kina er nyttår. Det har vi i januar og februar hvert år, og festen
					varer i 15 dager! Den{' '}
					<span style={{ color: 'Lime' }}>
						<b>markerer</b>{' '}
					</span>{' '}
					slutten på vinteren, så vi kaller den også for vårfestivalen. Vi pynter husene
					våre med fargene rødt og gull. Vi spiser godsaker som søte riskaker, appelsiner
					og klementiner.
				</p>
				<p style={{ fontSize: '22px' }}>
					Veldig mye fyrverkeri blir brukt for å skremme vekk ulykke, og vi har store og
					bråkete{' '}
					<span style={{ color: 'Lime' }}>
						<b>parader</b>
						{''}
					</span>
					. Alle paradene har en stor drage laget av mennesker som går under et stort
					kostyme. En person holder hodet, og resten holder kroppen. De løper sammen
					gjennom gatene. Den siste dagen sender vi{' '}
					<span style={{ color: 'Lime' }}>
						<b>lanterner</b>
						{''}
					</span>{' '}
					på vannet, og til og med opp i luften! Barna får gaver som er små røde
					konvolutter med penger i.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Når er nyttårsfeiringen i Kina og hvor lenge varer den?',
			'2. Hvorfor kalles den også for vårfestivalen?',
			'3. Kan du fortelle fire ting om feiringen?',
			'4. Hva får barna i gaver?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Høytid', ' - Markerer', '- Parader', ' - Lanterner'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session34 = [
	{
		introAudio: mainAudio_34,
		questionAudio: questions_34,
		wordsAudio: words_34,
		mainHeading: '1. Dagens tekst',
		mainImg: session34Img,
		subHeading: 'På et blunk',
		phonemeData: phoneme34,
		DaysTextWords: wordsInTheEnd34[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Blomsterbilen var gul.',
			'Den slapp ut røyk.',
			'Byen ble skitten.',
			'Den ble stanset av politibilen.',
		],
		summary: [
			'Blomsterbilen var gul.',
			'Blå røyk full av bly, lå bak som et teppe. ',
			'En blond mann i blått stanset politibilen.',
			'Den sperret veien for Billy.',
			'«Er det noe galt?» sa Billy som hadde blomster å levere.',
			'«Du skitner til hele byen med den bilen,», sa mannen og blunket raskt.',
			'Han begynte å bli bleik.',
			'«Skitner til byen?» sa Billy.',
			'«Jeg har med blåklokker og vakre blomster.',
			'«Bilen....forpester...» sa mannen og hostet.',
			'Så svimte han av på et blunk, midt på veien.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Blomsterbilen var gul, den{' '}
					<span style={{ color: 'Lime' }}>
						<b>harket</b>{' '}
					</span>{' '}
					og hostet langs veien i byen. Blå røyk full av bly, lå bak som et teppe. En
					blond mann i blått kjørte forbi, og stanset politibilen. Den{' '}
					<span style={{ color: 'Lime' }}>
						<b>sperret</b>{' '}
					</span>{' '}
					veien for Billy. «Er det noe galt?» sa Billy som hadde blomster å levere. Han
					var blid. Det var han alltid når han måtte snakke med lovens lange arm.
				</p>
				<p style={{ fontSize: '22px' }}>
					«Du skitner til hele byen med den bilen,», sa mannen og{' '}
					<span style={{ color: 'Lime' }}>
						<b>blunket</b>{' '}
					</span>
					raskt. Det sved i øynene. Han begynte å bli bleik. «Skitner til byen?» sa Billy.
					«Jeg har med blåklokker og vakre blomster. De er da rene og lukter godt!»
					«Bilen....
					<span style={{ color: 'Lime' }}>
						<b>forpester</b>
					</span>
					...» sa mannen og hostet hardt. Så svimte han av på et blunk, midt på veien.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva gjorde blomsterbilen?',
			'2. Hvem var den blonde mannen i blått?',
			'3. Hvem er «lovens lange arm»?',
			'4. Hva lukter godt?',
			'5. Hvem svimte av?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Harket', ' - Sperret', '- Blunk', ' - Forpester'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session35 = [
	{
		introAudio: mainAudio_35,
		questionAudio: questions_35,
		wordsAudio: words_35,
		mainHeading: '1. Dagens tekst',
		mainImg: session35Img,
		subHeading: 'Dagen da verden bråbremset',
		phonemeData: phoneme35,
		DaysTextWords: wordsInTheEnd35[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Brage lånte bilen til far.',
			'Han braste inn i en gul bil. ',
			'Bilen begynte å brenne. ',
			'Brage er 12 år',
		],
		summary: [
			'Brage hadde tyvlånt bilen til faren. ',
			'Det angret han på nå.  Han bråbremset, og braste rett inn i en gul bil.',
			'Hvorfor lærte han aldri!',
			'Den gule bilen begynte å brenne.',
			'En dame prøvde å bryte seg ut, men døren var knust.',
			'Brage ringte brannvesenet og løp til den gule bilen.',
			'Han fikk damen ut på den andre siden av bilen.',
			'Rett etter var bilen overtent. «Takk for at du reddet meg» sa damen. «Men er du gammel nok til å kjøre bil?»',
			'«Jeg er tolv,» sa Brage brydd.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Brage hadde tyvlånt bilen til faren. Det angret han på nå. Han bråbremset, og
					<span style={{ color: 'Lime' }}>
						{' '}
						<b>braste</b>{' '}
					</span>
					rett inn i en gul bil med et brak. Brillene spratt av og brusen smalt inn i
					bilruten. Det var den bråkjekke broren hans som hadde sagt at han aldri ville
					<span style={{ color: 'Lime' }}>
						{' '}
						<b>våge</b>{' '}
					</span>{' '}
					å ta bilen. Hvorfor lærte han aldri! Mens Brage satt der i bilen midt på broen,
					begynte den gule bilen å brenne. En dame prøvde å bryte seg ut, men døren var
					knust. Brage ringte brannvesenet og løp til den gule bilen. Han fikk damen ut på
					den andre siden av bilen. Rett etter var bilen{' '}
					<span style={{ color: 'Lime' }}>
						<b>overtent</b>{' '}
					</span>
					. Det brant voldsomt. «Takk for at du reddet meg, unge mann,» sa damen. «Men er
					du gammel nok til å kjøre bil, du da?» «Jeg er tolv,» sa Brage{' '}
					<span style={{ color: 'Lime' }}>
						<b>brydd</b>
					</span>
					.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor har Brage fått bilen fra?',
			'2. Hvorfor angret han på at han hadde tatt bilen?',
			'3. Hva skjedde med den gule bilen?',
			'4. Hva gjorde Brage?',
			'5. Hvordan endte historien?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Braste', ' - Våge', '- Overtent', ' - Brydd'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session36 = [
	{
		introAudio: mainAudio_36,
		questionAudio: questions_36,
		wordsAudio: words_36,
		mainHeading: '1. Dagens tekst',
		mainImg: session36Img,
		subHeading: 'Drømmefangeren',
		phonemeData: phoneme36,
		DaysTextWords: wordsInTheEnd36[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Dirk drømmer aldri.',
			'Snart sover han.',
			'Så ser han en drage.',
			'«Hjelp!» hyler Dirk i søvne.',
		],
		summary: [
			'Dirk drømmer aldri.',
			'Mor har hengt opp en drømmefanger over senga.',
			'Den skal hjelpe ham med å drømme.',
			'«Ta på deg nattdrakt, Dirk,» sier mor. Dirk drikker litt kaldt vann og gjør seg klar for natten.',
			'Dirk bor i Drammen. Det er drittvær ute.',
			'Snart sover han. Alt er svart først, men så dukker det opp en mann i en lilla drakt.',
			'Han skjønner at det er Ole Lukkøye som drysser støv over ham.',
			'Så ser han en drage.',
			'Dragen spyr ild og dreper alt i sin vei.',
			'«Hjelp!» hyler Dirk i søvne. «Jeg brenner!»',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Dirk drømmer aldri. Mor har hengt opp en{' '}
					<span style={{ color: 'Lime' }}>
						<b>drømmefanger</b>{' '}
					</span>{' '}
					over senga. Den skal hjelpe ham med å drømme. «Ta på deg nattdrakt, Dirk,» sier
					mor, før hun følger ham til sengs. Dirk drikker litt kaldt vann og gjør seg klar
					for natten. Dirk bor i Drammen. Det er drittvær ute. Flere dråper vann renner
					ned vinduet. Det{' '}
					<span style={{ color: 'Lime' }}>
						<b>prikker</b>{' '}
					</span>{' '}
					i huden under den kalde dynen. Snart sover han. Alt er svart først, men så
					dukker det opp en mann i en lilla drakt. Dirk lurer på hva han driver med. Han
					skjønner at det er Ole Lukkøye som{' '}
					<span style={{ color: 'Lime' }}>
						<b>drysser</b>{' '}
					</span>{' '}
					støv over ham. Så ser han en drage og ett par skyer som{' '}
					<span style={{ color: 'Lime' }}>
						<b>driver</b>{' '}
					</span>{' '}
					forbi ved stranda. En drage spyr ild og dreper alt i sin vei, før den sluker ham
					i et jafs. «Hjelp!» hyler Dirk i søvne. «Jeg brenner!»
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er en drømmefanger?',
			'2. Hvordan vet vi at det er dårlig vær?',
			'3. Hvem har lilla drakt på seg? ',
			'4. Hva kan vi kalle drømmen til Dirk?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Drømmefanger', ' - Prikker', '- Driver', ' - Drysser'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session37 = [
	{
		introAudio: mainAudio_37,
		questionAudio: questions_37,
		wordsAudio: words_37,
		mainHeading: '1. Dagens tekst',
		mainImg: session37Img,
		subHeading: 'En flamme midt i fleisen',
		phonemeData: phoneme37,
		DaysTextWords: wordsInTheEnd37[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Det er fri og flammefest.',
			'En drage spruter ild.',
			'Flammen brente flagg, klær og hår.',
			'Heksa Flåse ble flau.',
		],
		summary: [
			'Det er fri og flammefest.',
			'Alle hekser har dratt på utflukt.',
			'Dette gjør de en gang i året.',
			'En drage flyr ned fra fjellet for å sprute ild på en flaske.',
			'De har samlet seg i flokk for å åpne flasken med flammen i.',
			'«Varm flasken!» sa heksa Flåse.',
			'Dragen gjorde det den ble bedt om.',
			'Det freste i flasken. Flammen flekket tenner og snerret.',
			'Den vokste seg stor, og så sprakk glasset. Flammen flyktet i et poff.',
			'«Flaks!» sa heksa Flire, da hun så at flammen hadde brent både flagg, klær, hår og flette.',
			'«Den fikk jeg midt i fleisen!» flirte Flåse og ble flau.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Det er fri og flammefest. Alle hekser har dratt på{' '}
					<span style={{ color: 'Lime' }}>
						<b>utflukt</b>{' '}
					</span>
					. Dette gjør de en gang i året. På denne dagen flyr en drage ned fra fjellet for
					å sprute ild på en flaske som de har satt frem. De har{' '}
					<span style={{ color: 'Lime' }}>
						<b>samlet</b>{' '}
					</span>{' '}
					seg i flokk for å åpne flasken med flammen i. «Varm flasken!» sa heksa Flåse til
					den blå dragen, og kostet av seg litt flass. Dragen gjorde det den ble bedt om.
					Det freste i flasken. Flammen flekket tenner og snerret. Den vokste seg stor, og
					så{' '}
					<span style={{ color: 'Lime' }}>
						<b>sprakk</b>{' '}
					</span>{' '}
					glasset. Flammen{' '}
					<span style={{ color: 'Lime' }}>
						<b>flyktet</b>{' '}
					</span>{' '}
					i et poff. «Flaks!» sa heksa Flire, da hun så at flammen hadde brent både flagg,
					klær, hår og flette. Hele Flåse var full av sot. «Den fikk jeg midt i fleisen!»
					flirte Flåse og ble flau.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvem handler dette om?',
			'2. Hvor ofte drar heksene på flammefest?',
			'3. Hvordan får de flammen ned i flasken?',
			'4. Hva skjedde da flasken sprakk?',
			'5. Hva ville du gjort hvis noe begynte å brenne?',
		],
		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Samle', ' - Utflukt', '- Sprakk', ' - Flyktet'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session38 = [
	{
		introAudio: mainAudio_38,
		questionAudio: questions_38,
		wordsAudio: words_38,
		mainHeading: '1. Dagens tekst',
		mainImg: session38Img,
		subHeading: 'Solsystemet',
		phonemeData: phoneme38,
		DaysTextWords: wordsInTheEnd38[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Solen er en stor stjerne.',
			'Det er åtte planeter.',
			'Planetene går rundt solen.',
			'Det kalles å gå i bane.',
			,
		],
		summary: [
			'Det er fremdeles mye vi ikke vet om verdensrommet.',
			'Vi vet ganske mye om vårt eget solsystem.',
			'Solsystemet vårt består av Solen og planetene.',
			'Solen er en kjempestor, rund stjerne.',
			'Solen ligger midt i solsystemet vårt.',
			'Planetene har rund form, og beveger seg i sirkel rundt solen.',
			'Vi kaller dette å gå i bane.',
			'Planetene er mindre enn Solen.',
			'Solen har en sterk tyngdekraft som trekker planetene mot den.',
			'Planetene blir ikke dratt inn i Solen fordi de går rundt og rundt så fort.',
			'Uten tyngdekraften hadde vi falt av Jorden og ut i verdensrommet.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Det er fremdeles mye vi ikke vet om verdensrommet. Vi vet ganske mye om vårt
					eget solsystem. Solsystemet vårt{' '}
					<span style={{ color: 'Lime' }}>
						<b>består</b>
						{''}
					</span>{' '}
					av Solen og planetene. Solen er en kjempestor, rund stjerne. Solen ligger midt i
					solsystemet vårt. Planetene har rund form, og{' '}
					<span style={{ color: 'Lime' }}>
						<b>beveger</b>
						{''}
					</span>{' '}
					seg i sirkel rundt solen. Vi kaller dette å gå i bane. Planetene er mindre enn
					Solen.
				</p>
				<p style={{ fontSize: '22px' }}>
					{' '}
					De åtte planeter i solsystemet vårt er Merkur, Venus, Jorden, Mars, Jupiter,
					Saturn, Uranus, og Neptun.{' '}
				</p>

				<p style={{ fontSize: '22px' }}>
					{' '}
					Hvordan kan planetene holde seg nær Solen? Solen har en sterk tyngdekraft som
					trekker planetene mot den. Tyngdekraften er som en kraftig streng som drar i
					planetene. Planetene blir ikke{' '}
					<span style={{ color: 'Lime' }}>
						<b>dratt</b>
						{''}
					</span>{' '}
					inn i Solen fordi de går rundt og rundt så fort. Det blir som å svinge en ball
					som er festet i et tau, rundt i stor fart. Uten{' '}
					<span style={{ color: 'Lime' }}>
						<b>tyngdekraften</b>
						{''}
					</span>{' '}
					hadde vi falt av Jorden og forsvunnet ut i verdensrommet.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er Solen?',
			'2. Hvor mange planeter er det i vårt solsystem?',
			'3. Hvordan kan planetene holde seg nær Solen?',
			'4. Hvorfor blir ikke planetene dratt inn i Solen?',
			'5. Hvordan kan vi merke tyngdekraften?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Bestå', ' - Bevege', '- Dratt', ' - Tyngdekraft'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session39 = [
	{
		introAudio: mainAudio_39,
		questionAudio: questions_39,
		wordsAudio: words_39,
		mainHeading: '1. Dagens tekst',
		mainImg: session39Img,
		subHeading: 'Fakta om Merkur, Venus, Jorden, og Mars',
		phonemeData: phoneme39,
		DaysTextWords: wordsInTheEnd39[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Merkur har mange krater.',
			'Atmosfæren på Venus er giftig.',
			'Jorda har mye vann.',
			'Mars er kald og støvete.',
		],
		summary: [
			'Det er åtte planeter i solsystemet vårt.',
			'Planetene Merkur, Venus, Jorda og Mars er kjent som «steinplaneter».',
			'Du kan se Merkur med dine egne øyne på himmelen.',
			'Merkur er full av krater og groper.',
			'Steiner fra verdensrommet har krasjet inn i den.',
			'Atmosfæren på Venus er giftig.',
			'Trykket på overflaten er stort.',
			'Vi bor på planeten Jorden.',
			'Jordkloden blir også kalt Tellus.',
			'En stor del av Jordens overflate er dekket av vann.',
			'Vannet er grunnlaget for livet på Jorden.',
			'Mars er kald og støvete.',
			'Mange kaller den for «den røde planet».',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>Det er åtte planeter i solsystemet vårt.</p>
				<p style={{ fontSize: '22px' }}>
					Planetene Merkur, Venus, Jorda og Mars er kjent som «steinplaneter». Det er
					fordi de har steinete{' '}
					<span style={{ color: 'Lime' }}>
						<b>overflater</b>
					</span>
					.
				</p>
				<p style={{ fontSize: '22px' }}>
					Du kan se Merkur med dine egne øyne på himmelen rett før soloppgang og rett
					etter solnedgang. Merkur er full av{' '}
					<span style={{ color: 'Lime' }}>
						<b>krater</b>{' '}
					</span>
					og groper. De har blitt til etter at steiner fra verdensrommet har krasjet inn i
					overflaten.
				</p>
				<p style={{ fontSize: '22px' }}>
					Venus snurrer i motsatt retning av de fleste andre planetene.
					<span style={{ color: 'Lime' }}>
						{' '}
						<b>Atmosfæren</b>{' '}
					</span>{' '}
					er giftig. Trykket på overflaten ville knust deg på et øyeblikk.
				</p>
				<p style={{ fontSize: '22px' }}>
					Vi bor på planeten Jorden. Jordkloden blir også kalt Tellus. Hele 2/3 av Jordens
					overflate er dekket av vann. Vannet er{' '}
					<span style={{ color: 'Lime' }}>
						<b>grunnlaget</b>{' '}
					</span>
					for livet på Jorden.
				</p>
				<p style={{ fontSize: '22px' }}>
					Mars er kald og støvete. Det jernrike støvet gir Mars sin røde farge. Mange
					kaller den for «den røde planet».
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva kalles disse planetene?',
			'2. Hvorfor kalles de steinplaneter?',
			'3. Hva er grunnlaget for livet på Jorda?',
			'4. Hvilken planet er Tellus?',
			'5. Tror du at mennesker kan reise til andre planeter?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Overflate', ' - Krater', '- Atmosfære', ' - Grunnlag'],
			revisionParaData: (
				<span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session40 = [
	{
		introAudio: mainAudio_40,
		questionAudio: questions_40,
		wordsAudio: words_40,
		mainHeading: '1. Dagens tekst',
		mainImg: session40Img,
		subHeading: 'Fakta om Jupiter, Saturn, Uranus og Neptun',
		phonemeData: phoneme40,
		DaysTextWords: wordsInTheEnd40[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Jupiter har en rød flekk.',
			'Saturn har store ringer.',
			'Uranus har blå-grønn farge.',
			'Neptun har blå farge.',
		],
		summary: [
			'Det er åtte planeter i solsystemet vårt.',
			'Planetene Jupiter, Saturn, Uranus og Neptun er kjent som «gasskjemper».',
			'Det er fordi de er veldig store og består av gass.',
			'Jupiter har mange farger på grunn av ulike gasser som er i planeten.',
			'Det mest berømte kjennetegnet til Jupiter er «Den røde flekk».',
			'Saturn er mest kjent for sine store ringer.',
			'Det er is og stein som går i bane rundt planeten.',
			'Uranus har blå-grønn farge, og er den kaldeste planeten i solsystemet.',
			'Neptun har blå farge.',
			'Planeten er kjent for sine enormt sterke vinder.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Det er åtte planeter i solsystemet vårt. Planetene Jupiter, Saturn, Uranus og
					Neptun er kjent som «gasskjemper». Det er fordi de er veldig store og består av
					gass.{' '}
				</p>
				<p style={{ fontSize: '22px' }}>
					Jupiter har mange farger på grunn av ulike gasser som er i planeten. Det mest{' '}
					<span style={{ color: 'Lime' }}>
						<b>berømte</b>{' '}
					</span>{' '}
					<span style={{ color: 'Lime' }}>
						<b>kjennetegnet</b>{' '}
					</span>{' '}
					til Jupiter er «Den røde flekk», en storm som har rast i hundrevis av år.
				</p>
				<p style={{ fontSize: '22px' }}>
					Saturn er mest kjent for sine store ringer. Disse ringene består for det meste
					av is og stein som går i bane rundt planeten.
				</p>
				<p style={{ fontSize: '22px' }}>
					Uranus har blå-grønn farge, og er den kaldeste planeten i solsystemet. Uranus
					ligger på siden. Den har noen små ringer som går rundt planetene fra toppen til
					bunnen.
				</p>
				<p style={{ fontSize: '22px' }}>
					Neptun har blå farge. Planeten er kjent for sine{' '}
					<span style={{ color: 'Lime' }}>
						<b>enormt</b>{' '}
					</span>{' '}
					sterke vinder. Vindene blåser med{' '}
					<span style={{ color: 'Lime' }}>
						<b>supersonisk</b>{' '}
					</span>{' '}
					fart.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvorfor kalles noen planeter gassplaneter?',
			'2. Hva er «den røde flekk» på Jupiter.',
			'3. Hva består ringene til Saturn av?',
			'4. Hva kan du si om Uranus?',
			'5. Hva er Neptun kjent for?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Kjennetegn', ' - Berømt', '- Enormt', ' - Supersonisk'],
			revisionParaData: (
				<span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session41 = [
	{
		introAudio: mainAudio_41,
		questionAudio: questions_41,
		wordsAudio: words_41,
		mainHeading: '1. Dagens tekst',
		mainImg: session41Img,
		subHeading: 'Elg1',
		DaysTextWords: wordsInTheEnd41[0].revisionWordsAndInstruction.words,
		phonemeData: phoneme41,
		easySummary: [
			'Elgen er plante-eter.',
			'Elgen er et bytte-dyr.',
			'Elgen trives best alene.',
			'Elg-oksen har store gevir.',
		],
		summary: [
			'Elgen er et varmblodig pattedyr.',
			'Elgen liker å leve i kalde klima.',
			'Elgen er planteeter.',
			'Det betyr at de spiser planter og grønnsaker, ikke kjøtt.',
			'Elgen spiser gress, små busker og blad.',
			'Elgen er et byttedyr.',
			'Det betyr at rovdyr jakter elgen for mat. Rovdyr som spiser elg, kan være ulv, jerv, bjørn og mennesker.',
			'Elgen trives best alene. De er enslige dyr.',
			'En hannelg kalles for elgokse.',
			'Hunnene blir kalt for elgku. Ungene kalles for kalver.',
			'Hannelger har store, brede gevir.',
			'Elgoksen vil slåss mot hverandre for å bevise at geviret deres er størst.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Elgen er et varmblodig{' '}
					<span style={{ color: 'Lime' }}>
						<b>pattedyr</b>{' '}
					</span>{' '}
					som hører til samme familie som hjorten. Elgen liker å leve i kalde{' '}
					<span style={{ color: 'Lime' }}>
						<b>klima</b>
					</span>
					. Det lever elg i Canada, Nord-Amerika og i Nord-Europeiske land.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Elgen er planteeter. Det betyr at de spiser planter og grønnsaker, ikke kjøtt.
					Elgen spiser gress, små busker og blad.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Elgen er et{' '}
					<span style={{ color: 'Lime' }}>
						<b>byttedyr</b>
					</span>
					. Det betyr at rovdyr jakter elgen for mat. Rovdyr som spiser elg, kan være ulv,
					jerv, bjørn og mennesker.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Elgen trives best alene. De er{' '}
					<span style={{ color: 'Lime' }}>
						<b>enslige</b>{' '}
					</span>{' '}
					dyr. Det betyr at elgen er ulik andre hjortedyr, som lever i flokk.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					En hannelg kalles for elgokse. Hunnene blir kalt for elgku. Ungene kalles for
					kalver.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Hannelger har store, brede gevir. Hunnene vil velge en make ut fra størrelsen på
					geviret. Hannelgene vil slåss mot hverandre for å bevise at geviret deres er
					størst.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Geviret til en voksen elg kan bli mer enn 2 meter i diameter.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor lever elgen?',
			'2. Hva spiser elgen?',
			'3. Hva betyr det at elgen er et byttedyr?',
			'4. Hva kalles ungene til elgen?',
			'5. Hva er et gevir?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Klima', ' - Pattedyr', '- Byttedyr', ' - Enslig'],
			revisionParaData: (
				<span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];

export const session42 = [
	{
		introAudio: mainAudio_42,
		questionAudio: questions_42,
		wordsAudio: words_42,
		mainHeading: '1. Dagens tekst',
		mainImg: session41Img,
		subHeading: 'Elg 2',
		phonemeData: phoneme42,
		DaysTextWords: wordsInTheEnd42[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Hver vinter vil hannelgen felle geviret.',
			'Nytt gevir vil vokse ut våren etter.',
			'Elgene har varm, brun pels.',
			'En elg kan bli over 2 meter.',
		],
		summary: [
			'Hver vinter vil hannelgen felle geviret.',
			'Nytt gevir vil vokse ut våren etter.',
			'Det tar 3 til 5 måneder for et nytt gevir å vokse ut.',
			'Elgene har varm, brun pels. De har to lag med pels.',
			'Luft mellom de to lagene hjelper dem å holde seg varme, og å holde seg flytende når de svømmer.',
			'I gjennomsnitt vil en voksen elg vokse til litt over 2 meter.',
			'En voksen elgokse veier i gjennomsnitt 700 kilo. Hunnene veier mindre.',
			'Hulemalerier fra steinalderen viser at mennesker har jaktet på elg for tusenvis av år siden.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Hver vinter vil hannelgen{' '}
					<span style={{ color: 'Lime' }}>
						<b>felle</b>{' '}
					</span>{' '}
					geviret. Dette hjelper elgene å spare på{' '}
					<span style={{ color: 'Lime' }}>
						<b>energien</b>{' '}
					</span>{' '}
					for den kalde vinteren. Nytt gevir vil vokse ut våren etter. Det tar 3 til 5
					måneder for et nytt gevir å vokse ut.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Elgene har varm, brun pels. De har to lag med pels. Luft mellom de to lagene
					hjelper dem å holde seg varme om vinteren, og det hjelper dem å holde seg
					flytende når de svømmer.{' '}
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					I{' '}
					<span style={{ color: 'Lime' }}>
						<b>gjennomsnitt</b>{' '}
					</span>{' '}
					vil en voksen elg vokse til litt over 2 meter. En voksen elgokse veier i{' '}
					<span style={{ color: 'Lime' }}>
						<b>gjennomsnitt</b>{' '}
					</span>{' '}
					700 kilo. Hunnene veier mindre, de veier i gjennomsnitt 490 kilo.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					<span style={{ color: 'Lime' }}>
						<b>Hulemalerier</b>{' '}
					</span>{' '}
					fra steinalderen viser at mennesker har jaktet på elg for tusenvis av år siden.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Elgen er et av nasjonaldyrene i Canada. Den er avbildet på våpenskjold. Elger er
					også avbildet på våpenskjold i Finland.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvorfor tror du elgen feller geviret?',
			'2. Hvordan klarer elgen å holde seg varm om vinteren?',
			'3. Hva hjelper elgen å holde seg flytende når han svømmer?',
			'4. Hvor stor og tung kan en elgokse bli?',
			'5. Hvordan kan vi vite at mennesker har jaktet på elg i tusenvis av år?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Felle', ' - Energi', '- Gjennomsnitt', ' - Hulemaleri'],
			revisionParaData: (
				<span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session43 = [
	{
		introAudio: mainAudio_43,
		questionAudio: questions_43,
		wordsAudio: words_43,
		mainHeading: '1. Dagens tekst',
		mainImg: session43Img,
		subHeading: 'Bjørn',
		phonemeData: phoneme43,
		DaysTextWords: wordsInTheEnd43[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Bjørnen spiser kjøtt, fisk, planter og honning',
			'De kan løpe veldig raskt.',
			'Om vinteren går bjørnen i hi.',
			'Isbjørnen er den største bjørnen.',
		],
		summary: [
			'Bjørnen lever over hele verden, unntatt i Australia.',
			'En gruppe med bjørner kalles for en bjørnefamilie.',
			'Hannbjørner kalles for bamse, og hunbjørner blir kalt binne. Babybjørner er bjørnunger.',
			'De spiser kjøtt og fisk, samt planter, frukt, nøtter og honning.',
			'Bjørner er store dyr, med raggete pels som beskytter mot kulde.',
			'De kan løpe veldig raskt, og de er gode klatrere.',
			'I de kalde vintermånedene vil bjørnene gå i dvale.',
			'Det betyr at de kryper inn i en hule eller et hi, for å sove til våren.',
			'Isbjørnen er den største av bjørneartene.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Bjørnen lever over hele verden, unntatt i Australia. En gruppe med bjørner
					kalles for en bjørnefamilie. Hannbjørner kalles for bamse, og hunnbjørner blir
					kalt binne. Babybjørner er bjørnunger.{' '}
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					De spiser kjøtt og fisk, samt planter, frukt, nøtter og honning. Dette betyr at
					bjørner er{' '}
					<span style={{ color: 'Lime' }}>
						<b>altetende</b>{' '}
					</span>{' '}
					– de spiser hva som helst! Bjørner er store dyr, med raggete pels som{' '}
					<span style={{ color: 'Lime' }}>
						<b>beskytter</b>{' '}
					</span>{' '}
					mot kulde.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Selv om de er veldig store, er bjørner atletiske. De kan løpe veldig raskt, og
					de er gode klatrere, og mange er dyktige svømmere. I de kalde vintermånedene vil
					bjørnene gå i{' '}
					<span style={{ color: 'Lime' }}>
						<b>dvale</b>
					</span>
					. Det betyr at de kryper inn i en hule eller et hi, for å sove til våren. Dette
					kan vare i 100 dager!
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Isbjørnen er den største av{' '}
					<span style={{ color: 'Lime' }}>
						<b>bjørneartene</b>
					</span>
					. Hannbjørnen kan veie inntil 700 kg og bli inntil 3 meter lang. De er det
					største landrovdyret i verden.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva kalles hannbjørnen og hunnbjørnen?',
			'2. Hva betyr det at bjørnen er altetende?',
			'3. Hva betyr det at bjørnen går i dvale?',
			'4. Kan bjørnen svømme?',
			'5. Hvilken bjørneart er størst?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Altetende', ' - Beskytter', '- Dvale', ' - Art'],
			revisionParaData: (
				<span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];
export const session44 = [
	{
		introAudio: mainAudio_44,
		questionAudio: questions_44,
		wordsAudio: words_44,
		mainHeading: '1. Dagens tekst',
		mainImg: session44Img,
		subHeading: 'Ulv',
		phonemeData: phoneme44,
		DaysTextWords: wordsInTheEnd44[0].revisionWordsAndInstruction.words,
		easySummary: ['Ulven er et rovdyr.', 'Ulven lever i flokk.', 'Ungene kalles valper.', 'Ulven har tykk pels.'],
		summary: [
			'Ulven er et rovdyr.',
			'Den er kjøtteter.',
			'Ulven lever i grupper eller flokk.',
			'Ulven jakter på byttedyr sammen med flokken.',
			'De klare å ta dyr som er større enn dem selv.',
			'Ulven uler for å advare om fare.',
			'Ulvens ul kan høres over ti kilometer unna. Unge ulver kalles for valper.',
			'En valp lever sammen med foreldrenes flokk i minst to år.',
			'Hver flokk har sitt eget område.',
			'Ulven har tykk vinterpels.',
			'Denne pelsen er ofte grå og brun for å gi dem kamuflasje.',
			'Om våren og sommeren feller ulven vinterpelsen.',
			'Om høsten vokser den ut igjen.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Ulven er et{' '}
					<span style={{ color: 'Lime' }}>
						<b>rovdyr</b>{' '}
					</span>{' '}
					som jakter på byttedyr. Den er kjøtteter. Ulven lever i grupper eller flokk, med
					sju-åtte ulver. Ulven jakter på byttedyr sammen med flokken. De samarbeider for
					å klare å ta byttedyr som er større enn dem selv, for eksempel hjort, villsvin
					eller elg.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Ulven uler for å{' '}
					<span style={{ color: 'Lime' }}>
						<b>kommunisere</b>{' '}
					</span>{' '}
					med en annen flokk eller{' '}
					<span style={{ color: 'Lime' }}>
						<b>advare</b>{' '}
					</span>{' '}
					om fare. Hver flokk har et unikt ul. Ulvens ul kan høres over ti kilometer unna.
					Unge ulver kalles for valper. En valp lever sammen med foreldrenes flokk i minst
					to år.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Hver flokk har sitt eget område. Ulven kan bli opptil 1,60 meter lang, og veie
					rundt 40 kilo. Ulven har tykk, luftig vinterpels, som beskytter dem mot kulden.
					Denne pelsen er ofte grå og brun for å gi dem{' '}
					<span style={{ color: 'Lime' }}>
						<b>kamuflasje</b>{' '}
					</span>
					.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Om våren og sommeren feller ulven vinterpelsen. Om høsten vokser den ut igjen.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er et byttedyr?',
			'2. Hvordan klarer ulvene å ta dyr som er større enn dem selv?',
			'3. Hva betyr det at hver flokk har et unikt ul?',
			'4. Hva heter ungene til ulvene?',
			'5. Hvordan klarer ulvene å overleve ute om vinteren?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Rovdyr', ' - Advare', '- Kommunisere', ' - Kamuflasje'],
			revisionParaData: (
				<span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];

export const session45 = [
	{
		introAudio: mainAudio_45,
		questionAudio: questions_45,
		wordsAudio: words_45,
		mainHeading: '1. Dagens tekst',
		mainImg: session45Img,
		subHeading: 'Rev',
		phonemeData: phoneme45,
		DaysTextWords: wordsInTheEnd45[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Rødreven er alt-etende.',
			'Rødreven spiser mest kjøtt.',
			'Ulver og ørner jakter på rev.',
			'Rever bor i huler under jorden.',
		],
		summary: [
			'Rødreven lever vanligvis sammen i par eller i små grupper.',
			'Rødreven er altetende.',
			'Det betyr at den kan spise både planter og kjøtt.',
			'Rødreven spiser mest kjøtt.',
			'Rødreven er også byttedyr for større rovdyr.',
			'Ulver og store ørner jakter på rev.',
			'En voksen rødrev bli rundt 50 cm høy og en meter lang.',
			'Den har utmerket hørsel.',
			'De fleste rødrever har en rødlig, brun pels.',
			'Rever bor i huler under jorden. Disse hulene kalles for hi.',
			'Valpene bor ofte med foreldrene sine i sju måneder mens de vokser og modnes.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Rødreven lever{' '}
					<span style={{ color: 'Lime' }}>
						<b>vanligvis</b>{' '}
					</span>{' '}
					sammen i par eller i små grupper. Dette kan være et revepar og deres valp.
					Vanligvis er de ikke enslige dyr.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Rødreven er altetende. Det betyr at den kan spise både planter og kjøtt. Likevel
					består{' '}
					<span style={{ color: 'Lime' }}>
						<b>kostholdet</b>{' '}
					</span>{' '}
					deres stort sett av kjøtt. Vanlige byttedyr er kanin og ekorn.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Selv om reven er et rovdyr, er rødreven også byttedyr for større rovdyr. Ulver
					og store ørner jakter på rev.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					I gjennomsnitt blir en voksen rødrev rundt 50 cm høy og nesten en meter lang.
					Den har store ører, noe som betyr at de har{' '}
					<span style={{ color: 'Lime' }}>
						<b>utmerket</b>{' '}
					</span>{' '}
					hørsel. Som navnet antyder, har de fleste rødrever en{' '}
					<span style={{ color: 'Lime' }}>
						<b>karakteristisk</b>{' '}
					</span>{' '}
					rødlig, brun pels.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Rever bor i huler under jorden. Disse hulene kalles for hi. Valpene bor ofte med
					foreldrene sine i sju måneder mens de vokser og modnes.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva spiser rødreven?',
			'2. Hvem jakter på reven?',
			'3. Hvordan ser rødreven ut?',
			'4. Hva kalles det der reven bor?',
			'5. Hvor lenge bor revene sammen med foreldrene sine?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Vanligvis', ' - Kosthold', '- Utmerket', ' - Karakteristisk'],
			revisionParaData: (
				<span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];

export const session46 = [
	{
		introAudio: mainAudio_46,
		questionAudio: questions_46,
		wordsAudio: words_46,
		mainHeading: '1. Dagens tekst',
		mainImg: session46Img,
		subHeading: 'Hilsen fra Sápmi',
		phonemeData: phoneme46,
		DaysTextWords: wordsInTheEnd46[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Samene er et urfolk.',
			'Samene bor i flere land.',
			'Rune-bommen er en tromme.',
			'Samene pynter seg i samekofte.',
		],
		summary: [
			'Bouorre beavivi! Vi heter Ailo og Biret!',
			'Vi er samer, et urfolk som bor i Norge, Sverige, Finland og Russland.',
			'Vi bor i Sápmi.',
			'Sápmi er et nordsamisk ord som brukes om området der vi bor, på språket vårt og på oss selv.',
			'Den gamle samiske religionen var sjamanistisk.',
			'Det betyr at de hadde en sjaman som var folket sin forbindelse til gudene.',
			'Runebommen er samenes berømte tromme av reinskinn.',
			'Når vi skal pynte oss bruker vi en samisk folkedrakt.',
			'På norsk kalles den samedrakt eller samekofte.',
			'De finnes i flere ulike varianter, slik som med bunader.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Bouorre beavivi! Vi heter Ailo og Biret! Vi er samer, et{' '}
					<span style={{ color: 'Lime' }}>
						<b>urfolk</b>{' '}
					</span>{' '}
					som bor i Norge, Sverige, Finland og Russland. Vi bor i Sápmi.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Sápmi er et nordsamisk ord som brukes om{' '}
					<span style={{ color: 'Lime' }}>
						<b>området</b>{' '}
					</span>{' '}
					der vi bor, på språket vårt og på oss selv. De fleste samer som snakker samisk
					daglig bruker nordsamisk.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Den gamle samiske religionen var sjamanistisk. Det betyr at de hadde en sjaman
					som var folket sin{' '}
					<span style={{ color: 'Lime' }}>
						<b>forbindelse</b>{' '}
					</span>{' '}
					til gudene. Denne sjamanen ble kalt noaiden. Den kunne spå i fremtiden og var
					synsk. Runebommen er samenes berømte tromme av reinskinn. På skinnet er det malt
					en del figurer. Disse trommene ble opprinnelig brukt av noaiden.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Når vi skal pynte oss bruker vi en samisk{' '}
					<span style={{ color: 'Lime' }}>
						<b>folkedrakt</b>
					</span>
					. På norsk kalles den samedrakt eller samekofte. De finnes i flere ulike
					varianter, slik som med bunader.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor bor samene?',
			'2. Hva er det samiske ordet for området der samene bor?',
			'3. Hva er en sjaman?',
			'4. Hva er en runebomme og hva brukes den til?',
			'5. Hva kalles samenes folkedrakt?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Urfolk', ' - Område', '- Forbindelse', ' - Folkedrakt'],
			revisionParaData: (
				<span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];

export const session47 = [
	{
		introAudio: mainAudio_47,
		questionAudio: questions_47,
		wordsAudio: words_47,
		mainHeading: '1. Dagens tekst',
		mainImg: session47Img,
		subHeading: 'Hvordan levde samer før og nå?',
		phonemeData: phoneme47,
		DaysTextWords: wordsInTheEnd47[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Vi bodde i lavvo og var nomader.',
			'Vi fulgte reinen på vandring etter mat.',
			'I dag bor samer i vanlige hus.',
			'Vi arbeider med ulike ting.',
		],
		summary: [
			'Tradisjonelt har vi levd av fiske, jakt, fangst, sanking, håndverk og reindrift.',
			'Vi bodde i lavvo og var nomader som reiste rundt.',
			'Vi fulgte reinen på vandring mellom kysten og innlandet etter mat.',
			'Dagens samer bor i vanlige hus og arbeider med ulike ting.',
			'Noen av oss arbeider med reindrift og fiske.',
			'Andre arbeider i butikk, på skoler, som bønder, i restauranter, i helsevesenet og på anleggsplasser.',
			'Ja, vi er like forskjellige som alle andre.',
			'Det er viktig for oss å ta vare på tradisjoner.',
			'Vi er et urfolk og en minoritet.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Tradisjonelt har vi levd av fiske, jakt, fangst, sanking, håndverk og reindrift.
					Vi bodde i lavvo og var{' '}
					<span style={{ color: 'Lime' }}>
						<b>nomader</b>{' '}
					</span>{' '}
					som reiste rundt. Vi fulgte reinen på vandring mellom kysten og innlandet etter
					mat. Det var vanlig å dele inn samene i fire hovedgrupper ut ifra hva vi jobbet
					med og hvor vi levde: sjøsamer, elve- og innsjøsamer, skogsamer og fjellsamer.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Dagens samer bor i vanlige hus og arbeider med ulike ting. Noen av oss arbeider
					med reindrift og fiske. Mange{' '}
					<span style={{ color: 'Lime' }}>
						<b>veksler</b>{' '}
					</span>{' '}
					mellom vinterboplass og sommerboplass. De flytter sammen med reinen. Andre
					arbeider i butikk, på skoler, som bønder, i restauranter, i helsevesenet og på
					anleggsplasser. Ja, vi er like forskjellige som alle andre.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Det er viktig for oss å ta vare på tradisjoner. Vi har Sametinget som skal ta
					vare på samiske{' '}
					<span style={{ color: 'Lime' }}>
						<b>rettigheter</b>
					</span>
					, fordi vi er et{' '}
					<span style={{ color: 'Lime' }}>
						<b>urfolk</b>{' '}
					</span>{' '}
					og en{' '}
					<span style={{ color: 'Lime' }}>
						<b>minoritet</b>
					</span>
					.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva jobbet samene med før?',
			'2. Hvor bodde de?',
			'3. Hva jobber samene med nå?',
			'4. Hvor bor de nå?',
			'5. Hvorfor tror du det er viktig for samene å ta vare på tradisjonene?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Urfolk', ' - minoritet', '- nomader', ' - rettigheter', ' - veksler'],
			revisionParaData: (
				<span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];

export const session48 = [
	{
		introAudio: mainAudio_48,
		questionAudio: questions_48,
		wordsAudio: words_48,
		mainHeading: '1. Dagens tekst',
		mainImg: session48Img,
		subHeading: 'Behandling av samer i Norge',
		phonemeData: phoneme48,
		DaysTextWords: wordsInTheEnd48[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Samiske barn fikk ikke snakke samisk på skolen.',
			'De måtte lære norsk og fikk ikke leve slik de ønsket.',
			'Mange måtte bo på internatskoler langt borte fra foreldrene og noen mistet språket sitt.',
		],
		summary: [
			'Tidlig på 1900-tallet ble det vanskelig for samene i Norge.',
			'Myndighetene ønsket at de skulle bli norske som alle andre.',
			'Samiske barn ble tatt fra foreldrene og flytta til internatskoler langt hjemmefra. ',
			'Der skulle de lære å bli norske. ',
			'De fikk ikke undervisning på samisk. ',
			'De fikk heller ikke leve slik de selv ønsket sammen med foreldrene på gården, eller flytte med reinflokkene. ',
			'Fra 1990 fikk samiske barn rett å lære samisk på skolen. ',
			'I 1997 besøkte Kong Harald Sametinget der han beklaget fornorskningen samene som hadde vart i mer enn 100 år.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Tidlig på 1900-tallet ble det vanskelig for samene som bor i Norge.{' '}
					<span style={{ color: 'Lime' }}>
						<b>Myndighetene</b>{' '}
					</span>{' '}
					mente at de måtte bli norske og gi slipp på sitt språk, sin kultur og tradisjon.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Samiske barn fikk ikke undervisning på samisk i skolen, og det ble ikke holdt
					gudstjenester på samisk. De fleste samiske barn måtte bo på internatskoler der
					de ble tvunget til å snakke, lese og skrive på norsk. Mange samiske barn lærte
					ikke å lese og skrive verken på samisk eller norsk. Når de flytta fra foreldrene
					på gården eller familien i reindrifta, mistet mange sitt samiske språk. Vi vet
					at samiske barn ble utsatt for{' '}
					<span style={{ color: 'Lime' }}>
						<b>rasisme</b>{' '}
					</span>{' '}
					i internatskolene og fikk problemer som varte lenge etterpå.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Fra 1990 fikk samiske barn rett til å få undervisning på samisk. Samisk og norsk
					ble{' '}
					<span style={{ color: 'Lime' }}>
						<b>likeverdige</b>{' '}
					</span>{' '}
					språk i samiske områder. I noen områder er samiske barn{' '}
					<span style={{ color: 'Lime' }}>
						<b>tospråklige</b>
					</span>
					. I 1997 besøkte Kong Harald Sametinget der han beklaget{' '}
					<span style={{ color: 'Lime' }}>
						<b>fornorskningen</b>{' '}
					</span>{' '}
					av samene som hadde vart i mer enn 100 år.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvem bestemte at samiske barn måtte bli norske, og at de måtte slutte å snakke samisk?',
			'2. Hva skjedde med de samiske barna når de bodde på internatskolene?',
			'3. Når ble samisk og norsk likeverdige språk i samiske områder (Sápmi)?',
			'4. Hvem beklaget fornorskningen av samene på Sametinget i 1997?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Myndigheter', ' - Fornorskning', '- Rasisme', ' - Likeverdig', ' - Tospråklig'],
			revisionParaData: (
				<span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];

export const session49 = [
	{
		introAudio: mainAudio_49,
		questionAudio: questions_49,
		wordsAudio: words_49,
		mainHeading: '1. Dagens tekst',
		mainImg: session49Img,
		subHeading: 'Samenes nasjonaldag',
		phonemeData: phoneme49,
		DaysTextWords: wordsInTheEnd49[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Samenes nasjonaldag er 6. februar.',
			'Da feires samisk kultur.',
			'Det er fest og aktiviteter for barn.',
			'Mange heiser det samiske flagget.',
		],
		summary: [
			'6. februar i 1917 ble det første landsmøtet for samer holdt.',
			'Dette var første gang at samer fra Norge og Sverige var samlet for å snakke om sine rettigheter.',
			'Derfor feires samenes nasjonaldag den 6. februar hvert år.',
			'Samenes nasjonaldag blir også kalt samefolkets dag.',
			'Dette er en dag for å feire samisk kultur.',
			'Den blir feiret i alle land der det bor samer.',
			'Denne dagen feirer mange samer med fest og aktiviteter for barn.',
			'De tar på seg samiske festklær, kofter, og synger den samiske nasjonalsangen.',
			'Mange heiser det samiske flagget.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					6. februar i 1917 ble det første{' '}
					<span style={{ color: 'Lime' }}>
						<b>landsmøtet</b>{' '}
					</span>{' '}
					for samer holdt. Dette var første gang at samer fra Norge og Sverige var samlet
					for å snakke om sine rettigheter.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Derfor feires samenes nasjonaldag den 6. februar hvert år.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Samenes nasjonaldag blir også kalt samefolkets dag. Dette er en dag for å feire
					samisk{' '}
					<span style={{ color: 'Lime' }}>
						<b>kultur</b>
					</span>
					. Den blir feiret i alle land der det bor samer. Samefolkets dag ble feiret for
					første gang i 1993.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Denne dagen feirer mange samer med fest og aktiviteter for barn. De tar på seg
					samiske festklær, kofter, og synger den samiske nasjonalsangen.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>Mange heiser det samiske flagget.</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Det samiske flagget ble først brukt i 1986. En samisk{' '}
					<span style={{ color: 'Lime' }}>
						<b>kunstner</b>
					</span>{' '}
					laget flagget. På flagget er de samiske fargene rød, blå, gul og grønn. Midt på
					flagget er det en ring. Det skal være en halv sol og en halv måne. Ringen
					forteller også at samefolket{' '}
					<span style={{ color: 'Lime' }}>
						<b>holder sammen</b>
					</span>
					.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvorfor feires samenes nasjonaldag 6.februar?',
			'2. Vet du et annet navn for samenes nasjonaldag?',
			'3. Hvor feires denne dagen?',
			'4. Hva gjør samene på sin nasjonaldag?',
			'5. Hvilke farger har det samiske flagget? ',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Landsmøte', ' - Kultur', '- Holde sammen', ' - Kunstner'],
			revisionParaData: (
				<span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];

export const session50 = [
	{
		introAudio: mainAudio_50,
		questionAudio: questions_50,
		wordsAudio: words_50,
		mainHeading: '1. Dagens tekst',
		mainImg: session50Img,
		subHeading: 'Steinalderen 1',
		phonemeData: phoneme50,
		DaysTextWords: wordsInTheEnd50[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Folk laget redskap og våpen av stein.',
			'De brukte steinvåpen til å jakte på dyr.',
			'De bodde ikke på en plass. ',
			'Men flyttet rundt for å finne mat.',
		],
		summary: [
			'Steinalderen er den tidsepoken hvor folk laget redskap og våpen av stein.',
			'Spyd og økser ble laget av skarpe biter av stein som ble festet til pinner.',
			'Menneskene brukte steinvåpen til å jakte.',
			'Steinredskaper ble brukt til å kutte ting med.',
			'Menneskene som levde i steinalderen blir kalt for jegere og sankere.',
			'Det betyr at de levde i små grupper som hele tiden var på reise.',
			'De måtte flytte på seg for å finne mat.',
			'Det var ingen byer eller landsbyer enda.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Steinalderen er den{' '}
					<span style={{ color: 'Lime' }}>
						<b>tidsepoken</b>
					</span>{' '}
					hvor folk laget redskap og våpen av stein. For eksempel ble hammeren laget av
					store, tunge steiner. Spyd og økser ble laget av skarpe biter av stein som ble
					festet til pinner. Menneskene brukte steinvåpen til å jakte på dyr. De brukte
					stein til å bygge hus. Noen stein
					<span style={{ color: 'Lime' }}>
						<b>redskaper</b>
					</span>{' '}
					ble brukt til å knuse og slå mat, eller å slipe ting til pudder. Stein
					<span style={{ color: 'Lime' }}>
						<b>redskaper</b>
					</span>{' '}
					ble brukt til å kutte ting med.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Menneskene som levde i steinalderen blir kalt for jegere og{' '}
					<span style={{ color: 'Lime' }}>
						<b>sankere</b>
					</span>
					. Det betyr at de levde i små grupper som hele tiden var på reise. De måtte
					flytte på seg for å finne mat. Dette var en{' '}
					<span style={{ color: 'Lime' }}>
						<b>periode</b>
					</span>{' '}
					i historien før folk hadde begynt å{' '}
					<span style={{ color: 'Lime' }}>
						<b>bosette</b>
					</span>{' '}
					seg på et fast sted. Det var ingen byer eller landsbyer enda.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvorfor heter denne tiden steinalderen?',
			'2. Hva laget menneskene av stein?',
			'3. Hva ble menneskene som levde i denne tiden kalt for?',
			'4. Hvor bodde de?',
			'5. Hva tror du barna gjorde?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Tidsepoke', ' - Redskaper', '- Sankere', ' - Bosette', '- Periode'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];

export const session51 = [
	{
		introAudio: mainAudio_51,
		questionAudio: questions_51,
		wordsAudio: words_51,
		mainHeading: '1. Dagens tekst',
		mainImg: session51Img,
		subHeading: 'Steinalderen 2',
		phonemeData: phoneme51,
		DaysTextWords: wordsInTheEnd51[0].revisionWordsAndInstruction.words,
		easySummary: [
			'I steinalderen var husene enkle.',
			'Husene kunne flyttes.',
			'Menneskene laget enkle båter.',
			'De brukte båtene når de fisket.',
		],
		summary: [
			'I steinalderen var husene mye enklere enn i dag.',
			'Ofte var de laget av tre og hauger med stein.',
			'Husene var lette å ta ned og derfor kunne de flytte mye lettere enn vi gjør i dag.',
			'Steinaldermenneskene jaktet på alle typer dyr.',
			'Noen av disse finnes ikke lenger, som for eksempel den ullhårete mammuten.',
			'Den er utryddet.',
			'Steinaldermenneskene laget verktøy og våpen som skulle hjelpe de i hverdagen og under jakten.',
			'De lærte også å lage enkle båter.',
			'Dette førte til at de kunne fiske.',
			'Folk i steinalderen spiste ikke bare kjøtt.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					I steinalderen var husene mye{' '}
					<span style={{ color: 'Lime' }}>
						<b>enklere</b>
					</span>{' '}
					enn i dag. Ofte var de laget av tre og hauger med stein. Det gjorde at
					menneskene kunne bygge husene der de{' '}
					<span style={{ color: 'Lime' }}>
						<b>vandret</b>
					</span>
					. Husene var lette å ta ned og derfor kunne de flytte mye lettere enn vi gjør i
					dag.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					Steinaldermenneskene jaktet på alle{' '}
					<span style={{ color: 'Lime' }}>
						<b>typer</b>
					</span>{' '}
					dyr. Noen av disse finnes ikke lenger, som for eksempel den ullhårete mammuten.
					Den er{' '}
					<span style={{ color: 'Lime' }}>
						<b>utryddet</b>
					</span>
					.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					Steinaldermenneskene laget verktøy og våpen som skulle hjelpe de i hverdagen og
					under jakten. Først brukte de spisse pinner som spyd. Senere{' '}
					<span style={{ color: 'Lime' }}>
						<b>forbedret</b>
					</span>{' '}
					de redskapene ved å bruke skarpe steiner for å lage skarpere spyd og piler. De
					lærte også å lage enkle båter. Dette førte til at de kunne fiske. Folk i
					steinalderen spiste ikke bare kjøtt.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva gjorde menneskene med husene når de flyttet?',
			'2. Hva jaktet menneskene på?',
			'3. Lever alle disse dyrene i dag?',
			'4. De fisket også, hvordan tror du de klarte det?',
			'5. Hva laget de spyd og piler av før de begynte å bruke stein?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
					</span>
					<br></br>
				</span>
			),
			words: ['- Enklere', ' - Vandre', '- Typer', ' - Utryddet', '- Forbedre'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						a. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						b. Gradbøyning av adjektiv og adverb, entall/flertall, bøying av verb,
						etc. av sentrale ord i dagens tekst.
					</span>
				</span>
			),
		},
	},
];

export const session52 = [
	{
		introAudio: mainAudio_52,
		questionAudio: questions_52,
		wordsAudio: words_52,
		mainHeading: '1. Dagens tekst',
		mainImg: session52Img,
		subHeading: 'Steinalderen 3',
		phonemeData: phoneme52,
		DaysTextWords: wordsInTheEnd52[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Folk lærte å lage mat over bål.',
			'Det var veldig kaldt i steinalderen.',
			'De brukte klær av skinn og pels.',
			'Folk begynte å bruke metall i stedet for stein.',
			'Da sluttet steinalderen.',
		],
		summary: [
			'Folk i steinalderen lette også etter frukt, nøtter og grønnsaker.',
			'De lærte å lage mat over bål. Dette var veldig viktig.',
			'De laget potter av leire slik at de kunne lage og oppbevare mat bedre.',
			'Det var veldig kaldt i steinalderen.',
			'Jorden var i en istid. Det var mye is og snø.',
			'De brukte skinn og pels fra dyrene de hadde jaktet på slik at de kunne holde seg varm.',
			'Steinalderen sluttet da folk lærte seg hvordan de kunne bruke metall i stedet for stein.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Folk i steinalderen lette også etter frukt, nøtter og grønnsaker som de kunne
					sanke og spise.
				</p>

				<p style={{ fontSize: '22px' }}>
					De lærte å lage mat over bål. Dette var veldig viktig. Senere lærte de også mer
					om verden rundt seg og de startet å bruke nye{' '}
					<span style={{ color: 'Lime' }}>
						<b>materialer</b>
					</span>
					. De laget potter av leire slik at de kunne lage og oppbevare mat bedre.
				</p>

				<p style={{ fontSize: '22px' }}>
					Det var veldig kaldt i steinalderen. Jorden var i en istid, noe som betyr at{' '}
					<span style={{ color: 'Lime' }}>
						<b>temperaturen</b>
					</span>{' '}
					var mye lavere enn i dag. Det var mye mer is og snø.
				</p>

				<p style={{ fontSize: '22px' }}>
					Det var viktig for steinalderfolket å{' '}
					<span style={{ color: 'Lime' }}>
						<b>fortsette</b>
					</span>{' '}
					å flytte på seg. De brukte skinn og pels fra dyrene de hadde jaktet på slik at
					de kunne holde seg varm.
				</p>

				<p style={{ fontSize: '22px' }}>
					Steinalderen sluttet for ca. 5000 år siden. Den tok slutt da folk lærte seg
					hvordan de kunne bruke{' '}
					<span style={{ color: 'Lime' }}>
						<b>metall</b>
					</span>{' '}
					i stedet for stein.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvorfor tror du det var viktig at folk i steinalderen lærte å lage mat over bål?',
			'2. Hva brukte de leire til?',
			'3. Hvorfor var det kaldere i steinalderen enn i dag?',
			'4. Hva gjorde de for å holde seg varme?',
			'5. Hvorfor tok steinalderen slutt?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Materialer', ' - Temperatur', '- Metall', ' - Fortsette'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-ist.</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>

					<span className='rw-extra-text'>
						Når et ord slutter på -ist betyr det en person er opptatt av noe eller
						gjør noe spesielt, som peker mot begynnelsen av ordet - for eksempel
						gitar i gitarist. Hva tror du gitarist betyr?
						<li>
							Hva kommer før -ist i ordet pianist? Hva tror du pianist
							betyr?
						</li>
						<li>Hva kommer før -ist i ordet turist? Hva tror du turist betyr?</li>
						<li>Hva kommer før -ist i buddhist? Hva tror du buddhist betyr?</li>
						<li>Kan du komme på noen flere ord som slutter på -ist?</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session53 = [
	{
		introAudio: mainAudio_53,
		questionAudio: questions_53,
		wordsAudio: words_53,
		mainHeading: '1. Dagens tekst',
		mainImg: session53Img,
		subHeading: 'Bronsealderen 1',
		phonemeData: phoneme53,
		DaysTextWords: wordsInTheEnd53[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Menneskene brukte bronse for å lage redskap og våpen.',
			'Bronse blir laget ved å blande kobber og tinn.',
			'Kobber og tinn finnes i fjell og steiner.',
			'For å utvinne det, måtte man varme opp stein.',
		],
		summary: [
			'Bronsealderen var en tidsepoke da menneskene brukte bronse for å lage redskap og våpen. ',
			'Bronse er et hardt, rødbrunt og blankt metall.',
			'Det blir produsert ved å blande kobber og tinn.',
			'I bronsealderen fant folk kobber og tinn i fjell og steiner.',
			'For å utvinne det, måtte man varme opp stein.',
			'Tinn og kobber smeltet og rant ut. Da metallet ble avkjølt ble det hardt igjen.',
			'Så smeltet man kobberet og tinnet på nytt, og blandet de to metallene. Da ble det bronse.',
			'Bronsen kan bli varmet opp og banket i riktig form, eller støpt i støpeformer.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Historien til menneskene er fortalt på mange ulike måter. For eksempel snakker
					en ofte om ulike «aldre» i menneskets historie. Disse tidsepokene fikk navn
					etter hvilke materiale man brukte for å lage redskaper og våpen i den tiden.
				</p>

				<p style={{ fontSize: '22px' }}>
					Bronsealderen var en{' '}
					<span style={{ color: 'Lime' }}>
						<b>tidsepoke</b>
					</span>{' '}
					da menneskene brukte bronse for å lage redskap og våpen. Bronse er et hardt,
					rødbrunt og blankt metall. Det blir{' '}
					<span style={{ color: 'Lime' }}>
						<b>produsert</b>
					</span>{' '}
					ved å blande kobber og tinn.
				</p>

				<p style={{ fontSize: '22px' }}>
					I bronsealderen fant folk kobber og tinn i fjell og steiner. For å{' '}
					<span style={{ color: 'Lime' }}>
						<b>utvinne</b>
					</span>{' '}
					det, måtte man varme opp stein. Tinn og kobber smeltet og rant ut. Da metallet
					ble avkjølt ble det hardt igjen. Så smeltet man kobberet og tinnet på nytt, og
					blandet de to metallene. Da ble det bronse. Bronsen kan bli varmet opp og banket
					i riktig form, eller støpt i{' '}
					<span style={{ color: 'Lime' }}>
						<b>støpeformer</b>
					</span>
					.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvorfor heter denne tidsepoken bronsealderen?',
			'2. Hvorfor har de ulike tidsepokene forskjellige navn?',
			'3. Hva er bronse?',
			'4. Hvordan lages bronse?',
			'5. Hvordan lagde de ulike ting av bronse?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Tidsepoke', ' - Produsere', ' - Støpeform', ' - Utvinne'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>U</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>

					<span className='rw-extra-text'>
						Når vi setter u- foran noen ord, får ordene motsatt betydning. For
						eksempel: Ulike, uvenn, uheldig, usikker, usynlig.
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?{' '}
						</li>
						<li>
							Kan du komme på noen flere ord som begynner på u, og gir
							motsatt betydning av ordet?
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session54 = [
	{
		introAudio: mainAudio_54,
		questionAudio: questions_54,
		wordsAudio: words_54,
		mainHeading: '1. Dagens tekst',
		mainImg: session54Img,
		subHeading: 'Bronsealderen 2',
		phonemeData: phoneme54,
		DaysTextWords: wordsInTheEnd54[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Sverd og rustninger ble laget av bronse.',
			'De ble brukt i kamp.',
			'Noen bronse-redskap ble brukt til å lage mat.',
			'Sigder ble brukt til å kutte planter.',
		],
		summary: [
			'Sverd og rustninger ble laget av bronse.',
			'De ble brukt i kamp. Andre bronsevåpen ble brukt i jakt på dyr.',
			'Noen bronseredskap ble brukt til å lage mat.',
			'En kunne lage jordbruksredskaper, som ploger, av bronse.',
			'En plog er en type graveredskap.',
			'Store dyr dro plogen over jorden og vendte den.',
			'Sigder ble også laget av bronse.',
			'Sigder ble brukt til å kutte planter.',
			'Disse endringene i jordbruket gjorde det lettere å skaffe nok mat.',
			'Men det er vanskelig å si nøyaktig når bronsealderen startet.',
			'Det er fordi ulike folkegrupper begynte å bruke bronse til ulike tider.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Sverd og rustninger ble laget ved at bronsen ble varmet opp og banket i riktig
					form, eller støpt i støpeformer. De ble brukt i kamp. Andre bronsevåpen ble
					brukt i jakt på dyr.
				</p>

				<p style={{ fontSize: '22px' }}>
					Noen bronseredskap ble brukt til å lage mat. Bronse ble også brukt til å lage
					kokekar og panner.
				</p>

				<p style={{ fontSize: '22px' }}>
					En kunne lage{' '}
					<span style={{ color: 'Lime' }}>
						<b>jordbruksredskaper</b>
					</span>
					, som ploger, av bronse. En plog er en type graveredskap. Store dyr dro plogen
					over jorden og vendte den. Slik{' '}
					<span style={{ color: 'Lime' }}>
						<b>klargjorde</b>
					</span>{' '}
					man jorden til såing.
				</p>

				<p style={{ fontSize: '22px' }}>
					Sigder ble også laget av bronse. En sigd er et krumt blad. Noen ganger ble
					sigden festet på enden av en pinne. Sigder ble brukt til å kutte planter og
					samle frukt og grønnsaker.
				</p>
				<p style={{ fontSize: '22px' }}>
					Disse{' '}
					<span style={{ color: 'Lime' }}>
						<b>endringene</b>
					</span>{' '}
					i jordbruket gjorde det lettere å skaffe nok mat.
				</p>
				<p style={{ fontSize: '22px' }}>
					Bronsealderen startet for om lag 5000 år siden i Mesopotamia. Men det er
					vanskelig å si{' '}
					<span style={{ color: 'Lime' }}>
						<b>nøyaktig</b>
					</span>{' '}
					når bronsealderen startet. Det er fordi ulike folkegrupper begynte å bruke
					bronse til ulike tider.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva kunne de lage av bronse?',
			'2. Hva er en plog?',
			'3. Hvordan ble jorden klargjort til såing?',
			'4. Hvorfor ble det lettere å skaffe mat i bronsealderen?',
			'5. Vet vi nøyaktig når bronsealderen startet?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Jordbruksredskap', ' - Klargjøre', ' - Endringer', ' - Nøyaktig'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-ing</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					<span className='rw-extra-text'>
						Når et ord slutter på -ing, betyr det «å gjøre noe» - og peker tilbake
						på begynnelsen av ordet. For eksempel «løping» handler om «det å løpe»
						som i «løping inne i klasserommet er ikke er lov».<br></br>
						Synging handler om det å synge.
						<li>Hva kommer før –«ing» i såing? Hva tror du såing betyr? </li>
						<li>Hva kommer før –«ing» i endring? Hva tror du endring betyr?</li>
						<li>Hva kommer før –«ing» i blanding? Hva tror du blanding betyr?</li>
						<li>Hva kommer før –«ing» i skriving? Hva tror du skriving betyr?</li>
					</span>
					<br></br>
					Kan du komme på flere ord som slutter på -ing?
				</span>
			),
		},
	},
];

export const session55 = [
	{
		introAudio: mainAudio_55,
		questionAudio: questions_55,
		wordsAudio: words_55,
		mainHeading: '1. Dagens tekst',
		mainImg: session55Img,
		subHeading: 'Bronsealderen 3',
		phonemeData: phoneme55,
		DaysTextWords: wordsInTheEnd55[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Det kom mange oppfinnelser i bronsealderen.',
			'En av disse var skriving og skriftspråk.',
			'Folk skrev ned historien og lover.',
			'Jordbruket ble også oppfunnet.',
		],
		summary: [
			'Det kom mange oppfinnelser i bronsealderen.',
			'En av disse var skriving og skriftspråk.',
			'Tiden før bronsealderen, blant annet steinalderen, blir kalt forhistorisk.',
			'Det var tiden før man kunne skrive.',
			'Mange eksempler på skrifter fra bronsealderen har blitt funnet over hele verden.',
			'Et berømt eksempel er de egyptiske hieroglyfene.',
			'Folk skrev ikke bare ned historien, de skrev også ned lover.',
			'Dette var viktig, for jordbruket ble også oppfunnet i bronsealderen.',
			'Folk levde ikke lenger som jegere og sankere.',
			'I stedet slo de seg sammen i grupper i større samfunn.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Bronse er en blanding av tinn og kobber, to metaller som kan utvinnes fra
					bakken. En blanding av metall kalles for{' '}
					<span style={{ color: 'Lime' }}>
						<b>legering</b>
					</span>
					. Bronse er hardere enn begge metallene det er laget av. Det gjør at det er
					svært nyttig når en skal lage redskap og våpen.
				</p>
				<p style={{ fontSize: '22px' }}>
					Med slike redskap og våpen oppdaget folket i bronsealderen nye måter å{' '}
					<span style={{ color: 'Lime' }}>
						<b>kontrollere</b>
					</span>{' '}
					omverden på.
				</p>
				<p style={{ fontSize: '22px' }}>
					Det kom mange oppfinnelser i bronsealderen. En av disse var skriving og
					skriftspråk. Tiden før bronsealderen, blant annet steinalderen, blir kalt{' '}
					<span style={{ color: 'Lime' }}>
						<b>forhistorisk</b>
					</span>
					. Det var tiden før man kunne skrive.
				</p>
				<p style={{ fontSize: '22px' }}>
					Mange eksempler på skrifter fra bronsealderen har blitt funnet over hele verden.
					Et berømt eksempel er de egyptiske hieroglyfene. Folk skrev ikke bare ned
					historien, de skrev også ned lover. Dette var viktig, for jordbruket ble også
					oppfunnet i bronsealderen. Folk levde ikke lenger som jegere og sankere. I
					stedet slo de seg sammen i grupper i større samfunn.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er en legering?',
			'2. Kan du nevne to ting som ble oppfunnet i bronsealderen?',
			'3. Hva er hieroglyfer?',
			'4. Når var forhistorisk tid?',
			'5. Hvorfor tror du det var viktig å skrive ned lover?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Legering', ' - Kontrollere', ' - Bofaste', ' - Forhistorisk'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>opp-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Oppfinnelse, oppgave, oppleve, oppdage<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?{' '}
						</li>
						<li>Kan du komme på noen flere ord som begynner på opp?</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session56 = [
	{
		introAudio: mainAudio_56,
		questionAudio: questions_56,
		wordsAudio: words_56,
		mainHeading: '1. Dagens tekst',
		mainImg: session56Img,
		subHeading: 'Bronsealderen 4',
		phonemeData: phoneme56,
		DaysTextWords: wordsInTheEnd56[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Flere mennesker ble bofaste.',
			'De dyrket jorden og hadde husdyr.',
			'Menneskene bodde ofte i langhus.',
			'De bodde sammen med husdyrene.',
		],
		summary: [
			<p>Innføring av jordbruk og husdyrhold gjorde at det ble lettere å overleve.</p>,
			<p>Folk slapp å flytte rundt for å jakte på vilt.</p>,
			<p>Flere mennesker ble bofaste.</p>,

			<p>
				Menneskene og{' '}
				<span style={{ color: 'Gold' }}>
					<b>husdyrene</b>
				</span>{' '}
				bodde sammen i langhus.
			</p>,
			<p>
				Dette gjorde de for å{' '}
				<span style={{ color: 'Gold' }}>
					<b>utnytte</b>
				</span>{' '}
				varmen fra dyrene.
			</p>,
			<p>Jakt og sanking var viktig lenger nord i landet vårt.</p>,
			<p>Det oppsto landsbyer og byer.</p>,
			<p>Disse måtte beskyttes av lover og regler.</p>,
			<p>Det er funnet spor etter bronsealderen over hele verden.</p>,
			<p>I Norge er det gjort funn fra bronsealderen helt nord til Harstad.</p>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Innføring av jordbruk og husdyrhold gjorde at det ble lettere å overleve. Folk
					slapp å flytte rundt for å jakte på vilt for å{' '}
					<span style={{ color: 'Lime' }}>
						<b>skaffe</b>
					</span>{' '}
					nok mat. Flere mennesker ble bofaste og dyrket jorden og hadde husdyr.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Folk i Norge hadde kontakt med folk lenger sør i Europa. De byttet varer mot å
					få bronsegjenstander tilbake.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Nordiske bronsealderhus var ofte langhus der menneskene og{' '}
					<span style={{ color: 'Lime' }}>
						<b>husdyrene</b>
					</span>{' '}
					bodde sammen. Dette gjorde de for å{' '}
					<span style={{ color: 'Lime' }}>
						<b>utnytte</b>
					</span>{' '}
					varmen fra dyrene i et kaldt klima som det er i Norden. Jakt og sanking var
					viktig lenger nord i landet vårt. Flere steder var jakt viktigere enn jordbruk.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Det oppsto landsbyer og byer. Disse måtte{' '}
					<span style={{ color: 'Lime' }}>
						<b>reguleres</b>
					</span>{' '}
					og beskyttes av lover, regler, av konger og soldater.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Arkeologer og historikere har funnet spor etter bronsealderen over hele verden.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					I Norge har det vært flest funn fra bronsealderen i Rogaland, men det er gjort
					bronsefunn helt nord til Harstad.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvorfor tror du at innføring av jordbruk og husdyrhold gjorde det lettere å overleve?',
			'2. Hva gjorde folk i Norge når de hadde kontakt med folk lenger sør i Europa?',
			'3. Hva er et langhus?',
			'4. Hvorfor bodde menneskene i samme hus som dyrene?',
			'5. Er det funnet ting fra bronsealderen i Norge?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Skaffe', ' - Husdyr', ' - Utnytte', ' - Reguleres'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>inn-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Innføring, innbygger, innkjøp, innrømme<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?{' '}
						</li>
						<li>Kan du komme på noen flere ord som slutter begynner på inn-?</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session57 = [
	{
		introAudio: mainAudio_57,
		questionAudio: questions_57,
		wordsAudio: words_57,
		mainHeading: '1. Dagens tekst',
		mainImg: session57Img,
		subHeading: 'Jernalderen',
		phonemeData: phoneme57,
		DaysTextWords: wordsInTheEnd57[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Jern er et blankt, gråfarget metall.',
			'Det kan man finne i ulike steiner og i myrer.',
			'Jern ble brukt til å lage mynter med.',
			'Med mynter kunne folk kjøpe og selge ting.',
		],
		summary: [
			<p>I jernalderen ble redskaper og våpen laget av jern.</p>,
			<p>
				Jern er et blankt, gråfarget metall. Det kan man finne i ulike steiner og i{' '}
				<span style={{ color: 'Gold' }}>
					<b>myrer</b>
				</span>
				.
			</p>,
			<p>Når man varmer opp jern og slår på det, kan man forme det som man vil.</p>,
			<p>Sverd ble brukt i krig. Andre jernvåpen ble brukt når man skulle jakte på dyr.</p>,
			<p>Sag, hammer og spiker ble brukt når man skulle bygge ting.</p>,
			<p>En person som lager ting av jern, kaller vi for en smed.</p>,
			<p>
				Jern ble brukt til å lage{' '}
				<span style={{ color: 'Gold' }}>
					<b>mynter</b>
				</span>{' '}
				med. Med{' '}
				<span style={{ color: 'Gold' }}>
					<b>mynter</b>
				</span>{' '}
				kunne folk kjøpe og selge ting.
			</p>,
			<p>
				En annen viktig oppdagelse var{' '}
				<span style={{ color: 'Gold' }}>
					<b>kvernsteinen</b>
				</span>
				. Den kunne{' '}
				<span style={{ color: 'Gold' }}>
					<b>knuse</b>
				</span>{' '}
				korn til mel.
			</p>,
			<p>Melet kunne man så bruke til å lage brød.</p>,
			<p>Vikingtiden er en del av jernalderen i Norge.</p>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					I jernalderen brukte folk jern som de laget redskaper og våpen av.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Jernet er et blankt, gråfarget metall. Jern er et metall som man kan finne i
					ulike steiner og i{' '}
					<span style={{ color: 'Lime' }}>
						<b>myrer</b>
					</span>
					. Når man varmer opp jern og slår på det, kan man forme det som man vil. Sverdet
					ble laget på den måten.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Sverd ble brukt i krig. Andre jernvåpen ble brukt når man skulle jakte på dyr.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Jernredskaper, som sag og hammer, ble brukt når man skulle bygge ting. Folk
					laget også spiker av jern.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					En person som lager ting av jern, kaller vi for en smed. Jern ble brukt til å
					lage{' '}
					<span style={{ color: 'Lime' }}>
						<b>mynter</b>
					</span>{' '}
					med. Med{' '}
					<span style={{ color: 'Lime' }}>
						<b>mynter</b>
					</span>{' '}
					kunne folk kjøpe og selge ting. En annen viktig oppdagelse var{' '}
					<span style={{ color: 'Lime' }}>
						<b>kvernsteinen</b>
					</span>
					. Dette var et redskap som var laget av to steiner som{' '}
					<span style={{ color: 'Lime' }}>
						<b>knuste</b>
					</span>{' '}
					korn til mel. Melet kunne man så bruke til å lage brød.
				</p>
				<p style={{ fontSize: '22px' }}>Vikingtiden er en del av jernalderen i Norge.</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor kan man finne jern?',
			'2. Hvordan fikk de penger i jernalderen?',
			'3. Hva kalles en som lager ting av jern?',
			'4. Hva er en kvernstein?',
			'5. Hvorfor tror du kvernstein var en viktig oppfinnelse?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Myrer', ' - Mynter', ' - Knuse', ' - Kvernstein'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-else</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Oppdagelse, opplevelse, begynnelse, skuffelse<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>Kan du komme på noen flere ord som slutter på -else?</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session58 = [
	{
		introAudio: mainAudio_58,
		questionAudio: questions_58,
		wordsAudio: words_58,
		mainHeading: '1. Dagens tekst',
		mainImg: session58Img,
		subHeading: 'Vikingtiden',
		phonemeData: phoneme58,
		DaysTextWords: wordsInTheEnd58[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Vikingene bygde skip som de brukte for å utforske nye land.',
			'Disse skipene var lange og smale.',
			'Vikingene var bønder og krigere.',
			'De seilte til andre land og kriget.',
		],
		summary: [
			'Vikingene var en gruppe med mennesker som levde i Nord-Europa.',
			'Vikingene bygde spesielle skip som de brukte for å utforske nye land.',
			'Disse skipene var lange og smale.',
			'De var bygd for fart og de hadde årer for å få de raskt gjennom vannet.',
			'Noen skip hadde også seil.',
			'Vikingene var bønder og krigere.',
			'De seilte til andre land og kriget med ander menneskegrupper.',
			'De stjal landet deres og verdifulle ting.',
			'Noen vikinger reiste til nye land for å bosette seg, få familie og dyrke jorden.',
			'Vikingene var flinke bønder og håndverkere.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Vikingene var en gruppe med mennesker som levde i Nord- Europa. De levde i en{' '}
					<span style={{ color: 'Lime' }}>
						<b>region</b>
					</span>{' '}
					som vi i dag kjenner som Danmark, Sverige og Norge. Vikingtiden var rundt år 800
					– 1050 e.kr. Vikingene bygde{' '}
					<span style={{ color: 'Lime' }}>
						<b>spesielle</b>
					</span>{' '}
					skip som de brukte for å{' '}
					<span style={{ color: 'Lime' }}>
						<b>utforske</b>
					</span>{' '}
					nye land. Disse skipene var lange og smale. De var bygd for fart og de hadde
					årer for å få de raskt gjennom vannet.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Noen skip hadde også seil. Vikingenes langskip var bygd for å ligge høyt i
					vannet, noe som gjorde det lett for vikingene å gå i land på strender.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Vikingene var bønder og krigere. De seilte til andre land og kriget med ander
					menneskegrupper. De stjal landet deres og verdifulle ting.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Noen vikinger reiste til nye land for å bosette seg, få familie og{' '}
					<span style={{ color: 'Lime' }}>
						<b>dyrke</b>
					</span>{' '}
					jorden. Vikingene var flinke bønder og håndverkere. Noen vikinger seilte også
					til land langt unna for å bytte til seg sølv, klær, krydder, smykker og
					keramikk.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor levde vikingene?',
			'2. Hvordan var skipene til vikingene?',
			'3. Hva brukte vikingene skipene til?',
			'4. Hvordan fikk vikingene tak i varer som smykker, klær og krydder?',
			'5. Hvorfor tror du at noen var redde for vikingene?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Region', ' - Utforske', ' - Spesiell', ' - Dyrke'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>ut-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Utforske, uttale, utgang, utland<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på/begynner på
							ut-?
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session59 = [
	{
		introAudio: mainAudio_59,
		questionAudio: questions_59,
		wordsAudio: words_59,
		mainHeading: '1. Dagens tekst',
		mainImg: session59Img,
		subHeading: 'Ørn',
		phonemeData: phoneme59,
		DaysTextWords: wordsInTheEnd59[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Ørn er en rovfugl.',
			'Ørner jakter på fisk og pattedyr.',
			'De er utmerkede jegere.',
			'Ørner bygger rede for å legge egg og oppdra ungene sine.',
		],
		summary: [
			'Ørn er en rovfugl. Den jakter på byttedyr.',
			'Ørner jakter på fisk og pattedyr, deriblant kaniner.',
			'Andre arter av rovfugl er ugler, falker og gribber.',
			'Ørner er utmerkede jegere. De har store, krokete nebb og klør til å rive kjøtt med.',
			'Ørner har også et svært godt syn.',
			'Det betyr at de kan oppdage byttedyr fra store høyder.',
			'Ørner bygger rede for å legge egg og oppdra ungene sine.',
			'Disse kalles for ørnereder. De er ofte bygget i høye trær eller høyt oppå klipper.',
			'Ørner legger ofte to egg.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Ørn er en rovfugl. Det betyr at de jakter på byttedyr. Forskjellige ørnearter
					jakter forskjellige byttedyr, slik som fisk og pattedyr, deriblant kaniner.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Andre arter av rovfugl er ugler, falker og gribber. Et annet navn for rovfugl er
					raptor, som dinosauren!
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Ørner har forskjellige{' '}
					<span style={{ color: 'Lime' }}>
						<b>tilpasninger</b>
					</span>{' '}
					som gjør dem til utmerkede jegere. De har store, krokete nebb og klør til å rive
					kjøtt med.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Ørner har også et svært godt syn. Det betyr at de kan oppdage byttedyr fra store
					høyder.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					På grunn av størrelsen og{' '}
					<span style={{ color: 'Lime' }}>
						<b>styrken</b>
					</span>{' '}
					den har, er de fleste ørnene på toppen av{' '}
					<span style={{ color: 'Lime' }}>
						<b>næringskjeden</b>
					</span>
					. Ørner bygger rede for å legge egg og oppdra ungene sine. Disse kalles for
					ørnereder. De er ofte bygget i høye trær eller høyt oppå{' '}
					<span style={{ color: 'Lime' }}>
						<b>klipper</b>
					</span>
					.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Ørner legger ofte to egg. Men når de klekkes, vil den største av de to
					kyllingene normalt spise den som er mindre!
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva jakter ørner på?',
			'2. Kan du nevne andre arter av rovfugl?',
			'3. Hva er det som gjør at ørner er utmerkede jegere?',
			'4. Hvor kan vi finne ørnereder?',
			'5. Hvorfor tror du at et annet navn for rovfugl er raptor, som dinosauren?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Tilpasninger', ' - Styrke', ' - Næringskjede', ' - Klipper'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>be-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Betyr, bestå, bestemt, beskrive<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>Kan du komme på noen flere ord som begynner på be-?</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session60 = [
	{
		introAudio: mainAudio_60,
		questionAudio: questions_60,
		wordsAudio: words_60,
		mainHeading: '1. Dagens tekst',
		mainImg: session60Img,
		subHeading: 'Kanin',
		phonemeData: phoneme60,
		DaysTextWords: wordsInTheEnd60[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Noen har kaniner som kjæledyr.',
			'Kaniner spiser bare planter og grønnsaker.',
			'De har store ører og hører godt.',
			'De graver i bakken for å lage rede som de bor i.',
		],
		summary: [
			'Kaniner er varmblodige pattedyr.',
			'På norsk kaller vi dem bare kaniner og kaninunger.',
			'Kaniner har blitt holdt som kjæledyr og avlet som en kilde til mat og pels.',
			'Kaniner kan variere i størrelse fra kjempe til dverg.',
			'Kaniner er planteetere. Det betyr at de bare spiser planter og grønnsaker.',
			'De har to store framtenner som lar dem tygge hard vegetasjon.',
			'Kaniner er dyktige gravere. De graver i bakken for å lage rede som de bor i.',
			'Kaniner har store ører. De har utmerket hørsel som hjelper dem med å oppdage og unngå rovdyr.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>Kaniner er varmblodige pattedyr.</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>På norsk kaller vi dem bare kaniner og kaninunger.</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Kaniner har blitt temmet i flere hundre år. Det betyr at de har blitt holdt som
					kjæledyr og avlet som en{' '}
					<span style={{ color: 'Lime' }}>
						<b>kilde</b>
					</span>{' '}
					til mat og pels.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Noen kaniner har blitt spesielt{' '}
					<span style={{ color: 'Lime' }}>
						<b>avlet</b>
					</span>{' '}
					opp på grunn av pelsen. Angorakaninen ble{' '}
					<span style={{ color: 'Lime' }}>
						<b>avlet</b>
					</span>{' '}
					for sin lange, silkeaktige pels som ble brukt til å lage garn.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>Kaniner kan variere i størrelse fra kjempe til dverg.</p>

				<p style={{ fontSize: '22px' }}>
					Kaniner er planteetere. Det betyr at de bare spiser planter og grønnsaker. De
					har to store framtenner som lar dem tygge hard{' '}
					<span style={{ color: 'Lime' }}>
						<b>vegetasjon</b>
					</span>
					.
				</p>

				<p style={{ fontSize: '22px' }}>
					Kaniner er dyktige gravere. De graver i bakken for å lage rede som de bor i.
					Disse kalles for kaninhull med tunnelsystem. Mange kaniner kan leve i et enkelt
					tunnelsystem.
				</p>

				<p style={{ fontSize: '22px' }}>
					Kaniner har store ører. De har utmerket hørsel som hjelper dem med å oppdage og
					unngå rovdyr.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Kaniner er svært{' '}
					<span style={{ color: 'Lime' }}>
						<b>tilpasningsdyktige</b>
					</span>
					. De finnes over hele verden og kan leve i skog, ørken, på gressletter og til og
					med i urbane områder.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er kaniner blitt brukt til?',
			'2. Hva betyr det at kaniner varierer i størrelse?',
			'3. Hvorfor har kaniner store fortenner?',
			'4. Hvor finnes det kaniner?',
			'5. Hvorfor tror du at noen har kaniner som kjæledyr?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Avlet', ' - Kilde', ' - Vegetasjon', ' - Tilpasningsdyktig'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-sjon</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Vegetasjon, gratulasjon, repetisjon, konfirmasjon<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>-sjon?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session61 = [
	{
		introAudio: mainAudio_61,
		questionAudio: questions_61,
		wordsAudio: words_61,
		mainHeading: '1. Dagens tekst',
		mainImg: session61Img,
		subHeading: 'Rein',
		phonemeData: phoneme61,
		DaysTextWords: wordsInTheEnd61[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Rein er en type hjort.',
			'Hannene kalles for bukker.',
			'Hunnene kalles simle.',
			'Barna til reinen kalles kalver.',
			'Fargen på pelsen varierer.',
		],
		summary: [
			'Reinsdyr er varmblodige pattedyr.',
			'De er en type hjort.',
			'Reinsdyr lever på den nordlige halvkula.',
			'Både hannene og hunnene har store gevir.',
			'Reinsdyr feller geviret årlig. Så vokser det et nytt ut igjen.',
			'Hannene kalles for bukker.',
			'Reinsdyrhåret kalles pels.',
			'Fargen på pelsen varierer.',
			'De nordlige artene har ofte en hvitere pels.',
			'Hunnene kalles simle.',
			'Barna til reinen kalles kalver.',
			'De er vanligvis født sent utpå våren, enten i mai eller juni.',
			'Reinsdyr er byttedyr. De blir jaktet på av ulv og bjørn. ',
			'Kongeørn og jerv kan jakte på kalvene.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Reinsdyr er en{' '}
					<span style={{ color: 'Lime' }}>
						<b>type</b>
					</span>{' '}
					hjort.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Reinsdyr lever i{' '}
					<span style={{ color: 'Lime' }}>
						<b>kjøligere</b>
					</span>{' '}
					klima på den nordlige halvkula.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Reinsdyr er uvanlige fordi både hannene og hunnene har store gevir. Størrelsen{' '}
					<span style={{ color: 'Lime' }}>
						<b>varierer</b>
					</span>{' '}
					en del.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Voksne hanner har det nest største geviret av all levende hjort. Det største
					tilhører elgoksen.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Reinsdyr feller geviret årlig. Så vokser det et nytt ut igjen. Hannene kalles
					for bukker.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Bukkene bruker geviret til å slåss mot{' '}
					<span style={{ color: 'Lime' }}>
						<b>rivaliserende</b>
					</span>{' '}
					hanner.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Reinsdyrhåret kalles pels. Fargen på pelsen{' '}
					<span style={{ color: 'Lime' }}>
						<b>varierer</b>
					</span>{' '}
					avhengig av årstid og art. De nordlige artene har ofte en hvitere pels, slik at
					de går mer i ett med snøen.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Hunnene kalles simle. Bukkene kan veie inntil 180 kilo, mens simlene vanligvis
					ikke er tyngre enn 120 kilo.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Barna til reinen kalles kalver. De er vanligvis født sent utpå våren, enten i
					mai eller juni.
				</p>

				<p style={{ fontSize: '22px' }}>
					Reinsdyr er byttedyr. I naturen blir de jaktet på av ulv og bjørn. Kongeørn og
					jerv kan jakte på kalvene.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor lever reinsdyr?',
			'2. Hva er det som er uvanlig med reinsdyrene?',
			'3. Hvorfor tror du bukkene sloss mot hverandre?',
			'4. Hvilke farger har pelsen til reinen?',
			'5. Hvem er reinen byttedyr for?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Type', ' - Kjølig', ' - Variere', ' - Rival'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-lig</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Nordlig, uvanlig, årlig, farlig<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>-lig?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session62 = [
	{
		introAudio: mainAudio_62,
		questionAudio: questions_62,
		wordsAudio: words_62,
		mainHeading: '1. Dagens tekst',
		mainImg: session62Img,
		subHeading: 'Isbjørn',
		phonemeData: phoneme62,
		DaysTextWords: wordsInTheEnd62[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Isbjørn er den største bjørne-arten.',
			'Den spiser reinsdyr og sel.',
			'Isbjørn lager hi i snø-fonner eller under jorda.',
			'Ungene blir født inne i hiet.',
		],
		summary: [
			'Isbjørn er den største bjørnearten i verden.',
			'Isbjørnen lever i Arktis.',
			'Isbjørn er rovdyr. Det betyr at de jakter på byttedyr.',
			'Vanligvis består kostholdet deres av reinsdyr og sel.',
			'Isbjørner sniker seg inn på seler før de angriper.',
			'Isbjørnen er det største landrovdyret i verden.',
			'I motsetning til andre bjørner går ikke isbjørn i dvale regelmessig.',
			'Likevel kan de faste. Dette er nyttig når ishavet smelter om sommeren, og de ikke kan jakte sel.',
			'Isbjørnhunnen føder ungene sine i hi. De lager disse inni snøfonner eller under jorda.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Isbjørn er et stort varmblodig pattedyr. Det er den største bjørnearten i
					verden. Isbjørnen lever i Arktis. Den er{' '}
					<span style={{ color: 'Lime' }}>
						<b>tilpasset</b>
					</span>{' '}
					til å leve i dette{' '}
					<span style={{ color: 'Lime' }}>
						<b>kuldeklimaet</b>
					</span>
					.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					De bor like ved Polhavet der de også jakter. Ettersom de bor i arktiske strøk,
					kan isbjørner finnes i forskjellige land, slik som i Canada, USA, Norge og
					Russland.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Isbjørn er rovdyr. Det betyr at de jakter på byttedyr. Vanligvis består
					kostholdet deres av reinsdyr og sel. Isbjørner vil ofte gjøre bakholdsangrep på
					seler som sover på havisen. De sniker seg inn på disse dyrene før de angriper.
					Isbjørnen er det største landrovdyret i verden.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					En isbjørnhann blir kalt bamse og kan veie inntil 700 kilo og bli 3 meter lang.
					I{' '}
					<span style={{ color: 'Lime' }}>
						<b>motsetning</b>
					</span>{' '}
					til andre bjørner går ikke isbjørn i dvale{' '}
					<span style={{ color: 'Lime' }}>
						<b>regelmessig</b>
					</span>
					. Likevel kan de faste. Dette er nyttig når ishavet smelter om sommeren, og de
					ikke kan jakte sel.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Isbjørnhunnen føder ungene sine i hi. De lager disse inni snøfonner eller under
					jorda.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor lever isbjørnen?',
			'2. Hva består kostholdet til isbjørnen av?',
			'3. Går isbjørnen i hi?',
			'4. Hvordan jakter isbjørnen på sel?',
			'5. Hvorfor tror du det er vanskelig for isbjørnen å jakte på selen om sommeren?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Tilpasset', ' - Kulde', ' - Regelmessig', ' - Motsetning'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>an</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Angripe, anbefale, ankomme, anmelde<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>an?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session63 = [
	{
		introAudio: mainAudio_63,
		questionAudio: questions_63,
		wordsAudio: words_63,
		mainHeading: '1. Dagens tekst',
		mainImg: session63Img,
		subHeading: 'Politi',
		phonemeData: phoneme63,
		DaysTextWords: wordsInTheEnd63[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Politiet sørger for at alle følger loven.',
			'De arresterer personer de tror er skyldige.',
			'De hjelper også til når noen er i nød.',
			'Politiet jobber på en politistasjon.',
		],
		summary: [
			'Politiet sørger for at alle innbyggere følger loven.',
			'De etterforsker forbrytelser.',
			'De arresterer personer de tror er skyldige.',
			'De hjelper også til når noen er i nød.',
			'Politiet jobber på en politistasjon.',
			'Folk føler seg tryggere når politiet er i nærheten.',
			'De fleste politifolk bruker uniform. Det hjelper publikum med å kjenne dem igjen. Noen bruker hverdagsklær.',
			'De er ofte etterforskere.',
			<br></br>,
			<i style={{ color: 'Orange' }}>Noen av verktøyene politiet bruker er:</i>,

			<li>{'batonger'}</li>,
			<li>{'Håndjern'}</li>,
			<li>{'Pepperspray'}</li>,
			<li>{'Elektrosjokkvåpen'}</li>,
			<li>{'Notatbøker'}</li>,
			<li>{'Toveis radioer'}</li>,
			<li>{'Våpen'}</li>,
			<br></br>,
			'Politiet bruker våpen bare når det er nødvendig.',
			'Noen politifolk jobber med politihunder.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Politiet sørger for at alle innbyggere følger loven. De{' '}
					<span style={{ color: 'Lime' }}>
						<b>hindrer</b>
					</span>{' '}
					innbyggerne i å skade hverandre. De etterforsker forbrytelser. De arresterer
					personer de tror er{' '}
					<span style={{ color: 'Lime' }}>
						<b>skyldige</b>
					</span>
					. De hjelper også til når noen er i nød.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Politiet jobber på en politistasjon. De patruljerer i samfunnet. Ofte
					patruljerer de til fots. De patruljerer i politibiler, på motorsykler og på
					hesteryggen også.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					De fleste politifolk bruker{' '}
					<span style={{ color: 'Lime' }}>
						<b>uniform</b>
					</span>
					. Det hjelper publikum med å kjenne dem igjen. Noen bruker hverdagsklær. De er
					ofte etterforskere. Jobben deres er å etterforske forbrytelser, som for eksempel
					ran.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>Noen av verktøyene politiet bruker er:</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					<li>{'batonger'}</li>
					<li>{'Håndjern'}</li>
					<li>{'Pepperspray'}</li>
					<li>{'Elektrosjokkvåpen'}</li>
					<li>{'Notatbøker'}</li>
					<li>{'Toveis radioer'}</li>
					<li>{'Våpen'}</li>
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Politiet bruker våpen bare når det er{' '}
					<span style={{ color: 'Lime' }}>
						<b>nødvendig</b>
					</span>
					.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Noen politifolk jobber med politihunder. Hundene hjelper til med å lete etter
					narkotika, eksplosiver og savnede personer.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva gjør politiet?',
			'2. Hvorfor tror du at noen politifolk ikke bruker uniform?',
			'3. Hva bruker politiet når de patruljerer?',
			'4. Kan du nevne noen verktøy politiet bruker?',
			'5. Hva kan politihunder hjelpe til med?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Hindre', ' - Skyldig', ' - Uniform', ' - Nødvendig'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>etter-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					etterforsker, ettermiddag, ettertanke, etternavn<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>etter-?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session64 = [
	{
		introAudio: mainAudio_64,
		questionAudio: questions_64,
		wordsAudio: words_64,
		mainHeading: '1. Dagens tekst',
		mainImg: session64Img,
		subHeading: 'Brannkonstabel',
		phonemeData: phoneme64,
		DaysTextWords: wordsInTheEnd64[0].revisionWordsAndInstruction.words,
		easySummary: [
			'Brann-konstabler slokker branner.',
			'Alle bruker tunge klær for å beskytte seg.',
			'De fleste brannbiler er lastebiler.',
			<span>Brannbilene har brannslanger og stiger.</span>,
		],
		summary: [
			<span>Brannkonstabler er lært opp til å slokke branner.</span>,
			'De fleste brannene de rykker ut til, skjer i bygninger.',
			'Alle brannkonstabler bruker tunge klær for å beskytte seg.',
			'Det kan være jakker, støvler, hansker og bukser.',
			'De bruker også hjelmer for å beskytte hodet.',
			'Brannkonstabler hjelper også til i andre nødssituasjoner.',
			'Brannkonstabler holder til på en brannstasjon.',
			'De fleste brannbiler er lastebiler.',
			'De bruker også båter, fly og helikoptre.',
			'Disse bruker de når de skal slokke branner på steder som er vanskelig å komme til.',
			'Kjøretøyene deres har brannslanger og stiger.',
			'De har også førstehjelpsutstyr og økser.',
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Brannkonstabler er lært opp til å slokke branner. De fleste brannene de rykker
					ut til, skjer i bygninger. Noen brannkonstabler er også lært opp til å{' '}
					<span style={{ color: 'Lime' }}>
						<b>bekjempe</b>
					</span>{' '}
					skogbranner. Alle brannkonstabler bruker tunge klær for å beskytte seg. Det kan
					være jakker, støvler, hansker og bukser. De bruker også hjelmer for å beskytte
					hodet.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Brannkonstabler hjelper også til i andre nødssituasjoner. Noen eksempler er
					bilulykker,{' '}
					<span style={{ color: 'Lime' }}>
						<b>kollapsede</b>
					</span>{' '}
					bygninger og flom. De utfører også søk og redning. De kommer ofte til ofrene før
					ambulanse gjør det. Dette er grunnen til at de også kan{' '}
					<span style={{ color: 'Lime' }}>
						<b>utøve</b>{' '}
					</span>
					førstehjelp.
				</p>

				<p style={{ fontSize: '22px' }}>
					Brannkonstabler holder til på en brannstasjon. De bruker noe av tiden til å:
				</p>

				<p style={{ fontSize: '22px' }}>
					<li>Skrive rapporter om nødssituasjonene de rykket ut til.</li>
					<li>
						<span style={{ color: 'Lime' }}>
							<b>Kontrollere</b>
						</span>{' '}
						lastebiler og utstyr. De sørger for at det holder seg i god stand.
					</li>
					<li>
						Gå på skoler for å undervise i brannsikkerhet. Å gjennomføre
						brannøvelser er en del av det.
					</li>
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					De fleste brannbiler er lastebiler. De bruker også båter, fly og helikoptre.
					Disse bruker de når de skal slokke branner på steder som er vanskelig å komme
					til. Kjøretøyene deres har brannslanger og stiger. De har også
					førstehjelpsutstyr og økser.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva gjør en brannkonstabel?',
			'2. Hvilke klær bruker de når de slokker brann?',
			'3. Hva jobber brannkonstabler med når de ikke slokker brann?',
			'4. Hvilke kjøretøy kan brukes til å slokke brann?',
			'5. Hvorfor tror du noen har brannøvelser hjemme?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Bekjempe', ' - Kollapse', ' - Utøve', ' - Kontrollere'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>ut-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Utføre, utøve, utstyr, utsette<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>ut-?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session65 = [
	{
		introAudio: mainAudio_65,
		questionAudio: questions_65,
		wordsAudio: words_65,
		mainHeading: '1. Dagens tekst',
		mainImg: session65Img,
		subHeading: 'Frisør',
		phonemeData: phoneme65,
		DaysTextWords: wordsInTheEnd65[0].revisionWordsAndInstruction.words,
		easySummary: [
			<p>En frisør klipper og former hår.</p>,
			<p>De fleste frisører jobber i en salong.</p>,
			<p>Frisører kan lage krøller i håret.</p>,
			<p>Frisørene bruker hårfarger til å farge hår. </p>,
			<p>En barberer ordner hår og skjegg.</p>,
		],
		summary: [
			<p>En frisør klipper og former hår.</p>,
			<p>De fleste frisører jobber i en salong.</p>,
			<p>En frisør jobber ut fra en bestilling.</p>,
			<p>De ønsker å finne ut hvordan kunden ønsker at håret skal bli.</p>,
			<p>Frisører bruker også kjemikalier som kan rette ut krøllete hår.</p>,
			<p>Noen kjemikalier kan lage krøller og bølger i hår som er rett.</p>,
			<p>Frisørene bruker hårfarger til å farge hår.</p>,
			<p>En barberer er en slags frisør. </p>,
			<p>Det er oftest menn som er barberere. </p>,
			<p>De klipper, trimmer og barberer håret og skjegget til menn.</p>,
			<p>Også kvinner går til en barberer for å klippe seg.</p>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En frisør klipper og former hår. De fleste frisører jobber i en salong. Noen har
					husbesøk. De ordner håret til kunden hjemme hos kunden.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					En frisør jobber ut fra en bestilling. De ønsker å finne ut hvordan kunden
					ønsker at håret skal bli. Frisøren og{' '}
					<span style={{ color: 'Lime' }}>
						<b>klienten</b>
					</span>{' '}
					kan se på bilder av ulike stiler. Det er med på å sikre at kunden blir fornøyd
					med{' '}
					<span style={{ color: 'Lime' }}>
						<b>resultatet</b>
					</span>
					.
				</p>
				<br></br>
				<p style={{ fontSize: '22px', color: 'gold' }}>Noen av verktøyet til en frisør er:</p>

				<p style={{ fontSize: '22px' }}>
					<li>Saks, maskin og barberhøvel</li>
					<li>Hårruller</li>
					<li>Hårføner og hårdamper</li>
					<li>Hårnåler, hårspenner og annet tilbehør</li>
					<li>Sjampo og balsam</li>
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Frisører bruker også{' '}
					<span style={{ color: 'Lime' }}>
						<b>kjemikalier</b>
					</span>{' '}
					som kan rette ut krøllete hår. Noen{' '}
					<span style={{ color: 'Lime' }}>
						<b>kjemikalier</b>
					</span>{' '}
					kan lage krøller og bølger i hår som er rett. Frisørene bruker hårfarger til å
					farge hår. Det krever erfaring å farge håret til kunden slik kunden ønsker. Noen
					frisører er{' '}
					<span style={{ color: 'Lime' }}>
						<b>spesialister</b>
					</span>{' '}
					på dette. De kalles kolorister.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					En barberer er en slags frisør. Det er oftest menn som er barberere. De klipper,
					trimmer og barberer håret og skjegget til menn. Også kvinner går til en barberer
					for å klippe seg.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvordan jobber en frisør?',
			'2. Hvilke verktøy bruker en frisør?',
			'3. Hva gjør frisører med kjemikalier?',
			'4. Hva tror du kan skje hvis kjemikalier er for lenge i håret?',
			'5. Hva gjør en barberer?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Klient', ' - Kjemikalier', ' - Resultat', ' - Spesialister'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er det et sammensatt ord, og i så fall av hvilke
						ord?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>for-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Fornøyd, forbilde, forbudt, forklare<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>for-?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session66 = [
	{
		introAudio: mainAudio_66,
		questionAudio: questions_66,
		wordsAudio: words_66,
		mainHeading: '1. Dagens tekst',
		mainImg: session66Img,
		subHeading: 'Sykepleier',
		phonemeData: phoneme66,
		DaysTextWords: wordsInTheEnd66[0].revisionWordsAndInstruction.words,
		easySummary: [
			<p>En sykepleier hjelper de som er syke og skadde.</p>,
			<p>Sykepleiere jobber på sykehus og lege-kontorer.</p>,
			<p>De kan gi medisiner og sette sprøyter.</p>,
			<p>Sykepleiere bruker uniformer i forskjellige farger.</p>,
			<p>De bruker også gode sko.</p>,
		],
		summary: [
			<p>
				En sykepleier gir{' '}
				<span style={{ color: 'Lime' }}>
					<b>medisinsk</b>
				</span>{' '}
				hjelp til personer som er syke og skadde.
			</p>,
			<p>Sykepleiere jobber på sykehus, klinikker og legekontorer.</p>,
			<p>De jobber også på skoler, omsorgshjem og i private hjem.</p>,
			<p>Det finnes mange typer sykepleiere.</p>,
			<p>Sykepleiere stiller spørsmål for å finne ut hvordan helsen til pasienten er.</p>,
			<p>De kan sjekke temperatur og blodtrykk. De kan bytte bandasjer.</p>,
			<br></br>,

			<p>De kan gi medisiner og sette sprøyter.</p>,
			<p>Sykepleiere trøster pasientene og deres familier.</p>,
			<p>Før hadde sykepleiere helt hvite uniformer. Nå bruker de andre farger også.</p>,
			<p>Siden de går og står veldig mye, bruker de gode sko.</p>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En sykepleier gir{' '}
					<span style={{ color: 'Lime' }}>
						<b>medisinsk</b>
					</span>{' '}
					hjelp til personer som er syke og skadde. Sykepleiere jobber på sykehus,
					klinikker og legekontorer. De jobber også på skoler, omsorgshjem og i private
					hjem. Det finnes mange typer sykepleiere.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					<li>Noen hjelper babyer og små barn.</li>
					<li>
						Noen hjelper pasienter med{' '}
						<span style={{ color: 'Lime' }}>
							<b>psykiske</b>
						</span>{' '}
						problemer.
					</li>
					<li>En jordmor hjelper kvinner under graviditet og fødsel.</li>
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Sykepleiere stiller spørsmål for å finne ut hvordan helsen til pasienten er. De
					kan sjekke temperatur og blodtrykk. De kan bytte bandasjer. De kan gi medisiner
					og sette sprøyter. Sykepleiere trøster pasientene og deres familier. De kan gi{' '}
					<span style={{ color: 'Lime' }}>
						<b>råd</b>
					</span>{' '}
					om hvordan de kan{' '}
					<span style={{ color: 'Lime' }}>
						<b>håndtere</b>
					</span>{' '}
					eller komme seg gjennom en sykdom. Hver dag bruker sykepleiere mange verktøy når
					de tar vare på pasientene sine. Noen av disse er:
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					<li>Stetoskop</li>
					<li>Termometer</li>
					<li>
						Bandasjer og{' '}
						<span style={{ color: 'Lime' }}>
							<b>medisinsk</b>
						</span>{' '}
						teip (bandasjeteip)
					</li>
					<li>Sprøyter og nåler </li>
					<li>Klokke</li>
					<li>Telefon og notatblokk</li>
				</p>

				<br></br>
				<p style={{ fontSize: '22px' }}>
					Før hadde sykepleiere helt hvite uniformer. Nå bruker de andre farger også.
					Siden de går og står veldig mye, bruker de gode sko.
				</p>
				<br></br>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor jobber sykepleiere?',
			'2. Hva gjør de?',
			'3. Hvorfor tror du sykepleierne bruker uniformer med forskjellige farger, ikke bare hvite?',
			'4. Hvilke verktøy bruker sykepleiere?',
			'5. Hva kan sykepleierne gjøre for pasientenes familier?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Psykisk', ' - Håndtere', ' - Medisinsk', ' - Råd'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-ig</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Veldig, nyttig, modig, forskjellig<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>-ig?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session67 = [
	{
		introAudio: mainAudio_67,
		questionAudio: questions_67,
		wordsAudio: words_67,
		mainHeading: '1. Dagens tekst',
		mainImg: session67Img,
		subHeading: 'Veterinær',
		phonemeData: phoneme67,
		DaysTextWords: wordsInTheEnd67[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'En veterinær er en dyrlege.',
			'Noen jobber på dyresykehus.',
			'Dyr kan ikke si hvordan de har det.',
			'Veterinærene kan finne ut mye ved å undersøke dyrene.',
		],
		summary: [
			<p>En veterinær er en dyrlege. </p>,
			<p>De gjør det de kan, slik at dyrene skal holde seg friske.</p>,
			<p>Mange veterinærer har egne klinikker.</p>,
			<p>Noen jobber på dyresykehus, på gårder eller i dyrehager.</p>,
			<p>Dyr kan ikke si hvordan de har det.</p>,
			<p>Eierne kan hjelpe dem ved å fortelle veterinæren om helsen deres.</p>,
			<p>Veterinærene kan selv finne ut mye ved å undersøke dyrene.</p>,
			<br></br>,
			<p>De bruker instrumenter og tester.</p>,

			<p>
				De som ønsker å bli veterinær må studerer minst fem år. Noen veterinærer behandler kun
				kjæledyr. Andre behandler gårdsdyr.
			</p>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En veterinær er en dyrlege. Veterinærer{' '}
					<span style={{ color: 'Lime' }}>
						<b>diagnostiserer</b>
					</span>{' '}
					og behandler dyrs helseproblemer. De gjør det de kan for å{' '}
					<span style={{ color: 'Lime' }}>
						<b>forebygge</b>
					</span>{' '}
					sykdom, slik at dyrene skal holde seg friske. De skriver ut medisiner og utfører
					kirurgi ved behov. Mange veterinærer har egne klinikker. Noen jobber på
					dyresykehus, på gårder eller i dyrehager.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Dyr kan ikke fortelle om{' '}
					<span style={{ color: 'Lime' }}>
						<b>symptomene</b>
					</span>{' '}
					de har. De kan ikke si hvordan de har det. Eierne kan hjelpe dem ved å fortelle
					veterinæren om helsen deres. Veterinærene kan selv finne ut mye ved å undersøke
					dyrene. De bruker instrumenter og tester. Noen av{' '}
					<span style={{ color: 'Lime' }}>
						<b>metodene</b>
					</span>{' '}
					de bruker, er de samme som leger gjør på syke mennesker. To av disse metodene
					røntgen og blodprøver.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					De som ønsker å bli veterinær, studerer veterinærmedisin ved høyskole eller
					universitet. Dette kan ta minst fem år. Noen veterinærer behandler kun kjæledyr.
					Gårdsdyr behandles av en spesialist i sykdommer til produksjonsdyr.
				</p>
				<br></br>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva gjør en veterinær?',
			'2. Hvordan kan man bli veterinær?',
			'3. Hvor jobber veterinærer?',
			'4. Hvordan tror du eierne kan merke at dyrene deres er syke?',
			'5. Hvordan kan veterinærene finne ut om dyrene er syke?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Diagnostiserer', ' - Forebygge', ' - Symptomene', ' - Metode'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>Under-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Undersøke, underholde, undervise, undertøy<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>under-?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session68 = [
	{
		introAudio: mainAudio_68,
		questionAudio: questions_68,
		wordsAudio: words_68,
		mainHeading: '1. Dagens tekst',
		mainImg: session68Img,
		subHeading: 'Kjøkkensjef',
		phonemeData: phoneme68,
		DaysTextWords: wordsInTheEnd68[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			<p>
				En kjøkkensjef er en{' '}
				<span style={{ color: 'Lime' }}>
					<b>profesjonell</b>
				</span>{' '}
				kokk.
			</p>,
			'Han planlegger menyene, men lager ikke så ofte mat.',
			'Det finnes ulike typer kokker.',
			'Kjøkkensjefer bruker vanligvis en hvit uniform.',
		],
		summary: [
			<p>
				En kjøkkensjef er en{' '}
				<span style={{ color: 'Lime' }}>
					<b>profesjonell</b>
				</span>{' '}
				kokk. Mange kjøkkensjefer jobber på hoteller og restauranter.
			</p>,
			<p>De har ansvaret for:</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				<li>Å lage menyer.</li>
				<li>Innkjøp av matvarer.</li>
				<li>Å lage mat.</li>
				<li>
					<span style={{ color: 'Lime' }}>
						<b>Tilsyn</b>
					</span>{' '}
					med andre arbeidere på kjøkkenet.
				</li>
			</p>,
			<p>På et storkjøkken jobber det ulike typer kokker. </p>,
			<p>Kjøkkensjefen har ansvaret for alle på kjøkkenet.</p>,
			<p>Han planlegger menyene, men lager ikke så ofte mat.</p>,
			<p>Kokker bruker utstyr som visper, blendere, gryter og panner.</p>,
			<p>De må få maten til å se tiltalende ut ved servering.</p>,
			<p>Kjøkkensjefer bruker vanligvis en hvit uniform.</p>,
			<br></br>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En kjøkkensjef er en{' '}
					<span style={{ color: 'Lime' }}>
						<b>profesjonell</b>
					</span>{' '}
					kokk. Mange kjøkkensjefer jobber på hoteller og restauranter. De har ansvaret
					for:
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					<li>Å Lage menyer.</li>
					<li>Innkjøp av matvarer.</li>
					<li>Å Lage mat.</li>
					<li>
						<span style={{ color: 'Lime' }}>
							<b>Tilsyn</b>
						</span>{' '}
						med andre arbeidere på kjøkkenet.
					</li>
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					På et storkjøkken jobber det ulike typer kokker. Kjøkkensjefen har ansvaret for
					alle på kjøkkenet. Han planlegger menyene, men lager ikke så ofte mat.
				</p>
				<p style={{ fontSize: '22px' }}>
					Soussjefen er nestleder. Denne kokken lager mat og har ansvar for andre kokker.
				</p>
				<br></br>
				<p>Noen av disse er:</p>
				<p style={{ fontSize: '22px' }}>
					<li>Sautékokk.</li>
					<li>Grillkokk.</li>
				</p>{' '}
				<p style={{ fontSize: '22px' }}>
					Kokker bruker utstyr som visper, blendere, gryter og panner, i tillegg til
					kokeapparater. De må også få maten til å se tiltalende ut ved servering. Noen
					kjøkkensjefer{' '}
					<span style={{ color: 'Lime' }}>
						<b>utvikler</b>
					</span>{' '}
					kokkeferdighetene ved å være lærlinger. De jobber på kjøkken og lærer av mer{' '}
					<span style={{ color: 'Lime' }}>
						<b>erfarne</b>
					</span>{' '}
					kokker. Andre går på kokkeskoler. Etter de er ferdige med kokkeutdanningen,
					fortsetter noen å videreutvikle seg ved å jobbe under en mesterkokk.
					Kjøkkensjefer bruker vanligvis en hvit uniform.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er en kjøkkensjef?',
			'2. Hvor jobber kjøkkensjefer?',
			'3. Hvem tror du jobber sammen med kjøkkensjefen?',
			'4. Hvordan kan man bli kokk?',
			'5. Hvilke klær bruker kjøkkensjefen på jobb?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Profesjonell', ' - Tilsyn', ' - Erfarne', ' - Utvikle'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>til-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Tiltalende, tilstå, tilsløre, tilhøre<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>til-?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session69 = [
	{
		introAudio: mainAudio_69,
		questionAudio: questions_69,
		wordsAudio: words_69,
		mainHeading: '1. Dagens tekst',
		mainImg: session69Img,
		subHeading: 'Rørlegger',
		phonemeData: phoneme69,
		DaysTextWords: wordsInTheEnd69[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'En rørlegger jobber med rør som fører vann og avfall.',
			'Avfallet kan være vann fra avløp eller kloakk.',
			'Rørleggere blir ofte våte og skitne når de jobber.',
			'De kan ha vanntette kjeledresser over klærne.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				En rørlegger jobber med rør som fører vann og avfall. Avfallet kan være vann fra avløp
				eller kloakk. Noen rørleggere jobber også med rør som fører gass. Rørleggere{' '}
				<span style={{ color: 'Lime' }}>
					<b>installerer</b>
				</span>{' '}
				rør, og de reparerer også rør når det er nødvendig.
			</p>,
			<p>
				De installerer også møblene som rørene kobles til. De vanligste er vasker, toaletter og
				badekar.
			</p>,

			<p style={{ fontSize: '22px' }}>Rørleggere blir ofte våte og skitne når de jobber.</p>,
			<p style={{ fontSize: '22px' }}>De kan ha vanntette kjeledresser over klærne.</p>,
			<p style={{ fontSize: '22px' }}>De bruker også briller, hansker og arbeidsstøvler.</p>,
			<p style={{ fontSize: '22px' }}>De bruker forskjellige typer verktøy.</p>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En rørlegger jobber med rør som fører vann og avfall. Avfallet kan være vann fra
					avløp eller kloakk. Noen rørleggere jobber også med rør som fører gass.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Rørleggere{' '}
					<span style={{ color: 'Lime' }}>
						<b>installerer</b>
					</span>{' '}
					rør, og de reparerer også rør når det er nødvendig. De{' '}
					<span style={{ color: 'Lime' }}>
						<b>installerer</b>
					</span>{' '}
					også møblene som rørene kobles til. De vanligste er vasker, toaletter og
					badekar.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					En rørlegger følger nøye med på vann
					<span style={{ color: 'Gold' }}>
						<b>sikkerhet</b>
					</span>
					. Avløpsvann og kloakk skal føres bort på en sikker måte, da de kan gjøre vann
					utrygt å drikke og bade og svømme i.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Rørleggere skal være utdannet og{' '}
					<span style={{ color: 'Lime' }}>
						<b>sertifiserte</b>
					</span>
					. De bruker mye tid i klasserommet. Deler av utdanningen deres er som lærling.
					Da jobber de under en erfaren rørlegger eller rørleggermester. Rørleggere blir
					ofte våte og skitne når de jobber. De kan ha vanntette kjeledresser over klærne.
					De bruker også briller, hansker og arbeidsstøvler.
				</p>
				<p style={{ fontSize: '22px' }}>
					De bruker forskjellige typer håndverktøy og elektroverktøy. Noen av disse er:
				</p>{' '}
				<p style={{ fontSize: '22px' }}>
					<li>Skiftenøkler</li>
					<li>Klosettpumpe</li>
					<li>Baufil</li>
					<li>Bøyetang</li>
					<li>Lommelykter</li>
				</p>
				<p style={{ fontSize: '22px' }}>
					Flere av disse verktøyene krever at man har både erfaring og{' '}
					<span style={{ color: 'Lime' }}>
						<b>styrke</b>
					</span>
					.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva jobber en rørlegger med?',
			'2. Hva tror du vannsikkerhet er?',
			'3. Hvorfor må en rørlegger være utdannet og sertifisert?',
			'4. Hva er en lærling?',
			'5. Hvilke typer verktøy bruker rørleggere?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Installere', ' - Sertifisert', ' - Sikkerhet', ' - Styrke'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>av-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Avfall, avløp, avhør, avstå<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>av-?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session70 = [
	{
		introAudio: mainAudio_70,
		questionAudio: questions_70,
		wordsAudio: words_70,
		mainHeading: '1. Dagens tekst',
		mainImg: session70Img,
		subHeading: 'Lastebilsjåfør',
		phonemeData: phoneme70,
		DaysTextWords: wordsInTheEnd70[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'En lastebil-sjåfør kjører lastebil.',
			'Det er forskjellige typer lastebil.',
			'Noen sjåfører eier lastebilen de kjører.',
			'Andre jobber for et lastebil-firma og kjører deres biler.',
		],
		summary: [
			<p>En lastebilsjåfør lever av å kjøre lastebil.</p>,
			<p>Sjåføren trenger en spesiell lisens for å være lastebilsjåfør. </p>,
			<p>Noen av bilene de kjører er tankbiler, skapbiler og dumperbiler.</p>,
			<p>
				Varene de{' '}
				<span style={{ color: 'Lime' }}>
					<b>frakter</b>
				</span>
				, kalles gods.
			</p>,
			<p>Noen sjåfører eier lastebilen de kjører.</p>,
			<p>Andre lastebilsjåfører har ikke egne lastebiler.</p>,
			<p>De jobber for et lastebilfirma og kjører deres biler.</p>,
			<p>
				Noen lastebilsjåfører spesialiserer seg på hva slags gods de{' '}
				<span style={{ color: 'Lime' }}>
					<b>frakter</b>
				</span>
				. Eksempler er:
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				<li>Biltransport</li>
				<li>Båttransport</li>
				<li>Transport av husholdningsvarer</li>
				<li>Dyretransport</li>
			</p>,

			<br></br>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En lastebilsjåfør lever av å kjøre lastebil. Sjåføren trenger en spesiell lisens
					for å være lastebilsjåfør. Noen av bilene de kjører, er tankbiler, skapbiler og
					dumperbiler. Disse kan frakte tunge og uhåndterlige{' '}
					<span style={{ color: 'Lime' }}>
						<b>gjenstander</b>
					</span>
					. Varene de{' '}
					<span style={{ color: 'Lime' }}>
						<b>frakter</b>
					</span>
					, kalles gods. De kjører ofte lange avstander og{' '}
					<span style={{ color: 'Lime' }}>
						<b>tilbringer</b>
					</span>{' '}
					mye tid hjemmefra.
				</p>

				<br></br>
				<p style={{ fontSize: '22px' }}>
					Noen sjåfører eier lastebilen de kjører. Dem kan de bruke til å jobbe for ett
					eller flere lastebilfirmaer. Andre lastebilsjåfører har ikke egne lastebiler. De
					jobber for et lastebilfirma og kjører deres biler.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Lastebilsjåfører har ansvar for å sørge for at bilene deres er trygge å kjøre på
					veien. De har også ansvar for varene i bilene sine. De må passe på at de henter
					og leverer de rette varene. Noen lastebilsjåfører spesialiserer seg på hva slags
					gods de{' '}
					<span style={{ color: 'Lime' }}>
						<b>frakter</b>
					</span>
					. Eksempler er:
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					<li>Biltransport</li>
					<li>Båttransport</li>
					<li>Transport av husholdningsvarer</li>
					<li>Dyretransport</li>
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Kan du navnet på noen typer lastebiler?',
			'2. Hvem kan kjøre lastebil?',
			'3. Hvem eier lastebilene?',
			'4. Kan du nevne noen typer gods lastebilene frakter?',
			'5. Hvordan tror du de sjåførene som tilbringer mye tid hjemmefra har det?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Uhåndterlig', ' - Frakte', ' - Tilbringe', ' - Gjenstand'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>gjen-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Gjenstår, gjenskape, gjenta, gjenåpne<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>gjen-?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];
export const session71 = [
	{
		introAudio: mainAudio_71,
		questionAudio: questions_71,
		wordsAudio: words_71,
		mainHeading: '1. Dagens tekst',
		mainImg: session71Img,
		subHeading: 'Skuespiller',
		phonemeData: phoneme71,
		DaysTextWords: wordsInTheEnd71[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'En skuespiller er en som spiller i filmer og på teater.',
			'Det er forskjellige typer skuespillere.',
			'Noen bruker bare stemmene sine.',
			'Vil du bli skuespiller kan du gå på drama-skole.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				En skuespiller er en som spiller i filmer, teaterstykker og TV- serier.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Skuespillere må delta på audition for å få den rollen de ønsker. Da må de framføre en del
				av et skuespill.
			</p>,
			<p style={{ fontSize: '22px' }}>Det er forskjellige typer skuespillere.</p>,
			<p style={{ fontSize: '22px' }}>Noen av de som vil bli skuespillere, kan gå på dramaskole.</p>,
			<p style={{ fontSize: '22px' }}>Det er forskjellige typer skuespillere.</p>,

			<p style={{ fontSize: '22px' }}>
				<li>
					Teaterskuespillere{' '}
					<span style={{ color: 'Lime' }}>
						<b>opptrer</b>
					</span>{' '}
					i skuespill på teater.
				</li>
				<li>Filmskuespillere lager film.</li>
				<li>Stemmeskuespiller bruker bare stemmene sine.</li>
			</p>,
			<span style={{ color: 'Lime' }}></span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En skuespiller er en som spiller i filmer, teaterstykker, TV-serier eller
					lignende{' '}
					<span style={{ color: 'Lime' }}>
						<b>produksjoner</b>
					</span>
					. Ordet skuespiller brukes om menn og gutter som{' '}
					<span style={{ color: 'Lime' }}>
						<b>opptrer</b>
					</span>
					. Kvinner og jenter som{' '}
					<span style={{ color: 'Lime' }}>
						<b>opptrer</b>
					</span>
					, kalles skuespillerinner. I dag foretrekker noen av dem å bli omtalt som
					skuespillere.
				</p>

				<p style={{ fontSize: '22px' }}>
					Skuespillere må delta på audition for å få den rollen de ønsker. Da må de
					framføre en del av et skuespill for personene som har ansvaret for å velge
					skuespillere til{' '}
					<span style={{ color: 'Lime' }}>
						<b>produksjonen</b>
					</span>
					.
				</p>

				<p style={{ fontSize: '22px' }}>Det er forskjellige typer skuespillere.</p>

				<p style={{ fontSize: '22px' }}>
					Noen av de som vil bli skuespillere, kan gå på dramaskole. De må ofte delta på
					en audition for å vise{' '}
					<span style={{ color: 'Lime' }}>
						<b>talentet</b>
					</span>{' '}
					sitt. De kommer inn på skolen hvis de gjør det bra.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Noen skuespillere spesialiserer seg i en bestemt type{' '}
					<span style={{ color: 'Lime' }}>
						<b>produksjon</b>
					</span>
					.
				</p>

				<p style={{ fontSize: '22px' }}>
					<li>
						Teaterskuespillere{' '}
						<span style={{ color: 'Lime' }}>
							<b>opptrer</b>
						</span>{' '}
						i skuespill på teater. De{' '}
						<span style={{ color: 'Lime' }}>
							<b>opptrer</b>
						</span>{' '}
						foran et publikum.
					</li>
					<li>
						Filmskuespillere må hele tiden{' '}
						<span style={{ color: 'Lime' }}>
							<b>ta hensyn</b>
						</span>{' '}
						til hvor kameraene er.
					</li>
					<li>
						Stemmeskuespiller{' '}
						<span style={{ color: 'Lime' }}>
							<b>opptrer</b>
						</span>{' '}
						i radioprogrammer og animasjonsfilmer. De bruker bare stemmene sine.
					</li>
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor jobber skuespillere?',
			'2. Hvordan kan man bli skuespiller?',
			'3. Hva er en audition?',
			'4. Hvordan tror du det er å være på audition?',
			'5. Hva gjør en stemmeskuespiller?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Produksjon', ' - Talent', ' - Opptre', ' - Ta hensyn'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-ende</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Lignende, spennende, spisende, sovende<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>ende?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];
export const session72 = [
	{
		introAudio: mainAudio_72,
		questionAudio: questions_72,
		wordsAudio: words_72,
		mainHeading: '1. Dagens tekst',
		mainImg: session72Img,
		subHeading: 'Pilot',
		phonemeData: phoneme72,
		DaysTextWords: wordsInTheEnd72[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'En pilot er en som fører luft-fartøy.',
			'Piloter flyr fly og helikopter.',
			'En pilot må ha et spesielt sertifikat.',
			'De kjører rutefly og frakter passasjerer.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>En pilot er en som fører luftfartøy.</p>,
			<p style={{ fontSize: '22px' }}>Piloter flyr fly, helikopter og romskip også. </p>,
			<p style={{ fontSize: '22px' }}>En pilot må ha et spesielt sertifikat for å få lov til å fly.</p>,
			<p style={{ fontSize: '22px' }}>Piloter må passe på at flyene er trygge å fly. </p>,
			<p style={{ fontSize: '22px' }}>De må sørge for at både passasjerer og bagasje er trygge. </p>,
			<p style={{ fontSize: '22px' }}>De kontrollerer flymotoren og annet utstyr.</p>,
			<p style={{ fontSize: '22px' }}>
				En pilot må kunne lese og forstå alle instrumentene i cockpiten.
			</p>,
			<p style={{ fontSize: '22px' }}>
				En pilot med sertifikat, som har flydd i tusenvis av timer, kan bli pilot for et
				flyselskap.{' '}
			</p>,
			<p style={{ fontSize: '22px' }}>De kjører rutefly og frakter passasjerer.</p>,
			<span style={{ color: 'Lime' }}>
				<b></b>
			</span>,
			<br></br>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En pilot er en som fører luftfartøy. Piloter flyr fly, helikopter og romskip
					også. En pilot må ha et spesielt sertifikat for å få lov til å fly.
				</p>
				<p style={{ fontSize: '22px' }}>
					Piloter flyr til alle tider på dagen og natten. De må gjerne fly under{' '}
					<span style={{ color: 'Lime' }}>
						<b>krevende</b>
					</span>{' '}
					værforhold. De må kunne holde seg rolige i nødstilfeller.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Piloter må passe på at flyene er trygge å fly. De må sørge for at både
					passasjerer og bagasje er trygge. De gjennomfører flere kontroller før hver
					flygning. De{' '}
					<span style={{ color: 'Lime' }}>
						<b>kontrollerer</b>
					</span>{' '}
					flymotoren og annet utstyr. De sjekker værmeldingen også.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					En pilot må kunne lese og forstå alle instrumentene i cockpiten. En pilot med
					lite erfaring starter med å fly mindre fly. Etter mange timer med flygning får
					pilotene et pilotsertifikat.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					En pilot med sertifikat, som har flydd i tusenvis av timer, kan bli pilot for et
					flyselskap. De kjører rutefly og frakter passasjerer.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er en pilot?',
			'2. Hvilke viktige oppgaver har pilotene?',
			'3. Hva kan pilotene fly?',
			'4. Hva tror du er den vanskeligste oppgaven til pilotene?',
			'5. Hva må pilotene gjøre før de kan fly rutefly med passasjerer?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Krevende', ' - Kontrollere', ' - Delta', ' - Spre'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>over-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Overstått, overtale, overspise, overfalle<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som begynner på <b>over-?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];
export const session73 = [
	{
		introAudio: mainAudio_73,
		questionAudio: questions_73,
		wordsAudio: words_73,
		mainHeading: '1. Dagens tekst',
		mainImg: session73Img,
		subHeading: 'Fly',
		phonemeData: phoneme73,
		DaysTextWords: wordsInTheEnd73[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'Et fly er et luft-fartøy med faste vinger.',
			'Det blir flydd av en utdannet pilot.',
			'Militæret bruker dem til kamp.',
			'Luft-ambulanser kan frakte syke mennesker til sykehuset.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>Et fly er et luftfartøy med faste vinger. </p>,
			<p style={{ fontSize: '22px' }}>Det blir flydd av en utdannet pilot.</p>,

			<p style={{ fontSize: '22px' }}>Piloten sitter i cockpiten foran på flyet.</p>,
			<p style={{ fontSize: '22px' }}>Fly har mange bruksområder.</p>,
			<p style={{ fontSize: '22px' }}>Militæret bruker dem til kamp. </p>,
			<p style={{ fontSize: '22px' }}>
				Det brukes til å frakte{' '}
				<span style={{ color: 'Lime' }}>
					<b>tropper</b>
				</span>{' '}
				også.
			</p>,
			<p style={{ fontSize: '22px' }}>Sportsfly brukes til racing og stuntflyging.</p>,
			<p style={{ fontSize: '22px' }}>Luftambulanser kan frakte syke mennesker til sykehuset.</p>,
			<p style={{ fontSize: '22px' }}>Det første flyet var et biplan. </p>,
			<p style={{ fontSize: '22px' }}>Den hadde to par vinger.</p>,
			<p style={{ fontSize: '22px' }}>Det ble bygget av brødrene Orville og Wilbur Wright.</p>,

			<span style={{ color: 'Lime' }}>
				<b></b>
			</span>,
			<br></br>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Et fly er et luftfartøy med faste vinger. Motoren og vingene gjør at flyet kan
					ta av og fly.
				</p>

				<p style={{ fontSize: '22px' }}>
					Et fly blir flydd av en trent pilot. Piloten sitter i cockpiten foran på flyet.
					Passasjerene sitter bak piloten i den delen som kalles kabin.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					De fleste fly er monofly. De har ett par vinger. Biplan har to par og triplan
					har tre. Det første flyet var et biplan ved navn Kitty Hawk. Det ble bygget av
					brødrene Orville og Wilbur Wright i 1903.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Forsvaret bruker mye fly. Det bruker flyene til å frakte{' '}
					<span style={{ color: 'Lime' }}>
						<b>tropper</b>
					</span>{' '}
					og utstyr. Det har også bombefly og jagerfly for kamp.
				</p>
				<p style={{ fontSize: '22px' }}></p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Fly som ikke er militære fly, er{' '}
					<span style={{ color: 'Lime' }}>
						<b>sivile</b>
					</span>{' '}
					fly. Her er noen av dem:
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					<li>Flyselskaper frakter passasjerer på rutefly.</li>
					<li>Lastefly frakter kun last.</li>
					<li>Sportsfly brukes til konkurranser og stuntflyging.</li>
					<li>Brannfly brukes i brannslukking fra luften.</li>
					<li>
						Luftambulanser brukes av akuttmedisinske{' '}
						<span style={{ color: 'Lime' }}>
							<b>tjenester</b>
						</span>
						.
					</li>
					<li>
						I tillegg flyr orkanjegere fly inn i tordenvær for å samle{' '}
						<span style={{ color: 'Lime' }}>
							<b>værdata</b>
						</span>
						.
					</li>
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er en pilot?',
			'2. Hvor mange vinger har et biplan?',
			'3. Kan du nevne fire forskjellige typer fly?',
			'4. Hva er en orkanjeger?',
			'5. Hvorfor tror du noen vil være orkanjeger?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: [' - Tropper', ' - Sivil', ' - Tjeneste', ' - Værdata'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-skap</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Flyselskap, vennskap, vitenskap, landskap<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>skap?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];
export const session74 = [
	{
		introAudio: mainAudio_74,
		questionAudio: questions_74,
		wordsAudio: words_74,
		mainHeading: '1. Dagens tekst',
		mainImg: session74Img,
		subHeading: 'Motorsykkel',
		phonemeData: phoneme74,
		DaysTextWords: wordsInTheEnd74[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'En motorsykkel har to hjul og en motor.',
			'Det krever øvelse for å kjøre trygt på veien.',
			'Man må også bruke hjelm.',
			'Noen bruker motorsykler for moro skyld og til sport.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				En motorsykkel har to hjul og en motor. Det krever øvelse for å kjøre trygt på veien. I
				mange land er det{' '}
				<span style={{ color: 'Lime' }}>
					<b>nødvendig</b>
				</span>{' '}
				med et motorsykkelsertifikat for å kjøre motorsykkel. Man må også bruke hjelm.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Mange kjører motorsykkel. De bruker dem til blant annet å komme seg til jobb og for å
				handle. Mange bruker dem også for moro skyld og til sport. Noen motorsykler er laget til
				bruk på asfalterte veier. Noen er laget for å kjøre på skitt, sand, gjørme og snø.
			</p>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En motorsykkel er et kjøretøy med to hjul og en motor. Motoren gjør at den er
					mye raskere enn en vanlig sykkel.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					En fører av en motorsykkel kalles en motorsyklist. De fleste motorsykler kan ha
					én passasjer bak. Noen har sidevogn for en tredje person. Noen kan også trekke
					en tilhenger til å frakte ekstra gjenstander i.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Det krever øvelse for å kjøre en motorsykkel trygt på veien. I mange land er det{' '}
					<span style={{ color: 'Lime' }}>
						<b>nødvendig</b>
					</span>{' '}
					med et eget sertifikat for å kjøre motorsykkel. Noen land{' '}
					<span style={{ color: 'Lime' }}>
						<b>påbyr</b>
					</span>{' '}
					fører og passasjerer å bruke hjelm.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					En motorsykkel er et populært transportmiddel. De bruker den til å komme seg
					rundt i hverdagen. Den brukes også til moro og til sport. Ofte brukes den til
					konkurranser og stunt.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Noen motorsykler er laget for bruk på asfalterte veier. Noen er laget for bruk i{' '}
					<span style={{ color: 'Lime' }}>
						<b>terrenget</b>
					</span>
					. De{' '}
					<span style={{ color: 'Lime' }}>
						<b>fungerer</b>
					</span>{' '}
					godt i skitt, sand, gjørme og snø.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva kalles føreren av en motorsykkel?',
			'2. Hvordan kan motorsykler ha med passasjerer?',
			'3. Kan du nevne tre ting en motorsykkel kan brukes til?',
			'4. Hvilke typer konkurranser tror du motorsykler brukes til?',
			'5. Hvem har lov å kjøre motorsykkel på veien?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Nødvendig', '- Påbyr', '- Terreng', '- Fungere'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-bar</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Brukbar, dyrebar, kostbar, vaskbar<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b> -bar?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];
export const session75 = [
	{
		introAudio: mainAudio_75,
		questionAudio: questions_75,
		wordsAudio: words_75,
		mainHeading: '1. Dagens tekst',
		mainImg: session75Img,
		subHeading: 'Båt',
		phonemeData: phoneme75,
		DaysTextWords: wordsInTheEnd75[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'En båt er et slags vannfartøy.',
			'Det er som et skip, men mindre.',
			'Den kan være laget av tre, metall, glassfiber eller plast.',
			'Båter beveger seg over vannet på forskjellige måter.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				En båt er et slags vannfartøy. Det er som et skip, men mindre. Den kan være laget av tre,
				metall, glassfiber eller plast.
			</p>,

			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Båter{' '}
				<span style={{ color: 'Lime' }}>
					<b>beveger</b>
				</span>{' '}
				seg over vannet på forskjellige måter.
			</p>,

			<br></br>,
			<p style={{ fontSize: '22px' }}>
				<li>
					Vinden{' '}
					<span style={{ color: 'Lime' }}>
						<b>skyver</b>
					</span>{' '}
					seilene til en seilbåt for å flytte den.
				</li>
				<li>
					Folk bruker årer for å få kanoer og robåter til å{' '}
					<span style={{ color: 'Lime' }}>
						<b>bevege</b>
					</span>{' '}
					seg.
				</li>
				<li>
					Motorbåter har motorer som hjelper dem å{' '}
					<span style={{ color: 'Lime' }}>
						<b>bevege</b>
					</span>{' '}
					seg.
				</li>
			</p>,

			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Båter kan brukes som{' '}
				<span style={{ color: 'Lime' }}>
					<b>transportmiddel</b>
				</span>
				. Noen båter brukes til arbeid, som for eksempel fiske og tauing. Noen båter brukes til
				sport.
			</p>,

			<span style={{ color: 'Lime' }}>
				<b></b>
			</span>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En båt er et slags vannfartøy. Det er som et skip, men vanligvis mindre. Båter
					kommer i mange{' '}
					<span style={{ color: 'Lime' }}>
						<b>størrelser</b>
					</span>{' '}
					og former. De kan være laget av tre, metall, glassfiber eller plast.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					Det er forskjellige måter å få en båt til å{' '}
					<span style={{ color: 'Lime' }}>
						<b>bevege</b>
					</span>{' '}
					seg gjennom vannet.
					<br></br>
					<li>Seilbåt har seil. Vind presser på seilene og flytter båten.</li>
					<li>
						Folk bruker årer for å få kanoer, kajakker og robåter til å{' '}
						<span style={{ color: 'Lime' }}>
							<b>bevege</b>
						</span>{' '}
						seg.
					</li>
					<li>
						Motorbåt har motor med propell. Den får båten til å{' '}
						<span style={{ color: 'Lime' }}>
							<b>bevege</b>
						</span>{' '}
						seg framover.
					</li>
				</p>

				<br></br>
				<p style={{ fontSize: '22px' }}>
					Hver del av en båt har et navn.
					<br></br>
					<li>Skrog - kroppen</li>
					<li>Dekk - gulvet</li>
					<li>Luke - dører eller vinduer</li>
					<li>Baug - forran</li>
					<li>Hekk - bak</li>
					<li>Babord - venstre side</li>
					<li>Styrbord - høyre side</li>
				</p>

				<br></br>
				<p style={{ fontSize: '22px' }}>
					Båter kan brukes til{' '}
					<span style={{ color: 'Lime' }}>
						<b>transport</b>
					</span>
					. Folk bruker også båter for å ha det gøy, for eksempel en dag med seiling eller
					padling i kajakk. Noen båter brukes til arbeid, som for eksempel fiske og
					tauing. Noen brukes til sport, for eksempel konkurranser.
				</p>
				<br></br>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er forskjellen på en båt og et skip?',
			'2. Hva kan en båt være laget av?',
			'3. Kan du nevne tre ting som får båter til å bevege seg i vannet?',
			'4. Kan du noen ord som er brukt på deler av båten?',
			'5. Hva kan båter brukes til?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Bevege', '- Skyve', '- Transportmiddel', '- Størrelse'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-dom</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Barndom, ungdom, fattigdom, sykdom<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>-dom?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];
export const session76 = [
	{
		introAudio: mainAudio_76,
		questionAudio: questions_76,
		wordsAudio: words_76,
		mainHeading: '1. Dagens tekst',
		mainImg: session76Img,
		subHeading: 'Lastebil/vogntog',
		phonemeData: phoneme76,
		DaysTextWords: wordsInTheEnd76[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'En lastebil er et stort kjøretøy.',
			'Noen lastebiler har en fremre del som kan kjøres alene.',
			'Det kalles en trekkbil.',
			'Den kan trekke vogner, som kalles tilhengere.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				En lastebil er et stort kjøretøy som frakter{' '}
				<span style={{ color: 'Lime' }}>
					<b>gjenstander</b>
				</span>{' '}
				på land. Disse fraktes bak i bilen. Sjåføren sitter foran i førerhuset. Noen lastebiler
				har en fremre del som kan kjøres alene. Det kalles en trekkbil. Den kan trekke vogner, som
				kalles tilhengere.
			</p>,
			<br></br>,

			<p style={{ fontSize: '22px' }}>Lastebiler har ulikt utseende:</p>,
			<p style={{ fontSize: '22px' }}></p>,

			<p style={{ fontSize: '22px' }}>
				<li>Skapbil har vegger på alle sider og tak.</li>
				<li>Plattbil har bare et gulv uten sider eller tak.</li>
				<li>Tankbiler har en lang, rund tank.</li>
			</p>,
			<span style={{ color: 'Lime' }}>
				<b></b>
			</span>,
			<br></br>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En lastebil er et stort motorkjøretøy som frakter{' '}
					<span style={{ color: 'Lime' }}>
						<b>gjenstander</b>
					</span>{' '}
					på land. Varene den frakter, er lasten eller frakten.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Det er nødvendig med førerkort for lastebil for å kjøre lastebil.
					Lastebilsjåføren sitter i{' '}
					<span style={{ color: 'Lime' }}>
						<b>kabinen</b>
					</span>
					, eller førerhuset, som er i den fremre delen av lastebilen. Den bakre delen er
					lasterommet.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Det er to{' '}
					<span style={{ color: 'Lime' }}>
						<b>hovedtyper</b>
					</span>{' '}
					lastebiler. Den ene har for- og bakdeler som alltid henger sammen. I den andre
					kalles den fremste delen trekkvogn. Den kan kjøres alene. Den kan trekke
					forskjellige bakdeler, som kalles tilhengere eller semitrailere.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Lastebiler er laget for ulike typer last. Her er noen av dem:
					<br></br>
					<li>Skapbil har vegger på alle sider og tak.</li>
					<li>Dumpere har sider, men ikke tak. De vipper opp og dumper lasten bak.</li>
					<li>Plattbil har bare et gulv uten sider eller tak.</li>
					<li>
						Betongbiler frakter betong i en{' '}
						<span style={{ color: 'Lime' }}>
							<b>trommel</b>
						</span>
						. Den har en renne til å helle ut betongen.
					</li>
					<li>
						Tankbiler har vanligvis en lang, rund tank. De frakter væsker og
						gasser.
					</li>
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er en trekkbil?',
			'2. Hva heter den delen av bilen som sjåføren sitter i?',
			'3. Hva er forskjellen på en skapbil og en plattbil?',
			'4. Hvorfor tror du en tankbil heter tankbil?',
			'5. Lastebiler er laget for ulike typer last, kan du navnet på noen av bilene?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Gjenstand', '- Kabin', '- Hovedtype', '- Trommel'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-som</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Arbeidsom, hjelpsom, morsom, smittsom<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>som?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session77 = [
	{
		introAudio: mainAudio_77,
		questionAudio: questions_77,
		wordsAudio: words_77,
		mainHeading: '1. Dagens tekst',
		mainImg: session77Img,
		subHeading: 'Tog',
		phonemeData: phoneme77,
		DaysTextWords: wordsInTheEnd77[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			<p>
				Et tog{' '}
				<span style={{ color: 'Lime' }}>
					<b>består</b>
				</span>{' '}
				av et lokomotiv, eller motor.
			</p>,
			<p>
				Det trekker vogner på{' '}
				<span style={{ color: 'Lime' }}>
					<b>skinner</b>
				</span>{' '}
				eller spor.
			</p>,
			<p>Persontog frakter passasjerer.</p>,
			<p>Godstog frakter last eller gods.</p>,
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				Et tog består av et lokomotiv. Det trekker vogner på{' '}
				<span style={{ color: 'Lime' }}>
					<b>skinner</b>
				</span>{' '}
				eller spor. Det har en sjåfør. Persontog frakter passasjerer. Disse togene har vogner med
				seter, kafeer og senger. Godstog frakter last eller gods.
			</p>,
			<br></br>,

			<p style={{ fontSize: '22px' }}>
				En monorail er en bane for tog som kjører på én skinne. Maglev- tog berører ikke sporet
				når de er i bevegelse. Noen tog kjører over hodene våre. T-bane-, undergrunns- eller
				metrotog kjører for det meste under jorden.
			</p>,

			<br></br>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Et tog{' '}
					<span style={{ color: 'Lime' }}>
						<b>består</b>
					</span>{' '}
					av et lokomotiv som trekker en eller flere vogner. Det trekker dem på{' '}
					<span style={{ color: 'Lime' }}>
						<b>skinner</b>
					</span>{' '}
					av metall eller på spor.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Et tog frakter passasjerer fra en stasjon til en annen. Det kan også frakte last
					eller gods. Mange persontog har forskjellige vogner. Det er vogner med seter,
					kafeer, senger og vogner til bagasje.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Godstog har vogner for ulike typer gods. Det kan være flate vogner, åpne vogner
					med sider, åpne vogner med støtter, lukkede vogner med tak og tankvogner.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Sjåføren eller ingeniøren sitter i lokomotivet. Konduktøren eller toglederen har{' '}
					<span style={{ color: 'Lime' }}>
						<b>ansvaret</b>
					</span>{' '}
					for passasjerene og lasten. Andre som jobber på et passasjertog, kan være kokker
					og renholdere.
					<br></br>
					Mange tog kjører på{' '}
					<span style={{ color: 'Lime' }}>
						<b>gatenivå</b>
					</span>{' '}
					på et par{' '}
					<span style={{ color: 'Lime' }}>
						<b>skinner</b>
					</span>
					, men:
					<li>Noen elektriske tog får strøm fra en tredje skinne.</li>
					<li>Monorails er tog som kjører på én skinne under eller over toget.</li>
					<li>Maglev-tog kjører noen centimeter over sporet. De går veldig fort.</li>
					<li>
						Høytog kjører på spor over{' '}
						<span style={{ color: 'Lime' }}>
							<b>gatenivå</b>
						</span>
						.
					</li>
					<li>T-bane-, undergrunns- eller metrotog kjører for det meste under jorden.</li>
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva består et tog av?',
			'2. Hvor kjører tog?',
			'3. Hva er forskjellene på et persontog og et godstog?',
			'4. Hva er monorails?',
			'5. Hvorfor tror du noen tog blir kalt undergrunnstog?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Består', '- Skinner', '- Ansvar', '- Gatenivå'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>på</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Pågår, pårørende, påstand, påbudt<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som begynner på <b>som?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session78 = [
	{
		introAudio: mainAudio_78,
		questionAudio: questions_78,
		wordsAudio: words_78,
		mainHeading: '1. Dagens tekst',
		mainImg: session78Img,
		subHeading: 'Bil',
		phonemeData: phoneme78,
		DaysTextWords: wordsInTheEnd78[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'En bil er et kjøretøy med fire hjul og en motor.',
			'Et eldre ord for bil er automobil. ',
			'En person må ha førerkort for å kjøre den.',
			'De fleste biler bruker bensin eller diesel. ',
			'Noen bruker strøm.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				En bil er et kjøretøy med fire hjul og en motor. Et eldre ord for bil er automobil. En
				person må ha førerkort for å kjøre den.
			</p>,
			<br></br>,

			<p style={{ fontSize: '22px' }}>
				Det er rundt én milliard biler i verden. Folk bruker dem til å kjøre korte og lange
				avstander. De har forskjellige{' '}
				<span style={{ color: 'Lime' }}>
					<b>funksjoner</b>
				</span>{' '}
				som skal beskytte sjåføren og passasjerene i tilfelle det skjer en ulykke. Noen av disse
				er setebelter, kollisjonsputer og sikkerhetsglass.
			</p>,

			<p style={{ fontSize: '22px' }}>
				De fleste biler bruker bensin eller diesel. Noen bruker strøm. Hybridbiler bruker begge
				deler.
			</p>,

			<br></br>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En bil er et kjøretøy med fire hjul og en motor. Et{' '}
					<span style={{ color: 'Lime' }}>
						<b>eldre</b>
					</span>{' '}
					ord for bil er automobil. Noen biler har bare to dører. Noen har plass til
					opptil åtte personer. Noen er laget for terrengkjøring. En person må ha
					førerkort for å kunne kjøre bil.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Bilen ble oppfunnet av Carl Benz i 1886. Nå er det{' '}
					<span style={{ color: 'Lime' }}>
						<b>omtrent</b>
					</span>{' '}
					én milliard biler i verden. I hverdagen bruker folk dem på mange måter. De
					bruker dem til korte turer nær hjemmet og til lange reiser. Folk bruker biler
					til motorsport også, for eksempel billøp.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Biler har frontlys, baklys, blinklys og speil. Disse hjelper sjåfører med å
					kjøre trygt på veien. Biler har andre sikkerhets
					<span style={{ color: 'Lime' }}>
						<b>funksjoner</b>
					</span>{' '}
					for å beskytte sjåføren og passasjerene i tilfelle det oppstår en ulykke. Noen
					av disse er setebelter, kollisjonsputer og sikkerhetsglass.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					De fleste biler går på bensin eller diesel, men det gir gasser som skader
					miljøet. De som produserer biler har begynt å lage biler som bruker{' '}
					<span style={{ color: 'Lime' }}>
						<b>alternative</b>
					</span>{' '}
					energikilder. Hybridbiler går på både bensin og elektrisitet.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor mange biler er det i verden?',
			'2. Hvilket utstyr i bilen kan beskytte sjåføren og passasjerene?',
			'3. Hvem fant opp bilen?',
			'4. Hva er alternativ energikilde for biler?',
			'5. Hvorfor tror du folk vil ha biler som går på elektrisitet?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Eldre', '- Omtrent', '- Funksjon', '- Alternativ'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-løs</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Arbeidsløs, ansvarsløs, barnløs, foreldreløs<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>-løs?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session79 = [
	{
		introAudio: mainAudio_79,
		questionAudio: questions_79,
		wordsAudio: words_79,
		mainHeading: '1. Dagens tekst',
		mainImg: session79Img,
		subHeading: 'Buss',
		phonemeData: phoneme79,
		DaysTextWords: wordsInTheEnd79[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'En buss er et stort kjøretøy som kan frakte mange mennesker.',
			'En bussjåfør må ha et spesielt førerkort.',
			'Det finnes mange typer busser.',
			'En skolebuss kjører barn til og fra skolen.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				En buss er et stort kjøretøy som kan frakte flere mennesker enn det en bil kan. En
				bussjåfør må ha et spesielt førerkort.
			</p>,
			<br></br>,

			<p style={{ fontSize: '22px' }}>
				Busser brukes til transport. En buss henter og slipper av passasjerer på egne stasjoner
				eller steder. Det finnes mange{' '}
				<span style={{ color: 'Lime' }}>
					<b>typer</b>
				</span>{' '}
				busser.
				<li>En toetasjes buss har to etasjer med seter.</li>
				<li>En skolebuss kjører barn til og fra skolen.</li>
				<li>En kombibuss frakter både passasjerer og last.</li>
			</p>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En buss er et stort kjøretøy som kan frakte flere mennesker enn en bil. Noen kan
					frakte så mange som 300 personer. En bussjåfør må ha et spesielt førerkort for å
					kjøre store kjøretøy.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Busser brukes oftest til{' '}
					<span style={{ color: 'Lime' }}>
						<b>kollektivtransport</b>
					</span>
					, og folk må betale for å bruke dem. En buss henter og slipper av passasjerer på
					busstasjoner eller ved bussholdeplasser langs ruten. Noen busser har lett{' '}
					<span style={{ color: 'Lime' }}>
						<b>tilgjengelige</b>
					</span>{' '}
					funksjoner. Disse gjør at rullestolbrukere og barnevogner kan komme trygt på og
					av bussen.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Det finnes mange{' '}
					<span style={{ color: 'Lime' }}>
						<b>typer</b>
					</span>{' '}
					busser.
					<li>En toetasjes buss har to etasjer med seter for passasjerer.</li>
					<li>En leddbuss består av to eller flere deler.</li>
					<li>En skolebuss frakter barn til og fra skolen.</li>
					<li>
						En buss er for{' '}
						<span style={{ color: 'Lime' }}>
							<b>langdistanse</b>
						</span>
						reiser. Den har rikelig med bagasjeplass, toaletter og
						underholdningssystemer for passasjerer.
					</li>
					<li>En skyttelbuss kjører et kort stykke mellom to steder.</li>
					<li>En kombibuss frakter både passasjerer og last.</li>
				</p>
				<br></br>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er forskjellene på en bil og en buss?',
			'2. Hva er en kombibuss?',
			'3. Kan du nevne fire ulike typer busser?',
			'4. Hvorfor tror du vi må betale for å reise med buss?',
			'5. Hvilke typer busser har du reist med?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Kollektivtransport', '- Tilgjengelig', '- Langdistanse', '- Typer'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-het</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Kjærlighet, hastighet, dumhet, sikkerhet<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>-het?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session80 = [
	{
		introAudio: mainAudio_80,
		questionAudio: questions_80,
		wordsAudio: words_80,
		mainHeading: '1. Dagens tekst',
		mainImg: session80Img,
		subHeading: 'Traktor',
		phonemeData: phoneme80,
		DaysTextWords: wordsInTheEnd80[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'En traktor er et kjøretøy som ofte brukes på gårder. ',
			'Den er treg, men den kan trekke med mye kraft.',
			'Traktoren trekker landbruksutstyr.',
			'Traktorer kan også brukes til brøyting.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				En traktor er et kjøretøy som ofte brukes på gårder. Den er treg, men den kan trekke med
				mye kraft. Hovedoppgaven er å trekke landbruksutstyr mens de jobber. Det kan også gi dem
				kraften de trenger for å jobbe.
			</p>,
			<br></br>,

			<p style={{ fontSize: '22px' }}>
				Traktorer bidrar til at arbeidet på gårder blir mye mer produktivt. De kan gjøre jobben
				til mange gårdsarbeidere på kortere tid.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Traktorer kan også hjelpe til med:
				<li>Graving og brøyting</li>
				<li>Så frø og frøplanter</li>
				<li>Legge høy i baller</li>
				<li>
					Gjødsling av{' '}
					<span style={{ color: 'Lime' }}>
						<b>avlinger</b>
					</span>
				</li>
			</p>,
		],
		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En traktor er et tungt kjøretøy som ofte brukes på gårder. Den har vanligvis kun
					et sete for sjåføren. Den er ikke laget for å frakte personer. I noen land er
					det nødvendig med et førerkort for å kjøre traktor på offentlig vei.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					En traktor er treg, men har mye kraft. Hovedoppgaven er å trekke landbruksutstyr
					mens de jobber. Det er også mulig å feste utstyr foran på traktoren. Traktoren
					kan også gi kraften utstyret trenger for å fungere.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					Før traktoren brukte bonden sterke dyr, som hester, til å trekke plogen.
					Traktorer har{' '}
					<span style={{ color: 'Lime' }}>
						<b>bidratt</b>
					</span>{' '}
					til at gårder har blitt mye mer{' '}
					<span style={{ color: 'Lime' }}>
						<b>produktive</b>
					</span>
					. En traktor kan for eksempel{' '}
					<span style={{ color: 'Lime' }}>
						<b>høste</b>
					</span>{' '}
					like mye som 100 personer kunne{' '}
					<span style={{ color: 'Lime' }}>
						<b>høste</b>
					</span>{' '}
					på samme tid.
				</p>

				<p style={{ fontSize: '22px' }}>
					Traktorer kan også hjelpe til med:
					<li>Graving og brøyting</li>
					<li>Så frø og frøplanter</li>
					<li>Legge høy i baller</li>
					<li>
						Gjødsling av{' '}
						<span style={{ color: 'Lime' }}>
							<b>avlinger</b>
						</span>
					</li>
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Mange traktorer har to store bakhjul og to små hjul foran. Noen traktorer har
					belter i stedet for hjul.
				</p>
			</span>
		),
		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor brukes traktorer som oftest?',
			'2. Hva er hovedoppgaven til traktorer?',
			'3. Hva brukte bøndene før de fikk traktor?',
			'4. Hvorfor tror du traktorer har to store bakhjul og to små hjul foran?',
			'5. Hvor mye kan en traktor høste sammenlignet med mennesker?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Bidratt', '- Produktiv', '- Høste', '- Avlinger'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-aktig</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Grønnaktig, glassaktig, gnieraktig, eventyraktig<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>-aktig?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session81 = [
	{
		introAudio: mainAudio_81,
		questionAudio: questions_81,
		wordsAudio: words_81,
		mainHeading: '1. Dagens tekst',
		mainImg: session81Img,
		subHeading: 'Motorisert sparkesykkel',
		phonemeData: phoneme81,
		DaysTextWords: wordsInTheEnd81[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'Elsparkesykler har en liten, elektrisk motor. ',
			'Føreren trenger ikke å sparke for å holde sparkesykkelen i gang.',
			'Noen land har aldersgrenser for hvor gammel føreren må være.',
			'Det er regler for hvor den kan brukes. ',
			'Føreren må bruke hjelm.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				En motorisert sparkesykkel er en sparkesykkel som har sin egen kilde til energi. De fleste
				er elsparkesykler. Disse har en liten, elektrisk motor. Føreren trenger ikke å sparke for
				å holde sparkesykkelen i gang.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Folk bruker sparkesyklene som et transportmiddel. De bruker dem også for moro skyld og til
				kappløp. Noen land har aldersgrenser for hvor gammel føreren må være. Det er regler for
				hvor den kan brukes og at føreren må bruke en hjelm også.
			</p>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En motorisert sparkesykkel er en{' '}
					<span style={{ color: 'Lime' }}>
						<b>oppreist</b>
					</span>{' '}
					skuter som har sin egen energikilde. Føreren må ikke sparke for å holde den i
					gang. Motoriserte sparkesykler er laget for voksne og barn. Folk bruker dem som
					et{' '}
					<span style={{ color: 'Lime' }}>
						<b>transportmiddel</b>
					</span>
					. De kjører dem også for moro skyld og har kappløp.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					I noen land:
					<li>Føreren må ha lisens eller tillatelse.</li>
					<li>Det er aldersgrenser som sier hvor gammel føreren må være.</li>
					<li>Hjelm er påbudt når du kjører.</li>
					<li>Sparkesykkelen må ha skarpe lys.</li>
					<li>
						Det er også regler om hvor de motoriserte sparkesyklene kan brukes i{' '}
						<span style={{ color: 'Lime' }}>
							<b>offentlige</b>
						</span>{' '}
						rom.
					</li>
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					De fleste moderne motoriserte sparkesykler har en liten elektrisk motor i ett
					eller begge hjulene. Denne typen sparkesykler kalles en elsparkesykkel. Det er
					noen elsparkesykler som kan slås sammen. Dette gjør dem lettere å{' '}
					<span style={{ color: 'Lime' }}>
						<b>oppbevare</b>
					</span>{' '}
					og bære når de ikke er i bruk.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					Den første motoriserte sparkesykkelen ble laget i 1915. Den ble drevet av en
					liten bensinmotor. Slik ble de fleste motoriserte skutere laget tidligere. Noen
					få er fortsatt slik.
				</p>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvem er motoriserte sparkesykler laget for?',
			'2. Hva bruker folk disse til?',
			'3. Kan du nevne noen regler for bruk av sparkesykler?',
			'4. Hvor er motoren på motoriserte sparkesykler?',
			'5. Hvorfor tror du disse syklene er så populære ?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Oppreist', '- Transportmiddel', '- Offentlig', '- Oppbevare'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>mis-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Mislike, misforstå, mistanke, misfornøyd<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som begynner på <b>mis- ?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session82 = [
	{
		introAudio: mainAudio_82,
		questionAudio: questions_82,
		wordsAudio: words_82,
		mainHeading: '1. Dagens tekst',
		mainImg: session82Img,
		subHeading: 'Snøskuter',
		phonemeData: phoneme82,
		DaysTextWords: wordsInTheEnd82[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'En snøskuter er et vinterkjøretøy.',
			'Folk bruker snøskutere for å reise.',
			'De bruker dem også til kappløp.',
			'En snøskuter kan til og med kjøres over vann.',
			'Det kalles vanncross.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				En snøskuter er et vinterkjøretøy. Den kalles også beltemotorsykkel. Den har et par ski
				foran som hjelper den med å endre retning. Den har belte bak som hjelper den å bevege seg
				over isen og snøen.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Folk bruker snøskutere for å reise. De bruker dem også til kappløp. De kjører dem på gress
				og asfalt for moro skyld eller som en sport. En snøskuter kan til og med kjøres over vann.
				Det kalles vanncross.
			</p>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					En snøskuter er et vinterkjøretøy. Den kalles også beltemotorsykkel eller
					motorslede. Snøskutere er veldig populære på steder med lange,{' '}
					<span style={{ color: 'Lime' }}>
						<b>snørike</b>
					</span>{' '}
					vintre. Førere kan bruke en spesiell dress når de kjører snøskuter. Den
					beskytter dem mot kulde, snø og vind.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					En snøskuter er laget for å brukes på snø eller is. Den har et par ski foran og{' '}
					<span style={{ color: 'Lime' }}>
						<b>belte</b>
					</span>{' '}
					bak. Beltene gjør at den kan kjøre over is og snø. Skiene hjelper den med å{' '}
					<span style={{ color: 'Lime' }}>
						<b>endre</b>
					</span>{' '}
					retning. Føreren kan{' '}
					<span style={{ color: 'Lime' }}>
						<b>endre</b>
					</span>{' '}
					retning ved å{' '}
					<span style={{ color: 'Lime' }}>
						<b>endre</b>
					</span>{' '}
					hvordan han står eller sitter.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					De fleste snøskutere er laget for å frakte én person. I noen land har en person
					med en annen type førerkort lov til å kjøre snøskuter også. I andre land kreves
					det et spesielt førerkort for å kjøre den.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					Folk bruker snøskutere for å reise. De bruker dem også til kappløp. De kjører
					dem på gress og asfalt for moro skyld eller som en sport. En snøskuter kan til
					og med kjøres over vann. Det kalles vanncross.
				</p>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Vet du et annet navn på snøskuter?',
			'2. Hvor er snøskutere mest populære?',
			'3. Hvordan klarer snøskutere å svinge?',
			'4. Hva brukes snøskutere til?',
			'5. Hvorfor tror du snøskutere kan kjøre over vann?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Snørik', '- Belte', '- Endre', '- Retning'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-ning</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Retning, betydning, åpning, undervisning<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>-ning ?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session83 = [
	{
		introAudio: mainAudio_83,
		questionAudio: questions_83,
		wordsAudio: words_83,
		mainHeading: '1. Dagens tekst',
		mainImg: session83Img,
		subHeading: 'Hvordan feires 17.mai?',
		phonemeData: phoneme83,
		DaysTextWords: wordsInTheEnd83[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'Alle som har flaggstang heiser det norske flagget på 17.mai.',
			'Tidlig på dagen går skoleelever i tog, vifter med flagg og synger sanger.',
			'Etter toget samles mange på skolen der de spiser pølser og is.',
			'De leker mange forskjellige leker.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				Alle som har flaggstang heiser det norske flagget på 17.mai. Det er vanlig å pynte seg på
				17.mai.Tidlig på dagen går skoleelever i tog, vifter med flagg og synger sanger. Toget har
				ofte et musikkorps. Etter toget samles mange på skolen der de spiser pølser og is. De
				leker mange forskjellige leker. Noen byer har større tog på ettermiddagen der ulike korps,
				idrettslag og klubber deltar.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				På ettermiddagen spiser mange god middag og avslutter med is eller kake.
			</p>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Alle som har flaggstang heiser det norske flagget på 17.mai. Der er vanlig å
					pynte seg på 17.mai. Tidlig på dagen går skoleelever i tog, vifter med flagg og
					synger sanger. Toget har ofte et musikkorps. Etter toget samles mange på skolen
					der de spiser pølse og is. De leker mange forskjellige leker. Noen byer har
					større tog på ettermiddagen der ulike korps, idrettslag og klubber{' '}
					<span style={{ color: 'Lime' }}>
						<b>deltar</b>
					</span>
					.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					På 17.mai-feiringen har man ofte{' '}
					<span style={{ color: 'Lime' }}>
						<b>taler</b>
					</span>{' '}
					om hva som er bra med Norge. I talene snakkes det mye om hvorfor det er trygt og
					godt å bo her og om det å være norsk. Mange setter fokus på ting de er{' '}
					<span style={{ color: 'Lime' }}>
						<b>opptatt av</b>
					</span>{' '}
					og som de vil skal bli bedre i landet og samfunnet vårt. Siden vi feirer at
					Norge er et fritt land vil mange minne om at det ikke er slik for alle.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					På ettermiddagen spiser mange god middag og{' '}
					<span style={{ color: 'Lime' }}>
						<b>avslutter</b>
					</span>{' '}
					med is eller kake.
				</p>
				<br></br>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvordan feirer skoleelever 17.mai?',
			'2. Hva snakker de som holder tale om?',
			'3. Hvorfor feirer vi 17.mai?',
			'4. Hva er vanlig å gjøre på ettermiddagen?',
			'5. Hva liker du å gjøre på !7.mai?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Delta', '- Taler', '- Opptatt av', '- Avslutte'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>sam-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Samarbeide, samfunn, samtale, samtidig<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som begynner på <b>Sam ?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session84 = [
	{
		introAudio: mainAudio_84,
		questionAudio: questions_84,
		wordsAudio: words_84,
		mainHeading: '1. Dagens tekst',
		mainImg: session84Img,
		subHeading: 'Hvorfor er 17.mai Norges nasjonaldag?',
		phonemeData: phoneme84,
		DaysTextWords: wordsInTheEnd84[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			<p>
				Før 1814 ble Norge{' '}
				<span style={{ color: 'Lime' }}>
					<b>styrt av</b>
				</span>{' '}
				Danmark.
			</p>,
			<p>Mange nordmenn ville at Norge skulle bestemme over seg selv.</p>,
			<p>De møttes på Eidsvoll for å lage sin egen grunnlov.</p>,
			<p>Den nye loven ble skrevet under 17.mai 1814.</p>,
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				Før 1814 var Norge{' '}
				<span style={{ color: 'Lime' }}>
					<b>forent</b>
				</span>{' '}
				med Danmark og ble danskene. Danmark var på tapersiden av en stor krig i Europa og måtte
				gi Norge vekk til Sverige. Mange nordmenn ville at Norge skulle bestemme over seg selv, så
				de møttes på Eidsvoll for å lage sin egen grunnlov. Den nye loven ble skrevet under 17.mai
				1814.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Vi feirer 17.mai til minne om da grunnloven ble til på Eidsvoll.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Helt siden 1906 har kongefamilien hilst på barnetoget i Oslo fra Slottsbalkongen. Dette
				har blitt en over hundre år lang tradisjon.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>17.mai feires over hele landet.</p>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Før 1814 var Norge{' '}
					<span style={{ color: 'Lime' }}>
						<b>forent</b>
					</span>{' '}
					med Danmark og ble{' '}
					<span style={{ color: 'Lime' }}>
						<b>styrt av</b>
					</span>{' '}
					danskene. Danmark var på tapersiden av en stor krig i Europa og måtte gi Norge
					vekk til Sverige. Mange nordmenn ville at Norge skulle bestemme over seg selv,
					så de møttes på Eidsvoll for å lage sin egen grunnlov. Den nye loven ble skrevet
					under 17.mai 1814.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					Den danske kronprinsen Christian Fredrik var veldig populær i Norge, og mennene
					på Eidsvoll valgte han som konge. Det ble krig mellom Norge og Sverige, med den{' '}
					<span style={{ color: 'Lime' }}>
						<b>varte</b>
					</span>{' '}
					bare i to uker.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Det ble fred og Sverige{' '}
					<span style={{ color: 'Lime' }}>
						<b>godkjente</b>
					</span>{' '}
					grunnloven fra Eidsvoll. Norge bestemte nå over seg selv.
				</p>
				<p style={{ fontSize: '22px' }}>
					Vi feirer 17.mai til minne om da grunnloven ble til på Eidsvoll.
				</p>
				<p style={{ fontSize: '22px' }}>
					Helt siden 1906 har kongefamilien hilst på barnetoget i Oslo fra
					Slottsbalkongen. Dette har blitt en over hundre år lang tradisjon.
				</p>
				<p style={{ fontSize: '22px' }}>17.mai feires over hele landet.</p>
				<br></br>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvem styrte Norge før 1814?',
			'2. Det ble krig mellom Norge og Sverige, hvor lenge varte den?',
			'3. Hva skjedde på Eidsvoll?',
			'4. Hvilke tradisjoner har du på 17.mai?',
			'5. Hvorfor er 17.mai Norges nasjonaldag?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Forent', '- Styrt av', '- Varte', '- Godkjente'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>mot-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Motsi, motsatt, motstand, motpart<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som begynner på <b>mot ?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session85 = [
	{
		introAudio: mainAudio_85,
		questionAudio: questions_85,
		wordsAudio: words_85,
		mainHeading: '1. Dagens tekst',
		mainImg: session85Img,
		subHeading: 'Vårblomster',
		phonemeData: phoneme85,
		DaysTextWords: wordsInTheEnd85[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'Våren er tiden når planter dukker opp igjen etter vinteren.',
			'Hestehov er en gul vårblomst. ',
			'Den ligner litt på en løvetann.',
			'Hvitveis og snøklokke er hvite.',
			'Blåveis og krokus er lilla.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				Det er fire årstider i løpet av et år: sommer, høst, vinter og vår. Våren er tiden når
				planter dukker opp igjen etter de kalde vintermånedene. Du kan finne mange blomster som
				begynner å blomstre. En av disse vårblomstene blir kalt for hestehov. Det er en liten
				blomst med knallgule kronblad. Hestehoven ser litt ut som løvetann. De vokser ofte i
				veikanter og på stier.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Mange vårblomster er hvite, som for eksempel hvitveis og snøklokke.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Det finnes også lilla vårblomster. Som for eksempel blåveis og krokus.
			</p>,
			<br></br>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Det er flere årstider i løpet av ett år: sommer, høst, vinter og vår.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					Våren er tiden når mange planter starter å blomstre igjen etter flere kalde
					måneder med vinter.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Hestehov er en gul vårblomst. Den ligner litt på en løvetann. På latin heter den
					tussilago farfara. Hestehov vokser{' '}
					<span style={{ color: 'Lime' }}>
						<b>langs</b>
					</span>{' '}
					veikanter og stier på{' '}
					<span style={{ color: 'Lime' }}>
						<b>tvers</b>
					</span>{' '}
					over hele Europa. Hestehov blir noen ganger benyttet som urtemedisin. Dette er
					en tradisjonell form for medisin som bruker naturlige{' '}
					<span style={{ color: 'Lime' }}>
						<b>ingredienser</b>
					</span>
					, som planter, for å{' '}
					<span style={{ color: 'Lime' }}>
						<b>behandle</b>
					</span>{' '}
					sykdommer. Bladene til hestehoven har blitt benyttet i te og kremer. Man tror at
					dette kan hjelpe til med å{' '}
					<span style={{ color: 'Lime' }}>
						<b>behandle</b>
					</span>{' '}
					en rekke sykdommer, som for eksempel forkjølelse og feber.
				</p>
				<p style={{ fontSize: '22px' }}>
					Noen vårblomster har hvite kronblader. To eksempel på dette er hvitveis og
					snøklokker.
				</p>
				<p style={{ fontSize: '22px' }}>
					Mange vårblomster har lilla kronblader. To av disse er blåveis og krokus.
				</p>
				<br></br>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Kan du si alle årstidene i rekkefølge?',
			'2. Når starter planter å blomstre?',
			'3. Hvilken blomst er tussilago farfara?',
			'4. Hva kan hestehoven brukes til?',
			'5. Hvorfor tror du noen bruker urtemedisin?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Tvers', '- Langs', '- Behandle', '- Ingredienser'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>bi-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Bivirkning, bijobb, biinntekt, bismak<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som begynner på <b>bi?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session86 = [
	{
		introAudio: mainAudio_86,
		questionAudio: questions_86,
		wordsAudio: words_86,
		mainHeading: '1. Dagens tekst',
		mainImg: session86Img,
		subHeading: 'Vårens insekter del 1',
		phonemeData: phoneme86,
		DaysTextWords: wordsInTheEnd86[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'De første humlene som våkner  er dronninger.',
			'De leter etter et sted der de lager et bol. ',
			'Du kan hjelpe humlen ved å gi den sukkervann eller et bananskall. ',
			'Redder du en dronning, redder du hundrevis av humler og ',
			'hjelper tusenvis av planter.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				De første humlene som våkner er dronninger som har overvintret. De leter etter et sted der
				de lager et bol.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Tidlig på våren er dronninghumler utmattede og har lite energi. Du kan hjelpe humlen ved å
				gi den sukkervann eller legge ut et bananskall. Redder du en dronning, redder du faktisk
				hundrevis av humler og hjelper tusenvis av planter.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Humler, bier, sommerfugler og biller er eksempler på insekter som bestøver blomster som
				blant annet brukes i matproduksjonen vår. Uten disse hjelperne vil det bli vanskelig å{' '}
				<span style={{ color: 'Lime' }}>
					<b>produsere</b>
				</span>{' '}
				mat.
			</p>,
			<br></br>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					De første humlene våkner tidligst i mars. Det er dronninger som har{' '}
					<span style={{ color: 'Lime' }}>
						<b>overvintret</b>
					</span>
					. De leter etter et sted der de lager et bol. Det kan være et forlatt musehi
					eller en fuglekasse. Alle humler som blir født først på våren er hunner. Dette
					er arbeidere som starter å sanke mat så fort som mulig om våren. Hannene kalles
					for droner.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					Har du sett{' '}
					<span style={{ color: 'Lime' }}>
						<b>døsige</b>
					</span>{' '}
					humler tidlig på våren? Da er det{' '}
					<span style={{ color: 'Lime' }}>
						<b>utmattede</b>
					</span>{' '}
					dronninghumler du ser som har{' '}
					<span style={{ color: 'Lime' }}>
						<b>overvintret</b>
					</span>{' '}
					og har lite energi. Du kan hjelpe humlen ved å gi den sukkervann eller legge ut
					et bananskall. Redder du en dronning, redder du faktisk hundrevis av humler og
					hjelper tusenvis av planter.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Humler, bier, sommerfugler og biller er eksempler på insekter som bestøver
					blomster som blant annet brukes i matproduksjonen vår. Uten disse hjelperne vil
					det bli vanskelig å{' '}
					<span style={{ color: 'Lime' }}>
						<b>produsere</b>
					</span>{' '}
					mat.
				</p>
				<br></br>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva gjør de første humlene som våkner?',
			'2. Hvordan kan du hjelpe humler med lite energi?',
			'3. Hvorfor tror du humlene har lite energi når de våkner?',
			'4. Hvorfor er det lurt å redde humlene?',
			'5. Kan du nevne noen insekter som bestøver blomstene?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Overvintre', '- Utmattede', '- Produsere', '- Døsig'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-rik</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Fargerik, idèrik, lærerik, næringsrik<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>-rik?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session87 = [
	{
		introAudio: mainAudio_87,
		questionAudio: questions_87,
		wordsAudio: words_87,
		mainHeading: '1. Dagens tekst',
		mainImg: session87Img,
		subHeading: 'Insekter del 2',
		phonemeData: phoneme87,
		DaysTextWords: wordsInTheEnd87[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'Insekter trenger planter til mat. ',
			'Mange insekter lever og bor også i planter.',
			'Planter trenger insekter for å frakte pollen fra blomst til blomst. ',
			'Dette hjelper planter å lage frukt og frø.',
			'Det er dette bier, maur og humler gjør om våren.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				Det finnes rundt 10 millioner insekttyper i verden. De første insektene bodde på jorden
				for rundt 480 millioner år siden. Jorden har{' '}
				<span style={{ color: 'Lime' }}>
					<b>endret</b>
				</span>{' '}
				seg mye siden den gang.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Insekter og planter har også{' '}
				<span style={{ color: 'Lime' }}>
					<b>endret</b>
				</span>{' '}
				seg. Insekter trenger planter til mat. Mange insekter lever og bor også i planter.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Planter trenger insekter for å frakte pollen fra blomst til blomst. Dette hjelper planter
				å lage frukt og frø. Insekter frakter frø til nye steder som igjen vokser til nye planter.
				Det er dette bier, maur og humler gjør om våren.
			</p>,

			<br></br>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Det finnes rundt 10 millioner insekttyper i verden. De første insektene bodde på
					jorden for rundt 480 millioner år siden. Jorden har{' '}
					<span style={{ color: 'Lime' }}>
						<b>endret</b>
					</span>{' '}
					seg mye siden den gang.
				</p>
				<br></br>

				<p style={{ fontSize: '22px' }}>
					Insekter og planter har også{' '}
					<span style={{ color: 'Lime' }}>
						<b>endret</b>
					</span>{' '}
					seg. De utvikler seg slik at de kan{' '}
					<span style={{ color: 'Lime' }}>
						<b>overleve</b>
					</span>{' '}
					på jorden som er i stadig endring. Insekter og planter har et symbiotisk{' '}
					<span style={{ color: 'Lime' }}>
						<b>forhold</b>
					</span>
					. De trenger hverandre. De forandrer seg hver gang den andre forandrer seg.
					Insekter trenger planter til mat. Mange insekter lever og bor også i planter.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Om insektene ikke hadde{' '}
					<span style={{ color: 'Lime' }}>
						<b>endret</b>
					</span>{' '}
					seg som plantene{' '}
					<span style={{ color: 'Lime' }}>
						<b>endrer</b>
					</span>{' '}
					seg, ville de ganske enkelt forsvinne.
				</p>
				<p style={{ fontSize: '22px' }}>
					Planter trenger insekter for å frakte pollen fra blomst til blomst. Dette
					hjelper planter å lage frukt og frø. Insekter frakter frø til nye steder som
					igjen vokser til nye planter. Det er dette bier, maur og humler gjør om våren.
				</p>
				<br></br>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvor mange insekttyper finnes i verden?',
			'2. Hva menes med at insekter og planter har et symbiotisk forhold?',
			'3. Hva kan skje hvis insekter ikke endrer seg som plantene?',
			'4. Hvordan er insekter og planter avhengig av hverandre?',
			'5. Hva kan vi gjøre for insektene?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Endre', '- Symbiotiske', '- Forhold', '- Overleve'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-full</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Smakfull, talentfull, verdifull, skamfull<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>-full?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session88 = [
	{
		introAudio: mainAudio_88,
		questionAudio: questions_88,
		wordsAudio: words_88,
		mainHeading: '1. Dagens tekst',
		mainImg: session88Img,
		subHeading: 'Forurensning del 1',
		phonemeData: phoneme88,
		DaysTextWords: wordsInTheEnd88[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'Mye av plasten havner i havet over hele verden og fraktes rundt med havstrømmene. ',
			'Mye ender opp i strandsonen overalt i verden.',
			'Mange skoler deltar i strandrydding og plukking av søppel, spesielt om våren. ',
			'Elevene gjør en veldig viktig jobb når de rydder i naturen.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				De siste årene har fokuset på plast i havet vært{' '}
				<span style={{ color: 'Lime' }}>
					<b>økende</b>
				</span>
				. De fleste av oss husker «plasthvalen» som ble funnet død med flere bæreposer av plast i
				magesekken. Mye av plasten havner i havet over hele verden og fraktes rundt med
				havstrømmene. Mye ender opp i strandsonen overalt i verden.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Mange skoler deltar i strandrydding og plukking av søppel, spesielt om våren. Elevene gjør
				en veldig viktig jobb når de rydder i naturen.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Norge er ett av få land som har{' '}
				<span style={{ color: 'Lime' }}>
					<b>panteordning</b>
				</span>{' '}
				for plastflasker og brusbokser.
			</p>,

			<br></br>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					De siste årene har fokuset på plast i havet vært{' '}
					<span style={{ color: 'Lime' }}>
						<b>økende</b>
					</span>
					. De fleste av oss husker «plasthvalen» som ble funnet død med flere bæreposer
					av plast og andre plastgjenstander i magesekken. Mange sjødyr, fugler og andre
					dyr i naturen dør på grunn av søppelet vi mennesker{' '}
					<span style={{ color: 'Lime' }}>
						<b>etterlater</b>
					</span>{' '}
					oss. Det tar mange hundre år før plast{' '}
					<span style={{ color: 'Lime' }}>
						<b>brytes ned</b>
					</span>{' '}
					og forsvinner. Mye av plasten havner i havet over hele verden og fraktes rundt
					med havstrømmene. Mye ender opp i strandsonen overalt i verden , og det er
					viktig å få ryddet vekk dette.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Mange skoler deltar i strandrydding og plukking av søppel, spesielt om våren.
					Elevene gjør en veldig viktig jobb når de rydder i naturen.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Norge er ett av få land som har{' '}
					<span style={{ color: 'Lime' }}>
						<b>panteordning</b>
					</span>{' '}
					for plastflasker og brusbokser. Dette er en god idè for å bevare naturen og
					unngå at plasten havner de den ikke skal være. Mange mener at flaskepanting også
					forurenser naturen, da det kjører mange lastebiler rundt om i landet vårt med
					tomflasker på lasteplanet.
				</p>
				<br></br>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvem er «plasthvalen»?',
			'2. Hvor lang tid tar det før plasten brytes ned?',
			'3. Hvordan fraktes plasten i havet rundt?',
			'4. Hva synes du om at vi panter flasker?',
			'5. Hva kan dere gjøre for at det skal bli mindre plast i naturen?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Økende', '- Etterlate', '- Brytes ned', '- Panteordning'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>vel-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Velbrukt, velutstyrt, velkjent, velfortjent<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som begynner på <b>vel-?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session89 = [
	{
		introAudio: mainAudio_89,
		questionAudio: questions_89,
		wordsAudio: words_89,
		mainHeading: '1. Dagens tekst',
		mainImg: session89Img,
		subHeading: 'Forurensning del 2',
		phonemeData: phoneme89,
		DaysTextWords: wordsInTheEnd89[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'Forurensning er når naturen, vannet eller luften blir skitten. ',
			'Det kan gjøre oss syke. Det kan gjøre planter og dyr syke.',
			'Forurensning er det stort sett mennesker som sørger for. ',
			'En vulkan kan fylle luften med røyk og støv.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				Forurensning er når naturen, vannet eller luften blir skitten. Det kan gjøre oss syke. Det
				kan gjøre planter og dyr syke.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Forurensning er det stort sett mennesker som sørger for. Det finnes naturlig forurensning
				også. En vulkan kan fylle luften med røyk og støv.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Forurensning er ikke bra. Forurensning kan være søppel på bakken og i vannet. Mye søppel
				på bakken kan lukte ekkelt. Søppel kan også{' '}
				<span style={{ color: 'Lime' }}>
					<b>spre</b>
				</span>{' '}
				bakterier.
			</p>,
			<p style={{ fontSize: '22px' }}>
				Plast i havet kan fange dyrene. Det gjør det vanskelig for dem å svømme. De klarer ikke å
				finne mat.
			</p>,

			<br></br>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Forurensning er når noe skadelig kommer i naturen, vannet eller i luften.
				</p>

				<p style={{ fontSize: '22px' }}>
					Nesten all forurensning er menneskeskapte. Forurensning kan også komme fra
					naturen. Det kan være et vulkanutbrudd som{' '}
					<span style={{ color: 'Lime' }}>
						<b>sprer</b>
					</span>{' '}
					aske og røyk.
				</p>

				<p style={{ fontSize: '22px' }}>Forurensning kan gjøre planter, dyr og mennesker syke.</p>
				<p style={{ fontSize: '22px' }}>Det kan også ødelegge steder der dyrene bor.</p>
				<p style={{ fontSize: '22px' }}>
					<br></br>
					Mye forurensning kommer fra søppel på bakken og i vannet. Plast forurenser mye i
					naturen. Det er fordi plasten bruker lang tid på å bli nedbrutt. Plast i vannet
					kan også fange og kvele dyr.
				</p>
				<p style={{ fontSize: '22px' }}>
					Røyk, støv og skadelige gasser forurenser luften. Dette kan komme fra branner,
					kjøretøy og fabrikker.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Vi kan stoppe forurensningen ved å{' '}
					<span style={{ color: 'Lime' }}>
						<b>redusere</b>
					</span>
					, gjenbruke og{' '}
					<span style={{ color: 'Lime' }}>
						<b>resirkulere</b>
					</span>
					.
				</p>
				<p style={{ fontSize: '22px' }}>
					Å{' '}
					<span style={{ color: 'Lime' }}>
						<b>redusere</b>
					</span>{' '}
					<span style={{ color: 'Lime' }}>
						<b>forbruket</b>
					</span>{' '}
					betyr å lage mindre søppel. Papirservietter kastes etter bruk, mens
					tøyservietter kan vaskes etter bruk.
				</p>
				<p style={{ fontSize: '22px' }}>
					Noen ting som vi kaster kan vi bruke på nye måter. For eksempel kan et tomt
					syltetøyglass brukes som blyantholder.
				</p>
				<p style={{ fontSize: '22px' }}>
					Resirkulering betyr å lage noe nytt av gamle ting. For eksempel kan gamle aviser
					brukes til å produsere bursdagskort eller tørkepapir.
				</p>
				<br></br>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er forurensning?',
			'2. Hvor kommer forurensningen fra?',
			'3. Hvorfor vil vi ikke ha forurensning?',
			'4. Hva kan vi gjøre for å forurense mindre?',
			'5. Hva gjør du for at det skal bli mindre forurensning?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Spre', '- Forbruk', '- Redusere', '- Resirkulere'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-sel</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Fengsel, diesel, hørsel, deksel<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>-sel?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session90 = [
	{
		introAudio: mainAudio_90,
		questionAudio: questions_90,
		wordsAudio: words_90,
		mainHeading: '1. Dagens tekst',
		mainImg: session90Img,
		subHeading: 'Nise',
		phonemeData: phoneme90,
		DaysTextWords: wordsInTheEnd90[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'Nisen er en liten tannhval.',
			'Niser blir ofte forvekslet med delfiner fordi de ser ganske lik',
			'De fleste niser lever i små grupper på to til fire medlemmer.',
			'Nisen har et variert kosthold av fisk, akkar og ulike krepsdyr.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>
				Nisen er en liten tannhval. Niser blir ofte{' '}
				<span style={{ color: 'Lime' }}>
					<b>forvekslet</b>
				</span>{' '}
				med delfiner fordi de ser ganske like ut.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				Selv om den ser ut som en fisk så er nisen et pattedyr. De puster gjennom et spesielt
				nesebor på hodet som kalles blåsehull.
			</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>De fleste niser lever i små grupper på to til fire medlemmer.</p>,
			<p style={{ fontSize: '22px' }}>
				Nisen har et{' '}
				<span style={{ color: 'Lime' }}>
					<b>variert</b>
				</span>{' '}
				kosthold av fisk, akkar og ulike krepsdyr.
			</p>,

			<p style={{ fontSize: '22px' }}>
				Mange niser blir drept når de ved et uhell blir fanget i fiskegarn. De blir også jaktet på
				av større sjødyr som hai og spekkhogger.
			</p>,

			<br></br>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Nisen er en liten tannhval. Niser blir ofte{' '}
					<span style={{ color: 'Lime' }}>
						<b>forvekslet</b>
					</span>{' '}
					med delfiner fordi de ser ganske like ut. Delfinen har spiss nese mens nisen har
					avrundet nese.
				</p>
				<p style={{ fontSize: '22px' }}>
					Selv om den ser ut som en fisk så er nisen et pattedyr. De puster gjennom et
					spesielt nesebor på hodet som kalles blåsehull. Hvis de ikke kan komme til
					overflaten for luft vil de kveles. Nisen er mindre enn delfinen og blir ikke mer
					enn to meter lang. De fleste niser lever i sjøen.
				</p>
				<p style={{ fontSize: '22px' }}>
					De fleste niser lever i små grupper på to til fire medlemmer.
				</p>
				<p style={{ fontSize: '22px' }}>
					Nisen har et{' '}
					<span style={{ color: 'Lime' }}>
						<b>variert</b>
					</span>{' '}
					kosthold av fisk, akkar og ulike krepsdyr som reker og krabber.
				</p>
				<p style={{ fontSize: '22px' }}>
					<br></br>
					Akkurat som mange andre sjødyr bruker også nisen
					<span style={{ color: 'Lime' }}>
						<b>ekko</b>
					</span>
					lokalisering når de jakter. De lager en lyd og så lytter de etter{' '}
					<span style={{ color: 'Lime' }}>
						<b>ekkoet</b>
					</span>{' '}
					for å finne byttet.
				</p>
				<p style={{ fontSize: '22px' }}>
					Nisen lever 10-20 år i det fri. Mange niser blir drept når de ved et uhell blir
					fanget i fiskegarn. De blir også jaktet på av større sjødyr som hai og
					spekkhogger.
				</p>
				<br></br>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva er en nise?',
			'2. Hva skjer hvis nisen ikke kan komme til overflaten for å få luft?',
			'3. Hva er ekkolokalisering?',
			'4. Hva spiser nisen?',
			'5. Hvordan kan du se forskjell på en nise og en delfin?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Forveksle', '- Variert', '- Ekko', '- Lokalisere'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>Unn-</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Unntak, unnskyldning, unndra, unndra<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som begynner på <b>Unn ?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session91 = [
	{
		introAudio: mainAudio_91,
		questionAudio: questions_91,
		wordsAudio: words_91,
		mainHeading: '1. Dagens tekst',
		mainImg: session91Img,
		subHeading: 'Spekkhogger',
		phonemeData: phoneme91,
		DaysTextWords: wordsInTheEnd91[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'Spekkhoggeren er et pattedyr.',
			'De jakter på fisk, hvalross, pingvin, hai og andre hvaler.',
			'En spekkhogger har sterke og skarpe tenner.',
			'Spekkhoggeren er enorm.',
			<p>
				Spekkhoggeren kan holde pusten i{' '}
				<span style={{ color: 'Lime' }}>
					<b>opptil</b>
				</span>{' '}
				15 minutter.
			</p>,
		],
		summary: [
			<p style={{ fontSize: '22px' }}>Spekkhoggeren er et pattedyr.</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>De jakter på fisk, hvalross, pingvin, hai og andre hvaler.</p>,
			<br></br>,
			<p style={{ fontSize: '22px' }}>
				En spekkhogger har sterke og skarpe tenner. De bruker tennene til å drepe og rive i
				stykker{' '}
				<span style={{ color: 'Lime' }}>
					<b>byttet</b>
				</span>
				.
			</p>,
			<p style={{ fontSize: '22px' }}>
				Spekkhoggeren er{' '}
				<span style={{ color: 'Lime' }}>
					<b>enorm</b>
				</span>
				. De kan veie opptil seks tonn og kan bli ti meter lang. For å kunne holde vekten sin så
				må spekkhoggeren spise opptil 227 kilo mat hver dag.
			</p>,

			<p style={{ fontSize: '22px' }}>
				Spekkhoggeren bruker ekkolokalisering når de jakter. De lager en lyd og så lytter de etter
				ekkoet for å finne{' '}
				<span style={{ color: 'Lime' }}>
					<b>byttet</b>
				</span>
				.
			</p>,
			<p style={{ fontSize: '22px' }}>Spekkhoggeren kan holde pusten i opptil 15 minutter.</p>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Spekkhoggerne er de største medlemmene i delfinfamilien. Spekkhoggeren er et
					pattedyr.
				</p>
				<p style={{ fontSize: '22px' }}>
					De jakter på fisk, hvalross, pingvin, hai og andre hvaler.
				</p>
				<p style={{ fontSize: '22px' }}>
					En spekkhogger har sterke og skarpe tenner som kan bli opptil 7,5 cm lange. De
					bruker tennene til å drepe og rive i stykker{' '}
					<span style={{ color: 'Lime' }}>
						<b>byttet</b>
					</span>
					, siden de vanligvis svelger maten hel.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Spekkhoggeren er egentlig den eneste fienden hvithaien har i naturen.
					Spekkhoggeren blir noen ganger kalt «ulven i havet».
				</p>
				<p style={{ fontSize: '22px' }}>
					Spekkhoggeren er{' '}
					<span style={{ color: 'Lime' }}>
						<b>enorm</b>
					</span>
					. De kan veie opptil seks tonn og kan bli ti meter lang. For å kunne holde
					vekten sin så må spekkhoggeren spise opptil 227 kilo mat hver dag.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Spekkhoggeren lever i grupper. De kommuniserer med klikk- lignende lyde og rop.
					Hver gruppe har sin egen spesielle lyd.
				</p>

				<p style={{ fontSize: '22px' }}>
					Spekkhoggeren bruker ekkolokalisering når de jakter. De lager en lyd og så
					lytter de etter ekkoet for å finne{' '}
					<span style={{ color: 'Lime' }}>
						<b>byttet</b>
					</span>
					.
				</p>
				<p style={{ fontSize: '22px' }}>
					Spekkhoggeren kan holde pusten i opptil 15 minutter. Til vanlig kommer de opp
					til{' '}
					<span style={{ color: 'Lime' }}>
						<b>overflaten</b>
					</span>{' '}
					<span style={{ color: 'Lime' }}>
						<b>hyppigere</b>
					</span>{' '}
					enn det for å puste gjennom blåsehullet sitt.
				</p>
				<br></br>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva jakter spekkhoggere på?',
			'2. Hva blir spekkhoggeren kalt?',
			'3. Hvordan finner spekkhoggeren byttet når de jakter?',
			'4. Hvor lenge kan spekkhoggere holde pusten?',
			'5. Hvor lenge tror du dere kan holde pusten?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Enorm', '- Byttet', '- Overflaten', '- Hyppigere'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>-eri</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Bakeri, maleri, bedrageri, raseri<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>-eri ?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];
export const session92 = [
	{
		introAudio: mainAudio_92,
		questionAudio: questions_92,
		wordsAudio: words_92,
		mainHeading: '1. Dagens tekst',
		mainImg: session92Img,
		subHeading: 'Delfiner',
		phonemeData: phoneme92,
		DaysTextWords: wordsInTheEnd92[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			'Delfiner er pattedyr.',
			'De lever i sjøer og hav rundt om i verden.',
			'Delfiner er rovdyr.',
			'De får maten ved å spise andre dyr.',
			'Delfiner er smarte og lekne.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>Delfiner er pattedyr. </p>,
			<p style={{ fontSize: '22px' }}>
				De{' '}
				<span style={{ color: 'Lime' }}>
					<b>tilbringer</b>
				</span>{' '}
				hele livet i vannet.
			</p>,
			<p style={{ fontSize: '22px' }}>De lever i sjøer og hav rundt om i verden. Noen bor i elver.</p>,
			<p style={{ fontSize: '22px' }}>Spekkhoggere er de største delfinene.</p>,
			<p style={{ fontSize: '22px' }}>De kan bli mer enn ni meter lange.</p>,
			<p style={{ fontSize: '22px' }}>Delfiner er rovdyr. </p>,
			<p style={{ fontSize: '22px' }}>De får maten ved å spise andre dyr. </p>,
			<p style={{ fontSize: '22px' }}>De spiser mest fisk og blekksprut. </p>,
			<p style={{ fontSize: '22px' }}>Delfiner bruker ekkolokalisering for å finne byttedyr. </p>,
			<p style={{ fontSize: '22px' }}>Dette ekkoet hjelper delfinene å vite hvor byttet er. </p>,
			<p style={{ fontSize: '22px' }}>Delfiner kommer opp til vannoverflaten for å puste inn luft. </p>,
			<p style={{ fontSize: '22px' }}>De puster gjennom et blåsehull på toppen av hodet. </p>,
			<p style={{ fontSize: '22px' }}>
				Delfiner er smarte,{' '}
				<span style={{ color: 'Lime' }}>
					{' '}
					<b>sosiale</b>
				</span>{' '}
				og lekne.
			</p>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Delfiner er pattedyr. De{' '}
					<span style={{ color: 'Lime' }}>
						<b>tilbringer</b>
					</span>{' '}
					hele livet i vannet. De lever i sjøer og hav rundt om i verden. Noen bor i
					elver.
				</p>
				<p style={{ fontSize: '22px' }}>
					Spekkhoggere er de største delfinene. De kan bli mer enn ni meter lange.
					Mauidelfiner er de minste. De blir bare 1,7 meter lange. De fleste delfiner har
					en{' '}
					<span style={{ color: 'Lime' }}>
						{' '}
						<b>fargeblanding</b>
					</span>{' '}
					av svart, hvitt og grått. Noen elvedelfiner er rosa.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Delfiner er rovdyr. De får maten ved å spise andre dyr. De spiser mest fisk og
					blekksprut. Delfiner bruker ekkolokalisering for å finne byttedyr. Dette ekkoet
					hjelper delfinene å vite hvor byttet er.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					De kommer opp til vannoverflaten for å puste inn luft. De puster gjennom et
					blåsehull på toppen av hodet. Delfiner bruker klikk, fløyter og gråt for å
					<span style={{ color: 'Lime' }}>
						{' '}
						<b>kommunisere</b>
					</span>{' '}
					med hverandre. De kan kjenne igjen hverandres stemmer. Delfiner er smarte,
					<span style={{ color: 'Lime' }}>
						{' '}
						<b>sosiale</b>
					</span>{' '}
					og lekne. De lever og jakter i grupper. Noen delfiner dør når de ved et uhell
					blir fanget i fiskegarn.
				</p>
				<br></br>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hva betyr det at delfiner er pattedyr?',
			'2. Hva betyr det at delfiner er rovdyr?',
			'3. Hvilke farger kan delfiner ha?',
			'4. Hva betyr det at delfiner er sosiale og lekne?',
			'5. Noen delfiner dør ved et uhell, hvordan skjer det?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Tilbringer', '- Kommunisere', '- Sosiale', '- Fargeblanding'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens forstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>til</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Tilbringe, tilstå, tilføre, tilhenger<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som begynner på <b>til ?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];

export const session93 = [
	{
		introAudio: mainAudio_93,
		questionAudio: questions_93,
		wordsAudio: words_93,
		mainHeading: '1. Dagens tekst',
		mainImg: session93Img,
		subHeading: 'Blekksprut',
		phonemeData: phoneme93,
		DaysTextWords: wordsInTheEnd93[0].revisionWordsAndInstruction.words, //chage it to relevant
		easySummary: [
			<p>
				'Blekkspruter er veldig{' '}
				<span style={{ color: 'Lime' }}>
					{' '}
					<b>smarte</b>
				</span>
				.
			</p>,
			'De har store øyne og godt syn.',
			'Blekkspruter kan svømme veldig fort.',
			'Alle blekkspruter har gift når de biter noe.',
			'Blekkspruter kalles noen ganger blekkfisk.',
		],
		summary: [
			<p style={{ fontSize: '22px' }}>Blekkspruter er marine bløtdyr. </p>,
			<p style={{ fontSize: '22px' }}>Blekksprutene kan ha mange forskjellige farger. </p>,
			<p style={{ fontSize: '22px' }}>De lever i sjøer og hav over hele verden. </p>,
			<p style={{ fontSize: '22px' }}>Blekkspruter har tre hjerter. </p>,
			<p style={{ fontSize: '22px' }}>
				De har store hjerner og er veldig{' '}
				<span style={{ color: 'Lime' }}>
					{' '}
					<b>smarte</b>
				</span>
				.{' '}
			</p>,
			<p style={{ fontSize: '22px' }}>De har store øyne og veldig godt syn.</p>,
			<p style={{ fontSize: '22px' }}>
				Blekkspruter er virvelløse dyr. Det betyr at de ikke har ryggrad.{' '}
			</p>,
			<p style={{ fontSize: '22px' }}>
				De kan presse sine myke kropper inn i små rom for å rømme fra rovdyr.{' '}
			</p>,
			<p style={{ fontSize: '22px' }}>Blekkspruter kan svømme veldig fort. </p>,
			<p style={{ fontSize: '22px' }}>Blekkspruter er rovdyr. De spiser andre dyr.</p>,
			<p style={{ fontSize: '22px' }}>
				Alle blekkspruter{' '}
				<span style={{ color: 'Lime' }}>
					{' '}
					<b>frigjør</b>
				</span>{' '}
				gift når de biter noe.{' '}
			</p>,
			<p style={{ fontSize: '22px' }}>Blekkspruter kalles noen ganger blekkfisk.</p>,
		],

		para: (
			<span>
				<p style={{ fontSize: '22px' }}>
					Blekkspruter er marine bløtdyr. De er i slekt med snegler, blåskjell og andre
					marine bløtdyr.
				</p>
				<p style={{ fontSize: '22px' }}>
					Blekksprutene kan ha mange forskjellige farger. De lever i sjøer og hav over
					hele verden. Blekkspruter har tre hjerter. De har
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					store hjerner og er veldig{' '}
					<span style={{ color: 'Lime' }}>
						{' '}
						<b>smarte</b>
					</span>
					. De har store øyne og veldig godt syn.
				</p>
				<br></br>
				<p style={{ fontSize: '22px' }}>
					Blekkspruter er virvelløse dyr. Det betyr at de ikke har ryggrad. De kan presse
					sine myke kropper inn i små rom for å rømme fra rovdyr.
				</p>
				<p style={{ fontSize: '22px' }}>
					Blekkspruter kan svømme veldig fort. De skyter vannstråler ut på baksiden av
					deres poselignende kropper og{' '}
					<span style={{ color: 'Lime' }}>
						{' '}
						<b>kraften</b>
					</span>{' '}
					hjelper til med å presse dem fremover.
				</p>
				<br />
				<p style={{ fontSize: '22px' }}>Blekkspruter er rovdyr. De spiser andre dyr.</p>
				<p style={{ fontSize: '22px' }}>
					Alle blekkspruter{' '}
					<span style={{ color: 'Lime' }}>
						{' '}
						<b>frigjør</b>
					</span>{' '}
					gift når de biter noe. Det bedøver byttet deres slik at det ikke kan{' '}
					<span style={{ color: 'Lime' }}>
						{' '}
						<b>unnslippe</b>
					</span>
					.
				</p>
				<p style={{ fontSize: '22px' }}>
					Blekkspruter slipper ut en blekkaktig væske i vannet for å
					<span style={{ color: 'Lime' }}>
						{' '}
						<b>skjule</b>
					</span>{' '}
					seg. Det er derfor de noen ganger kalles blekkfisk.
				</p>
				<br></br>
			</span>
		),

		repeatParaHeading: (
			<span>
				<span>
					2. Narrativ.
					<span className='rw-extra-text'>
						{' '}
						Elevene gjenforteller teksten og handlingen med oppfølgingsspørsmål
						fra lærer.
					</span>
				</span>
			</span>
		),
		helpingQuestionHeading: 'Hva leste du nå?',
		helpingQuestions: [
			'1. Hvem er blekkspruter i slekt med?',
			'2. Hva betyr det at blekkspruter er virvelløse dyr?',
			'3. Hva gjør blekkspruter for å svømme fort?',
			'4. Hvordan kan blekkspruter bedøve byttet sitt?',
			'5. Hvorfor kalles blekkspruter for blekkfisk?',
		],

		revisionWordsAndInstruction: {
			revisionParaHeading: (
				<span>
					<span>
						3. Vokabular.
						<span className='rw-extra-text'>
							{' '}
							Gjennomgang av sentrale ord i teksten
						</span>
						<br />
					</span>
				</span>
			),

			words: ['- Smart', '- Kraften', '- Frigjør', '- Unnslippe', '- Skjule'],
			revisionParaData: (
				<span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>a</b>
						</span>
						. Ordenes betydning. Er noen av ordene sammensatt av to eller flere
						ord? I så fall, hvilke ord består det av?
					</span>
					<br></br>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>b</b>
						</span>
						. Hvordan bøyes disse ordene?
						<br></br>
					</span>
					<span className='rw-extra-text'>
						{' '}
						<span style={{ color: 'yellow' }}>
							<b>c</b>
						</span>
						. Dagens etterstavelse:{' '}
						<span style={{ color: 'yellow' }}>
							<b>
								<i>løse</i>
							</b>
							<br></br>
							<br></br>
						</span>
					</span>
					Tilbringe, tilstå, tilføre, tilhenger<br></br>
					<span className='rw-extra-text'>
						<li>
							Kan du gjette hva disse ordene betyr, selv om du ikke har
							hørt dem før?
						</li>
						<li>
							Kan du komme på noen flere ord som slutter på <b>løse ?</b>
						</li>
					</span>
					<br></br>
				</span>
			),
		},
	},
];
