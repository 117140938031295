import React from 'react';
import './styles.scss';
import * as data from './data/no-data';
import { SUBTEST_IDS, ALL_SUBTESTS } from '../../../../constants/index';

const getPracticeData = (session) => {
	let practiceData;
	const indexValue = `session${session}`;
	practiceData = data[indexValue];
	return practiceData;
};
var counter = 0;
const resultsGloabl = [];
class PhonemeAwareness extends React.Component {
	constructor(props) {
		super(props);
		this.practiceData = getPracticeData(this.props.selectedSession);
		this.state = { currentCounter: counter, rendernext: false };
	}

	render() {
		const { currentCounter } = this.state;
		const { onNextStep } = this.props;
		return (
			<div>
				<div className='rw-intro-text'>
					<p>Hva er det nye ordet?</p>
				</div>

				<div className='rw-paw'>
					{this.practiceData.map((data, sessionIndex) => {
						const { precedingText, precedingWord, leadingText, leadingWord } =
							data;
						if (sessionIndex - 1 < currentCounter) {
							return (
								<div className='rw-pw-content-wrapper'>
									<div
										className='rw-pw-content'
										id={sessionIndex}
									>
										<div className='rw-wp-content__question'>
											<p
												className={
													counter ===
													sessionIndex
														? 'rw-wp-currentLine'
														: 'rw-wp-previousLine'
												}
											>
												<span>
													{
														precedingText
													}
												</span>

												{
													precedingWord
												}

												<span>
													{
														leadingText
													}
												</span>
												<span className='rw-pw-leadingword'>
													{
														leadingWord
													}
												</span>
												<span>
													{
														' ?'
													}
												</span>
												{counter ===
													sessionIndex && (
													<span className='pw-right-arrow-wrapper'>
														<button
															className='pw-right-arrow-phen'
															onClick={() => {
																if (
																	currentCounter <
																	this
																		.practiceData
																		.length -
																		1
																) {
																	counter =
																		counter +
																		1;
																	this.setState(
																		{
																			currentCounter:
																				counter,
																		}
																	);
																} else {
																	counter =
																		counter +
																		1;
																	this.setState(
																		{
																			currentCounter:
																				counter,
																			renderNext: true,
																		}
																	);
																}
															}}
														></button>
													</span>
												)}
											</p>
										</div>
									</div>
								</div>
							);
						}
					})}
				</div>
				<br></br>

				<div className='rw-next-btn'>
					<button
						className='rw-btn bg-primary'
						onClick={() => onNextStep('', { isAvoid: true })}
					>
						{'Neste'}
					</button>
				</div>
			</div>
		);
	}
}

export default PhonemeAwareness;
