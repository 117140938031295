export const allSessionData = [
	{ name: '0', disabled: 'false', id: 1 },
	{ name: '01', disabled: 'false', id: 2 },
	{ name: '02', disabled: 'false', id: 3 },
	{ name: '03', disabled: 'false', id: 4 },
	{ name: '04', disabled: 'false', id: 5 },
	{ name: '05', disabled: 'false', id: 6 },
	{ name: '06', disabled: 'false', id: 7 },
	{ name: '07', disabled: 'false', id: 8 },
	{ name: '08', disabled: 'false', id: 9 },
	{ name: '09', disabled: 'false', id: 10 },
	{ name: '10', disabled: 'false', id: 11 },
	{ name: '11', disabled: 'false', id: 12 },
	{ name: '12', disabled: 'false', id: 13 },
	{ name: '13', disabled: 'false', id: 14 },
	{ name: '14', disabled: 'false', id: 15 },
	{ name: '15', disabled: 'false', id: 16 },
	{ name: '16', disabled: 'false', id: 17 },
	{ name: '17', disabled: 'false', id: 18 },
	{ name: '18', disabled: 'false', id: 19 },
	{ name: '19', disabled: 'false', id: 20 },
	{ name: '20', disabled: 'false', id: 21 },
	{ name: '21', disabled: 'false', id: 22 },
	{ name: '22', disabled: 'false', id: 23 },
	{ name: '23', disabled: 'false', id: 24 },
	{ name: '24', disabled: 'false', id: 25 },
	{ name: '25', disabled: 'false', id: 26 },
	{ name: '26', disabled: 'false', id: 27 },
	{ name: '27', disabled: 'false', id: 28 },
	{ name: '28', disabled: 'false', id: 29 },
	{ name: '29', disabled: 'false', id: 30 },
	{ name: '30', disabled: 'false', id: 31 },
	{ name: '31', disabled: 'false', id: 32 },
	{ name: '32', disabled: 'false', id: 33 },
	{ name: '33', disabled: 'false', id: 34 },
	{ name: '34', disabled: 'false', id: 35 },
	{ name: '35', disabled: 'false', id: 36 },
	{ name: '36', disabled: 'false', id: 37 },
	{ name: '37', disabled: 'false', id: 38 },
	{ name: '38', disabled: 'false', id: 39 },
	{ name: '39', disabled: 'false', id: 40 },
	{ name: '40', disabled: 'false', id: 41 },
	{ name: '41', disabled: 'false', id: 42 },
	{ name: '42', disabled: 'false', id: 43 },
	{ name: '43', disabled: 'false', id: 44 },
	{ name: '44', disabled: 'false', id: 45 },
	{ name: '45', disabled: 'false', id: 46 },
	{ name: '46', disabled: 'false', id: 47 },
	{ name: '47', disabled: 'false', id: 48 },
	{ name: '48', disabled: 'false', id: 49 },
	{ name: '49', disabled: 'false', id: 50 },
	{ name: '50', disabled: 'false', id: 51 },
	{ name: '51', disabled: 'false', id: 52 },
	{ name: '52', disabled: 'false', id: 53 },
	{ name: '53', disabled: 'false', id: 54 },
	{ name: '54', disabled: 'false', id: 55 },
	{ name: '55', disabled: 'false', id: 56 },
	{ name: '56', disabled: 'false', id: 57 },
	{ name: '57', disabled: 'false', id: 58 },
	{ name: '58', disabled: 'false', id: 59 },
	{ name: '59', disabled: 'false', id: 60 },
	{ name: '60', disabled: 'false', id: 61 },
	{ name: '61', disabled: 'false', id: 62 },
	{ name: '62', disabled: 'false', id: 63 },
	{ name: '63', disabled: 'false', id: 64 },
	{ name: '64', disabled: 'false', id: 65 },
	{ name: '65', disabled: 'false', id: 66 },
	{ name: '66', disabled: 'false', id: 67 },
	{ name: '67', disabled: 'false', id: 68 },
	{ name: '68', disabled: 'false', id: 69 },
	{ name: '69', disabled: 'false', id: 70 },
	{ name: '70', disabled: 'false', id: 71 },
	{ name: '71', disabled: 'false', id: 72 },
	{ name: '72', disabled: 'false', id: 73 },
	{ name: '73', disabled: 'false', id: 74 },
	{ name: '74', disabled: 'false', id: 75 },
	{ name: '75', disabled: 'false', id: 76 },

	{ name: '76', disabled: 'false', id: 77 },
	{ name: '77', disabled: 'false', id: 78 },
	{ name: '78', disabled: 'false', id: 79 },
	{ name: '79', disabled: 'false', id: 80 },
	{ name: '80', disabled: 'false', id: 81 },
	{ name: '81', disabled: 'false', id: 82 },
	{ name: '82', disabled: 'false', id: 83 },
	{ name: '83', disabled: 'false', id: 84 },
	{ name: '84', disabled: 'false', id: 85 },
	{ name: '85', disabled: 'false', id: 86 },
	{ name: '86', disabled: 'false', id: 87 },
	{ name: '87', disabled: 'false', id: 88 },
	{ name: '88', disabled: 'false', id: 89 },
	{ name: '89', disabled: 'false', id: 90 },
	{ name: '90', disabled: 'false', id: 91 },
	{ name: '91', disabled: 'false', id: 92 },
	{ name: '92', disabled: 'false', id: 93 },
	{ name: '93', disabled: 'false', id: 94 },
	{ name: '94', disabled: 'false', id: 95 },
	{ name: '95', disabled: 'false', id: 96 },
];
