export const session52 = [
	{
		id: 1,
		text: 'folkbedrebålskinn',
		answer: 'folk bedre bål skinn',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'leirelagemyestein',
		answer: 'leire lage mye stein',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'årslikvarveldig',
		answer: 'år slik var veldig',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'matsnøsluttviktig',
		answer: 'mat snø slutt viktig',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session53 = [
	{
		id: 1,
		text: 'forlagevåpenog',
		answer: 'for lage våpen og',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'bronsevedtinndet',
		answer: 'bronse ved tinn det',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'formåttesteinvarme',
		answer: 'for måtte stein varme',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'hardtriktigoppbanket',
		answer: 'hardt riktig opp banket',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session54 = [
	{
		id: 1,
		text: 'bleavmatkamp',
		answer: 'ble av mat kamp',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'sigdkuttejaktdyr',
		answer: 'sigd kutte jakt dyr',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'plogtypedrojorden',
		answer: 'plog type dro jorden',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'bladpinnekuttesamle',
		answer: 'blad pinne kutte samle',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session55 = [
	{
		id: 1,
		text: 'komblemangelover',
		answer: 'Kom ble mange  lover',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'nedhistorieavskrev',
		answer: 'Ned historie av skrev',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'tidførsegslo',
		answer: 'tid før seg slo',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'ikkelevdenedstørre',
		answer: 'Ikke levde ned større',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session56 = [
	{
		id: 1,
		text: 'flereogmedbodde',
		answer: 'flere og med bodde',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'oftedejordenble',
		answer: 'ofte de jorden ble',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'slappdyrketforvilt',
		answer: 'slapp dyrket for vilt',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'sørbyttetklimavar',
		answer: 'sør byttet klima var',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session57 = [
	{
		id: 1,
		text: 'jernnårspikeropp',
		answer: 'jern når spiker opp',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'finnekrigpåslår',
		answer: 'finne krig på slår',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'andretingdyrvar',
		answer: 'andre ting dyr var',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'brødavlageble',
		answer: 'brød av lage ble',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session58 = [
	{
		id: 1,
		text: 'skipvarsmaletil',
		answer: 'skip var smale til',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'langeognyeland',
		answer: 'lange og nye land',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'seilsombønderog',
		answer: 'seil som bønder og',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'stjaltinghøytkrydder',
		answer: 'stjal ting høyt krydder',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session59 = [
	{
		id: 1,
		text: 'ørnfiskeerrede',
		answer: 'ørn fiske er rede',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'leggeeggpåsine',
		answer: 'legge egg på sine',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'godtsynuglerive',
		answer: 'godt syn ugle rive',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'artnebbkjøttraptor',
		answer: 'art nebb kjøtt raptor',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session60 = [
	{
		id: 1,
		text: 'kaninsomørerbor',
		answer: 'kanin som ører bor',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'graverforlagede',
		answer: 'graver for lage de',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'kallerdemkildemat',
		answer: 'kaller dem kilde mat',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'kaningrønnsakerspiserkan',
		answer: 'kanin grønnsaker spiser kan',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session61 = [
	{
		id: 1,
		text: 'leveravtypeklima',
		answer: 'lever av type klima',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'reinfellerårligtil',
		answer: 'rein feller årlig til',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'bukkergårgevirmot',
		answer: 'bukker går gevir mot',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'kalversimlekallesveie',
		answer: 'kalver simle kalles veie',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session62 = [
	{
		id: 1,
		text: 'Spiserhietselinne',
		answer: 'Spiser hiet sel inne',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'Størstlageunderden',
		answer: 'Størst lage under den',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'fødtjaktersnikergår',
		answer: 'født jakter sniker går',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'dvalesmelteangripeat',
		answer: 'dvale smelte angripe at',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session63 = [
	{
		id: 1,
		text: 'sørgerallenødjobb',
		answer: 'sørger alle nød jobb',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'politihjelpernårtror',
		answer: 'politi hjelper når tror',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'følgerfolksegtrygg',
		answer: 'følger folk seg trygg',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'Flesteuniformtryggpå',
		answer: 'Fleste uniform trygg på',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session64 = [
	{
		id: 1,
		text: 'slokkeklærstigehar',
		answer: 'slokke klær stige har',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'brannalletungeog',
		answer: 'brann alle tunge og',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'slangebrukertilogså',
		answer: 'slange bruker til også',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'jakkerhjelperbåtnår',
		answer: 'jakke hjelper båt når',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session65 = [
	{
		id: 1,
		text: 'frisørhårlagekan',
		answer: 'frisør hår lage kan',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'klippefargeskjeggtil',
		answer: 'klippe farge skjegg til',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'formersalongmennønsker',
		answer: 'former salong menn ønsker',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'barbererkoloristklipperog',
		answer: 'barberer kolorist klipper og',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session66 = [
	{
		id: 1,
		text: 'hjelpskaddegisko',
		answer: 'hjelp skadde gi sko',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'sprøytertilmedisinkan',
		answer: 'sprøyter til medisin kan',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'sykepleiergodefargerogså',
		answer: 'sykepleier gode farger også',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'barnskolerogtermometer',
		answer: 'barn skoler og termometer',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session67 = [
	{
		id: 1,
		text: 'veterinærdyrmyenoen',
		answer: 'veterinær dyr mye noen',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'jobbevedikkefinne',
		answer: 'jobbe ved ikke finne',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'friskegenhjelpehar',
		answer: 'frisk egen hjelp har',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'eierstuderehelseår',
		answer: 'eier studere helse år',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session68 = [
	{
		id: 1,
		text: 'kokkmenylageså',
		answer: 'kokk meny lage så',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'uniformtypeulikefinnes',
		answer: 'uniform type ulike finnes',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'ansvarhotellmatalle',
		answer: 'ansvar hotell mat alle',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'vispgryteserveringgår',
		answer: 'visp gryte servering går',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session69 = [
	{
		id: 1,
		text: 'rørvannvåtofte',
		answer: 'rør vann våt ofte',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'kloakkføreoverklær',
		answer: 'kloakk føre over klær',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'jobbegassskitnekobles',
		answer: 'jobb gass skitne kobles',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'brillerhanskertyperverktøy',
		answer: 'briller hansker typer verktøy',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session70 = [
	{
		id: 1,
		text: 'firmakjøreeierbiler',
		answer: 'firma kjøre eie biler',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'sjåførandrenoentyper',
		answer: 'sjåfør andre noen typer',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'leverlisensværegods',
		answer: 'lever lisens være gods',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'fraktelandjobbefor',
		answer: 'frakte land jobbe for',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session71 = [
	{
		id: 1,
		text: 'spillerfilmgåsom',
		answer: 'spiller film gå som',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'bruketeaterbaredu',
		answer: 'bruker teater bare du',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'deltastemmedetaudition',
		answer: 'delta stemme det audition',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'lagerdramarollenønsker',
		answer: 'lager drama rollen ønsker',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session72 = [
	{
		id: 1,
		text: 'pilotflymåha',
		answer: 'pilot fly må ha',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'førerlovpassepå',
		answer: 'fører lov passe på',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'passasjerfrakterogsåtrygg',
		answer: 'passasjer frakter også trygg',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'utstyrbagasjetimerkan',
		answer: 'utstyr bagasje timer kan',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session73 = [
	{
		id: 1,
		text: 'flyvingerdemkamp',
		answer: 'fly vinger dem kamp',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'erpilotfrakteflydd',
		answer: 'er pilot frakte flydd',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'vingerfastesykebruker',
		answer: 'vinger faste syke bruker',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'mangeparmenneskertil',
		answer: 'mange par mennesker til',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session74 = [
	{
		id: 1,
		text: 'motorveidethjelm',
		answer: 'motor vei det hjelm',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'hjulbrukeogkjøre',
		answer: 'hjul bruke og kjøre',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'morosporttilskyld',
		answer: 'moro sport til skyld',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'øvelsemåtrygtkrever',
		answer: 'øvelse må trygt krever',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session75 = [
	{
		id: 1,
		text: 'skipmindrevannmåter',
		answer: 'skip mindre vann måter',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'tremetallplastover',
		answer: 'tre metall plast over',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'flyttelagetfiskeseil',
		answer: 'flytte laget fiske seil',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'skrogdekklukestyrbord',
		answer: 'skrog dekk luke styrbord',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session76 = [
	{
		id: 1,
		text: 'deltrekkebilkan',
		answer: 'del trekke bil kan',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'kjørekallesvogneralene',
		answer: 'kjøre kalles vogner alene',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'taksidergulvfire',
		answer: 'tak sider gulv fire',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'tanktrommelfraktetype',
		answer: 'tank trommel frakte type',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session77 = [
	{
		id: 1,
		text: 'togmotorlastpå',
		answer: 'tog motor last på',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'skinnersporellertrekker',
		answer: 'skinner spor eller trekker',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'sengervognerseterkjører',
		answer: 'senger vogner seter kjører',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'stasjonbagasjeulikeåpne',
		answer: 'stasjon bagasje ulike åpne',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session78 = [
	{
		id: 1,
		text: 'fireeldrepersonstrøm',
		answer: 'fire eldre person strøm',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'noenordbensinautomobil',
		answer: 'noen ord bensin automobil',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'dieselhjulpersonførerkort',
		answer: 'diesel hjul person førerkort',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'beskyttesjåførulykkealternativ',
		answer: 'beskytte sjåfør ulykke alternativ',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session79 = [
	{
		id: 1,
		text: 'bussstortmangebarn',
		answer: 'buss stort mange barn',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'typermåhafinnes',
		answer: 'typer må ha finnes',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'spesieltseteskolekjøre',
		answer: 'spesielt sete skole kjøre',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'frakteretasjerfunksjonerpersoner',
		answer: 'frakter etasjer funksjoner personer',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session80 = [
	{
		id: 1,
		text: 'traktormyekraftkan',
		answer: 'traktor mye kraft kan',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'trekketregoftebrukes',
		answer: 'trekke treg ofte brukes',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'jobbergårdersetesjåfør',
		answer: 'jobber gårder sete sjåfør',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'såfrøhøyavling',
		answer: 'så frø høy avling',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session81 = [
	{
		id: 1,
		text: 'litensparkeværegang',
		answer: 'liten sparke være gang',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'reglermåbrukeshvor',
		answer: 'regler må brukes hvor',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'hjelmlandnoenmotor',
		answer: 'hjelm land noen motor',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'skuteregenmoroskyld',
		answer: 'skuter egen moro skyld',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session82 = [
	{
		id: 1,
		text: 'kjørevannvinterskuter',
		answer: 'kjøre vann vinter skuter',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'snøfolkreisevinter',
		answer: 'snø folk reise vinter',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'sportdresskuldevind',
		answer: 'sport dress kulde vind',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'retningbelteskimoro',
		answer: 'retning belte ski moro',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session83 = [
	{
		id: 1,
		text: 'somalleharnorsk',
		answer: 'som alle har norsk',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'flaggtogsyngeis',
		answer: 'flagg tog synge is',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'pølsespiseroftekake',
		answer: 'pølse spiser ofte kake',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'fokustalergodttrygt',
		answer: 'fokus taler godt trygt',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session84 = [
	{
		id: 1,
		text: 'førNorgenyeunder',
		answer: 'før Norge nye under',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'styrtsegoverselv',
		answer: 'styrt seg over selv',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'bleskullelageloven',
		answer: 'ble skulle lage loven',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'minnehilstOslohundre',
		answer: 'minne hilst Oslo hundre',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session85 = [
	{
		id: 1,
		text: 'vårtidenoppvinter',
		answer: 'vår tiden opp vinter',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'guldenlillapå',
		answer: 'gul den lilla på',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'nårlitttidenhesttehov',
		answer: 'når litt tiden hestehov',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'vokseroftemedisinfeber',
		answer: 'vokser ofte medisin feber',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];

export const session86 = [
	{
		id: 1,
		text: 'førstsomharhumle',
		answer: 'først som har humle',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'hjelpestedbollager',
		answer: 'hjelpe sted bol lager',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'reddeplantergide',
		answer: 'redde planter gi de',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'våknemarsbiervår',
		answer: 'våkne mars bier vår',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session87 = [
	{
		id: 1,
		text: 'insektmatborfrakte',
		answer: 'insekt mat bor frakte',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'fruktplantermaurfrø',
		answer: 'frukt planter maur frø',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'pollenbierjordenvokser',
		answer: 'pollen bier jorden vokser',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'endrehvermillionersymbiotisk',
		answer: 'endre hver millioner symbiotisk',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session88 = [
	{
		id: 1,
		text: 'plastmyeverdenav',
		answer: 'plast mye verden av',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'havetmedskolerrundt',
		answer: 'havet med skoler rundt',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'enderplukketnaturenjobb',
		answer: 'ender plukket naturen jobb',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'brytesdødfunnetelever',
		answer: 'brytes død funnet elever',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session89 = [
	{
		id: 1,
		text: 'luftensykvannoss',
		answer: 'luften syk vann oss',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'fyllerøykstøvkan',
		answer: 'fylle røyk støv kan',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'vulkansørgermenneskerog',
		answer: 'vulkan sørger mennesker og',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'skittenstøvfangedyr',
		answer: 'skitten støv fange dyr',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session90 = [
	{
		id: 1,
		text: 'litenniseserut',
		answer: 'liten nise ser ut',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'likedelfinfirefisk',
		answer: 'like delfin fire fisk',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'togrupperharvariert',
		answer: 'to grupper har variert',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'kallesakkarulikepuster',
		answer: 'kalles akkar ulike puster',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session91 = [
	{
		id: 1,
		text: 'haihvaltennerhar',
		answer: 'hai hval tenner har',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'pusteersterkkan',
		answer: 'puste er sterk kan',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'leverdreperivesvelge',
		answer: 'lever drepe rive svelge',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'enormholdejakterkilo',
		answer: 'enorm holde jakter kilo',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session92 = [
	{
		id: 1,
		text: 'sjøhavandredyr',
		answer: 'sjø hav andre dyr',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'fårmaterspiser',
		answer: 'får mat er spiser',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'smartvedleknedelfin',
		answer: 'smart ved lekne delfin',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'borfiskhodettoppen',
		answer: 'bor fisk hodet toppen',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session93 = [
	{
		id: 1,
		text: 'synharkanøyne',
		answer: 'syn har kan øyne',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: 'nårnoebitekalles',
		answer: 'når noe bite kalles',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: 'denårfortgodt',
		answer: 'de når fort godt',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: 'gifthartrealle',
		answer: 'gift har tre alle',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session94 = [
	{
		id: 1,
		text: '',
		answer: '',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: '',
		answer: '',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: '',
		answer: '',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: '',
		answer: '',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session95 = [
	{
		id: 1,
		text: '',
		answer: '',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: '',
		answer: '',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: '',
		answer: '',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: '',
		answer: '',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
export const session96 = [
	{
		id: 1,
		text: '',
		answer: '',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 2,
		text: '',
		answer: '',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 3,
		text: '',
		answer: '',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
	{
		id: 4,
		text: '',
		answer: '',
		pointsAwarded: false,
		userAnswer: '',
		unAnswered: [],
		isCorrect: false,
	},
];
