import session18_audio1 from '../assets/Session_18_MORPH_1.m4a';
import session18_audio2 from '../assets/Session_18_MORPH_2.m4a';
import session18_audio3 from '../assets/Session_18_MORPH_3.m4a';
import session18_audio4 from '../assets/Session_18_MORPH_4.m4a';
import session18_audio5 from '../assets/Session_18_MORPH_5.m4a';
import session18_audio6 from '../assets/Session_18_MORPH_6.m4a';
//ToDO: one last missing here
import session19_audio1 from '../assets/Session_19_MORPH_1.m4a';
import session19_audio2 from '../assets/Session_19_MORPH_2.m4a';
import session19_audio3 from '../assets/Session_19_MORPH_3.m4a';
import session19_audio4 from '../assets/Session_19_MORPH_4.m4a';
import session19_audio5 from '../assets/Session_19_MORPH_5.m4a';
//TODO: last  Missing two here

import session20_audio1 from '../assets/Session_20_MORPH_1.m4a';
import session20_audio2 from '../assets/Session_20_MORPH_2.m4a';
import session20_audio3 from '../assets/Session_20_MORPH_3.m4a';
import session20_audio4 from '../assets/Session_20_MORPH_4.m4a';
import session20_audio5 from '../assets/Session_20_MORPH_5.m4a';
import session20_audio6 from '../assets/Session_20_MORPH_6.m4a';
import session20_audio7 from '../assets/Session_20_MORPH_7.m4a';

import session21_audio1 from '../assets/Session_21_MORPH_1.m4a';
import session21_audio2 from '../assets/Session_21_MORPH_2.m4a';
import session21_audio3 from '../assets/Session_21_MORPH_3.m4a';
import session21_audio4 from '../assets/Session_21_MORPH_4.m4a';
import session21_audio5 from '../assets/Session_21_MORPH_5.m4a';
import session21_audio6 from '../assets/Session_21_MORPH_6.m4a';
import session21_audio7 from '../assets/Session_21_MORPH_7.m4a';

import session22_audio1 from '../assets/Session_22_MORPH_1.m4a';
import session22_audio2 from '../assets/Session_22_MORPH_2.m4a';
import session22_audio3 from '../assets/Session_22_MORPH_3.m4a';
import session22_audio4 from '../assets/Session_22_MORPH_4.m4a';
import session22_audio5 from '../assets/Session_22_MORPH_5.m4a';
import session22_audio6 from '../assets/Session_22_MORPH_6.m4a';

import session23_audio1 from '../assets/Session_23_MORPH_1.m4a';
import session23_audio2 from '../assets/Session_23_MORPH_2.m4a';
import session23_audio3 from '../assets/Session_23_MORPH_3.m4a';
import session23_audio5 from '../assets/Session_23_MORPH_5.m4a';
import session23_audio6 from '../assets/Session_23_MORPH_6.m4a';
import session23_audio7 from '../assets/Session_23_MORPH_7.m4a';
import session23_audio8 from '../assets/Session_23_MORPH_8.m4a';

//Todo missing number  session23_audio4 here

import session24_audio1 from '../assets/Session_24_MORPH_1.m4a';
import session24_audio2 from '../assets/Session_24_MORPH_2.m4a';
import session24_audio3 from '../assets/Session_24_MORPH_3.m4a';
import session24_audio4 from '../assets/Session_24_MORPH_4.m4a';
import session24_audio5 from '../assets/Session_24_MORPH_5.m4a';
import session24_audio6 from '../assets/Session_24_MORPH_6.m4a';
import session24_audio7 from '../assets/Session_24_MORPH_7.m4a';

import session25_audio1 from '../assets/Session_25_MORPH_1.m4a';
import session25_audio2 from '../assets/Session_25_MORPH_2.m4a';
import session25_audio3 from '../assets/Session_25_MORPH_3.m4a';
import session25_audio4 from '../assets/Session_25_MORPH_4.m4a';
import session25_audio5 from '../assets/Session_25_MORPH_5.m4a';
import session25_audio6 from '../assets/Session_25_MORPH_6.m4a';

import session26_audio1 from '../assets/Session_26_MORPH_1.m4a';
import session26_audio2 from '../assets/Session_26_MORPH_2.m4a';
import session26_audio3 from '../assets/Session_26_MORPH_3.m4a';
import session26_audio4 from '../assets/Session_26_MORPH_4.m4a';
import session26_audio5 from '../assets/Session_26_MORPH_5.m4a';
import session26_audio6 from '../assets/Session_26_MORPH_6.m4a';

import session27_audio1 from '../assets/Session_27_MORPH_1.m4a';
import session27_audio2 from '../assets/Session_27_MORPH_2.m4a';
import session27_audio3 from '../assets/Session_27_MORPH_3.m4a';
import session27_audio4 from '../assets/Session_27_MORPH_4.m4a';
import session27_audio5 from '../assets/Session_27_MORPH_5.m4a';
import session27_audio6 from '../assets/Session_27_MORPH_6.m4a';

import session28_audio1 from '../assets/Session_28_MORPH_1.m4a';
import session28_audio2 from '../assets/Session_28_MORPH_2.m4a';
import session28_audio3 from '../assets/Session_28_MORPH_3.m4a';
import session28_audio4 from '../assets/Session_28_MORPH_4.m4a';
import session28_audio5 from '../assets/Session_28_MORPH_5.m4a';
import session28_audio6 from '../assets/Session_28_MORPH_6.m4a';
import session28_audio7 from '../assets/Session_28_MORPH_7.m4a';

import session29_audio1 from '../assets/Session_29_MORPH_1.m4a';
import session29_audio2 from '../assets/Session_29_MORPH_2.m4a';
import session29_audio3 from '../assets/Session_29_MORPH_3.m4a';
import session29_audio4 from '../assets/Session_29_MORPH_4.m4a';
import session29_audio5 from '../assets/Session_29_MORPH_5.m4a';
import session29_audio6 from '../assets/Session_29_MORPH_6.m4a';

import session30_audio1 from '../assets/Session_30_MORPH_1.m4a';
import session30_audio2 from '../assets/Session_30_MORPH_2.m4a';
import session30_audio3 from '../assets/Session_30_MORPH_3.m4a';
import session30_audio4 from '../assets/Session_30_MORPH_4.m4a';
import session30_audio5 from '../assets/Session_30_MORPH_5.m4a';
import session30_audio6 from '../assets/Session_30_MORPH_6.m4a';

import session31_audio1 from '../assets/Session_31_MORPH_1.m4a';
import session31_audio2 from '../assets/Session_31_MORPH_2.m4a';
import session31_audio3 from '../assets/Session_31_MORPH_3.m4a';
import session31_audio4 from '../assets/Session_31_MORPH_4.m4a';
import session31_audio5 from '../assets/Session_31_MORPH_5.m4a';
import session31_audio6 from '../assets/Session_31_MORPH_6.m4a';
import session31_audio7 from '../assets/Session_31_MORPH_7.m4a';

import session32_audio1 from '../assets/Session_32_MORPH_1.m4a';
import session32_audio2 from '../assets/Session_32_MORPH_2.m4a';
import session32_audio3 from '../assets/Session_32_MORPH_3.m4a';
import session32_audio4 from '../assets/Session_32_MORPH_4.m4a';
import session32_audio5 from '../assets/Session_32_MORPH_5.m4a';
import session32_audio6 from '../assets/Session_32_MORPH_6.m4a';

import session33_audio1 from '../assets/Session_33_MORPH_1.m4a';
import session33_audio2 from '../assets/Session_33_MORPH_2.m4a';
import session33_audio3 from '../assets/Session_33_MORPH_3.m4a';
import session33_audio4 from '../assets/Session_33_MORPH_4.m4a';
import session33_audio5 from '../assets/Session_33_MORPH_5.m4a';
import session33_audio6 from '../assets/Session_33_MORPH_6.m4a';
import session33_audio7 from '../assets/Session_33_MORPH_7.m4a';

import session34_audio1 from '../assets/Session_34_MORPH_1.m4a';
import session34_audio2 from '../assets/Session_34_MORPH_2.m4a';
import session34_audio3 from '../assets/Session_34_MORPH_3.m4a';
import session34_audio4 from '../assets/Session_34_MORPH_4.m4a';
import session34_audio5 from '../assets/Session_34_MORPH_5.m4a';
import session34_audio6 from '../assets/Session_34_MORPH_6.m4a';

import session35_audio1 from '../assets/Session_35_MORPH_1.m4a';
import session35_audio2 from '../assets/Session_35_MORPH_2.m4a';
import session35_audio3 from '../assets/Session_35_MORPH_3.m4a';
import session35_audio4 from '../assets/Session_35_MORPH_4.m4a';
import session35_audio5 from '../assets/Session_35_MORPH_5.m4a';
import session35_audio6 from '../assets/Session_35_MORPH_6.m4a';

import session36_audio1 from '../assets/Session_36_MORPH_1.m4a';
import session36_audio2 from '../assets/Session_36_MORPH_2.m4a';
import session36_audio3 from '../assets/Session_36_MORPH_3.m4a';
import session36_audio4 from '../assets/Session_36_MORPH_4.m4a';
import session36_audio5 from '../assets/Session_36_MORPH_5.m4a';
import session36_audio6 from '../assets/Session_36_MORPH_6.m4a';

import session37_audio1 from '../assets/Session_37_MORPH_1.m4a';
import session37_audio2 from '../assets/Session_37_MORPH_2.m4a';
import session37_audio3 from '../assets/Session_37_MORPH_3.m4a';
import session37_audio4 from '../assets/Session_37_MORPH_4.m4a';
import session37_audio5 from '../assets/Session_37_MORPH_5.m4a';
import session37_audio6 from '../assets/Session_37_MORPH_6.m4a';

import session38_audio1 from '../assets/Session_38_MORPH_1.m4a';
import session38_audio2 from '../assets/Session_38_MORPH_2.m4a';
import session38_audio3 from '../assets/Session_38_MORPH_3.m4a';
import session38_audio4 from '../assets/Session_38_MORPH_4.m4a';
import session38_audio5 from '../assets/Session_38_MORPH_5.m4a';
import session38_audio6 from '../assets/Session_38_MORPH_6.m4a';

import session39_audio1 from '../assets/Session_39_MORPH_1.m4a';
import session39_audio2 from '../assets/Session_39_MORPH_2.m4a';
import session39_audio3 from '../assets/Session_39_MORPH_3.m4a';
import session39_audio4 from '../assets/Session_39_MORPH_4.m4a';
import session39_audio5 from '../assets/Session_39_MORPH_5.m4a';
import session39_audio6 from '../assets/Session_39_MORPH_6.m4a';

//TODO:: Missing one audio here
import session40_audio1 from '../assets/Session_40_MORPH_1.m4a';
import session40_audio2 from '../assets/Session_40_MORPH_2.m4a';
import session40_audio3 from '../assets/Session_40_MORPH_3.m4a';
import session40_audio4 from '../assets/Session_40_MORPH_4.m4a';
import session40_audio5 from '../assets/Session_40_MORPH_5.m4a';

import session41_audio1 from '../assets/Session_41_MORPH_1.m4a';
import session41_audio2 from '../assets/Session_41_MORPH_2.m4a';
import session41_audio3 from '../assets/Session_41_MORPH_3.m4a';
import session41_audio4 from '../assets/Session_41_MORPH_4.m4a';
import session41_audio5 from '../assets/Session_41_MORPH_5.m4a';

import session42_audio1 from '../assets/Session_42_MORPH_1.m4a';
import session42_audio2 from '../assets/Session_42_MORPH_2.m4a';
import session42_audio3 from '../assets/Session_42_MORPH_3.m4a';
import session42_audio4 from '../assets/Session_42_MORPH_4.m4a';
import session42_audio5 from '../assets/Session_42_MORPH_5.m4a';
import session42_audio6 from '../assets/Session_42_MORPH_6.m4a';

import session43_audio1 from '../assets/Session_43_MORPH_1.m4a';
import session43_audio2 from '../assets/Session_43_MORPH_2.m4a';
import session43_audio3 from '../assets/Session_43_MORPH_3.m4a';
import session43_audio4 from '../assets/Session_43_MORPH_4.m4a';
import session43_audio5 from '../assets/Session_43_MORPH_5.m4a';
import session43_audio6 from '../assets/Session_43_MORPH_6.m4a';

import session44_audio1 from '../assets/Session_44_MORPH_1.m4a';
import session44_audio2 from '../assets/Session_44_MORPH_2.m4a';
import session44_audio3 from '../assets/Session_44_MORPH_3.m4a';
import session44_audio4 from '../assets/Session_44_MORPH_4.m4a';
import session44_audio5 from '../assets/Session_44_MORPH_5.m4a';
import session44_audio6 from '../assets/Session_44_MORPH_6.m4a';

import session45_audio1 from '../assets/Session_45_MORPH_1.m4a';
import session45_audio2 from '../assets/Session_45_MORPH_2.m4a';
import session45_audio3 from '../assets/Session_45_MORPH_3.m4a';
import session45_audio4 from '../assets/Session_45_MORPH_4.m4a';
import session45_audio5 from '../assets/Session_45_MORPH_5.m4a';
import session45_audio6 from '../assets/Session_45_MORPH_6.m4a';

import session46_audio1 from '../assets/Session_46_MORPH_1.m4a';
import session46_audio2 from '../assets/Session_46_MORPH_2.m4a';
import session46_audio3 from '../assets/Session_46_MORPH_3.m4a';
import session46_audio4 from '../assets/Session_46_MORPH_4.m4a';
import session46_audio5 from '../assets/Session_46_MORPH_5.m4a';
import session46_audio6 from '../assets/Session_46_MORPH_6.m4a';

import session47_audio1 from '../assets/Session_47_MORPH_1.m4a';
import session47_audio2 from '../assets/Session_47_MORPH_2.m4a';
import session47_audio3 from '../assets/Session_47_MORPH_3.m4a';
import session47_audio4 from '../assets/Session_47_MORPH_4.m4a';
import session47_audio5 from '../assets/Session_47_MORPH_5.m4a';
import session47_audio6 from '../assets/Session_47_MORPH_6.m4a';

import session48_audio1 from '../assets/Session_48_MORPH_1.m4a';
import session48_audio2 from '../assets/Session_48_MORPH_2.m4a';
import session48_audio3 from '../assets/Session_48_MORPH_3.m4a';
import session48_audio4 from '../assets/Session_48_MORPH_4.m4a';
import session48_audio5 from '../assets/Session_48_MORPH_5.m4a';
import session48_audio6 from '../assets/Session_48_MORPH_6.m4a';

import session49_audio1 from '../assets/Session_49_MORPH_1.m4a';
import session49_audio2 from '../assets/Session_49_MORPH_2.m4a';
import session49_audio3 from '../assets/Session_49_MORPH_3.m4a';
import session49_audio4 from '../assets/Session_49_MORPH_4.m4a';
import session49_audio5 from '../assets/Session_49_MORPH_5.m4a';
import session49_audio6 from '../assets/Session_49_MORPH_6.m4a';

import session50_audio1 from '../assets/Session_50_MORPH_1.m4a';
import session50_audio2 from '../assets/Session_50_MORPH_2.m4a';
import session50_audio3 from '../assets/Session_50_MORPH_3.m4a';
import session50_audio4 from '../assets/Session_50_MORPH_4.m4a';
import session50_audio5 from '../assets/Session_50_MORPH_5.m4a';
import session50_audio6 from '../assets/Session_50_MORPH_6.m4a';

import session51_audio1 from '../assets/Session_51_MORPH_1.m4a';
import session51_audio2 from '../assets/Session_51_MORPH_2.m4a';
import session51_audio3 from '../assets/Session_51_MORPH_3.m4a';
import session51_audio4 from '../assets/Session_51_MORPH_4.m4a';
import session51_audio5 from '../assets/Session_51_MORPH_5.m4a';
import session51_audio6 from '../assets/Session_51_MORPH_6.m4a';

export const session18 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Noen barn kler seg ut som',
				leadingText: '',
				correct: ['c'],
				sound: session18_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'vampyr', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'vampyren', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'vampyrer', isAnswered: false, isCorrect: true },
					{ id: 4, letter: 'd', label: 'vampyrene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Andre barn kler seg ut som',
				leadingText: '',
				correct: ['c'],
				sound: session18_audio2,
				choices: [
					{ id: 1, letter: 'a', label: ' spøkelse', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: ' spøkelset',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 3, letter: 'c', label: ' spøkelser', isAnswered: false, isCorrect: true },
					{
						id: 4,
						letter: 'd',
						label: ' spøkelsene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Mange pynter med',
				correct: ['a'],
				leadingText: '',
				sound: session18_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'gresskar', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'gresskaret',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'gresskarene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Barn ',
				correct: ['b'],
				leadingText: ' rundt og banker på dører. ',
				sound: session18_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'gå', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'går', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'gikk', isAnswered: false, isCorrect: false },
					{ id: 4, letter: 'd', label: 'gått', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'De',
				correct: ['b'],
				leadingText: ': Knask eller knep! ',
				sound: session18_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'rope', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'roper', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'ropte', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'd', label: 'ropt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 6,
				precedingText: 'Da ',
				correct: ['b'],
				leadingText: 'vi halloween',
				sound: session18_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'feire', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'feirer', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'feiret', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 7,
				precedingText: 'De som ikke vil ha besøk ',

				correct: ['b'],
				leadingText: 'utelyset',
				choices: [
					{ id: 1, letter: 'a', label: 'slukke', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'slukker', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'slukket', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];
export const session19 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Vi ',
				leadingText: ' de for trekkfugler.',
				correct: ['b'],
				sound: session19_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'kalle', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'kaller', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'kalte', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Mange',
				leadingText: ' kan ikke leve i nord om vinteren ',
				correct: ['b'],
				sound: session19_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'fugl', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: ' fugler',
						isAnswered: false,
						isCorrect: true,
					},
					{ id: 3, letter: 'c', label: ' fuglene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Dagene blir ',
				correct: ['b'],
				leadingText: '',
				sound: session19_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'kort', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'kortere',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kortest',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Det blir ',
				correct: ['b'],
				leadingText: ' for fuglene å finne mat.  ',
				sound: session19_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'vanskelig', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'vanskeligere',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vanskeligst',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Fuglene liker å',
				correct: ['a'],
				leadingText: 'insekter ',
				sound: session19_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'spise', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'spiser', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'spiste', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 6,
				precedingText: 'Været blir ',
				correct: ['b'],
				leadingText: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'kald', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'kaldere', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'kaldest', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 7,
				precedingText: 'Mange fugler kan ikke ',

				correct: ['a'],
				leadingText: ' i nord om vinteren.',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'leve', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'lever', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'levde', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];
export const session20 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'De ',
				leadingText: ' i grupper eller flokk.',
				correct: ['b'],
				sound: session20_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'fly', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'flyr', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'fløyet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Den',
				leadingText: ' fuglen er i front av V-en. ',
				correct: ['c'],
				sound: session20_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'sterk', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: ' sterkere',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 3, letter: 'c', label: ' sterkeste', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 3,
				precedingText: 'Resten av fuglene ',
				correct: ['b'],
				leadingText: ' etter i to linjer.',
				sound: session20_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'følge', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'følger',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'fulgte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'De flyr nordover når ',
				correct: ['b'],
				leadingText: ' er over.',
				sound: session20_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'vinter', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'vinteren',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vintrer',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Når fuglen i front blir ',
				correct: ['a'],
				leadingText: 'bytter den plass med en annen fugl.',
				sound: session20_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'sliten', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'mer sliten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'mest sliten',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Fuglene tar ikke turen på én ',
				correct: ['a'],
				leadingText: '',
				sound: session20_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'dag', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'dagen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'dager', isAnswered: false, isCorrect: false },
					{ id: 4, letter: 'd', label: 'dagene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 7,
				precedingText: 'Den sterkeste ',
				correct: ['b'],
				leadingText: ' er i front av V-en.',
				sound: session20_audio7,
				choices: [
					{ id: 1, letter: 'a', label: 'fugl', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'fuglen', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'fugler', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'fuglene', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];
export const session21 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Det ',
				leadingText: ' mange stater som jobber sammen.',
				correct: ['b'],
				sound: session21_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'være', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'er', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'var', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Det er vanlig å  ',
				leadingText: ' dem FN.',
				correct: ['a'],
				sound: session21_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'kalle', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'kaller', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'kalt', isAnswered: false, isCorrect: false },
					{ id: 4, letter: 'd', label: 'kalte', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'De',
				leadingText: ' syke og fattige mennesker. ',
				correct: ['b'],
				sound: session21_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'hjelpe', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: ' hjelper',
						isAnswered: false,
						isCorrect: true,
					},
					{ id: 3, letter: 'c', label: ' hjalp', isAnswered: false, isCorrect: false },
					{ id: 4, letter: 'd', label: ' hjulpet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'De hjelper syke og ',
				correct: ['b'],
				leadingText: ' mennesker',
				sound: session21_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'fattig', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'fattige',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Mange ',
				correct: ['c'],
				leadingText: ' jobber i FN..',
				sound: session21_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'menneske', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'mennesket',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'mennesker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'menneskene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'De hjelper land å være ',
				correct: ['c'],
				leadingText: '',
				sound: session21_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'venn', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'vennen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'venner',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'vennene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 7,
				precedingText: 'Det er mange ',
				correct: ['c'],
				leadingText: ' som jobber sammen.',
				sound: session21_audio7,
				choices: [
					{ id: 1, letter: 'a', label: 'stat', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'staten', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'stater', isAnswered: false, isCorrect: true },
					{ id: 4, letter: 'd', label: 'statene', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];
export const session22 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Det er mye ',
				leadingText: ' å være frisk! ',
				correct: ['b'],
				sound: session22_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'bare', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'bedre', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'best', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Det er mange måter å  ',
				leadingText: ' seg frisk på.',
				correct: ['a'],
				sound: session22_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'holde', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'holder', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'holdt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'En måte er å ',
				leadingText: ' sprøyte. ',
				correct: ['a'],
				sound: session22_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'få', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: ' får',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 3, letter: 'c', label: ' fikk', isAnswered: false, isCorrect: false },
					{ id: 4, letter: 'd', label: ' fått', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'En ',
				correct: ['a'],
				leadingText: ' har en vaksine i seg.',
				sound: session22_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'injeksjon', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'injeksjonen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'injeksjoner',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Det er en slags ',
				correct: ['a'],
				leadingText: '',
				sound: session22_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'medisin', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'medisinen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'medisinene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Noen ',
				correct: ['b'],
				leadingText: ' skal du drikke. ',
				sound: session22_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'vaksine', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'vaksiner',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vaksinene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session23 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Været ',
				leadingText: ' hvordan vi lever ',
				correct: ['b'],
				sound: session23_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'påvirke', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'påvirker', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'påvirket', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Når  ',
				leadingText: ' fuktig luft stiger raskt, blir det storm.',
				correct: ['a'],
				sound: session23_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'varm', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'varmere', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'varmest', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Varmen kommer fra ',
				leadingText: '',
				correct: ['b'],
				sound: session23_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'sol', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'solen',
						isAnswered: false,
						isCorrect: true,
					},
					{ id: 3, letter: 'c', label: ' solene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Varmen ',
				correct: ['b'],
				leadingText: ' fra solen.',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'komme', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'kommer',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kom',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Det blåser ',
				correct: ['a'],
				leadingText: ' vind under en storm.',
				sound: session23_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'sterk', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'sterkere',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sterkest',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Vanndråpene i luften danner ',
				correct: ['b'],
				leadingText: '',
				sound: session23_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'sky', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'skyer',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'skyene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 7,
				precedingText: 'Luft som ',
				correct: ['b'],
				leadingText: ' seg kaller vi for vind.',
				sound: session23_audio7,
				choices: [
					{ id: 1, letter: 'a', label: 'bevege', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'beveger',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'beveget',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 8,
				precedingText: '',
				correct: ['c'],
				leadingText: ' i luften danner skyer.',
				sound: session23_audio8,
				choices: [
					{ id: 1, letter: 'a', label: 'Vanndråpe', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'Vanndråpen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'Vanndråpene',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];
export const session24 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Om våren blir det ',
				leadingText: '',
				correct: ['b'],
				sound: session24_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'varm', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'varmere', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'varmest', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Sommeren er varm og har lange  ',
				leadingText: '',
				correct: ['b'],
				sound: session24_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'dagen', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'dager', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'dagene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Hver årstid  ',
				leadingText: ' i tre måneder. ',
				correct: ['b'],
				sound: session24_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'vare', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'varer', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: ' varte', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Høsten er ',
				leadingText: ' mellom sommer og vinter.',
				correct: ['b'],
				sound: session24_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'årstid', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'årstiden', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'årstider', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'årstidene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Jorden ',
				leadingText: ' seg rundt solen.',
				correct: ['b'],
				sound: session24_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'bevege', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'beveger', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'beveget', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 6,
				precedingText: 'Jorden vår er rund som en ',
				leadingText: '',
				correct: ['a'],
				sound: session24_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'appelsin', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'appelsinen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'appelsiner',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'appelsinene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 7,
				precedingText: 'Jordens akse ',
				correct: ['b'],
				leadingText: ' litt på skrå.',
				sound: session24_audio7,
				choices: [
					{ id: 1, letter: 'a', label: 'stå', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'står', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'sto', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'stått', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];
export const session25 = [
	{
		words: [
			{
				id: 1,
				precedingText: '«Hvem var det som ',
				leadingText: ' deg',
				correct: ['c'],
				sound: session25_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'redde', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'redder', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'reddet', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: 'Sist jeg så deg var du på  ',
				leadingText: '',
				correct: ['b'],
				sound: session25_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'kai', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'kaien', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'kaier', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: '«Det er godt å   ',
				leadingText: ' deg igjen».',
				correct: ['a'],
				sound: session25_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'se', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'ser', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: ' så', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'd', label: ' sett', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Nå må jeg ',
				leadingText: '',
				correct: ['a'],
				sound: session25_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'dra', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'drar', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'dro', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'd', label: 'dratt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Skal vi spise litt ',
				leadingText: '',
				correct: ['a'],
				sound: session25_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'eplepai', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'eplepaien', isAnswered: false, isCorrect: false },
					{
						id: 3,
						letter: 'c',
						label: 'eplepaiene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Aina falt av ',
				leadingText: ' med et klask.',
				correct: ['b'],
				sound: session25_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'scenekant', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'scenekanten',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'scenekanter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'scenekantene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session26 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Klaus skulle aldri ha ',
				leadingText: ' skøytene',
				correct: ['c'],
				sound: session26_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'prøve', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'prøver', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'prøvd', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: 'Nå lå han på magen i en  ',
				leadingText: '',
				correct: ['a'],
				sound: session26_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'snøhaug', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'snøhaugen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'snøhauger', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: '',
				leadingText: ' det bra med deg, Klaus?',
				correct: ['b'],
				sound: session26_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'Gå', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'Gikk', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: ' Gått', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Gaute ble ',
				leadingText: '',
				correct: ['a'],
				sound: session26_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'flau', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'flauere', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'flauest', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Nesten som en ',
				leadingText: '',
				correct: ['a'],
				sound: session26_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'sau', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'sauen', isAnswered: false, isCorrect: false },
					{
						id: 3,
						letter: 'c',
						label: 'sauene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Han burde ',
				leadingText: ' seg en pause for lenge siden.',
				correct: ['c'],
				sound: session26_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'ta', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'tar',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tatt',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];
export const session27 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Men der ',
				leadingText: ' han feil.',
				correct: ['b'],
				sound: session27_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'ta', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'tok', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'tatt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Men alt  ',
				leadingText: ' skeis.',
				correct: ['b'],
				sound: session27_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'gå', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'gikk', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'gått', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Eirin sto på balkongen og sang til ',
				leadingText: '',
				correct: ['b'],
				sound: session27_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'drage', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'dragen', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: ' drager', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: '«Ha deg vekk, ditt ',
				leadingText: ' !»',
				correct: ['a'],
				sound: session27_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'beist', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'beistet', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'beistene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Eirin sto på balkongen og ',
				leadingText: ' til dragen.',
				correct: ['c'],
				sound: session27_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'synge', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'synger', isAnswered: false, isCorrect: false },
					{
						id: 3,
						letter: 'c',
						label: 'sang',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Dragen veivet med ',
				leadingText: '',
				correct: ['b'],
				sound: session27_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'hale', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'halen',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'halene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session28 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Frøya bor på en ',
				leadingText: ' høyde',
				correct: ['a'],
				sound: session28_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'liten', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'mindre', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'minst', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Det er en liten  ',
				leadingText: '',
				correct: ['a'],
				sound: session28_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'ferieøy', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'ferieøyen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'ferieøyer', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'I dag har Eføy  ',
				leadingText: '',
				correct: ['c'],
				sound: session28_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'rømme', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'rømmer', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: ' rømt', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'Eføy ',
				leadingText: ' ikke støy',
				correct: ['b'],
				sound: session28_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'like', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'liker', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'likt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Frøya tar med seg et ',
				leadingText: ' syltetøy.',
				correct: ['a'],
				sound: session28_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'glass', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'glasset', isAnswered: false, isCorrect: false },
					{
						id: 3,
						letter: 'c',
						label: 'glassene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Hun går forbi en gammel ',
				leadingText: '',
				correct: ['a'],
				sound: session28_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'brøytebil', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'brøytebilen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brøytebilene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 7,
				precedingText: 'Mannen har ikke ',
				leadingText: ' ham.',
				correct: ['d'],
				sound: session28_audio7,
				choices: [
					{ id: 1, letter: 'a', label: 'se', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'ser',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'så',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'sett',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];
export const session29 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Gutter og ',
				leadingText: ' kler seg ut og går i tog.',
				correct: ['c'],
				sound: session29_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'jente', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'jenten', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'jenter', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: 'Til Luciadagen  ',
				leadingText: ' man lussekatter. ',
				correct: ['b'],
				sound: session29_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'bake', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'baker', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'bakt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Hun har en   ',
				leadingText: '  i håret.',
				correct: ['a'],
				sound: session29_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'krone', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'kronen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'kronene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Vi ',
				leadingText: ' juletre inne i huset',
				correct: ['b'],
				sound: session29_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'ha', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'har', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'hatt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Mange pynter med en ',
				leadingText: ' nisser og julestjerner.',
				correct: ['a'],
				sound: session29_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'bukk', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'bukken', isAnswered: false, isCorrect: false },
					{
						id: 3,
						letter: 'c',
						label: 'bukker',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Vi ',
				leadingText: ' gjerne ribbe, pinnekjøtt eller fisk på julaften.',
				correct: ['b'],
				sound: session29_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'spise', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'spiser',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'spist',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session30 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'I England ',
				leadingText: ' vi julegavene 1.juledag.',
				correct: ['b'],
				sound: session30_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'åpne', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'åpner', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'åpnet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Vi pynter ',
				leadingText: ' vårt med kristtorn og julelys.',
				correct: ['b'],
				sound: session30_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'hus', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'huset', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'husene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'I stuen ',
				leadingText: '  vi et juletre.',
				correct: ['b'],
				sound: session30_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'ha', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'har', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'hatt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Alle henger opp julesokker med godterier og små ',
				leadingText: ' til julenissen.',
				correct: ['c'],
				sound: session30_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'gave', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'gaven', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'gaver', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Hvis vi ikke ',
				leadingText: ' snille får vi kull i dem!',
				correct: ['c'],
				sound: session30_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'var', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'vært', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'har vært', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 6,
				precedingText: 'Ved siden av hvert ',
				leadingText: ' legger vi en bongbong.',
				correct: ['a'],
				sound: session30_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'fat', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'fatet', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'fatene', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];
export const session31 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Det er ',
				leadingText: ' her på den tiden av året.',
				correct: ['a'],
				sound: session31_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'sommer', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'sommeren', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'somrene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Vi ',
				leadingText: ' ofte på stranden',
				correct: ['b'],
				sound: session31_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'grille', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'griller', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'grillet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Vi spiser middag på ',
				leadingText: '',
				correct: ['b'],
				sound: session31_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'strand', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'stranden', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'strender', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Buskene har røde ',
				leadingText: '',
				correct: ['b'],
				sound: session31_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'blomst', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'blomster', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'blomstene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Vi pynter husene våre med ',
				leadingText: '',
				correct: ['c'],
				sound: session31_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'julebusk', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'julebusken',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 3, letter: 'c', label: 'julebusker', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 6,
				precedingText: 'Mange av oss ',
				leadingText: ' julesanger i parken på kvelden.',
				correct: ['c'],
				sound: session31_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'synge', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'synget', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'synger', isAnswered: false, isCorrect: true },
				],
			},

			{
				id: 7,
				precedingText: 'I Australia ',
				leadingText: ' julenissen ut gaver til alle på julaften',
				correct: ['b'],
				sound: session31_audio7,
				choices: [
					{ id: 1, letter: 'a', label: 'levere', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'leverer', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'levert', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];
export const session32 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Før ',
				leadingText: ' det jól.',
				correct: ['c'],
				sound: session32_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'hete', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'heter', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'het', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: 'Jól var en ',
				leadingText: ' og de feiret et godt år.',
				correct: ['a'],
				sound: session32_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'fest', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'festen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'festene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'De ',
				leadingText: ' et godt år. ',
				correct: ['c'],
				sound: session32_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'feire', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'feirer', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'feiret', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'De gikk ',
				leadingText: ' og fikk noe godt.',
				correct: ['a'],
				sound: session32_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'julebukk', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'julebukken',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'julebukkene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Grøt ble ',
				leadingText: ' ut til fjøsnissen.',
				correct: ['c'],
				sound: session32_audio5,
				choices: [
					{ id: 1, letter: 'a', label: 'sette', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'setter',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 3, letter: 'c', label: 'satt', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 6,
				precedingText: 'Fjøsnissen er ikke ',
				leadingText: '',
				correct: ['b'],
				sound: session32_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'julenisse', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'julenissen', isAnswered: false, isCorrect: true },
					{
						id: 3,
						letter: 'c',
						label: 'julenissene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session33 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Den ',
				leadingText: ' høytiden vi har i Kina er nyttår.',
				correct: ['c'],
				sound: session33_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'store', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'større', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'største', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: 'Vi ',
				leadingText: ' den også for vårfestivalen.',
				correct: ['b'],
				sound: session33_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'kalle', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'kaller', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'kalte', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Vi pynter ',
				leadingText: ' våre med fargene rødt og gull. ',
				correct: ['c'],
				sound: session33_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'hus', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'huset', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'husene', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'Vi ',
				leadingText: ' godsaker som søte riskaker, appelsiner og klementiner.',
				correct: ['b'],
				sound: session33_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'spise', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'spiser', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'spist', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Fyrverkeri blir brukt for å ',
				leadingText: ' vekk ulykke.',
				sound: session33_audio5,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'skremme', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'skremmer',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 3, letter: 'c', label: 'skremte', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 6,
				precedingText: 'Alle ',
				leadingText: ' har en stor drage.',
				correct: ['c'],
				sound: session33_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'parade', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'paraden', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'paradene', isAnswered: false, isCorrect: true },
				],
			},

			{
				id: 7,
				precedingText: 'Barna får gaver som er små røde ',
				leadingText: ' med penger i.',
				correct: ['c'],
				sound: session33_audio7,
				choices: [
					{ id: 1, letter: 'a', label: 'konvolutt', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'konvolutten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'konvolutter',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session34 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En blond ',
				leadingText: ' i blått.',
				correct: ['a'],
				sound: session34_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'mann', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'mannen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'menn', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Jeg har med blåklokker og vakre ',
				leadingText: ' ',
				correct: ['b'],
				sound: session34_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'blomst', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'blomster', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'blomstene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Han begynte å ',
				leadingText: ' bleik. ',
				correct: ['a'],
				sound: session34_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'bli', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'blir', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'blitt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Billy hadde blomster å  ',
				leadingText: ' ',
				correct: ['a'],
				sound: session34_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'levere', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'leverer', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'leverte', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Så svimte han av midt på ',
				leadingText: '',
				sound: session34_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'vei', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'veien',
						isAnswered: false,
						isCorrect: true,
					},
					{ id: 3, letter: 'c', label: 'veiene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 6,
				precedingText: 'Bilen harket og ',
				leadingText: ' langs veien i byen.',
				correct: ['c'],
				sound: session34_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'hoste', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'hoster', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'hostet', isAnswered: false, isCorrect: true },
				],
			},
		],
	},
];
export const session35 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Brage hadde tyvlånt ',
				leadingText: ' til faren.',
				correct: ['b'],
				sound: session35_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'bil', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'bilen', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'biler', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Han ',
				leadingText: ' rett inn i en gul bil. ',
				correct: ['c'],
				sound: session35_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'brase', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'braser', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'braste', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 3,
				precedingText: ' Han fikk ',
				leadingText: ' ut av bilen.',
				correct: ['b'],
				sound: session35_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'dame', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'damen', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'damer', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: ' Den gule bilen begynte å',
				leadingText: ' ',
				correct: ['a'],
				sound: session35_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'brenne', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'brenner', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'brente', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'En dame prøvde å ',
				leadingText: ' seg ut.',
				sound: session35_audio5,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'bryte', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'bryter', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'brøt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 6,
				precedingText: 'Døren ',
				leadingText: ' knust.',
				correct: ['b'],
				sound: session35_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'være', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'var', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'vært', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];
export const session36 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Dirk ',
				leadingText: ' aldri.',
				correct: ['b'],
				sound: session36_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'drømme', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'drømmer', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'drømt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Drømmefangeren henger over ',
				leadingText: ' ',
				correct: ['b'],
				sound: session36_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'seng', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'sengen', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'senger', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: ' Snart ',
				leadingText: ' han.',
				correct: ['b'],
				sound: session36_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'sove', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'sover', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'sovet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: ' Flere',
				leadingText: ' vann renner ned vinduet.',
				correct: ['c'],
				sound: session36_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'dråpe', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'dråpen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'dråper', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Dragen ',
				leadingText: ' ild.',
				sound: session36_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'spy', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'spyr', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'spydd', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 6,
				precedingText: 'En mann i lilla ',
				leadingText: ' ',
				correct: ['a'],
				sound: session36_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'drakt', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'drakten', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'draktene', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];
export const session37 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En ',
				leadingText: ' midt i fleisen.',
				correct: ['a'],
				sound: session37_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'flamme', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'flammen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'flammer', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'En drage ',
				leadingText: ' ned fra fjellet',
				correct: ['b'],
				sound: session37_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'fly', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'flyr', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'flydd', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Alle ',
				leadingText: ' har dratt på utflukt.',
				correct: ['c'],
				sound: session37_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'heks', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'heksen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'hekser', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: ' Flammen hadde',
				leadingText: ' flagg, klær og hår.',
				correct: ['c'],
				sound: session37_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'brenne', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'brenner', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'brent', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Dette ',
				leadingText: ' de en gang i året.',
				sound: session37_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'gjøre', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'gjør', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'gjort', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 6,
				precedingText: '«Varm flasken!» sa ',
				leadingText: ' ',
				correct: ['b'],
				sound: session37_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'heks', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'heksa', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'hekser', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];
export const session38 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Solsystemet vårt ',
				leadingText: ' av Solen og planetene.',
				correct: ['b'],
				sound: session38_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'bestå', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'består', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'bestått', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Solen er en kjempestor, rund ',
				leadingText: '',
				correct: ['a'],
				sound: session38_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'stjerne', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'stjernen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'stjerner', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Solen har en ',
				leadingText: ' tyngdekraft.',
				correct: ['a'],
				sound: session38_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'sterk', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'sterkere', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'sterkest', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Det er åtte ',
				leadingText: ' i solsystemet vårt.',
				correct: ['c'],
				sound: session38_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'planet', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'planeten', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'planeter', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Uten tyngdekraften hadde vi ',
				leadingText: ' av Jorden.',
				sound: session38_audio5,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'falle', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'faller', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'falt', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 6,
				precedingText: 'Solen er en ',
				leadingText: ' rund stjerne.',
				correct: ['a'],
				sound: session38_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'stor', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'større', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'størst', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];
export const session39 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Du kan ',
				leadingText: ' Merkur',
				correct: ['a'],
				sound: session39_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'se', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'ser', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'så', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Merkur er full av krater og ',
				leadingText: '',
				correct: ['c'],
				sound: session39_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'grop', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'gropen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'groper', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 3,
				precedingText: 'Vi ',
				leadingText: ' på planeten Jorden.',
				correct: ['b'],
				sound: session39_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'bo', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'bor', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'bodde', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: ' ',
				leadingText: ' blir også kalt Tellus.',
				correct: ['b'],
				sound: session39_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'Jordklode', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'Jordkloden', isAnswered: false, isCorrect: true },
					{
						id: 3,
						letter: 'c',
						label: 'Jordkloder',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Mars ',
				leadingText: ' kald og støvete.',
				sound: session39_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'være', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'er', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'var', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 6,
				precedingText: ' ',
				leadingText: ' på overflaten er stort.',
				correct: ['b'],
				sound: session39_audio6,
				choices: [
					{ id: 1, letter: 'a', label: 'Trykk', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'Trykket', isAnswered: false, isCorrect: true },
				],
			},
		],
	},
];
export const session40 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Jupiter har mange ',
				leadingText: ' ',
				correct: ['c'],
				sound: session40_audio1, //TODO:: Missing audio here
				choices: [
					{ id: 1, letter: 'a', label: 'farge', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'fargen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'farger', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: ' ',
				leadingText: ' til Jupiter er «Den røde flekk».',
				correct: ['b'],
				sound: session40_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'Kjennetegn',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kjennetegnet',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjennetegner',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Uranus ',
				leadingText: ' blå-grønn farge.',
				correct: ['b'],
				sound: session40_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'ha', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'har', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'hadde', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Det er den ',
				leadingText: ' planeten',
				correct: ['c'],
				sound: session40_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'kald', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'kaldere', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'kaldeste', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Vindene ',
				leadingText: ' med supersonisk fart.',
				sound: session40_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'blåse', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'blåser', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'blåst', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];

export const session41 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Elgen er et ',
				leadingText: ' ',
				correct: ['a'],
				sound: session41_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'byttedyr', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'byttedyret',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'byttedyrene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Elgen ',
				leadingText: ' best alene.',
				correct: ['a'],
				sound: session41_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'trives', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'trivdes', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'De er enslige ',
				leadingText: '',
				correct: ['b'],
				sound: session41_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'dyret', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'dyr', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'dyrene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Ungene kalles for ',
				leadingText: '',
				correct: ['c'],
				sound: session41_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'kalv', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'kalven', isAnswered: false, isCorrect: false },
					{
						id: 3,
						letter: 'c',
						label: 'kalver',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Hannelger ',
				leadingText: ' store gevir.',
				sound: session41_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'ha', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'har', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'hadde', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];

export const session42 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Hver vinter vil hannelgen ',
				leadingText: ' geviret',
				correct: ['a'],
				sound: session42_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'felle', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'feller',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'felte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Nytt gevir vil vokse ut ',
				leadingText: ' etter.',
				correct: ['b'],
				sound: session42_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'vår', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'våren', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'vårer', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Elgene ',
				leadingText: ' varm, brun pels.',
				correct: ['b'],
				sound: session42_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'ha', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'har', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'hadde', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'En ',
				leadingText: ' kan bli over 2 meter.',
				correct: ['a'],
				sound: session42_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'elg', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'elgen', isAnswered: false, isCorrect: false },
					{
						id: 3,
						letter: 'c',
						label: 'elger',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Nytt ',
				leadingText: ' vil vokse ut våren etter.',
				sound: session42_audio5,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'gevir', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'geviret', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'gevirene', isAnswered: false, isCorrect: false },
				],
			},

			{
				id: 6,
				precedingText: 'Mennesker har ',
				leadingText: ' på elg i tusenvis av år.',
				sound: session42_audio6,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'jakte', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'jaktet', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'jakter', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];
export const session43 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En gruppe med ',
				leadingText: ' kalles for en bjørnefamilie',
				correct: ['c'],
				sound: session43_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'bjørn', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'bjørnen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bjørner',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'De ',
				leadingText: ' kjøtt og fisk.',
				correct: ['b'],
				sound: session43_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'spise', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'spiser', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'spist', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: '',
				leadingText: ' er store dyr.',
				correct: ['c'],
				sound: session43_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'Bjørn', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'Bjørnen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'Bjørner', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'De kan',
				leadingText: '  veldig raskt',
				correct: ['a'],
				sound: session43_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'løpe', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'løper', isAnswered: false, isCorrect: false },
					{
						id: 3,
						letter: 'c',
						label: 'løpte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Bjørnene sover i et',
				leadingText: ' ',
				sound: session43_audio5,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'hi', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'hiet', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'hiene', isAnswered: false, isCorrect: false },
				],
			},

			{
				id: 6,
				precedingText: 'Isbjørnen er den ',
				leadingText: ' av bjørneartene.',
				sound: session43_audio6,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'store', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'større', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'største', isAnswered: false, isCorrect: true },
				],
			},
		],
	},
];

export const session44 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Ulven er et ',
				leadingText: '',
				correct: ['a'],
				sound: session44_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'rovdyr', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'rovdyret', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'rovdyrene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Ulven ',
				leadingText: ' i grupper eller flokk.',
				correct: ['b'],
				sound: session44_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'leve', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'lever', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'levd', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Den er ',
				leadingText: '',
				correct: ['a'],
				sound: session44_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'kjøtteter', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'kjøtteteren',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjøttetere',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Ulven ',
				leadingText: ' for å advare om fare.',
				correct: ['b'],
				sound: session44_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'ule', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'uler', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'ulte', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Unge ',
				leadingText: ' kalles for valper.',
				sound: session44_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'ulv', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'ulver', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'ulvene', isAnswered: false, isCorrect: false },
				],
			},

			{
				id: 6,
				precedingText: 'Ulven ',
				leadingText: ' tykk vinterpels.',
				sound: session44_audio6,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'ha', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'har', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'hadde', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];

export const session45 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Rødreven ',
				leadingText: ' mest kjøtt.',
				correct: ['b'],
				sound: session45_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'spise', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'spiser', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'spist', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Rever bor i ',
				leadingText: ' under jorden.',
				correct: ['c'],
				sound: session45_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'hule', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'hulen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'huler', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 3,
				precedingText: 'Disse ',
				leadingText: ' kalles for hi.',
				correct: ['c'],
				sound: session45_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'hule', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'hulen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hulene',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Den har utmerket ',
				leadingText: ' ',
				correct: ['a'],
				sound: session45_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'hørsel', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'hørselen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'hørsler', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Ulver og store ørner ',
				leadingText: ' på rev.',
				sound: session45_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'jakte', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'jakter', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'jakter', isAnswered: false, isCorrect: false },
				],
			},

			{
				id: 6,
				precedingText: 'Valpene ',
				leadingText: ' med foreldrene sine i sju måneder.',
				sound: session45_audio6,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'bo', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'bor', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'bodd', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];

export const session46 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Samene er et ',
				leadingText: ' ',
				correct: ['a'],
				sound: session46_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'urfolk', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'urfolket', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'urfolkene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Samene bor i flere ',
				leadingText: ' ',
				correct: ['a'],
				sound: session46_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'land', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'landet', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'landene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Vi ',
				leadingText: ' i Sápmi.',
				correct: ['b'],
				sound: session46_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'bo', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'bor',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bodd',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Runebommen er en ',
				leadingText: ' ',
				correct: ['a'],
				sound: session46_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'tromme', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'trommen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'trommer', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'På skinnet er det ',
				leadingText: ' en del figurer.',
				sound: session46_audio5,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'male', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'maler', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'malt', isAnswered: false, isCorrect: true },
				],
			},

			{
				id: 6,
				precedingText: 'Når vi pynter oss ',
				leadingText: ' vi en samekofte.',
				sound: session46_audio6,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'bruke', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'bruker', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'brukt', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];

export const session47 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Vi ',
				leadingText: ' i lavvo og var nomader.',
				correct: ['c'],
				sound: session47_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'bo', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'bor', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'bodde', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: 'Vi ',
				leadingText: ' reinen på vandring.',
				correct: ['b'],
				sound: session47_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'følge', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'fulgte', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'fulgt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 3,
				precedingText: 'Dagens samer bor i vanlige ',
				leadingText: ' ',
				correct: ['a'],
				sound: session47_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'hus', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'huset', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'husene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Noen av oss ',
				leadingText: ' med reindrift og fiske.',
				correct: ['c'],
				sound: session47_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'arbeid', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'arbeide', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'arbeider', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Vi er en ',
				leadingText: ' ',
				sound: session47_audio5,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'minoritet', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'minoriteten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'minoritetene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},

			{
				id: 6,
				precedingText: 'Vi er et ',
				leadingText: ' ',
				sound: session47_audio6,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'urfolk', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'urfolket', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'urfolkene', isAnswered: false, isCorrect: false },
				],
			},
		],
	},
];

export const session48 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Tidlig på 1900-tallet ',
				leadingText: ' ting seg i Norge. ',
				correct: ['c'],
				sound: session48_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'forandre', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'forandrer', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'forandret', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: 'Det ble forbudt å ',
				leadingText: ' på samisk.',
				correct: ['a'],
				sound: session48_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'undervise', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'underviser',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'underviste',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Samiske ',
				leadingText: ' ble tatt fra foreldrene sine.',
				correct: ['a'],
				sound: session48_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'barn', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'barnet', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'barnene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'De måtte ',
				leadingText: ' på internatskoler.',
				correct: ['a'],
				sound: session48_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'bo', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'bor', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'bodde', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: '',
				leadingText: ' fikk ikke leve slik de selv ønsket.',
				sound: session48_audio5,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'Same', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'Samen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'Samene',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},

			{
				id: 6,
				precedingText: 'Kongen ba om ',
				leadingText: ' ',
				sound: session48_audio6,
				correct: ['a'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'unnskyldning',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'unnskyldningen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'unnskyldningene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session49 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Samenes nasjonaldag blir også ',
				leadingText: ' samefolkets dag.',
				correct: ['c'],
				sound: session49_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'kalle', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'kaller', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'kalt', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: 'Dette er en ',
				leadingText: ' for å feire samisk kultur.',
				correct: ['a'],
				sound: session49_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'dag', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'dagen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'dager',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Mange tar på seg samiske ',
				leadingText: ' ',
				correct: ['c'],
				sound: session49_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'kofte', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'koften', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'kofter', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'Den blir feiret i alle land der det ',
				leadingText: ' samer.',
				correct: ['b'],
				sound: session49_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'bo', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'bor', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'bodd', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Mange ',
				leadingText: ' det samiske flagget.',
				sound: session49_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'heise', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'heiser',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'heist',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},

			{
				id: 6,
				precedingText: 'En samisk ',
				leadingText: ' laget flagget.',
				sound: session49_audio6,
				correct: ['a'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kunstner',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kunstneren',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kunstnere',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session50 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Hammeren ble ',
				leadingText: ' av store, tunge steiner.',
				correct: ['c'],
				sound: session50_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'lage', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'lager', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'laget', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: 'Menneskene brukte steinvåpen til å ',
				leadingText: ' på dyr',
				correct: ['a'],
				sound: session50_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'jakte', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'jakter ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'jaktet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De levde i små ',
				leadingText: ' ',
				correct: ['c'],
				sound: session50_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'gruppe', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'gruppen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'grupper', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'De var hele tiden på ',
				leadingText: ' ',
				correct: ['a'],
				sound: session50_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'reise', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'reisen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'reiser', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'De måtte ',
				leadingText: ' på seg for å finne mat.',
				sound: session50_audio5,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'flytte', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'flytter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'flyttet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},

			{
				id: 6,
				precedingText: 'Det var ingen ',
				leadingText: ' eller landsbyer enda.',
				sound: session50_audio6,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'byen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'byer',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'byene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session51 = [
	{
		words: [
			{
				id: 1,
				precedingText: '',
				leadingText: ' var lette å ta ned.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'Huset', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'Husene', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: 'De ',
				leadingText: ' laget av tre og hauger med stein.',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'være', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'er ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'var',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Steinaldermenneskene ',
				leadingText: ' på alle typer dyr.',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'jakte', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'jakter', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'jaktet', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'Noen av ',
				leadingText: ' finnes ikke lenger.',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'dyr', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'dyret', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'dyrene', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'De lærte også å lage enkle ',
				leadingText: '',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'båt', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'båten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'båter',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},

			{
				id: 6,
				precedingText: 'Folk i steinalderen ',
				leadingText: ' ikke bare kjøtt.',
				sound: session51_audio6,
				correct: ['c'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'spise',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'spiser',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'spiste',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];
export const session52 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Folk i steinalderen lette også etter frukt og ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'nøtt', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'nøtten', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'nøtter', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: 'De lærte å ',
				leadingText: ' mat over bål.',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'lage', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'lager ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'laget',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Dette var veldig ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'viktig', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'viktigere', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'viktigst', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Jorden var i en ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'istid', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'istiden', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'istider', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'De ',
				leadingText: ' skinn og pels fra dyrene',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'bruke', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'bruker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brukte',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session53 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Menneskene brukte bronse for å ',
				leadingText: ' redskap og våpen.',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'lage', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'lager', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'laget', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 2,
				precedingText: 'Bronse blir laget ved å ',
				leadingText: ' kobber og tinn.',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'blande', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'blander ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'blandet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'For å utvinne det, måtte man ',
				leadingText: ' opp stein.',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{ id: 2, letter: 'a', label: 'varmer', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'b', label: 'varmet', isAnswered: false, isCorrect: false },
					{ id: 1, letter: 'c', label: 'varme', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'Bronse er et hardt, rødbrunt og blankt ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 3, letter: 'a', label: 'metallene', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'metallet', isAnswered: false, isCorrect: false },
					{ id: 1, letter: 'c', label: 'metall', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Bronsen kan bli varmet opp og banket i riktig ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'form', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'formen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'formene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session54 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Sverd ble ',
				leadingText: ' i kamp.',
				correct: ['c'],
				sound: session51_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'bruke', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'bruker', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'brukt', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 2,
				precedingText: 'Noen bronseredskap ble brukt til å ',
				leadingText: ' mat.',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'lage', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'lager ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'laget',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Sigder ble brukt til å ',
				leadingText: ' planter.',
				correct: ['a'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'kutte', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'kutter', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'kuttet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'En ',
				leadingText: ' er en type graveredskap',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'plog', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'plogen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'ploger', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Store ',
				leadingText: ' dro plogen.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'dyret', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'dyr',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'dyrene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Det er vanskelig å si nøyaktig når ',
				leadingText: ' startet',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bronsealder',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bronsealderen',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bronsealdre',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session55 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Det kom mange ',
				leadingText: ' i bronsealderen.',
				correct: ['c'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'oppfinnelse',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'oppfinnelsen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'oppfinnelser',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'En av disse var ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'skrive', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'skriver ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'skriving',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Jordbruket ble også ',
				leadingText: ' .',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'oppfinne', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'oppfinner', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'oppfunnet', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'Folk levde ikke lenger som jegere og ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'sanke', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'sanker', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'sankere', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'En ',
				leadingText: ' av metall kalles for legering',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'blanding', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'blandingen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'blandinger',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Skrifter fra bronsealderen har blitt ',
				leadingText: ' over hele verden',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'finne',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'finner',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'funnet',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session56 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Flere ',
				leadingText: ' ble bofaste.',
				correct: ['c'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'menneske',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'mennesket',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'mennesker',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Menneskene ',
				leadingText: ' ofte i langhus.',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'bo', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'bor ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bodde',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De dyrket jorden og ',
				leadingText: ' husdyr.',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'ha', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'har', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'hadde', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'Det oppsto landsbyer og ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'by', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'byen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'byer', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Byer måtte beskyttes av konger og ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'soldat', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'soldaten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'soldater',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session57 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Jern er et blankt, gråfarget ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'metall',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'metallet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'metaller',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det kan man finne i ulike ',
				leadingText: ' og i myrer.',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'stein', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'steinen ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'steiner',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Med mynter kunne folk kjøpe og ',
				leadingText: ' ting.',
				correct: ['a'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'selge', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'selger', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'solgt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Jern kan man ',
				leadingText: ' som man vil.',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'forme', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'former', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'formet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Sag og hammer ble ',
				leadingText: ' når man skulle bygge ting.',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'bruke', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'bruker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brukt',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Den kunne knuse korn til ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'mel', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'melet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session58 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Disse skipene var lange og ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'smale',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'smalere',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'smaleste',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Vikingene var bønder og ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'kriger', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'krigeren ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'krigere',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De seilte til andre land og ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'krige', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'kriger', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'kriget', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'Noen skip hadde også ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'seil', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'seilet', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'seilene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'De stjal landet deres og verdifulle ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'ting', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'tingen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tingene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Noen vikinger ',
				leadingText: ' til nye land for å bosette seg.',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'reise', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'reiser',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'reiste',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session59 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Ørn er en',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'rovfugl',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'rovfuglen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'rovfugler',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Ørner ',
				leadingText: ' på fisk og pattedyr.',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'jakte', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'jakter ',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'jaktet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De er utmerkede ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'jeger', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'jegeren', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'jegere', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'Ørner bygger rede for å ',
				leadingText: ' egg.',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'legge', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'legger', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'lagt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Ørner legger ofte to ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'egg', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'egget',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'eggene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Ørner ',
				leadingText: ' også et svært godt syn.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'ha', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'har',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hadde',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session60 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Noen har kaniner som ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kjæledyr',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kjæledyret',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjæledyrene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Kaniner spiser bare ',
				leadingText: ' og grønnsaker.',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'plante', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'planten ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'planter',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De har store ører og ',
				leadingText: ' godt.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'høre', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'hører', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'hørte', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'De graver i bakken for å ',
				leadingText: ' rede.',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'lage', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'lager', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'laget', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Kaniner har ',
				leadingText: ' holdt som kjæledyr.',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'bli', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'blir',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'blitt',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Kaniner er dyktige ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'graver', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'graveren',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'gravere',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session61 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Rein er en ',
				leadingText: ' hjort.',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'type',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'typer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'typene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Hannene kalles for ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'bukk', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'bukken ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bukker',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Fargen på pelsen ',
				leadingText: ' ',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'variere', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'varierer', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'varierte', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'De blir ',
				leadingText: ' på av ulv og bjørn.',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'jakte', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'jakter', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'jaktet', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Bukkene ',
				leadingText: ' geviret til å slåss.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'bruke', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'bruker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brukt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Reinsdyr ',
				leadingText: ' byttedyr.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{ id: 1, letter: 'a', label: 'være', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'er',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vært',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session62 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Isbjørn er den ',
				leadingText: ' bjørne-arten.',
				correct: ['c'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'store',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'større',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'største',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Den ',
				leadingText: ' og sel.',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'spis', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'spiser ',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'spist',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Ungene ',
				leadingText: ' født inne i hiet.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'bli', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'blir', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'blitt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Isbjørn er ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'rovdyr', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'rovdyret', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'rovdyrene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Isbjørn føder ',
				leadingText: ' sine i hi.',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'ungen', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'unge',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'ungene',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Isbjørner sniker seg innpå ',
				leadingText: ' som sover.',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{ id: 1, letter: 'a', label: 'sel', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'selen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'seler',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session63 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Politiet sørger for at alle ',
				leadingText: ' loven.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'følge',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'følger',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'fulgt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'De arresterer ',
				leadingText: ' de tror er skyldige.',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'person', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'personen ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'personer',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De ',
				leadingText: ' også til når noen er i nød.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'hjelpe', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'hjelper', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'hjulpet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Politiet ',
				leadingText: ' på en politistasjon.',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'jobbe', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'jobber', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'jobbet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'De fleste politifolk bruker ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{ id: 1, letter: 'a', label: 'uniform', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'uniformen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'uniformene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'De er ofte ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'etterforsker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'etterforskeren',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'etterforskere',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session64 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Brann-konstabler ',
				leadingText: ' branner.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'slokke',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'slokker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'slokket',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Alle bruker tunge klær for å ',
				leadingText: ' seg.',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'beskytte', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'beskytter ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'beskyttet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De fleste brannbiler er  ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'lastebil', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'lastebilen',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 3, letter: 'c', label: 'lastebiler', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'Brannbilene har brannslanger og ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'stige', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'stigen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'stiger', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Brannkonstabler holder til på en ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'brannstasjon',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'brannstasjonen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brannstasjoner',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'De ',
				leadingText: ' også båter.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bruke',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bruker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brukt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session65 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En frisør ',
				leadingText: ' og former hår.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'klippe',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'klipper',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'klippet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'De fleste',
				leadingText: ' jobber i en salong.',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'frisør', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'frisøren ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'frisører',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Frisører kan lage  ',
				leadingText: ' i håret.',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'krøll', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'krøllen',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 3, letter: 'c', label: 'krøller', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'Frisørene bruker hårfarger til å ',
				leadingText: ' hår.',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'farge', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'farger', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'farget', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'De kalles ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'klorist',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kloristen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kolorister',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Også kvinner ',
				leadingText: ' til en barberer for å klippe seg.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'gå',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'går',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'gikk',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session66 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En sykepleier ',
				leadingText: ' de som er syke og skadde.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'hjelpe',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hjelper',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hjulpet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Sykepleiere ',
				leadingText: ' på sykehus og lege-kontorer.',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'jobbe', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'jobber ',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'jobbet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De kan gi medisiner og sette  ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'sprøyte', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'sprøyten',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 3, letter: 'c', label: 'sprøyter', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'Sykepleiere bruker uniformer i forskjellige ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'farge', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'farget', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'farger', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Hver dag ',
				leadingText: ' sykepleiere mange verktøy.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bruke',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bruker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brukte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'De bruker  gode ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'sko',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skoen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'skoene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session67 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En veterinær er en ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'dyrlege',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'dyrlegen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'dyrleger',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Veterinærer ',
				leadingText: ' på dyresykehus.',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'jobbe', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'jobber ',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'jobbet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Dyr kan ikke  ',
				leadingText: ' hvordan de har det.',
				correct: ['a'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'si', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'sier',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 3, letter: 'c', label: 'sa', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'De bruker instrumenter og ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'test', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'testen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'tester', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'To av disse metodene er røntgen og ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'blodprøve',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'blodprøven',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'blodprøver',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session68 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En kjøkkensjef er en profesjonell ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kokk',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kokken',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kokker',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det finnes ulike typer ',
				leadingText: '',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'kokk', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'kokken ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kokker',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Kokker  ',
				leadingText: ' utstyr som visper og blendere.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'bruke', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'bruker',
						isAnswered: false,
						isCorrect: true,
					},
					{ id: 3, letter: 'c', label: 'brukt', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'De må ',
				leadingText: ' maten til å se tiltalende ut ved servering. ',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'få', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'får', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'fikk', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Andre ',
				leadingText: ' på kokkeskoler.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'gå',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'går',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'gått',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Kjøkkensjefer bruker vanligvis en hvit ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'uniform',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'uniformen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'uniformer',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session69 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En røreleger jobber med rør som ',
				leadingText: ' vann.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'føre',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'fører',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'ført',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Avfallet kan ',
				leadingText: ' vann fra avløp eller kloakk.',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'være', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'var ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vært',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De kan ha vanntette  ',
				leadingText: ' ',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kjeledress',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kjeledresser',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjeledressene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'De bruker også briller og ',
				leadingText: ' ',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'hanske', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'hansker', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'hanskene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Rølegere installerer og ',
				leadingText: ' rør.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'reparere',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'reparerer',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'reparert',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Rølegere ',
				leadingText: ' ofte våte og skitne når de jobber.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ble',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'blir',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'blitt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session70 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En lastebilsjåfør ',
				leadingText: ' lastebil.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kjøre',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kjører',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjørt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det er forskjellige ',
				leadingText: ' lastebiler.',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'type', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'typer ',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'typene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Noen sjåfører  ',
				leadingText: ' astebilen de kjører.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'eie',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'eier',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'eid',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Varene de ',
				leadingText: ' kalles gods.',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'frakte', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'frakter', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'fraktet', isAnswered: false, isCorrect: false },
				],
			},
			/* {
				id: 5,
				precedingText: 'De frakter godset i et bestemt ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'land',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'landet',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'landene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			}, */
			{
				id: 5,
				precedingText: 'Noen lastebilsjåfører frakter ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'båt',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'båten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'båter',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session71 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En skuespiller er en som ',
				leadingText: ' i filmer og på teater.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'spille',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'spiller',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'spilt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det er forskjellige typer ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skuespiller',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skuespilleren ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'skuespillere',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Noen bruker bare  ',
				leadingText: ' sine.',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'stemme',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'stemmen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'stemmene',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Vil du bli skuespiller kan du ',
				leadingText: ' på drama-skole.',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'gå', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'går', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'gikk', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Skuespillere må ',
				leadingText: ' på audition.',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'delta',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'deltar',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'deltok',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Da må de framføre en del av et ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skuespill',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skuespillet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'skuespillene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session72 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En ',
				leadingText: ' er en som fører luft-fartøy.',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'pilot',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'piloten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'piloter',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Piloter ',
				leadingText: ' fly og helikopter',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'fly',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'flyr ',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'flydd',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'En pilot må ha et spesielt  ',
				leadingText: '',
				correct: ['a'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'sertifikat',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'sertifikatet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sertifikatene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'De kjører rutefly og  ',
				leadingText: ' passasjerer.',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'frakte', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'frakter', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'fraktet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'En pilot med lite erfaring  ',
				leadingText: ' med å fly mindre fly.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'starte',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'starter',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'startet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Piloter må passe på at ',
				leadingText: ' er trygge å fly.',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'fly',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'flyet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'flyene',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session73 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Et fly er et luft-fartøy med faste  ',
				leadingText: ' ',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'vingen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'vinger',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vingene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det blir flydd av en utdannet ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'pilot',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'piloten ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'piloter',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Militæret ',
				leadingText: ' dem til kamp.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bruke',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bruker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brukt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Luft-ambulanser kan  ',
				leadingText: ' syke mennesker.',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'frakte', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'frakter', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'fraktet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Det første ',
				leadingText: ' var et biplan.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'fly',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'flyet',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'flyene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Orkanjegere flyr inn i tordenvær for å ',
				leadingText: ' værdata.',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'samle',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'samler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'samlet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session74 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En motorsykkel har to hjul og en  ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'motor',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'motoren',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'motorer',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det krever øvelse for å ',
				leadingText: ' trygt på veien',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kjøre',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kjører ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjørte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Man må også ',
				leadingText: ' hjelm.',
				correct: ['a'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bruke',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bruker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brukt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Noen bruker ',
				leadingText: ' for moro skyld og til sport.',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'motorsykkel',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'motorsykkelen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'motorsykler',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Noen er ',
				leadingText: ' for å kjøre på sand.',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'lage',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'lager',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'laget',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Noen har  ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'sidevogn',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'sidevognen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sidevognene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session75 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En ',
				leadingText: ' er et slags vannfartøy.',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'båt',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'båten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'båtene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det er som et ',
				leadingText: ' men mindre.',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skip',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skipet ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'skipene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Den kan være ',
				leadingText: ' av tre.',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'lage',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'lager',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'laget',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Båter beveger seg over vannet på forskjellige ',
				leadingText: ' ',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'måte', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'måter', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'måtene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Hver del av en båt  ',
				leadingText: ' et navn.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ha',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'har',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hatt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Båter kan   ',
				leadingText: ' som transportmiddel.',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bruke',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bruker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brukes',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session76 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En lastebil er et stort ',
				leadingText: '',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kjøretøy',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kjøretøyet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjøretøyene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'En lastebil kan ',
				leadingText: ' vogner.',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'trekke',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'trekker ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'trakk',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Sjåføren ',
				leadingText: ' foran i førerhuset.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'sitte',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'sitter',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sittet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Dumpere har ',
				leadingText: ' men ikke tak.',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'side', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'siden', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'sider', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Tankbiler har vanligvis en lang, rund ',
				leadingText: ' ',
				sound: session51_audio5,
				correct: ['a'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'tank',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'tanken',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tanker',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Betongbiler ',
				leadingText: ' betong i en trommel.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'frakte',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'frakter',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'frakt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session77 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Et tog ',
				leadingText: ' av et lokomotiv.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bestå',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'består',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bestod',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det trekker vogner på ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skinne',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skinnen ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'skinner',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Persontog ',
				leadingText: ' passasjerer.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'frakte',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'frakter',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'fraktet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Godstog frakter ',
				leadingText: ' eller gods.',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'last', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'lasten', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'lastene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Noen tog kjører over ',
				leadingText: ' våre.',
				sound: session51_audio5,
				correct: ['c'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'hode',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hodet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hodene',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Godstog ',
				leadingText: ' vogner for ulike typer gods.',
				sound: session51_audio5,
				correct: ['b'],
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ha',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'har',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hatt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session78 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En bil er et kjøretøy med fire ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'hjul',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hjulet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hjulene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Et eldre ',
				leadingText: ' for bil er automobil.',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ord',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ordet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'ordene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'En person må ha førerkort for å ',
				leadingText: ' den.',
				correct: ['a'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kjøre',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kjører',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjørte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'De fleste biler ',
				leadingText: ' bensin eller diesel.',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'bruke', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'bruker', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'brukte', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Bilen ble ',
				leadingText: ' av Carl Benz i 1886.',
				correct: ['c'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'oppfinne',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'oppfinner',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'oppfunnet',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Nå ',
				leadingText: ' det omtrent én milliard biler i verden.',
				correct: ['a'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'er',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'var',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vært',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session79 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En buss er et stort ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kjøretøy',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kjøretøyet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjøretøyene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'En bussjåfør må ',
				leadingText: 'et spesielt førerkort. ',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ha',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'har',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hadde',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Det finnes mange typer ',
				leadingText: ' ',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'buss',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'busser',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bussene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'En ',
				leadingText: ' kjører barn til og fra skolen.',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'skolebuss', isAnswered: false, isCorrect: true },
					{
						id: 2,
						letter: 'b',
						label: 'skolebussen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'skolebusser',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Folk må ',
				leadingText: 'or å bruke bussen.',
				correct: ['a'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'betale',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'betaler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'betalt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'En skolebuss ',
				leadingText: 'barn til og fra skolen. ',
				correct: ['b'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'frakte',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'frakter',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];
export const session80 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Traktoren ',
				leadingText: ' landbruksutstyr.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'trekke',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'trekker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'trekt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Traktorer kan også ',
				leadingText: ' til brøyting.',
				correct: ['c'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bruke',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bruker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brukes',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Traktorer kan også hjelpe til med å ',
				leadingText: ' frø.',
				correct: ['a'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'så',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'sår',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sådd',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Mange traktorer har to store ',
				leadingText: ' ',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'bakhjulet', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'bakhjul', isAnswered: false, isCorrect: true },
					{
						id: 3,
						letter: 'c',
						label: 'bakhjulene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Noen traktorer har ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'belte',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'beltet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'belter',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Traktorer kan også legge høy i ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ball',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ballen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'baller',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];
export const session81 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Elsparkesykler har en liten, elektrisk ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'motor',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'motoren',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'motorer',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Føreren trenger ikke å ',
				leadingText: ' for å holde sparkesykkelen i gang.',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'sparke',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'sparker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sparket',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Noen ',
				leadingText: ' har aldersgrenser for føreren.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'landet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'land',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'landene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Det er noen elsparkesykler som kan ',
				leadingText: ' sammen.',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'slå', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'slår', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'slås', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Den første motoriserte sparkesykkelen ble ',
				leadingText: ' i 1915.',
				correct: ['c'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'lage',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'lager',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'laget',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 6,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session82 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Folk bruker snøskutere for å ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'reise',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'reiser',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'reiste',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'De ',
				leadingText: ' dem også til kappløp.',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bruke',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bruker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brukte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'En snøskuter kan ',
				leadingText: ' over vann.',
				correct: ['a'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kjøre',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kjører',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjørte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Den har et par ',
				leadingText: ' foran.',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'ski', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'skien', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'skiene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Den har et ',
				leadingText: ' bak.',
				correct: ['a'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'belte',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'beltet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'belter',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Det kreves ',
				leadingText: ' for å kjøre skuter.',
				correct: ['a'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'førerkort',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'førerkortet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'førerkortene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session83 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Alle som har flaggstang ',
				leadingText: ' flagget.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'heise',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'heiser',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'heist',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Tidlig på ',
				leadingText: ' går skoleelever i tog.',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'dag',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'dagen',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'dager',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Etter toget ',
				leadingText: ' mange på skolen.',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'samle',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'samler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'samles',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'De spiser pølser og ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'is', isAnswered: false, isCorrect: true },
					{ id: 2, letter: 'b', label: 'isen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'isene', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'De leker mange forskjellige ',
				leadingText: ' ',
				correct: ['b'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'leke',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'leker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'lekene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Vi feirer at Norge ',
				leadingText: ' et fritt land.',
				correct: ['b'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'være',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'er',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'var',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session84 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Før 1814 ble Norge ',
				leadingText: ' av Danmark.',
				correct: ['c'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'styre',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'styrer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'styrt',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Mange nordmenn ville at Norge skulle ',
				leadingText: ' over seg selv.',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bestemme',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bestemmer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bestemte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De møttes på Eidsvoll for å ',
				leadingText: ' sin egen grunnlov.',
				correct: ['a'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'lage',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'lager',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'laget',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Den nye loven ble ',
				leadingText: ' under 17.mai 1814.',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'skrive', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'skriver', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'skrevet', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Vi feirer 17.mai til minne om da ',
				leadingText: ' ble til på Eidsvoll.',
				correct: ['b'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'grunnlov',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'grunnloven',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'grunnlovene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: '17.mai feires over hele ',
				leadingText: ' ',
				correct: ['b'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'land',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'landet',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'landene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session85 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Våren er ',
				leadingText: ' når planter dukker opp igjen etter vinteren.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'tid',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'tiden',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tider',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Hestehov er en gul ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'vårblomst',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'vårblomsten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vårblomster',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Den ',
				leadingText: ' litt på en løvetann.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ligne',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ligner',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'lignet',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'På latin ',
				leadingText: ' den tussilago farfara.',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'hete', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'heter', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'het', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Hestehov ',
				leadingText: ' langs veikanter og stier.',
				correct: ['b'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'vokse',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'vokser',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vokst',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Hestehov blir noen ',
				leadingText: ' benyttet som urtemedisin.',
				correct: ['b'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'gang',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ganger',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'gangene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session86 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'De første humlene som ',
				leadingText: ' er dronninger.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'våkne',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'våkner',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'våknet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'De leter etter et sted der de lager et ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bol',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bolet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bolene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Du kan hjelpe ',
				leadingText: ' ved å gi den sukkervann eller et bananskall.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'humle',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'humlen',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'humler',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Redder du en dronning, ',
				leadingText: ' du hundrevis av humler og hjelper tusenvis av planter.',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'redde', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'redder', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'reddet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Humler, bier, sommerfugler og biller er eksempler på ',
				leadingText: ' som bestøver blomster.',
				correct: ['c'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'insekt',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'insektet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'insekter',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Uten disse hjelperne vil det bli vanskelig å ',
				leadingText: ' mat.',
				correct: ['a'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'produsere',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'produserer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'produserte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session87 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Insekter ',
				leadingText: ' planter til mat.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'trenge',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'trenger',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'trengte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Mange insekter lever og ',
				leadingText: ' også i planter.',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bo',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bor',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bodde',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Planter trenger insekter for å ',
				leadingText: ' pollen fra blomst til blomst.',
				correct: ['a'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'frakte',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'frakter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'fraktet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Dette ',
				leadingText: ' planter å lage frukt og frø.',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'hjelpe', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'hjelper', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'hjapl', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Det er dette ',
				leadingText: ' maur og humler gjør om våren.',
				correct: ['c'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bie',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bien',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bier',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Insekter frakter frø til nye ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'sted',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'stedet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'steder',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];
export const session88 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Mye av plasten ',
				leadingText: ' i havet over hele verden',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'havne',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'havner',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'havnet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Mye plast ',
				leadingText: ' opp i strandsonen overalt i verden.',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ende',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ender',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'endte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Mange skoler ',
				leadingText: ' i strandrydding.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'delta',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'deltar',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'deltok',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Elevene gjør en veldig viktig jobb når de ',
				leadingText: ' i naturen.',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'rydde', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'rydder', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'ryddet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Norge er ett av få ',
				leadingText: ' som har panteordning for plastflasker og brusbokser.',
				correct: ['a'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'land',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'landet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'landene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Dette er en god ',
				leadingText: ' for å bevare naturen.',
				correct: ['a'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'idè',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'idèen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'idèene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session89 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Forurensning er når naturen, ',
				leadingText: ' eller luften blir skitten.',
				correct: ['b'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'vann',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'vannet',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vannene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det kan ',
				leadingText: ' oss syke.',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'gjøre',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'gjør',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'gjort',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Det kan gjøre ',
				leadingText: ' og dyr syke.',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'plante',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'planten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'planter',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Forurensning er det stort sett mennesker som ',
				leadingText: ' for.',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'sørge', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'sørger', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'sørget', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'En ',
				leadingText: ' kan fylle luften med røyk og støv.',
				correct: ['a'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'vulkan',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'vulkanen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vulkaner',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Noen ting som vi ',
				leadingText: ' kan vi bruke på nye måter.',
				correct: ['b'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kaste',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kaster',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kastet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session90 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Nisen er en liten ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'tannhval',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'tannhvalen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tannhvalene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Niser ',
				leadingText: ' ofte forvekslet med delfiner.',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bli',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'blir',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'blitt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De ',
				leadingText: ' ganske like ut.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'se',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ser',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'så',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'De fleste ',
				leadingText: ' lever i små grupper.',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'nise', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'nisen', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'niser', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Nisen har et variert kosthold av fisk, akkar og ulike ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'krepsdyr',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'krepsdyret',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'krepsdyrene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Nisen ',
				leadingText: ' 10-20 år i det fri.',
				correct: ['b'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'leve',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'lever',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'levde',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session91 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Spekkhoggeren er et ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'pattedyr',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'pattedyret',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'pattedyrene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'De ',
				leadingText: ' på fisk, hvalross, pingvin, hai og andre hvaler.',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'jakte',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'jakter',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'jaktet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'En spekkhogger har sterke og skarpe ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'tann',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'tannen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tenner',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Spekkhoggeren ',
				leadingText: ' enorm.',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'være', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'er', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'vært', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Spekkhoggeren kan ',
				leadingText: ' pusten i opptil 15 minutter.',
				correct: ['a'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'holde',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'holder',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'holdt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'De lager en ',
				leadingText: ' og så lytter de etter ekkoet for å finne byttet.',
				correct: ['a'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'lyd',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'lyden',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'lyder',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session92 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Delfiner er ',
				leadingText: ' ',
				correct: ['c'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'rovdyret',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'rovdyrene',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'rovdyr',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Noen delfiner ',
				leadingText: ' i elver.',
				correct: ['b'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'leve',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'lever',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'levde',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De spiser andre ',
				leadingText: ' ',
				correct: ['a'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'dyr',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'dyret',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'dyrene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Delfiner ',
				leadingText: ' gjennom pustehull.',
				correct: ['b'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'puste', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'puster', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'pustet', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Noen delfiner ',
				leadingText: ' rosa',
				correct: ['b'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'være',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: ' er ',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vært',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: 'Noen ',
				leadingText: ' dør ved et uhell.',
				correct: ['c'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'delfin',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'delfinen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'delfiner',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];
export const session93 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'De har ',
				leadingText: ' øyne og godt syn.',
				correct: ['a'],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'store',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'større',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'største',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Blekkspruter kan ',
				leadingText: ' veldig fort.',
				correct: ['a'],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'svømme',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'svømmer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'svømte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Alle blekkspruter ',
				leadingText: ' gift når de biter noe.',
				correct: ['b'],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ha',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'har',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hatt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Blekkspruter ',
				leadingText: ' noen ganger blekkfisk.',
				correct: ['c'],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: 'kalle', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'kaller', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'kalles', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 5,
				precedingText: 'Blekkspruter har tre ',
				leadingText: ' ',
				correct: ['b'],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'hjerte',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hjerter',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hjertene',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session94 = [
	{
		words: [
			{
				id: 1,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: '', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: '', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: '', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session95 = [
	{
		words: [
			{
				id: 1,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio1,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio2,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio3,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio4,
				choices: [
					{ id: 1, letter: 'a', label: '', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: '', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: '', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 6,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: session51_audio5,
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
