export const session1 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session2 = [
	{
		allWords: [
			{ word: 'flere', isCorrect: false, synonym: 'mange', isAnswered: false, isSelected: false },
			{ word: 'store', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
			{ word: 'mange', isCorrect: false, synonym: 'flere', isAnswered: false, isSelected: false },
		],
	},
];
export const session3 = [
	{
		allWords: [
			{ word: 'Skuffet', isCorrect: false, synonym: 'lei seg', isAnswered: false, isSelected: false },
			{ word: 'lei seg', isCorrect: false, synonym: 'Skuffet', isAnswered: false, isSelected: false },
			{ word: 'Overrasket', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session4 = [
	{
		allWords: [
			{ word: 'de fleste', isCorrect: false, synonym: 'mange', isAnswered: false, isSelected: false },
			{ word: 'mange', isCorrect: false, synonym: 'de fleste', isAnswered: false, isSelected: false },
			{ word: 'ingen', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session5 = [
	{
		allWords: [
			{ word: 'kikket', isCorrect: false, synonym: 'så', isAnswered: false, isSelected: false },
			{ word: 'slo', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
			{ word: 'så', isCorrect: false, synonym: 'kikket', isAnswered: false, isSelected: false },
		],
	},
];
export const session6 = [
	{
		allWords: [
			{ word: 'se', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
			{ word: 'mase', isCorrect: false, synonym: 'gnåle', isAnswered: false, isSelected: false },
			{ word: 'gnåle', isCorrect: false, synonym: 'mase', isAnswered: false, isSelected: false },
		],
	},
];
export const session7 = [
	{
		allWords: [
			{ word: 'hestemøkk', isCorrect: false, synonym: 'hestebæsj', isAnswered: false, isSelected: false },
			{ word: 'hestebæsj', isCorrect: false, synonym: 'hestemøkk', isAnswered: false, isSelected: false },
			{ word: 'hestehale', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session8 = [
	{
		allWords: [
			{ word: 'lure', isCorrect: false, synonym: 'jukse', isAnswered: false, isSelected: false },
			{ word: 'redd', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
			{ word: 'jukse', isCorrect: false, synonym: 'lure', isAnswered: false, isSelected: false },
		],
	},
];
export const session9 = [
	{
		allWords: [
			{ word: 'knise', isCorrect: false, synonym: 'le', isAnswered: false, isSelected: false },
			{ word: 'le', isCorrect: false, synonym: 'knise', isAnswered: false, isSelected: false },
			{ word: 'lei seg', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session10 = [
	{
		allWords: [
			{
				word: 'Edel steiner',
				isCorrect: false,
				synonym: 'diamanter',
				isAnswered: false,
				isSelected: false,
			},
			{ word: 'gråstein', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
			{
				word: 'diamanter',
				isCorrect: false,
				synonym: 'Edel steiner',
				isAnswered: false,
				isSelected: false,
			},
		],
	},
];
export const session11 = [
	{
		allWords: [
			{ word: 'skummel', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
			{ word: 'merkelig', isCorrect: false, synonym: 'rar', isAnswered: false, isSelected: false },
			{ word: 'rar', isCorrect: false, synonym: 'merkelig', isAnswered: false, isSelected: false },
		],
	},
];
export const session12 = [
	{
		allWords: [
			{ word: 'redd', isCorrect: false, synonym: 'nervøs', isAnswered: false, isSelected: false },
			{ word: 'nervøs', isCorrect: false, synonym: 'redd', isAnswered: false, isSelected: false },
			{ word: 'sliten', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session13 = [
	{
		allWords: [
			{ word: 'fisk', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
			{ word: 'sjørøver', isCorrect: false, synonym: 'pirat', isAnswered: false, isSelected: false },
			{ word: 'pirat', isCorrect: false, synonym: 'sjørøver', isAnswered: false, isSelected: false },
		],
	},
];
export const session14 = [
	{
		allWords: [
			{ word: 'runer', isCorrect: false, synonym: 'skrifttegn', isAnswered: false, isSelected: false },
			{ word: 'stjele', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
			{ word: 'skrifttegn', isCorrect: false, synonym: 'runer', isAnswered: false, isSelected: false },
		],
	},
];
export const session15 = [
	{
		allWords: [
			{ word: 'redd', isCorrect: false, synonym: 'feig', isAnswered: false, isSelected: false },
			{ word: 'feig', isCorrect: false, synonym: 'redd', isAnswered: false, isSelected: false },
			{ word: 'modig', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session16 = [
	{
		allWords: [
			{ word: 'Gap opp', isCorrect: false, synonym: 'Åpne munnen', isAnswered: false, isSelected: false },
			{ word: 'Se på tennene', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
			{ word: 'Åpne munnen', isCorrect: false, synonym: 'Gap opp', isAnswered: false, isSelected: false },
		],
	},
];
export const session17 = [
	{
		allWords: [
			{ word: 'prøver', isCorrect: false, synonym: 'tester', isAnswered: false, isSelected: false },
			{ word: 'tester', isCorrect: false, synonym: 'prøver', isAnswered: false, isSelected: false },
			{ word: 'redd', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session18 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session19 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session20 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session21 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session22 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session23 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session24 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session25 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session26 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session27 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session28 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session29 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
export const session30 = [
	{
		allWords: [
			{ word: 'løpe', isCorrect: false, synonym: 'jogge', isAnswered: false, isSelected: false },
			{ word: 'jogge', isCorrect: false, synonym: 'løpe', isAnswered: false, isSelected: false },
			{ word: 'sloss', isCorrect: false, synonym: null, isAnswered: false, isSelected: false },
		],
	},
];
