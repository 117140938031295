import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import './App.css';
import Login from './pages/Login/';
import ReadWellTest from './pages/ReadWellTest';

const App = () => {
	return (
		<Router>
			<div className='pageWrapper'>
				<Routes>
					<Route path='/' element={<Login />} />
					<Route path='/login' element={<Login />} />

					<Route path='/test' element={<ReadWellTest />} />
				</Routes>
			</div>
		</Router>
	);
};

export default App;
