import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';
const updatedVersion = 26;

const migrations = {
	[updatedVersion]: (state) => {
		return {
			auth: state.auth,
		};
	},
};

const config = {
	key: 'Reading-well-storage',
	storage,
	whitelist: [],
	version: updatedVersion,
	migrage: createMigrate(migrations),
};

const reducer = persistReducer(config, rootReducer);
function configureStore() {
	const middlewares = [thunk];
	if (process.env.NODE_ENV !== 'production') {
		const createLogger = require('redux-logger').createLogger;
		const logger = createLogger();
		middlewares.push(logger);
	}
	const store = createStore(reducer, applyMiddleware(...middlewares));
	const persistor = persistStore(store);

	if (module.hot) {
		module.hot.accept(() => {
			const nextReducer = require('./reducers').default;
			store.replaceReducer(nextReducer);
		});
	}
	return { store, persistor };
}
const { store } = configureStore();
export default store;
