import React from 'react';
import { connect } from 'react-redux';
import * as data from './data/no-data';
import TimerCount from '../../../TimerCount';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
import { updateObject } from '../../../../utils/reducerUtils';
import AudioPlayer from '../../../AudioPlayer';
import introAudio from '../../../../assets/sounds/fill-blanks-intro.m4a';

import './styles.scss';
import intro from '../intro';

const mainArray = [];
const resultsGloabl = [];
const getAllSessionItems = (session) => {
	const indexValue = `session${session}`;
	const sessionItems = data[indexValue];
	return sessionItems;
};
class SuperlativeAdjectives extends React.Component {
	constructor(props) {
		super(props);
		this.audio = introAudio;
		this.sessionData = getAllSessionItems(this.props.selectedSession);
		const results = {};
		for (let i = 0; i < this.sessionData[0].testQuestions.length; i++) {
			let id = this.sessionData[0].testQuestions[i].id;

			const obj = { isAnswered: false, isCorrect: false };
			results[id] = '';
			mainArray.push(obj);
		}
		this.state = {
			sessionItems: this.sessionData,
			results,
			renderNext: false,
		};
	}
	handleKeyPress(e, correct, testQuestions, sessionIndex, index, id, nexCorrect, previousCorrect) {
		e.preventDefault(); // Ensure it is only this code that runs
		const value = e.target.value;
		if (value.toLowerCase() === correct.toLowerCase()) {
			this.props.flipCoin();
			this.updateResult(value, correct, { isCorrect: true });
			this.setResults(id);
			testQuestions[index].isAnswered = true;
			const updatedQuestions = testQuestions;
			this.sessionData[sessionIndex].testQuestions = updatedQuestions;
			this.setState({
				sessionItems: this.sessionData,
			});
		} else {
			if (index === 0 && value.toLowerCase() === nexCorrect.toLowerCase()) {
				this.props.recordMistake();
				this.updateResult(value, correct, { isCorrect: false });
			} else if (index === 1 && value.toLowerCase() === previousCorrect.toLowerCase()) {
				this.props.recordMistake();
				this.updateResult(value, correct, { isCorrect: false });
			}
		}
	}
	setResults(id) {
		const obj = [{ isAnswered: 1, isCorrect: 1 }];
		const { results } = this.state;
		this.setState({
			results: updateObject(results, {
				[id]: obj,
			}),
		});
	}
	updateResult(chosenAdjective, correctAdjective, status) {
		resultsGloabl.push({ answer: chosenAdjective, correct: correctAdjective, status });
	}
	render() {
		const { onNextStep } = this.props;
		const { sessionItems, results } = this.state;
		const allQuestionsAnswered = Object.keys(results).every((key) => results[key]);
		const isNextButtonVisible = allQuestionsAnswered;
		let audioPlayerRef;
		return (
			<div>
				<div className='rw-lp-intro'>
					<div className='rw-infobar'>
						<TimerCount
							duration={123}
							onComplete={() => {
								this.setState({ renderNext: true });
							}}
						/>
					</div>
					<div className='rw-intro-text'>
						<p>
							<AudioPlayer
								autoPlay
								inline
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								src={this.audio}
							/>
							Skriv inn ordene som mangler under bildene
						</p>
					</div>
				</div>
				<div className='rw-sa'>
					{sessionItems.map((item, sessionIndex) => {
						const { superlatives, solvedExample, testQuestions } = item;
						return (
							<div className='rw-superlatives'>
								<div
									className='rw-sa-content'
									key={`rw-sa-content-${sessionIndex}`}
								>
									{superlatives.map(
										(adjective, index) => {
											return (
												<div
													className='rw-sa-content__question'
													key={`rw-sa-content__question-${index}`}
												>
													{
														adjective
													}
												</div>
											);
										}
									)}
								</div>
								<div className='rw-sa-content-options'>
									<span className='rw-sa-img'>
										<img
											className='rw-sa-img__small'
											src={
												solvedExample.img
											}
										/>
									</span>

									<div className='rw-sa-content-options__option'>
										<p>
											{solvedExample.text}
											{` `}
											<span className='instruciton__highlight-sa'>
												{
													solvedExample.correctSuperlative
												}
											</span>
											{solvedExample.remainder
												? solvedExample.remainder
												: ''}
										</p>
									</div>
								</div>
								{testQuestions.map((testQuestion, index) => {
									const {
										id,
										questionText,
										img,
										defaultClass,
										correct,
										isAnswered,
									} = testQuestion;
									let nexCorrect = null;
									let previousCorrect = null;
									if (
										index === 0 &&
										testQuestions[index + 1]
									) {
										nexCorrect =
											testQuestions[
												index + 1
											].correct;
									} else if (
										index === 1 &&
										testQuestions[index - 1]
									) {
										previousCorrect =
											testQuestions[
												index - 1
											].correct;
									}
									return (
										<div
											className='rw-sa-content-options'
											key={`rw-sa-content-options-${index}`}
										>
											<span
												className='rw-sa-img'
												key={`rw-sa-img-${index}`}
											>
												<img
													className={`rw-sa-img__${defaultClass}`}
													key={`rw-sa-img__${index}`}
													src={
														img
													}
												/>
											</span>

											<div
												className='rw-sa-content-options__option'
												key={`rrw-sa-content-options__option_${index}`}
											>
												<p>
													{
														questionText
													}
													{` `}

													{!isAnswered && (
														<span
															className='instruciton__highlight-sa'
															key={`instruciton__highlight-sa_${index}`}
														>
															<input
																className='rw-sa-input'
																key={`irw-sa-input_${index}`}
																type='text'
																onKeyUp={(
																	event
																) => {
																	this.handleKeyPress(
																		event,
																		correct,
																		testQuestions,
																		sessionIndex,
																		index,
																		id,
																		nexCorrect,
																		previousCorrect
																	);
																}}
															/>
														</span>
													)}
													{isAnswered && (
														<span
															className='instruciton__highlight'
															key={`instruciton__highlight${index}`}
														>
															{
																correct
															}
														</span>
													)}
													{testQuestion.remainder ? (
														<span className='rw-sla-remainder'>
															{
																testQuestion.remainder
															}
														</span>
													) : (
														''
													)}
												</p>
											</div>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
				{(isNextButtonVisible || this.state.renderNext || this.props.isSuperTeacher) && (
					<div className='rw-next-btn'>
						<button
							className='rw-btn bg-primary'
							onClick={() =>
								onNextStep(
									resultsGloabl,
									ALL_SUBTESTS.SUPERLATIVEADJECTIVES
								)
							}
						>
							{'Neste'}
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default SuperlativeAdjectives;
