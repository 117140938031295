import React from 'react';
import { connect } from 'react-redux';
import '../styles.scss';
import { goToNextStep } from '../../../../actions';
import AudioPlayer from '../../../AudioPlayer';
import TimerCount from '../../../TimerCount';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
import * as data from './data/no-data';

import { motion } from 'framer-motion';
import './styles.scss';

var counter = 0;
const resultsGloabl = [];
var consecutiveCorrect = 0;
var firstCorrectAttempt = true;

const getFlyingWords = (session) => {
	let sessionWords;
	const indexValue = `session${session}`;
	sessionWords = data[indexValue][0];
	const obj = {
		mainAudio: sessionWords.introAudio,
		targetWord: sessionWords.targetWord,
		instructionText: sessionWords.instructionText,
		discoverableWords: sessionWords.discoverableWords,
		flyingWords: sessionWords.flyingWords,
	};
	return obj;
};

let arrayVar = [];
const flyingPrefixes = [58, 59, 62, 63, 64, 65, 67, 68, 69, 70, 72, 77, 81, 83, 84, 85, 88, 90, 92];
const flyingSufixes = [57, 60, 61, 66, 71, 73, 74, 75, 76, 78, 79, 80, 82, 86, 87, 89, 93];

class FlyingWords extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
		this.myRef2 = React.createRef();
		this.allData = getFlyingWords(this.props.selectedSession);
		this.audio = this.allData.mainAudio;
		this.wordsToInsert = this.allData.flyingWords;
		this.discoverableWords = this.allData.discoverableWords;
		this.targetWord = this.allData.targetWord;
		this.instructionText = this.allData.instructionText;

		this.state = {
			isSubmitting: false,
			currentNestedStepIndex: 0,
			randomVariable: [],
			/* words: this.wordsToInsert, */
			words: this.wordsToInsert,
			hasStartedTest: true,
			timerRanOut: false,
			discoveries: 0,
			results: [],
		};
		this.startIntervel = this.startIntervel.bind(this);
	}
	componentDidMount() {
		/* words = this.wordsToInsert; */
		/* this.startIntervel(); */
	}

	startIntervel = async () => {
		setInterval(() => {
			if (this.wordsToInsert[counter]) this.moveItemsInsdieArray();
			counter++;
		}, 1050);
	};

	evaluateCorrectTargetWord(variable) {
		const targetWordLength = this.targetWord[0].length;
		if (this.props.selectedSession < 18) {
			if (variable.word.toLowerCase() === this.targetWord.toLowerCase()) {
				return true;
			}
		} else if (flyingPrefixes.includes(this.props.selectedSession)) {
			//prefixes
			for (let i = 0; i < this.targetWord.length; i++) {
				if (
					variable.word.slice(0, targetWordLength).toLowerCase() ===
					this.targetWord[0].toLowerCase()
				) {
					consecutiveCorrect = consecutiveCorrect + 1;
					return true;
				}
			}
		} else if (flyingSufixes.includes(this.props.selectedSession)) {
			//suffix ing
			for (let i = 0; i < this.targetWord.length; i++) {
				if (
					variable.word.slice(0).slice(-targetWordLength).toLowerCase() ===
					this.targetWord[0].toLowerCase()
				) {
					consecutiveCorrect = consecutiveCorrect + 1;
					return true;
				}
			}
		} else {
			for (let i = 0; i < this.targetWord.length; i++) {
				if (variable.word.toLowerCase() === this.targetWord[i].toLowerCase()) {
					consecutiveCorrect = consecutiveCorrect + 1;
					return true;
				}
			}
		}
		return false;
	}
	moveItemsInsdieArray() {
		arrayVar.push(this.wordsToInsert[counter]);
		this.setState({
			randomVariable: arrayVar,
		});
	}
	updateResults(word, target, status) {
		resultsGloabl.push({ chosenWord: word, correctWord: target, status });
	}

	render() {
		const { onNextStep, selectedSession } = this.props;
		let audioPlayerRef;
		return (
			<div>
				<div className='rw-lp-intro'>
					<div className='rw-infobar'>
						<TimerCount
							duration={131}
							onComplete={() => {
								this.setState({
									timerRanOut: true,
								});
							}}
						/>
					</div>
				</div>
				<div className='rw-pageWrapper_component__flyingW'>
					<div>
						<p>
							<AudioPlayer
								autoPlay
								inline
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								onComplete={() => {
									this.startIntervel();
								}}
								src={this.audio}
							/>

							<span className='instruciton-fw__highlight'>
								{this.instructionText}
							</span>
						</p>
						<br></br>

						<p className='instruciton-fw__highlight'>{` ${this.state.discoveries} av ${this.discoverableWords}`}</p>
					</div>

					{!this.state.hasStartedTest && (
						<div className='rw-start-btn'>
							<br></br>
							<button
								className='rw-btn bg-primary'
								onClick={() =>
									this.setState({
										hasStartedTest: true,
									})
								}
							>
								{'Start'}
							</button>
						</div>
					)}
					{this.state.discoveries < this.discoverableWords &&
						!this.state.timerRanOut &&
						this.state.hasStartedTest && (
							<div className='rw-rc-container-motion'>
								{/* {this.wordsToInsert[counter] && (
							<div className='rw-fw-howitzer'>
								<img
									className='rw-fw-dragon-img__small'
									src={howitzer}
								/>
							</div>
						)} */}

								{this.state.randomVariable.map(
									(variable, index) => {
										return (
											<div className='rw-animation-container'>
												<motion.div
													className='motion'
													style={{
														width: '3%',
														display: 'flex',
														position: 'relative',
													}}
													onClick={() => {
														if (
															!arrayVar[
																index
															]
																.isAnswerd
														) {
															if (
																this.evaluateCorrectTargetWord(
																	variable
																)
																/* variable.word.toLowerCase() ===
																this.targetWord.toLowerCase() */
															) {
																consecutiveCorrect >
																	2 ||
																firstCorrectAttempt
																	? this.props.flipCoin(
																			3,
																			true
																	  )
																	: this.props.flipCoin(
																			1,
																			false
																	  );
																this.updateResults(
																	variable.word,
																	this
																		.targetWord,
																	{
																		isCorrect: true,
																	}
																);
																arrayVar[
																	index
																].isCorrect = true;
																arrayVar[
																	index
																].isAnswerd = true;
																this.setState(
																	{
																		randomVariable:
																			arrayVar,
																		discoveries:
																			this
																				.state
																				.discoveries +
																			1,
																	}
																);
															} else {
																this.props.recordMistake();
																consecutiveCorrect = 0;
																firstCorrectAttempt = false;
																arrayVar[
																	index
																].isCorrect = false;
																arrayVar[
																	index
																].isAnswerd = true;
																this.updateResults(
																	variable.word,
																	this
																		.targetWord,
																	{
																		isCorrect: false,
																	}
																);
																this.setState(
																	{
																		randomVariable:
																			arrayVar,
																	}
																);
															}
														}
													}}
													animate={{
														/* rotate: 360, */
														y: variable.yTranslate,
														x: variable.xTranslate,
													}}
													transition={{
														type: 'linear',
														mass: 0.5,
														stiffness: 10,
														repeat: Infinity,
														repeatType: 'mirror',
														repeatDelay: 1,
														bounce: 0.25,
														times: [
															0,
															2,
															1,
															1,
															1,
															1,
															2,
															2,
															4,
															4,
														],
														duration: 70,
														ease: 'easeIn',
													}}
												>
													<span
														className={
															variable.isCorrect
																? 'ghost-glowing'
																: variable.isAnswerd
																? 'ghost-wrong'
																: 'ghost'
														}
														key={
															index
														}
													>
														<p>
															{`	${variable.word} `}
														</p>
													</span>
												</motion.div>
											</div>
										);
									}
								)}
							</div>
						)}
					<br></br>
					<br></br>
					{(this.state.discoveries === this.discoverableWords ||
						this.state.timerRanOut ||
						this.props.isSuperTeacher) && (
						<div>
							{/* <div className='rw-fw-fireworks'>
							<img className='rw-fw-fireworks-img' src={fireworks} />
						</div> */}
							<div className='rw-next-btn'>
								<button
									className='rw-btn bg-primary'
									onClick={() =>
										onNextStep(
											resultsGloabl,
											ALL_SUBTESTS.FLYINGWORDS
										)
									}
								>
									{'Neste'}
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default FlyingWords;

/* Possible other styles for animation:
"linear"
"easeIn", "easeOut", "easeInOut"
"circIn", "circOut", "circInOut"
"backIn", "backOut", "backInOut"
"anticipate" */
