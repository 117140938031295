import mainAudio from '../assets/Oppgave 7. Rimord.m4a';
import session1_audio1 from '../assets/Session_1_RhymingWords_1.m4a';
import session1_audio2 from '../assets/Session_1_RhymingWords_2.m4a';
import session1_audio3 from '../assets/Session_1_RhymingWords_3.m4a';
import session1_audio4 from '../assets/Session_1_RhymingWords_4.m4a';

import session2_audio1 from '../assets/Session_2_RhymingWords_1.m4a';
import session2_audio2 from '../assets/Session_2_RhymingWords_2.m4a';
import session2_audio3 from '../assets/Session_2_RhymingWords_3.m4a';
import session2_audio4 from '../assets/Session_2_RhymingWords_4.m4a';

import session3_audio1 from '../assets/Session_3_RhymingWords_1.m4a';
import session3_audio2 from '../assets/Session_3_RhymingWords_2.m4a';
import session3_audio3 from '../assets/Session_3_RhymingWords_3.m4a';
import session3_audio4 from '../assets/Session_3_RhymingWords_4.m4a';
import session3_audio5 from '../assets/Session_3_RhymingWords_5.m4a';

import session4_audio1 from '../assets/Session_4_RhymingWords_1.m4a';
import session4_audio2 from '../assets/Session_4_RhymingWords_2.m4a';
import session4_audio3 from '../assets/Session_4_RhymingWords_3.m4a';
import session4_audio4 from '../assets/Session_4_RhymingWords_4.m4a';

import session5_audio1 from '../assets/Session_5_RhymingWords_1.m4a';
import session5_audio2 from '../assets/Session_5_RhymingWords_2.m4a';
import session5_audio3 from '../assets/Session_5_RhymingWords_3.m4a';
import session5_audio4 from '../assets/Session_5_RhymingWords_4.m4a';

import session6_audio1 from '../assets/Session_6_RhymingWords_1.m4a';
import session6_audio2 from '../assets/Session_6_RhymingWords_2.m4a';
import session6_audio3 from '../assets/Session_6_RhymingWords_3.m4a';
import session6_audio4 from '../assets/Session_6_RhymingWords_4.m4a';
import session6_audio5 from '../assets/Session_6_RhymingWords_5.m4a';

import session7_audio1 from '../assets/Session_7_RhymingWords_1.m4a';
import session7_audio2 from '../assets/Session_7_RhymingWords_2.m4a';
import session7_audio3 from '../assets/Session_7_RhymingWords_3.m4a';
import session7_audio4 from '../assets/Session_7_RhymingWords_4.m4a';
import session7_audio5 from '../assets/Session_7_RhymingWords_5.m4a';

import session8_audio1 from '../assets/Session_8_RhymingWords_1.m4a';
import session8_audio2 from '../assets/Session_8_RhymingWords_2.m4a';
import session8_audio3 from '../assets/Session_8_RhymingWords_3.m4a';
import session8_audio4 from '../assets/Session_8_RhymingWords_4.m4a';
import session8_audio5 from '../assets/Session_8_RhymingWords_5.m4a';

import session9_audio1 from '../assets/Session_9_RhymingWords_1.m4a';
import session9_audio2 from '../assets/Session_9_RhymingWords_2.m4a';
import session9_audio3 from '../assets/Session_9_RhymingWords_3.m4a';
import session9_audio4 from '../assets/Session_9_RhymingWords_4.m4a';
import session9_audio5 from '../assets/Session_9_RhymingWords_5.m4a';

import session10_audio1 from '../assets/Session_10_RhymingWords_1.m4a';
import session10_audio2 from '../assets/Session_10_RhymingWords_2.m4a';
import session10_audio3 from '../assets/Session_10_RhymingWords_3.m4a';
import session10_audio4 from '../assets/Session_10_RhymingWords_4.m4a';

import session11_audio1 from '../assets/Session_11_RhymingWords_1.m4a';
import session11_audio2 from '../assets/Session_11_RhymingWords_2.m4a';
import session11_audio3 from '../assets/Session_11_RhymingWords_3.m4a';
import session11_audio4 from '../assets/Session_11_RhymingWords_4.m4a';
import session11_audio5 from '../assets/Session_11_RhymingWords_5.m4a';

import session12_audio1 from '../assets/Session_12_RhymingWords_1.m4a';
import session12_audio2 from '../assets/Session_12_RhymingWords_2.m4a';
import session12_audio3 from '../assets/Session_12_RhymingWords_3.m4a';
import session12_audio4 from '../assets/Session_12_RhymingWords_4.m4a';

import session13_audio1 from '../assets/Session_13_RhymingWords_1.m4a';
import session13_audio2 from '../assets/Session_13_RhymingWords_2.m4a';
import session13_audio3 from '../assets/Session_13_RhymingWords_3.m4a';
import session13_audio4 from '../assets/Session_13_RhymingWords_4.m4a';
import session13_audio5 from '../assets/Session_13_RhymingWords_5.m4a';

import session14_audio1 from '../assets/Session_14_RhymingWords_1.m4a';
import session14_audio2 from '../assets/Session_14_RhymingWords_2.m4a';
import session14_audio3 from '../assets/Session_14_RhymingWords_3.m4a';
import session14_audio4 from '../assets/Session_14_RhymingWords_4.m4a';

import session15_audio1 from '../assets/Session_15_RhymingWords_1.m4a';
import session15_audio2 from '../assets/Session_15_RhymingWords_2.m4a';
import session15_audio3 from '../assets/Session_15_RhymingWords_3.m4a';
import session15_audio4 from '../assets/Session_15_RhymingWords_4.m4a';

import session16_audio1 from '../assets/Session_16_RhymingWords_1.m4a';
import session16_audio2 from '../assets/Session_16_RhymingWords_2.m4a';
import session16_audio3 from '../assets/Session_16_RhymingWords_3.m4a';
import session16_audio4 from '../assets/Session_16_RhymingWords_4.m4a';

import session17_audio1 from '../assets/Session_17_RhymingWords_1.m4a';
import session17_audio2 from '../assets/Session_17_RhymingWords_2.m4a';
import session17_audio3 from '../assets/Session_17_RhymingWords_3.m4a';
import session17_audio4 from '../assets/Session_17_RhymingWords_4.m4a';

export const session1 = [
	{
		introAudio: mainAudio,
		wordsArray: [
			{
				sound: session1_audio1,
				allWords: [
					{ word: 'ror', isCorrect: false, isAnswered: false },
					{ word: 'liten', isCorrect: true, isAnswered: false },
					{ word: 'stor', isCorrect: false, isAnswered: false },
					{ word: 'bor', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session1_audio2,
				allWords: [
					{ word: 'tre', isCorrect: false, isAnswered: false },
					{ word: 'se', isCorrect: false, isAnswered: false },
					{ word: 'så', isCorrect: true, isAnswered: false },
					{ word: 'le', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session1_audio3,
				allWords: [
					{ word: 'leker', isCorrect: false, isAnswered: false },
					{ word: 'peker', isCorrect: false, isAnswered: false },
					{ word: 'reker', isCorrect: false, isAnswered: false },
					{ word: 'rakett', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session1_audio4,
				allWords: [
					{ word: 'katt', isCorrect: true, isAnswered: false },
					{ word: 'ku', isCorrect: false, isAnswered: false },
					{ word: 'snu', isCorrect: false, isAnswered: false },
					{ word: 'bru', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session2 = [
	{
		wordsArray: [
			{
				sound: session2_audio1,
				allWords: [
					{ word: 'fly', isCorrect: false, isAnswered: false },
					{ word: 'sy', isCorrect: false, isAnswered: false },
					{ word: 'by', isCorrect: false, isAnswered: false },
					{ word: 'hus', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session2_audio2,
				allWords: [
					{ word: 'litt', isCorrect: false, isAnswered: false },
					{ word: 'ditt', isCorrect: false, isAnswered: false },
					{ word: 'fått', isCorrect: true, isAnswered: false },
					{ word: 'mitt', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session2_audio3,
				allWords: [
					{ word: 'ylvis', isCorrect: true, isAnswered: false },
					{ word: 'sky', isCorrect: false, isAnswered: false },
					{ word: 'by', isCorrect: false, isAnswered: false },
					{ word: 'sy', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session2_audio4,
				allWords: [
					{ word: 'tøy', isCorrect: false, isAnswered: false },
					{ word: 'sy', isCorrect: true, isAnswered: false },
					{ word: 'gøy', isCorrect: false, isAnswered: false },
					{ word: 'bøy', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session3 = [
	{
		wordsArray: [
			{
				sound: session3_audio1,
				allWords: [
					{ word: 'smykke', isCorrect: false, isAnswered: false },
					{ word: 'lykke', isCorrect: false, isAnswered: false },
					{ word: 'dykke', isCorrect: false, isAnswered: false },
					{ word: 'pakke', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session3_audio2,
				allWords: [
					{ word: 'bie', isCorrect: false, isAnswered: false },
					{ word: 'bamse', isCorrect: true, isAnswered: false },
					{ word: 'tie', isCorrect: false, isAnswered: false },
					{ word: 'vie', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session3_audio3,
				allWords: [
					{ word: 'berit', isCorrect: true, isAnswered: false },
					{ word: 'stein', isCorrect: false, isAnswered: false },
					{ word: 'bein', isCorrect: false, isAnswered: false },
					{ word: 'grein', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session3_audio4,
				allWords: [
					{ word: 'bil', isCorrect: false, isAnswered: false },
					{ word: 'stil', isCorrect: false, isAnswered: false },
					{ word: 'film', isCorrect: true, isAnswered: false },
					{ word: 'mil', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session3_audio5,
				allWords: [
					{ word: 'båt', isCorrect: true, isAnswered: false },
					{ word: 'våt', isCorrect: false, isAnswered: false },
					{ word: 'låt', isCorrect: false, isAnswered: false },
					{ word: 'bil', isCorrect: true, isAnswered: false },
				],
			},
		],
	},
];
export const session4 = [
	{
		wordsArray: [
			{
				sound: session4_audio1,
				allWords: [
					{ word: 'kaller', isCorrect: false, isAnswered: false },
					{ word: 'faller', isCorrect: false, isAnswered: false },
					{ word: 'traller', isCorrect: false, isAnswered: false },
					{ word: 'synger', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session4_audio2,
				allWords: [
					{ word: 'ikke', isCorrect: false, isAnswered: false },
					{ word: 'nikke', isCorrect: false, isAnswered: false },
					{ word: 'snakke', isCorrect: true, isAnswered: false },
					{ word: 'slikke', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session4_audio3,
				allWords: [
					{ word: 'venner', isCorrect: false, isAnswered: false },
					{ word: 'kjekke', isCorrect: true, isAnswered: false },
					{ word: 'kjenner', isCorrect: false, isAnswered: false },
					{ word: 'spenner', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session4_audio4,
				allWords: [
					{ word: 'hopper', isCorrect: true, isAnswered: false },
					{ word: 'danser', isCorrect: false, isAnswered: false },
					{ word: 'svanser', isCorrect: false, isAnswered: false },
					{ word: 'kranser', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session5 = [
	{
		wordsArray: [
			{
				sound: session5_audio1,
				allWords: [
					{ word: 'fnatt', isCorrect: false, isAnswered: false },
					{ word: 'hatt', isCorrect: false, isAnswered: false },
					{ word: 'lue', isCorrect: true, isAnswered: false },
					{ word: 'katt', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session5_audio2,
				allWords: [
					{ word: 'ingen', isCorrect: false, isAnswered: false },
					{ word: 'tingen', isCorrect: false, isAnswered: false },
					{ word: 'ringen', isCorrect: false, isAnswered: false },
					{ word: 'fingeren', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session5_audio3,
				allWords: [
					{ word: 'hår', isCorrect: true, isAnswered: false },
					{ word: 'huk', isCorrect: false, isAnswered: false },
					{ word: 'duk', isCorrect: false, isAnswered: false },
					{ word: 'sluk', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session5_audio4,
				allWords: [
					{ word: 'lange', isCorrect: false, isAnswered: false },
					{ word: 'korte', isCorrect: true, isAnswered: false },
					{ word: 'mange', isCorrect: false, isAnswered: false },
					{ word: 'slange', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session6 = [
	{
		wordsArray: [
			{
				sound: session6_audio1,
				allWords: [
					{ word: 'bakken', isCorrect: false, isAnswered: false },
					{ word: 'jakken', isCorrect: false, isAnswered: false },
					{ word: 'frakken', isCorrect: false, isAnswered: false },
					{ word: 'sjiraffen', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session6_audio2,
				allWords: [
					{ word: 'klask', isCorrect: false, isAnswered: false },
					{ word: 'vask', isCorrect: false, isAnswered: false },
					{ word: 'vann', isCorrect: true, isAnswered: false },
					{ word: 'plask', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session6_audio3,
				allWords: [
					{ word: 'glaner', isCorrect: false, isAnswered: false },
					{ word: 'se', isCorrect: true, isAnswered: false },
					{ word: 'vaner', isCorrect: false, isAnswered: false },
					{ word: 'svaner', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session6_audio4,
				allWords: [
					{ word: 'blå', isCorrect: false, isAnswered: false },
					{ word: 'grå', isCorrect: false, isAnswered: false },
					{ word: 'svart', isCorrect: true, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session6_audio5,
				allWords: [
					{ word: 'hoster', isCorrect: true, isAnswered: false },
					{ word: 'nyser', isCorrect: false, isAnswered: false },
					{ word: 'fryser', isCorrect: false, isAnswered: false },
					{ word: 'pyser', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session7 = [
	{
		wordsArray: [
			{
				sound: session7_audio1,
				allWords: [
					{ word: 'fe', isCorrect: false, isAnswered: false },
					{ word: 'le', isCorrect: false, isAnswered: false },
					{ word: 'se', isCorrect: false, isAnswered: false },
					{ word: 'vi', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session7_audio2,
				allWords: [
					{ word: 'bet', isCorrect: false, isAnswered: false },
					{ word: 'het', isCorrect: false, isAnswered: false },
					{ word: 'vei', isCorrect: true, isAnswered: false },
					{ word: 'vet', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session7_audio3,
				allWords: [
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session7_audio4,
				allWords: [
					{ word: 'katter', isCorrect: false, isAnswered: false },
					{ word: 'hatter', isCorrect: false, isAnswered: false },
					{ word: 'luer', isCorrect: true, isAnswered: false },
					{ word: 'latter', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session7_audio5,
				allWords: [
					{ word: 'slem', isCorrect: false, isAnswered: false },
					{ word: 'slo', isCorrect: true, isAnswered: false },
					{ word: 'klem', isCorrect: false, isAnswered: false },
					{ word: 'fem', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session8 = [
	{
		wordsArray: [
			{
				sound: session8_audio1,
				allWords: [
					{ word: 'halen', isCorrect: false, isAnswered: false },
					{ word: 'salen', isCorrect: false, isAnswered: false },
					{ word: 'dalen', isCorrect: false, isAnswered: false },
					{ word: 'fisken', isCorrect: true, isAnswered: false },
					{ word: 'hvalen', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session8_audio2,
				allWords: [
					{ word: 'kanin', isCorrect: false, isAnswered: false },
					{ word: 'haren', isCorrect: true, isAnswered: false },
					{ word: 'fin', isCorrect: false, isAnswered: false },
					{ word: 'svin', isCorrect: false, isAnswered: false },
					{ word: 'din', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session8_audio3,
				allWords: [
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session8_audio4,
				allWords: [
					{ word: 'spratt', isCorrect: false, isAnswered: false },
					{ word: 'satt', isCorrect: false, isAnswered: false },
					{ word: 'mus', isCorrect: true, isAnswered: false },
					{ word: 'katt', isCorrect: false, isAnswered: false },
					{ word: 'hatt', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session8_audio5,
				allWords: [
					{ word: 'bort', isCorrect: false, isAnswered: false },
					{ word: 'gjort', isCorrect: false, isAnswered: false },
					{ word: 'lort', isCorrect: false, isAnswered: false },
					{ word: 'fort', isCorrect: false, isAnswered: false },
					{ word: 'fint', isCorrect: true, isAnswered: false },
				],
			},
		],
	},
];
export const session9 = [
	{
		wordsArray: [
			{
				sound: session9_audio1,
				allWords: [
					{ word: 'sukker', isCorrect: false, isAnswered: false },
					{ word: 'krukker', isCorrect: false, isAnswered: false },
					{ word: 'vaser', isCorrect: true, isAnswered: false },
					{ word: 'dukker', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session9_audio2,
				allWords: [
					{ word: 'benker', isCorrect: false, isAnswered: false },
					{ word: 'lenker', isCorrect: false, isAnswered: false },
					{ word: 'tenker', isCorrect: false, isAnswered: false },
					{ word: 'sover', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session9_audio3,
				allWords: [
					{ word: 'sang', isCorrect: false, isAnswered: false },
					{ word: 'musikk', isCorrect: true, isAnswered: false },
					{ word: 'tang', isCorrect: false, isAnswered: false },
					{ word: 'gang', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session9_audio4,
				allWords: [
					{ word: 'boken', isCorrect: false, isAnswered: false },
					{ word: 'kroken', isCorrect: false, isAnswered: false },
					{ word: 'stangen', isCorrect: true, isAnswered: false },
					{ word: 'knoken', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session9_audio5,
				allWords: [
					{ word: 'jakken', isCorrect: false, isAnswered: false },
					{ word: 'buksen', isCorrect: true, isAnswered: false },
					{ word: 'bakken', isCorrect: false, isAnswered: false },
					{ word: 'krakken', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session10 = [
	{
		wordsArray: [
			{
				sound: session10_audio1,
				allWords: [
					{ word: 'lasse', isCorrect: false, isAnswered: false },
					{ word: 'gasse', isCorrect: false, isAnswered: false },
					{ word: 'vasse', isCorrect: false, isAnswered: false },
					{ word: 'være', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session10_audio2,
				allWords: [
					{ word: 'sag', isCorrect: false, isAnswered: false },
					{ word: 'dag', isCorrect: false, isAnswered: false },
					{ word: 'natt', isCorrect: true, isAnswered: false },
					{ word: 'fag', isCorrect: false, isAnswered: false },
					{ word: 'lag', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session10_audio3,
				allWords: [
					{ word: 'fikk', isCorrect: false, isAnswered: false },
					{ word: 'gikk', isCorrect: false, isAnswered: false },
					{ word: 'satt', isCorrect: true, isAnswered: false },
					{ word: 'strikk', isCorrect: false, isAnswered: false },
					{ word: 'stikk', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session10_audio4,
				allWords: [
					{ word: 'rikke', isCorrect: false, isAnswered: false },
					{ word: 'hikke', isCorrect: false, isAnswered: false },
					{ word: 'ikke', isCorrect: false, isAnswered: false },
					{ word: 'sitte', isCorrect: true, isAnswered: false },
					{ word: 'brikke', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session11 = [
	{
		wordsArray: [
			{
				sound: session11_audio1,
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session11_audio2,
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session11_audio3,
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session11_audio4,
				allWords: [
					{ word: 'fat', isCorrect: false, isAnswered: false },
					{ word: 'kopp', isCorrect: true, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session11_audio5,
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session12 = [
	{
		wordsArray: [
			{
				sound: session12_audio1,
				allWords: [
					{ word: 'gråten', isCorrect: false, isAnswered: false },
					{ word: 'flåten', isCorrect: false, isAnswered: false },
					{ word: 'båten', isCorrect: false, isAnswered: false },
					{ word: 'bilen', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session12_audio2,
				allWords: [
					{ word: 'glem', isCorrect: false, isAnswered: false },
					{ word: 'fem', isCorrect: false, isAnswered: false },
					{ word: 'fire', isCorrect: true, isAnswered: false },
					{ word: 'hjem', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session12_audio3,
				allWords: [
					{ word: 'stopp', isCorrect: true, isAnswered: false },
					{ word: 'sår', isCorrect: false, isAnswered: false },
					{ word: 'får', isCorrect: false, isAnswered: false },
					{ word: 'går', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session12_audio4,
				allWords: [
					{ word: 'kalle', isCorrect: false, isAnswered: false },
					{ word: 'skalle', isCorrect: false, isAnswered: false },
					{ word: 'alle', isCorrect: false, isAnswered: false },
					{ word: 'ingen', isCorrect: true, isAnswered: false },
				],
			},
		],
	},
];
export const session13 = [
	{
		wordsArray: [
			{
				sound: session13_audio1,
				allWords: [
					{ word: 'prikket', isCorrect: false, isAnswered: false },
					{ word: 'kikket', isCorrect: false, isAnswered: false },
					{ word: 'hikket', isCorrect: false, isAnswered: false },
					{ word: 'rakett', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session13_audio2,
				allWords: [
					{ word: 'først', isCorrect: false, isAnswered: false },
					{ word: 'størst', isCorrect: false, isAnswered: false },
					{ word: 'minst', isCorrect: true, isAnswered: false },
					{ word: 'børst', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session13_audio3,
				allWords: [
					{ word: 'kinn', isCorrect: false, isAnswered: false },
					{ word: 'hake', isCorrect: true, isAnswered: false },
					{ word: 'skinn', isCorrect: false, isAnswered: false },
					{ word: 'vind', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session13_audio4,
				allWords: [
					{ word: 'bord', isCorrect: false, isAnswered: false },
					{ word: 'stol', isCorrect: true, isAnswered: false },
					{ word: 'stor', isCorrect: false, isAnswered: false },
					{ word: 'fjord', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session13_audio5,
				allWords: [
					{ word: 'hatten', isCorrect: false, isAnswered: false },
					{ word: 'hunden', isCorrect: true, isAnswered: false },
					{ word: 'katten', isCorrect: false, isAnswered: false },
					{ word: 'skatten', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session14 = [
	{
		wordsArray: [
			{
				sound: session14_audio1,
				allWords: [
					{ word: 'raner', isCorrect: true, isAnswered: false },
					{ word: 'senker', isCorrect: false, isAnswered: false },
					{ word: 'tenker', isCorrect: false, isAnswered: false },
					{ word: 'lenker', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session14_audio2,
				allWords: [
					{ word: 'greier', isCorrect: false, isAnswered: false },
					{ word: 'feier', isCorrect: false, isAnswered: false },
					{ word: 'veier', isCorrect: false, isAnswered: false },
					{ word: 'gater', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session14_audio3,
				allWords: [
					{ word: 'flykte', isCorrect: false, isAnswered: false },
					{ word: 'sykle', isCorrect: true, isAnswered: false },
					{ word: 'rykte', isCorrect: false, isAnswered: false },
					{ word: 'frykte', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session14_audio4,
				allWords: [
					{ word: 'røver', isCorrect: false, isAnswered: false },
					{ word: 'tøver', isCorrect: false, isAnswered: false },
					{ word: 'tiger', isCorrect: true, isAnswered: false },
					{ word: 'løver', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session15 = [
	{
		wordsArray: [
			{
				sound: session15_audio1,
				allWords: [
					{ word: 'svinger', isCorrect: false, isAnswered: false },
					{ word: 'finger', isCorrect: false, isAnswered: false },
					{ word: 'armer', isCorrect: true, isAnswered: false },
					{ word: 'vinger', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session15_audio2,
				allWords: [
					{ word: 'salt', isCorrect: false, isAnswered: false },
					{ word: 'sukker', isCorrect: true, isAnswered: false },
					{ word: 'kalt', isCorrect: false, isAnswered: false },
					{ word: 'alt', isCorrect: false, isAnswered: false },
					{ word: 'falt', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session15_audio3,
				allWords: [
					{ word: 'rygg', isCorrect: false, isAnswered: false },
					{ word: 'mygg', isCorrect: false, isAnswered: false },
					{ word: 'flue', isCorrect: true, isAnswered: false },
					{ word: 'stygg', isCorrect: false, isAnswered: false },
					{ word: 'bygg', isCorrect: false, isAnswered: false },
				],
			},

			{
				sound: session15_audio4,
				allWords: [
					{ word: 'søte', isCorrect: true, isAnswered: false },
					{ word: 'sure', isCorrect: false, isAnswered: false },
					{ word: 'lure', isCorrect: false, isAnswered: false },
					{ word: 'dure', isCorrect: false, isAnswered: false },
					{ word: 'skure', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session16 = [
	{
		wordsArray: [
			{
				sound: session16_audio1,
				allWords: [
					{ word: 'tann', isCorrect: false, isAnswered: false },
					{ word: 'krem', isCorrect: true, isAnswered: false },
					{ word: 'spann', isCorrect: false, isAnswered: false },
					{ word: 'vann', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session16_audio2,
				allWords: [
					{ word: 'sy', isCorrect: false, isAnswered: false },
					{ word: 'ny', isCorrect: false, isAnswered: false },
					{ word: 'by', isCorrect: false, isAnswered: false },
					{ word: 'bor', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: session16_audio3,
				allWords: [
					{ word: 'brus', isCorrect: true, isAnswered: false },
					{ word: 'bris', isCorrect: false, isAnswered: false },
					{ word: 'glis', isCorrect: false, isAnswered: false },
					{ word: 'flis', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session16_audio4,
				allWords: [
					{ word: 'trist', isCorrect: false, isAnswered: false },
					{ word: 'rist', isCorrect: false, isAnswered: false },
					{ word: 'tann', isCorrect: true, isAnswered: false },
					{ word: 'frist', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session17 = [
	{
		wordsArray: [
			{
				sound: session17_audio1,
				allWords: [
					{ word: 'drakt', isCorrect: false, isAnswered: false },
					{ word: 'hjelm', isCorrect: true, isAnswered: false },
					{ word: 'pakt', isCorrect: false, isAnswered: false },
					{ word: 'vakt', isCorrect: false, isAnswered: false },
					{ word: 'slakt', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session17_audio2,
				allWords: [
					{ word: 'sint', isCorrect: true, isAnswered: false },
					{ word: 'snill', isCorrect: false, isAnswered: false },
					{ word: 'vill', isCorrect: false, isAnswered: false },
					{ word: 'dill', isCorrect: false, isAnswered: false },
					{ word: 'krill', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: session17_audio3,
				allWords: [
					{ word: 'rått', isCorrect: false, isAnswered: false },
					{ word: 'blått', isCorrect: false, isAnswered: false },
					{ word: 'gult', isCorrect: true, isAnswered: false },
					{ word: 'fått', isCorrect: false, isAnswered: false },
					{ word: 'slått', isCorrect: false, isAnswered: false },
				],
			},

			{
				sound: session17_audio4,
				allWords: [
					{ word: 'seier', isCorrect: false, isAnswered: false },
					{ word: 'heier', isCorrect: false, isAnswered: false },
					{ word: 'bleier', isCorrect: false, isAnswered: false },
					{ word: 'veier', isCorrect: false, isAnswered: false },
					{ word: 'vinker', isCorrect: true, isAnswered: false },
				],
			},
		],
	},
];
export const session18 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session19 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session20 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session21 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session22 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session23 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session24 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session25 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session26 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session27 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session28 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session29 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session30 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
export const session31 = [
	{
		wordsArray: [
			{
				sound: '',
				allWords: [
					{ word: 'nå', isCorrect: false, isAnswered: false },
					{ word: 'få', isCorrect: false, isAnswered: false },
					{ word: 'fikk', isCorrect: true, isAnswered: false },
					{ word: 'så', isCorrect: false, isAnswered: false },
					{ word: 'lå', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'sopp', isCorrect: false, isAnswered: false },
					{ word: 'opp', isCorrect: false, isAnswered: false },
					{ word: 'ned', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'hopp', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'her', isCorrect: false, isAnswered: false },
					{ word: 'er', isCorrect: false, isAnswered: false },
					{ word: 'der', isCorrect: false, isAnswered: false },
					{ word: 'ser', isCorrect: true, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'fat', isCorrect: true, isAnswered: false },
					{ word: 'kopp', isCorrect: false, isAnswered: false },
					{ word: 'mat', isCorrect: false, isAnswered: false },
					{ word: 'prat', isCorrect: false, isAnswered: false },
				],
			},
			{
				sound: '',
				allWords: [
					{ word: 'lo', isCorrect: false, isAnswered: false },
					{ word: 'to', isCorrect: false, isAnswered: false },
					{ word: 'tre', isCorrect: true, isAnswered: false },
					{ word: 'do', isCorrect: false, isAnswered: false },
					{ word: 'sko', isCorrect: false, isAnswered: false },
				],
			},
		],
	},
];
