import React from 'react';
import { connect } from 'react-redux';
import { goToNextStep } from '../../../actions/index';

class Intro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { onNextStep } = this.props;
		return (
			<div className='rw-lk-introWrapper'>
				<div className='rw-lk-intro'>
					<div className='rw-blog'>
						<p className='rw-lk-Heading'>1. Lytteforståelse.</p>
					</div>
					<div className='rw-blog-heading'>
						<p className='rw-lk-paraHeading'>Alle gode ting er tre.</p>
					</div>
					<div className='rw-blog-para-summary'>
						<p className='rw-lk-para'>Ylvis uler som en ulv.</p>
						<p className='rw-lk-para'>
							Han tror at han er stor, men han er liten.
						</p>
						<p className='rw-lk-para'>Hunder er fine dyr.</p>
						<p className='rw-lk-para'>De liker å leke og ha det gøy.</p>
						<p className='rw-lk-para'>
							Unni, Ylvis og Åge liker å lukte der andre har tisset og
							bæsjet.
						</p>
						<p className='rw-lk-para'>
							Alle tre har god luktesans. Hunder har bedre luktesans enn
							mennesker.
						</p>
						<p className='rw-lk-para'>Det er litt rart å tenke på.</p>
					</div>
				</div>
				<br></br>
				<div className='rw-lk-paragraph'>
					<div className='rw-blog-heading'>
						<p className='rw-lk-paraHeading'>Alle gode ting er tre.</p>
					</div>
					<p className='rw-lk-EntirePara'>
						Ylvis uler som en ulv. Han tror at han er veldig stor, men han er like
						liten som en støvsuger. Hunder er flotte dyr. De liker å leke og ha
						det gøy. Unni, Ylvis og Åge elsker å lukte der andre har tisset og
						bæsjet. Alle tre har god luktesans. Visste du at hunder kan ha flere
						tusen ganger bedre luktesans enn mennesker? Det er litt rart å tenke
						på.
					</p>
				</div>
				<div className='rw-blog-heading'>
					<p className='rw-lk-paraHeading'>
						2. Elevene gjenforteller teksten og handlingen.
					</p>
				</div>
				<div className='rw-blog-para-summary__right'>
					<p className='rw-lk-para'>Hjelpespørsmål ved behov:</p>
					<p className='rw-lk-para'>- Hvem er med i fortellingen?</p>
					<p className='rw-lk-para'>- Hvor er de?</p>
					<p className='rw-lk-para'>- Hva gjør de?</p>
					<p className='rw-lk-para'>- Når foregår handlingen?</p>
					<p className='rw-lk-para'>- Hvorfor?</p>
				</div>
				<br></br>
				<div className='rw-blog-heading'>
					<p className='rw-lk-paraHeading'>3. Gjennomgang av sentrale ord i teksten.</p>
				</div>
				<div className='rw-lk-revision'>
					<div className='rw-lk-revision__points'>
						<p className='rw-lk-para__heavy'> Presenter et ord om gang </p>
						<p className='rw-lk-para'>- Støvsuger</p>
						<p className='rw-lk-para'>- Luktesans</p>
					</div>
					<div className='rw-lk-revision__Questions'>
						<p className='rw-lk-para__heavy'>Spørsmål til ordene</p>
						<p className='rw-lk-para'>- Hvem er med i fortellingen?</p>
						<p className='rw-lk-para'>- Hvor er de?</p>
						<p className='rw-lk-para'>- Hva gjør de?</p>
						<p className='rw-lk-para'>- Når foregår handlingen?</p>
						<p className='rw-lk-para'>- Hvorfor?</p>
					</div>
				</div>
				<br></br>
				<div className='rw-next-btn'>
					<button className='rw-btn bg-primary' onClick={() => onNextStep()}>
						{'Neste'}
					</button>
				</div>
			</div>
		);
	}
}
const mapStateToProps = () => {
	return {};
};
export default connect(mapStateToProps)(Intro);
