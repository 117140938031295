export const session52 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'De brukte klær av',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'silke', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'ull', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'bomull', isAnswered: false, isCorrect: false },
					{
						id: 4,
						letter: 'd',
						label: 'skinn og pels',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det var veldig ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'mye regn', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'varmt', isAnswered: false, isCorrect: false },
					{
						id: 3,
						letter: 'c',
						label: 'kaldt',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'mye vind',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Folk i steinalderen lagde mat ',
				leadingText: '',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'på kjøkkenet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'i komfyren',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'på grillen',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 4, letter: 'd', label: 'over bål', isAnswered: false, isCorrect: true },
				],
			},
			{
				id: 4,
				precedingText: 'De lagde potter av ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'plast', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'leire', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'tre', isAnswered: false, isCorrect: false },
					{ id: 4, letter: 'd', label: 'metall', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Steinalderen sluttet da folk begynte å bruke ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'grill', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'leire', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'metall', isAnswered: false, isCorrect: true },
					{
						id: 4,
						letter: 'd',
						label: 'skinn og pels',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session53 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Bronse blir laget ved å blande ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skinn og pels',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'fjell og tinn',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kobber og tinn',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'kobber og stein',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Kobber og tinn finnes i ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'myr og stein',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skog og stein',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'fjell og myr',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'fjell og steiner',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Bronse er et hardt, rødbrunt og blankt',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skinn',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'stein',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'metall',
						isAnswered: false,
						isCorrect: true,
					},
					{ id: 4, letter: 'd', label: 'fjell', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'For å utvinne metallet, måtte man varme opp ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'myr', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'stein', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'bronse', isAnswered: false, isCorrect: false },
					{ id: 4, letter: 'd', label: 'tinn', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Da metallet ble avkjølt ble det ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'mykt', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'blankt', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'rødt', isAnswered: false, isCorrect: false },
					{
						id: 4,
						letter: 'd',
						label: 'hardt',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];
export const session54 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Sverd og rustninger ble laget av ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kobber',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bronse',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'stein',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'tinn',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Sigder ble brukt til å ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'spise med',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'lage mat i',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sloss med',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'kutte planter',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'En plog er en type ',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'rustning',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'graveredskap',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sverd',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 4, letter: 'd', label: 'panne', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 4,
				precedingText: 'Sverd ble brukt til å  ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'grave med', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'sloss med', isAnswered: false, isCorrect: true },
					{ id: 3, letter: 'c', label: 'spise med', isAnswered: false, isCorrect: false },
					{
						id: 4,
						letter: 'd',
						label: 'kutte planter',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Store dyr dro ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'rustningen',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 2, letter: 'b', label: 'sigden', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'sverdet', isAnswered: false, isCorrect: false },
					{
						id: 4,
						letter: 'd',
						label: 'plogen',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];
export const session55 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Hva ble oppfunnet i bronsealderen? ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skriving og skriftspråk',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'spyd og kniv',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sverd og kniv',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'spyd og klær',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Hva skrev folk? ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'handleliste',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'lekser',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'historier og lover',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'brev',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'En berømt skrift er ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'helleristninger',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'store bokstaver',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hieroglyfer',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'små bokstaver',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'En blanding av metall kalles for  ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'kobber', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'tinn', isAnswered: false, isCorrect: false },
					{ id: 3, letter: 'c', label: 'stein', isAnswered: false, isCorrect: false },
					{
						id: 4,
						letter: 'd',
						label: 'legering',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Forhistorisk tid er tiden ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'etter bronsealderen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'før bronsealderen',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'etter steinalderen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'etter vikingetiden',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session56 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Flere mennesker ble ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bofaste',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'syke',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'snekkere',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'jegere',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Menneskene bodde ofte i ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'lavvo',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'langhus',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'gammer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'telt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Menneskene bodde sammen med ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ulver',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'reinsdyr',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'husdyrene',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'folk fra Egypt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'De byttet varer mot å få  ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'klær', isAnswered: false, isCorrect: false },
					{ id: 2, letter: 'b', label: 'kjøtt', isAnswered: false, isCorrect: false },
					{
						id: 3,
						letter: 'c',
						label: 'bronsegjenstander',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'pels og skinn',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Det er gjort bronsefunn helt nord til ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'Tromsø',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'Senja',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'Harstad',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'Alta',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session57 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Jern er et blankt, gråfarget ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'stein',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'tre',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'myr',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'metall',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Med mynter kunne folk ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bytte varer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bestille varer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjøpe og selge varer',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'pakke varer',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Jern kan man finne i ulike ',
				leadingText: '',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'steiner og i myrer',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'elver',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'skoger',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'trær',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Redskapene de brukte til å bygge ting med var  ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'sverd', isAnswered: false, isCorrect: false },
					{
						id: 2,
						letter: 'b',
						label: 'hammer og sag ',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kvernsteiner ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'panner og gryter',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Kvernsteinen ble brukt til å male ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'hus',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ansiktet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'redskaper',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'korn til mel',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session58 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Skipene var ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'korte og smale',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'lange og smale',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'korte og breie',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'smale og breie',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Vikingene var ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'smeder',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'fiskere',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bønder og krigere',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'jegere og sankere',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Skipene hadde ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'årer og motor',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'seil og motor',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'seil og årer',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'motor',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Vikingene levde i ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'Nord-Europa',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'USA',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'Australia',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 4, letter: 'd', label: 'Kina', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Noen vikinger seilte til land langt unna for å bytte til seg ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'mat',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'sverd og skjold',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'båter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'klær, krydder og smykker',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session59 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Ørn er en ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ugle',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'gribbe',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'falk',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'rovfugl',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Ørner jakter på pattedyr og ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'mus',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'fisk',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kanin',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'hare',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Ørner bygger rede ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'i fjæra',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'i fuglekasser',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'på klipper',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'i huler',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Ørner legger ofte ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'to egg',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ett egg',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tre egg',
						isAnswered: false,
						isCorrect: false,
					},
					{ id: 4, letter: 'd', label: 'fire egg', isAnswered: false, isCorrect: false },
				],
			},
			{
				id: 5,
				precedingText: 'Et annet navn for rovfugl er  ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'gribber',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'falk',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'ugle',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'raptor',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session60 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Kaniner spiser bare ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'planter og kjøtt',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'planter og grønnsaker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjøtt og grønnsaker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'grønnsaker og fisk',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Kaniner har store ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'øyne',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'nese',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'klør',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'ører',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De graver i bakken for å ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'finne mat',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'gjemme mat',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'lage rede',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'gjemme seg',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Kaniner finnes ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bare i Norge',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'over hele verden',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bare i Europa',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bare i kalde klima',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Ungene til kaninen kalles for  ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kaninunger',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'dvergkanin',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'valper',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'babykanin',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session61 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Rein er en type ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'elg',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'rovdyr',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hjort',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bjørn',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Hunnene kalles ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'jente',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'simle',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bukk',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'valp',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Hannene kalles for ',
				leadingText: '',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'simle',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kalver',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'gevir',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bukker',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Kongeørn og jerv kan jakte på ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bukker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'simler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kalver',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'elg',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Reinsdyr feller geviret  ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'aldri',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'årlig',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hvert 3. år',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'hvert 2. år',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session62 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Isbjørn spiser  ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'reinsdyr og ørn',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'reinsdyr og sel',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sel og mus',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'ørn og jerv',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Ungene blir født ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'på isen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'på klippen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'i hiet',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'i havet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Isbjørnen lever i  ',
				leadingText: '',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'Danmark',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'India',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'Tyskland',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'Arktis',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'En isbjørnhann blir kalt ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bamse',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'binne',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'okse',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'kalv',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Isbjørnen er tilpasset til å leve i  ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'mye regn',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'varmt klima',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kaldt klima',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'mye sol',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session63 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Politiet sørger for at alle  ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'er høflig',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'er glad ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'følger loven',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'sykler sakte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'De arresterer personer de tror ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'vil gjøre noe galt',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ikke bruker refleks',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'er skyldig',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'sykler fort',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De hjelper også til når noen  ',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'har punktert',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'er i nød',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'ikke når bussen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'har kjørt seg fast',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Noen av verktøyene politiet bruker er ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'håndjern og notatbøker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hammer og sag',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'lommelykt og kniv',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'Ipad og skrujern',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'De fleste politifolk bruker  ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'uniform',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'politibil',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'grønne klær',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'hjelm',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session64 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'De fleste brannbiler er   ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ford',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'volvo ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'lastebiler',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'nye',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Brannbilene har  ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'slanger og tiger',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'slanger og stiger',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'øks og bøtter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bøtter og vann',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De fleste brannene de rykker ut til, er i  ',
				leadingText: '',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'gresset',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skogen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'biler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bygninger',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Brannkonstabler bruker noe av tiden til å ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skrive rapport',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'patruljere i gatene',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'trene hunder',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'klippe gress',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'De bruker også båter,  ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'motorsykkel og fly',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'fly og helikoptre',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'helikopter og traktor',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'fly og hunder',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session65 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En frisør bruker  ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'hårruller og saks',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'saks og lim ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'maling og balsam',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'shampo og lim',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'De fleste frisører jobber i  ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'butikk',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'salong',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'parfymeri',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'Amfi',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Frisører kan lage  ',
				leadingText: '',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kake',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'klær',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sminke',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'krøller i hår',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'En kolorist kan ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'lage krøller',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'barbere skjegg',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'farge hår',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'ta bort krøller',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'En barberer  ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'trimmer skjegg',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'farger hår',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'lager krøller ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'er ofte en dame',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session66 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Sykepleiere jobber på ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'brannstasjon',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'politistasjon',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'butikk',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'skoler',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'De kan gi ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'medisiner',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'godteri',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'leker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bøker',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Hvilke farge er det på uniformene?  ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bare hvite',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bare rosa',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'forskjellige farger',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bare sorte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Hvilke verktøy bruker sykepleiere? ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'hammer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'stetoskop',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kost',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'fil',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Hvilke sko bruker sykepleiere?  ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'rosa',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'spisse',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'gode',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'hvite',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session67 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En veterinær er en ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'lege',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'sykepleier',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'dyrlege',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'tannlege',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Veterinærer jobber på ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'dyresykehus',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'legekontor',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'skoler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'tannklinikker',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De bruker instrumenter og  ',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'noter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'tester',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'premier',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'mat',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Veterinær må studerer i minst ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'tre år',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'to år',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'fem år',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'fire år',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Noen veterinærer behandler kun  ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'mus',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'katter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hunder',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'kjæledyr',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session68 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En kjøkkensjef er en profesjonell ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'baker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kokk',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'servitør',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'sjåfør',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Kokker bruker utstyr som ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'blendere',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hammer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sag',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'tang',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Kjøkkensjefer bruker vanligvis en  ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'sort uniform',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'blå uniform',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hvit uniform',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'rosa uniform',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'De må få maten til å se tiltalende ut ved ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'koking',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'steiking',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'visping',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'servering',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Kjøkkensjefer jobber på ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'butikker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skoler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hotell',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'kiosker',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session69 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Avfallet kan være vann fra avløp eller ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'vasken',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'badekaret',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kloakk',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'dusjen',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Rørleggere blir ofte våte og ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'tørre',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skitne',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'varme',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'store',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De kan ha vanntette  ',
				leadingText: '',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kjeledresser',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'luer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sokker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'votter',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'En røreleger føloger nøys med på ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'trafikksikkerheten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'matsikkerheten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'brannsikkerheten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'vannsikkerheten',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'De bruker forskjellige typer verktøy som ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'baufil',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'visp',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'blender',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'gaffel',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session70 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En lastebil-sjåfør kjører ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'taxi',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'buss',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'lastebil',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'tog',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Varene de frakter, kalles ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'post',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'gods',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'varer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'passasjerer',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Lastebilsjåfører trenger en spesiell  ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'tilhenger',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'uniform',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'lisens',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'utdannelse',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Noen av bilene de kjører er  ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'tankbiler',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'busser',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'motorsykler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'trikk',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			/* {
				id: 5,
				precedingText: 'Noen lastebilsjåfører spesialiserer seg på hva slags gods de frakter. ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'snø',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'sand',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'mennesker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'dyr',
						isAnswered: false,
						isCorrect: true,
					},
				],
			}, */
		],
	},
];

export const session71 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En skuespiller er en som spiller i filmer og ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'teater',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kor',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bowling',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'fotball',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Noen skuespillere bruker bare ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'armene',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'føttene',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hørselen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'stemmen',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Vil du bli skuespiller kan du gå på  ',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ski',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'drama-skole',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'turn',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'fotball',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'De må ofte delta på en audition for å vise  ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'sangstemmen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'trylletriks',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'talentet',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'dans',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Hvem kan kalles skuespillerinner? ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'barn',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kvinner',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'menn',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'elever',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session72 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En pilot er en som fører ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bil',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'buss',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'lastebil',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'luft-fartøy',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'De kjører rutefly og frakter ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'passasjerer',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'dyr',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'gods',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'mat',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Piloter må passe på at flyene er',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'varme',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'trygge',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'rene',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'ryddige',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Piloter må kunne holde seg rolige i  ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'flyet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'solskinn',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'regnvær',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'nødstilfeller',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Før hver flyving må pilotene sjekke ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'uniformen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'værmeldingen',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'passasjerene',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bagasjen',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session73 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Et fly er et luft-fartøy med faste ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'vinger',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'seter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'piloter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'hjul',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det blir flydd av en utdannet ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'mann',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'dame',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'pilot',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'sjåfør',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Luft-ambulanser kan frakte  ',
				leadingText: '',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'militæret',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'piloter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'last',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'syke mennesker',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Det første flyet var et ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'monofly',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'biplan',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'triplan',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'militærfly',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Lastefly frakter kun ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'tropper',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'last',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'passasjerer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'militæret',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session74 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En motorsykkel har to hjul og en ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'motor',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'passasjer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hjelm',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'gjenstand',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det krever øvelse for å kjøre ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'raskt',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'sakte',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'trygt',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'på sand',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Man må også bruke ',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skjerf',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hjelm',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'regnjakke',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'lue',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Noen bruker motorsykler for moro skyld og til ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'brannslukking',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'pynt',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'sport',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'syketransport',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'De fleste motorsykler kan ha ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'fire passasjerer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'to passasjerer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tre passasjerer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'én passasjer',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session75 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Gulvet i båten kalles for ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'dekk',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hjul',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hekk',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'skrog',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'En båt er som et skip, men ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'større',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'smalere',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'lengre',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'mindre',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Den kan være laget av tre, metall eller ',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'glass',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'plast',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'papir',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'keramikk',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Bak på båten kalles ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'babord',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'baug',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hekk',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'dekk',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Høyre side på båten kalles ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'babord',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'styrbord',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hekk',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'baug',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session76 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En lastebil er et stort ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'dumper',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'platt',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'kjøretøy',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'tilhenger',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'En lastebil kan trekke vogner, som kalles ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'dumper',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skuter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tilhenger',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'platt',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Sjåføren sitter foran i ',
				leadingText: '',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'førerhuset',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'tanken',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'trommelen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'platten',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'En skapbil har ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'trommel',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'tank',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'ikke vegger og tak',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'vegger og tak',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'En betongbil har ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'trommel',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'tank',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'platt',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'vegger',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session77 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Lokomotivet trekker vogner på ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'vannet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skinner',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'veien',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'terrenget',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Persontog frakter ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'gods',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'last',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'passasjerer',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'dyr',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Godstog frakter ',
				leadingText: '',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'passasjerer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'lokomotiv',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'dyr',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'gods',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Monorails er tog som kjører på ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'én skinne',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'to skinner ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tre skinner',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'hjul',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Undergrunnstog kjører for det meste ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'på veien',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'under jorden',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'over jorden',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'på bruer',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session78 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En bil er et kjøretøy med fire hjul og en ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'motor',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'passasjer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'mobil',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'diesel',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Et eldre ord for bil er ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'mobil',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'automobil',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'diesel',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'førerbil',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Sjåføren må ha ',
				leadingText: '',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'mobil',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'diesel',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'strøm',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'førerkort',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'De fleste biler bruker ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'strøm',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bensin eller diesel ',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'diesel eller strøm',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bensin eller støm',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Bilen ble oppfunnet av ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'Camilla Benz',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'Kåre Benz',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'Carl Benz',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'Carol Benz',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session79 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En buss kan frakte mange ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'biler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'dyr',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'mennesker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'funksjoner',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'En bussjåfør må ha et spesielt ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'sete',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'førerkort',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'setebelte',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'speil',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'En skolebuss kjører ',
				leadingText: '',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'frakt',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'barnevogner',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'rullestoler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'barn',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'En kombibuss frakter både ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'barnevogner og gods',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'rullestoler og last',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'skolebarn og rullestoler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'passasjerer og last',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'En leddbuss består av ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'to eller flere deler',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'to etasjer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'to sjåfører',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'last',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session80 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En traktor er et kjøretøy som ofte brukes på ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skolen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'gårder',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'fjellet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'båter',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Traktoren trekker ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'båter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'tog',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'landbruksutstyr',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'biler',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Traktorer kan også legge høy i ',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'belter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'baller',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bakhjulet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'frø',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Traktorer kan også brukes til ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'brøyting',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'passasjerer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'buss',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'sport',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'En traktor kan for eksempel høste like mye som ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '10 personer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '50 personer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '70 personer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '100 personer',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];
export const session81 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Elsparkesykler har en liten, elektrisk ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'brems',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'lykt',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'motor',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'fløyte',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Det er regler for hvor den kan ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'kjøpes',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'brukes',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'selges',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'vaskes',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Føreren må bruke ',
				leadingText: '',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'støvler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hansker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'fart',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'hjelm',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Sparkesykler har en liten elektrisk motor i ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'setet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hjulene',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'styret',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bremsen',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Den første motoriserte sparkesykkelen ble laget i ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '2015',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '1950',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '1915',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: '2005',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session82 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En snøskuter er et ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'vinterkjøretøy',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'høstkjøretøy',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vårkjøretøy',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'sommerkjøretøy',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Den kalles også ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skiskuter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'belteskuter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'vinterskuter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'beltemotorsykkel',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Foran har snøskuteren ',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'belte',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ski',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hjul',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'slede',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Bak har snøskuteren ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'hjul',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ski',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'belte',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'tak',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'En snøskuter kan til og med kjøres',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'i lufta',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'over vann',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'på skinner',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'på motorvei',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session83 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Alle som har flaggstang heiser ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skoen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skolen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'ballongen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'flagget',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Etter toget samles mange på skolen der de spiser ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'is og potet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'pølse og gulrot',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'pølse og is',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'is og knekkebrød',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Tidlig på dagen går skoleelever ',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'hjem',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'i tog',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'på kino',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'på butikken',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Barna vifter med ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'leker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'pølse',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'flagg',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'is',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'I talene snakkes det mye om ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'brus og is',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'det som er bra med Norge',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'is og pølser',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'leker',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session84 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Før 1814 ble Norge styrt av ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'Sverige',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'Finland',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'Danmark',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'Island',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Mange nordmenn ville at Norge skulle bestemme over ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'Danmark',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'Island',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'seg selv',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'Sverige',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'De møttes på Eidsvoll for å lage ',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'flagget',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'grunnloven',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'uniformer',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'kart',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Den nye loven ble skrevet under 17.mai ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '1914',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '1924',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '1824',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '1814',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Helt siden 1906 har kongefamilien hilst på barnetoget i ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'Oslo',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'Danmark',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'Tromsø',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'Sverige',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session85 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Våren er tiden når planter dukker opp igjen etter ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'vinteren',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'sommeren',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'høsten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'vårsommeren',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Hestehov er en gul ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'sommerblomst',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'vårblomst',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'høstblomst',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'busk',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Den ligner litt på en ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'blåveis',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hvitveis',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'løvetann',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'tigertann',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Hvitveis og snøklokke er ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'gul',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'rosa',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'blå',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'hvit',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Blåveis og krokus er ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'gul',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hvit',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'lilla',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'rosa',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];

export const session86 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'De første humlene som våkner  er ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'droner',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'dronninger',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bier',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'konger',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'De leter etter et sted der de lager ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'musehi',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'fuglereir',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'fuglekasse',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bol',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Du kan hjelpe humlen ved å gi den sukkervann eller ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'epleskall',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'appelsinskall',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'bananskall',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'pæreskall',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText:
					'Redder du en dronning, redder du hundrevis av humler og hjelper tusenvis av',
				leadingText: ' ',
				correct: ['b'],
				sound: 'a',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'planter',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bier',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'maur',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'droner',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Tidlig på våren har humler som har overvintret lite ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'droner',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'energi',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'hus',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'musehi',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session87 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Insekter trenger planter til ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'pynt',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'venn',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'mat',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'fargelegging',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Insekter hjelper planter å lage frukt og ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'syltetøy',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'frø',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'saft',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bol',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Det er dette bier, maur og humler gjør om ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'sommeren',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'høsten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'våren',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'vinteren',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Planter trenger insekter for å frakte ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'frukt',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bær',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'saft',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'pollen',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Det finnes mange insekttyper i verden, ca. ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '10 millioner',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: '1 million',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '100 millioner',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '480 millioner',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session88 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Mye av plasten havner i ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'søppelen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hagen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'veigrøften',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'havet',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Mye ender opp i strandsonen i ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'Norge',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'hele verden',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'Danmark',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'USA',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Mange skoler deltar i strandrydding og plukking av ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'bær',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'kongler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'søppel',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'epler',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Elevene gjør en veldig viktig jobb når de rydder i ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'lekene',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'stua',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'skolesekken',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'naturen',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Mye av plasten i havet fraktes rundt med ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'båter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'havstrømmene',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tog',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'lastebiler',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session89 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Forurensning er når naturen, vannet eller luften blir ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'blå',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'skitten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'gul',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'rosa',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Forurensning kan gjøre oss ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'glade',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'døsige',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'syke',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'friske',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Forurensning er stort sett skapt av ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'dyr',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'fisker',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'mennesker',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'insekter',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Vulkanutbrudd sprer aske og ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'støv',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'vann',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'gress',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'plast',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Plasten bruker lang tid på å bli ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'spist',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'funnet i strandsonen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'pantet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'nedbrutt',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];
export const session90 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Nisen er en liten ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'fisk',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'tannhval',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'delfin',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'spekkhogger',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Niser blir ofte forvekslet med ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'haier',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'delfiner',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tannhvaler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'lakser',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Selv om den ser ut som en fisk så er nisen ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'et krepsdyr',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'en akkar',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'et pattedyr',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'en reke',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'I det fri lever nisen ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '10-20 år',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: '1-2 år',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '20-30 år',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '5-15 år',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Niser blir jaktet  på av ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'hai og laks',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'spekkhogger og delfin',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'delfin og hai',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'hai og spekkhogger',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
		],
	},
];

export const session91 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'En spekkhogger har sterke ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'finner',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'bein',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'tenner',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'lunger',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Spekkhoggeren er ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'liten',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'mindre',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'minst',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'enorm',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Spekkhoggeren kan holde pusten i opptil ',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '5 minutter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '15 minutter',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: '25 minutter',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '10 minutter',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Spekkhoggeren blir noen ganger kalt ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'reven i havet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ugla i havet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'ulven i havet',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'hvalrossen i havet',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Spekkhoggeren er egentlig den eneste fienden til ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'hvithaien',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'ulven i havet',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'pingvinen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'hvalrossen',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session92 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Delfiner kan ha fargeblandingen ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'svart og rød',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'blå og rosa',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'grønn og hvit',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'svart og hvit',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Delfiner spiser mest ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'skjell',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'tang',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'fisk og blekksprut',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'blekksprut og stein',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Delfiner puster gjennom pustehull ',
				leadingText: '',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'i magen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'på nesen',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'på hodet',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'i munnen',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Delfiner er ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'smarte og lekne ',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'smarte og sinte ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'smarte og trøtte ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'lekne og sure',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Delfiner dør når de ',
				leadingText: ' ',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'spiser stein',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'blir fanget i fiskegarn ',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'får feber',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'jakter',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session93 = [
	{
		words: [
			{
				id: 1,
				precedingText: 'Blekkspruter er veldig ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'store',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'redde',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'smarte',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: 'gule',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: 'Blekkspruter har store ',
				leadingText: ' ',
				correct: ['d'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'ører',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'haler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'munner',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'øyne',
						isAnswered: false,
						isCorrect: true,
					},
				],
			},
			{
				id: 3,
				precedingText: 'Blekkspruter er i slekt med  ',
				leadingText: '',
				correct: ['b'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'isbjørn',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: 'snegler',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 3,
						letter: 'c',
						label: 'fugler',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'krabber',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: 'Hvor mange hjerter har blekkspruter ? ',
				leadingText: ' ',
				correct: ['c'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: ' 1 ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: ' 2 ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: ' 3 ',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 4,
						letter: 'd',
						label: ' 4 ',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: 'Blekkspruter kalles også for ',
				leadingText: ' ',
				correct: ['a'],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: 'blekkfisk',
						isAnswered: false,
						isCorrect: true,
					},
					{
						id: 2,
						letter: 'b',
						label: 'giftfisk',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: 'blekkulf',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: 'bleikfisk',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session94 = [
	{
		words: [
			{
				id: 1,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: ' ',
				leadingText: '',
				correct: [''],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: ' ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
export const session95 = [
	{
		words: [
			{
				id: 1,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 2,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 3,
				precedingText: ' ',
				leadingText: '',
				correct: [''],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 4,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: ' ',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
			{
				id: 5,
				precedingText: ' ',
				leadingText: ' ',
				correct: [''],
				sound: '',
				choices: [
					{
						id: 1,
						letter: 'a',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 2,
						letter: 'b',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 3,
						letter: 'c',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
					{
						id: 4,
						letter: 'd',
						label: '',
						isAnswered: false,
						isCorrect: false,
					},
				],
			},
		],
	},
];
