import React from 'react';
import './styles.scss';
import * as data from './data/no-data';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
import introAudio from '../../../../assets/sounds/Session_all_Morphemes.m4a';
import AudioPlayer from '../../../AudioPlayer';
import TimerCount from '../../../TimerCount';
const getPracticeData = (session) => {
	const indexValue = `session${session}`;
	const sessionData = { allwords: data[indexValue][0].words };
	return sessionData.allwords;
};

var counter = 0;
let resultsArray = [];
const resultsGloabl = [];
let firstCorrectAttempt = true;

class MorphemeAwareness extends React.Component {
	constructor(props) {
		super(props);
		this.practiceData = getPracticeData(this.props.selectedSession);
		this.audio = introAudio;
		let pseudoResults = {};
		for (let i = 0; i < this.practiceData.length; i++) {
			const choices = this.practiceData[i].choices;
			let id = this.practiceData[i].id;
			for (let j = 0; j < choices.length; j++) {
				pseudoResults[id] = '';
			}
			resultsArray.push(pseudoResults);
		}

		this.state = {
			currentCounter: counter,
			rendernext: false,
			allQuestions: this.practiceData,
			resultsArray,
			timeRanOut: false,
		};
	}

	handleAnswer(isAnswered, correct, choice, allQuestions, letter, currentCounter, label, data) {
		if (!isAnswered) {
			if (correct.includes(letter)) {
				choice.isAnswered = true;
				if (currentCounter < allQuestions.length - 1) {
					counter = counter + 1;
					firstCorrectAttempt
						? this.props.flipCoin(3, true)
						: this.props.streak
						? this.props.flipCoin(3, true)
						: this.props.flipCoin();
					firstCorrectAttempt = true;
					this.updateResults(letter, label, {
						isCorrect: true,
					});

					for (let i = 0; i < data.choices.length; i++) {
						if (data.choices[i].label !== label) {
							data.choices[i].label = '';
						}
					}

					this.setState({
						allQuestions,
						currentCounter: counter,
					});
				} else {
					counter = counter + 1;
					firstCorrectAttempt
						? this.props.flipCoin(3, true)
						: this.props.streak
						? this.props.flipCoin(3, true)
						: this.props.flipCoin();
					this.updateResults(letter, label, {
						isCorrect: true,
					});
					firstCorrectAttempt = true;
					for (let i = 0; i < data.choices.length; i++) {
						if (data.choices[i].label !== label) {
							data.choices[i].label = '';
						}
					}
					this.setState({
						allQuestions,

						rendernext: true,
					});
				}
			} else {
				choice.isAnswered = true;
				firstCorrectAttempt = false;
				this.props.recordMistake();
				this.updateResults(letter, label, {
					isCorrect: false,
				});
				this.setState({
					allQuestions,
					currentCounter: counter,
				});
			}
		}
	}
	updateResults(opiton, word, status) {
		resultsGloabl.push({ chosenOption: opiton, chosenWord: word, status });
	}

	render() {
		const { currentCounter, allQuestions } = this.state;
		const { onNextStep } = this.props;
		let audioPlayerRef;
		return (
			<div>
				<div className='rw-intro-text'>
					<p>
						<AudioPlayer
							autoPlay
							inline
							ref={(ref) => {
								audioPlayerRef = ref;
							}}
							src={this.audio}
						/>
						Velg de ordene som passer best{' '}
					</p>
				</div>
				<div className='rw-infobar'>
					<TimerCount
						duration={135}
						onComplete={() => {
							this.setState({
								timeRanOut: true,
							});
						}}
					/>
				</div>

				<div className='rw-pw'>
					{allQuestions.map((data, sessionIndex) => {
						const {
							id,
							precedingText,
							index,
							isCorrect,
							isAnswered,
							leadingText,
							correct,
						} = data;
						if (sessionIndex - 1 < currentCounter) {
							return (
								<div>
									<div
										className='rw-mw-content'
										id={sessionIndex}
									>
										<div className='rw-wp-content__question'>
											<span
												className={
													currentCounter ===
													sessionIndex
														? 'rw-mw-currentLine'
														: 'rw-mw-previousLine'
												}
											>
												<p>
													<span>
														{
															precedingText
														}{' '}
													</span>
													<span className='rw-mc-precedingwords-wrapper'>
														{data.choices.map(
															(
																choice,
																choiceIndex
															) => {
																const {
																	id,
																	label,
																	letter,
																	isAnswered,
																	isCorrect,
																} =
																	choice;
																if (
																	label
																)
																	return (
																		<span
																			className={
																				isAnswered
																					? isCorrect
																						? 'rw-maw-preWord__correct'
																						: 'rw-maw-preWord__wrong'
																					: 'rw-maw-preWord'
																			}
																			onClick={() => {
																				this.handleAnswer(
																					isAnswered,
																					correct,
																					choice,
																					allQuestions,
																					letter,
																					currentCounter,
																					label,
																					data
																				);
																			}}
																		>
																			{
																				label
																			}
																		</span>
																	);
															}
														)}
													</span>
													<span>
														{
															leadingText
														}
													</span>
												</p>
											</span>
										</div>
									</div>
								</div>
							);
						}
					})}
				</div>
				<br></br>
				{(this.state.timeRanOut || this.state.rendernext || this.props.isSuperTeacher) && (
					<div className='rw-next-btn'>
						<button
							className='rw-btn bg-primary'
							onClick={() =>
								onNextStep(
									resultsGloabl,
									ALL_SUBTESTS.MORPHEMEAWARENESS
								)
							}
						>
							{'Neste'}
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default MorphemeAwareness;
