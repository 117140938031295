import getFetchOptions from '../utils/getFetchOptions';
import { ENPOINT_URL } from '../constants/index';

export default function getSessionInfo(session) {
	return fetch(
		`${ENPOINT_URL}/sessioninfo`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				session,
			}),
		})
	).then((response) => {
		if (!response.ok) {
			throw response.json();
		}
		return response.json();
	});
}
