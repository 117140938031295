export function updateObject(oldObject, newValues) {
	return Object.assign({}, oldObject, newValues);
}

export function updateItemInArray(array, entryId, updatedItemCallback) {
	const updatedItems = array.map((item) => {
		if (item.id !== entryId) {
			return item;
		}
		const updatedItem = updatedItemCallback(item);
		return updatedItem;
	});
	return updatedItems;
}

export function removeItemFromArray(array, entryId) {
	return array.filter((item) => item.id !== entryId);
}
