import React from 'react';
import * as data from '../LetterPlacement/data/no-data';
import TimerCount from '../../../TimerCount';
import { connect } from 'react-redux';
import { updateObject } from '../../../../utils/reducerUtils';
import AudioPlayer from '../../../AudioPlayer';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
const getSessionData = (session) => {
	const indexValue = `session${session}`;
	const sessionData = { allwords: data[indexValue][0].words, mainAudio: data[indexValue][0].introAudio };
	return sessionData;
};
let resultsArray = [];
let currentCounter = 0;
const resultsGloabl = [];
class LetterPlacement extends React.Component {
	constructor(props) {
		super(props);
		/* this.audio = introAudio; */
		this.sessionObject = getSessionData(this.props.selectedSession);
		this.sessionData = this.sessionObject.allwords;
		this.audio = this.sessionObject.mainAudio;
		const results = {};
		let pseudoResults = {};

		for (let i = 0; i < this.sessionData.length; i++) {
			let id = this.sessionData[i].id;
			results[id] = '';
		}
		for (let i = 0; i < this.sessionData.length; i++) {
			const choices = this.sessionData[i].choices;
			let id = this.sessionData[i].id;
			for (let j = 0; j < choices.length; j++) {
				pseudoResults[id] = '';
			}
			resultsArray.push(pseudoResults);
		}
		this.state = {
			results,
			sessionContents: this.sessionData,
			resultsArray,
			timerRanOut: false,
		};
	}

	updateResults(value, target, status) {
		resultsGloabl.push({ chosenOption: value, correctOption: target, status });
	}
	handleInputChange(id, value, correctValue, index, position, checked) {
		if (!this.sessionData[index].choices[position].checked && correctValue.includes(value)) {
			this.props.flipCoin();
			this.updateResults(value, correctValue[0], { isCorrect: true });
			if (currentCounter < this.state.resultsArray.length - 1) currentCounter = currentCounter + 1;
		}
		if (!this.sessionData[index].choices[position].checked && !correctValue.includes(value)) {
			this.props.recordMistake();
			this.updateResults(value, correctValue[0], { isCorrect: false });
		}
		if (correctValue.includes(value)) {
			this.sessionData[index].isAnswered = true;
			this.sessionData[index].choices[position].checked = true;
			this.sessionData[index].isCorrect = true;
		} else {
			this.sessionData[index].isAnswered = true;
			this.sessionData[index].isCorrect = false;
		}
		const choice = position + 1;
		let existingValuesAtindex = this.state.resultsArray[index];
		let allExistingValues = this.state.resultsArray;

		const newValues = updateObject(existingValuesAtindex, {
			[choice]: value,
		});

		allExistingValues[index] = newValues;
		this.setState({
			resultsArray: allExistingValues,
		});
		const { results } = this.state;
		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
			resultsArray: allExistingValues,
			sessionContents: this.sessionData,
		});
	}

	render() {
		const { sessionContents, results, resultsArray } = this.state;
		const allQuestionsAnswered = Object.keys(results).every((key) => results[key]);
		const isNextButtonVisible = allQuestionsAnswered;
		const { onNextStep } = this.props;
		let audioPlayerRef;
		return (
			<div>
				<div className='rw-lp-intro'>
					<div className='rw-infobar'>
						<TimerCount
							duration={120}
							onComplete={() => {
								this.setState({ timerRanOut: true });
							}}
						/>
					</div>
					<div className='rw-intro-text'>
						<p>
							<AudioPlayer
								autoPlay
								inline
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								src={sessionContents[currentCounter].sound}
							/>
							Hvor i ordet hører du lyden?
						</p>
					</div>

					<div className='rw-lp'>
						{sessionContents.map((data, sessionIndex) => {
							const {
								word,
								correct,
								isCorrect,
								isAnswered,
								choices,
								img,
							} = data;
							const dataId = data.id;
							const currentQuestionValue = results[dataId];

							if (sessionIndex - 1 < currentCounter) {
								return (
									<div>
										<div
											className='rw-lp-content'
											key={dataId}
										>
											<div className='rw-lp-content__question'>
												<span className='rw-mw-img'>
													{' '}
													<img
														className='rw-mw-img__large'
														src={
															img
														}
													/>
												</span>
												<div className='rw-lp-content__text'>
													<p>
														Hvor
														i
														ordet
														hører
														du{' '}
														<span className='glow-it'>
															{
																data.letter
															}
														</span>{' '}
														i{' '}
														<span>
															{word +
																' ?'}
														</span>
													</p>
												</div>
											</div>

											<div className='rw-lp-content__options'>
												{choices.map(
													(
														position,
														positionIndex,
														checked
													) => {
														const {
															id,
														} =
															position;
														const idChoice =
															dataId +
															position.letter;

														return (
															<span
																className='rw-choice'
																key={
																	idChoice
																}
															>
																<input
																	checked={
																		resultsArray[
																			sessionIndex
																		][
																			positionIndex +
																				1
																		] ===
																		position.letter
																	}
																	className={
																		correct.includes(
																			position.letter
																		)
																			? 'rw-choice__field'
																			: 'rw-choice__wrong'
																	}
																	id={
																		idChoice
																	}
																	onChange={(
																		e
																	) => {
																		this.handleInputChange(
																			dataId,
																			position.letter,
																			correct,
																			sessionIndex,
																			positionIndex,
																			checked
																		);
																	}}
																	type='checkbox'
																/>
																<label
																	className='rw-choice__label'
																	htmlFor={
																		idChoice
																	}
																>
																	<div className='checkbox' />
																	{
																		position.label
																	}
																</label>
															</span>
														);
													}
												)}
											</div>
										</div>
										<div className='sp-scroller-roof'></div>
									</div>
								);
							}
						})}
					</div>
				</div>
				<br></br>
				<br></br>
				{(isNextButtonVisible || this.state.timerRanOut || this.props.isSuperTeacher) && (
					<div className='rw-next-btn'>
						<button
							className='rw-btn bg-primary'
							onClick={() =>
								onNextStep(
									resultsGloabl,
									ALL_SUBTESTS.LETTERPLACEMENT
								)
							}
						>
							{'Neste'}
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default LetterPlacement;
