import { authCallback, initAuth } from '../utils/feideLogin';

export const INIT_AUTH = 'INIT_AUTH';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_AUTH_IFRAME_URL = 'SET_AUTH_IFRAME_URL';
export const initAuthAction = (idType) => {
	return (dispatch) => {
		dispatch({
			type: INIT_AUTH,
		});

		initAuth(idType)
			.then((jsonResponse) => {
				dispatch({
					type: SET_AUTH_IFRAME_URL,
					iframeUrl: jsonResponse.redirect,
				});
			})
			.catch((error) => {
				dispatch({
					type: SET_AUTH_ERROR,
					error,
				});
			});
	};
};

export const RESET_AUTH = 'RESET_AUTH';
export const resetAuthAction = () => {
	window.removeEventListener('message', authCallback);
	return {
		type: RESET_AUTH,
	};
};

/*
export const SUBMIT_TEST_RESULTS = 'SUBMIT_TEST_RES'
export const SET_TEST_STEP_ANSWERS = 'SET_TEST_STEP_ANSWERS';
export const setTestStepAnswers = (step, answers) => {
	return (dispatch) => {
		dispatch({
			type: SUBMIT_TEST_RESULTS,
		});
		dispatch({
			type: SET_TEST_STEP_ANSWERS,
			step,
			answers,
		});
		  // return submitTestAnswers(step,answers) 
	};
}; */
export const SET_CURRENT_STEP_INDEX = 'SET_CURRENT_STEP_INDEX';
export const setCurrentIndex = (stepIndex) => {
	return {
		type: SET_CURRENT_STEP_INDEX,
		stepIndex,
	};
};

export const goToNextStep = () => {
	return (dispatch, getState) => {
		const { currentStepIndex } = getState();
		const stepIndex = currentStepIndex + 1;

		dispatch({
			type: SET_CURRENT_STEP_INDEX,
			stepIndex,
		});
		setTimeout(() => {
			window.scrollTo(0, 0);
		});
	};
};

export const SET_CANDIDATE_SESSION = 'SET_CANDIDATE_SESSION';
export const setCandidateSession = (session) => {
	return {
		type: SET_CANDIDATE_SESSION,
		session,
	};
};
