import React from 'react';
import './styles.scss';
import * as sessionData from './data/Session-data.js';
import { isInProduction, availableSessions } from '../../constants/index';
const getAllSessionsData = () => {
	const sessionItems = sessionData.allSessionData;

	return sessionItems;
};

class DashBoard extends React.Component {
	constructor(props) {
		super(props);
		this.sessions = getAllSessionsData();
		this.myRef = React.createRef();
		this.state = {
			sessionsData: this.sessions,
		};
	}

	componentDidMount() {
		this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	render() {
		const { completedSessions, isSuperTeacher } = this.props;
		const { sessionsData } = this.state;
		const { onSelectingSessions } = this.props;
		const sessionsForTesting = 0;

		return (
			<div className='rw-dashboard'>
				<h1 className='rw-page-title'>Kontrollpanel</h1>

				<p className='rw-page-description'>
					{isInProduction
						? 'Læreren din sier hvilken leksjon du skal velge'
						: 'Velg en leksjon'}
				</p>
				<div className='session-scroller-roof'></div>
				<div className='rw-scroller'>
					<div className='pseudo' ref={this.myRef}>
						<div className='pseudo__inner'>
							<div className='rw-pageWrapper-main'>
								{sessionsData.map((sessionData, index) => {
									const { id, name } = sessionData;
									if (!isSuperTeacher) {
										if (
											index <
												availableSessions &&
											completedSessions.includes(
												id
											)
										) {
											return (
												<span
													className='rw-sessions'
													key={`spanIndex${index}`}
												>
													<button className='rw-styled-button-completed primary-session'>
														<span>
															{
																name
															}
														</span>
													</button>
												</span>
											);
										} else if (
											index <
												availableSessions &&
											!completedSessions.includes(
												id
											)
										) {
											return (
												<span
													className='rw-sessions'
													key={`spanIndex${index}`}
												>
													<button
														className='rw-styled-button primary-session'
														onClick={() => {
															onSelectingSessions(
																id
															);
														}}
													>
														<span>
															{
																name
															}
														</span>
													</button>
												</span>
											);
										} else {
											return (
												<span
													className='rw-sessions'
													key={`spanIndex${index}`}
												>
													<button
														className='rw-styled-button-disabled primary-session'
														onClick={() => {
															return;
														}}
													>
														<span>
															{
																name
															}
														</span>
													</button>
												</span>
											);
										}
									} else {
										if (
											index <
												availableSessions +
													sessionsForTesting &&
											completedSessions.includes(
												id
											)
										) {
											return (
												<span
													className='rw-sessions'
													key={`spanIndex${index}`}
												>
													<button className='rw-styled-button-completed primary-session'>
														<span>
															{
																name
															}
														</span>
													</button>
												</span>
											);
										} else if (
											index <
												availableSessions +
													sessionsForTesting &&
											!completedSessions.includes(
												id
											)
										) {
											return (
												<span
													className='rw-sessions'
													key={`spanIndex${index}`}
												>
													<button
														className='rw-styled-button primary-session'
														onClick={() => {
															onSelectingSessions(
																id
															);
														}}
													>
														<span>
															{
																name
															}
														</span>
													</button>
												</span>
											);
										} else {
											return (
												<span
													className='rw-sessions'
													key={`spanIndex${index}`}
												>
													<button
														className='rw-styled-button-disabled primary-session'
														onClick={() => {
															return;
														}}
													>
														<span>
															{
																name
															}
														</span>
													</button>
												</span>
											);
										}
									}
								})}
							</div>
						</div>
					</div>
				</div>
				<div className='session-scroller-floor'></div>
				<br></br>
				{/* <p className='rw-page-description'>
					Du må spørre læreren din om hvilke leksjonen du bor trykke på. Lykke til!
				</p> */}
			</div>
		);
	}
}
export default DashBoard;
