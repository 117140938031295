import getFetchOptions from '../utils/getFetchOptions';
import { ENPOINT_URL } from '../constants/index';

export default function getUserInfo() {
	return fetch(
		`${ENPOINT_URL}/sessionuser/self`,
		getFetchOptions({
			method: 'GET',
		})
	);
}
