import wolf from '../../../../../assets/Leksjon_0/leksjon0_ulv.png';
import unni from '../../../../../assets/Leksjon_0/leksjon0_unnihund.png';
import dog from '../../../../../assets/Leksjon_0/leksjon0_størsthund.png';

import monkey from '../../../../../assets/Leksjon_2/leksjon2_bavian.png';
import duck from '../../../../../assets/Leksjon_2/leksjon2_badeand.png';
import badeball from '../../../../../assets/Leksjon_2/leksjon2_badeball.png';

import city from '../../../../../assets/Leksjon_1/leksjon1_by.png';
import jet from '../../../../../assets/Leksjon_1/leksjon1_fly.png';
import ylvis from '../../../../../assets/Leksjon_1/leksjon1_ylvishund.png';

import dinosaur from '../../../../../assets/Leksjon_3/leksjon3_dinosaur.png';
import volcano from '../../../../../assets/Leksjon_3/leksjon3_vulkanutbrudd.png';
import usDancing from '../../../../../assets/Leksjon_3/leksjon3_noen_som_danser.png';

import fido from '../../../../../assets/Leksjon_4/leksjon4_hunden_fido.png';
import door from '../../../../../assets/Leksjon_4/leksjon4_dør.png';
import wiper from '../../../../../assets/Leksjon_4/leksjon4_kost.png';

import pants from '../../../../../assets/Leksjon_5/leksjon5_bukse.png';
import yellow from '../../../../../assets/Leksjon_5/leksjon5_gul.png';
import giraffe from '../../../../../assets/Leksjon_5/leksjon5_sjiraff.png';

import mushroom from '../../../../../assets/Leksjon_6/leksjon6_sopp.png';
import springSeason from '../../../../../assets/Leksjon_6/leksjon6_høst.png';
import gate from '../../../../../assets/Leksjon_6/leksjon6_dør.png';

import girls from '../../../../../assets/Leksjon_7/leksjon7_jenter.png';
import singleTree from '../../../../../assets/Leksjon_7/leksjon7_tre.png';
import tiger from '../../../../../assets/Leksjon_7/leksjon7_tiger.png';

import suger from '../../../../../assets/Leksjon_8/leksjon8_sukker.png';
import cream from '../../../../../assets/Leksjon_8/leksjon8_krem.png';
import dentist from '../../../../../assets/Leksjon_8/leksjon8_tannlege.png';

import wholedPants from '../../../../../assets/Leksjon_9/leksjon9_hullete_bukse.png';
import singleLoad from '../../../../../assets/Leksjon_9/leksjon9_lass_med_diamanter.png';
import longPants from '../../../../../assets/Leksjon_9/leksjon9_langbukse.png';

import moon from '../../../../../assets/Leksjon_10/leksjon10_måne.png';
import rocket from '../../../../../assets/Leksjon_10/leksjon10_månerakett.png';

import mobile from '../../../../../assets/Leksjon_11/leksjon11_mobilen.png';
import snakePack from '../../../../../assets/Leksjon_11/leksjon11_nistepakke.png';
import nose from '../../../../../assets/Leksjon_11/leksjon11_nese.png';

import pirate from '../../../../../assets/Leksjon_12/leksjon12_pirat.png';
import treasure from '../../../../../assets/Leksjon_12/leksjon12_skatt.png';
import parrot from '../../../../../assets/Leksjon_12/leksjon12_papegøyen.png';

import runes from '../../../../../assets/Leksjon_13/leksjon13_runer.png';
import someoneThinking from '../../../../../assets/Leksjon_13/leksjon13_en_som_tenker.png';
import spruce from '../../../../../assets/Leksjon_13/leksjon13_gran.png';

import sword from '../../../../../assets/Leksjon_14/leksjon14_sverd.png';
import diningTable from '../../../../../assets/Leksjon_14/leksjon14_spisebord.png';
import knight from '../../../../../assets/Leksjon_14/leksjon14_ridder.png';

import sadDoctor from '../../../../../assets/Leksjon_15/leksjon15_trist_tannlege.png';
import teeth from '../../../../../assets/Leksjon_15/leksjon15_tenner.png';
import toothPaste from '../../../../../assets/Leksjon_15/leksjon15_tannkrem.png';

import viking from '../../../../../assets/Leksjon_16/leksjon16_viking.png';
import wafle from '../../../../../assets/Leksjon_16/leksjon16_vaffler.png';
import helmet from '../../../../../assets/Leksjon_16/leksjon16_hjelm.png';

import mainAudio from '../assets/Oppgave 6. Finn lyden.m4a';
import session1_audio1 from '../assets/Session_1_letterPlacement_1.m4a';
import session1_audio2 from '../assets/Session_1_letterPlacement_2.m4a';
import session1_audio3 from '../assets/Session_1_letterPlacement_3.m4a';

import session2_audio1 from '../assets/Session_2_letterPlacement_1.m4a';
import session2_audio2 from '../assets/Session_2_letterPlacement_2.m4a';
import session2_audio3 from '../assets/Session_2_letterPlacement_3.m4a';

import session4_audio1 from '../assets/Session_4_letterPlacement_1.m4a';
import session4_audio2 from '../assets/Session_4_letterPlacement_2.m4a';
import session4_audio3 from '../assets/Session_4_letterPlacement_3.m4a';

import session5_audio1 from '../assets/Session_5_letterPlacement_1.m4a';
import session5_audio2 from '../assets/Session_5_letterPlacement_2.m4a';
import session5_audio3 from '../assets/Session_5_letterPlacement_3.m4a';

import session6_audio1 from '../assets/Session_6_letterPlacement_1.m4a';
import session6_audio2 from '../assets/Session_6_letterPlacement_2.m4a';
import session6_audio3 from '../assets/Session_6_letterPlacement_3.m4a';

import session7_audio1 from '../assets/Session_7_letterPlacement_1.m4a';
import session7_audio2 from '../assets/Session_7_letterPlacement_2.m4a';
import session7_audio3 from '../assets/Session_7_letterPlacement_3.m4a';

import session8_audio1 from '../assets/Session_8_letterPlacement_1.m4a';
import session8_audio2 from '../assets/Session_8_letterPlacement_2.m4a';
import session8_audio3 from '../assets/Session_8_letterPlacement_3.m4a';

import session9_audio1 from '../assets/Session_9_letterPlacement_1.m4a';
import session9_audio2 from '../assets/Session_9_letterPlacement_2.m4a';
import session9_audio3 from '../assets/Session_9_letterPlacement_3.m4a';

import session10_audio1 from '../assets/Session_10_letterPlacement_1.m4a';
import session10_audio2 from '../assets/Session_10_letterPlacement_2.m4a';
import session10_audio3 from '../assets/Session_10_letterPlacement_3.m4a';

import session11_audio1 from '../assets/Session_11_letterPlacement_1.m4a';
import session11_audio2 from '../assets/Session_11_letterPlacement_2.m4a';
import session11_audio3 from '../assets/Session_11_letterPlacement_3.m4a';

import session12_audio1 from '../assets/Session_12_letterPlacement_1.m4a';
import session12_audio2 from '../assets/Session_12_letterPlacement_2.m4a';
import session12_audio3 from '../assets/Session_12_letterPlacement_3.m4a';

import session13_audio1 from '../assets/Session_13_letterPlacement_1.m4a';
import session13_audio2 from '../assets/Session_13_letterPlacement_2.m4a';
import session13_audio3 from '../assets/Session_13_letterPlacement_3.m4a';

import session14_audio1 from '../assets/Session_14_letterPlacement_1.m4a';
import session14_audio2 from '../assets/Session_14_letterPlacement_2.m4a';
import session14_audio3 from '../assets/Session_14_letterPlacement_3.m4a';

import session15_audio1 from '../assets/Session_15_letterPlacement_1.m4a';
import session15_audio2 from '../assets/Session_15_letterPlacement_2.m4a';
import session15_audio3 from '../assets/Session_15_letterPlacement_3.m4a';

import session16_audio1 from '../assets/Session_16_letterPlacement_1.m4a';
import session16_audio2 from '../assets/Session_16_letterPlacement_2.m4a';
import session16_audio3 from '../assets/Session_16_letterPlacement_3.m4a';

import session17_audio1 from '../assets/Session_17_letterPlacement_1.m4a';
import session17_audio2 from '../assets/Session_17_letterPlacement_2.m4a';
import session17_audio3 from '../assets/Session_17_letterPlacement_3.m4a';

export const session1 = [
	{
		introAudio: mainAudio,
		words: [
			{
				id: 1,
				word: 'Ulv',
				letter: 'u',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: wolf,
				sound: session1_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'Unni',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: unni,
				sound: session1_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'hund',
				letter: 'u',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: dog,
				sound: session1_audio3,

				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session2 = [
	{
		words: [
			{
				id: 1,
				word: 'by',
				letter: 'y',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: city,
				sound: session2_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'Ylvis',
				letter: 'y',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: ylvis,
				sound: session2_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'fly',
				letter: 'y',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: jet,
				sound: session2_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session3 = [
	{
		words: [
			{
				id: 1,
				word: 'bavian',
				letter: 'b',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: monkey,
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'badeand',
				letter: 'b',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: duck,
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'badeball',
				letter: 'b',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['b', 'a'],
				img: badeball,
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session4 = [
	{
		words: [
			{
				id: 1,
				word: 'dinosaur',
				letter: 'd',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: dinosaur,
				sound: session4_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'vulkanutbrudd',
				letter: 'd',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: volcano,
				sound: session4_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'danse',
				letter: 'd',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: usDancing,
				sound: session4_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session5 = [
	{
		words: [
			{
				id: 1,
				word: 'fido',
				letter: 'f',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: fido,
				sound: session5_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'dør',
				letter: 'ø',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: door,
				sound: session5_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'kost',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: wiper,
				sound: session5_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session6 = [
	{
		words: [
			{
				id: 1,
				word: 'bukse',
				letter: 'u',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: pants,
				sound: session6_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'gul',
				letter: 'g',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: yellow,
				sound: session6_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'sjiraff',
				letter: 'f',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: giraffe,
				sound: session6_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session7 = [
	{
		words: [
			{
				id: 1,
				word: 'høst',
				letter: 'h',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: springSeason,
				sound: session7_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'sopp',
				letter: 'p',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: mushroom,
				sound: session7_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'dør',
				letter: 'ø',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: gate,
				sound: session7_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session8 = [
	{
		words: [
			{
				id: 1,
				word: 'jenter',
				letter: 'j',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: girls,
				sound: session8_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'tre',
				letter: 'e',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: singleTree,
				sound: session8_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'tiger',
				letter: 'g',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: tiger,
				sound: session8_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session9 = [
	{
		words: [
			{
				id: 1,
				word: 'sukker',
				letter: 'k',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: suger,
				sound: session9_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'krem',
				letter: 'k',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: cream,
				sound: session9_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'tannlege',
				letter: 'g',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: dentist,
				sound: session9_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session10 = [
	{
		words: [
			{
				id: 1,
				word: 'hullete',
				letter: 'l',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: wholedPants,
				sound: session10_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'lass',
				letter: 'l',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: singleLoad,
				sound: session10_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'langbukse',
				letter: 'b',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: longPants,
				sound: session10_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session11 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: moon,
				sound: session11_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: dog,
				sound: session11_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: rocket,
				sound: session11_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session12 = [
	{
		words: [
			{
				id: 1,
				word: 'mobil',
				letter: 'b',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: mobile,
				sound: session12_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'nistepakke',
				letter: 'k',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: snakePack,
				sound: session12_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'nesen',
				letter: 'n',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['a', 'c'],
				img: nose,
				sound: session12_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session13 = [
	{
		words: [
			{
				id: 1,
				word: 'pirat',
				letter: 'p',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: pirate,
				sound: session13_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'skatt',
				letter: 'k',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: treasure,
				sound: session13_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'papegøye',
				letter: 'g',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: parrot,
				sound: session13_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session14 = [
	{
		words: [
			{
				id: 1,
				word: 'runer',
				letter: 'r',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: runes,
				sound: session14_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'tenker',
				letter: 'r',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: someoneThinking,
				sound: session14_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'gran',
				letter: 'r',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: spruce,
				sound: session14_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session15 = [
	{
		words: [
			{
				id: 1,
				word: 'sverd',
				letter: 's',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: sword,
				sound: session15_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'spisebord',
				letter: 'b',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: diningTable,
				sound: session15_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'ridder',
				letter: 'd',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: knight,
				sound: session15_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session16 = [
	{
		words: [
			{
				id: 1,
				word: 'trist',
				letter: 't',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: sadDoctor,
				sound: session16_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'tenner',
				letter: 'n',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: teeth,
				sound: session16_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'tannkrem',
				letter: 'm',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: toothPaste,
				sound: session16_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session17 = [
	{
		words: [
			{
				id: 1,
				word: 'viking',
				letter: 'v',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: viking,
				sound: session17_audio1,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'vafler',
				letter: 'f',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: wafle,
				sound: session17_audio2,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'hjelm',
				letter: 'm',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: helmet,
				sound: session17_audio3,
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session18 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session19 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session20 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session21 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session22 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session23 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session24 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session25 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session26 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session27 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',

				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session28 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session29 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',

				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
export const session30 = [
	{
		words: [
			{
				id: 1,
				word: 'månen',
				letter: 'm',
				index: 'a',
				isCorrect: false,
				isAnswered: false,
				correct: ['b'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 2,
				word: 'hund',
				letter: 'u',
				index: 'b',
				isCorrect: false,
				isAnswered: false,
				correct: ['a'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
			{
				id: 3,
				word: 'månerakett',
				letter: 't',
				index: 'c',
				isCorrect: false,
				isAnswered: false,
				correct: ['c'],
				img: '',
				sound: '',
				choices: [
					{ id: 1, letter: 'a', label: 'først', checked: false },
					{ id: 2, letter: 'b', label: 'inni', checked: false },
					{ id: 3, letter: 'c', label: 'sist', checked: false },
				],
			},
		],
	},
];
