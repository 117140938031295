import React from 'react';
import { connect } from 'react-redux';
import * as data from './data/no-data';
import AudioPlayer from '../../../AudioPlayer';
import introAudio from './assets/Session _all_Reading aloud_52.m4a';
/* import TimerCount from '../../../TimerCount'; */
import { isInProduction } from '../../../../constants/index';
import printIcon from '../../../../assets/printerIcon.svg';
import printIconAnime from '../../../../assets/printerIconAnime.svg';
import './styles.scss';

/* import { goToNextStep } from '../../../actions/index'; */

const getOpeningIntro = (session) => {
	const indexValue = `session${session}`;
	const openingIntro = data[indexValue][0];
	return openingIntro;
};
class OpeningIntro extends React.Component {
	constructor(props) {
		super(props);
		this.mainAudio = introAudio;
		this.openingIntroData = getOpeningIntro(this.props.selectedSession);
		this.audio_intro = this.openingIntroData.introAudio;
		this.audio_questions = this.openingIntroData.questionAudio;
		this.audio_words = this.openingIntroData.wordsAudio;
		this.state = {
			removeBlur: false,
			hasReadIntroText: false,
			isReadingQuestions: false,
			hasListenedToQuestions: false,
			isReadingWords: true,
			currentPrintIcon: printIcon,
		};
	}

	rendercorrectText(mainHeading, subHeading, summary, para, mainImg, easySummary, audioPlayerRef) {
		if (this.props.selectedSession < 18) {
			if (this.props.totalScore < 90) {
				return (
					<div className={'rw-lk-intro'}>
						<div className='rw-lk-intro__mainContent'>
							<div className='rw-blog'>
								<p className='rw-lk-Heading'>{mainHeading}</p>
							</div>
							<div className={'rw-blog-heading'}>
								<p className='rw-lk-paraHeading'>{subHeading}</p>
							</div>

							<div className='rw-blog-para-summary'>
								{summary.map((text, index) => {
									return (
										<p
											key={index}
											className='rw-lk-para'
										>
											{text}
										</p>
									);
								})}
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div className={'rw-lk-paragraph'}>
						<div className='rw-blog-heading-main'>
							<p className='rw-lk-paraHeading'>{subHeading}</p>
						</div>
						<p className='audio-btn'>
							<AudioPlayer
								inline
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								src={this.audio_intro}
								onPlay={() => {}}
								onComplete={() => {
									this.setState({
										hasReadIntroText: true,
									});
								}}
							/>
						</p>
						<p className='rw-lk-EntirePara'>{para}</p>
					</div>
				);
			}
		} else if (this.props.selectedSession > 50 && this.props.selectedSession < 100) {
			if (this.props.totalScore > 15 && this.props.totalMistakes < 18) {
				return (
					<div className={'rw-lk-paragraph'}>
						<div className='rw-blog-heading-main'>
							<p className='rw-lk-paraHeading'>{subHeading}</p>
						</div>

						<p className='rw-lk-EntirePara'>{para}</p>
					</div>
				);
			} else if (
				this.props.totalScore < 16 &&
				this.props.totalScore > 10 &&
				this.props.totalMistakes < 22
			) {
				return (
					<div className={'rw-lk-intro'}>
						<div className='rw-lk-intro__mainContent'>
							<div className='rw-blog'>
								<p className='rw-lk-Heading'>{mainHeading}</p>
							</div>
							<div className={'rw-blog-heading'}>
								<p className='rw-lk-paraHeading'>{subHeading}</p>
							</div>

							<div className='rw-blog-para-summary'>
								{summary.map((text, index) => {
									return (
										<p
											key={index}
											className='rw-lk-para'
										>
											{text}
										</p>
									);
								})}
							</div>
						</div>
					</div>
				);
			} else if (this.props.totalScore < 11 || this.props.totalMistakes > 15) {
				return (
					<div className={'rw-lk-intro'}>
						<div className='rw-lk-intro__mainContent'>
							<div className='rw-blog'>
								<p className='rw-lk-Heading'>{mainHeading}</p>
							</div>
							<div className={'rw-blog-heading'}>
								<p className='rw-lk-paraHeading'>{subHeading}</p>
							</div>

							<div className='rw-blog-para-summary'>
								{easySummary.map((text, index) => {
									return (
										<p
											key={index}
											className='rw-lk-para'
										>
											{text}
										</p>
									);
								})}
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div className={'rw-lk-intro'}>
						<div className='rw-lk-intro__mainContent'>
							<div className='rw-blog'>
								<p className='rw-lk-Heading'>{mainHeading}</p>
							</div>
							<div className={'rw-blog-heading'}>
								<p className='rw-lk-paraHeading'>{subHeading}</p>
							</div>

							<div className='rw-blog-para-summary'>
								{easySummary.map((text, index) => {
									return (
										<p
											key={index}
											className='rw-lk-para'
										>
											{text}
										</p>
									);
								})}
							</div>
						</div>
					</div>
				);
			}
		} else {
			if (this.props.totalScore > 35) {
				return (
					<div className={'rw-lk-paragraph'}>
						<div className='rw-blog-heading-main'>
							<p className='rw-lk-paraHeading'>{subHeading}</p>
						</div>

						<p className='rw-lk-EntirePara'>{para}</p>
					</div>
				);
			} else if (this.props.totalScore < 36 && this.props.totalScore > 22) {
				return (
					<div className={'rw-lk-intro'}>
						<div className='rw-lk-intro__mainContent'>
							<div className='rw-blog'>
								<p className='rw-lk-Heading'>{mainHeading}</p>
							</div>
							<div className={'rw-blog-heading'}>
								<p className='rw-lk-paraHeading'>{subHeading}</p>
							</div>

							<div className='rw-blog-para-summary'>
								{summary.map((text, index) => {
									return (
										<p
											key={index}
											className='rw-lk-para'
										>
											{text}
										</p>
									);
								})}
							</div>
						</div>
					</div>
				);
			} else if (this.props.totalScore < 23) {
				return (
					<div className={'rw-lk-intro'}>
						<div className='rw-lk-intro__mainContent'>
							<div className='rw-blog'>
								<p className='rw-lk-Heading'>{mainHeading}</p>
							</div>
							<div className={'rw-blog-heading'}>
								<p className='rw-lk-paraHeading'>{subHeading}</p>
							</div>

							<div className='rw-blog-para-summary'>
								{easySummary.map((text, index) => {
									return (
										<p
											key={index}
											className='rw-lk-para'
										>
											{text}
										</p>
									);
								})}
							</div>
						</div>
					</div>
				);
			}
		}
	}

	render() {
		const { onNextStep } = this.props;
		const {
			mainHeading,
			subHeading,
			easySummary,
			summary,
			para,
			repeatParaHeading,
			helpingQuestionHeading,
			helpingQuestions,
			revisionParaHeading,
			mainImg,
			revisionWordsAndInstruction,
		} = this.openingIntroData;
		let audioPlayerRef;
		return (
			<div className='rw-lk-introWrapper'>
				<div className='rw-lk-intro__mainImg'>
					<img src={mainImg}></img>
				</div>
				<div className='rw-intro-text'>
					<p>
						<AudioPlayer
							autoPlay
							inline
							ref={(ref) => {
								audioPlayerRef = ref;
							}}
							src={this.mainAudio}
						/>
						Lese ordene høyt minst tre ganger
					</p>
				</div>
				<br></br>
				{!this.state.isReadingWords &&
					this.rendercorrectText(
						mainHeading,
						subHeading,
						summary,
						para,
						mainImg,
						easySummary,
						audioPlayerRef
					)}

				{this.state.isReadingWords && (
					<div>
						<div className={'rw-blog-heading'}>
							<p className='rw-lk-paraHeading'>{revisionParaHeading}</p>
						</div>
						<div className={'rw-lk-revision'}>
							<div className='rw-lk-revision__points'>
								<p className='rw-lk-para__heavy'></p>
								{revisionWordsAndInstruction.words.map(
									(word, index) => {
										return (
											<p
												key={`rw-lk-${index}`}
												className='rw-lk-para'
											>
												{word}
											</p>
										);
									}
								)}
							</div>
							{/* <div className='rw-lk-revision__Questions'>
						<p className='rw-lk-para__heavy'>
							{revisionWordsAndInstruction.questionInstructions}
						</p>
						{revisionWordsAndInstruction.questions.map((question, index) => {
							return (
								<p
									key={`rw-lk-para${index}`}
									className='rw-lk-para'
								>
									{question}
								</p>
							);
						})}
					</div> */}
						</div>
					</div>
				)}

				<br></br>

				{this.state.isReadingWords && (
					<div className={'rw-next-btn-bg-more'}>
						<button
							className={'rw-btn bg-more'}
							onClick={() => {
								this.props.onChangeProgressCounter();
								this.setState({
									isReadingWords: false,
								});
							}}
						>
							{'>'}
						</button>
					</div>
				)}

				{(!this.state.isReadingWords || this.props.isSuperTeacher) && (
					<div className='rw-next-btn'>
						{/* change it back to blur */}
						<button
							className='rw-btn bg-primary'
							/* 	disabled={!this.state.removeBlur} */
							/* Uncommnet out this */
							onClick={() => {
								onNextStep('', { isLast: true });
							}}
						>
							{'Neste'}
						</button>
					</div>
				)}
			</div>
		);
	}
}
const mapStateToProps = () => {
	return {};
};
export default connect(mapStateToProps)(OpeningIntro);
