import getFetchOptions from '../utils/getFetchOptions';
import { ENPOINT_URL, SUBTEST_IDS } from '../constants/index';

export default function submitAnswers(answers, subtest, session, rewardPoints) {
	const testId = SUBTEST_IDS[subtest];

	return fetch(
		`${ENPOINT_URL}/session/result/${testId}`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				answers,
				session,
				subtest,
				rewardPoints,
			}),
		})
	);
}
