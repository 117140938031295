const pauseAndReset = (audioElement) => {
	if (!audioElement) {
		return;
	}
	audioElement.pause();
	audioElement.currentTime = 0;
};

const playPause = (audioElement, otherAudioElements = {}) => {
	if (audioElement.paused) {
		Object.keys(otherAudioElements).forEach((key) => {
			pauseAndReset(otherAudioElements[key]);
		});

		audioElement.play();
	} else {
		pauseAndReset(audioElement);
	}
};
export default playPause;
