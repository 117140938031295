import iris from '../assets/iris.png';
import multipleIris from '../assets/multipleIris.png';

/* import jet from '../assets/jet.png'; */
import multipleJets from '../assets/multipleJets.png';
/* import animals from '../assets/animals.png'; */
import multipleAnimals from '../assets/multipleAnimals.png';
/* import Jewellery from '../assets/Jewellery.jpeg'; */
import Jewellery from '../../../../../assets/Leksjon_2/leksjon2_smykke.png';
import multipleJewellery from '../../../../../assets/Leksjon_2/leksjon2_smykkene.png';
/* import multipleJewellery from '../assets/multipleJewellery.jpg'; */
import banana from '../../../../../assets/Leksjon_2/leksjon2_banan.png';
/* import banana from '../assets/banana.png'; */
import car from '../../../../../assets/Leksjon_2/leksjon2_bil.png';
import cars from '../../../../../assets/Leksjon_2/leksjon2_biler.png';
import multipleDogs from '../../../../../assets/Leksjon_0/leksjon0_hundene.png';
import dog from '../../../../../assets/Leksjon_0/leksjon0_størsthund.png';
import wolf from '../../../../../assets/Leksjon_0/leksjon0_ulv.png';
import wolves from '../../../../../assets/Leksjon_0/leksjon0_ulvene.png';

import vacuumCleaner from '../../../../../assets/Leksjon_1/leksjon1_støvsuger.png';
import vacuumCleaners from '../../../../../assets/Leksjon_1/leksjon1_støvsugerne.png';
import jet from '../../../../../assets/Leksjon_1/leksjon1_fly.png';
import jets from '../../../../../assets/Leksjon_1/leksjon1_mangefly.png';

import animal from '../../../../../assets/Leksjon_1/leksjon1_dyret.png';
import animals from '../../../../../assets/Leksjon_1/leksjon1_dyrene.png';

import cat from '../../../../../assets/Leksjon_0/leksjon0_katt.png';
import cats from '../../../../../assets/Leksjon_0/leksjon0_kattene.png';
/* import car from '..//assets/car.png'; */
import multipleBananas from '../../../../../assets/Leksjon_2/leksjon2_bananer.png';

import volcano from '../../../../../assets/Leksjon_3/leksjon3_vulkanutbrudd.png';
import volcanos from '../../../../../assets/Leksjon_3/leksjon3_vulkaner.png';
import dinosaur from '../../../../../assets/Leksjon_3/leksjon3_dinosaur.png';
import dinosaurs from '../../../../../assets/Leksjon_3/leksjon3_dinosaurene.png';
import egg from '../../../../../assets/Leksjon_3/leksjon3_egget.png';
import eggs from '../../../../../assets/Leksjon_3/leksjon3_eggene.png';

import frog from '../../../../../assets/Leksjon_4/leksjon4_frosk.png';
import frogs from '../../../../../assets/Leksjon_4/leksjon4_froskene.png';
import door from '../../../../../assets/Leksjon_4/leksjon4_dør.png';
import doors from '../../../../../assets/Leksjon_4/leksjoner4_dører.png';
import toilet from '../../../../../assets/Leksjon_4/leksjon4_toalett.png';
import toilets from '../../../../../assets/Leksjon_4/leksjon4_toalettene.png';

/* import multipleBananas from '../assets/multipleBananas.png'; */
import sweater from '../../../../../assets/Leksjon_5/leksjon5_genseren.png';
import sweaters from '../../../../../assets/Leksjon_5/leksjon5_genserne.png';
import pants from '../../../../../assets/Leksjon_5/leksjon5_bukse.png';
import multiplePants from '../../../../../assets/Leksjon_5/leksjon5_buksene.png';
import giraffe from '../../../../../assets/Leksjon_5/leksjon5_sjiraff.png';
import giraffs from '../../../../../assets/Leksjon_5/leksjon5_sjiraffene.png';

import glass from '../../../../../assets/Leksjon_6/leksjon6_glass.png';
import glasses from '../../../../../assets/Leksjon_6/leksjon6_glassene.png';
import mushroom from '../../../../../assets/Leksjon_6/leksjon6_sopp.png';
import mushrooms from '../../../../../assets/Leksjon_6/leksjon6_soppene.png';
import bee from '../../../../../assets/Leksjon_6/leksjon6_feen.png';
import bees from '../../../../../assets/Leksjon_6/leksjon6_feene.png';

import hat from '../../../../../assets/Leksjon_7/leksjon7_hatten.png';
import hats from '../../../../../assets/Leksjon_7/leksjon7_hattene.png';
import tiger from '../../../../../assets/Leksjon_7/leksjon7_tiger.png';
import tigers from '../../../../../assets/Leksjon_7/leksjon7_tigrene.png';
import rabbit from '../../../../../assets/Leksjon_7/leksjon7_kaninen.png';
import rabbits from '../../../../../assets/Leksjon_7/leksjon7_kaninene.png';

import caramel from '../../../../../assets/Leksjon_8/leksjon8_karamellen.png';
import caramels from '../../../../../assets/Leksjon_8/leksjon8_karamellene.png';
import tooth from '../../../../../assets/Leksjon_8/leksjon8_tannen.png';
import teeth from '../../../../../assets/Leksjon_8/leksjon8_tennene.png';
import cake from '../../../../../assets/Leksjon_8/leksjon8_kaken.png';
import cakes from '../../../../../assets/Leksjon_8/leksjon8_kakene.png';

import mine from '../../../../../assets/Leksjon_9/leksjon9_gruven.png';
import mines from '../../../../../assets/Leksjon_9/leksjon9_gruvene.png';
import ruby from '../../../../../assets/Leksjon_9/leksjon9_rubinen.png';
import rubies from '../../../../../assets/Leksjon_9/leksjon9_rubiner.png';
import jacket from '../../../../../assets/Leksjon_9/leksjon9_jakken.png';
import jackets from '../../../../../assets/Leksjon_9/leksjon9_jakker.png';

import meteor from '../../../../../assets/Leksjon_10/leksjon10_meteor.png';
import meteors from '../../../../../assets/Leksjon_10/leksjon10_meteorer.png';
import moon from '../../../../../assets/Leksjon_10/leksjon10_måne.png';
import moons from '../../../../../assets/Leksjon_10/leksjon10_månene.png';
import rocket from '../../../../../assets/Leksjon_10/leksjon10_månerakett.png';
import rockets from '../../../../../assets/Leksjon_10/leksjon10_raketter.png';

import clown from '../../../../../assets/Leksjon_11/leksjon11_narren.png';
import clowns from '../../../../../assets/Leksjon_11/leksjon11_narrene.png';
import mobile from '../../../../../assets/Leksjon_11/leksjon11_mobilen.png';
import mobiles from '../../../../../assets/Leksjon_11/leksjon11_mobilene.png';
import snakePack from '../../../../../assets/Leksjon_11/leksjon11_nistepakke.png';
import snakePacks from '../../../../../assets/Leksjon_11/leksjon11_nistepakkene.png';

/* CURRNT session 13 */
import treasureChest from '../../../../../assets/Leksjon_12/leksjon12_skattekisten.png';
import treasureChests from '../../../../../assets/Leksjon_12/leksjon12_skattekistene.png';
import cheek from '../../../../../assets/Leksjon_12/leksjon12_kinnet.png';
import cheeks from '../../../../../assets/Leksjon_12/leksjon12_kinnene.png';
import parrot from '../../../../../assets/Leksjon_12/leksjon12_papegøyen.png';
import parrots from '../../../../../assets/Leksjon_12/leksjon12_papegøyene.png';

/* CURRNT session 14 */
import robber from '../../../../../assets/Leksjon_13/leksjon13_røver.png';
import robbers from '../../../../../assets/Leksjon_13/leksjon13_røvere.png';
import spruce from '../../../../../assets/Leksjon_13/leksjon13_gran.png';
import spruces from '../../../../../assets/Leksjon_13/leksjon13_granene.png';
import farmer from '../../../../../assets/Leksjon_13/leksjon13_bonde.png';
import farmers from '../../../../../assets/Leksjon_13/leksjon13_bønder.png';

/* CURRNT session 15 */
import knight from '../../../../../assets/Leksjon_14/leksjon14_ridder.png';
import knights from '../../../../../assets/Leksjon_14/leksjon14_ridderne.png';
import sword from '../../../../../assets/Leksjon_14/leksjon14_sverd.png';
import swords from '../../../../../assets/Leksjon_14/leksjon14_sverdene.png';
import eye from '../../../../../assets/Leksjon_14/leksjon14_øye.png';
import eyes from '../../../../../assets/Leksjon_14/leksjon14_øynene.png';

/* CURRNT session 16 */
import singleTooth from '../../../../../assets/Leksjon_15/leksjon15_tannen.png';
import manyTeeth from '../../../../../assets/Leksjon_15/leksjon15_tennene.png';
import toothPaste from '../../../../../assets/Leksjon_15/leksjon15_tannkrem.png';
import manyToothPaste from '../../../../../assets/Leksjon_15/leksjon15_tannkremene.png';
import toothFairy from '../../../../../assets/Leksjon_15/leksjon15_tannfeen.png';
import toothFairies from '../../../../../assets/Leksjon_15/leksjon15_tannfeene.png';

/* CURRNT session 17 */
import helmet from '../../../../../assets/Leksjon_16/leksjon16_hjelm.png';
import helmets from '../../../../../assets/Leksjon_16/leksjon16_hjelmene.png';
import viking from '../../../../../assets/Leksjon_16/leksjon16_viking.png';
import vikings from '../../../../../assets/Leksjon_16/leksjon16_vikinger.png';
import horn from '../../../../../assets/Leksjon_16/leksjon16_horn.png';
import horns from '../../../../../assets/Leksjon_16/leksjon16_hornene.png';

export const session1 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: wolves,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Ulvene',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Ulv',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'Ulvene',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: wolf,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Ulv',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-sp-img__small',
				img: multipleDogs,
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Hundene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Hund',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Hundene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Hund',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Katt',
			},
			{
				words: [
					{
						id: 4,
						word: 'Katt',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Kattene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cats,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Kattene',
			},
		],
	},
];
export const session2 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: vacuumCleaner,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Støvsugeren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Støvsugeren',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Støvsugerne',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: vacuumCleaners,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Støvsugerne',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: jets,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Flyene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Flyet',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Flyene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: jet,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Flyet',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: animal,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Dyret',
			},
			{
				words: [
					{
						id: 4,
						word: 'Dyret',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Dyrene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: animals,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Dyrene',
			},
		],
	},
];
export const session3 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleJewellery,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Smykkene',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Smykket',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						correctClassName: 'rw-sp-word-correct',
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Smykkene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: Jewellery,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'smykket',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: banana,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Bananen',
			},
			{
				words: [
					{
						id: 2,
						word: 'Bananen',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Bananene',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleBananas,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Bananene',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: car,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Bilen',
			},
			{
				words: [
					{
						id: 4,
						word: 'Bilene',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Bilen',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cars,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Bilene',
			},
		],
	},
];
export const session4 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: volcanos,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Vulkaner',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Vulkan',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						correctClassName: 'rw-sp-word-correct',
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Vulkaner',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: volcano,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Vulkan',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: dinosaur,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Dinosaur',
			},
			{
				words: [
					{
						id: 2,
						word: 'Dinosaur',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Dinosaurer',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dinosaurs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Dinosaurer',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: egg,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Egg',
			},
			{
				words: [
					{
						id: 4,
						word: 'Egg',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Egg',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: eggs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Egg',
			},
		],
	},
];
export const session5 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: frogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Froskene',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Frosken',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'Froskene',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: frog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Frosken',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-sp-img__small',
				img: doors,
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Dørene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Døren',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Dørene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: door,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Døren',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: toilets,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Toalettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Toalettet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Toalettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: toilet,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Toalettet',
			},
		],
	},
];
export const session6 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: sweater,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Genseren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Genseren',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Genserne',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: sweaters,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Genserne',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: pants,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Buksen',
			},
			{
				words: [
					{
						id: 2,
						word: 'Buksene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Buksen',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multiplePants,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Buksene',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: giraffs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Sjiraffene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Sjiraffen',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Sjiraffene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: giraffe,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Sjiraffen',
			},
		],
	},
];
export const session7 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: glass,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Glasset',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Glassene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Glasset',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: glasses,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Glassene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: bee,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Feen',
			},
			{
				words: [
					{
						id: 2,
						word: 'Feen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Feene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: bees,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Feene',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: mushrooms,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Soppene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Soppen',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Soppene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: mushroom,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Soppen',
			},
		],
	},
];
export const session8 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: rabbit,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Kaninen',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Kaninen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Kaninene',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: rabbits,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Kaninene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: hat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Hatten',
			},
			{
				words: [
					{
						id: 2,
						word: 'Hattene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Hatten',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: hats,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Hattene',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tiger,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Tigeren',
			},
			{
				words: [
					{
						id: 4,
						word: 'Tigeren',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Tigrene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: tigers,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Tigrene',
			},
		],
	},
];
export const session9 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: caramel,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Karamellen',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Karamellene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Karamellen',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: caramels,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Karamellene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: teeth,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Tennene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Tannen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Tennene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: tooth,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Tannen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: cakes,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Kakene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Kaken',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Kakene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cake,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Kaken',
			},
		],
	},
];
export const session10 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: mine,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Gruven',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Gruvene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Gruven',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: mines,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Gruvene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: rubies,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rubinene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Rubinen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Rubinene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ruby,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rubinen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: jackets,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Jakkene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Jakken',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Jakkene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: jacket,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Jakken',
			},
		],
	},
];
export const session11 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: meteor,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: meteors,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: moons,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: moon,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: rockets,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: rocket,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session12 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: clown,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Narren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Narrene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Narren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: clowns,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Narrene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: mobiles,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Mobilene',
			},
			{
				words: [
					{
						id: 2,

						word: 'Mobilen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Mobilene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: mobile,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Mobilen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: snakePacks,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Nistepakkene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Nistepakken',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Nistepakkene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: snakePack,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Nistepakken',
			},
		],
	},
];
export const session13 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: treasureChest,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Kisten',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Kistene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Kisten',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: treasureChests,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Kistene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: cheeks,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Kinnene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Kinnet',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Kinnene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cheek,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Kinnet',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: parrots,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Papegøyene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Papegøyen',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Papegøyene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: parrot,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Papegøyen',
			},
		],
	},
];
export const session14 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: robber,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Røveren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Røverne',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Røveren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: robbers,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Røverne',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: spruces,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Granene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Granen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Granene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: spruce,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Granen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: farmers,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Bøndene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Bonden',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Bøndene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: farmer,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Bonden',
			},
		],
	},
];
export const session15 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: knight,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Ridderen',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Ridderne',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Ridderen',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: knights,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Ridderne',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: swords,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Sverdene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Sverdet',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Sverdene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: sword,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Sverdet',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: eyes,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Øynene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Øyet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Øynene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: eye,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Øyet',
			},
		],
	},
];
export const session16 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: manyTeeth,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Tennene',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Tannen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Tennene',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: singleTooth,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Tannen',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: toothPaste,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Tannkremen',
			},
			{
				words: [
					{
						id: 2,
						word: 'Tannkremene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Tannkremen',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: manyToothPaste,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Tannkremene',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: toothFairy,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Tannfeen',
			},
			{
				words: [
					{
						id: 4,
						word: 'Tannfeen',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Tannfeene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: toothFairies,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Tannfeene',
			},
		],
	},
];
export const session17 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: helmet,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Hjelmen',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Hjelmene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Hjelmen',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: helmets,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Hjelmene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: viking,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Vikingen',
			},
			{
				words: [
					{
						id: 2,
						word: 'Vikingen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Vikingene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: vikings,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Vikingene',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: horn,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Hornet',
			},
			{
				words: [
					{
						id: 4,
						word: 'Hornene',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Hornet',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: horns,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Hornene',
			},
		],
	},
];
export const session18 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session19 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session20 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session21 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session22 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session23 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session24 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session25 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session26 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session27 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session28 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session29 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
export const session30 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteoren',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'Meteorene',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 1,
						word: 'Meteoren',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Meteorene',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				img: multipleDogs,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månene',
			},
			{
				words: [
					{
						id: 2,
						word: 'Månen',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Månene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: cat,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Månen',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: iris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Rakettene',
			},
			{
				words: [
					{
						id: 4,
						word: 'Raketten',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'Rakettene',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-sp-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleIris,
				currentClassName: 'rw-sp-img__small',
				defaultClassName: 'rw-sp-img__small',
				hoverClassName: 'rw-sp-img__small__hover',
				selectClassName: 'rw-sp-img__small__select',
				inCorrectClassName: 'rw-sp-img__small__wrong',
				correctClassName: 'rw-sp-img__small__correct',
				isCorrect: false,
				isAnswered: false,
				disable: true,
				correctAnswer: 'Raketten',
			},
		],
	},
];
