import { ENPOINT_URL } from '../constants';
const headers = new Headers();
const method = 'feide';
headers.append('Content-Type', 'application/json');
const authErrorMessage = {
	message: 'Kunne ikke logge inn!',
};
function isValidJsonString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}
export const authCallback = (event) => {
	console.log(event.origin);
	const data = event.data ? JSON.parse(event.data) : null;
	if (data && data.action === 'auth' && data.result === 'OK') {
		window.removeEventListener('message', authCallback);
		localStorage.setItem('authtoken-readwell', data.authToken);
		window.location.href = '/test';
	}
};

export const initAuth = () => {
	const body = JSON.stringify({
		method,
		origin: 'readWell',
	});
	return fetch(`${ENPOINT_URL}/initAuth`, {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		headers,
		body,
	}).then((response) => {
		if (response.ok) {
			window.addEventListener('message', authCallback);
			return response.json();
		}
		return Promise.reject();
	});
};
