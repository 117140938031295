import React from 'react';
import { connect } from 'react-redux';
import '../styles.scss';
import TimerCount from '../../../TimerCount';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
import { goToNextStep } from '../../../../actions';
import introAudio from '../../../../assets/sounds/matching-wrods-intro.m4a';
import AudioPlayer from '../../../AudioPlayer';
import { updateObject } from '../../../../utils/reducerUtils';
import * as data from './data/no-data';
const defaultClassName = 'rw-mw-container__Questions';
const selectClassName = 'rw-mw-container__Questions__select';
const correctClassName = 'rw-mw-container__Questions__correct';
const wrongClassName = 'rw-mw-container__Questions__wrong';
let clicks = 0;
var wordsToCompare = [];
const defaultMatches = [];
let allMatches = null;
let correctCounter = 0;
const resultsGloabl = [];
let correctCount = 0;
let consecutiveCorrect = 0;
let firstCorrectAttempt = true;
/* Leksjon 16 for matching words refrence for multiple matching words
starting with the same word!!! */

const getMatchingWordsForSession = (session) => {
	const indexValue = `Session${session}`;

	let sessionData;
	sessionData = data[indexValue][0].matchingWord;
	return sessionData;
};
const getDefaultMatchingWordsForSession = (session) => {
	const indexValue = `Session${session}`;
	let sessionData;
	sessionData = data[indexValue][0].defaultMatchingWordsAnime;
	return sessionData;
};
const getAllAnswersForSessions = (session) => {
	const indexValue = `Session${session}`;
	let sessionData;
	sessionData = data[indexValue][0].answers;
	return sessionData;
};

const getNumberOfMatches = (session) => {
	const indexValue = `Session${session}`;
	let sessionData;
	sessionData = data[indexValue][0].totalMatches;
	return sessionData;
};
class MatchingWords extends React.Component {
	constructor(props) {
		super(props);
		this.audio = introAudio;
		this.matchingWords = getMatchingWordsForSession(this.props.selectedSession);
		this.defaultMatchingWordsAnime = getDefaultMatchingWordsForSession(this.props.selectedSession);
		this.answers = getAllAnswersForSessions(this.props.selectedSession);
		this.totalMatches = getNumberOfMatches(this.props.selectedSession);
		const results = {};

		for (let i = 0; i < this.matchingWords.length; i++) {
			let id = this.matchingWords[i].id;
			results[id] = '';
		}
		allMatches = this.matchingWords.length / 2;

		this.state = {
			matchingTasks: this.matchingWords,
			selectedMatch: null,
			results: null,
			matchedWords: [],
			allowNextBtn: false,
			allResults: results,
			allMatches,
			matchingWordsAnime: this.defaultMatchingWordsAnime,
		};

		this.setUpMatchingTasks = this.setUpMatchingTasks.bind(this);
		this.evaluteItemSelection = this.evaluteItemSelection.bind(this);
	}
	componentDidMount() {
		this.setUpMatchingTasks();
	}
	setUpMatchingTasks() {
		this.setState({
			matchingTasks: this.matchingWords,
			matchingWordsAnime: this.defaultMatchingWordsAnime,
		});
	}
	evaluteItemSelection(id, index, task) {
		const { matchingWordsAnime, results } = this.state;
		clicks = clicks + 1;
		const indexValue = index;
		switch (index) {
			case index:
				if (clicks % 2 === 0) {
					wordsToCompare.push(matchingWordsAnime[indexValue]);
					this.updateResults(indexValue);
				} else {
					wordsToCompare.push(matchingWordsAnime[indexValue]);
				}
				break;
		}

		this.setState({
			matchingTasks: this.matchingWords,
			results: wordsToCompare,
		});
	}

	updateResults = async (index) => {
		const results = await this.state.results;
		const indexValue = results.length - 1;

		if (results[indexValue]['matchingWord'].includes(results[indexValue - 1]['word'])) {
			const firstClick = results[indexValue - 1];
			const secondClick = results[indexValue];
			let combinedWords = `${firstClick['word']}${secondClick['word']}`;
			if (this.answers.includes(combinedWords)) {
				if (this.checkMatchedWords(combinedWords).hasCurrentWord) {
					const shineIndex = this.checkMatchedWords(combinedWords).indexValue;
					this.shineMatchedWords(shineIndex, firstClick.id, secondClick.id);
					setTimeout(() => {
						this.matchingWords[secondClick.id].className = defaultClassName;
						this.matchingWords[firstClick.id].className = defaultClassName;
						this.matchingWords[secondClick.id].disable = false;
						this.matchingWords[firstClick.id].disable = false;
						this.setState({
							matchingTasks: this.matchingWords,
						});
					}, 1400);
				} else {
					defaultMatches.push({
						firstEnd: firstClick['word'],
						lastEnd: secondClick['word'],
						combinedWords,
					});
					this.updateGlobalObject(
						this.matchingWords[firstClick.id].word,
						this.matchingWords[secondClick.id].word,
						{ isCorrect: true }
					);
					this.matchingWords[secondClick.id].disable = false;
					this.matchingWords[firstClick.id].disable = false;
					this.matchingWords[secondClick.id].className = defaultClassName;

					this.matchingWords[firstClick.id].className = defaultClassName;
					consecutiveCorrect = consecutiveCorrect + 1;
					consecutiveCorrect > 1 || this.props.streak || firstCorrectAttempt
						? this.props.flipCoin(5, true)
						: this.props.flipCoin(2, false);
				}

				this.setState({
					matchingTasks: this.matchingWords,
					matchedWords: defaultMatches,
				});

				if (defaultMatches.length === this.totalMatches) {
					setTimeout(() => {
						this.reArrangeMatchedWords();
					}, 1000);
				}
			} else {
				combinedWords = `${secondClick['word']}${firstClick['word']}`;
				if (this.answers.includes(combinedWords)) {
					if (this.checkMatchedWords(combinedWords).hasCurrentWord) {
						const shineIndex = this.checkMatchedWords(combinedWords).indexValue;
						this.shineMatchedWords(shineIndex, firstClick.id, secondClick.id);
						setTimeout(() => {
							this.matchingWords[secondClick.id].className =
								defaultClassName;
							this.matchingWords[firstClick.id].className =
								defaultClassName;
							this.matchingWords[secondClick.id].disable = false;
							this.matchingWords[firstClick.id].disable = false;
							this.setState({
								matchingTasks: this.matchingWords,
							});
						}, 1400);
					} else {
						defaultMatches.push({
							firstEnd: secondClick['word'],
							lastEnd: firstClick['word'],
							combinedWords,
						});
						this.updateGlobalObject(
							this.matchingWords[firstClick.id].word,
							this.matchingWords[secondClick.id].word,
							{ isCorrect: true }
						);
						this.matchingWords[secondClick.id].disable = false;

						this.matchingWords[firstClick.id].disable = false;
						this.matchingWords[secondClick.id].className = defaultClassName;

						this.matchingWords[firstClick.id].className = defaultClassName;
						consecutiveCorrect = consecutiveCorrect + 1;
						consecutiveCorrect > 1 || this.props.streak || firstCorrectAttempt
							? this.props.flipCoin(5, true)
							: this.props.flipCoin(2, false);
					}

					this.setState({
						matchingTasks: this.matchingWords,
						matchedWords: defaultMatches,
					});
					if (defaultMatches.length === this.totalMatches) {
						this.reArrangeMatchedWords();
					}
				}
			}
		} else {
			const firstClick = results[indexValue - 1];
			const secondClick = results[indexValue];
			this.props.recordMistake();
			consecutiveCorrect = 0;
			firstCorrectAttempt = false;

			this.updateGlobalObject(
				this.matchingWords[firstClick.id].word,
				this.matchingWords[secondClick.id].word,
				{ isCorrect: false }
			);
			this.matchingWords[secondClick.id].className = wrongClassName;
			this.matchingWords[firstClick.id].className = wrongClassName;
			this.matchingWords[secondClick.id].disable = true;
			this.matchingWords[results[indexValue - 1].id].disable = true;
			this.setState(
				{
					matchingTasks: this.matchingWords,
				},
				() => {
					setTimeout(() => {
						this.matchingWords[secondClick.id].className = defaultClassName;
						this.matchingWords[results[indexValue - 1].id].className =
							defaultClassName;
						this.matchingWords[secondClick.id].disable = false;
						this.matchingWords[results[indexValue - 1].id].disable = false;
						this.setState({
							matchingTasks: this.matchingWords,
						});
					}, 1000);
				}
			);
		}
	};
	reArrangeMatchedWords() {
		let k = 0;
		for (let i = 0; i < defaultMatches.length; i++) {
			for (let j = k; j < k + 2; j++) {
				let firstEnd = defaultMatches[i].firstEnd;
				let lastEnd = defaultMatches[i].lastEnd;
				let combinedWords = defaultMatches[i].combinedWords;
				let indexOfFirstEnd = combinedWords.indexOf(firstEnd);

				if (indexOfFirstEnd === 0 && j + 1 < this.matchingWords.length) {
					this.matchingWords[j].word = firstEnd;
					this.matchingWords[j].disable = true;
					this.matchingWords[j + 1].disable = true;

					this.matchingWords[j + 1].word = lastEnd;
					this.matchingWords[j].className = `${correctClassName}-${i + 1}-shine`;
					this.matchingWords[j + 1].className = `${correctClassName}-${i + 1}-shine`;
				} else if (indexOfFirstEnd !== 0 && j + 1 < this.matchingWords.length) {
					this.matchingWords[j].word = lastEnd;
					this.matchingWords[j + 1].word = firstEnd;
					this.matchingWords[j].className = `${correctClassName}-${i + 1}-shine`;
					this.matchingWords[j + 1].className = `${correctClassName}-${i + 1}-shine`;
				}
				break;
			}
			k = k + 2;
		}
	}
	checkMatchedWords(matchedWord) {
		for (let i = 0; i < defaultMatches.length; i++) {
			if (matchedWord === defaultMatches[i].combinedWords) {
				return { hasCurrentWord: true, indexValue: i };
			}
		}
		return { hasCurrentWord: false, indexValue: null };
	}
	shineMatchedWords(indexOfMatchedWord, firstWord, secondWord) {
		document.getElementById(`matchedwords-${indexOfMatchedWord + 1}`).className = `rw-mw-matchedwords-${
			indexOfMatchedWord + 1
		}__shine`;
		this.matchingWords[firstWord].className = `${correctClassName}-${indexOfMatchedWord + 1}`;
		this.matchingWords[secondWord].className = `${correctClassName}-${indexOfMatchedWord + 1}`;
		setTimeout(() => {
			document.getElementById(`matchedwords-${indexOfMatchedWord + 1}`).className = `rw-mw-matchedwords-${
				indexOfMatchedWord + 1
			}`;
			this.matchingWords[firstWord].className = defaultClassName;
			this.matchingWords[secondWord].className = defaultClassName;
			this.matchingWords[firstWord].disable = false;
			this.matchingWords[secondWord].disable = false;
		}, 1400);
	}
	updateGlobalObject(firstWord, secondWord, status) {
		resultsGloabl.push({ firstWord: firstWord, secondWord: secondWord, status });
		if (status.isCorrect) {
			correctCount = correctCount + 1;

			if (this.state.allMatches === correctCount) {
				setTimeout(() => {
					this.renderNextButton();
				}, 10000);
			}
		}
	}
	renderNextButton() {
		this.setState({ allowNextBtn: true });
	}

	render() {
		const { onNextStep, flipCoin } = this.props;
		const { matchingTasks } = this.state;
		let audioPlayerRef;
		return (
			<div>
				<div className='rw-lk-intro'>
					<div className='rw-infobar'>
						<TimerCount
							duration={130}
							onComplete={() => {
								this.renderNextButton();
							}}
						/>
					</div>
					<div className='rw-intro-text'>
						<p>
							<AudioPlayer
								autoPlay
								inline
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								src={this.audio}
							/>
							Hvilke ord passer sammen? Trykk på ordene.
						</p>
					</div>
				</div>
				<br></br>
				<div className='rw-mw-matched'>
					{this.matchingWords.map((word, index) => {
						if (index === 1)
							return (
								<span
									id='matchedwords-1'
									className='rw-mw-matchedwords-1'
								>
									{this.state.matchedWords[0]
										? this.state.matchedWords[0]
												.combinedWords
										: '?'}
								</span>
							);
						if (index === 3)
							return (
								<span
									id='matchedwords-2'
									className='rw-mw-matchedwords-2'
								>
									{this.state.matchedWords[1]
										? this.state.matchedWords[1]
												.combinedWords
										: '?'}
								</span>
							);
						if (index === 5)
							return (
								<span
									id='matchedwords-3'
									className='rw-mw-matchedwords-3'
								>
									{this.state.matchedWords[2]
										? this.state.matchedWords[2]
												.combinedWords
										: '?'}
								</span>
							);
						if (index === 7)
							return (
								<span
									id='matchedwords-4'
									className='rw-mw-matchedwords-4'
								>
									{this.state.matchedWords[3]
										? this.state.matchedWords[3]
												.combinedWords
										: '?'}
								</span>
							);
					})}
				</div>

				<div className='rw-mw-main-container'>
					<div className='rw-mw-container'>
						{matchingTasks.map((task, index) => {
							return (
								<button
									className={task.className}
									id={task.id}
									key={task.id}
									disabled={task.disable}
									onClick={(event) => {
										this.matchingWords[
											index
										].className = selectClassName;
										if (
											!this.matchingWords[
												index
											].disableCounter
										) {
											this.matchingWords[
												index
											].disable = true;
										}
										this.setState({
											matchingTasks:
												this
													.matchingWords,
										});

										this.evaluteItemSelection(
											event.target.id,
											index,
											task
										);
									}}
								>
									<p
										key={`${task.id}_key`}
										className='rw-lk-para__black'
									>
										{task.word}
									</p>
								</button>
							);
						})}
					</div>
				</div>
				{(this.state.allowNextBtn || this.props.isSuperTeacher) && (
					<div className='rw-next-btn'>
						<button
							className='rw-btn bg-primary'
							onClick={() =>
								onNextStep(
									resultsGloabl,
									ALL_SUBTESTS.MATCHINGWORDS
								)
							}
						>
							{'Neste'}
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default MatchingWords;
