import clickInstruction_1 from '../assets/clickInstruction_1.m4a';

import clickInstruction_2 from '../assets/clickInstruction_2.m4a';
import writeinstruction_2 from '../assets/writeInstruction_2.m4a';

import clickInstruction_3 from '../assets/clickInstruction_3.m4a';
import writeinstruction_3 from '../assets/writeInstruction_3.m4a';

import clickInstruction_4 from '../assets/clickInstruction_4.m4a';
import writeinstruction_4 from '../assets/writeInstruction_4.m4a';

import clickInstruction_5 from '../assets/clickInstruction_5.m4a';
import writeinstruction_5 from '../assets/writeInstruction_5.m4a';
import clickInstruction_6 from '../assets/clickInstruction_6.m4a';
import writeinstruction_6 from '../assets/writeInstruction_6.m4a';

import clickInstruction_7 from '../assets/clickInstruction_7.m4a';
import writeinstruction_7 from '../assets/writeInstruction_7.m4a';
import clickInstruction_8 from '../assets/clickInstruction_8.m4a';
import writeinstruction_8 from '../assets/writeInstruction_8.m4a';
import clickInstruction_9 from '../assets/clickInstruction_9.m4a';
import writeinstruction_9 from '../assets/writeInstruction_9.m4a';
import clickInstruction_10 from '../assets/clickInstruction_10.m4a';
import writeinstruction_10 from '../assets/writeInstruction_10.m4a';
import clickInstruction_11 from '../assets/clickInstruction_11.m4a';
import writeinstruction_11 from '../assets/writeInstruction_11.m4a';
import clickInstruction_12 from '../assets/clickInstruction_12.m4a';
import writeinstruction_12 from '../assets/writeInstruction_12.m4a';
import clickInstruction_13 from '../assets/clickInstruction_13.m4a';
import writeinstruction_13 from '../assets/writeInstruction_13.m4a';
import clickInstruction_14 from '../assets/clickInstruction_14.m4a';
import writeinstruction_14 from '../assets/writeInstruction_14.m4a';
import clickInstruction_15 from '../assets/clickInstruction_15.m4a';
import writeinstruction_15 from '../assets/writeInstruction_15.m4a';
import clickInstruction_16 from '../assets/clickInstruction_16.m4a';
import writeinstruction_16 from '../assets/writeInstruction_16.m4a';
import clickInstruction_17 from '../assets/clickInstruction_17.m4a';
import writeinstruction_17 from '../assets/writeInstruction_17.m4a';

import clickInstruction_18 from '../assets/clickInstruction_18.m4a';
import writeinstruction_18 from '../assets/writeInstruction_18.m4a';

import clickInstruction_19 from '../assets/clickInstruction_19.m4a';
import writeinstruction_19 from '../assets/writeInstruction_19.m4a';

import clickInstruction_20 from '../assets/clickInstruction_20.m4a';
import writeinstruction_20 from '../assets/writeInstruction_20.m4a';

import clickInstruction_21 from '../assets/clickInstruction_21.m4a';
import writeinstruction_21 from '../assets/writeInstruction_21.m4a';

import clickInstruction_22 from '../assets/clickInstruction_22.m4a';
import writeinstruction_22 from '../assets/writeInstruction_22.m4a';

import clickInstruction_23 from '../assets/clickInstruction_23.m4a';
import writeinstruction_23 from '../assets/writeInstruction_23.m4a';

import clickInstruction_24 from '../assets/clickInstruction_24.m4a';
import writeinstruction_24 from '../assets/writeInstruction_24.m4a';

import clickInstruction_25 from '../assets/clickInstruction_25.m4a';
import writeinstruction_25 from '../assets/writeInstruction_25.m4a';
import clickInstruction_26 from '../assets/clickInstruction_26.m4a';
import writeinstruction_26 from '../assets/writeInstruction_26.m4a';
import clickInstruction_27 from '../assets/clickInstruction_27.m4a';
import writeinstruction_27 from '../assets/writeInstruction_27.m4a';

import clickInstruction_28 from '../assets/clickInstruction_28.m4a';
import writeinstruction_28 from '../assets/writeInstruction_28.m4a';
import clickInstruction_29 from '../assets/clickInstruction_29.m4a';
import writeinstruction_29 from '../assets/writeInstruction_29.m4a';

import clickInstruction_30 from '../assets/clickInstruction_30.m4a';
import writeinstruction_30 from '../assets/writeInstruction_30.m4a';

import clickInstruction_31 from '../assets/clickInstruction_31.m4a';
import writeinstruction_31 from '../assets/writeInstruction_31.m4a';
import clickInstruction_32 from '../assets/clickInstruction_32.m4a';
import writeinstruction_32 from '../assets/writeInstruction_32.m4a';
import clickInstruction_33 from '../assets/clickInstruction_33.m4a';
import writeinstruction_33 from '../assets/writeInstruction_33.m4a';

import clickInstruction_34 from '../assets/clickInstruction_34.m4a';
import writeinstruction_34 from '../assets/writeInstruction_34.m4a';
import clickInstruction_35 from '../assets/clickInstruction_35.m4a';
import writeinstruction_35 from '../assets/writeInstruction_35.m4a';
import clickInstruction_36 from '../assets/clickInstruction_36.m4a';
import writeinstruction_36 from '../assets/writeInstruction_36.m4a';
import clickInstruction_37 from '../assets/clickInstruction_37.m4a';
import writeinstruction_37 from '../assets/writeInstruction_37.m4a';
import clickInstruction_38 from '../assets/clickInstruction_38.m4a';
import writeinstruction_38 from '../assets/writeInstruction_38.m4a';
import clickInstruction_39 from '../assets/clickInstruction_39.m4a';
import writeinstruction_39 from '../assets/writeInstruction_39.m4a';
import clickInstruction_40 from '../assets/clickInstruction_40.m4a';
import writeinstruction_40 from '../assets/writeInstruction_40.m4a';
import clickInstruction_41 from '../assets/clickInstruction_41.m4a';
import writeinstruction_41 from '../assets/writeInstruction_41.m4a';
import clickInstruction_42 from '../assets/clickInstruction_42.m4a';
import writeinstruction_42 from '../assets/writeInstruction_4.m4a';
import clickInstruction_43 from '../assets/clickInstruction_43.m4a';
import writeinstruction_43 from '../assets/writeInstruction_43.m4a';
import clickInstruction_44 from '../assets/clickInstruction_44.m4a';
import writeinstruction_44 from '../assets/writeInstruction_44.m4a';
import clickInstruction_45 from '../assets/clickInstruction_45.m4a';
import writeinstruction_45 from '../assets/writeInstruction_45.m4a';
import clickInstruction_46 from '../assets/clickInstruction_46.m4a';
import writeinstruction_46 from '../assets/writeInstruction_46.m4a';
import clickInstruction_47 from '../assets/clickInstruction_47.m4a';
import writeinstruction_47 from '../assets/writeInstruction_47.m4a';
import clickInstruction_48 from '../assets/clickInstruction_48.m4a';
import writeinstruction_48 from '../assets/writeInstruction_48.m4a';
import clickInstruction_49 from '../assets/clickInstruction_49.m4a';
import writeinstruction_49 from '../assets/writeInstruction_49.m4a';
import clickInstruction_50 from '../assets/clickInstruction_50.m4a';
import writeinstruction_50 from '../assets/writeInstruction_50.m4a';

import clickInstruction_51 from '../assets/clickInstruction_51.m4a';
import writeinstruction_51 from '../assets/writeInstruction_51.m4a';
export const Session1 = [
	{
		wordCount: 6,
		clickInstructions: clickInstruction_1,
		wrtieInstructions: writeinstruction_2,
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'u'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'U'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'u'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'U'}</b>
				</span>
				{'>'}
			</span>
		),
		targetWord: 'U',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		text: 'Unni, Ylvis og Åge bor sammen. De løper og leker hver eneste dag. Når katten Iris er utenfor, bjeffer alle sammen. Åge blir sint. Han hater katter og kunne tenkt seg og sloss med Iris. Han skulle gjerne ha jaget katten opp i et tre. Unni klynker og logrer usikkert med halen. Hun tenker sikkert at katter er rare dyr. Ylvis uler som en ulv.',
	},
];
export const Session2 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'y'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Y'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'y'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Y'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 2,
		clickInstructions: clickInstruction_2,
		wrtieInstructions: writeinstruction_2,
		targetWord: 'Y',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: 'Ylvis uler som en ulv. Han tror at han er veldig stor, men han er like liten som en støvsuger. Hunder er flotte dyr. De liker å leke og ha det gøy. Unni, Ylvis og Åge elsker å lukte der andre har tisset og bæsjet. Alle fire har god luktesans. Visste du at hunder kan ha flere tusen ganger bedre luktesans enn mennesker? Det er litt rart å tenke på.',
	},
];
export const Session3 = [
	{
		wordCount: 18,
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'b'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'B'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'b'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'B'}</b>
				</span>
				{'>'}
			</span>
		),
		clickInstructions: clickInstruction_3,
		wrtieInstructions: writeinstruction_3,
		targetWord: 'B',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: 'Jeg heter Berit og har bursdag i dag. Jeg gleder meg til at Børre og Bente skal komme. Jeg har bedt Bente kjøpe et smykke til meg, og fra Børre ønsket jeg meg en bamse. En bamse med deilig myk pels. «Dere er sene!» Sier jeg og tar imot gavene. Jeg river opp papiret uten å lese kortene. Kort er for bavianer. «Så det er her du bor,» sier Børre. «Fint hus,» sier Bente, men jeg svarer ikke. Jeg står og ser på gavene. «Fikk dere ikke ønskelisten min?» sier jeg og kjenner at jeg blir skuffet og sint. «Hva er dette?» «Jo, da. Vi fikk listen,» sier Bente og Børre i kor. «Men hvorfor får jeg en bil og en badeand, da?»',
	},
];

export const Session4 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'d'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'D'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'d'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'D'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 29,
		clickInstructions: clickInstruction_4,
		wrtieInstructions: writeinstruction_4,
		targetWord: 'D',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: 'Dino er en dinosaur. Mange kaller ham tårnet, men det liker han ikke. Dino har ingen venner, for de fleste spiser slike som ham. Rex dreper andre dinosaurer. Han spiser alt som går på minst to bein. Rex er en dust, og hadde det ikke vært for at vulkanen fikk utbrudd, hadde Dino dødd. Rex stikker og det er deilig å se. «Ha det bra!» roper Dino etter ham og danser en liten dans han lærte på danseskolen for lange dinosaurer. Dolly og Dille flyr unna røyken og hoster. De drar mot nord. Dino labber videre. Røyken kveler ham så han dåner. Dolly og Dille flyr ned for å hjelpe Dino. Lavaen nærmer seg ungene! Er det for sent?',
	},
];

export const Session5 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'f'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'F'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'f'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'F'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 26,
		clickInstructions: clickInstruction_5,
		wrtieInstructions: writeinstruction_5,
		targetWord: 'F',
		ignoreWords: ['i', 'å', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: 'Da Fido bæsjet ved døra til Frida Fisefin, fikk hun fnatt. Fido ante fred og ingen fare da døra åpnet seg, mens han satt der på huk og gjorde det han alltid gjorde ute. «Kom deg vekk, din frosk!» ropte Frida, og rev seg i håret. «Du kan ikke bæsje her, dette er da ikke et toalett!» Fido kikket rart på Frida, som nå hadde revet av seg flere lange hårtuster. Da Fido var ferdig, logret han med halen og sparket i grusen. Frida holdt seg for ansiktet. Hun fikk både bæsj, sand og jord over hele seg. «Dø!» ropte Frida Fisefin, fant en kost og slo etter Fido. Heldigvis for Fido, bommet hun. Han løp bare til neste hus og tisset på alle dører han kunne finne.',
	},
];

export const Session6 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'g'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'G'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'g'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'G'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 23,
		clickInstructions: clickInstruction_6,
		wrtieInstructions: writeinstruction_6,
		targetWord: 'G',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: 'Guri er ikke som andre sjiraffer. Hun er en gul og gal sjiraff, som går med blått skjerf i varmen. Geir og Gunnar digger Guri, og de glaner på henne hver eneste dag. Geir tror at Gunnar har blitt forelsket, for han strammer selene på den lilla buksa med gule bukseknapper når hun går forbi. «Er noe galt, Guri?» sier Gunnar. «Du hoster så fælt!» «Jeg tror jeg har blitt syk,» sier Guri og nyser på genseren til Geir. «Æsj!» gnåler Geir og gremmes. Han stikker ut den blå tunga. «Du nøs på meg. «Sorry,» sier Guri og blir svimmel. Hun snubler i beina på Geir og lander på bakken med et klask.',
	},
];
export const Session7 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'h'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'H'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'h'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'H'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 24,
		clickInstructions: clickInstruction_7,
		wrtieInstructions: writeinstruction_7,
		targetWord: 'H',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: 'Zara og Ada er feer, og de flyr høyt om høsten og lavt om våren. Nå er det høstferie, og de vet at Haldis vil komme på besøk i år også. Haldis elsker honning, og i fjor stjal hun alt hun kunne bære. Det får hun ikke gjøre denne gangen, for Ada og Zara har klekket ut en plan. De ser at Haldis flyr over himmelen. Hun lander og banker på døra. Zara og Ada har gjemt seg bak en stor sopp og kniser. De ser at Haldis tar flere glass som de har satt fram. Det står ” honning ” på lappene. Haldis putter fem glass i sekken sin og flyr høyt opp i det blå. « Hva var det i de glassene?» sa Ada. «Det var heslig hestemøkk, » sa Zara, og knakk sammen av latter. Ada rullet rundt og hylte av glede. Der fikk hun! ',
	},
];
export const Session8 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'j'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'J'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'j'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'J'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 15,
		clickInstructions: writeinstruction_8,
		wrtieInstructions: clickInstruction_8,
		targetWord: 'J',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: 'Jens angret på at han tryllet litt for godt. Han burde ha jukset og ikke tryllet seg bort, som Jerry Potter. Jentene i salen jublet da han forsvant, men det gikk ikke som han hadde håpet på. Han dukket opp midt i en jungel. Det var jaggu en tabbe! En tiger som hadde gjemt seg, snek seg innpå og jaget ham opp i et tre. Heldigvis kan ikke kattedyr klatre, tenkte Jens, men der tok han feil. Tigeren brølte og klatret opp i treet. Jens hylte, jamret seg og veivet med tryllestaven. «Simsalabim, gå bort ditt jungelbeist!» sa han, men noe rart skjedde. Opp fra hatten spratt en kanin og gjorde alt verre. « Jammen, jammen.... » sa Jens og svimte av. Det siste han husket var at han falt.',
	},
];
export const Session9 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'k'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'K'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'k'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'K'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 17,
		clickInstructions: clickInstruction_9,
		wrtieInstructions: writeinstruction_9,
		targetWord: 'K',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: '«Hvorfor jeg elsker sukker?» spør Kine. Kurt har sikkert aldri vært i Kakeland før, tenker hun. Da hadde han forstått. «Er det første gang du er her?» «Ja, jeg har aldri hørt om dette stedet,» sier Kurt, og kakker den ene knoken på en dør av karamell. «Jeg skjønner ikke hvorfor tannlegen ble så sint,» sier Kine og spiser litt pynt fra en kake som vokser på bakken. «Det skjønner jeg,» sier Kurt, og kniser. Han tar litt krem med kokos fra taket på det ene huset, og smaker. «Du bor jo i sukker! Ti hull på ett år. Fikk du premie?» Kine sukker og blir flau. «Jeg liker sukker, men mamma sier at jeg må spise sunn mat,» sier Kine, og henger koften på en knagg.',
	},
];
export const Session10 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'l'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'L'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'l'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'L'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 28,
		clickInstructions: clickInstruction_10,
		wrtieInstructions: writeinstruction_10,
		targetWord: 'L',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: 'Lars, Leif og Lasse liker diamanter. De bryter løst lass med edle steiner hver dag, og labber ut av gruven med sekkene fulle. De er latterlig rike, men liker ikke å gi bort penger. «Kan jeg få låne en lapp eller to av dere?» ropte Laffen inn i gruven. «Jeg lover å betale tilbake!» Han gikk i en hullete langbukse og jakke. «Er du lam i hodet eller? Vi lar ikke late og lurvete folk som deg få penger,» sa Lars og lo. «Få deg jobb på lageret, du,» sa Leif og løsnet en rubin. «Men jeg trenger litt loff og pålegg i dag. Sønnen min sulter.» Laffen gråt og så på den løse lappen på buksen. «Få deg en jobb!» hylte de alle i kor, og det var da gruven raste sammen.',
	},
];
export const Session11 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'m'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'M'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'m'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'M'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 25,
		clickInstructions: clickInstruction_11,
		wrtieInstructions: writeinstruction_11,
		targetWord: 'M',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: 'Nå er ikke månen langt unna. Jeg har reist i to måneder, og nå har jeg kommet meg ut av Melkeveien. Jeg ble skutt opp 17. mai, og i dag er det 17. juli. Måneraketten ” Milde Måne ” er en merkelig liten rakett. Det er bare plass til meg og hunden min Merkur. Det er lite mat igjen til Merkur, men hvis alt går bra, skal jeg lande på M-119 i morgen. Det er navnet på månen utenfor solsystemet vårt. Mange tror at det er vann og luft på månen. Jeg håper det, ellers lever jeg ikke lenge. Jeg har bare mat i ni dager til. Jeg liker ikke at den store meteoren kommer nærmere månen jeg skal lande på. Jeg har fulgt med på den siden jeg først så den. Kræsjer den, vil månen gå ut av kurs.',
	},
];
export const Session12 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'n'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'N'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'n'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'N'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 25,
		clickInstructions: clickInstruction_12,
		wrtieInstructions: writeinstruction_12,
		wordCount: 25,
		targetWord: 'N',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: 'Ikke slå, ikke slå!» ropte Nils. Nikolas hadde stoppet ham på vei hjem fra naboen. Det var midt på dagen. «Gi meg mobilen, din nerd.» Nikolas skrek i øret til Nils, og knyttet neven. Nils ble nervøs og svimmel som i går, da Nikolas hadde slått ham på nesen. « Nei, jeg kan ikke, » sa Nils. «Jeg har den ikke med meg, men du kan få nistepakken min!» « Nisten din? Holder du meg for narr, din nisse? » «Det er alt jeg har. Æresord!» sa Nils, nesten på gråten. «Da gir du meg alle pengene dine!» Nils nikket. Han skalv da han hentet opp ni kroner fra lommen. «Stopp nå med en gang,» sa naboen, og tok Nikolas i armen. «Driver du og bøller i dag, også?»',
	},
];
export const Session13 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'p'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'P'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'p'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'P'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 32,
		clickInstructions: clickInstruction_13,
		wrtieInstructions: writeinstruction_13,
		targetWord: 'P',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'inn', 'til'],
		text: '«Sett flagget fra Perlen der,» sa piraten Pondus og pekte med kroken. Petrus satte flagget i sanden og knytta fast pannebåndet. «Er du sikker på at skatten er her?» sa Pippi og klødde papegøyen Polly på nebbet. «Øya er jo så stor.» Pondus klødde seg i pannen og prikket seg på kinnet. «Bare se på skattekartet,» sa Petrus og pekte med en pistol. Pippi nikket. Hun startet å grave og kort tid etter smalt det i tre. Pippi kikket på Petrus. «Det må være kisten,» sa Petrus. Pondus falt pladask med nesa først i sanden, og bannet på polsk. « Potet, potet, potet! » skrek Polly, da de åpnet kisten.',
	},
];
export const Session14 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'r'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'R'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'r'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'R'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 25,
		clickInstructions: clickInstruction_14,
		wrtieInstructions: writeinstruction_14,
		targetWord: 'R',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: '«Hva er det du gjør?» lurer Roar på. Rune risser runer mens Roar ser på. Roar synes runer er rare greier. Rune kaster tre rosa runesteiner på gresset og tenker. I hånden holder han en rød runepinne av gran og leser høyt. «Vi skal ikke heller røve fra rike bønder da?» sier Roar. «Dette er jo kjipt!» «Nei, sjefen sa at vi må holde vakt her,» sier Rune. « Røverne kan jo komme når som helst. Vi må passe på veien til i morgen. Vi har jo et rykte å ta vare på!» «Dette er et ran! Slipp våpnene, ellers er dere ferdige!» roper sju røvere som nå har omringet dem. «Ja, nå får vi et godt rykte,» sa Roar og rødmet.',
	},
];
export const Session15 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'s'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'S'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'s'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'S'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 37,
		clickInstructions: clickInstruction_15,
		wrtieInstructions: writeinstruction_15,
		targetWord: 'S',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: 'Svein er ikke som andre riddere i Sumpland. De andre svinger sverdet mot skurker og sinte fiender. Svein er feig. Han blir kalt for en sviker og en sutrekopp. Svein er sulten, og rir mot et spisested. Han har feber og er svett. Når han kommer frem setter han seg ved et stort spisebord som er ledig. Han får sure blikk fra folk rundt seg. Ingen liker Svein, så flere bøller går mot ham og lover ham bank etter han har spist. «Ikke skad meg,» sier Svein og skjelver litt. Med ett nyser han så snørr lander på nesa til den stygge bøllen med de store musklene. «Er du syk?» spør han. «Ja, jeg tror jeg har fått et virus,» sier Svein. Han hoster og sikler. Når han åpner øynene, er alle borte.',
	},
];
export const Session16 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'t'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'T'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'t'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'T'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 38,
		clickInstructions: clickInstruction_16,
		wrtieInstructions: writeinstruction_16,
		targetWord: 'T',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		text: '«Gap opp!» sa Tomas til sin pasient. Han var en tannlege og trengte ikke å se så nøye på tennene til Truls. Han trakk pusten og sukket. «Når pusset du tennene dine sist med tannkrem?» lurte Tomas på. « Tja, sist jeg var på Tivoli, tror jeg,» sa Truls og gliste. « Tenker du på tivoliet som er i byen nå?» sa tannlegen. «Nei, det som var i byen for tolv år siden.» «Hva er det du sier, Truls? Du har en tann som er råtten.» Tomas pirket litt på den. «Du har dårlig tannhelse!» «Bare en tann?» sa Truls. «Ja. En er råtten. Men alle tenner må også trekkes. Det blir travelt for tannfeen, dette,» sa Tomas trist, og ba pleieren om te fra tekannen. «Avlys alle andre pasienter for i dag, og hent et gebiss. Dette vil ta minst tre timer!»',
	},
];
export const Session17 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'v'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'V'}</b>
				</span>
				{'> '}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'v'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'V'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 28,
		clickInstructions: clickInstruction_17,
		wrtieInstructions: writeinstruction_17,
		targetWord: 'V',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		text: '« Angrip veikingen. Det er ingen som holder vakt! » ropte den ene vikingen, og var vill i blikket. De hadde gått av vikingskipet. Nå løp de mot Viktor som lå og solte seg på stranden i Viken. Det var som om de vokste seg større da de kom frem, og alle hadde mistet vettet. «Dere er da ikke vikinger,» sa Viktor og lo rått. « Vrøvl og vås. At du våger. Vi er vikinger!» «En viking har ikke horn på hjelmen sin,» sa Viktor. «Dere er noen vattnisser. Tykke er dere også. Har dere spist for mye vafler? Dere veier for mye.» «For pokker da!» sa Vilde. «Det var jo det jeg sa! Hvorfor måtte vi møte på en liten, veslevoksen viktigper på vårt første tokt? Jeg slutter!»',
	},
];
export const Session18 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'kn'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Kn'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'kn'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Kn'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 5,
		intermediateCount: 5,
		clickInstructions: clickInstruction_18,
		wrtieInstructions: writeinstruction_18,
		targetWord: ['kn'],
		easyText: 'Noen sier knask eller knep. Barna vil ha godteri. Noen barn kler seg ut. Får de ikke godteri, blir det knep.',
		intermediateText:
			'31 . oktober sier mange: knask eller knep! Da feirer vi halloween. Halloween kom til oss fra USA. Mange barn kler seg ut. Noen som spøkelser, vampyrer og superhelter. De banker på og roper: Knask eller knep! Det er en trussel. Barna vil ha godteri. Har de ikke godteri, blir det knep. Mange pynter med gresskar. De som ikke vil ha besøk slukker lyset.',
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'til'],
		text: 'Hver høst, den 31 . oktober sier mange barn: knask eller knep! Det er dagen før allehelgensdag. Mange barn gleder seg hver høst. Da feirer vi halloween. På denne dagen feirer vi de døde. I Norge startet vi å feire halloween rundt år 2000. Halloween kom til oss fra USA. Men det var ikke her de startet med skikken. Egentlig kommer den fra Irland og Skottland. Mange barn kler seg ut. Noen som spøkelser, vampyrer og superhelter. Barn går rundt og banker på dører og roper: Knask eller knep! Det er egentlig en trussel. Barn vil ha godteri, ellers får det følger. Har de ikke godteri, blir det knep. Da gjør de fantestreker. Mange pynter med gresskar og andre ting som skal skremme. De som ikke vil ha besøk slukker utelyset. Det er lurt. Mange er jo ikke hjemme.',
	},
];
export const Session19 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'hv'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Hv'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'hv'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Hv'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 4,
		intermediateCount: 3,
		clickInstructions: clickInstruction_19,
		wrtieInstructions: writeinstruction_19,
		targetWord: ['hv'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Dyr må ha mat og varme. Dagene blir kortere om vinteren. Været blir kaldere. Fuglene flyr mot sør når det blir høst.',
		intermediateText:
			'Dyr trenger mat og varme. Noen fugler flyr veldig langt hvert år for å finne mat og varmt vær. Vi kaller de for trekkfugler. Hvorfor trekker fugler sørover? Mange fugler kan ikke leve i nord om vinteren. Det er for kaldt. Det er mindre mat å finne. De flyr sørover når det blir høst. Hvordan vet fugler når de skal fly sørover? Dagene blir kortere. Været blir kaldere. Det blir vanskeligere for fuglene å finne mat.',
		text: 'Dyr trenger mat og varme akkurat som mennesker. Noen fugler flyr veldig langt hvert år for å finne mat og varme. Vi kaller disse fuglene for trekkfugler. Hvorfor trekker fugler sørover? Fugletrekk er viktig. Det hjelper fuglene til å overleve. Mange fugler flyr fra nord til sør når vinteren er nær. De kan ikke bli når været blir for kaldt. I tillegg liker fuglene å spise ting som insekter, amfibier og planter. Om vinteren er det mindre av denne maten å leve av. Visste du? For lenge siden visste ikke folk hvor fuglene ble av. Noen sa at fuglene var i dvale for vinteren. Andre sa at fuglene ble til fisk! Hvordan vet fuglene når det er på tide å trekke sørover? Noen forskere sier at fuglene merker endringer i miljøet sitt. De merker at dagene blir kortere og at temperaturen synker. Det blir vanskeligere for fuglene å finne mat.',
	},
];
export const Session20 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'hj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Hj'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'hj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Hj'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 2,
		intermediateCount: 1,
		clickInstructions: clickInstruction_20,
		wrtieInstructions: writeinstruction_20,
		targetWord: ['hj'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Fuglene flyr i flokk. De flyr i V formasjon. De blir ikke så slitne. Fuglene flyr hjem når det blir vår.',
		intermediateText:
			'Fuglene tar ikke turen på én dag. De flyr i grupper eller flokk. De flyr i V-formasjon. Den sterkeste fuglen flyr først. Resten av fuglene følger etter i to linjer. Å fly på denne måten er lettere for fuglene bak. De blir ikke så slitne. Når fuglen i front blir sliten bytter den plass med en annen fugl. Hvorfor trekker fuglene tilbake nordover? Det er mye mat til alle fuglene når det er sommer i sør. Det blir mindre mat om vinteren. Derfor flyr fuglene hjem igjen når det blir vår.',
		text: 'For det første tar de ikke hele reisen på én dag. For det andre flyr de i grupper eller i flokk. De flyr også i en V-formasjon. Den sterkeste fuglen flyr først i front av V-en. Resten av flokken følger i to linjer bak. Å fly på denne måten hjelper fuglene å redusere luftmotstanden. Fuglene bak blir ikke så slitne. Når ledefuglen blir sliten bytter den plass med en annen fugl. Hvorfor trekker fuglene tilbake nordover? Det er rikelig med mat til alle fuglene når det er sommer i sør. Det er mindre mat om vinteren. Dette er grunnen til at de nordlige fuglene begynner å fly hjem når vinteren nærmer seg i sør. De foretrekker også å hekke i sine naturlige klima.',
	},
];
export const Session21 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'hj'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'hj'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 7,
		intermediateCount: 3,
		clickInstructions: clickInstruction_21,
		wrtieInstructions: writeinstruction_21,
		targetWord: ['Sj', 'Hj'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'FN har viktige oppgaver: 1. Bevare fred. 2. Hjelpe land å være venner. 3. Hjelpe syke og fattige mennesker.',
		intermediateText:
			'Hva er de Forente nasjoner? Det er mange stater som jobber sammen. Det er vanlig å kalle dem FN. FN jobber for at alle skal ha det trygt. Det ble startet i 1945 av 51 land som ikke likte krig. De ble enige om hvordan FN skulle jobbe. Det er 193 medlemsland i FN i dag. FN har viktige oppgaver: 1. Bevare fred. 2. Hjelpe land å være venner. 3. Hjelpe syke og fattige mennesker. 4. Jobbe sammen for å løse oppgaver. Mange mennesker jobber i FN. De arbeider i grupper.',
		text: 'Hva er de Forente nasjoner? De Forente nasjoner er en stor samling av stater. Det vanligste er å kalle dem FN. FN jobber for å gjøre verden til et tryggere sted. FN startet for lenge siden. Det ble startet av 51 land i 1945 som ikke likte krig og konflikter. De ble enige om hvordan FN skulle jobbe. Det er 193 medlemsland i FN i dag. Hovedkontoret ligger i New York. FN har viktige oppgaver: 1. Bevare fred. 2. Hjelpe land å være venner. 3. Hjelpe land med å løse problemene sine. 4. Jobbe sammen for å løse oppgaver Mange mennesker jobber i FN. De arbeider i grupper. En gruppe bestemmer hva FN skal gjøre. En annen gruppe hjelper med å bevare fred og gjøre verden tryggere. Det er en gruppe som hjelper syke og fattige mennesker. De er også en gruppe som hjelper statene å følge lovene',
	},
];
export const Session22 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'øy'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'gj'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'øy'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'gj'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 8,
		intermediateCount: 4,
		clickInstructions: clickInstruction_22,
		wrtieInstructions: writeinstruction_22,
		targetWord: ['Øy', 'Gj'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Det er ikke moro å være syk. Det er mye bedre å være frisk! Du kan få sprøyte. Det kan gjøre litt vondt.',
		intermediateText:
			'Det er ikke moro å være syk. Det er mye bedre å være frisk! Det er mange måter å holde seg frisk på. En måte er å få sprøyte hos legen eller hos helsesykepleier. Det kalles å få en injeksjon. Det kan gjøre litt vondt. Men det viktig for kroppen din. Hva er en injeksjon? Injeksjonen har en vaksine i seg. Det er en slags medisin. Den gjør at farlige mikrober ikke kan skade deg. Farlige mikrober er virus eller bakterier som kan gjøre deg syk. Noen vaksiner skal du drikke. Andre kan bli sprayet inn i nesen din.',
		text: 'Det er ikke moro å være syk. Det er mye bedre å være frisk! Det er mange måter å holde seg frisk på. En måte er å få sprøyte på et legekontor eller hos helsesykepleier. Istedenfor sprøyte kan en si at en får en injeksjon. Det kan gjøre litt vondt, men injeksjonen gjør en viktig jobb i kroppen din. Alle fire har god luktesans. Visste du at hunder kan ha flere tusen ganger bedre luktesans enn mennesker? Det er litt rart å tenke på. Hva er en injeksjon? Injeksjonen har en vaksine i seg. Det er en slags medisin. Den gjør at farlige mikrober ikke kan skade deg. Skadelige mikrober som virus eller bakterier kan gjøre deg veldig syk. Vaksinen gjør deg ikke veldig syk. Kanskje får du litt feber etter injeksjonene, men du vil føle deg raskt bedre. Vaksinene forhindrer mikrober i å gjøre folk syke. De holder mange barn og voksne friske. Noen vaksiner skal du drikke. Andre kan bli sprayet inn i nesen din.',
	},
];
export const Session23 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sk'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'st'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sk'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'st'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 19,
		intermediateCount: 10,
		clickInstructions: clickInstruction_23,
		wrtieInstructions: writeinstruction_23,
		targetWord: ['Sk', 'St'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Varmen kommer fra solen. Vanndråpene i luften danner skyer. Vannet faller ned som regn eller snø. Luft som beveger seg kaller vi for vind.',
		intermediateText:
			'Været påvirker oss. Med vær mener vi temperatur, solskinn, vind, skyer og storm. Temperaturen viser hvor varmt eller kaldt noe er. Varmen kommer fra solen. Varmen fra solen gjør at bitte små vanndråper stiger opp i luften. Vanndråpene i luften danner skyer. Vannet faller ned som regn eller snø. Når varm, fuktig luft stiger raskt, blir det storm. Luft som beveger seg kaller vi for vind. Når det er storm, regner det mye. Det blåser sterk vind under en storm.',
		text: `Været påvirker hvordan vi lever. Med vær mener vi for eksempel temperatur, solskinn, vind, skyer og storm. Temperaturen viser hvor varmt eller kaldt noe er. 
		Varmen kommer fra solen. Solen varmer ikke likt alle steder på jorden. Det er ulik temperatur på forskjellige steder på kloden vår. Noen steder er luften varm. 
		Varm luft stiger opp. Kald luft kommer inn under den varme luften. Luft som beveger seg kaller vi for vind. Varmen fra solen gjør at bitte små vanndråper stiger opp i luften (fordamper). 
		Når vanndråpene samler seg i luften blir det dannet skyer. Når skyene blir for store, faller vannet tilbake til jorden. Vanligvis faller vannet ned som regn eller snø. 
		Når varm, fuktig luft stiger raskt, blir det storm. Skyene danner seg hurtig. Når det er storm, regner det mye. Det blåser sterk vind under en storm.`,
	},
];
export const Session24 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ng'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'dt'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ng'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'dt'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 8,
		intermediateCount: 4,
		clickInstructions: clickInstruction_24,
		wrtieInstructions: writeinstruction_24,
		targetWord: ['Ng', 'Dt'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'rund'],
		easyText: 'Sommeren er varm og har lange dager. Vinteren er motsatt. Om våren blir det varmere. Om høsten endrer bladene farge.',
		intermediateText:
			'Sommeren er varm og har lange dager. Vinteren er motsatt.  Da er det kaldt og snø flere steder. Våren er årstiden mellom vinter og sommer.  Høsten er årstiden mellom sommer og vinter.  Om våren blir det varmere og blomstene rundt oss blomstrer.  Om høsten endrer bladene farge og faller ned fra trærne.  Hver årstid varer i tre måneder. Jorden vår er rund.  Jorden beveger seg rundt solen. Jordens akse står litt på skrå. Når den delen av jorden du bor på heller mot solen, er det sommer.  Når den delen av jorden du bor på heller bort fra solen, er det vinter.  ',
		text: `Sommeren er veldig varm, og har lange, solfylte dager. 
Vinteren er helt motsatt. Da er det kaldt og landskapet er dekket av snø 
flere steder. Våren er årstiden mellom vinter og sommer. Høsten er årstiden mellom 
sommer og vinter. Om våren blir det varmere og alle blomstene rundt oss 
blomstrer. Om høsten endrer bladene farge og faller ned fra trærne. Hver 
årstid varer i tre måneder. Jorden vår er rund som en appelsin. Jordens akse står også litt på skrå. 
Når jorden beveger seg rundt solen, heller jordens akse noen ganger mot 
solen. Da er nord nærmere solen enn sør. Noen ganger skråner jordens 
akse bort fra solen. Da er nord lenger bort fra solen. Når den delen av jorden du bor på heller mot solen, er det sommer. Husk, 
solen er veldig varm. Når du lener deg mot den, er du nærmere. Det er 
derfor sommeren er varmere enn de andre årstidene vi har. Når den delen av jorden du bor på heller bort fra solen, er det vinter. Når 
du lener deg bort fra den, er du lenger unna. Det er derfor vinteren er 
kaldere enn de andre årstidene vi har. 
`,
	},
];
export const Session25 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ai'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ai'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ai'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ai'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 17,
		intermediateCount: 16,
		clickInstructions: clickInstruction_25,
		wrtieInstructions: writeinstruction_25,
		targetWord: ['Ai'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: '«Prinsesse Aina, er det deg?» sa Kai «Hvem var det som reddet deg? «Jeg ble reddet i Dubai,» sa Aina «Det var Bahai som reddet meg.»',
		intermediateText:
			'«Prinsesse Aina, er det deg?» sa Kai og gikk ned på kne. «Hvem var det som reddet deg?  Sist jeg så deg var du på kaien og fisket hai.» «Det er godt å se deg igjen, Kai. Jeg ble reddet i Dubai,» sa Aina «Det var Bahai som reddet meg.» «Og jeg som trodde du var i Hawaii,» sa Kai og ga Aina en klem. «Takk for all hjelp, Bahai.» «Men nå må jeg dra. «Skal vi spise litt eplepai, Aina? Aina skulle til å svare, men så snublet hun, dro Kai med seg og falt av scenekanten med et klask.',
		text: '«Prinsesse Aina, er det virkelig deg?» sa Kai og gikk ned på kne. «Hvem var det som reddet deg? Sist jeg så deg var du på kaien og fisket hai.» «Det er godt å se deg igjen, Kai. Jeg ble reddet i Dubai,» sa Aina, og vinket til en gutt ved slottet. «Det var Bahai som reddet meg.» «Og jeg som trodde du var i Hawaii,» sa Kai og ga Aina en klem. «Takk for all hjelp, Bahai.» «Det skulle bare mangle,» sa Bahai. «Men nå må jeg dra. Flyet til Jamaika går om fem minutter.» «Skal vi spise litt eplepai, Aina? Bestemoren din har bakt i hele dag.» Aina skulle til å svare, men så snublet hun, dro Kai med seg og falt av scenekanten med et klask.',
	},
];
export const Session26 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Au'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'au'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Au'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'au'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 21,
		intermediateCount: 17,
		clickInstructions: clickInstruction_26,
		wrtieInstructions: writeinstruction_26,
		targetWord: ['Au'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'sa'],
		easyText: '',
		intermediateText:
			'Klaus skulle aldri ha prøvd skøytene han fikk i august. Nå lå han på magen i en snøhaug. Han burde tatt seg en pause for lenge siden. «Gikk det bra med deg, Klaus? Er du dau?» sa piggsvinet Gaute, som lå under ham. «Det stikker!» «Unnskyld,» sa Gaute. Fem av piggene hans hadde spiddet Klaus. «Du ser ut som en hvit nålepute. Nesten som en sau! «Det gjør du også,» sa Klaus «Nei, du ser ut som en ball med pigger.» Gaute ble flau. «Vil dere ha litt graut?» sa Aud. «Jeg vil gjerne ha litt maursuppe,» sa Gaute',
		text: 'Klaus skulle aldri ha prøvd skøytene han fikk i august, for nå lå han på magen i en snøhaug og vred seg i smerte. Han burde tatt seg en pause for lenge siden. «Gikk det bra med deg, Klaus? Er du dau?» sa piggsvinet Gaute, som lå under ham og fikk knapt puste. «Au!» hylte Klaus og tok av seg skøytene. «Det stikker!» «Unnskyld,» sa Gaute og holdt seg fast i et tau. Fem av piggene hans hadde spiddet Klaus. «Du ser ut som en hvit nålepute. Nesten som en sau! Her, ta denne baufilen.» «Det gjør du også,» sa Klaus og begynte å le. «Nei, du ser ut som en ball med pigger.» Gaute ble flau. «Vil dere ha litt graut ? » sa Aud, og gikk mot huset. «Jeg vil gjerne ha litt maursuppe,» sa Gaute.',
	},
];
export const Session27 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ei'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ei'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ei'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ei'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 25,
		intermediateCount: 18,
		clickInstructions: clickInstruction_27,
		wrtieInstructions: writeinstruction_27,
		targetWord: ['Ei'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Ridder Stein skulle redde prinsesse Eirin. Men der tok han feil. Eirin sang at dragen skulle spise han. Dragen pustet ild mot ham.',
		intermediateText:
			'Ridder Stein skulle redde prinsesse Eirin. Men der tok han feil. Eirin sto på balkongen og sang til dragen. Men alt gikk skeis. Stemmen var så klein at ridder Stein ble flau. Hun sang om ham og veivet med lutten. «Hei, Eirin. Er alt greit?» sa ridder Stein. Hun neiet tilbake som svar. Dragen snudde seg plutselig. Eirin sang om ham. At dragen skulle spise han. Den hadde blitt forhekset! «Husj,» sa Stein. «Ha deg vekk, ditt beist!» Dragen pustet ild mot ham. «Nei!» hylte ridder Stein, og det var siste gang noen hadde hørt fra ham.',
		text: 'Ridder Stein trodde han skulle redde prinsesse Eirin i steintårnet, men der tok han feil. Eirin sto på balkongen og sang til dragen. Det var da hun åpnet munnen at alt gikk skeis. Stemmen var så klein at ridder Stein ble flau. Hun sang om ham og veivet med lutten. «Hei, Eirin. Er alt greit?» sa ridder Stein, og bukket. Hun neiet tilbake som svar, og sang videre. Dragen snudde seg plutselig, og Stein skjønte nå hva alt dreide seg om. Eirin sang om ham. At dragen skulle spise han. Den hadde blitt forhekset! «Husj,» sa Stein. «Ha deg vekk, ditt beist!» Et eiketre knakk som en fyrstikk da dragen reiste seg opp. Den veivet med halen og slo den i leiren. Så pustet den ild mot ham. «Nei!» hylte ridder Stein, og det var siste gang noen hadde hørt fra ham.',
	},
];
export const Session28 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Øy'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'øy'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Øy'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'øy'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 21,
		intermediateCount: 16,
		clickInstructions: clickInstruction_28,
		wrtieInstructions: writeinstruction_28,
		targetWord: ['Øy'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Frøya bor like ved den lille byen Hisøy. I dag har Eføy rømt. Frøya tar med seg et glass syltetøy. Hun vet at Eføy liker alt som er søtt.',
		intermediateText:
			'Frøya bor på en liten høyde like ved den lille byen Hisøy. Det er en liten ferieøy som mange øyhoppere besøker. I dag har Eføy rømt. Det er hunden til Frøya, og hun er redd hun ikke finner ham. Eføy liker ikke støy, for han ser dårlig. Frøya tar med seg et glass syltetøy. Hun setter den ned ved en søyle. «Eføy, er du der!» roper hun. Hun går forbi en gammel brøytebil, og spør en mann som spiller fløyte: «Har du sett en liten, grå nakenhund? Han heter Eføy, og har hvite dusker på ørene.» Mannen har ikke sett ham.',
		text: 'Frøya bor på en liten høyde like ved den lille byen Hisøy. Det er en liten ferieøy som mange øyhoppere besøker hver dag det er varmt i været. I dag har Eføy rømt. Det er hunden til Frøya, og hun er redd hun ikke finner ham. Eføy liker ikke støy, for han ser dårlig. Eføy er enøyd og nesten blind. Frøya tar med seg et glass syltetøy, for hun vet at Eføy liker alt som er søtt. Hun setter den ned ved en søyle. «Eføy, er du der!» roper hun, mens hun leter i Hisøy. Hun går forbi en gammel brøytebil, og spør en mann som spiller fløyte: «Har du sett en liten, grå nakenhund? Han heter Eføy, og har hvite dusker på ørene.» Mannen har ikke sett ham, men kanskje du finner Eføy?',
	},
];
export const Session29 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Jul'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'jul'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Jul'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'jul'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 8,
		intermediateCount: 5,
		clickInstructions: clickInstruction_29,
		wrtieInstructions: writeinstruction_29,
		targetWord: ['Jul'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Julefeiring i Norge Til Luciadagen baker man lussekatter. Gutter og jenter kler seg ut og går i tog. Vi legger gaver under juletreet. På julenatten setter mange ut grøt til nissen.',
		intermediateText:
			'I flere nordiske land feires Luciadagen 13.desember. Gutter og jenter kler seg ut og går i tog. Fremst i toget går en jente som er Lucia. Hun har en krone i håret med lys på. Til Luciadagen baker man lussekatter. Mange pynter til jul med en bukk laget av halm, nisser og julestjerner. Vi har juletre inne i huset som vi legger gaver under. På julenatten setter mange ut grøt til nissen. Vi spiser gjerne ribbe, pinnekjøtt eller fisk på julaften.  ',
		text: 'I flere nordiske land feires Luciadagen 13.desember. Denne dagen minnes vi St. Lucia som var god mot de fattige og gav dem mat. Gutter og jenter kler seg i hvite kjoler og kjortler. De går i tog. Fremst i toget går en jente som er Lucia. Hun har en krone i håret med lys på. Til Luciadagen baker man lussekatter. Lussekatter er gjærbakst som er krydret med safran, og det er derfor de er gule. I Norge pynter mange til jul med en bukk laget av halm og sløyfebånd, nisser og julestjerner. Julestjerner har røde eller hvite blomster. Vi har juletre inne i huset som vi legger gaver under. På julenatten setter mange ut grøt til nissen. Vi spiser gjerne ribbe, pinnekjøtt eller fisk på julaften. Mange baker ulike sorter julekaker før jul.',
	},
];
export const Session30 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ng'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ng'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ng'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ng'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 12,
		intermediateCount: 9,
		clickInstructions: clickInstruction_30,
		wrtieInstructions: writeinstruction_30,
		targetWord: ['Ng'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'I England åpner vi julegavene 1.juledag. Mange går fra dør til dør for å synge julesanger. Alle henge opp julesokker til julenissen. Til julemiddag spiser vi kalkun.	',
		intermediateText:
			'I England åpner vi julegavene 1.juledag. Vi pynter huset vårt med kristtorn og julelys. I stuen har vi et juletre. Mange er glade i å gå fra dør til dør for å synge julesanger. Vi sender julekort til familie og venner. Alle henger opp julesokker med godterier og små gaver til julenissen. Vi henger opp julesokker på sengen vår som julenissen legger igjen gaver i. Hvis vi ikke har vært snille får vi kull i dem! Til julemiddag spiser vi kalkun. Ved siden av hvert fat legger vi en bongbong. De smeller når vi dra i dem! ',
		text: 'I England åpner vi julegavene 1.juledag. Vi pynter huset vårt med kristtorn og julelys. I stuen har vi et juletre. Mange er glade i å gå fra dør til dør for å synge julesanger. Vi sender julekort til familie og venner. Tradisjonen med å sende julekort startet faktisk i England. I England kaller vi julenissen Father Christmas. På julaften skriver vi brev til julenissen og ønsker oss gaver. Brevene kaster vi i peisen så de brenner opp og blir sendt ut pipen. Alle henger opp julesokker med godterier og små gaver til julenissen. Vi henger opp julesokker på sengen vår som julenissen legger igjen gaver i. Hvis vi ikke har vært snille får vi kull i dem! Hele familien samles til en stor julemiddag 1.juledag. Vi spiser kalkun, pølser i bacon og har julepudding til dessert. Ved siden av hvert fat legger vi en bongbong. De smeller når vi dra i dem! Hvert år har dronningen en tale på TV som vi ser sammen.',
	},
];
export const Session31 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'au'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ei'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'au'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ei'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 5,
		intermediateCount: 3,
		clickInstructions: clickInstruction_31,
		wrtieInstructions: writeinstruction_31,
		targetWord: ['Au', 'Ei'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'I Australia feirer vi jul 25.desember. Det sommer her på den tiden av året. Vi griller ofte på stranden og spiser middag der! Vi pynter husene våre med julebusker.',
		intermediateText:
			'I Australia feirer vi jul 25.desember. Det sommer her på den tiden av året. Det er vanligvis veldig varmt og mye sol.  Vi griller ofte på stranden og spiser middag der!  Vi pynter husene våre med julebusker. Buskene har røde blomster og grønne blader. Mange av oss synger julesanger i parken på kvelden. I Australia leverer julenissen ut gaver til alle på julaften, men vi åpner dem ikke før morgenen etter.',
		text: 'I Australia feirer vi jul 25.desember. På vår side av jorden er det sommer på den tiden av året. De er vanligvis fint og varmt vær, så vi griller ofte på stranden og spiser middag der! Vi spiser gjerne sjømat, ulike typer kjøtt og salat til middag. Vi har en kjempegod dessert som heter Pavlova. Den har en sprø bunn av marengs og vaniljekrem med frukt på toppen. Vi pynter husene våre med julebusker. Det er en spesiell plante som vokser her i Australia. Den har røde blomster og grønne blader. Mange av oss synger julesanger i parken på kvelden, det kalles «Carols By Candlelight». I Australia leverer julenissen ut gaver til alle på julaften, men vi åpner dem ikke før morgenen etter.',
	},
];
export const Session32 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'mm'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ss'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'mm'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ss'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 5,
		intermediateCount: 6,
		clickInstructions: clickInstruction_32,
		wrtieInstructions: writeinstruction_32,
		targetWord: ['Mm', 'Ss'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn', 'ga'],
		easyText: 'Ordet jul, er gammelt. Før het det jól. De gikk julebukk og fikk noe godt. Grøt ble satt ut til fjøsnissen. Fjøsnissen er ikke julenissen.',
		intermediateText:
			'Ordet jul, er gammelt. Før het det jól. Julen var hedensk. En feiring der de ofret. Hedensk betyr at julen ikke var kristen. Jól var en fest. De feiret et godt år. De gikk julebukk og fikk noe godt. Grøt ble satt ut til fjøsnissen. Fjøsnissen er ikke julenissen. Julenissen stammer fra Sankt Nikolaus. Det var en snill mann som ga bort gaver. Han ga til fattige. Han forsvarte svake. I dag er hedensk jól blandet med kristen jul. Kristne feirer Jesu bursdag.',
		text: 'Ordet jul, er gammelt. Før het det jól. Vi har mange gamle tradisjoner. Julen var hedensk og en feiring der de ofret mat til de norrøne gudene. Hedensk betyr at julen ikke var kristen. Jól var en fest og de feiret et godt år. De håpte neste år skulle bli like godt. Mye handlet om å spise. Barn gikk på dørene og sang. Da gikk de julebukk og fikk noe godt tilbake som premie. Grøt ble satt ut til fjøsnissen så han ikke ble sur. Han var en av de underjordiske. Fjøsnissen er ikke den samme som julenissen. I dag blandes de sammen. Julenissen stammer fra en helgen som heter Sankt Nikolaus. Han var snill Han ga bort gaver til fattige og barn. Derfor passer han som julenissen. I dag har gammel hedensk jól blandet seg med kristen jul. Kristne feirer Jesus sin fødselsdag.',
	},
];

export const Session33 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'tt'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ll'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'tt'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ll'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 6,
		intermediateCount: 5,
		clickInstructions: clickInstruction_33,
		wrtieInstructions: writeinstruction_33,
		targetWord: ['Tt', 'Ll'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Den største høytiden vi har i Kina er nyttår. Vi kaller den også for vårfestivalen. Vi har store parader.   Alle paradene har en stor drage.',
		intermediateText:
			'Blomsterbilen var gul.  Det har vi i januar og februar hvert år.  Vi kaller den også for vårfestivalen.  Vi pynter husene våre med fargene rødt og gull.  Vi spiser godsaker som søte riskaker, appelsiner og klementiner.  Fyrverkeri blir brukt for å skremme vekk ulykke.  Vi har store parader.  Alle paradene har en stor drage. Den siste dagen sender vi lanterner på vannet og opp i luften! Barna får gaver som er små røde konvolutter med penger i.',
		text: 'Den største høytiden vi har i Kina er nyttår. Det har vi i januar og februar hvert år, og festen varer i 15 dager! Den markerer slutten på vinteren, så vi kaller den også for vårfestivalen. Vi pynter husene våre med fargene rødt og gull. Vi spiser godsaker som søte riskaker, appelsiner og klementiner. Veldig mye fyrverkeri blir brukt for å skremme vekk ulykke, og vi har store og bråkete parader. Alle paradene har en stor drage laget av mennesker som går under et stort kostyme. En person holder hodet, og resten holder kroppen. De løper sammen gjennom gatene. Den siste dagen sender vi lanterner på vannet, og til og med opp i luften! Barna får gaver som er små røde konvolutter med penger i.',
	},
];
export const Session34 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Bl'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'bl'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Bl'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'bl'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 13,
		intermediateCount: 12,
		clickInstructions: clickInstruction_34,
		wrtieInstructions: writeinstruction_34,
		targetWord: ['Bl'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Blomsterbilen var gul. Den slapp ut røyk. Byen ble skitten. Den ble stanset av politibilen.',
		intermediateText:
			'Blomsterbilen var gul.  Blå røyk full av bly, lå bak som et teppe.  En blond mann i blått stanset politibilen.  Den sperret veien for Billy.  «Er det noe galt?» sa Billy som hadde blomster å levere.  «Du skitner til hele byen med den bilen,», sa mannen og blunket raskt.  Han begynte å bli bleik. «Skitner til byen?» sa Billy.  «Jeg har med blåklokker og vakre blomster. «Bilen....forpester...» sa mannen og hostet. Så svimte han av på et blunk, midt på veien.',
		text: 'Blomsterbilen var gul, den harket og hostet langs veien i byen. Blå røyk full av bly, lå bak som et teppe. En blond mann i blått kjørte forbi, og stanset politibilen. Den sperret veien for Billy. «Er det noe galt?» sa Billy som hadde blomster å levere. Han var blid. Det var han alltid når han måtte snakke med lovens lange arm. «Du skitner til hele byen med den bilen,», sa mannen og blunket raskt. Det sved i øynene. Han begynte å bli bleik. «Skitner til byen?» sa Billy. «Jeg har med blåklokker og vakre blomster. De er da rene og lukter godt!» «Bilen....forpester...» sa mannen og hostet hardt. Så svimte han av på et blunk, midt på veien.',
	},
];
export const Session35 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Br'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'br'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Br'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'br'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 17,
		intermediateCount: 10,
		clickInstructions: clickInstruction_35,
		wrtieInstructions: writeinstruction_35,
		targetWord: ['Br'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Brage lånte bilen til far. Han braste inn i en gul bil. Bilen begynte å brenne. Brage er 12 år.',
		intermediateText:
			'Dagen da verden bråbremset Brage hadde tyvlånt bilen til faren. Det angret han på nå. Han bråbremset, og braste rett inn i en gul bil. Hvorfor lærte han aldri! Den gule bilen begynte å brenne. En dame prøvde å bryte seg ut, men døren var knust. Brage ringte brannvesenet og løp til den gule bilen. Han fikk damen ut på den andre siden av bilen. Rett etter var bilen overtent. «Takk for at du reddet meg» sa damen. «Men er du gammel nok til å kjøre bil?» «Jeg er tolv,» sa Brage brydd.',
		text: 'Brage hadde tyvlånt bilen til faren. Det angret han på nå. Han bråbremset, og braste rett inn i en gul bil med et brak. Brillene spratt av og brusen smalt inn i bilruten. Det var den bråkjekke broren hans som hadde sagt at han aldri ville våge å ta bilen. Hvorfor lærte han aldri! Mens Brage satt der i bilen midt på broen, begynte den gule bilen å brenne. En dame prøvde å bryte seg ut, men døren var knust. Brage ringte brannvesenet og løp til den gule bilen. Han fikk damen ut på den andre siden av bilen. Rett etter var bilen overtent. Det brant voldsomt. «Takk for at du reddet meg, unge mann,» sa damen. «Men er du gammel nok til å kjøre bil, du da?» «Jeg er tolv,» sa Brage brydd.',
	},
];

export const Session36 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Dr'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'dr'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Dr'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'dr'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 16,
		intermediateCount: 13,
		clickInstructions: clickInstruction_36,
		wrtieInstructions: writeinstruction_36,
		targetWord: ['Dr'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Dirk drømmer aldri. Snart sover han. Så ser han en drage. «Hjelp!» hyler Dirk i søvne.',
		intermediateText:
			'Dirk drømmer aldri. Mor har hengt opp en drømmefanger over senga. Den skal hjelpe ham med å drømme. «Ta på deg nattdrakt, Dirk,» sier mor. Dirk drikker litt kaldt vann og gjør seg klar for natten. Dirk bor i Drammen. Det er drittvær ute. Snart sover han. Alt er svart først, men så dukker det opp en mann i en lilla drakt. Han skjønner at det er Ole Lukkøye som drysser støv over ham. Så ser han en drage. Dragen spyr ild og dreper alt i sin vei. «Hjelp!» hyler Dirk i søvne. «Jeg brenner!»',
		text: 'Dirk drømmer aldri. Mor har hengt opp en drømmefanger over senga. Den skal hjelpe ham med å drømme. «Ta på deg nattdrakt, Dirk,» sier mor, før hun følger ham til sengs. Dirk drikker litt kaldt vann og gjør seg klar for natten. Dirk bor i Drammen. Det er drittvær ute. Flere dråper vann renner ned vinduet. Det prikker i huden under den kalde dynen. Snart sover han. Alt er svart først, men så dukker det opp en mann i en lilla drakt. Dirk lurer på hva han driver med. Han skjønner at det er Ole Lukkøye som drysser støv over ham. Så ser han en drage og ett par skyer som driver forbi ved stranda. En drage spyr ild og dreper alt i sin vei, før den sluker ham i et jafs. «Hjelp!» hyler Dirk i søvne. «Jeg brenner!»',
	},
];

export const Session37 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Fl'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'fl'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Fl'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'fl'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 24,
		intermediateCount: 24,
		clickInstructions: clickInstruction_37,
		wrtieInstructions: writeinstruction_37,
		targetWord: ['Fl'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Det er fri og flammefest. En drage spruter ild. Flammen brente flagg, klær og hår. Heksa Flåse og ble flau.',
		intermediateText:
			'Det er fri og flammefest.  Alle hekser har dratt på utflukt. Dette gjør de en gang i året. En drage flyr ned fra fjellet for å sprute ild på en flaske. De har samlet seg i flokk for å åpne flasken med flammen i. «Varm flasken!» sa heksa Flåse. Dragen gjorde det den ble bedt om. Det freste i flasken. Flammen flekket tenner og snerret. Den vokste seg stor, og så sprakk glasset. Flammen flyktet i et poff. «Flaks!» sa heksa Flire, da hun så at flammen hadde brent både flagg, klær, hår og flette. «Den fikk jeg midt i fleisen!» flirte Flåse og ble flau.',
		text: 'Det er fri og flammefest. Alle hekser har dratt på utflukt. Dette gjør de en gang i året. På denne dagen flyr en drage ned fra fjellet for å sprute ild på en flaske som de har satt frem. De har samlet seg i flokk for å åpne flasken med flammen i. «Varm flasken!» sa heksa Flåse til den blå dragen, og kostet av seg litt flass. Dragen gjorde det den ble bedt om. Det freste i flasken. Flammen flekket tenner og snerret. Den vokste seg stor, og så sprakk glasset. Flammen flyktet i et poff. «Flaks!» sa heksa Flire, da hun så at flammen hadde brent både flagg, klær, hår og flette. Hele Flåse var full av sot. «Den fikk jeg midt i fleisen!» flirte Flåse og ble flau.',
	},
];

export const Session38 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'St'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'st'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'St'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'st'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 11,
		intermediateCount: 7,
		clickInstructions: clickInstruction_38,
		wrtieInstructions: writeinstruction_38,
		targetWord: ['St'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Solen er en stor stjerne. Det er åtte planeter. Planetene går rundt solen. Det kalles å gå i bane.',
		intermediateText:
			'Det er fremdeles mye vi ikke vet om verdensrommet. Vi vet ganske mye om vårt eget solsystem. Solsystemet vårt består av Solen og planetene. Solen er en kjempestor, rund stjerne. Solen ligger midt i solsystemet vårt. Planetene har rund form, og beveger seg i sirkel rundt solen. Vi kaller dette å gå i bane. Planetene er mindre enn Solen. Solen har en sterk tyngdekraft som trekker planetene mot den. Planetene blir ikke dratt inn i Solen fordi de går rundt og rundt så fort. Uten tyngdekraften hadde vi falt av Jorden og ut i verdensrommet.',
		text: 'Det er fremdeles mye vi ikke vet om verdensrommet. Vi vet ganske mye om vårt eget solsystem. Solsystemet vårt består av Solen og planetene. Solen er en kjempestor, rund stjerne. Solen ligger midt i solsystemet vårt. Planetene har rund form, og beveger seg i sirkel rundt solen. Vi kaller dette å gå i bane. Planetene er mindre enn Solen. De åtte planeter i solsystemet vårt er Merkur, Venus, Jorden, Mars, Jupiter, Saturn, Uranus, og Neptun. Hvordan kan planetene holde seg nær Solen? Solen har en sterk tyngdekraft som trekker planetene mot den. Tyngdekraften er som en kraftig streng som drar i planetene. Planetene blir ikke dratt inn i Solen fordi de går rundt og rundt så fort. Det blir som å svinge en ball som er festet i et tau, rundt i stor fart. Uten tyngdekraften hadde vi falt av Jorden og forsvunnet ut i verdensrommet.',
	},
];

export const Session39 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'gi'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Sj'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'gi'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Sj'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 3,
		intermediateCount: 2,
		clickInstructions: clickInstruction_39,
		wrtieInstructions: writeinstruction_39,
		targetWord: ['Gi', 'Sj'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Merkur har mange krater. Atmosfæren på Venus er giftig. Jorda har mye vann. Mars er kald og støvete.',
		intermediateText:
			'Det er åtte planeter i solsystemet vårt. Planetene Merkur, Venus, Jorda og Mars er kjent som «steinplaneter». Du kan se Merkur med dine egne øyne på himmelen. Merkur er full av krater og groper. Steiner fra verdensrommet har krasjet inn i den. Atmosfæren på Venus er giftig. Trykket på overflaten er stort. Vi bor på planeten Jorden. Jordkloden blir også kalt Tellus. En stor del av Jordens overflate er dekket av vann. Vannet er grunnlaget for livet på Jorden. Mars er kald og støvete. Mange kaller den for «den røde planet».',
		text: 'Det er åtte planeter i solsystemet vårt. Planetene Merkur, Venus, Jorda og Mars er kjent som «steinplaneter». Det er fordi de har steinete overflater. Du kan se Merkur med dine egne øyne på himmelen rett før soloppgang og rett etter solnedgang. Merkur er full av krater og groper. De har blitt til etter at steiner fra verdensrommet har krasjet inn i overflaten. Venus snurrer i motsatt retning av de fleste andre planetene. Atmosfæren er giftig. Trykket på overflaten ville knust deg på et øyeblikk. Vi bor på planeten Jorden. Jordkloden blir også kalt Tellus.  Hele 2/3 av Jordens overflate er dekket av vann. Vannet er grunnlaget for livet på Jorden. Mars er kald og støvete. Det jernrike støvet gir Mars sin røde farge. Mange kaller den for «den røde planet».',
	},
];

export const Session40 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Kj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'kj'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Kj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'kj'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 5,
		intermediateCount: 5,
		clickInstructions: clickInstruction_40,
		wrtieInstructions: writeinstruction_40,
		targetWord: ['Kj'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Jupiter har en rød flekk. Saturn har store ringer. Uranus har blå-grønn farge. Neptun har blå farge.',
		intermediateText:
			'Det er åtte planeter i solsystemet vårt. Planetene Jupiter, Saturn, Uranus og Neptun er kjent som «gasskjemper». Det er fordi de er veldig store og består av gass. Jupiter har mange farger på grunn av ulike gasser som er i planeten. Det mest berømte kjennetegnet til Jupiter er «Den røde flekk». Saturn er mest kjent for sine store ringer. Det er is og stein som går i bane rundt planeten. Uranus har blå-grønn farge, og er den kaldeste planeten i solsystemet. Neptun har blå farge. Planeten er kjent for sine enormt sterke vinder.',
		text: 'Det er åtte planeter i solsystemet vårt. Planetene Jupiter, Saturn, Uranus og Neptun er kjent som «gasskjemper». Det er fordi de er veldig store og består av gass. Jupiter har mange farger på grunn av ulike gasser som er i planeten. Det mest berømte kjennetegnet til Jupiter er «Den røde flekk», en storm som har rast i hundrevis av år. Saturn er mest kjent for sine store ringer. Disse ringene består for det meste av is og stein som går i bane rundt planeten. Uranus har blå-grønn farge, og er den kaldeste planeten i solsystemet. Uranus ligger på siden. Den har noen små ringer som går rundt planetene fra toppen til bunnen. Neptun har blå farge. Planeten er kjent for sine enormt sterke vinder. Vindene blåser med supersonisk fart.  ',
	},
];

export const Session41 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Elg'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'elg'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Elg'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'elg'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 17,
		intermediateCount: 13,
		clickInstructions: clickInstruction_41,
		wrtieInstructions: writeinstruction_41,
		targetWord: ['Elg'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Elgen er plante-eter. Elgen er et bytte-dyr. Elgen trives best alene. Elg-oksen har store gevir.',
		intermediateText:
			'Elgen er et varmblodig pattedyr. Elgen liker å leve i kalde klima. Elgen er planteeter. Det betyr at de spiser planter og grønnsaker, ikke kjøtt. Elgen spiser gress, små busker og blad. Elgen er et byttedyr. Det betyr at rovdyr jakter elgen for mat. Rovdyr som spiser elg, kan være ulv, jerv, bjørn og mennesker. Elgen trives best alene. De er enslige dyr. En hannelg kalles for elgokse. Hunnene blir kalt for elgku. Ungene kalles for kalver. Hannelger har store, brede gevir. Elgoksen vil slåss mot hverandre for å bevise at geviret deres er størst.',
		text: 'Elgen er et varmblodig pattedyr som hører til samme familie som hjorten. Elgen liker å leve i kalde klima. Det lever elg i Canada, Nord-Amerika og i Nord-Europeiske land. Elgen er planteeter. Det betyr at de spiser planter og grønnsaker, ikke kjøtt. Elgen spiser gress, små busker og blad. Elgen er et byttedyr. Det betyr at rovdyr jakter elgen for mat. Rovdyr som spiser elg, kan være ulv, jerv, bjørn og mennesker. Elgen trives best alene. De er enslige dyr. Det betyr at elgen er ulik andre hjortedyr, som lever i flokk. En hannelg kalles for elgokse. Hunnene blir kalt for elgku. Ungene kalles for kalver. Hannelger har store, brede gevir. Hunnene vil velge en make ut fra størrelsen på geviret. Hannelgene vil slåss mot hverandre for å bevise at geviret deres er størst. Geviret til en voksen elg kan bli mer enn 2 meter i diameter.',
	},
];

export const Session42 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'gj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'hj'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'gj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'hj'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 6,
		intermediateCount: 3,
		clickInstructions: clickInstruction_42,
		wrtieInstructions: writeinstruction_42,
		targetWord: ['Gj', 'Hj'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Hver vinter vil hannelgen felle geviret. Nytt gevir vil vokse ut våren etter. Elgene har varm, brun pels. En elg kan bli over 2 meter.',
		intermediateText:
			'Hver vinter vil hannelgen felle geviret. Nytt gevir vil vokse ut våren etter. Det tar 3 til 5 måneder for et nytt gevir å vokse ut. Elgene har varm, brun pels. De har to lag med pels. Luft mellom de to lagene hjelper dem å holde seg varme, og å holde seg flytende når de svømmer. I gjennomsnitt vil en voksen elg vokse til litt over 2 meter. En voksen elgokse veier i gjennomsnitt 700 kilo. Hunnene veier mindre. Hulemalerier fra steinalderen viser at mennesker har jaktet på elg for tusenvis av år siden.',
		text: 'Hver vinter vil hannelgen felle geviret. Dette hjelper elgene å spare på energien for den kalde vinteren. Nytt gevir vil vokse ut våren etter. Det tar 3 til 5 måneder for et nytt gevir å vokse ut. Elgene har varm, brun pels. De har to lag med pels. Luft mellom de to lagene hjelper dem å holde seg varme om vinteren, og det hjelper dem å holde seg flytende når de svømmer. I gjennomsnitt vil en voksen elg vokse til litt over 2 meter. En voksen elgokse veier i gjennomsnitt 700 kilo. Hunnene veier mindre, de veier i gjennomsnitt 490 kilo. Hulemalerier fra steinalderen viser at mennesker har jaktet på elg for tusenvis av år siden. Elgen er et av nasjonaldyrene i Canada. Den er avbildet på våpenskjold. Elger er også avbildet på våpenskjold i Finland.',
	},
];

export const Session43 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Bjørn'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'bjørn'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Bjørn'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'bjørn'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 14,
		intermediateCount: 11,
		clickInstructions: clickInstruction_43,
		wrtieInstructions: writeinstruction_43,
		targetWord: ['bjørn'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Bjørnen spiser kjøtt, fisk, planter og honning De kan løpe veldig raskt. Om vinteren går bjørnen i hi. Isbjørnen er den største bjørnen.',
		intermediateText:
			'Bjørnen lever over hele verden, unntatt i Australia. En gruppe med bjørner kalles for en bjørnefamilie. Hannbjørner kalles for bamse, og hunbjørner blir kalt binne. Babybjørner er bjørnunger. De spiser kjøtt og fisk, samt planter, frukt, nøtter og honning. Bjørner er store dyr, med raggete pels som beskytter mot kulde. De kan løpe veldig raskt, og de er gode klatrere. I de kalde vintermånedene vil bjørnene gå i dvale. Det betyr at de kryper inn i en hule eller et hi, for å sove til våren. Isbjørnen er den største av bjørneartene.',
		text: 'Bjørnen lever over hele verden, unntatt i Australia. En gruppe med bjørner kalles for en bjørnefamilie. Hannbjørner kalles for bamse, og hunnbjørner blir kalt binne. Babybjørner er bjørnunger. De spiser kjøtt og fisk, samt planter, frukt, nøtter og honning. Dette betyr at bjørner er altetende – de spiser hva som helst! Bjørner er store dyr, med raggete pels som beskytter mot kulde. Selv om de er veldig store, er bjørner atletiske. De kan løpe veldig raskt, og de er gode klatrere, og mange er dyktige svømmere. I de kalde vintermånedene vil bjørnene gå i dvale. Det betyr at de kryper inn i en hule eller et hi, for å sove til våren. Dette kan vare i 100 dager! Isbjørnen er den største av bjørneartene. Hannbjørnen kan veie inntil 700 kg og bli inntil 3 meter lang. De er det største landrovdyret i verden.',
	},
];

export const Session44 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ul'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ul'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ul'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ul'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 14,
		intermediateCount: 10,
		clickInstructions: clickInstruction_44,
		wrtieInstructions: writeinstruction_44,
		targetWord: ['Ul'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Ulven er et rovdyr. Ulven lever i flokk. Ungene kalles valper. Ulven har tykk pels.',
		intermediateText:
			'Ulven er et rovdyr. Den er kjøtteter. Ulven lever i grupper eller flokk. Ulven jakter på byttedyr sammen med flokken. De klare å ta dyr som er større enn dem selv. Ulven uler for å advare om fare. Ulvens ul kan høres over ti kilometer unna. Unge ulver kalles for valper. En valp lever sammen med foreldrenes flokk i minst to år. Hver flokk har sitt eget område. Ulven har tykk vinterpels. Denne pelsen er ofte grå og brun for å gi dem kamuflasje. Om våren og sommeren feller ulven vinterpelsen. Om høsten vokser den ut igjen.',
		text: 'Ulven er et rovdyr som jakter på byttedyr. Den er kjøtteter. Ulven lever i grupper eller flokk, med sju-åtte ulver. Ulven jakter på byttedyr sammen med flokken. De samarbeider for å klare å ta byttedyr som er større enn dem selv, for eksempel hjort, villsvin eller elg. Ulven uler for å kommunisere med en annen flokk eller advare om fare. Hver flokk har et unikt ul. Ulvens ul kan høres over ti kilometer unna. Unge ulver kalles for valper. En valp lever sammen med foreldrenes flokk i minst to år. Hver flokk har sitt eget område. Ulven kan bli opptil 1,60 meter lang, og veie rundt 40 kilo. Ulven har tykk, luftig vinterpels, som beskytter dem mot kulden. Denne pelsen er ofte grå og brun for å gi dem kamuflasje. Om våren og sommeren feller ulven vinterpelsen. Om høsten vokser den ut igjen.',
	},
];

export const Session45 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'rød'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Rød'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'rød'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Rød'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 6,
		intermediateCount: 7,
		clickInstructions: clickInstruction_45,
		wrtieInstructions: writeinstruction_45,
		targetWord: ['Rød'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Rødreven er alt-etende. Rødreven spiser mest kjøtt. Ulver og ørner jakter på rev. Rever bor i huler under jorden.',
		intermediateText:
			'Rødreven lever vanligvis sammen i par eller i små grupper. Rødreven er altetende. Det betyr at den kan spise både planter og kjøtt. Rødreven spiser mest kjøtt. Rødreven er også byttedyr for større rovdyr. Ulver og store ørner jakter på rev. En voksen rødrev bli rundt 50 cm høy og en meter lang. Den har utmerket hørsel. De fleste rødrever har en rødlig, brun pels. Rever bor i huler under jorden. Disse hulene kalles for hi. Valpene bor ofte med foreldrene sine i sju måneder mens de vokser og modnes.',
		text: 'Rødreven lever vanligvis sammen i par eller i små grupper. Dette kan være et revepar og deres valp. Vanligvis er de ikke enslige dyr. Rødreven er altetende. Det betyr at den kan spise både planter og kjøtt. Likevel består kostholdet deres stort sett av kjøtt. Vanlige byttedyr er kanin og ekorn. Selv om reven er et rovdyr, er rødreven også byttedyr for større rovdyr. Ulver og store ørner jakter på rev. I gjennomsnitt blir en voksen rødrev rundt 50 cm høy og nesten en meter lang. Den har store ører, noe som betyr at de har utmerket hørsel. Som navnet antyder, har de fleste rødrever en karakteristisk rødlig, brun pels. Rever bor i huler under jorden. Disse hulene kalles for hi. Valpene bor ofte med foreldrene sine i sju måneder mens de vokser og modnes.',
	},
];

export const Session46 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Sj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sj'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Sj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sj'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 3,
		intermediateCount: 2,
		clickInstructions: clickInstruction_46,
		wrtieInstructions: writeinstruction_46,
		targetWord: ['Sj'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Samene er et urfolk. Samene bor i flere land. Rune-bommen er en tromme. Samene pynter seg i samekofte.',
		intermediateText:
			'Bouorre beavivi! Vi heter Ailo og Biret! Vi er samer, et urfolk som bor i Norge, Sverige, Finland og Russland. Vi bor i Sápmi. Sápmi er et nordsamisk ord som brukes om området der vi bor, på språket vårt og på oss selv. Den gamle samiske religionen var sjamanistisk. Det betyr at de hadde en sjaman som var folket sin forbindelse til gudene. Runebommen er samenes berømte tromme av reinskinn. Når vi skal pynte oss bruker vi en samisk folkedrakt. På norsk kalles den samedrakt eller samekofte. De finnes i flere ulike varianter, slik som med bunader.',
		text: 'Vi er samer, et urfolk som bor i Norge, Sverige, Finland og Russland. Vi bor i Sápmi. Sápmi er et nordsamisk ord som brukes om området der vi bor, på språket vårt og på oss selv. De fleste samer som snakker samisk daglig bruker nordsamisk. Den gamle samiske religionen var sjamanistisk. Det betyr at de hadde en sjaman som var folket sin forbindelse til gudene. Denne sjamanen ble kalt noaiden. Den kunne spå i fremtiden og var synsk. Runebommen er samenes berømte tromme av reinskinn. På skinnet er det malt en del figurer. Disse trommene ble opprinnelig brukt av noaiden. Når vi skal pynte oss bruker vi en samisk folkedrakt. På norsk kalles den samedrakt eller samekofte. De finnes i flere ulike varianter, slik som med bunader.',
	},
];

export const Session47 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ing'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ing'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ing'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ing'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 4,
		intermediateCount: 3,
		clickInstructions: clickInstruction_47,
		wrtieInstructions: writeinstruction_47,
		targetWord: ['ing'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Vi bodde i lavvo og var nomader. Vi fulgte reinen på vandring etter mat. I dag bor samer i vanlige hus. Vi arbeider med ulike ting.',
		intermediateText:
			'Tradisjonelt har vi levd av fiske, jakt, fangst, sanking, håndverk og reindrift. Vi bodde i lavvo og var nomader som reiste rundt. Vi fulgte reinen på vandring mellom kysten og innlandet etter mat. Dagens samer bor i vanlige hus og arbeider med ulike ting. Noen av oss arbeider med reindrift og fiske. Andre arbeider i butikk, på skoler, som bønder, i restauranter, i helsevesenet og på anleggsplasser. Ja, vi er like forskjellige som alle andre. Det er viktig for oss å ta vare på tradisjoner. Vi er et urfolk og en minoritet.',
		text: 'Tradisjonelt har vi levd av fiske, jakt, fangst, sanking, håndverk og reindrift. Vi bodde i lavvo og var nomader som reiste rundt. Vi fulgte reinen på vandring mellom kysten og innlandet etter mat. Det var vanlig å dele inn samene i fire hovedgrupper ut i fra hva vi jobbet med og hvor vi levde: sjøsamer, elve- og innsjøsamer, skogsamer og fjellsamer. Dagens samer bor i vanlige hus og arbeider med ulike ting. Noen av oss arbeider med reindrift og fiske. Mange veksler mellom vinterboplass og sommerboplass. De flytter sammen med reinen. Andre arbeider i butikk, på skoler, som bønder, i restauranter, i helsevesenet og på anleggsplasser. Ja, vi er like forskjellige som alle andre. Det er viktig for oss å ta vare på tradisjoner. Vi har Sametinget som skal ta vare på samiske rettigheter, fordi vi er et urfolk og en minoritet.',
	},
];

export const Session48 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Sk'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sk'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Sk'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sk'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 22,
		intermediateCount: 14,
		clickInstructions: clickInstruction_48,
		wrtieInstructions: writeinstruction_48,
		targetWord: ['Sk'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Samiske barn fikk ikke snakke samisk på skolen. De måtte lære norsk og fikk ikke leve slik de ønsket. Mange måtte bo på internatskoler langt borte fra foreldrene og noen mistet språket sitt.',
		intermediateText:
			'Tidlig på 1900-tallet ble det vanskelig for samene i Norge. Myndighetene ønsket at de skulle bli norske som alle andre. Samiske barn ble tatt fra foreldrene og flytta til internatskoler langt hjemmefra. Der skulle de lære å bli norske. De fikk ikke undervisning på samisk. De fikk heller ikke leve slik de selv ønsket sammen med foreldrene på gården, eller flytte med reinflokkene. Fra 1990 fikk samiske barn rett å lære samisk på skolen. I 1997 besøkte Kong Harald Sametinget der han beklaget fornorskningen samene som hadde vart i mer enn 100 år.',
		text: 'Tidlig på 1900-tallet ble det vanskelig for samene som bor i Norge. Myndighetene mente at de måtte bli norske og gi slipp på sitt språk, sin kultur og tradisjon. Samiske barn fikk ikke undervisning på samisk i skolen, og det ble ikke holdt gudstjenester på samisk. De fleste samiske barn måtte bo på internatskoler der de ble tvunget til å snakke, lese og skrive på norsk. Mange samiske barn lærte ikke å lese og skrive verken på samisk eller norsk. Når de flytta fra foreldrene på gården eller familien i reindrifta, mistet mange sitt samiske språk. Vi vet at samiske barn ble utsatt for rasisme i internatskolene og fikk problemer som varte lenge etterpå. Fra 1990 fikk samiske barn rett til å få undervisning på samisk. Samisk og norsk ble likeverdige språk i samiske områder. I noen områder er samiske barn tospråklige. I 1997 besøkte Kong Harald Sametinget der han beklaget fornorskningen av samene som hadde vart i mer enn 100 år.',
	},
];

export const Session49 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Sj'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Sj'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 3,
		intermediateCount: 3,
		clickInstructions: clickInstruction_49,
		wrtieInstructions: writeinstruction_49,
		targetWord: ['Sj'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Samenes nasjonaldag Samenes nasjonaldag er 6. februar. Da feires samisk kultur. Det er fest og aktiviteter for barn. Mange heiser det samiske flagget.',
		intermediateText:
			'6. februar i 1917 ble det første landsmøtet for samer holdt. Dette var første gang at samer fra Norge og Sverige var samlet for å snakke om sine rettigheter. Derfor feires samenes nasjonaldag den 6. februar hvert år. Samenes nasjonaldag blir også kalt samefolkets dag. Dette er en dag for å feire samisk kultur. Den blir feiret i alle land der det bor samer. Denne dagen feirer mange samer med fest og aktiviteter for barn. De tar på seg samiske festklær, kofter, og synger den samiske nasjonalsangen. Mange heiser det samiske flagget.',
		text: '6. februar i 1917 ble det første landsmøtet for samer holdt. Dette var første gang at samer fra Norge og Sverige var samlet for å snakke om sine rettigheter. Derfor feires samenes nasjonaldag den 6. februar hvert år. Samenes nasjonaldag blir også kalt samefolkets dag. Dette er en dag for å feire samisk kultur. Den blir feiret i alle land der det bor samer. Samefolkets dag ble feiret for første gang i 1993. Denne dagen feirer mange samer med fest og aktiviteter for barn. De tar på seg samiske festklær, kofter, og synger den samiske nasjonalsangen. Mange heiser det samiske flagget. Det samiske flagget ble først brukt i 1986. En samisk kunstner laget flagget. På flagget er de samiske fargene rød, blå, gul og grønn. Midt på flagget er det en ring. Det skal være en halv sol og en halv måne. Ringen forteller også at samefolket holder sammen.',
	},
];

export const Session50 = [
	{
		wordCount: 14,
		intermediateCount: 7,
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'st'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'St'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'st'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'St'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		clickInstructions: clickInstruction_50,
		wrtieInstructions: writeinstruction_50,
		targetWord: ['St'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'Folk laget redskap og våpen av stein. De brukte steinvåpen til å jakte på dyr. De bodde ikke på en plass. Men flyttet rundt for å finne mat.',
		intermediateText:
			'Steinalderen er den tidsepoken folk laget redskap og våpen av stein. Spyd og økser ble laget av skarpe biter av stein som ble festet til pinner. Menneskene brukte steinvåpen til å jakte. Steinredskaper ble brukt til å kutte ting med. Menneskene som levde i steinalderen blir kalt for jegere og sankere. Det betyr at de levde i små grupper som hele tiden var på reise. De måtte flytte på seg for å finne mat. Det var ingen byer eller landsbyer enda.',
		text: 'Steinalderen er den tidsepoken folk laget redskap og våpen av stein. For eksempel ble hammeren laget av store, tunge steiner. Spyd og økser ble laget av skarpe biter av stein som ble festet til pinner. Menneskene brukte steinvåpen til å jakte på dyr. De brukte stein til å bygge hus. Noen steinredskaper ble brukt til å knuse og slå mat, eller å slipe ting til pudder. Steinredskaper ble brukt til å kutte ting med. Menneskene som levde i steinalderen blir kalt for jegere og sankere. Det betyr at de levde i små grupper som hele tiden var på reise. De måtte flytte på seg for å finne mat. Dette var en periode i historien før folk hadde begynt å bosette seg på et fast sted. Det var ingen byer eller landsbyer enda.',
	},
];

export const Session51 = [
	{
		clickInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Trykk på de ordene som har {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'j'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'J'}</b>
				</span>
				{'> i seg'}
			</span>
		),
		writeInstructionText: (
			<span style={{ color: 'yellow', fontSize: '18px' }}>
				Skriv de ordene som begynner med lyden {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'j'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'J'}</b>
				</span>
				{'>'}
			</span>
		),
		wordCount: 6,
		intermediateCount: 5,
		clickInstructions: clickInstruction_51,
		wrtieInstructions: writeinstruction_51,
		targetWord: ['J'],
		ignoreWords: ['i', 'å', 'for', 'må', 'kan', 'en', 'et', 'ei', 'på', 'inn'],
		easyText: 'I steinalderen var husene enkle. Husene kunne flyttes. Menneskene laget enkle båter. De brukte båtene når de fisket.',
		intermediateText:
			'I steinalderen var husene mye enklere enn i dag. Ofte var de laget av tre og hauger med stein. Husene var lette å ta ned og derfor kunne de flytte mye lettere enn vi gjør i dag. Steinaldermenneskene jaktet på alle typer dyr. Noen av disse finnes ikke lenger, som for eksempel den ullhårete mammuten. Den er utryddet. Steinaldermenneskene laget verktøy og våpen som skulle hjelpe de i hverdagen og under jakten. De lærte også å lage enkle båter. Dette førte til at de kunne fiske. Folk i steinalderen spiste ikke bare kjøtt.',
		text: 'I steinalderen var husene mye enklere enn i dag. Ofte var de laget av tre og hauger med stein. Det gjorde at menneskene kunne bygge husene der de vandret. Husene var lette å ta ned og derfor kunne de flytte mye lettere enn vi gjør i dag. Steinaldermenneskene jaktet på alle typer dyr. Noen av disse finnes ikke lenger, som for eksempel den ullhårete mammuten. Den er utryddet. Steinaldermenneskene laget verktøy og våpen som skulle hjelpe de i hverdagen og under jakten. Først brukte de spisse pinner som spyd. Senere forbedret de redskapene ved å bruke skarpe steiner for å lage skarpere spyd og piler. De lærte også å lage enkle båter. Dette førte til at de kunne fiske. Folk i steinalderen spiste ikke bare kjøtt.',
	},
];
