import React from 'react';
import { connect } from 'react-redux';
import * as data from './data/no-data';
import introAudio from '../../../../assets/sounds/FindWords-intro.m4a';
import AudioPlayer from '../../../AudioPlayer';
import TimerCount from '../../../TimerCount';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
import './styles.scss';
let detectedWord = [];
const defaultInputClass = 'rw-fw-input';
const getAllSessionItem = (session) => {
	const indexValue = `session${session}`;
	const sessionItems = data[indexValue];
	return sessionItems;
};
let total = 0;
let resultsGloabl = [];
const tempFullResults = [];

var firstCorrectAttempt = true;
var consecutiveCorrect = 0;
class FindWords extends React.Component {
	constructor(props) {
		super(props);
		this.audio = introAudio;
		this.sessionData = getAllSessionItem(this.props.selectedSession);
		const results = {};
		let allPossibilities;

		for (let i = 0; i < this.sessionData.length; i++) {
			total = total + this.sessionData[i].total;
			if (i === this.sessionData.length - 1) {
				allPossibilities = this.sessionData[i - 1].possibilites.concat(
					this.sessionData[i].possibilites
				);
			}
			results[i] = '';
		}
		const totalPossibleAnswers = total;
		this.state = {
			sessionItems: this.sessionData,
			textFieldState: null,
			answers: [],
			timeRanOut: false,
			totalPossibleAnswers,
			allPossibilities,
		};
	}
	handleKeyPress(e, possibilites, sessionIndex, defaultClass, incorrectClass, salimWord) {
		e.preventDefault(); // Ensure it is only this code that runs

		const value = e.target.value.toLowerCase();
		if (possibilites.includes(value) && !this.sessionData[sessionIndex].answers.includes(value)) {
			this.props.streak || consecutiveCorrect > 1 || firstCorrectAttempt
				? this.props.flipCoin(10, true)
				: this.props.streak
				? this.props.flipCoin(10, true)
				: this.props.flipCoin(5, false);
			consecutiveCorrect = consecutiveCorrect + 1;

			this.updateResult(salimWord, value, { isCorrect: true });
			detectedWord.push(value);
			this.sessionData[sessionIndex].answers.push(value);
			e.target.value = '';
			this.setState({
				sessionItems: this.sessionData,
			});
		} else if (this.sessionData[sessionIndex].answers.includes(value)) {
			this.sessionData[sessionIndex].defaultClass = incorrectClass;

			e.target.value = 'allerede besvart';
			this.setState({
				sessionItems: this.sessionData,
			});
			setTimeout(() => {
				this.sessionData[sessionIndex].defaultClass = defaultInputClass;
				this.setState({
					sessionItems: this.sessionData,
				});

				e.target.value = '';
				e.target.autofocus = true;
			}, 800);
		} else {
			this.sessionData[sessionIndex].defaultClass = incorrectClass;
			this.props.recordMistake();
			consecutiveCorrect = 0;
			this.updateResult(salimWord, value, { isCorrect: false });
			this.setState({
				sessionItems: this.sessionData,
			});
			setTimeout(() => {
				this.sessionData[sessionIndex].defaultClass = defaultInputClass;
				this.setState({
					sessionItems: this.sessionData,
				});

				e.target.value = '';
				e.target.autofocus = true;
			}, 500);
		}
	}
	updateResult(salimWord, value, status) {
		const { allPossibilities } = this.state;
		const allUserWords = [];
		tempFullResults.push({ word: salimWord, foundWord: value, status });
		resultsGloabl = [];
		let totalCorrect = 0;
		let totalRemaining = 0;
		tempFullResults.forEach((results) => {
			resultsGloabl.push(results);
			if (results.status.isCorrect) {
				totalCorrect = totalCorrect + 1;
			}
		});
		for (let i = 0; i < resultsGloabl.length; i++) {
			if (resultsGloabl[i].status.isCorrect) {
				allUserWords.push(resultsGloabl[i].foundWord);
			}
		}
		totalRemaining = this.notFounddWords(allPossibilities, allUserWords);
		for (let i = 0; i < totalRemaining.length; i++) {
			status = { isCorrect: false };
			resultsGloabl.push({ word: totalRemaining[i], foundWord: '', status });
		}
	}

	notFounddWords(first, second) {
		const res = [];
		for (let i = 0; i < first.length; i++) {
			if (second.indexOf(first[i]) === -1) {
				res.push(first[i]);
			}
		}
		for (let j = 0; j < second.length; j++) {
			if (first.indexOf(second[j]) === -1) {
				res.push(second[j]);
			}
		}
		return res;
	}
	revealAllWords(sessionItems) {
		for (let i = 0; i < sessionItems.length; i++) {
			const answers = sessionItems[i].answers;
			const possibilites = sessionItems[i].possibilites;
			const notFound = this.returnUnfoundWords(answers, possibilites);
			sessionItems[i].unAnswered = notFound;
		}
		this.setState({ sessionItems });
	}
	returnUnfoundWords(answers, possibilites) {
		const unfoundWords = [];
		let flag = 0;
		for (let i = 0; i < possibilites.length; i++) {
			flag = 0;
			for (let j = 0; j < answers.length; j++) {
				if (possibilites[i] === answers[j]) {
					flag = 1;
					break;
				}
			}
			if (!flag) {
				unfoundWords.push(possibilites[i]);
			}
		}
		return unfoundWords;
	}
	render() {
		const { onNextStep } = this.props;
		const { sessionItems } = this.state;
		let audioPlayerRef;
		return (
			<div>
				<div className='rw-lp-intro'>
					<div className='rw-infobar'>
						<TimerCount
							duration={this.props.isSuperTeacher ? 45 : 184}
							onComplete={() => {
								this.revealAllWords(sessionItems);
								this.setState({ timeRanOut: true });
							}}
						/>
					</div>
					<div className='rw-intro-text'>
						<p>
							<AudioPlayer
								autoPlay
								inline
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								src={this.audio}
							/>
							Skriv alle ordene du finner i ordene under.
						</p>
					</div>
				</div>
				<div className='rw-fw'>
					{sessionItems.map((item, sessionIndex) => {
						const {
							id,
							word,
							possibilites,
							answers,
							unAnswered,
							defaultClass,
							incorrectClass,
						} = item;
						let salimWord = '';

						word.forEach((single) => {
							salimWord = salimWord + single;
						});

						return (
							<div className='rw-fw-content'>
								<div className='rw-fw-content__question'>
									{word.map((letter) => {
										return (
											<span className='rw-fw-letters'>
												{letter}
											</span>
										);
									})}
								</div>
								<div className='rw-fw-content-answers'>
									{answers &&
										answers.map((answer) => {
											return (
												<span className='rw-fw-words'>
													{
														answer
													}
												</span>
											);
										})}
									{unAnswered &&
										unAnswered.map(
											(answer, index) => {
												let existingWords =
													index ===
													0
														? answers.length
														: 0;
												return (
													<span>
														{Boolean(
															existingWords
														) &&
															existingWords >
																1 && (
																<br></br>
															)}
														<span className='rw-fw-not-words'>
															{
																answer
															}
														</span>
														{index !==
															0 &&
															index %
																5 ===
																0 && (
																<br></br>
															)}
													</span>
												);
											}
										)}
								</div>
								<br></br>
								{(!this.state.timeRanOut ||
									detectedWord.length ===
										this.state
											.totalPossibleAnswers) && (
									<div className='rw-fw-input-container'>
										<span className='instruciton__highlight-fw'>
											<input
												key={id}
												className={
													defaultClass
												}
												placeholder={
													'skriv her'
												}
												type='text'
												onKeyUp={(
													event
												) => {
													if (
														event.keyCode ===
															13 ||
														event.code ===
															'Enter'
													) {
														this.handleKeyPress(
															event,
															possibilites,
															sessionIndex,
															defaultClass,
															incorrectClass,
															salimWord
														);
													} else {
														this.setState(
															{
																textFieldState:
																	event,
															}
														);
													}
												}}
											/>
										</span>
										<div className='rw-right-arrow-wrapper'>
											<div>
												<button className='rw-arrow-pushable'>
													<span
														className='rw-right-arrow'
														onClick={() => {
															this.handleKeyPress(
																this
																	.state
																	.textFieldState,
																possibilites,
																sessionIndex,
																defaultClass,
																incorrectClass,
																salimWord
															);
														}}
													></span>
												</button>
											</div>
										</div>
									</div>
								)}
							</div>
						);
					})}
					{!isInProduction && this.props.isSuperTeacher && (
						<div className='rw-next'>
							<button
								className='rw-btn bg-primary'
								style={{
									backgroundColor: 'Cyan',
									color: 'black',
								}}
								onClick={() => this.revealAllWords(sessionItems)}
							>
								{'Vis Svar'}
							</button>
						</div>
					)}
				</div>

				<br></br>
				{(this.state.timeRanOut ||
					detectedWord.length === this.state.totalPossibleAnswers ||
					this.props.isSuperTeacher) && (
					<div className='rw-next-btn'>
						<button
							className='rw-btn bg-primary'
							onClick={() =>
								onNextStep(resultsGloabl, ALL_SUBTESTS.FINDWORDS)
							}
						>
							{'Neste'}
						</button>
					</div>
				)}
			</div>
		);
	}
}
export default FindWords;
