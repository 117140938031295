import session1_Audio from '../assets/Audio_session_1.m4a';
import session2_Audio from '../assets/Audio_session_2.m4a';
import session4_Audio from '../assets/Audio_session_4.m4a';
import session5_Audio from '../assets/Audio_session_5.m4a';
import session6_Audio from '../assets/Audio_session_6.m4a';
import session7_Audio from '../assets/Audio_session_7.m4a';
import session8_Audio from '../assets/Audio_session_8.m4a';
import session9_Audio from '../assets/Audio_session_9.m4a';
import session10_Audio from '../assets/Audio_session_10.m4a';
import session11_Audio from '../assets/Audio_session_11.m4a';
import session12_Audio from '../assets/Audio_session_12.m4a';
import session13_Audio from '../assets/Audio_session_13.m4a';
import session14_Audio from '../assets/Audio_session_14.m4a';
import session15_Audio from '../assets/Audio_session_15.m4a';
import session16_Audio from '../assets/Audio_session_16.m4a';
import session17_Audio from '../assets/Audio_session_17.m4a';
import session18_Audio from '../assets/Audio_session_18.m4a';

import session19_Audio from '../assets/Audio_session_19.m4a';
import session20_Audio from '../assets/Audio_session_20.m4a';
import session21_Audio from '../assets/Audio_session_21.m4a';
import session22_Audio from '../assets/Audio_session_22.m4a';
import session23_Audio from '../assets/Audio_session_23.m4a';
import session24_Audio from '../assets/Audio_session_24.m4a';
import session25_Audio from '../assets/Audio_session_25.m4a';
import session26_Audio from '../assets/Audio_session_26.m4a';
import session27_Audio from '../assets/Audio_session_27.m4a';
import session28_Audio from '../assets/Audio_session_28.m4a';
import session29_Audio from '../assets/Audio_session_29.m4a';
import session30_Audio from '../assets/Audio_session_30.m4a';
import session31_Audio from '../assets/Audio_session_31.m4a';
import session32_Audio from '../assets/Audio_session_32.m4a';
import session33_Audio from '../assets/Audio_session_33.m4a';

import session34_Audio from '../assets/Audio_session_34.m4a';
import session35_Audio from '../assets/Audio_session_35.m4a';
import session36_Audio from '../assets/Audio_session_36.m4a';
import session37_Audio from '../assets/Audio_session_37.m4a';
import session38_Audio from '../assets/Audio_session_38.m4a';
import session39_Audio from '../assets/Audio_session_39.m4a';
import session40_Audio from '../assets/Audio_session_40.m4a';
import session41_Audio from '../assets/Audio_session_41.m4a';
import session42_Audio from '../assets/Audio_session_42.m4a';
import session43_Audio from '../assets/Audio_session_43.m4a';
import session44_Audio from '../assets/Audio_session_44.m4a';
import session45_Audio from '../assets/Audio_session_45.m4a';
import session46_Audio from '../assets/Audio_session_46.m4a';
import session47_Audio from '../assets/Audio_session_47.m4a';
import session48_Audio from '../assets/Audio_session_48.m4a';
import session49_Audio from '../assets/Audio_session_49.m4a';
import session50_Audio from '../assets/Audio_session_50.m4a';
import session51_Audio from '../assets/Audio_session_51.m4a';
import session52_Audio from '../assets/Audio_session_52.m4a';
import session53_Audio from '../assets/Audio_session_53.m4a';
import session54_Audio from '../assets/Audio_session_54.m4a';
import session55_Audio from '../assets/Audio_session_55.m4a';
import session56_Audio from '../assets/Audio_session_56.m4a';
import session57_Audio from '../assets/Audio_session_57.m4a';
import session58_Audio from '../assets/Audio_session_58.m4a';
import session59_Audio from '../assets/Audio_session_59.m4a';
import session60_Audio from '../assets/Audio_session_60.m4a';
import session61_Audio from '../assets/Audio_session_61.m4a';
import session62_Audio from '../assets/Audio_session_62.m4a';
import session63_Audio from '../assets/Audio_session_63.m4a';
import session64_Audio from '../assets/Audio_session_64.m4a';
import session65_Audio from '../assets/Audio_session_65.m4a';
import session66_Audio from '../assets/Audio_session_66.m4a';
import session67_Audio from '../assets/Audio_session_67.m4a';
import session68_Audio from '../assets/Audio_session_68.m4a';
import session69_Audio from '../assets/Audio_session_69.m4a';
import session70_Audio from '../assets/Audio_session_70.m4a';
import session71_Audio from '../assets/Audio_session_71.m4a';
import session72_Audio from '../assets/Audio_session_72.m4a';
import session73_Audio from '../assets/Audio_session_73.m4a';
import session74_Audio from '../assets/Audio_session_74.m4a';
import session75_Audio from '../assets/Audio_session_75.m4a';
import session76_Audio from '../assets/Audio_session_76.m4a';

import session77_Audio from '../assets/Audio_session_77.m4a';
import session78_Audio from '../assets/Audio_session_78.m4a';
import session79_Audio from '../assets/Audio_session_79.m4a';
import session80_Audio from '../assets/Audio_session_80.m4a';
import session81_Audio from '../assets/Audio_session_81.m4a';
import session82_Audio from '../assets/Audio_session_82.m4a';
import session83_Audio from '../assets/Audio_session_83.m4a';
import session84_Audio from '../assets/Audio_session_84.m4a';
import session85_Audio from '../assets/Audio_session_85.m4a';
import session86_Audio from '../assets/Audio_session_86.m4a';
import session87_Audio from '../assets/Audio_session_87.m4a';
import session88_Audio from '../assets/Audio_session_88.m4a';
import session89_Audio from '../assets/Audio_session_89.m4a';
import session90_Audio from '../assets/Audio_session_90.m4a';
import session91_Audio from '../assets/Audio_session_91.m4a';
import session92_Audio from '../assets/Audio_session_92.m4a';
import session93_Audio from '../assets/Audio_session_93.m4a';

let accumulator = Math.random() * 10;
export const session1 = [
	{
		introAudio: session1_Audio,
		targetWord: 'U',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'U'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'u'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 14,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'u',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'U',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'U',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'u',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'u',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'U',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'u',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'u',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'u',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'U',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'u',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'U',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session2 = [
	{
		introAudio: session2_Audio,
		targetWord: 'Y',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'y'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Y'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'u',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'U',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'U',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'u',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'y',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'u',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Y',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'U',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'u',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'y',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'u',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'U',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Y',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'u',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'Y',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Y',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session3 = [
	{
		introAudio: session4_Audio,
		targetWord: 'B',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'b'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'B'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 11,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [40, 125, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'b',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'B',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'B',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'u',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'b',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'u',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'B',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'b',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'u',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'b',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'b',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'B',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'b',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'b',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'U',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session4 = [
	{
		introAudio: session4_Audio,
		targetWord: 'D',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'d'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'D'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 604, 634, 650, 570, 550, 500, 470, 370, 250, 270],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'd',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'D',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'D',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'd',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'd',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'D',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'u',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'u',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'd',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'u',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'd',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'd',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'U',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'd',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'u',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'd',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'D',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session5 = [
	{
		introAudio: session5_Audio,
		targetWord: 'F',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'f'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'F'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'f',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'F',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'F',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'f',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'f',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'F',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'u',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'u',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'f',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'u',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'f',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'U',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'u',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'F',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session6 = [
	{
		introAudio: session6_Audio,
		targetWord: 'G',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'g'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'G'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 14,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'g',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'G',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'G',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'g',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'g',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'u',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'g',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'U',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'g',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'g',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'g',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'l',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'g',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'G',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'g',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'g',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'G',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session7 = [
	{
		introAudio: session7_Audio,
		targetWord: 'H',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'h'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'H'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'h',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'H',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'H',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'u',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'h',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'H',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'h',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'q',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'h',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'h',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'H',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'h',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'H',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session8 = [
	{
		introAudio: session8_Audio,
		targetWord: 'J',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'j'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'J'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'j',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'U',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'J',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'j',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'j',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'J',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'J',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'j',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'j',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'J',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'j',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'j',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'J',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session9 = [
	{
		introAudio: session9_Audio,
		targetWord: 'K',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'k'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'K'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 14,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'k',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'K',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'K',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'k',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'k',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'k',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'K',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'k',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'k',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'k',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'K',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'k',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'k',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'K',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session10 = [
	{
		introAudio: session10_Audio,
		targetWord: 'L',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'l'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'L'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'l',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'K',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'L',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'l',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'k',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'l',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'L',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'k',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'l',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'l',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'L',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'k',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'k',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'L',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session11 = [
	{
		introAudio: session11_Audio,
		targetWord: 'M',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'m'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'M'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'm',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'M',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'M',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'm',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'm',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'M',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'm',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'm',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'm',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'M',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'm',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'm',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'M',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session12 = [
	{
		introAudio: session12_Audio,
		targetWord: 'N',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'n'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'N'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'n',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'P',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'n',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'p',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'n',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'P',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'N',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'n',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'p',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'n',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'N',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'n',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'n',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'N',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session13 = [
	{
		introAudio: session13_Audio,
		targetWord: 'P',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'p'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'P'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'p',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'P',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'P',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'p',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'p',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'P',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'p',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'p',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'p',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'P',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'p',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'p',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'P',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session14 = [
	{
		introAudio: session14_Audio,
		targetWord: 'R',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'r'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'R'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'u',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'r',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'R',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'R',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'r',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'r',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'R',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'r',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'u',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'r',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'R',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'r',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'r',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'R',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session15 = [
	{
		introAudio: session15_Audio,
		targetWord: 'S',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'s'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'S'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 14,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 's',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'S',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'S',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 's',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 's',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'S',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 's',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 's',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 's',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'S',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'u',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 's',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'S',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session16 = [
	{
		introAudio: session16_Audio,
		targetWord: 'T',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'t'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'T'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 't',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'T',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'T',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'u',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 's',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'u',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'f',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'T',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 't',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 't',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 't',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'T',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 't',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'T',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session17 = [
	{
		introAudio: session17_Audio,
		targetWord: 'V',
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'v'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'V'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 't',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'r',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'v',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'e',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'V',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'B',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'u',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'u',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'v',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 't',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'u',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'V',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'n',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'B',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'v',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'o',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'b',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'u',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'h',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'b',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'n',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'y',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'b',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'g',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'e',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'e',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'B',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'v',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'o',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'l',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'b',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 's',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'v',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'b',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'U',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session18 = [
	{
		introAudio: session18_Audio,
		targetWord: ['Kn'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Kn'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'KN'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 14,
		flyingWords: [
			{
				id: 1,
				word: 'Kn',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'Sj',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'Gj',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Kn',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Sj',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Hv',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'Kn',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'hv',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'kn',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'Hv',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'ai',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'Sj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'kn',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'hv',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'gJ',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Kn',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Gj',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Hv',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Kn',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Sj',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'HV',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'kn',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'hV',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Sj',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Sj',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'Kn',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'sj',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'ai',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'kn',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'Hv',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Kn',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'HV',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'Sj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'Kn',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'hv',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'Sj',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'Hv',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'KN',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vB',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'HV',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'Sk',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'kn',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session19 = [
	{
		introAudio: session19_Audio,
		targetWord: ['Hv'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Hv'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'HV'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 19,
		flyingWords: [
			{
				id: 1,
				word: 'Hv',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'Sj',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'Gj',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Hv',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Sj',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Hv',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'sj',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'hv',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Gj',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'Hv',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'ai',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'Sj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'GJ',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'hv',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'gJ',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Sj',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Gj',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Hv',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'ai',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Sj',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'HV',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'AU',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'hV',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Sj',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Sj',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'Hv',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'sj',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'ai',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'Hv',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'Hv',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Hv',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'HV',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'Sj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'SG',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'hv',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'Sj',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'Hv',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'HV',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vB',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'HV',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'Sk',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'hv',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session20 = [
	{
		introAudio: session20_Audio,
		targetWord: ['Hj'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Hj'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'HJ'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 11,
		flyingWords: [
			{
				id: 1,
				word: 'Sk',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'Sj',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'Gj',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Ei',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Sj',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Hj',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'sj',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'Hj',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Gj',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'sj',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'gj',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'Sj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'Hj',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'sj',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'gJ',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'hj',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Gj',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'EI',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'ai',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Hj',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'sj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'AU',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Sj',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Sj',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'ai',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'sj',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'Hj',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'Sj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'Hj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Sj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Gj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'Hj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'SG',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'Ei',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'HJ',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'ai',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'Sj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'Hj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'Sk',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Sj',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'Hj',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session21 = [
	{
		introAudio: session21_Audio,
		targetWord: ['Sj'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Sj'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'SJ'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 18,
		flyingWords: [
			{
				id: 1,
				word: 'Sk',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'Sj',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'Gj',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Ei',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Sj',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'el',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'sj',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'av',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Gj',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'sj',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'ai',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'Sj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'GJ',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'sj',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'gJ',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Sj',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Gj',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'EI',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'ai',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Sj',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'sj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'AU',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Sj',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Sj',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'ai',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'sj',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'ai',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'Sj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'ey',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Sj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Gj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'Sj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'SG',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'Ei',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'Sj',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'ai',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'Sj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vB',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'Sk',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Sj',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session22 = [
	{
		introAudio: session22_Audio,
		targetWord: ['Øy', 'Gj'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Øy'}</b>
				</span>
				{'> '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ØY'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Gj'}</b>
				</span>
				{'>'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'GJ'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 18,
		flyingWords: [
			{
				id: 1,
				word: 'Sk',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'Øy',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'Gj',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Ei',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'gJ',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'el',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'SK',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'av',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Øy',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'yv',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'ai',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'Su',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'GJ',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'Øy',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'gJ',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Gj',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Øy',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'ai',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'jG',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'ai',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'AU',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Sk',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Gj',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'Øy',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'uK',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'ai',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'll',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'ey',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Gj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'åu',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'Ei',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sQ',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'ai',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vB',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'Sk',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'ai',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session23 = [
	{
		introAudio: session23_Audio,
		targetWord: ['Sk', 'St'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Sk'}</b>
				</span>
				{'> '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'SK'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'St'}</b>
				</span>
				{'>'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ST'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 16,
		flyingWords: [
			{
				id: 1,
				word: 'Sk',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'ai',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'Sk',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Ei',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Sk',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'el',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'SK',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'av',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'sk',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'yv',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'st',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'Su',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ks',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'ai',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'Sk',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'St',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'EI',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'ai',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'sK',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'St',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'AU',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'ØY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Sk',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'fK',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'st',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'uK',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'ai',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'll',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'Bk',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'ey',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'ST',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Sk',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'åu',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'sQ',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'Ei',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sQ',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'ai',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'Sk',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vB',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'Sk',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'ai',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session24 = [
	{
		introAudio: session24_Audio,
		targetWord: ['Ng'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ng'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'NG'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 15,
		flyingWords: [
			{
				id: 1,
				word: 'Ng',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'ai',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'Ng',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Ng',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Sk',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Ng',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'SK',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'ng',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'sk',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'Ng',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'ai',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'Su',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ks',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'ai',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'Sk',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'NG',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Sk',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'EI',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Ng',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'sK',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'ai',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'Ng',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'ØY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Sk',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'ng',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'ai',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'Ng',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'ai',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'll',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'Bk',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'Ng',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Sk',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'åu',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'Ng',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'sQ',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'Ei',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sQ',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'Ng',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'gn',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'mG',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'Sk',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Ng',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session25 = [
	{
		introAudio: session25_Audio,
		targetWord: ['Ai'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ai'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'AI'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'au',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'ai',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'au',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Ei',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'ai',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'el',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'lo',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'av',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Ux',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'yv',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'ai',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'Su',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ei',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'ai',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'aU',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'ai',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'EI',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'ai',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Øo',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'ai',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'AU',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'ØY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Ei',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'au',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'ai',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'uI',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'ai',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'll',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'ai',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'ey',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'ai',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'åu',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'ls',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'Ei',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sQ',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'ai',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'uY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vB',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'åu',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'ai',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session26 = [
	{
		introAudio: session26_Audio,
		targetWord: ['Au'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Au'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'aU'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'au',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'på',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'au',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Ei',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'au',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'el',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'lo',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'av',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Ux',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'yv',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'au',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'Su',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ei',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'åy',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'aU',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'nØ',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'EI',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Au',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Øo',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Øy',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'AU',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'ØY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Ei',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'au',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yx',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'uI',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'au',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'll',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'eY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'ey',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'el',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'åu',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'ls',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'Ei',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sQ',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'tv',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'uY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vB',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'åu',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'au',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session27 = [
	{
		introAudio: session27_Audio,
		targetWord: ['Ei'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ei'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ei'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 'op',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'på',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'Øy',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Ei',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'ew',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'U',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'v',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'U',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'y',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'EI',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'Su',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ei',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'åy',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'Æy',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'nØ',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'EI',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'yv',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Øo',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Øy',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'ØT',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'ØY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Ei',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Qy',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yx',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'uI',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'Ei',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'll',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'eY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'ey',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'el',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'Øy',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'Ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'ls',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'Ei',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sQ',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'tv',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'uY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vB',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'ob',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Øy',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session28 = [
	{
		introAudio: session28_Audio,
		targetWord: ['Øy'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Øy'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'øY'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 14,
		flyingWords: [
			{
				id: 1,
				word: 'ØY',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'på',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'Øy',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'as',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'ew',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Us',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'Øy',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vv',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Øy',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'øy',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'Øy',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'Su',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'Zs',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'åy',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'Æy',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Øy',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'nØ',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'ØU',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'yv',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Øo',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Øy',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'ØT',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'ØY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Øy',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Qy',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yx',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'Øy',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'gg',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'll',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'eY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'eø',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'vP',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Un',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'Øy',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'oØ',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'ls',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'Øy',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sQ',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'tv',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'uY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vB',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'yØ',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'ob',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Øy',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'vn',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session29 = [
	{
		introAudio: session29_Audio,
		targetWord: ['Jul'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'jul'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'JUL'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'Jul',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'sr',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'up',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Jul',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'ex',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Up',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'ls',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vs',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'wl',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'Jul',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'jUl',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'JUL',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'gul',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'ful',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'ss',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'ff',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Jul',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Uz',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'jul',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'oo',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'lx',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'su',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'ho',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'fi',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'nå',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'på',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'Jul',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'gå',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'sl',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'ez',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'eo',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'kl',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Uq',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'vj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'oe',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'Jul',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'øl',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'æø',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'tp',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'gh',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'bv',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'my',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'mi',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Jul',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'vi',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session30 = [
	{
		introAudio: session30_Audio,
		targetWord: ['Ng'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ng'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'NG'}</b>
				</span>
				{'> '}
			</span>
		),

		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'tt',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'rp',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'uv',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Ng',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'eS',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'UJ',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'mJ',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'Ng',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Uv',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'ey',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'nG',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'uv',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ng',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'ls',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'vw',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Ng',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'tn',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'aU',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'vx',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Ng',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'gN',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'Ng',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'nG',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Ng',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'nn',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yt',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'ux',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'ge',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'll',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'nl',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'ex',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Ng',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'ls',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'bs',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'bc',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'Ng',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'ut',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sq',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'tx',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'ax',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'ox',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Ng',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'oa',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Ng',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session31 = [
	{
		introAudio: session31_Audio,
		targetWord: ['Au', 'Ei'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Au'}</b>
				</span>
				{'> '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'aU'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ei'}</b>
				</span>
				{'>'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'eI'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 14,
		flyingWords: [
			{
				id: 1,
				word: 'tt',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'rr',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'us',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Au',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'uA',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'AU',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'lx',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 've',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'AU',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'xy',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'uf',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'au',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ss',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'et',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'ut',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'fv',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'xn',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'sU',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'av',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'oo',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Al',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'au',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'hl',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Au',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'np',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'ey',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'AU',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'gg',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'lk',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'eI',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'se',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'vs',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'ei',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'vi',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'op',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'Ei',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'du',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'eI',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'Az',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'au',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 've',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'ey',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'EI',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Ui',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'ei',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session32 = [
	{
		introAudio: session32_Audio,
		targetWord: ['Mm', 'Ss'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'nm'}</b>
				</span>
				{'> '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'MM'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ss'}</b>
				</span>
				{'>'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'SS'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 14,
		flyingWords: [
			{
				id: 1,
				word: 'tz',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'rs',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'mm',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'st',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'se',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'ss',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'lb',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vt',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'ss',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'sy',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'mM',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'er',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'SS',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'ys',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: '3S',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'ES',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'ss',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Mm',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'mm',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'oP',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Ml',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'up',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'hp',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'mm',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'ns',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'ss',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'u6',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'gB',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'mm',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'ss',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'eq',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'vs',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Ub',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 've',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'ox',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'lz',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'qu',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'ss',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'tt',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'uu',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vv',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'yy',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o0',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'UU',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'MM',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session33 = [
	{
		introAudio: session33_Audio,
		targetWord: ['Tt', 'Ll'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'tt'}</b>
				</span>
				{'> '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'TT'}</b>
				</span>
				{'> '}
				eller{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ll'}</b>
				</span>
				{'>'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'LL'}</b>
					{'>'}
				</span>
			</span>
		),
		discoverableWords: 18,
		flyingWords: [
			{
				id: 1,
				word: 'tp',
				xTranslate: [75, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, -10, -14, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'tr',
				xTranslate: [75, 75, 50, 50, 200, 300, 400, 370, 350, 300, 224],
				yTranslate: [0, 25, 34, -25, 32, 21, 28, 20, 25, 32, 21],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'uU',
				xTranslate: [75, 225, 350, 50, 100, 200, 400, 500, 600, 700, 150],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'ty',
				xTranslate: [75, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Tt',
				xTranslate: [75, 125, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 155, 134, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'TU',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 175],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'tl',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'LL',
				xTranslate: [75, 125, 150, 225, 200, 265, 300, 276, 270, 200, 225],
				yTranslate: [0, 175, 127, 175, 172, 151, 178, 270, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'pl',
				xTranslate: [75, 325, 250, 250, 300, 340, 400, 500, 542, 700, 600],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 280],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'pt',
				xTranslate: [
					75,
					205 + accumulator,
					290 + accumulator,
					280,
					300,
					400,
					380,
					360,
					300,
					250,
					0,
				],
				yTranslate: [0, 85, 84, 85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'ps',
				xTranslate: [75, 25, 50, 50, 200 + accumulator, 300, 400, 500, 600, 700, 75],
				yTranslate: [0, 55, 54, 55, 52, 51, 58, 50, 55, -52, 50],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'tT',
				xTranslate: [70, 25, 50, 50, 200, 300, 400 + accumulator, 500, 600, 700, 150],
				yTranslate: [0, 155, 154, 155, 152, 151, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'TT',
				xTranslate: [70, 704, 634, 650, 570, 550, 500, 470, 370, 250, 310],
				yTranslate: [0, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'll',
				xTranslate: [70, 225, 250, 150, 200 - accumulator, 300, 400, 500, 500, 500, 300],
				yTranslate: [0, -20, -30, 341, 342, 289, 298, 250, 219, 195, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'Ll',
				xTranslate: [70, 25, 320, 400, 440, 460 - accumulator, 480, 500, 600, 700, 575],
				yTranslate: [0, -28, -14, 25, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'ft',
				xTranslate: [70, 25, 50, 50, 200 - accumulator, 300, 400, 500, 600, 700, 450],
				yTranslate: [0, 225, 164, 165, 192, 209, 140, 120, 100, 110, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'nT',
				xTranslate: [70, 25, 50 + accumulator, 50, 250, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 204, 253, 275, 282, 291, -28, -14, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'UV',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Pv',
				xTranslate: [70, 425, 450 + accumulator, 540, 400, 390, 299, 327, 600, 700, 0],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, 185, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'll',
				xTranslate: [70, 25, 350, 350, 300, 410, 400, 500, 600, 700, 75],
				yTranslate: [0, 165, 114, 185, 155, 91, 118, 95, 125, 92, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'ls',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, 148, 159, 124, 100, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'LL',
				xTranslate: [70, 625, 650, 550, 467, 500, 476, 500, 300, 700, 525],
				yTranslate: [0, 415, 380, 345, 300, 280, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'ho',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 299, 304, 311, 278, 255, 298, 200, 175, 150, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'lL',
				xTranslate: [70, 376, 350, 500, 399, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 265, 271, 245, 327, 190, 238, 290, 225, 190, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'tt',
				xTranslate: [70, 225, 550, 350, 700, 300, 440, 500, 600, 700, 450],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 103, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yl',
				xTranslate: [70, 425, 450, 350, 200, 300, 400, 500, 600, 700, 525],
				yTranslate: [0, 385, 304, 333, 332, 300, 230, 200, 185, 163, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'tl',
				xTranslate: [70, 325, 250, 350, 200, 300, 400, 500, 600, 700, 630],
				yTranslate: [0, 285, 294, 265, 220, 180, 178, 190, 175, 132, 300],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'tt',
				xTranslate: [70, 225, 530, 350, 500, 100, 300, 400, 550, 700, 630],
				yTranslate: [0, 346, 345, 324, 123, 344, 343, 243, 232, 234, 135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'tT',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 675],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'el',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 150],
				yTranslate: [0, 425, 404, 415, 392, 381, 340, 290, 295, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'TT',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 325, 304, 295, 272, 261, 268, 250, 225, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'vb',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 425, 404, 375, 350, 341, 358, 306, 285, 240, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'UV',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 285, 244, 215, 200, 190, 188, 170, 195, 180, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'dm',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 235, 300, 380, 320, 290, 220, 198, 210, 150, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'tt',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'll',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 690, 600],
				yTranslate: [0, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'uS',
				xTranslate: [70, 525, 550, 550, 493, 369, 400, 431, 385, 541, 600],
				yTranslate: [0, 255, 264, 275, 259, 240, 251, 263, 260, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sq',
				xTranslate: [70, 324, 432, 345, 543, 345, 532, 345, 543, 654, 345],
				yTranslate: [0, 345, 232, 234, 142, 242, 124, 321, 213, 231, 222],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'tL',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'LT',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'tt',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'yQ',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'll',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Uh',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'll',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session34 = [
	{
		introAudio: session34_Audio,
		targetWord: ['bl'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'bl'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Bl'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'bt',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'cr',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'g',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'y',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'bl',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'G',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'lb',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'bl',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Gl',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'bl',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'dl',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'al',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'BL',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'tl',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'bl',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'gl',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'ln',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'bL',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'vl',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'bl',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'ls',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'gl',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'hl',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'bl',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'kl',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'bl',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'gl',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'lb',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'bl',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'el',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'et',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'vb',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'B',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'l',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'bl',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'lB',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'ge',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'bl',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 't',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'g',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'bl',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'vl',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session35 = [
	{
		introAudio: session35_Audio,
		targetWord: ['br'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'br'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'BR'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 'tr',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'rb',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'br',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'yr',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'el',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'rb',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'br',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vr',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'GR',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'Br',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'rg',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'bR',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'sr',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'tr',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'ru',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'rB',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'br',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Ur',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'BR',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'or',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'gl',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'og',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'br',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'gk',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'br',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'ar',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'br',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'lR',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'dr',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'dR',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'de',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'be',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'br',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'vt',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'ol',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'br',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'se',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'cd',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'er',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'qb',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'br',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'br',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'vr',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session36 = [
	{
		introAudio: session36_Audio,
		targetWord: ['dr'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'dr'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'DR'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 11,
		flyingWords: [
			{
				id: 1,
				word: 'tr',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'br',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'dr',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'yr',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'ex',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'GR',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'dr',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vr',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'aG',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'dR',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'gv',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'dr',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'sR',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'br',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'dr',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'gr',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Dr',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'db',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'xt',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'op',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Lr',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'dr',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'hj',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'ge',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'nm',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yr',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'dr',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'lr',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'br',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'dr',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'ir',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'rd',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Rd',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'rD',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'dr',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'll',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'gp',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'pr',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'ts',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'gv',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vs',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'yv',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'oe',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'sv',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'dr',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session37 = [
	{
		introAudio: session37_Audio,
		targetWord: ['fl'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'fl'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Fl'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 'tr',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'fl',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'dr',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'fl',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'ex',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'fl',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'dr',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vr',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'aG',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'fL',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'gv',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'fl',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'sR',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'br',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'FL',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'gr',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'FL',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'db',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'fl',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'op',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Lr',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'dr',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'fl',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'ge',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'nm',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yr',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'dr',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'LF',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'br',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'dr',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'lf',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'rd',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Rd',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'fl',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'dr',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'll',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'gp',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'fl',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'ts',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'gv',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vs',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'yv',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'oe',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'sv',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'fl',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session38 = [
	{
		introAudio: session38_Audio,
		targetWord: ['st'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'st'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'St'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 'tr',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'fl',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'dr',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'fl',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'st',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'fl',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'dr',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'st',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'aG',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'fL',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'gv',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'fl',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'sR',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'St',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'FL',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'st',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'FL',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'db',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'st',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'op',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'ST',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'dr',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'fl',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'ge',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'st',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yr',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'dr',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'st',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'br',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'sT',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'lf',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'rd',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'ts',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'fl',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'st',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'll',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'gp',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'fl',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'ts',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'st',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vs',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'yv',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'st',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'sv',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'fl',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session39 = [
	{
		introAudio: session39_Audio,
		targetWord: ['Gi'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'gi'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Gi'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 11,
		flyingWords: [
			{
				id: 1,
				word: 'ti',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'ri',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'gi',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'gy',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'fe',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Gi',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'lg',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vi',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'GI',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'gy',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'gi',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'ge',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'se',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'tg',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'gu',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'gl',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'ni',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Ug',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'vi',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'gi',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'li',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'si',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'hi',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'gI',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'ni',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'gy',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'gi',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'li',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'gi',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'eg',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'eg',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'vi',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Gi',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'vi',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'go',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'lg',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'ig',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'si',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'gi',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'gI',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'iv',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'py',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'ry',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Gi',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session40 = [
	{
		introAudio: session40_Audio,
		targetWord: ['Kj'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'kj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Kj'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 11,
		flyingWords: [
			{
				id: 1,
				word: 'kt',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'rj',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'gj',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'kj',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'ek',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Gj',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'lk',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'kj',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'GT',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'kj',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'jk',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'sk',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'jt',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'uk',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Kj',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'jK',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'uJ',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'vs',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'ks',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'kJ',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'gs',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'he',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'gJ',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'nk',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'kJ',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'Ju',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'Rj',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'kj',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'ej',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'eb',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'vJ',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'KG',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'kj',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'kJ',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'lj',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'gK',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'kJ',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'tk',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'gk',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'KJ',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'o',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'KG',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session41 = [
	{
		introAudio: session41_Audio,
		targetWord: ['El'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'El'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'el'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 'ts',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'el',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'gl',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'yl',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'el',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'gl',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'gl',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vl',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'lG',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'el',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'gl',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'il',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'eL',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'El',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'el',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'el',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'nl',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'sU',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'vl',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'ol',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'el',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'gl',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'El',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'sg',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'nl',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yl',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'sg',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'El',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'eg',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'el',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'et',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'le',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Le',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'lE',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'ol',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'El',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'lg',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'ls',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'El',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'lg',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vc',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'wy',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'oh',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'hl',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'Ek',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session42 = [
	{
		introAudio: session42_Audio,
		targetWord: ['Gj'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'gj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Gj'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'Øj',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'to',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'gj',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'yj',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'åe',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Gj',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'jG',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vj',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'jG',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'yJ',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'Jg',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'Øb',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'jt',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'uj',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Jg',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'sU',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'vJ',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'gJ',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'al',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'jy',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'fh',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'ga',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'nJ',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yJ',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'Gj',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'øk',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'GJ',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'ey',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'Gj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'tJ',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Gj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'vx',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'oP',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'lG',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'tG',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'Gj',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'gJ',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'av',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'og',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Jg',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'Gj',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session43 = [
	{
		introAudio: session43_Audio,
		targetWord: ['Bj'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'bj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Bj'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 8,
		flyingWords: [
			{
				id: 1,
				word: 'tj',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'dj',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'jb',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'bj',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'eJ',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'GJ',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'dJ',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'bJ',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'ak',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'dJ',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'gB',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'ga',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'bJ',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'Bj',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'Sj',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Bg',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'vG',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Bj',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'vS',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'oT',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'bj',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'BJ',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'sh',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'gB',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'jB',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yA',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'Bg',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'By',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'gB',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'Ej',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'EJ',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'bj',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'GJ',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'vT',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'oS',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'Kl',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'gH',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sJ',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'st',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'gA',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vK',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Æy',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'øY',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Gø',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'ØJ',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session44 = [
	{
		introAudio: session44_Audio,
		targetWord: ['Ul'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ul'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ul'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 'tl',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'ar',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'Ål',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Lu',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'eL',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Gl',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'ul',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vl',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'øl',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'Ul',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'lg',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'gl',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'uj',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'ut',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'uL',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'ls',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'in',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'UL',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'kl',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'ol',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Ul',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'bl',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'VL',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'UL',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'nP',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yL',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'UL',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'lU',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'Ul',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'le',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'eU',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'vU',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Gl',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'uL',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'tU',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'lU',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'bU',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'lu',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'ul',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'gL',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'Ul',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'yK',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'UL',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'lG',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session45 = [
	{
		introAudio: session45_Audio,
		targetWord: ['Rø'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'rø'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'RØ'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'øt',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'rØ',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'gå',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'yo',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Ae',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Gå',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'lø',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'Ov',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'RØ',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'yo',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'sg',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'rø',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'st',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'tø',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'kØ',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'ro',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'På',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Ov',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Rø',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'ro',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'ol',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'Og',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'hi',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'eg',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'nÅ',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'Jø',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'Rø',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'ho',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'tø',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'RØ',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'rØ',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'vØ',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Bo',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'LU',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'Rø',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'Øl',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'ge',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sT',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'at',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'gl',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'vE',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'yM',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'oP',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'RØ',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'ØR',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session46 = [
	{
		introAudio: session46_Audio,
		targetWord: ['Sj'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Sj'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'js',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'rS',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'sg',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Sj',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'je',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'SG',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'sJ',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'Js',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Gs',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'æs',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'sj',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'sg',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'sJ',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'St',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'uJ',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'eg',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'sK',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Up',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Sj',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'SJ',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'lJ',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'ås',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'hj',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'gS',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'SJ',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'Js',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'sg',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'Sl',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'sY',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'sj',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'Se',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Sv',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'Gb',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'vJ',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'so',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'tl',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'gæ',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sJ',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'at',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'gY',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'bv',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Sy',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'Sj',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'GJ',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'v',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session47 = [
	{
		introAudio: session47_Audio,
		targetWord: ['ING'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ing'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ING'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'gt',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'gn',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'ing',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'ay',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'ek',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'ei',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'sl',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'wp',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Gh',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'lb',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'ing',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'igN',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'iNg',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'ts',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'uø',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'gæ',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Ing',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Ue',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'ctv',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'kø',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'år',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'InG',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'gh',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'gIN',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'knI',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'Jn',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'Gn',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'lt',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'iNg',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'InG',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'es',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'dv',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'nm',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'må',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'op',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'sl',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'ing',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'sG',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'gm',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'Mg',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'Ing',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'gy',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'iNg',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Gni',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'vR',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session48 = [
	{
		introAudio: session48_Audio,
		targetWord: ['SK'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sk'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Sk'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'tk',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'rB',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'ag',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'yS',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'sk',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'sG',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'lK',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vT',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'ks',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'kS',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'Sk',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'gb',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'sR',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'sK',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'uv',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'gK',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'sJ',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'eT',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'vI',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Sk',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'lb',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'gA',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'sk',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'gD',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Sk',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yA',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'gP',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'lS',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'gm',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'eS',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'Ks',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Sk',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'GA',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'vM',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'sk',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'Al',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'gå',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'To',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'på',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'gS',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'Sk',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Ay',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'oP',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'Sk',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'kS',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session49 = [
	{
		introAudio: session49_Audio,
		targetWord: ['Sj'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sj'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'SJ'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 8,
		flyingWords: [
			{
				id: 1,
				word: 'Aj',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'rj',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'Js',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'sK',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'sj',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'sG',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'aK',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vT',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'ks',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'Sj',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'Sk',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'gå',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'sR',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'oK',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'sj',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Sk',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'sJ',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'eT',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'vI',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Sj',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'lb',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'gA',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'st',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'bD',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Sk',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yA',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'må',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'nå',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'gm',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'eS',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'Ku',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Sa',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'GA',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'vM',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'sj',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'Al',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'gå',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'på',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'tå',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'gS',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'Sj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'Ay',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'oP',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'SJ',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'kS',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session50 = [
	{
		introAudio: session50_Audio,
		targetWord: ['St'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'st'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ST'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'Aj',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'rT',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'st',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'aK',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'St',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'sG',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'aK',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'sT',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'ks',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'Sb',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'Sk',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'gå',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'Rs',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'oK',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'sT',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Sk',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'sJ',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'eT',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'vI',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Så',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'æs',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'gA',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'st',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'bD',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Sk',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yA',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'må',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'kø',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'gt',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'eS',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'Kt',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'ST',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'GA',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'vM',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'st',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'Al',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'gå',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'på',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'ts',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'Ts',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'Sj',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'st',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'oP',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'ST',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'kS',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session51 = [
	{
		introAudio: session51_Audio,
		targetWord: ['J'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'j'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'J'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'A',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'ya',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'st',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'aK',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'j',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'ba',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'ia',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'j',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'ks',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'j',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'h',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'gå',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'Y',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'j',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'sT',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'kY',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'J',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'y',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'j',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Så',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'y',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'g',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'st',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'j',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'j',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'yA',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'm',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'kø',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'g',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'eS',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'Ku',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'ST',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'J',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'vM',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'st',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'Al',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'p',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'å',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'ts',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 's',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'j',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'si',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'P',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'T',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'k',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session52 = [
	{
		introAudio: session52_Audio,
		targetWord: ['IST'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ist'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ist'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'AsT',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'ism',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'ist',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'lig',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'jit',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'ist',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'het',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'lse',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Ist',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 10,
				word: 'ått',
				xTranslate: [75, 25, 50, 50, 100, 140, 190, 200, 300, 250, 0],
				yTranslate: [0, 85, 84, -85, 82, 81, 88, 80, 85, 82, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'len',
				xTranslate: [175, 125, 50, 50, 200, 300, 311, 298, 321, 298, 75],
				yTranslate: [65, 55, 54, 55, -52, 51, 58, 50, 55, -52, 150],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 12,
				word: 'isk',
				xTranslate: [70, 25, 50, 50, 200, 300, 245, 321, 253, 342, 321],
				yTranslate: [110, 155, 154, 155, -122, 100, 158, 150, 155, 152, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ist',
				xTranslate: [70, 143, 200, 231, 321, 231, 132, 231, 234, 231, 278],
				yTranslate: [30, 325, 344, 305, 312, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'lig',
				xTranslate: [170, 225, 250, 150, 200, 300, 100, 124, 112, 243, 0],
				yTranslate: [80, -20, -30, 341, 342, 289, 298, 250, 219, 195, 267],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'isT',
				xTranslate: [70, 125, 120, 210, 240, 222, 280, 300, 246, 264, 275],
				yTranslate: [300, -28, -14, -65, -26, 271, 199, 170, 165, 182, 250],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'seg',
				xTranslate: [70, 25, 50, 50, -100, 240, 210, 257, 233, 275, 250],
				yTranslate: [-100, -225, 24, 165, 192, 209, 140, 100, 60, -110, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Joe',
				xTranslate: [70, 25, 50, 50, 250, 252, 177, 300, 286, 200, 225],
				yTranslate: [0, -204, -253, -275, -282, -291, -218, -214, 25, -26, 182, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'ist',
				xTranslate: [70, 196 - accumulator, 250, 350, 315, 246, 245, 490, 520, 500, 529],
				yTranslate: [0, 225, 324, 325, 332, 339, 328, 302, 275, 232, 20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'esk',
				xTranslate: [70, 125, 150 + accumulator, 140, 200, 290, 299, 227, 321, 124, 142],
				yTranslate: [0, 275, 284, 259, 212, -28, -14, 25, -26, -85, 162, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'bar',
				xTranslate: [70, 25, 12, 23, 34, 111, 134, 153, 167, 143, 175],
				yTranslate: [0, 165, 114, 185, 155, 91, 18, -55, -75, 2, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'ist',
				xTranslate: [70, 25, 50, 50, 200, 300, 280, 222, 177, 195, 250],
				yTranslate: [0, 345, 304, -28, -14, 25, -26, -48, -59, -74, -100, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'get',
				xTranslate: [70, 0, 50, 10, 167, 200, 276, 300, 300, 234, 225],
				yTranslate: [0, 215, 280, 145, 100, 180, 200, 219, 178, 152, 159],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'ist',
				xTranslate: [70, 25, 50, 50, 200, 211, 231, 143, 154, 321, 300],
				yTranslate: [100, 152, 211, 132, 53, 23, -50, -100, -175, -67, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'tig',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'isk',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 26,
				word: 'sit',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 27,
				word: 'ist',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 28,
				word: 'kam',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 29,
				word: 'gis',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 30,
				word: 'est',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 31,
				word: 'ist',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 32,
				word: 'Sti',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 33,
				word: 'ist',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 34,
				word: 'bar',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 35,
				word: 'sti',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 36,
				word: 'ist',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 37,
				word: 'pen',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 38,
				word: 'okt',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 39,
				word: 'tsi',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 40,
				word: 'ist',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 225],
				yTranslate: [0, 275, 264, 285, 290, 310, 288, 290, 275, 260, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 41,
				word: 'log',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 300],
				yTranslate: [0, 235, 284, 265, 275, 281, 268, 260, 275, 290, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 42,
				word: 'sib',
				xTranslate: [70, 25, 50, 50, 200, 300, 400, 500, 600, 700, 375],
				yTranslate: [0, 325, 335, 300, 281, 300, 288, 260, 295, 243, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 43,
				word: 'ist',
				xTranslate: [70, 225, 250, 150, 200, 300, 432, 453, 451, 432, 43],
				yTranslate: [0, 280, 284, 275, 263, 370, 240, 210, 255, 208, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 44,
				word: 'lam',
				xTranslate: [70, 167, 198, 250, 200, 325, 235, 324, 532, 324, 65],
				yTranslate: [0, 335, 324, 250, 245, 241, 260, 278, 245, 251, 290],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 45,
				word: 'its',
				xTranslate: [70, 25, 50, 50, 200, 300, 221, 432, 234, 324, 0],
				yTranslate: [0, 135, 224, 435, 322, 311, 298, 260, 330, 300, 350],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session53 = [
	{
		introAudio: session53_Audio,
		targetWord: ['U'],
		instructionText: (
			<span>
				<span>
					Trykk på {' <'}
					<span style={{ color: 'Lime', fontSize: '24px' }}>
						<b>{'u'}</b>
					</span>
					{'> eller '}
					{' <'}
					<span style={{ color: 'Lime', fontSize: '24px' }}>
						<b>{'U'}</b>
					</span>
					{'> '}
				</span>
			</span>
		),
		discoverableWords: 7,
		flyingWords: [
			{
				id: 1,
				word: 'U',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'i',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 's',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'l',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'u',
				xTranslate: [75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 't',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'l',
				xTranslate: [75, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'e',
				xTranslate: [75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'u',
				xTranslate: [75, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'g',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'f',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'i',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 't',
				xTranslate: [110, 87, 32, 764, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'm',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'g',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'ø',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'P',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'å',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'u',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'u',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'i',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'b',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'u',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'f',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'u',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
export const session54 = [
	{
		introAudio: session54_Audio,
		targetWord: ['ing'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ing'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ing'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 8,
		flyingWords: [
			{
				id: 1,
				word: 'ing',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'and',
				xTranslate: [75, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'ing',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'mer',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [0, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'ing',
				xTranslate: [-75, 125, 50, 50, 200, 276, 328, 248, 328, 321, 234],
				yTranslate: [0, 155, 234, 125, 142, 131, 128, 100, -55, -22, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'ing',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'alt',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'lig',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'sikker',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [0, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'pliktig',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'fisk',
				xTranslate: [70, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [0, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'ing',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'isbjørn',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, 133, 100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'kam',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'gis',
				xTranslate: [70, 25, 50, 50, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, 235, 432, 324, 214, 236, 432, 235, 343, 212, 321],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'ing',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'ist',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'stå',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, 275, 250, 141, 258, 206, 185, 140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'uttale',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'uheldig',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'sti',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'ing',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 343, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'usann',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'okt',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'ing',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 654],
				yTranslate: [0, 278, 284, 265, 290, 269, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session55 = [
	{
		introAudio: session55_Audio,
		targetWord: ['opp'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'opp'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Opp'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 6,
		flyingWords: [
			{
				id: 1,
				word: 'uppsala',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'opp',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'nytt',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'opp',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'age',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'lys',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'Opp',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'len',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'kam',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'skjerm',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'lukt',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'duft',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ski',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'kam',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'mellom',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Opp',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'iskrem',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'bare',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'søk',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'opp',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'sti',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'betal',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'sann',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'okt',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'opp',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session56 = [
	{
		introAudio: session56_Audio,
		targetWord: ['inn'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'inn'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Inn'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 7,
		flyingWords: [
			{
				id: 1,
				word: 'uppsala',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'inn',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'nytt',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'inn',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'drage',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'lys',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'leve',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'len',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Inn',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'skjerm',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'lukt',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'duft',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ski',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'kam',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'inn',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'gang',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'inn',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'bare',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'søk',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'oppdra',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'sti',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'inn',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'usann',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'okt',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'inn',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session57 = [
	{
		introAudio: session57_Audio,
		targetWord: ['else'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'else'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Else'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 11,
		flyingWords: [
			{
				id: 1,
				word: 'lese',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'følelse',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'pels',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'mat',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'ned',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'opplevelse',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'leve',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'dyre',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'else',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'høy',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'skuffelse',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'else',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'vesle',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'else',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'Else',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'kopi',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'eldre',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'else',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'søke',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Else',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'anslå',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'else',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'usann',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'okt',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'else',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session58 = [
	{
		introAudio: session58_Audio,
		targetWord: ['ut'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ut'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ut'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 11,
		flyingWords: [
			{
				id: 1,
				word: 'opp',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'ut',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'lam',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'mat',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'utgang',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'uke',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'ut',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'du',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'tau',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'Ut',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'på',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'ut',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ord',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'ut',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'kopp',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'uttale',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'ut',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'ule',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Ut',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Else',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'utføre',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'syk',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'usann',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'okt',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'ut',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session59 = [
	{
		introAudio: session59_Audio,
		targetWord: ['be'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'be'}</b>
				</span>
				{'> eller '}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Be'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'ri',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'se',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'be',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'ba',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Be',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'ku',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'ti',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'be',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'ørn',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'betyr',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'på',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'be',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'si',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'Be',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'opp',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'ta',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'be',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'pil',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'mest',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'be',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'la',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'bestemt',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'ull',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'lam',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Be',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session60 = [
	{
		introAudio: session60_Audio,
		targetWord: ['sjon'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sjon'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sjon'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'sjon',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'repetisjon',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'byttelse',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'ston',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'stopp',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'stasjon',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'ti',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'be',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'infeksjon',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'betyr',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'på',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'informasjon',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'sjon',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'Be',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'konfirmasjon',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'ta',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'be',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'sjon',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'mest',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'be',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'kjøkken',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'kjempe',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'sjon',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'lam',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Be',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session61 = [
	{
		introAudio: session61_Audio,
		targetWord: ['lig'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'lig'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'lig'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'lig',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'tillegg',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'alvorlig',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'le',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'lys',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'billig',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'lite',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'lydelse',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'ligner',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'lig',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'på',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'brukelig',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'sjon',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'Bevege',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'deilig',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'fortelling',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'be',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'farlig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'mest',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'lig',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'kjøkken',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'årlig',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'sjon',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'lam',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Be',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session62 = [
	{
		introAudio: session62_Audio,
		targetWord: ['an'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'an'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'an'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'mann',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'kan',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'anbefale',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'angripe',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'sann',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'får',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'an',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'ankomme',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'par',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'elg',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'på',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'anbefale',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'nå',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'an',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'stil',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'fargen',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'an',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'farlig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'mest',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'lig',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'anmelde',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'årlig',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'an',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'lam',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Be',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session63 = [
	{
		introAudio: session63_Audio,
		targetWord: ['etter'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'etter'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'etter'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'retter',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'etter',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'etternavn',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'leker',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'mett',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'etter',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'lett',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'ettertanke',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'leser',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'elger',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'mynter',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'ettermiddag',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'nøtter',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'andre',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'etter',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'lam',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'fetter',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'farlig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'hetter',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'etter',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'anmelde',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'etterslag',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'an',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'etter',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Be',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session64 = [
	{
		introAudio: session64_Audio,
		targetWord: ['ut'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ut'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ut'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'ut',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'opp',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'utstyr',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'uttale',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'lek',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'ut',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'lett',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'Utføre',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'hente',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'elger',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'mynter',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'utsette',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'møte',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'andre',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'eller',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'lam',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'snut',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'ut',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'hetter',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'etter',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'arlig',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'utdanne',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'svak',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'ut',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Be',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session65 = [
	{
		introAudio: session65_Audio,
		targetWord: ['for'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'for'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'for'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'for',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'forlate',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'forvente',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'får',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'fag',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'fint',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'fire',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'forbilde',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'For',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'fint',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'mynter',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'frem',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'Forklare',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'fant',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'for',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'lam',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'snut',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'utføre',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Fornøyd',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'etter',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'arlig',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'for',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'svak',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'forutsi',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Be',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session66 = [
	{
		introAudio: session66_Audio,
		targetWord: ['ig'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ig'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ig'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'gi',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'ig',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'gå',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'te',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'pal',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'fri',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'ig',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'gir',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'nyttig',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'fint',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'sier',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'veldig',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'Forklare',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'leselig',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'for',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'modig',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'snut',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'ig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'fattig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'etter',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'farlig',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'for',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'svak',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'forskjellig',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Be',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session67 = [
	{
		introAudio: session67_Audio,
		targetWord: ['under'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'under'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'under'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'undersøke',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'underholde',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'undervise',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'undertøy',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'pal',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'fri',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'rund',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'and',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'nyttig',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'fint',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'sier',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'under',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'Forklare',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'leselig',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'for',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'modig',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'snut',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'ig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'fattig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'under',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'under',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'under',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'kunder',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Under',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'runder',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session68 = [
	{
		introAudio: session68_Audio,
		targetWord: ['til'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Til'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'til'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'til',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'Til',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'te',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Tiltalende',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'lit',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'tør',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'tilhøre',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'til',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'nyttig',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'tilstå',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'bytt',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'under',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'tilhøre',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'tid',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'for',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'tilbake',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'snut',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'ig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'fattig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'under',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'tilstå',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'under',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'Ørn',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'kanin',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'rabat',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session69 = [
	{
		introAudio: session69_Audio,
		targetWord: ['av'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'av'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Av'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'av',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'år',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'rå',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'avstå',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'var',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'bra',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'Avfall',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vask',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'lyst',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'avtale',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'av',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'bare',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'avgjøre',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'Styrke',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'for',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'avstå',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'våt',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'av',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'fattig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Avtale',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'tilstå',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'ekte',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'av',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Utrygt',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'ørn',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session70 = [
	{
		introAudio: session70_Audio,
		targetWord: ['gjen'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Gjen'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'gjen'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'ingen',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'gjen',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'gen',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Gjen',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'var',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'gjenta',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'gjengen',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'gaver',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'gjenåpne',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'avgift',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'Gjenstår',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'begge',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'gjen',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'spar',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'gjenskape',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'etter',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'gebyr',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Gjen',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'fattig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Avtale',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'tilstå',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'gjennom',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'av',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Utrygt',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'ørn',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session71 = [
	{
		introAudio: session71_Audio,
		targetWord: ['ende'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Ende'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ende'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'endelig',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'Ende',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'and',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'endring',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'ende',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'etter',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'spisende',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'egg',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'spennende',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'ringe',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'sovende',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'hensyn',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'Ende',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'spar',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'Opptre',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'etter',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'hår',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'hente',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Ende',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Avtale',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'ende',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'gjennom',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'dra',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Lignende',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'kanskje',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session72 = [
	{
		introAudio: session72_Audio,
		targetWord: ['over'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'over'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'over'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'over',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'vår',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'dem',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'flere',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'fly',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'overtale',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'noen',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'frakt',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'overspise',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'ringe',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'Overstått',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'mindre',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'overfalle',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'delta',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'spre',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'over',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'hår',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'overtake',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'tekst',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'over',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'ende',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'Pilot',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'dra',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'over',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'kjøre',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session73 = [
	{
		introAudio: session73_Audio,
		targetWord: ['skap'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'skap'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'skap'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'skatt',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'vitenskap',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'skap',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'slem',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'bak',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'vennskap',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'modig',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'pen',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Flyselskap',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'rask',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'Over',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'bil',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'landskap',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'kamp',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'ekteskap',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'vinger',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'skap',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Sivil',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Skap',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'ren',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'bak',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'skap',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'fort',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'skap',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'pilot',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session74 = [
	{
		introAudio: session74_Audio,
		targetWord: ['bar'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'bar'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'bar'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 9,
		flyingWords: [
			{
				id: 1,
				word: 'bar',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'Bar',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'bil',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'bytt',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'bak',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Brukbar',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'båt',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'kostbar',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'blir',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'vaskbar',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'be',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'bil',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'dyrebar',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'betyr',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'dyrebar',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'bruke',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'snø',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Sivil',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'mange',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Bar',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'rask',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'skap',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'hjelm',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'bar',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'trygt',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session75 = [
	{
		introAudio: session75_Audio,
		targetWord: ['dom'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'dom'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'dom'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'dom',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'Dom',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'rom',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'kom',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'pil',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'barnedom',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'våt',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'ungdom',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'domme',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'sykdom',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'dag',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'dom',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'fattigdom',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'bevege',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'dom',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'bruke',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'sol',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Sivil',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'fridom',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'plast',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'rask',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'hekk',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'hjelm',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'dom',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'dekk',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session76 = [
	{
		introAudio: session76_Audio,
		targetWord: ['som'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'som'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'som'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 'som',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'kom',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'rom',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Som',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Kabin',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'hjelpsom',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'Trommel',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'Arbeidsom',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Ulike',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'morsom',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'dag',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'som',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'vis',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'som',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'tak',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'som',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'gulv',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'smittsom',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'fire',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'som',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Som',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'hekk',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'alene',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'som',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'tank',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session77 = [
	{
		introAudio: session77_Audio,
		targetWord: ['på'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'på'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'på'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 'på',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'ape',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'på',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'På',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'kopp',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'påstand',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'noen',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'påbudt',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'spise',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'på',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'par',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'pårørende',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'rik',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'Pågår',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'spre',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'på',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'hår',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'på',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'tekst',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'på',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Består',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'Ansvar',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'dra',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'på',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'kjøre',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session78 = [
	{
		introAudio: session78_Audio,
		targetWord: ['løs'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'løs'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'løs'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 'løs',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'Løs',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'la',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'barnløs',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'på',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'plass',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'løs',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'mange',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'Arbeidsløs',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'Eldre',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'løs',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'ansvarsløs',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'rik',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'løs',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'spre',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'Omtrent',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'løs',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Funksjon',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'tekst',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'foreldreløs',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Består',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'løs',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'dra',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'løs',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'kjøre',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session79 = [
	{
		introAudio: session79_Audio,
		targetWord: ['het'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'het'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'het'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 12,
		flyingWords: [
			{
				id: 1,
				word: 'het',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'har',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'Het',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Typer',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Tilgjengelig',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'sikkerhet',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'skolen',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'het',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'frakte',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'dumhet',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'buss',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'het',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'last',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'hastighet',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'henter',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'het',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'løs',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'Kjærlighet',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'slipper',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'het',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Består',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'het',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'dra',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'het',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'kjøre',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session80 = [
	{
		introAudio: session80_Audio,
		targetWord: ['aktig'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'aktig'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'aktig'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'aktig',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'traktor',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'aktig',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Høste',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'aktig',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'eventyraktig',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'skolen',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'het',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'aktig',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'Grønnaktig',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'Avlinger',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'aktig',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'last',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'glassaktig',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'henter',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'aktig',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'løs',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'gnieraktig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Produktiv',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'aktig',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Består',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'aktig',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'dra',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'aktig',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'små',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session81 = [
	{
		introAudio: session81_Audio,
		targetWord: ['mis'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'mis'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'mis'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'mis',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'traktor',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'mis',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Høste',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'mis',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Mislike',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'skolen',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'het',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'mis',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'misforstå',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'Avtale',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'Mis',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'last',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'mistanke',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'henter',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'mis',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'rom',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'misfornøyd',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Offentlig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'mis',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'holde',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'mis',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'Oppreist',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'mis',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'små',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session82 = [
	{
		introAudio: session82_Audio,
		targetWord: ['ning'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ning'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'ning'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'ning',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'traktor',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'ning',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Høste',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'ning',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Retning',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'skolen',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'het',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'ning',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'betydning',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'Avtale',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'ning',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'last',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'åpning',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'Belte',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'ning',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'rom',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'undervisning',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'Offentlig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'ning',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Endre',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'ning',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'Snørik',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'ning',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'små',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session83 = [
	{
		introAudio: session83_Audio,
		targetWord: ['sam'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sam'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sam'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'sam',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'brus',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'sam',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'pølse',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'sam',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'samtidig',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'is',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'het',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'sam',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'Samarbeide',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'tog',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'sam',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ofte',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'samfunn',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'leker',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'sam',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'synger',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'samtale',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'pynte',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'sam',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'skoler',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'sam',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'heiser',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Sam',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'små',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session84 = [
	{
		introAudio: session84_Audio,
		targetWord: ['mot'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'mot'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'mot'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'mot',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'styrt',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'mot',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'mat',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Mot',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Motsi',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'is',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'nye',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'mot',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'motsatt',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'tog',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'mot',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'ofte',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'motstand',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'leker',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'mot',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'endring',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'motpart',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'forent',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'mot',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'Sverige',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'mot',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'Oslo',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'Mot',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'selv',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session85 = [
	{
		introAudio: session85_Audio,
		targetWord: ['bi'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'bi'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'bi'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'bi',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'feber',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'bi',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'mat',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'Bi',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Bivirkning',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'is',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'vinter',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'bi',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'bijobb',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'lilla',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'bi',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'vokser',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'biinntekt',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'leker',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'bi',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'såing',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'bismak',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'gul',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'Bi',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'hestehov',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'bi',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'te',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'bi',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'be',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session86 = [
	{
		introAudio: session86_Audio,
		targetWord: ['rik'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'rik'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'rik'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'rik',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'redde',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'rik',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'mat',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'rik',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Fargerik',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'is',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'mars',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'rik',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'idèrik',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'humle',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'rik',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'vokser',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'lærerik',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'sted',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'rik',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'forlatt',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'næringsrik',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'tidlig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'rik',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'energi',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'rik',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'te',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'rik',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'ris',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session87 = [
	{
		introAudio: session87_Audio,
		targetWord: ['full'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'full'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'full'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 14,
		flyingWords: [
			{
				id: 1,
				word: 'full',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'redde',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'full',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'mat',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'full',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'smakfull',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'is',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'mars',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'full',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'talentfull',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'verdifull',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'full',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'vokser',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'full',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'frakte',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'full',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'insekt',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'skamfull',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'hver',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'full',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'bor',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'full',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'frø',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'full',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'pollen',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session88 = [
	{
		introAudio: session88_Audio,
		targetWord: ['vel'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'vel'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Vel'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'vel',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'naturen',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'vel',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'mat',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'vel',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Velbrukt',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'is',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'mars',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'havet',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'velutstyrt',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'Velutstyrt',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'vel',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'elever',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'vel',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'Kjølig',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'vel',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Bismak',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'velutstyrt',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'grunn',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'vel',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'plukket',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'vel',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'jobb',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'vel',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'dyr',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session89 = [
	{
		introAudio: session89_Audio,
		targetWord: ['sel'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sel'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'sel'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'sel',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'naturen',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'sel',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'mat',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'sel',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'fengsel',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'is',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'skitten',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'havet',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'diesel',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'hørsel',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'sel',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'fange',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'sel',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'røyk',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'sel',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Bismak',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'deksel',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'luften',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'sel',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'sørger',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'sel',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'vulkan',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'sel',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Avfall',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session90 = [
	{
		introAudio: session90_Audio,
		targetWord: ['unn'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'unn'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Unn'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'unn',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'naturen',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'unn',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'mat',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'unn',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Unntak',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'is',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'skitten',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'havet',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'unnskyldning',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'unndra',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'unn',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'nepe',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'unn',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'tannhval',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'unn',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'Ekko',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'unngjelde',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'grupper',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'unn',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'fisk',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'Unn',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'liten',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'unn',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'nisen',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session91 = [
	{
		introAudio: session91_Audio,
		targetWord: ['eri'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'eri'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'eri'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 13,
		flyingWords: [
			{
				id: 1,
				word: 'eri',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'pattedyr',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'eri',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'holde',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'eri',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'Bakeri',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'jakte',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'skitten',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'rive',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'maleri',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'bedrageri',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'eri',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'drepe',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'eri',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'svelge',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'eri',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'sterk',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'raseri',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'hval',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'eri',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'gripe',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'eri',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'enorm',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'eri',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'puste',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session92 = [
	{
		introAudio: session92_Audio,
		targetWord: ['til'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'Til'}</b>
				</span>
				{'> eller de som begynner på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'til'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'til',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'Til',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'te',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Tiltalende',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'lit',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'tør',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'tilhøre',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'til',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'nyttig',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'tilstå',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'bytt',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'under',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'tilhøre',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'tid',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'for',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'tilbake',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'snut',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'tilhenger',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'fattig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'under',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'tilstå',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'under',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'Ørn',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'kanin',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'Avfall',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];

export const session93 = [
	{
		introAudio: session93_Audio,
		targetWord: ['løse'],
		instructionText: (
			<span>
				Trykk på {' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'løse'}</b>
				</span>
				{'> eller de som slutter på'}
				{' <'}
				<span style={{ color: 'Lime', fontSize: '24px' }}>
					<b>{'løse'}</b>
				</span>
				{'> '}
			</span>
		),
		discoverableWords: 10,
		flyingWords: [
			{
				id: 1,
				word: 'utløse',
				xTranslate: [215, 304, 334, 250, 370, 250, 300, 270, 370, 250, 10],
				yTranslate: [0, -10, -113, 105, 212, 251, 268, 210, 185, 162, 50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 2,
				word: 'utføre',
				xTranslate: [-145, -125, -150, 25, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 3,
				word: 'løse',
				xTranslate: [175, 225, 350, 50, 100, 200, 300, 240, 310, 200, 280],
				yTranslate: [0, 115, 144, 125, 49, 51, 48, 40, 35, -12, -20],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 4,
				word: 'Tiltalende',
				xTranslate: [75, 25, 50, 50, 160, 130, 300, 130, 200, 310, 25],
				yTranslate: [250, 165, 174, 169, 182, 131, 118, 180, 165, 182, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 5,
				word: 'lit',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 6,
				word: 'løse',
				xTranslate: [75, 105, 100, 150, 100, 100, 170, 180, 100, 190, 75],
				yTranslate: [0, 245, 244, 145, 142, 241, 148, 240, 145, 242, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 7,
				word: 'tilhøre',
				xTranslate: [135, 150, 50, 50, 40, 50, 100, 120, 60, 70, 50],
				yTranslate: [0, 275, 274, 275, 172, 271, 178, 270, 175, 272, -60],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 8,
				word: 'til',
				xTranslate: [-75, 125, 150, 125, 100, 65, 300, 276, 270, 200, 125],
				yTranslate: [-200, -175, -27, 105, 172, 51, -78, 70, 175, 272, 210],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 9,
				word: 'løse',
				xTranslate: [295, 325, 250, 250, 300, 340, 274, 232, 321, 234, 121],
				yTranslate: [200, 275, 274, 205, 172, 171, 178, 270, 375, 267, 0],
				isCorrect: false,
				isAnswerd: false,
			},

			{
				id: 10,
				word: 'tilstå',
				xTranslate: [170, 176, 250, 200, 199, 240, 241, 174, 124, 187, 145],
				yTranslate: [210, 265, 271, 245, 100, 169, 138, 38, -125, -45, 100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 11,
				word: 'løse',
				xTranslate: [240, 225, 250, 254, 310, 267, 254, 201, 159, 132, 107],
				yTranslate: [290, 275, 294, 275, 242, 210, 178, 150, 95, 50, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 12,
				word: 'under',
				xTranslate: [70, 87, 132, 214, 264, 243, 200, 175, 200, 220, 75],
				yTranslate: [0, 385, 200, 167, 133, 100, 54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 13,
				word: 'løse',
				xTranslate: [110, 87, 32, 364, 64, 43, 100, 75, 100, 184, 175],
				yTranslate: [110, 285, 222, 167, -133, -100, -54, -10, -30, -63, -100],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 14,
				word: 'tid',
				xTranslate: [70, 116, 164, 222, 214, 253, 211, 186, 86, 43, 75],
				yTranslate: [221, 346, 345, 374, 323, 76, 43, 43, -32, -100, -135],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 15,
				word: 'for',
				xTranslate: [290, 225, 250, 250, 200, 300, 200, 240, 321, 234, 243],
				yTranslate: [0, -325, -244, -195, -132, -61, 236, 432, 235, 343, 212],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 16,
				word: 'løse',
				xTranslate: [111, 25, 50, 50, 153, 231, 254, 198, 214, 234, 321],
				yTranslate: [350, 425, 404, 415, 392, 381, 340, 290, 395, 200, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 17,
				word: 'snut',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -325, -244, -195, -132, -61, -8, 30, 125, 160, 190],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 18,
				word: 'planløse',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, 125, 204, -275, -250, -141, 58, 206, 185, -140, 0],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 19,
				word: 'fattig',
				xTranslate: [75, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [0, -285, -244, -215, -200, -190, -88, -70, -5, 18, -50],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 20,
				word: 'under',
				xTranslate: [75, 164, 330, 250, 330, 210, 324, 231, 315, 321, 331],
				yTranslate: [0, -35, -60, -40, -180, -190, -220, -98, -10, 50, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 21,
				word: 'avløse',
				xTranslate: [277, 250, 330, 250, 330, 210, 324, 231, 325, 321, 331],
				yTranslate: [221, 295, 304, 285, 260, 200, 208, 190, 185, 160, 150],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 22,
				word: 'under',
				xTranslate: [100, 25, 50, 50, 200, 300, 400, 500, 600, 690, 321],
				yTranslate: [290, 215, 224, 275, 310, 310, 233, 278, 245, 249, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 23,
				word: 'Ørn',
				xTranslate: [210, 225, 250, 264, 293, 269, 300, 271, 285, 321, 300],
				yTranslate: [43, 255, 264, 175, 109, 0, -151, -63, 60, 255, 200],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 24,
				word: 'kanin',
				xTranslate: [170, 224, 262, 305, 298, 305, 312, 300, 300, 300, 245],
				yTranslate: [321, 345, 232, 234, 142, 98, -24, -100, -85, 31, 62],
				isCorrect: false,
				isAnswerd: false,
			},
			{
				id: 25,
				word: 'hvileløse',
				xTranslate: [70, 432, 134, 543, 432, 432, 321, 234, 346, 456, 554],
				yTranslate: [0, 278, 284, 365, 290, 169, 288, 270, 265, 240, 200],
				isCorrect: false,
				isAnswerd: false,
			},
		],
	},
];
