import React from 'react';
import './styles.scss';
import * as data from './data/no-data';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
import AudioPlayer from '../../../AudioPlayer';
import introAudio from '../../../../assets/sounds/wordChain_audio.m4a';
import classNames from 'classnames';
import TimerCount from '../../../TimerCount';
import { updateObject } from 'utils/reducerUtils';
const getAllSessionItem = (session) => {
	const indexValue = `session${session}`;
	const sessionItems = data[indexValue];
	return sessionItems;
};
const whiteSpaceRegex = /\s+/g;
const stripWhiteSpaces = (str) => str.replace(whiteSpaceRegex, '');
const removeDuplicateWhiteSpaces = (str) => str.replace(whiteSpaceRegex, ' ');
const getNumberofWhiteSpaces = (str) => {
	const matches = str.match(whiteSpaceRegex);
	if (!matches) return 0;
	return matches.length;
};
const WHITE_SPACE_LIMIT = 3;
let resultsArray = [];
let resultsGloabl = [];
class WordChain extends React.Component {
	constructor(props) {
		super(props);
		this.sessionData = getAllSessionItem(this.props.selectedSession);
		this.audio = introAudio;
		const results = {};
		let pseudoResults = {};

		for (let i = 0; i < this.sessionData.length; i++) {
			results[this.sessionData[i].id] = this.sessionData[i].text;
			let currentIndexData = this.sessionData[i];
			resultsArray.push(currentIndexData);
		}

		this.state = {
			disabledAssignments: {},
			results,
			timeRanOut: false,
			sessionData: this.sessionData,
			resultsArray,
			activeClassName: 'rw-input-wc__field',
		};
	}

	componentDidUpdate() {}

	setResults(id, value, index) {
		const { results, sessionData } = this.state;
		for (let i = 0; i < sessionData.length; i++) {
			if (sessionData[i].id === id) {
				if (sessionData[i].answer === value) {
					if (!sessionData[i].pointsAwarded) {
						this.props.flipCoin(3, true);
						sessionData[i].pointsAwarded = true;
					}

					sessionData[i].userAnswer = value;
					sessionData[i].isCorrect = true;
					resultsGloabl[index] = {
						index: id,
						correct: sessionData[i].answer,
						answer: value,
						status: { isCorrect: true },
					};
				} else {
					this.props.recordMistake();
					sessionData[i].userAnswer = value;
					sessionData[i].isCorrect = false;
					resultsGloabl[index] = {
						index: id,
						correct: sessionData[i].answer,
						answer: value,
						status: { isCorrect: false },
					};
				}
			}
		}
		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
			sessionData,
		});
	}
	getDisabledAssignments() {
		const { results } = this.state;
		const disabledAssignments = {};
		for (let i = 0; i < this.sessionData.length; i++) {
			const assignmentId = this.sessionData[i].id;
			const numberOfWhiteSpaces = getNumberofWhiteSpaces(results[assignmentId]);
			const completedAssignment = numberOfWhiteSpaces === WHITE_SPACE_LIMIT;
			if (completedAssignment) {
				disabledAssignments[assignmentId] = true;
			}
		}
		return disabledAssignments;
	}
	runTimeOut() {
		const { sessionData, results } = this.state;
		for (let i = 0; i < sessionData.length; i++) {
			resultsGloabl[i] = {
				index: sessionData[i].id,
				correct: sessionData[i].answer,
				answer: sessionData[i].userAnswer,
				status: { isCorrect: sessionData[i].isCorrect },
			};
		}
		for (let i = 0; i < this.sessionData.length; i++) {
			results[this.sessionData[i].id] = this.sessionData[i].answer;
		}
		this.setState({ timeRanOut: true, results });
	}
	render() {
		const { onNextStep } = this.props;
		const { results, disabledAssignments, timeRanOut } = this.state;
		let allAssignmentsCompleted = true;
		for (let result in results) {
			if (results.hasOwnProperty(result)) {
				const numberOfWhiteSpaces = getNumberofWhiteSpaces(results[result]);
				if (numberOfWhiteSpaces !== WHITE_SPACE_LIMIT) {
					allAssignmentsCompleted = false;
					break;
				}
			}
		}
		let audioPlayerRef;
		return (
			<div className='rw-wordchain-wrapper'>
				<div className='rw-wordChain-container'>
					<div className='rw-infobar'>
						<TimerCount
							duration={this.props.isSuperTeacher ? 45 : 248}
							onComplete={() => {
								/* 	this.revealAllWords(sessionItems); */
								this.runTimeOut();
							}}
						/>
					</div>
					<div className='rw-intro-text'>
						<p>
							<AudioPlayer
								autoPlay
								inline
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								src={this.audio}
							/>
							Lag mellomrom mellom ordene sånn at de blir fire ord
						</p>
					</div>
					<br></br>
					<div className='rw-wrapper--thin'>
						<div className='rw-word-chains'>
							{this.sessionData.map((data, i) => {
								const { id, text } = data;
								const numberOfWhiteSpaces =
									getNumberofWhiteSpaces(results[id]);
								if (timeRanOut && numberOfWhiteSpaces === 0) {
									return null;
								}
								const isDisabled = disabledAssignments[id];
								const onChange = (ev) => {
									if (timeRanOut) {
										return;
									}
									let value = ev.target.value;
									value = value.trim();
									value =
										removeDuplicateWhiteSpaces(
											value
										);
									const hasEnteredOtherCharacters =
										stripWhiteSpaces(value) !==
										text;
									const numberOfWhiteSpaces =
										getNumberofWhiteSpaces(value);
									if (
										hasEnteredOtherCharacters ||
										numberOfWhiteSpaces >
											WHITE_SPACE_LIMIT
									) {
										return;
									}
									this.setResults(id, value, i);
								};
								return (
									<div
										className={classNames({
											'rw-input inline': true,
											completed:
												isDisabled ||
												timeRanOut ||
												allAssignmentsCompleted,
										})}
										key={id}
									>
										<input
											autoFocus={i === 0}
											className={
												this.state
													.timeRanOut ||
												allAssignmentsCompleted
													? classNames(
															{
																'rw-input-wc-after__field': true,
																'style-font': true,
															}
													  )
													: classNames(
															{
																'rw-input-wc__field': true,
																'style-font': true,
															}
													  )
											}
											disabled={
												isDisabled ||
												timeRanOut
											}
											onChange={onChange}
											onFocus={() => {
												const disabledAssignments =
													this.getDisabledAssignments();
												disabledAssignments[
													id
												] = false;
												this.setState(
													{
														disabledAssignments,
													}
												);
											}}
											spellCheck={false}
											value={results[id]}
										/>
									</div>
								);
							})}
						</div>
						<br></br>
						{(allAssignmentsCompleted ||
							timeRanOut ||
							this.props.isSuperTeacher) && (
							<div className='rw-next-btn'>
								<button
									className='rw-btn bg-primary'
									onClick={() =>
										onNextStep(
											resultsGloabl,
											ALL_SUBTESTS.WORDCHAIN
										)
									}
								>
									{'Neste'}
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default WordChain;
