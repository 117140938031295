import monkey from '../../../../../assets/Leksjon_2/leksjon2_bavian.png';
import monkeys from '../../../../../assets/Leksjon_2/leksjon2_bavianer.png';
import duck from '../../../../../assets/Leksjon_2/leksjon2_badeand.png';
import ducks from '../../../../../assets/Leksjon_2/leksjon2_badeander.png';
import teddy from '../../../../../assets/Leksjon_2/leksjon2_bamse.png';
import teddies from '../../../../../assets/Leksjon_2/leksjon2_bamser.png';
import badeball from '../../../../../assets/Leksjon_2/leksjon2_badeball.png';

import oneTail from '../../../../../assets/Leksjon_0/leksjon0_halekatt.png';
import multipleTails from '../../../../../assets/Leksjon_0/leksjon0_halerkatt.png';
import dogs from '../../../../../assets/Leksjon_0/leksjon0_hundene.png';
import dog from '../../../../../assets/Leksjon_0/leksjon0_hund.png';

import cat from '../../../../../assets/Leksjon_0/leksjon0_katt.png';
import cats from '../../../../../assets/Leksjon_0/leksjon0_kattene.png';

import tree from '../../../../../assets/Leksjon_0/leksjon0_tre.png';
import multipleTrees from '../../../../../assets/Leksjon_0/leksjon0_trær.png';

import jet from '../../../../../assets/Leksjon_1/leksjon1_fly.png';
import jets from '../../../../../assets/Leksjon_1/leksjon1_mangefly.png';

import vacuumCleaner from '../../../../../assets/Leksjon_1/leksjon1_støvsuger.png';
import vacuumCleaners from '../../../../../assets/Leksjon_1/leksjon1_støvsugerne.png';

import volcano from '../../../../../assets/Leksjon_3/leksjon3_vulkanutbrudd.png';
import volcanos from '../../../../../assets/Leksjon_3/leksjon3_vulkaner.png';
import dinosaur from '../../../../../assets/Leksjon_3/leksjon3_dinosaur.png';
import dinosaurs from '../../../../../assets/Leksjon_3/leksjon3_dinosaurene.png';
import egg from '../../../../../assets/Leksjon_3/leksjon3_egget.png';
import eggs from '../../../../../assets/Leksjon_3/leksjon3_mangeegg.png';
import usDancing from '../../../../../assets/Leksjon_3/leksjon3_noen_som_danser.png';

import frog from '../../../../../assets/Leksjon_4/leksjon4_frosk.png';
import frogs from '../../../../../assets/Leksjon_4/leksjon4_froskene.png';
import door from '../../../../../assets/Leksjon_4/leksjon4_dør.png';
import doors from '../../../../../assets/Leksjon_4/leksjoner4_dører.png';
import toilet from '../../../../../assets/Leksjon_4/leksjon4_toalett.png';
import toilets from '../../../../../assets/Leksjon_4/leksjon4_toalettene.png';
import someonShouting from '../../../../../assets/Leksjon_4/leksjon4_jente_som_roper.png';

import dressButton from '../../../../../assets/Leksjon_5/leksjon5_knapp.png';
import dressButtons from '../../../../../assets/Leksjon_5/leksjon5_knappene.png';
import tongue from '../../../../../assets/Leksjon_5/leksjon5_tunge.png';
import tongues from '../../../../../assets/Leksjon_5/leksjon5_tunger.png';
import skarf from '../../../../../assets/Leksjon_5/leksjon5_skjerf.png';
import skarfs from '../../../../../assets/Leksjon_5/leksjon5_mange_skjerf.png';
import someonSneezing from '../../../../../assets/Leksjon_5/leksjon5_sjiraff_nyser.png';

/* CURRNT session 6 */
import mushroom from '../../../../../assets/Leksjon_6/leksjon6_sopp.png';
import mushrooms from '../../../../../assets/Leksjon_6/leksjon6_sopper.png';
import patch from '../../../../../assets/Leksjon_6/leksjon6_lapp.png';
import patches from '../../../../../assets/Leksjon_6/leksjon6_lapper.png';
import glass from '../../../../../assets/Leksjon_6/leksjon6_glass.png';
import glasses from '../../../../../assets/Leksjon_6/leksjon6_glasser.png';
import someonSteeling from '../../../../../assets/Leksjon_6/leksjon6_fe_stjeler_honning.png';

import girl from '../../../../../assets/Leksjon_7/leksjon7_jente.png';
import girls from '../../../../../assets/Leksjon_7/leksjon7_jenter.png';
import magicalWands from '../../../../../assets/Leksjon_7/leksjon7_tryllestavene.png';
import magicalWand from '../../../../../assets/Leksjon_7/leksjon7_tryllestav.png';
import singleTree from '../../../../../assets/Leksjon_7/leksjon7_tre.png';
import manyTrees from '../../../../../assets/Leksjon_7/leksjon7_trær.png';
import someonroaring from '../../../../../assets/Leksjon_7/leksjon7_tiger_som_brøler.png';

/* CURRNT session 9 */
import kofte from '../../../../../assets/Leksjon_8/leksjon8_kofte.png';
import kofter from '../../../../../assets/Leksjon_8/leksjon8_kofter.png';
import knagg from '../../../../../assets/Leksjon_8/leksjon8_knagg.png';
import knagger from '../../../../../assets/Leksjon_8/leksjon8_knagger.png';
import singleRoof from '../../../../../assets/Leksjon_8/leksjon8_et_tak.png';
import manyRoofs from '../../../../../assets/Leksjon_8/leksjon8_mange_tak.png';
import someoneTasting from '../../../../../assets/Leksjon_8/leksjon8_smaker_på_kake.png';

/* CURRNT session 10 */
import loaf from '../../../../../assets/Leksjon_9/leksjon9_loff.png';
import loafs from '../../../../../assets/Leksjon_9/leksjon9_loffer.png';
import pant from '../../../../../assets/Leksjon_9/leksjon9_bukse.png';
import pants from '../../../../../assets/Leksjon_9/leksjon9_bukser.png';
import singleLoad from '../../../../../assets/Leksjon_9/leksjon9_lass_med_diamanter.png';
import manyLoads from '../../../../../assets/Leksjon_9/leksjon9_mange_lass_diamanter.png';
import someoneCrying from '../../../../../assets/Leksjon_9/leksjon9_mann_lurvete_som_gråter.png';

/* CURRNT session 11 */
import meteor from '../../../../../assets/Leksjon_10/leksjon10_meteor.png';
import meteors from '../../../../../assets/Leksjon_10/leksjon10_meteorer.png';
import moon from '../../../../../assets/Leksjon_10/leksjon10_måne.png';
import moons from '../../../../../assets/Leksjon_10/leksjon10_månene.png';
import rocket from '../../../../../assets/Leksjon_10/leksjon10_månerakett.png';
import rockets from '../../../../../assets/Leksjon_10/leksjon10_raketter.png';
import rocketLanding from '../../../../../assets/Leksjon_10/leksjon10_planet_lengre_unna.png';

/* CURRNT session 12 */
import fist from '../../../../../assets/Leksjon_11/leksjon11_neve.png';
import fists from '../../../../../assets/Leksjon_11/leksjon11_never.png';
import nose from '../../../../../assets/Leksjon_11/leksjon11_nese.png';
import noses from '../../../../../assets/Leksjon_11/leksjon11_neser.png';
import clown from '../../../../../assets/Leksjon_11/leksjon11_narren.png';
import clowns from '../../../../../assets/Leksjon_11/leksjon11_narrene.png';
import givingMobile from '../../../../../assets/Leksjon_11/leksjon11_gutt_gir_gutt_mobilen.png';

/* CURRNT session 13 */
import wristBand from '../../../../../assets/Leksjon_12/leksjon12_pannebånd.png';
import wristBands from '../../../../../assets/Leksjon_12/leksjon12_mange_pannebånd.png';
import hook from '../../../../../assets/Leksjon_12/leksjon12_krok.png';
import hooks from '../../../../../assets/Leksjon_12/leksjon12_kroker.png';
import flag from '../../../../../assets/Leksjon_12/leksjon12_flag.png';
import flags from '../../../../../assets/Leksjon_12/leksjon12_mangeflag.png';
import pointingPistol from '../../../../../assets/Leksjon_12/leksjon12_pirat_peker_pistol.png';

/* CURRNT session 14 */
import ruinStone from '../../../../../assets/Leksjon_13/leksjon13_runestein.png';
import ruinStones from '../../../../../assets/Leksjon_13/leksjon13_runesteiner.png';
import robber from '../../../../../assets/Leksjon_13/leksjon13_røver.png';
import robbers from '../../../../../assets/Leksjon_13/leksjon13_røvere.png';
import farmer from '../../../../../assets/Leksjon_13/leksjon13_bonde.png';
import farmers from '../../../../../assets/Leksjon_13/leksjon13_bønder.png';
import someoneBlushig from '../../../../../assets/Leksjon_13/leksjon13_røver_som_rødmer.png';

/* CURRNT session 15 */
import muscle from '../../../../../assets/Leksjon_14/leksjon14_muskel.png';
import muscles from '../../../../../assets/Leksjon_14/leksjon14_muskler.png';
import thug from '../../../../../assets/Leksjon_14/leksjon14_bølle.png';
import thugs from '../../../../../assets/Leksjon_14/leksjon14_bøller.png';
import eye from '../../../../../assets/Leksjon_14/leksjon14_øye.png';
import eyes from '../../../../../assets/Leksjon_14/leksjon14_øyne.png';
import swordSwinging from '../../../../../assets/Leksjon_14/leksjon14_ridder_svinger_sverdet.png';

/* CURRNT session 16 */
import patient from '../../../../../assets/Leksjon_15/leksjon15_pasient.png';
import patients from '../../../../../assets/Leksjon_15/leksjon15_pasienter.png';
import denture from '../../../../../assets/Leksjon_15/leksjon15_gebiss.png';
import manyDentures from '../../../../../assets/Leksjon_15/leksjon15_mange_gebiss.png';
import dentalNurse from '../../../../../assets/Leksjon_15/leksjon15_tann_pleier.png';
import dentalNurses from '../../../../../assets/Leksjon_15/leksjon15_tannpleiere.png';
import openingMouth from '../../../../../assets/Leksjon_15/leksjon15_pasient_som_gaper_opp.png';

/* CURRNT session 17 */
import wafle from '../../../../../assets/Leksjon_16/leksjon16_vaffler.png';
import wafles from '../../../../../assets/Leksjon_16/leksjon16_vafler.png';
import vikingShip from '../../../../../assets/Leksjon_16/leksjon16_vikingskip.png';
import vikingShips from '../../../../../assets/Leksjon_16/leksjon16_mange_vikingskip.png';
import horn from '../../../../../assets/Leksjon_16/leksjon16_horn.png';
import horns from '../../../../../assets/Leksjon_16/leksjon16_mange_horn.png';
import walkingViking from '../../../../../assets/Leksjon_16/leksjon16_viking_som_går.png';

import ylvis from '../../../../../assets/Leksjon_1/leksjon1_ylvishund.png';

export const session1 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'hale',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'hale',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'haler',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'haler',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dog,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'hund',
			},
			{
				words: [
					{
						id: 2,
						word: 'hund',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'hunder',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dogs,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'hunder',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'tre',
			},
			{
				words: [
					{
						id: 4,
						word: 'tre',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'trær',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'trær',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Nå _ _ _ hunden som en ulv.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'uler',
			},
			{
				words: [
					{
						id: 6,
						word: 'ulte',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'uler',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ hunden som en ulv.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'ulte',
			},
		],
	},
];
export const session2 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: jet,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'fly',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'fly',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'fly',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: jets,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'fly',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: vacuumCleaner,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'støvsuger',
			},
			{
				words: [
					{
						id: 2,
						word: 'støvsuger',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'støvsugere',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: vacuumCleaners,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'støvsugere',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Nå _ _ _ hunden.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lukter',
			},
			{
				words: [
					{
						id: 6,
						word: 'luktet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lukter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ hunden.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'luktet',
			},
		],
	},
];
export const session3 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: monkey,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'bavian',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'bavian',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'bavianer',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: monkeys,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'bavianer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: teddy,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'Bamse',
			},
			{
				words: [
					{
						id: 2,
						word: 'bamser',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'Bamse',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: teddies,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'bamser',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: duck,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'Badeand',
			},
			{
				words: [
					{
						id: 4,
						word: 'Badeand',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'badeender',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ducks,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'badeender',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: teddy,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Nå _ _ _ jeg en bamse.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'får',
			},
			{
				words: [
					{
						id: 6,
						word: 'får',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'fikk',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: teddy,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ jeg en bamse.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'fikk',
			},
		],
	},
];
export const session4 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: volcano,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'vulkan',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'vulkan',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'Vulkaner',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: volcanos,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'Vulkaner',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dinosaur,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'dinosaur',
			},
			{
				words: [
					{
						id: 2,
						word: 'dinosaur',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'dinosaurer',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dinosaurs,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'dinosaurer',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: egg,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'egg',
			},
			{
				words: [
					{
						id: 4,
						word: 'egg',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'egg',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: eggs,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'egg',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: usDancing,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ vi.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'danser',
			},
			{
				words: [
					{
						id: 6,
						word: 'danser',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'danset',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: usDancing,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ vi.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'danset',
			},
		],
	},
];
export const session5 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: frog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'frosk',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'frosker',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'frosk',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: frogs,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'frosker',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: door,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'dør',
			},
			{
				words: [
					{
						id: 2,
						word: 'dør',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'dører',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: doors,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'dører',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: toilet,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'toalett',
			},
			{
				words: [
					{
						id: 4,
						word: 'toalett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'toalett',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: toilets,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'toalett',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: someonShouting,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ vi.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'roper',
			},
			{
				words: [
					{
						id: 6,
						word: 'ropte',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'roper',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: someonShouting,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ vi.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'ropte',
			},
		],
	},
];
export const session6 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: dressButton,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'knapp',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'knapper',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'knapp',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dressButtons,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'knapper',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: tongue,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'tunge',
			},
			{
				words: [
					{
						id: 2,
						word: 'tunge',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'tunger',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: tongues,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'tunger',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: skarf,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'skjerf',
			},
			{
				words: [
					{
						id: 4,
						word: 'skjerf',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'skjerf',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: skarfs,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'skjerf',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: someonSneezing,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ Guri.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'nyser',
			},
			{
				words: [
					{
						id: 6,
						word: 'nøs',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'nyser',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: someonSneezing,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ Guri.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'nøs',
			},
		],
	},
];
export const session7 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: mushroom,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'sopp',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'sopper',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'sopp',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: mushrooms,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'sopper',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: patch,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lapp',
			},
			{
				words: [
					{
						id: 2,
						word: 'lapp',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'lapper',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: patches,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lapper',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: glass,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'glass',
			},
			{
				words: [
					{
						id: 4,
						word: 'glass',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'glass',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: glasses,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'glass',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: someonSteeling,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ hun honning.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'stjeler',
			},
			{
				words: [
					{
						id: 6,
						word: 'stjal',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'stjeler',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: someonSteeling,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ hun honning.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'stjal',
			},
		],
	},
];
export const session8 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: magicalWand,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'tryllestav',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'tryllestaver',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'tryllestav',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: magicalWands,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'tryllestaver',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: girl,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'jente',
			},
			{
				words: [
					{
						id: 2,
						word: 'jente',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'jenter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: girls,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'jenter',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: singleTree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'tre',
			},
			{
				words: [
					{
						id: 4,
						word: 'trær',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'tre',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: manyTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'trær',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: someonroaring,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ tigeren.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'brøler',
			},
			{
				words: [
					{
						id: 6,
						word: 'brølte',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'brøler',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: someonroaring,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ tigeren.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'brølte',
			},
		],
	},
];
export const session9 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: kofte,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'kofte',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'kofter',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'kofte',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: kofter,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'kofter',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: knagg,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'knagg',
			},
			{
				words: [
					{
						id: 2,
						word: 'knagger',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'knagg',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: knagger,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'knagger',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: singleRoof,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'tak',
			},
			{
				words: [
					{
						id: 4,
						word: 'tak',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'tak',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: manyRoofs,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'tak',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: someoneTasting,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ han på kaken.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'smaker',
			},
			{
				words: [
					{
						id: 6,
						word: 'smaker',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'smakte',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: someoneTasting,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ smakte han på kaken.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'smakte',
			},
		],
	},
];
export const session10 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: loaf,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'loff',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'loffer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'loff',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: loafs,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'loffer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: pant,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'bukse',
			},
			{
				words: [
					{
						id: 2,
						word: 'bukser',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'bukse',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: pants,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'bukser',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: singleLoad,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lass',
			},
			{
				words: [
					{
						id: 4,
						word: 'lass',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'lass',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: manyLoads,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lass',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: someoneCrying,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ Laffen',
				isAnswered: false,
				disable: true,
				correctAnswer: 'gråter',
			},
			{
				words: [
					{
						id: 6,
						word: 'gråt',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'gråter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: someoneCrying,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ Laffen.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'gråt',
			},
		],
	},
];
export const session11 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: meteor,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: meteors,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: moon,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: moons,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: rocket,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: rockets,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: rocketLanding,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: rocketLanding,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session12 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: fist,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'neve',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'never',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'neve',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: fists,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'never',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: nose,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'nese',
			},
			{
				words: [
					{
						id: 2,
						word: 'neser',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'nese',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: noses,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'neser',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: clown,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'narr',
			},
			{
				words: [
					{
						id: 4,
						word: 'narr',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'narrer',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: clowns,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'narrer',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: givingMobile,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ han meg mobilen.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'gir',
			},
			{
				words: [
					{
						id: 6,
						word: 'ga',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'gir',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: givingMobile,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ han meg mobilen..',
				isAnswered: false,
				disable: true,
				correctAnswer: 'ga',
			},
		],
	},
];
export const session13 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: wristBand,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'pannebånd',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'pannebånd',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'pannebånd',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: wristBands,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'pannebånd',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: hook,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'krok',
			},
			{
				words: [
					{
						id: 2,
						word: 'kroker',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'krok',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: hooks,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'kroker',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: flag,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'flagg',
			},
			{
				words: [
					{
						id: 4,
						word: 'flagg',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'flagg',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: flags,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'flagg',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: pointingPistol,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ piraten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'peker',
			},
			{
				words: [
					{
						id: 6,
						word: 'pekte',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'peker',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: pointingPistol,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ piraten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'pekte',
			},
		],
	},
];
export const session14 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: ruinStone,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'runestein',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'runesteiner',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'runestein',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ruinStones,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'runesteiner',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: robber,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'røver',
			},
			{
				words: [
					{
						id: 2,
						word: 'røvere',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'røver',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: robbers,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'røvere',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: farmer,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'bonde',
			},
			{
				words: [
					{
						id: 4,
						word: 'bonde',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'bønder',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: farmers,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'bønder',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: someoneBlushig,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ røveren',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rødmer',
			},
			{
				words: [
					{
						id: 6,
						word: 'rødmet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'rødmer',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: someoneBlushig,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ røveren.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rødmet',
			},
		],
	},
];
export const session15 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: muscle,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'muskel',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'muskler',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'muskel',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: muscles,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'muskler',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: thug,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'bølle',
			},
			{
				words: [
					{
						id: 2,
						word: 'bøller',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'bølle',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: thugs,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'bøller',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: eye,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'øye',
			},
			{
				words: [
					{
						id: 4,
						word: 'øye',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'øyne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: eyes,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'øyne',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: swordSwinging,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ han sverdet.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'svinger',
			},
			{
				words: [
					{
						id: 6,
						word: 'svingte',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'svinger',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: swordSwinging,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ han sverdet..',
				isAnswered: false,
				disable: true,
				correctAnswer: 'svingte',
			},
		],
	},
];
export const session16 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: patient,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'pasient',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'pasienter',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'pasient',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: patients,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'pasienter',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: denture,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'gebiss',
			},
			{
				words: [
					{
						id: 2,
						word: 'gebiss',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'gebiss',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: manyDentures,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'gebiss',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: dentalNurse,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'pleier',
			},
			{
				words: [
					{
						id: 4,
						word: 'pleier',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'pleiere',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dentalNurses,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'pleiere',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: openingMouth,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ pasienten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'gaper',
			},
			{
				words: [
					{
						id: 6,
						word: 'gapte',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'gaper',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: openingMouth,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ pasienten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'gapte',
			},
		],
	},
];
export const session17 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: wafle,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'vaffel',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'vafler',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'vaffel',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: wafles,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'vafler',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: vikingShip,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'vikingskip',
			},
			{
				words: [
					{
						id: 2,
						word: 'vikingskip',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'vikingskip',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: vikingShips,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'vikingskip',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: horn,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er et _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'horn',
			},
			{
				words: [
					{
						id: 4,
						word: 'horn',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'horn',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: horns,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'horn',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: walkingViking,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ vikingen',
				isAnswered: false,
				disable: true,
				correctAnswer: 'går',
			},
			{
				words: [
					{
						id: 6,
						word: 'går',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'gikk',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: walkingViking,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ vikingen.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'gikk',
			},
		],
	},
];
export const session18 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session19 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session20 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session21 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session22 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session23 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session24 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session25 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session26 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session27 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session28 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session29 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
export const session30 = [
	{
		id: 1,
		matchingImages: [
			{
				id: 0,
				img: oneTail,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteor',
			},
			{
				img: null,
				words: [
					{
						id: 0,
						word: 'meteorer',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},

					{
						id: 1,
						word: 'meteor',
						wordId: 'b',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTails,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',

				isAnswered: false,
				disable: true,
				words: null,
				correctAnswer: 'meteorer',
			},
		],
	},
	{
		id: 2,
		matchingImages: [
			{
				id: 0,
				currentClassName: 'rw-fb-img__small',
				img: dogs,
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er èn _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måne',
			},
			{
				words: [
					{
						id: 2,
						word: 'måner',
						correctImageId: '',
						disable: true,
						incorrectClassName: null,
						wordId: 'a',
						isAnswered: false,
					},
					{
						id: 3,
						word: 'måne',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: dog,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'måner',
			},
		],
	},
	{
		id: 3,
		matchingImages: [
			{
				id: 0,
				img: tree,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det en _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'rakett',
			},
			{
				words: [
					{
						id: 4,
						word: 'rakett',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 5,
						word: 'raketter',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: multipleTrees,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'Her er det mange _ _ _',
				isAnswered: false,
				disable: true,
				correctAnswer: 'raketter',
			},
		],
	},
	{
		id: 4,
		matchingImages: [
			{
				id: 0,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I dag _ _ _ raketten',
				isAnswered: false,
				disable: true,
				correctAnswer: 'lander',
			},
			{
				words: [
					{
						id: 6,
						word: 'landet',
						correctImageId: '',
						disable: true,
						wordId: 'a',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						isAnswered: false,
					},
					{
						id: 7,
						word: 'lander',
						wordId: 'b',
						correctImageId: '',
						correctClassName: 'rw-fb-word-correct',
						incorrectClassName: null,
						disable: true,
						isAnswered: false,
					},
				],
			},
			{
				id: 1,
				img: ylvis,
				currentClassName: 'rw-fb-img__small',
				defaultClassName: 'rw-fb-img__small',
				hoverClassName: 'rw-fb-img__small__hover',
				selectClassName: 'rw-fb-img__small__select',
				correctClassName: 'rw-fb-img__small__correct',
				isCorrect: false,
				question: 'I går _ _ _ raketten.',
				isAnswered: false,
				disable: true,
				correctAnswer: 'landet',
			},
		],
	},
];
