import React from 'react';
import { updateObject } from '../../../src/utils/reducerUtils';
import testVerifyAuth from '../../api/testVerifyAuth';
import getUserInfo from '../../api/getUserInfo';
import './styles.scss';
import Logo from '../../assets/readwell_logo_tekst.png';
import { FEIDE } from '../../constants/index';
import { initAuthAction } from '../../actions/index';
import { connect } from 'react-redux';
import { isInProduction } from '../../constants/index';
import queryString from 'query-string';
const parsedSearch = queryString.parse(window.location.search);
const authSuccess = parsedSearch.authSuccess;
function IsValidJSONString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}
class Login extends React.Component {
	constructor() {
		super();
		this.state = {
			isLoginVisible: false,
			isAdminLoginVisible: true,
			isFeideLoginVisible: false,

			psswordLogin: false,
			user: { firstName: null, lastName: null, psw: null },
			createUser: false,
			showAltLogin: false,
		};
	}
	componentDidMount() {
		if (authSuccess) {
			let data = atob(
				decodeURIComponent(window.location.search.substr(window.location.search.indexOf('=') + 1))
			);

			if (data && IsValidJSONString(data)) {
				return new Promise((resolve, reject) => {
					data = JSON.parse(data);

					if (data.authError) {
						reject({ message: 'There was an error logging you in' });
						return;
					}

					if (data && data.action === 'auth' && data.result === 'OK') {
						localStorage.setItem('authtoken-readwell', data.authToken);

						window.location.href = `/test`;
						resolve();
						return;
					}
					reject({ message: `Kunne ikke logge inn!` });
				});
			} else {
				return;
			}
		}
		getUserInfo().then((response) => {
			if (response.ok) {
				window.location.href = `/test`;
			} else {
				localStorage.removeItem('authtoken-readwell');
				if (isInProduction) {
					this.setState({
						isFeideLoginVisible: true,
					});
				} else {
					this.setState({
						isAdminLoginVisible: true,
					});
				}
			}
		});
	}

	updateUser(key, value) {
		const { user } = this.state;
		this.setState({
			user: updateObject(user, {
				[key]: value,
			}),
		});
	}
	sendToBackEnd(user) {
		const firstName = user.firstName;
		const lastName = user.lastName;
		const psw = user.psw;
		testVerifyAuth(firstName, lastName, psw).then((response) => {
			if (response && response.action === 'auth' && response.result === 'OK') {
				localStorage.setItem('authtoken-readwell', response.authToken);

				window.location.href = `/test`;
			} else {
				localStorage.removeItem('authtoken-readwell');
				alert('wrong credentials');
			}
		});
	}

	render() {
		const { isFeideLoginVisible, isAdminLoginVisible } = this.state;
		if (!isInProduction && !isAdminLoginVisible) {
			return null;
		}
		if (isInProduction && !isFeideLoginVisible) {
			return null;
		}
		const { error, iframeUrl, initAuth, isInit } = this.props;
		const showIframe = isInit && iframeUrl;
		const showError = isInit && error;
		if (showIframe && isInProduction) window.location.href = `${iframeUrl}`;
		return (
			<div className='rw-login-mainWrapper'>
				<header className='rw-header'>
					<h1 className='rw-logo'>
						<img className='rw-logo__img' src={Logo} alt='Readwell Logo' />
					</h1>
				</header>
				<>
					<h1 className='rw-page-title'>Read-Well</h1>
				</>
				{!this.state.createUser && isInProduction && (
					<p className='rw-page-description'>
						For å ta oppgaven, må du logge inn med Feide
					</p>
				)}
				{!this.state.createUser && !isInProduction && (
					<p className='rw-page-description'>
						For å ta oppgaven som lærer, må du logge inn med Passord
					</p>
				)}

				<div className='rw-login-main'>
					<div className='rw-login-inner-main'>
						{!this.state.isLoginVisible &&
							!this.state.createUser &&
							!isInit &&
							isInProduction && (
								<div className='rw-btn__text'>
									<button
										className='rw-btn'
										onClick={() => {
											initAuth(FEIDE);
										}}
									>
										Feide
									</button>
								</div>
							)}

						{this.state.isAdminLoginVisible &&
							!this.state.createUser &&
							!isInProduction && (
								<div className='rw-login-form'>
									<div className='rw-input'>
										<label className='rw-label'>
											Brukernavn
										</label>
										<div className='rw-login-text-input'>
											<input
												className='rw-text-input'
												type='text'
												onChange={(
													event
												) => {
													this.updateUser(
														'firstName',
														event
															.target
															.value
													);
												}}
											></input>
										</div>
									</div>
									<div className='rw-input'>
										<label className='rw-label'>
											Tilhørighet
										</label>
										<div className='rw-login-text-input'>
											<input
												className='rw-text-input'
												type='text'
												onChange={(
													event
												) => {
													this.updateUser(
														'lastName',
														event
															.target
															.value
													);
												}}
											></input>
										</div>
									</div>
									<div className='rw-input'>
										<label className='rw-label'>
											Passord
										</label>
										<div className='rw-login-text-input'>
											<input
												className='rw-text-input'
												type='password'
												onChange={(
													event
												) => {
													this.updateUser(
														'psw',
														event
															.target
															.value
													);
												}}
												onKeyUp={(
													event
												) => {
													if (
														event.keyCode ===
															13 ||
														event.code ===
															'Enter'
													) {
														this.sendToBackEnd(
															this
																.state
																.user
														);
													}
												}}
											></input>
										</div>
									</div>
									<div className='rw-btn'>
										<button
											className='rw-btn'
											onClick={() => {
												this.sendToBackEnd(
													this
														.state
														.user
												);
											}}
										>
											{'Send'}
										</button>
									</div>
								</div>
							)}
						{/* {showIframe && isInProduction && (
							<div className='bank-id-iframe-wrapper'>
								<iframe
									title='login-frame'
									frameBorder='0'
									id='authFrame'
									scrolling='no'
									src={iframeUrl}
									style={{
										height: '600px',
										width: '100%',
									}}
								/>
							</div>
						)} */}
						{showError && <div className='rw-feide-error'>{error}</div>}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { error, iframeUrl, isInit } = state.auth;
	return {
		error,
		iframeUrl,
		isInit,
	};
};
const mapDispatchToProps = {
	initAuth: initAuthAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
