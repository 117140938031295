import React from 'react';
import { connect } from 'react-redux';
import * as data from './data/no-data';
import AudioPlayer from '../../../AudioPlayer';
import TimerCount from '../../../TimerCount';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
import introAudio from '../../../../assets/sounds/tak-with-text-into.m4a';
const getTestText = (session, threshold) => {
	let sessionParagraph;

	const indexValue = `Session${session}`;
	if (session < 18) {
		sessionParagraph = data[indexValue][0].text;
		const targetWord = data[indexValue][0].targetWord;
		const clickInstructionText = data[indexValue][0].clickInstructionText;
		const writeInstructionText = data[indexValue][0].writeInstructionText;
		const wordsToIgnore = data[indexValue][0].ignoreWords;
		console.log('wordsToIgnore', wordsToIgnore);
		const targetCount = data[indexValue][0].wordCount;
		const clickInstructions = data[indexValue][0].clickInstructions;
		const wrtieInstructions = data[indexValue][0].wrtieInstructions;

		const sessionSplitText = sessionParagraph.split(' ');

		const objData = {
			text: sessionSplitText,
			targetWord: targetWord,
			wordsToIgnore: wordsToIgnore,
			targetCount: targetCount,

			clickInstructionAudio: clickInstructions,
			wrtieInstructionsAudio: wrtieInstructions,
			clickInstructionText,
			writeInstructionText,
		};
		return objData;
	} else {
		sessionParagraph = threshold === 'advanced' ? data[indexValue][0].text : data[indexValue][0].intermediateText;
		const targetWord = data[indexValue][0].targetWord;
		const wordsToIgnore = data[indexValue][0].ignoreWords;
		console.log('wordsToIgnore', wordsToIgnore);
		const clickInstructionText = data[indexValue][0].clickInstructionText;
		const writeInstructionText = data[indexValue][0].writeInstructionText;

		const targetCount =
			threshold === 'advanced' ? data[indexValue][0].wordCount : data[indexValue][0].intermediateCount;
		const clickInstructions = data[indexValue][0].clickInstructions;
		const wrtieInstructions = data[indexValue][0].wrtieInstructions;

		const sessionSplitText = sessionParagraph.split(' ');

		const objData = {
			text: sessionSplitText,
			targetWord: targetWord,
			wordsToIgnore: wordsToIgnore,
			targetCount: targetCount,

			clickInstructionAudio: clickInstructions,
			wrtieInstructionsAudio: wrtieInstructions,
			clickInstructionText,
			writeInstructionText,
		};
		return objData;
	}
};
/* const splitTextArray = text.split(' ');
threshold ==='advanced'? data[indexValue][0].text :threshold==='intermediate'?'':''
*/
const mainTextArray = [];
let temporaryCounter = 0;
const resultsGloabl = [];
var firstCorrectAttempt = true;
var consecutiveCorrect = 0;
class TaskWithText extends React.Component {
	constructor(props) {
		super(props);

		this.textData = getTestText(this.props.selectedSession, this.props.threshold);
		this.text = this.textData.text;
		this.targetWord = this.textData.targetWord;
		this.wordsToIgnore = this.textData.wordsToIgnore;
		this.targetCount = this.textData.targetCount;
		this.clickAudio = this.textData.clickInstructionAudio;
		this.wrtieAudio = this.textData.wrtieInstructionsAudio;
		this.clickInstructionText = this.textData.clickInstructionText;
		this.writeInstructionText = this.textData.writeInstructionText;

		for (let i = 0; i < this.text.length; i++) {
			const obj = { isEnabled: true, isAnswered: false, isCorrect: false, text: this.text[i] };
			mainTextArray.push(obj);
		}

		this.state = {
			textArray: mainTextArray,
			counter: temporaryCounter,
			writtenWords: [],
			hasFoundAllWords: false,
			currentSound: this.clickAudio,
			currentInstruction: this.clickInstructionText,
			hasWrittenAllWords: false,
			removePlaceHolder: false,
			textFieldClass: 'instruciton__highlight-twt',
			duration: 300,
			timerRanOut: false,
		};
	}

	setUpMainText() {
		for (let i = 0; i < this.text.length; i++) {
			const obj = { isAnswered: false, isCorrect: false, text: this.text[i] };
			mainTextArray.push(obj);
		}
	}
	handleKeyPress(event, word) {
		console.log('This is the word', word);
		if (event.keyCode === 32) {
			event.target.value = '';
			return;
		}
		if (event.code !== undefined) {
			if (event.code === 'Space') {
				event.target.value = '';
				return;
			}
		}
		console.log('These are the words to ignore', this.wordsToIgnore);

		if (this.wordsToIgnore.includes(word) || word === '') {
			return;
		}

		let wordsFound = 0;
		for (let i = 0; i < mainTextArray.length; i++) {
			if (
				mainTextArray[i].text.toLowerCase().replace(/[. ,\/#!$%\^&\*;»?:{}«=\-_`~ ()]/g, '') ===
					word.toLowerCase().replace(/[. ,\/#!$%\^&\*;»?:{}«=\-_`~ ()]/g, '') &&
				!mainTextArray[i].isAnswered
			) {
				if (
					this.props.selectedSession < 18 &&
					word[0].toLowerCase().trim() === this.targetWord.toLowerCase().trim()
				) {
					mainTextArray[i].isAnswered = true;
					mainTextArray[i].isCorrect = true;
					temporaryCounter = temporaryCounter + 1;
					wordsFound = wordsFound + 1;
					if (temporaryCounter >= this.targetCount) {
						this.setState({
							textArray: mainTextArray,
							counter: temporaryCounter,
							hasWrittenAllWords: true,
						});
					} else {
						this.setState({
							textArray: mainTextArray,
							counter: temporaryCounter,
						});
					}
					event.target.value = '';

					this.props.streak || consecutiveCorrect > 3 || firstCorrectAttempt
						? this.props.flipCoin(wordsFound * 3, true)
						: this.props.streak
						? this.props.flipCoin(wordsFound * 3, true)
						: this.props.flipCoin(wordsFound);
					consecutiveCorrect = consecutiveCorrect + 1;

					this.updateResults(mainTextArray[i].text, word, { isCorrect: true });
				} else if (
					this.props.selectedSession > 17 &&
					this.evaluateWordInclusion(word, this.targetWord)
				) {
					mainTextArray[i].isAnswered = true;
					mainTextArray[i].isCorrect = true;
					temporaryCounter = temporaryCounter + 1;
					wordsFound = wordsFound + 1;
					if (temporaryCounter >= this.targetCount) {
						this.setState({
							textArray: mainTextArray,
							counter: temporaryCounter,
							hasWrittenAllWords: true,
						});
					} else {
						this.setState({
							textArray: mainTextArray,
							counter: temporaryCounter,
						});
					}
					event.target.value = '';

					this.props.streak || consecutiveCorrect > 3
						? this.props.flipCoin(wordsFound * 3, true)
						: this.props.streak
						? this.props.flipCoin(wordsFound * 3, true)
						: this.props.flipCoin(wordsFound);
					consecutiveCorrect = consecutiveCorrect + 1;

					this.updateResults(mainTextArray[i].text, word, { isCorrect: true });
				} else {
					mainTextArray[i].isAnswered = true;
					mainTextArray[i].isCorrect = false;
					this.props.recordMistake();
					consecutiveCorrect = false;
					this.updateResults(mainTextArray[i].text, word, { isCorrect: false });
					this.setState({
						textArray: mainTextArray,
						counter: temporaryCounter,
						textFieldClass: 'instruciton__highlight-wrong-fw',
					});
					setTimeout(() => {
						this.setState({
							textFieldClass: 'instruciton__highlight-fw',
						});
						event.target.value = '';
					}, 1000);
				}
			}
		}
	}

	evaluateWordInclusion(word, targetWord) {
		for (let i = 0; i < this.targetWord.length; i++) {
			if (word.toLowerCase().includes(this.targetWord[i].toLowerCase())) {
				return true;
			}
		}
		return false;
	}
	updateResults(word, target, status) {
		resultsGloabl.push({ chosenWord: word, correctWord: target, status });
	}

	setValuesToDefault() {
		for (let i = 0; i < mainTextArray.length; i++) {
			mainTextArray[i].isAnswered = false;
			mainTextArray[i].isEnabled = false;
			mainTextArray[i].isCorrect = false;
		}
		temporaryCounter = 0;
		this.setState({
			textArray: mainTextArray,
			duration: 400,
			counter: temporaryCounter,
			hasFoundAllWords: true,
			currentSound: this.wrtieAudio,
			currentInstruction: this.writeInstructionText,
		});
	}
	timeHasRunOut() {
		for (let i = 0; i < mainTextArray.length; i++) {
			mainTextArray[i].isAnswered = false;
			mainTextArray[i].isEnabled = false;
			mainTextArray[i].isCorrect = false;
		}
		temporaryCounter = 0;
		this.setState({
			textArray: mainTextArray,
			duration: 400,
			counter: temporaryCounter,
			hasFoundAllWords: true,
			currentSound: this.wrtieAudio,
			currentInstruction: this.writeInstructionText,
			timerRanOut: true,
		});
	}

	render() {
		const { textArray } = this.state;
		const { onNextStep } = this.props;
		let videoRef;
		let audioPlayerRef;
		return (
			<div className='rw-lk-introWrapper'>
				{!this.state.hasFoundAllWords && (
					<div className='rw-infobar'>
						<TimerCount
							duration={130}
							onComplete={() => {
								this.setValuesToDefault();
							}}
						/>
					</div>
				)}

				{this.state.hasFoundAllWords && !this.state.timerRanOut && (
					<div className='rw-infobar'>
						<TimerCount
							duration={130}
							onComplete={() => {
								this.timeHasRunOut();
							}}
						/>
					</div>
				)}

				<div className='instruction'>
					<p className='text-left'>
						<AudioPlayer
							autoPlay
							inline
							ref={(ref) => {
								audioPlayerRef = ref;
							}}
							src={this.state.currentSound}
						/>
						<span className='instruciton-fw__highlight'>
							{this.state.currentInstruction}
						</span>
					</p>
				</div>

				<div className='rw-lk-intro'>
					<div className='rw-lk-paragraph'>
						<div className='rw-text-taskWrapper'>
							{textArray.map((text, index) => {
								let textValue = text.text + ' ';

								return (
									<span
										className={
											textArray[index]
												.isCorrect
												? 'rw-text-taskWrapper__correct'
												: textArray[
														index
												  ]
														.isAnswered
												? 'rw-text-taskWrapper__wrong'
												: 'rw-text-taskWrapper__task'
										}
										id={index}
										key={index}
										onClick={() => {
											const singleWord =
												textValue.split(
													''
												);

											if (
												this.props
													.selectedSession <
													18 &&
												singleWord[0].toLowerCase() ===
													this.targetWord.toLowerCase() &&
												textArray[
													index
												]
													.isEnabled
											) {
												if (
													!textArray[
														index
													]
														.isAnswered
												) {
													consecutiveCorrect =
														consecutiveCorrect +
														1;
													this
														.props
														.streak ||
													consecutiveCorrect >
														3 ||
													firstCorrectAttempt
														? this.props.flipCoin(
																3,
																true
														  )
														: this
																.props
																.streak
														? this.props.flipCoin(
																3,
																true
														  )
														: this.props.flipCoin();
													firstCorrectAttempt = true;
													this.updateResults(
														textValue,
														this
															.targetWord,
														{
															isCorrect: true,
														}
													);
													temporaryCounter =
														temporaryCounter +
														1;
													mainTextArray[
														index
													].isAnswered = true;
													mainTextArray[
														index
													].isCorrect = true;
												}

												if (
													temporaryCounter >=
													this
														.targetCount
												) {
													this.setValuesToDefault();
												} else {
													this.setState(
														{
															textArray: mainTextArray,
															counter: temporaryCounter,
														}
													);
												}
											} else if (
												this.evaluateWordInclusion(
													textValue,
													this
														.targetWord
												) &&
												textArray[
													index
												]
													.isEnabled &&
												this.props
													.selectedSession >
													17
											) {
												if (
													!textArray[
														index
													]
														.isAnswered
												) {
													consecutiveCorrect =
														consecutiveCorrect +
														1;
													this
														.props
														.streak ||
													consecutiveCorrect >
														3 ||
													firstCorrectAttempt
														? this.props.flipCoin(
																3,
																true
														  )
														: this
																.props
																.streak
														? this.props.flipCoin(
																3,
																true
														  )
														: this.props.flipCoin();
													firstCorrectAttempt = true;
													this.updateResults(
														textValue,
														this
															.targetWord,
														{
															isCorrect: true,
														}
													);
													temporaryCounter =
														temporaryCounter +
														1;
													mainTextArray[
														index
													].isAnswered = true;
													mainTextArray[
														index
													].isCorrect = true;
												}

												if (
													temporaryCounter >=
													this
														.targetCount
												) {
													this.setValuesToDefault();
												} else {
													this.setState(
														{
															textArray: mainTextArray,
															counter: temporaryCounter,
														}
													);
												}
											} else {
												{
													if (
														!textArray[
															index
														]
															.isAnswered &&
														textArray[
															index
														]
															.isEnabled
													) {
														firstCorrectAttempt = false;
														consecutiveCorrect = 0;
														console.log(
															'These are the words to ignore',
															this
																.wordsToIgnore
														);
														this.props.recordMistake();
														this.updateResults(
															singleWord,
															this
																.targetWord,
															{
																isCorrect: false,
															}
														);
													}
													if (
														textArray[
															index
														]
															.isEnabled
													) {
														mainTextArray[
															index
														].isAnswered = true;
														mainTextArray[
															index
														].isCorrect = false;

														this.setState(
															{
																textArray: mainTextArray,
															}
														);
													}
												}
											}
										}}
									>
										<p
											style={{
												whiteSpace: 'nowrap',
											}}
										>
											{' '}
											{`${textValue} `}
										</p>
									</span>
								);
							})}
						</div>
					</div>
				</div>
				{this.state.hasFoundAllWords &&
					!this.state.timerRanOut &&
					!this.state.hasWrittenAllWords && (
						<div className='twt-txt-container'>
							<div className='twt-txt'>
								<div className='rw-twt-input-container'>
									<span
										className={
											this.state
												.textFieldClass
										}
									>
										<input
											key={123}
											className={
												'rw-task-fw-input'
											}
											/* placeholder={`Ord som begynner med ${this.targetWord}`}
											 */ type='text'
											onKeyUp={(
												event
											) => {
												this.handleKeyPress(
													event,
													event
														.target
														.value
												);
												if (
													!event
														.target
														.value
												) {
													this.setState(
														{
															removePlaceHolder: false,
														}
													);
												}
											}}
											onKeyDown={() => {
												this.setState(
													{
														removePlaceHolder: true,
													}
												);
											}}
										/>
										{!this.state
											.removePlaceHolder && (
											<div className='placeholder'>
												Skriv Ord
												med{' '}
												<span>
													{this
														.props
														.selectedSession <
													18
														? this
																.targetWord
														: this.targetWord.map(
																(
																	singleWord
																) => {
																	return (
																		<span>
																			{'   ' +
																				singleWord +
																				'  '}
																		</span>
																	);
																}
														  )}
												</span>
											</div>
										)}
									</span>
								</div>
							</div>
						</div>
					)}
				<br></br>
				{(this.state.hasWrittenAllWords ||
					this.state.timerRanOut ||
					this.props.isSuperTeacher) && (
					<div className='rw-next-btn'>
						<button
							className='rw-btn bg-primary'
							onClick={() =>
								onNextStep(
									resultsGloabl,
									ALL_SUBTESTS.TASKWITHTEXT
								)
							}
						>
							{'Neste'}
						</button>
					</div>
				)}
			</div>
		);
	}
}
export default TaskWithText;
