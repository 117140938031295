import { combineReducers } from 'redux';
import auth from './auth';
import currentStepIndex from './currentStepIndex';
/* import testStepAnswers from './testStepAnswers';
 */
import candidateSession from './candidateSession';

const rootReducer = combineReducers({
	auth,
	/* 	testStepAnswers, */
	currentStepIndex,
	candidateSession,
});

export default rootReducer;
