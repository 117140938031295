import { ENPOINT_URL } from '../constants';
import getFetchOptions from '../utils/getFetchOptions';

export default function testVerifyAuth(firstName, lastName, psw) {
	const user = {
		firstName: firstName,
		lastName: lastName,
		origin: 'readWell',
		password: psw,
	};

	return fetch(
		`${ENPOINT_URL}/verifyPesudoAuth`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify(user),
		})
	)
		.then((response) => {
			if (response.ok) {
				return response.json();
			}
			return Promise.reject(response);
		})
		.catch((error) => {
			console.log('error', error);
		});
}
