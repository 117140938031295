export const DEVELOPMENT = 'development';
export const TESTING = 'testing';
export const PRODUCTION = 'production';

const validEnviroments = [DEVELOPMENT, TESTING, PRODUCTION];

export const environmentFromEnVars = PRODUCTION;
export const isInProduction = environmentFromEnVars === 'production' ? true : false;
export const ENVIROMENT = validEnviroments.includes(environmentFromEnVars) ? environmentFromEnVars : PRODUCTION;

const rootUrls = { development: '', testing: '', production: '' };
export const ROOT_URL = rootUrls[ENVIROMENT];
const endpointUrls = {
	development: 'http://localhost:3000',
	testing: 'http://testin.eba-e8bmkadq.eu-central-1.elasticbeanstalk.com',
	production: 'https://sessionscreening.leselig.com',
};
export const apiGatewayUrl = 'http://lit-testing.eu-central-1.elasticbeanstalk.com';
export const ENPOINT_URL = endpointUrls[ENVIROMENT];
export const DASHARRAY_LENGTH = 230;
export const REDUCED_TIMERS = false;
export const FEIDE = 'feide';
export const availableSessions = 93;

const OPENINGINTRO = 'OPENINGINTRO';
const FLYINGWORDS = 'FLYINGWORDS';
const TASKWITHTEXT = 'TASKWITHTEXT';
const LETTERPLACEMENT = 'LETTERPLACEMENT';
const RHYMINGWORDS = 'RHYMINGWORDS';
const SINGLUARPLURAL = 'SINGLUARPLURAL';
const FILLINTHEBLANCKS = 'FILLINTHEBLANCKS';
const MATCHINGWORDS = 'MATCHINGWORDS';
const WORDSYNONYMOUS = 'WORDSYNONYMOUS';
const SUPERLATIVEADJECTIVES = 'SUPERLATIVEADJECTIVES';
const FINDWORDS = 'FINDWORDS';
const DAYSTEXTREVISION = 'DAYSTEXTREVISION';
const PHONEMEAWARENESS = 'PHONEMEAWARENESS';
const MORPHEMEAWARENESS = 'MORPHEMEAWARENESS';
const MULTIPLECHOICES = 'MULTIPLECHOICES';
const WORDCHAIN = 'WORDCHAIN';

export const ALL_SUBTESTS = {
	OPENINGINTRO,
	FLYINGWORDS,
	TASKWITHTEXT,
	LETTERPLACEMENT,
	RHYMINGWORDS,
	SINGLUARPLURAL,
	FILLINTHEBLANCKS,
	MATCHINGWORDS,
	WORDSYNONYMOUS,
	SUPERLATIVEADJECTIVES,
	FINDWORDS,
	PHONEMEAWARENESS,
	DAYSTEXTREVISION,
	MORPHEMEAWARENESS,
	MULTIPLECHOICES,
	WORDCHAIN,
};
export const SUBTEST_IDS = {
	[OPENINGINTRO]: 3,
	[FLYINGWORDS]: 4,
	[TASKWITHTEXT]: 5,
	[LETTERPLACEMENT]: 6,
	[RHYMINGWORDS]: 7,
	[SINGLUARPLURAL]: 8,
	[FILLINTHEBLANCKS]: 9,
	[MATCHINGWORDS]: 10,
	[WORDSYNONYMOUS]: 11,
	[SUPERLATIVEADJECTIVES]: 12,
	[FINDWORDS]: 13,
	[DAYSTEXTREVISION]: 14,
	[PHONEMEAWARENESS]: 15,
	[MORPHEMEAWARENESS]: 16,
	[MULTIPLECHOICES]: 17,
	[WORDCHAIN]: 18,
};
