import React from 'react';
import './styles.scss';

import flipGif from '../../../assets/smiley_wink.gif';
import flipGifStreak from '../../../assets/streak.gif';
import flipImg from '../../../assets/smiley_wink_img.png';
import flipImgStreak from '../../../assets/smiley_wink_streak.png';
import { isInProduction } from '../../../constants/index';
import OpeningIntro from '../ReadinComprehension/OpeningIntro';
import PhonemeAwareness from '../ReadinComprehension/PhonemeAwareness';
import MorphemeAwareness from '../ReadinComprehension/MorphemeAwareness';
import MatchingWords from '../ReadinComprehension/MatchingWords';
import RhymingWords from '../ReadinComprehension/RhymingWords';
import FlyingWords from '../ReadinComprehension/FlyingWords';
import WordSynonymous from '../ReadinComprehension/WordSynonyms';
import LetterPlacement from '../ReadinComprehension/LetterPlacement';
import TaskWithText from '../ReadinComprehension/TaskWithText';
import SingluarPlural from '../ReadinComprehension/SingularPlural';
import FillinTheBlancks from '../ReadinComprehension/FillinTheBlanks';
import SuperlativeAdjectives from './SuperlativeAdjectives';
import DaysTextRevision from './DaysTextRevision';
import submitAnswers from '../../../api/submitAnswers';
import Finish from '../../Finish';
import FindWords from './FindWords';
import MultipleChoices from './MultipleChoices';
import WordChain from './WordChain';
import getSessionInfo from '../../../api/getSessionInfo';
import { connect } from 'react-redux';

const nestedSteps = [
	OpeningIntro,
	MatchingWords,
	FlyingWords,
	TaskWithText,
	LetterPlacement,
	RhymingWords,
	SingluarPlural,
	FillinTheBlancks,
	/* MatchingWords, */
	WordSynonymous,
	SuperlativeAdjectives,
	FindWords,
	DaysTextRevision,
	Finish,
];
const titles = [
	'Lytteforståelse',
	'Sammensatte ord',
	'Flygende bokstaver',
	'Bokstavkunnskap',
	'Les og lytt etter lyden i ordet',
	'Rimord',
	'Entall-Flertall',
	'Hvilket ord mangler?',
	/* 'Sammensatte ord', */
	'Ord som betyr det samme',
	'Gradbøyning av adjektiv',
	'Finn ord i ord',
	'Individuell tekstlesing',
	'Ferdig',
];

const nestedSteps18 = [
	OpeningIntro /* openingIntro */,
	PhonemeAwareness, //PhonemeAwareness
	FlyingWords, //FlyingWords
	TaskWithText /*TaskWithText  */,
	MorphemeAwareness, //MorphemeAwareness,
	MatchingWords, //MatchingWords,
	FindWords /*FindWords  */,
	DaysTextRevision /* DaysTextRevision */,
	Finish,
];

const titles18 = [
	'Lytteforståelse',
	'Fonembevissthet',
	'Flygende bokstaver',
	'Bokstavkunnskap',
	'Morfembevissthet',
	'Sammensatte ord',
	'Finn ord i ord',
	'Individuell tekstlesing',
	'Ferdig',
];

const nestedSteps52 = [
	OpeningIntro /* openingIntro */,
	PhonemeAwareness, //PhonemeAwareness
	FlyingWords, //FlyingWords
	MultipleChoices, //MultipleChoices
	MorphemeAwareness, //MorphemeAwareness,
	MatchingWords, //MatchingWords,
	WordChain /*WORDCHAIN  */,
	DaysTextRevision /* DaysTextRevision */,
	Finish,
];

const titles52 = [
	'Lytteforståelse',
	'Fonembevissthet',
	'Flygende grafemer',
	'Leseforståelse',
	'Morfembevissthet',
	'Sammensatte ord',
	'Ordkjeder',
	'Individuell tekstlesing',
	'Ferdig',
];

const upper18Ids = [4, 5, 16, 10, 13];
const upper51Ids = [4, 17, 16, 10, 18];
const renderRemainingTasks = [];
const renderRemainingTitles = [];
const renderRemainingProgressIndex = [];

const progress = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
const progress18 = [3, 4, 5, 6, 7, 8, 9, 10, 11];
const progress52 = [3, 4, 5, 6, 7, 8, 9, 10, 11];

const getNestedStepForSession = (session) => {
	let sessionChapters;

	if (session < 18) sessionChapters = { titles: titles, nestedSteps: nestedSteps, progress: progress };

	if (session > 17 && session < 52)
		sessionChapters = { titles: titles18, nestedSteps: nestedSteps18, progress: progress18 };

	if (session > 51) sessionChapters = { titles: titles52, nestedSteps: nestedSteps52, progress: progress52 };
	return sessionChapters;
};

class ReadingComprehension extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentNestedStepIndex: 0,
			currentImg: flipImgStreak,
			rewardPoints: 0,
			totalMistakes: 0,
			achievedPoints: 0,
			currentChapterIndex: 1,
			currentTitle: 'Lytteforståelse',
			progress: 1,
			threshold: 'advanced',
			totalCorrect: 0,
			streak: true,
			creditScoreClass: 'rw-credit-score',
		};
		this.allNestedSteps = getNestedStepForSession(this.props.selectedSession);
		this.defaultNestedSteps = this.allNestedSteps.nestedSteps;
		this.defaultTitles = this.allNestedSteps.titles;
		this.defaultProgress = this.allNestedSteps.progress;
		this.handleNextNestedStep = this.handleNextNestedStep.bind(this);
		this.flipCoin = this.flipCoin.bind(this);
		this.recordMistake = this.recordMistake.bind(this);
		this.updateProgressCounter = this.updateProgressCounter.bind(this);
	}

	componentDidMount() {
		getSessionInfo(this.props.selectedSession).then((response) => {
			const exclusiveTasks = response.exclusiveTasks;
			const threshold = response.threshold;
			const rewardPoints = response.rewardPoints.length ? response.rewardPoints[0].RewardPoints : null;
			const achievedPoints = response.achievedPoints;
			const tasksToContinue = Object.values(exclusiveTasks);
			this.flipCoin(response.achievedPoints, true);
			if (tasksToContinue.length) {
				renderRemainingTasks.push(OpeningIntro);
				this.pushRemainingTasks(tasksToContinue);
			}
			this.setState({
				threshold: threshold,
				achievedPoints: achievedPoints,
				rewardPoints: rewardPoints ? rewardPoints : achievedPoints,
				currentImg: flipImgStreak,
			});
		});
	}
	flipCoin(extraReward, streak) {
		let currentCredit = this.state.rewardPoints;
		let totalCorrect = this.state.totalCorrect;
		let newStreak = false;

		if (streak) {
			newStreak = streak;
		}

		totalCorrect = totalCorrect + 1;
		if (extraReward) {
			currentCredit = currentCredit + extraReward;
		} else {
			currentCredit = currentCredit + 1;
		}

		this.setState({
			currentImg: newStreak ? flipGifStreak : flipGif,
			creditScoreClass: newStreak ? 'rw-credit-score-streak' : 'rw-credit-score',
			rewardPoints: currentCredit,
			streak: newStreak,
			totalCorrect,
		});

		setTimeout(() => {
			this.setState({
				currentImg: newStreak ? flipImgStreak : flipImg,
			});
		}, 1400);
	}
	recordMistake() {
		let currentMistakes = this.state.totalMistakes;
		currentMistakes = currentMistakes + 1;
		let streak = false;

		this.setState({
			currentImg: flipImg,
			totalMistakes: currentMistakes,
			creditScoreClass: 'rw-credit-score',
			streak,
		});
	}
	pushRemainingTasks(tasksToContinue) {
		if (this.props.selectedSession < 18) {
			for (let i = 0; i < tasksToContinue.length + 1; i++) {
				switch (tasksToContinue[i]) {
					case 4:
						renderRemainingTasks.push(FlyingWords);
						renderRemainingTitles.push('Flygende bokstaver');
						renderRemainingProgressIndex.push(4);

						break;
					case 5:
						renderRemainingTasks.push(TaskWithText);
						renderRemainingTitles.push('Bokstavkunnskap');
						renderRemainingProgressIndex.push(5);

						break;
					case 6:
						renderRemainingTasks.push(LetterPlacement);
						renderRemainingTitles.push('Les og lytt etter lyden i ordet');
						renderRemainingProgressIndex.push(6);

						break;
					case 7:
						renderRemainingTasks.push(RhymingWords);
						renderRemainingTitles.push('Rimord');
						renderRemainingProgressIndex.push(7);

						break;
					case 8:
						renderRemainingTasks.push(SingluarPlural);
						renderRemainingTitles.push('Entall-Flertall');
						renderRemainingProgressIndex.push(8);

						break;
					case 9:
						renderRemainingTasks.push(FillinTheBlancks);
						renderRemainingTitles.push('Hvilket ord mangler?');
						renderRemainingProgressIndex.push(9);

						break;
					case 10:
						renderRemainingTasks.push(MatchingWords);
						renderRemainingTitles.push('Sammensatte ord');
						renderRemainingProgressIndex.push(10);

						break;
					case 11:
						renderRemainingTasks.push(WordSynonymous);
						renderRemainingTitles.push('Ord som betyr det samme');
						renderRemainingProgressIndex.push(11);

						break;
					case 12:
						renderRemainingTasks.push(SuperlativeAdjectives);
						renderRemainingTitles.push('Gradbøyning av adjektiv');
						renderRemainingProgressIndex.push(12);

						break;
					case 13:
						renderRemainingTasks.push(FindWords);
						renderRemainingTitles.push('Finn ord i ord');
						renderRemainingProgressIndex.push(13);

						break;
					case 14:
						renderRemainingTasks.push(DaysTextRevision);
						renderRemainingTitles.push('Individuell tekstlesing');
						renderRemainingProgressIndex.push(14);
				}
				if (i === tasksToContinue.length) {
					renderRemainingTasks.push(Finish);
					renderRemainingTitles.push('Ferdig');
					renderRemainingProgressIndex.push(15);
				}
				this.setState({
					tasksToContinue,
				});
			}
		} else if (this.props.selectedSession > 17 && this.props.selectedSession < 52) {
			for (let i = 0; i < upper18Ids.length; i++) {
				this.SetUpSecondPhaseTests(upper18Ids[i], tasksToContinue);
			}
			renderRemainingTasks.push(FindWords, DaysTextRevision, Finish);
			renderRemainingTitles.push('Finn ord i ord', 'Individuell tekstlesing', 'Ferdig');
			renderRemainingProgressIndex.push(9, 10, 11);

			this.setState({
				tasksToContinue,
			});
		} else {
			for (let i = 0; i < upper51Ids.length; i++) {
				this.SetUpThirdPhaseTests(upper51Ids[i], tasksToContinue);
			}
			renderRemainingTasks.push(WordChain, DaysTextRevision, Finish);
			renderRemainingTitles.push('Ordkjeder', 'Individuell tekstlesing', 'Ferdig');
			renderRemainingProgressIndex.push(9, 10, 11);

			this.setState({
				tasksToContinue,
			});
		}
	}
	SetUpSecondPhaseTests(testId, missingTests) {
		if (missingTests.includes(testId)) {
			switch (testId) {
				case 4:
					renderRemainingTasks.push(FlyingWords);
					renderRemainingTitles.push('Flygende bokstaver');
					renderRemainingProgressIndex.push(5);

					break;
				case 5:
					renderRemainingTasks.push(TaskWithText);
					renderRemainingTitles.push('Bokstavkunnskap');
					renderRemainingProgressIndex.push(6);

					break;
				case 16:
					renderRemainingTasks.push(MorphemeAwareness);
					renderRemainingTitles.push('Morfembevissthet');
					renderRemainingProgressIndex.push(7);

					break;

				case 10:
					renderRemainingTasks.push(MatchingWords);
					renderRemainingTitles.push('Sammensatte ord');
					renderRemainingProgressIndex.push(8);

					break;
			}
		}
	}

	SetUpThirdPhaseTests(testId, missingTests) {
		if (missingTests.includes(testId)) {
			switch (testId) {
				case 4:
					renderRemainingTasks.push(FlyingWords);
					renderRemainingTitles.push('Flygende grafemer');
					renderRemainingProgressIndex.push(5);

					break;
				case 17:
					renderRemainingTasks.push(MultipleChoices);
					renderRemainingTitles.push('Arbeid med teksten');
					renderRemainingProgressIndex.push(6);

					break;
				case 16:
					renderRemainingTasks.push(MorphemeAwareness);
					renderRemainingTitles.push('Morfembevissthet');
					renderRemainingProgressIndex.push(7);

					break;

				case 10:
					renderRemainingTasks.push(MatchingWords);
					renderRemainingTitles.push('Sammensatte ord');
					renderRemainingProgressIndex.push(8);

					break;
			}
		}
	}
	handleNextNestedStep(results, subtestId) {
		if (this.props.selectedSession < 18) this.submitTestResultsForPhase1(results, subtestId);
		else if (this.props.selectedSession > 17 && this.props.selectedSession < 52) {
			this.submitTestResultsForPhase2(results, subtestId);
		} else if (this.props.selectedSession > 51) {
			this.submitTestResultsForPhase3(results, subtestId);
		}
	}
	updateProgressCounter() {
		this.setState({ currentChapterIndex: this.state.currentChapterIndex + 1 });
	}
	submitTestResultsForPhase1(results, subTest) {
		const { currentNestedStepIndex, tasksToContinue } = this.state;
		let nextTaskTitle;
		let nextProgress;

		if (currentNestedStepIndex > 0 && currentNestedStepIndex < 11 && !subTest.isLast && isInProduction) {
			submitAnswers(results, subTest, this.props.selectedSession, this.state.rewardPoints).then((res) => {
				if (res.ok) {
					const isLastStep =
						currentNestedStepIndex === this.defaultNestedSteps.length - 1;
					if (isLastStep) {
					} else {
						if (renderRemainingTasks.length) {
							nextTaskTitle =
								renderRemainingTitles[currentNestedStepIndex];
							nextProgress =
								renderRemainingProgressIndex[
									currentNestedStepIndex
								];
						} else {
							nextTaskTitle =
								this.defaultTitles[currentNestedStepIndex + 1];
							nextProgress =
								this.defaultProgress[currentNestedStepIndex + 1];
						}

						this.setState({
							currentNestedStepIndex: currentNestedStepIndex + 1,
							currentChapterIndex: nextProgress,
							currentTitle: nextTaskTitle,
						});
						setTimeout(() => {
							window.scrollTo(0, 0);
						});
					}
				}
			});
		} else {
			if (renderRemainingTasks.length) {
				nextTaskTitle = renderRemainingTitles[currentNestedStepIndex];
				nextProgress = renderRemainingProgressIndex[currentNestedStepIndex];
			} else {
				nextTaskTitle = this.defaultTitles[currentNestedStepIndex + 1];
				nextProgress = this.defaultProgress[currentNestedStepIndex + 1];
			}

			this.setState({
				currentNestedStepIndex: currentNestedStepIndex + 1,
				currentChapterIndex: nextProgress,
				currentTitle: nextTaskTitle,
			});
			setTimeout(() => {
				window.scrollTo(0, 0);
			});
		}
	}

	submitTestResultsForPhase2(results, subTest) {
		const { currentNestedStepIndex, tasksToContinue } = this.state;
		let nextTaskTitle;
		let nextProgress;

		if (
			currentNestedStepIndex > 0 &&
			currentNestedStepIndex < 7 &&
			!subTest.isAvoid &&
			!subTest.isLast &&
			isInProduction
		) {
			submitAnswers(results, subTest, this.props.selectedSession, this.state.rewardPoints).then((res) => {
				if (res.ok) {
					const isLastStep =
						currentNestedStepIndex === this.defaultNestedSteps.length - 1;
					if (isLastStep) {
					} else {
						if (renderRemainingTasks.length) {
							nextTaskTitle =
								renderRemainingTitles[currentNestedStepIndex];
							nextProgress =
								renderRemainingProgressIndex[
									currentNestedStepIndex
								];
						} else {
							nextTaskTitle =
								this.defaultTitles[currentNestedStepIndex + 1];
							nextProgress =
								this.defaultProgress[currentNestedStepIndex + 1];
						}

						this.setState({
							currentNestedStepIndex: currentNestedStepIndex + 1,
							currentChapterIndex: nextProgress,
							currentTitle: nextTaskTitle,
						});
						setTimeout(() => {
							window.scrollTo(0, 0);
						});
					}
				}
			});
		} else {
			if (renderRemainingTasks.length) {
				nextTaskTitle = renderRemainingTitles[currentNestedStepIndex];
				nextProgress = renderRemainingProgressIndex[currentNestedStepIndex];
			} else {
				nextTaskTitle = this.defaultTitles[currentNestedStepIndex + 1];
				nextProgress = this.defaultProgress[currentNestedStepIndex + 1];
			}

			this.setState({
				currentNestedStepIndex: currentNestedStepIndex + 1,
				currentChapterIndex: nextProgress,
				currentTitle: nextTaskTitle,
			});
			setTimeout(() => {
				window.scrollTo(0, 0);
			});
		}
	}

	submitTestResultsForPhase3(results, subTest) {
		const { currentNestedStepIndex, tasksToContinue } = this.state;
		let nextTaskTitle;
		let nextProgress;

		if (
			currentNestedStepIndex > 0 &&
			currentNestedStepIndex < 7 &&
			!subTest.isAvoid &&
			!subTest.isLast &&
			isInProduction
		) {
			submitAnswers(results, subTest, this.props.selectedSession, this.state.rewardPoints).then((res) => {
				if (res.ok) {
					const isLastStep =
						currentNestedStepIndex === this.defaultNestedSteps.length - 1;
					if (isLastStep) {
					} else {
						if (renderRemainingTasks.length) {
							nextTaskTitle =
								renderRemainingTitles[currentNestedStepIndex];
							nextProgress =
								renderRemainingProgressIndex[
									currentNestedStepIndex
								];
						} else {
							nextTaskTitle =
								this.defaultTitles[currentNestedStepIndex + 1];
							nextProgress =
								this.defaultProgress[currentNestedStepIndex + 1];
						}

						this.setState({
							currentNestedStepIndex: currentNestedStepIndex + 1,
							currentChapterIndex: nextProgress,
							currentTitle: nextTaskTitle,
						});
						setTimeout(() => {
							window.scrollTo(0, 0);
						});
					}
				}
			});
		} else {
			if (renderRemainingTasks.length) {
				nextTaskTitle = renderRemainingTitles[currentNestedStepIndex];
				nextProgress = renderRemainingProgressIndex[currentNestedStepIndex];
			} else {
				nextTaskTitle = this.defaultTitles[currentNestedStepIndex + 1];
				nextProgress = this.defaultProgress[currentNestedStepIndex + 1];
			}

			this.setState({
				currentNestedStepIndex: currentNestedStepIndex + 1,
				currentChapterIndex: nextProgress,
				currentTitle: nextTaskTitle,
			});
			setTimeout(() => {
				window.scrollTo(0, 0);
			});
		}
	}

	render() {
		const { selectedSession } = this.props;
		const { isSuperTeacher } = this.props;
		const { currentNestedStepIndex } = this.state;
		if (renderRemainingTasks.length) {
			var CurrentNestedStep = renderRemainingTasks[currentNestedStepIndex];
		} else {
			var CurrentNestedStep = this.defaultNestedSteps[currentNestedStepIndex];
		}

		return (
			<div className='rw-pageWrapper_component'>
				{isSuperTeacher && !isInProduction && (
					<div className='environmentBanner'>
						<p className='rw-print-instruction-for-testers'>
							<span>
								<b> NB: </b>
							</span>
							<span>
								<em>(Updated At: 26-06-2023 :: 14:31)</em>{' '}
							</span>{' '}
							<span>
								<b>You are in a Testing Envioronment. </b>
							</span>
							Voiceovers for 88, 89, and 90 not yet provided.
						</p>
					</div>
				)}
				{Boolean(this.state.rewardPoints) && (
					<div className='gamePlay'>
						<div className='rw-credit-image'>
							<img
								className='rw-RC-dragon-img__small'
								src={this.state.currentImg}
							/>
						</div>
						<div className={this.state.creditScoreClass}>
							<p>{this.state.rewardPoints}</p>
						</div>
					</div>
				)}
				<div className='tilte-and-conter'>
					<>
						<div className='task-stage-wrapper'>
							<div className='task-stage-indicator'>
								<p>{`${this.state.currentChapterIndex} av ${
									this.defaultNestedSteps.length + 2
								}`}</p>
							</div>
						</div>
						<div>
							<h1 className='rw-page-title'>{`${this.state.currentTitle}`}</h1>
						</div>
					</>
				</div>
				<CurrentNestedStep
					onNextStep={this.handleNextNestedStep}
					onChangeProgressCounter={this.updateProgressCounter}
					selectedSession={selectedSession}
					totalScore={this.state.totalCorrect}
					rewardPoints={this.state.rewardPoints}
					totalMistakes={this.state.totalMistakes}
					flipCoin={this.flipCoin}
					recordMistake={this.recordMistake}
					streak={this.state.streak}
					threshold={this.state.threshold}
					achievedPoints={this.state.achievedPoints}
					isSuperTeacher={isSuperTeacher}
				/>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	const { session } = state;
	return {
		session,
	};
};
export default connect(mapStateToProps)(ReadingComprehension);
