import React from 'react';
import { connect } from 'react-redux';
import TimerCount from '../../../TimerCount';
import { updateObject } from '../../../../utils/reducerUtils';
import * as data from '../RhymingWords/data/no-data';
import introAudio from '../../../../assets/sounds/Rimord-intro.mp3';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
import AudioPlayer from '../../../AudioPlayer';
import './styles.scss';

const getallRhymingWords = (session) => {
	const indexValue = `session${session}`;
	const dataItems = { allWords: data[indexValue][0].wordsArray, mainAudio: data[indexValue][0].introAudio };
	return dataItems;
};
let currentCounter = 0;
let resultsArray = [];
let allowNextButtonToAppear = false;
const resultsGloabl = [];
class RhymingWords extends React.Component {
	constructor(props) {
		super(props);

		this.testDataObject = getallRhymingWords(this.props.selectedSession);
		this.testData = this.testDataObject.allWords;
		this.audio = this.testDataObject.mainAudio;
		const results = {};
		let pseudoResults = {};
		for (let i = 0; i < this.testData.length; i++) {
			let id = i + 1;
			results[id] = 1;
		}
		for (let i = 0; i < this.testData.length; i++) {
			const allwords = this.testData[i].allWords;

			for (let j = 0; j < allwords.length; j++) {
				let id = j + 1;
				pseudoResults[id] = '';
			}
			resultsArray.push(pseudoResults);
		}
		this.state = {
			allWords: this.testData,
			results,
			timeRanOut: false,
			resultsArray,
			renderNext: false,
		};
	}
	updateResults(word, status) {
		resultsGloabl.push({ chosenWord: word, status });
	}

	render() {
		const { allWords, results } = this.state;
		const allQuestionsAnswered = Object.keys(results).every((key) => results[key]);
		const isNextButtonVisible = allQuestionsAnswered;

		const { onNextStep } = this.props;
		let audioPlayerRef;

		return (
			<div>
				<div className='rw-lp-intro'>
					<div className='rw-infobar'>
						<TimerCount
							duration={130}
							onComplete={() => {
								this.setState({
									timeRanOut: true,
								});
							}}
						/>
					</div>
					<div className='rw-intro-text'>
						<p>
							<AudioPlayer
								autoPlay
								inline
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								src={allWords[currentCounter].sound}
							/>
							Rimord: hvilket ord rimer ikke? (Trykk)
						</p>
					</div>

					{allWords.map((singleWordInstance, mainIndex) => {
						const { allWords } = singleWordInstance;

						return (
							<div
								key={`rw-lk-para-${mainIndex}`}
								className='rw-rhyme-paragraph'
							>
								<div
									key={`rw-lk-${mainIndex}`}
									className='rw-rhymetext-taskWrapper'
								>
									{allWords.map((single, index) => {
										let word =
											single.word +
											'      ';

										if (
											mainIndex - 1 <
											currentCounter
										) {
											return (
												<span
													className={
														allWords[
															index
														]
															.isCorrect &&
														allWords[
															index
														]
															.isAnswered
															? 'rw-rhymetext-taskWrapper__correct'
															: allWords[
																	index
															  ]
																	.isAnswered
															? 'rw-rhymetext-taskWrapper__wrong'
															: 'rw-rhymetext-taskWrapper__task'
													}
													id={
														index
													}
													key={`index-${index}`}
													onClick={() => {
														if (
															allWords[
																index
															]
																.isCorrect &&
															!allWords[
																index
															]
																.isAnswered
														) {
															this.props.flipCoin();
															this.updateResults(
																single.word,
																{
																	isCorrect: true,
																}
															);
															if (
																currentCounter <
																this
																	.state
																	.allWords
																	.length -
																	1
															) {
																currentCounter =
																	currentCounter +
																	1;
															} else {
																allowNextButtonToAppear = true;
															}
														}
														this.testData[
															mainIndex
														].allWords[
															index
														].isAnswered = true;

														this.setState(
															{
																allWords: this
																	.testData,
																renderNext: allowNextButtonToAppear,
															}
														);
														if (
															!allWords[
																index
															]
																.isCorrect &&
															allWords[
																index
															]
																.isAnswered
														) {
															this.updateResults(
																single.word,
																{
																	isCorrect: false,
																}
															);
														}
													}}
												>
													<p>
														{' '}
														{`${word} `}
													</p>
												</span>
											);
										}
									})}
								</div>
							</div>
						);
					})}
				</div>

				<br></br>
				{(this.state.timeRanOut || this.state.renderNext || this.props.isSuperTeacher) && (
					<div className='rw-next-btn'>
						<button
							className='rw-btn bg-primary'
							onClick={() =>
								onNextStep(
									resultsGloabl,
									ALL_SUBTESTS.RHYMINGWORDS
								)
							}
						>
							{'Neste'}
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default RhymingWords;
