export const session18 = [
	{
		precedingText: 'Hva blir igjen når du tar bort',
		leadingText: ' fra ordet ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>knep</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> p</span>,
	},
	{
		precedingText: 'Hva blir igjen når du tar bort',
		leadingText: ' fra ordet ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>banker</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> b</span>,
	},
	{
		precedingText: 'Hva blir igjen når du tar bort',
		leadingText: ' fra ordet ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>roper</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ro</span>,
	},
	{
		precedingText: 'Hva blir igjen når du tar bort',
		leadingText: ' fra ordet ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>slukker</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>dagen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> d med m</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>feire</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mange</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> m med f</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kom</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> k med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>før</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med s</span>,
	},
];
export const session19 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>d</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> dyr</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> fugler</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>n</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> nord</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> finne</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>nær</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> nærmer</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mat</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> m med f</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>finne</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med v</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>som</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s med k</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mat</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> m med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>viktig</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med r</span>,
	},
];
export const session20 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> flokk</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sliten</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>t</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> trekker</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lige</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sørlige</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>og</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> også</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>første</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med b</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>tar</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> t med f</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>bytter</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> b med h</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mindre</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> m med h</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sommer</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s med k</span>,
	},
];
export const session21 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> stor</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>gj</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> gjøre</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>t</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> trygg</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>si</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> siden</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du setter',
		leadingText: ' foran ordet ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>trygg</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du setter',
		leadingText: ' foran ordet ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>fred</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du setter',
		leadingText: ' foran ordet ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>venner</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kalle</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> k med f</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>land</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ligger</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med p</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>venner</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med p</span>,
	},
];
export const session22 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>vak</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> vaksine</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lig</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> farlig</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>k</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> syk</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> frisk</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>være</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mange</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> m med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>måter</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> m med fl</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>viktig</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med r</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>skade</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sk med b</span>,
	},
];
export const session23 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>på</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> påvirker</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>skinn</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> solskinn</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>v</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> varmen</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>u</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ulik</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>st</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> storm</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>solen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s med kj</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>faller</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med b</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>skyer</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sk med b</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>faller</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med k</span>,
	},
];
export const session24 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>n</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> nord</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sol</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> solfylte</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mer</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sommer</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ak</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> akse</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ge</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> farge</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lange</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med m</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sommer</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s med k</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>heller</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> h med kj</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lenger</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>bladene</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> bl med sk</span>,
	},
];
export const session25 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>et</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> reddet</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lig</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> virkelig</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>et</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> flyet</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>t</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> trodde</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kaien</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> k med h</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>klem</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> k med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>bakt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> b med v</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>dro</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> d med b</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>falt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med s</span>,
	},
];
export const session26 = [
	{
		precedingText: <span>{'Hva blir igjen av ordet'}</span>,
		leadingText: <span>{' når du tar bort '}</span>,
		leadingWord: <span style={{ color: 'black' }}>{' pr'}</span>,
		precedingWord: <span>{' prøvd '}</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span style={{ color: 'orange' }}>{' si'}</span>,
		precedingWord: <span className='rw-pw-preWord'> siden</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span style={{ color: 'red', fontWeight: 'bold' }}>{' t'}</span>,
		precedingWord: <span className='rw-pw-preWord'> tau</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span style={{ color: 'magenta' }}>{' bau'}</span>,
		precedingWord: <span className='rw-pw-preWord'> baufilen</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>suppe</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> maursuppe</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>magen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> m med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>dau</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> d med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>gjør</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> gj med d</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>pigger</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> p med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lenge</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med h</span>,
	},
];
export const session27 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>t</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> trodde</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>b</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ble</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>bal</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> balkongen</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>gen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> dragen</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>vet</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> veivet</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sang</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s med t</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>neiet</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> n med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>halen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> h med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>pustet</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> p med r</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>hørt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> h med kj</span>,
	},
];
export const session28 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>m</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ham</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> forbi</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ø</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ørene</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>er</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> roper</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>t</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> liten</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>den</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> mad med mt</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>finner</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med v</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>har</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> h med v</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>hun</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mann</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
];
export const session29 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>u</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ulike</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> minnes</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>fran</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> safran</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> halm</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> land</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>land</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>nisser</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> n med t</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>legger</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med v</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>minnes</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> m med f</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>får</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> å med a</span>,
	},
];
export const session30 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>jul</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> julaften</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>b</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> brev</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> vårt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> pølser</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> dra</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kaller</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> k med f</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kull</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> k med r</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sender</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s med v</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kaller</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med t</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>venner</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med p</span>,
	},
];
export const session31 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>g</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> grønne</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mat</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sjømat</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> frukt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> blader</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mer</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sommer</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>feirer</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>bunn</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> b med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>toppen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> t med k</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>tiden</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> t med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>husene</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> h med l</span>,
	},
];
export const session32 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kristen</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>b</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ble</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sur</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>m</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> gamle</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>h</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> het</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sur</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>passer</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> p med k</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>fest</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>neste</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med i</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>like</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> i med u</span>,
	},
];
export const session33 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>tiden</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> høytiden</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> slutte</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>u</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ulykke</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kroppen</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>t</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> brukt</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>festen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med r</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>søte</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> t med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>pynter</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> y med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>stort</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> o med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>dager</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med i</span>,
	},
];
export const session34 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>g</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> gull</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> full</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>n</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> han</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> snakke</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>er</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> lukter</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>når</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> n med h</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>rene</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> r med p</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>hoster</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> h med k</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>jeg</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> j med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>byen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> y med u</span>,
	},
];
export const session35 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lånt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> tyvlånt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sp</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> spratt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> smalt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>b</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> brenne</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>b</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> brant</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>rett</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> r med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>satt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s med r</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ville</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med p</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>gul</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> g med k</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sagt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s med l</span>,
	},
];
export const session36 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>d</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> drømmer</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> senga</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> klar</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>d</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> driver</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> støv</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>natten</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> n med sk</span>,
	},

	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>drage</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> dr med m</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>prikker</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> p med d</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mann</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>spyr</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> y med a</span>,
	},
];
export const session37 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> fri</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> frem</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ut</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> utflukt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>g</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> glasset</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> flire</span>,
	},

	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>hekser</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> h med l </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>drage</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> dr med h </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>frem</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med k </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sprakk</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sp med k</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>flau</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> au med øy</span>,
	},
];
export const session38 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sol</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> solsystem</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>be</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> bestå</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mer</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> Merkur</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>t</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> trekker</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sterk</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>form</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med n</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mye</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> m med n</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>stjerne</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> st med f </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>vårt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> å med æ</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>tau</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> au med øy</span>,
	},
];
export const session39 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>planeter</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> steinplaneter</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>g</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> groper</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>b</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> blitt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>st</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> steiner</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> støvete</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>full</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u med ø</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>blitt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> i med å</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>snurrer</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s med k</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kaller</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med t </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>etter</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> t med l</span>,
	},
];
export const session40 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sa</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> Saturn</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ringer</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>u</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ulike</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> fart</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sterke</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>gass</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> g med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>for</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> o med ø</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>stein</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> st med b</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>har</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med å</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>meste</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> m med fl</span>,
	},
];
export const session41 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> små</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>k</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kalt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>k</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kalver</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>v</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> velg</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>dyr</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> rovdyr</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>busker</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> b med h</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>hører</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> h med kj</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kalver</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> k med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>blir</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> i med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mot</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> o med a</span>,
	},
];

export const session42 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> felle</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>a</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> spare</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>v</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> varm</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>v</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> våren</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>v</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> veier</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>vokse</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med b</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>felle</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med t</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>geviret</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> i med æ</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>våren</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> å med æ</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kilo</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med n</span>,
	},
];

export const session43 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>tyr</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> betyr</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>be</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> beskytter</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> selv</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>k</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> krype</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>d</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> dyr</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lever</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med b</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>gruppe</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> gr med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>frukt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>hule</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>veie</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ei med ai</span>,
	},
];

export const session44 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>dyr</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> rovdyr</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ver</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> lever</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> flokk</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>u</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> uler</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>st</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> minst</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lever</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sitt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> i med e</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>feller</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ll med tt</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>brun</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u med y</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>vokser</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med b</span>,
	},
];

export const session45 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ut</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> utmerket</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>e</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ekorn</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>n</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ørner</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>hør</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> hørsel</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> fleste</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>den</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sammen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med ø</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>både</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> å med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>stort</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> o med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>blir</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> i med a</span>,
	},
];

export const session46 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ur</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> urfolk</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>rådet</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> området</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> tromme</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> snakker</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>a</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> samiske</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>gamle</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> g med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>bruker</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>norsk</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> n med t</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>pynte</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> y med a</span>,
	},
];

export const session47 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>folk</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> urfolk</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>u</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ulike</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> flytter</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> veksler</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>k</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> skoler</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sammen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>reiste</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> r med h</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>bodde</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> b med r</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>like</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> i med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>vare</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> r med s</span>,
	},
];

export const session48 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> samen</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>t</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sterk</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>n</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> uten</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> lære</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>j</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> jord</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>viktig</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med r</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kjøpe</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kj med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>rett</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med å</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>slipp</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> i med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lære</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> æ med u</span>,
	},
];

export const session49 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kul</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kultur</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ble</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ringen</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>n</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> mange</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sa</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> samer</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>første</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med b</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kofter</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>synger</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> y med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ble</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med ø</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sammen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
];

export const session50 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sette</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> bosette</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>k</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kalt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>v</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> levde</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>e</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> laget</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> brukt</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>reise</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> r med h</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>brukte</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>festet</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ble</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> b med sk</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>små</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> m med l</span>,
	},
];

export const session51 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>u</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> hauger</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> tre</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> flytte</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> skarpe</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>t</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> brukte</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>dag</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med e</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kunne</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>typer</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> y med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ned</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med ø</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>fiske</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> i med u</span>,
	},
];

export const session52 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> frukt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sanke</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sette</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> fortsette</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> flytte</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>k</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> bruker</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>nøtter</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ø med y</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>bål</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> å med i</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>potter</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> o med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kunne</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>slutte</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> t med k</span>,
	},
];
export const session53 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>u</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ulike</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>red</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> redskap</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>me</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> metall</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>v</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> våpen</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>b</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> blande</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>våpen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med d</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>til</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> t med v</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>støpt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> st med d</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>smelt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>tinn</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> i med a</span>,
	},
];
export const session54 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> form</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>m</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> mat</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>d</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> dyr</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> store</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>u</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ulike</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>feste</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>type</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> y med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>panner</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med i</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mat</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med o</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>skaffe</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
];

export const session55 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>opp</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> oppfinne</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sk</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> skrive</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ing</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> skriving</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>be</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> berømt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>n</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sanker</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kalt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med i</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>folk</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> o med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>levde</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med o</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>tinn</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> i med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lage</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med y</span>,
	},
];

export const session56 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>bruk</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> jordbruk</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>b</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ble</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> slapp</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>bo</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> bofaste</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>v</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> varmen</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>dyrene</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> y med ø</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>jakt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> j med b</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>spor</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> o med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>bodde</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> b med r</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>skaffe</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
];

export const session57 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>v</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> våpen</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>me</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> metall</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>et</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> laget</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>n</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> mynter</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> selger</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kjøpe</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kj med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>mel</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med i</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>del</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>knuste</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u med i</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>jakte</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> j med b</span>,
	},
];

export const session58 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>n</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> nord</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>d</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> bygde</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> fart</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>v</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> vannet</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>t</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> stjal</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>høy</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> øy med ai</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>seile</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ei med øy</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>land</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>bønder</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ø med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>smykker</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> y med e</span>,
	},
];

export const session59 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>g</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ugler</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>g</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> gribber</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>n</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> ørner</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>r</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kroker</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ut</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> utmerkede</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ørn</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> n med e</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>svært</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> æ med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>syn</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>artene</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> t med k</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>rede</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> e med ø</span>,
	},
];
export const session60 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>i</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kaniner</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>d</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kilde</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>b</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> blitt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>i</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> varier</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> blitt</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kan</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>grunn</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> u med i</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lar</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>graver</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ørken</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> k med n</span>,
	},
];

export const session61 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>u</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> uvanlige</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> slik</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>k</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> bruker</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>i</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kilo</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>k</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> kalver</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>slik</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> s med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lever</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>feller</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> f med t</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>hannene</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>simle</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> i med o</span>,
	},
];

export const session62 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> land</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>l</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sel</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sover</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>an</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> angripe</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>n</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> lang</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>sover</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>lang</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> l med t</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>fast</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> a med e</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kalt</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> k med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>føder</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> d med l</span>,
	},
];

export const session63 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ger</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sørger</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>g</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> følger</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>br</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> bruker</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>f</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> fleste</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>h</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> hindre</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>skade</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> sk med b</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>nød</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> n med r</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>klær</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> æ med ø</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>ran</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> n med r</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>våpen</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> v med s</span>,
	},
];

export const session64 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>t</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> lært</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>s</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> hansker</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>k</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> bruker</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>m</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> flom</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>u</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> utøve</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>kommer</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> k med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>støvler</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> st med h</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>dette</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> t med k</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>rykke</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> r med d</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: (
			<span>
				<span className='rw-pw-preWord'>båter</span>
			</span>
		),
		precedingWord: <span className='rw-pw-preWord'> å med i</span>,
	},
];

export const session65 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> sør</span>,
		precedingWord: <span className='rw-pw-preWord'> frisør</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> skj</span>,
		precedingWord: <span className='rw-pw-preWord'> skjegg</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> krøller</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> farger</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> k</span>,
		precedingWord: <span className='rw-pw-preWord'> kvinner</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> klipper</span>,
		precedingWord: <span className='rw-pw-preWord'> k med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> menn</span>,
		precedingWord: <span className='rw-pw-preWord'> e med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> trimmer</span>,
		precedingWord: <span className='rw-pw-preWord'> i med o</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> krøllete</span>,
		precedingWord: <span className='rw-pw-preWord'> kr med b</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> klipper</span>,
		precedingWord: <span className='rw-pw-preWord'> i med a</span>,
	},
];

export const session66 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> k</span>,
		precedingWord: <span className='rw-pw-preWord'> kvinner</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> g</span>,
		precedingWord: <span className='rw-pw-preWord'> mange</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> helse</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> t</span>,
		precedingWord: <span className='rw-pw-preWord'> trøster</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> t</span>,
		precedingWord: <span className='rw-pw-preWord'> står</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> typer</span>,
		precedingWord: <span className='rw-pw-preWord'> t med h</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> stiller</span>,
		precedingWord: <span className='rw-pw-preWord'> i med e</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> bytte</span>,
		precedingWord: <span className='rw-pw-preWord'> y med ø</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> nåler</span>,
		precedingWord: <span className='rw-pw-preWord'> å med ø</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> små</span>,
		precedingWord: <span className='rw-pw-preWord'> m med l</span>,
	},
];

export const session67 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> dyr</span>,
		precedingWord: <span className='rw-pw-preWord'> dyrlege</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> d</span>,
		precedingWord: <span className='rw-pw-preWord'> dyr</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> friske</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> føre</span>,
		precedingWord: <span className='rw-pw-preWord'> utføre</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> l</span>,
		precedingWord: <span className='rw-pw-preWord'> holde</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> leger</span>,
		precedingWord: <span className='rw-pw-preWord'> g med v</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> leger</span>,
		precedingWord: <span className='rw-pw-preWord'> e med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> noen</span>,
		precedingWord: <span className='rw-pw-preWord'> n med d</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> bruke</span>,
		precedingWord: <span className='rw-pw-preWord'> k med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> bli</span>,
		precedingWord: <span className='rw-pw-preWord'> i med ø</span>,
	},
];

export const session68 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> n</span>,
		precedingWord: <span className='rw-pw-preWord'> mange</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> til</span>,
		precedingWord: <span className='rw-pw-preWord'> tilsyn</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> an</span>,
		precedingWord: <span className='rw-pw-preWord'> ansvar</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> p</span>,
		precedingWord: <span className='rw-pw-preWord'> visper</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> uni</span>,
		precedingWord: <span className='rw-pw-preWord'> uniform</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> jobber</span>,
		precedingWord: <span className='rw-pw-preWord'> b med g</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> kokker</span>,
		precedingWord: <span className='rw-pw-preWord'> k med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> gryter</span>,
		precedingWord: <span className='rw-pw-preWord'> y med å</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> typer</span>,
		precedingWord: <span className='rw-pw-preWord'> y med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> panner</span>,
		precedingWord: <span className='rw-pw-preWord'> a med e</span>,
	},
];

export const session69 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> av</span>,
		precedingWord: <span className='rw-pw-preWord'> avløp</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> k</span>,
		precedingWord: <span className='rw-pw-preWord'> vasker</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> n</span>,
		precedingWord: <span className='rw-pw-preWord'> nøye</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> b</span>,
		precedingWord: <span className='rw-pw-preWord'> briller</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> st</span>,
		precedingWord: <span className='rw-pw-preWord'> styrke</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> vann</span>,
		precedingWord: <span className='rw-pw-preWord'> v med t</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> føre</span>,
		precedingWord: <span className='rw-pw-preWord'> r med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> sikker</span>,
		precedingWord: <span className='rw-pw-preWord'> s med h</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> bade</span>,
		precedingWord: <span className='rw-pw-preWord'> a med å</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> bort</span>,
		precedingWord: <span className='rw-pw-preWord'> o med a</span>,
	},
];

export const session70 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> trenger</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> bil</span>,
		precedingWord: <span className='rw-pw-preWord'> lastebil</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> l</span>,
		precedingWord: <span className='rw-pw-preWord'> bilene</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> n</span>,
		precedingWord: <span className='rw-pw-preWord'> henter</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> t</span>,
		precedingWord: <span className='rw-pw-preWord'> trygge</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> lever</span>,
		precedingWord: <span className='rw-pw-preWord'> v med k</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> lange</span>,
		precedingWord: <span className='rw-pw-preWord'> l med f</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> kalle</span>,
		precedingWord: <span className='rw-pw-preWord'> a med ø</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> ansvar</span>,
		precedingWord: <span className='rw-pw-preWord'> an med for</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> ofte</span>,
		precedingWord: <span className='rw-pw-preWord'> t med r</span>,
	},
];

export const session71 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> spiller</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> k</span>,
		precedingWord: <span className='rw-pw-preWord'> kvinner</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> ta</span>,
		precedingWord: <span className='rw-pw-preWord'> delta</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> v</span>,
		precedingWord: <span className='rw-pw-preWord'> velg</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> fram</span>,
		precedingWord: <span className='rw-pw-preWord'> framføre</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> menn</span>,
		precedingWord: <span className='rw-pw-preWord'> e med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> gutter</span>,
		precedingWord: <span className='rw-pw-preWord'> u med i</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> del</span>,
		precedingWord: <span className='rw-pw-preWord'> e med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> velge</span>,
		precedingWord: <span className='rw-pw-preWord'> v med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> stemmene</span>,
		precedingWord: <span className='rw-pw-preWord'> e med a</span>,
	},
];

export const session72 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> f</span>,
		precedingWord: <span className='rw-pw-preWord'> fører</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> l</span>,
		precedingWord: <span className='rw-pw-preWord'> flyr</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> l</span>,
		precedingWord: <span className='rw-pw-preWord'> holde</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> trygge</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> j</span>,
		precedingWord: <span className='rw-pw-preWord'> sjekker</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> mindre</span>,
		precedingWord: <span className='rw-pw-preWord'> m med h</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> føre</span>,
		precedingWord: <span className='rw-pw-preWord'> f med kj</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> noen</span>,
		precedingWord: <span className='rw-pw-preWord'> n med sk</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> frakt</span>,
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> timer</span>,
		precedingWord: <span className='rw-pw-preWord'> t med l</span>,
	},
];

export const session73 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> t</span>,
		precedingWord: <span className='rw-pw-preWord'> faste</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> an</span>,
		precedingWord: <span className='rw-pw-preWord'> foran</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> p</span>,
		precedingWord: <span className='rw-pw-preWord'> kamp</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> fast</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> menn</span>,
		precedingWord: <span className='rw-pw-preWord'> mennesker</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> tropper</span>,
		precedingWord: <span className='rw-pw-preWord'> o med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> kabin</span>,
		precedingWord: <span className='rw-pw-preWord'> b med n</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> vinger</span>,
		precedingWord: <span className='rw-pw-preWord'> v med f</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> navn</span>,
		precedingWord: <span className='rw-pw-preWord'> n med s </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> last</span>,
		precedingWord: <span className='rw-pw-preWord'> l med f</span>,
	},
];

export const session74 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> k</span>,
		precedingWord: <span className='rw-pw-preWord'> krever</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> l</span>,
		precedingWord: <span className='rw-pw-preWord'> hjelm</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> br</span>,
		precedingWord: <span className='rw-pw-preWord'> bruke</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> le</span>,
		precedingWord: <span className='rw-pw-preWord'> handle</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> k</span>,
		precedingWord: <span className='rw-pw-preWord'> rask</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> snø</span>,
		precedingWord: <span className='rw-pw-preWord'> ø med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> veier</span>,
		precedingWord: <span className='rw-pw-preWord'> ei med ai</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> gjøre</span>,
		precedingWord: <span className='rw-pw-preWord'> gj med h</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> mange</span>,
		precedingWord: <span className='rw-pw-preWord'> m med l </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> fører</span>,
		precedingWord: <span className='rw-pw-preWord'> f med d</span>,
	},
];

export const session75 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> v</span>,
		precedingWord: <span className='rw-pw-preWord'> skyver</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> p</span>,
		precedingWord: <span className='rw-pw-preWord'> plast</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> p</span>,
		precedingWord: <span className='rw-pw-preWord'> skip</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> ing</span>,
		precedingWord: <span className='rw-pw-preWord'> tauing</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> me</span>,
		precedingWord: <span className='rw-pw-preWord'> metall</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> båt</span>,
		precedingWord: <span className='rw-pw-preWord'> å med i</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> laget</span>,
		precedingWord: <span className='rw-pw-preWord'> l med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> åre</span>,
		precedingWord: <span className='rw-pw-preWord'> r med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> måter</span>,
		precedingWord: <span className='rw-pw-preWord'> m med b </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> luke</span>,
		precedingWord: <span className='rw-pw-preWord'> k med r</span>,
	},
];

export const session76 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> f</span>,
		precedingWord: <span className='rw-pw-preWord'> frem</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> t</span>,
		precedingWord: <span className='rw-pw-preWord'> trekke</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> k</span>,
		precedingWord: <span className='rw-pw-preWord'> kalle</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> u</span>,
		precedingWord: <span className='rw-pw-preWord'> ulike</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> bil</span>,
		precedingWord: <span className='rw-pw-preWord'> trekkbil</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> kalle</span>,
		precedingWord: <span className='rw-pw-preWord'> l med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> frakt</span>,
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> trekke</span>,
		precedingWord: <span className='rw-pw-preWord'> k med f</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> vippe</span>,
		precedingWord: <span className='rw-pw-preWord'> v med t </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> side</span>,
		precedingWord: <span className='rw-pw-preWord'> s med r</span>,
	},
];

export const session77 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> t</span>,
		precedingWord: <span className='rw-pw-preWord'> tog</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> mo</span>,
		precedingWord: <span className='rw-pw-preWord'> motor</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> gods</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> seter</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> li</span>,
		precedingWord: <span className='rw-pw-preWord'> ulike</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> lukke</span>,
		precedingWord: <span className='rw-pw-preWord'> u med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> strøm</span>,
		precedingWord: <span className='rw-pw-preWord'> ø med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> fort</span>,
		precedingWord: <span className='rw-pw-preWord'> o med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> type</span>,
		precedingWord: <span className='rw-pw-preWord'> y med a </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> lasten</span>,
		precedingWord: <span className='rw-pw-preWord'> a med y</span>,
	},
];

export const session78 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> u</span>,
		precedingWord: <span className='rw-pw-preWord'> ulykke</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> d</span>,
		precedingWord: <span className='rw-pw-preWord'> dører</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> p</span>,
		precedingWord: <span className='rw-pw-preWord'> plass</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> l</span>,
		precedingWord: <span className='rw-pw-preWord'> plass</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> opp</span>,
		precedingWord: <span className='rw-pw-preWord'> opptil</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> bil</span>,
		precedingWord: <span className='rw-pw-preWord'> b med p</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> gasser</span>,
		precedingWord: <span className='rw-pw-preWord'> g med p</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> mange</span>,
		precedingWord: <span className='rw-pw-preWord'> m med f</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> både</span>,
		precedingWord: <span className='rw-pw-preWord'> å med a </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> måter</span>,
		precedingWord: <span className='rw-pw-preWord'> å med a</span>,
	},
];

export const session79 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> sete</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> k</span>,
		precedingWord: <span className='rw-pw-preWord'> skolen</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> n</span>,
		precedingWord: <span className='rw-pw-preWord'> henter</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> f</span>,
		precedingWord: <span className='rw-pw-preWord'> frakte</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> last</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> buss</span>,
		precedingWord: <span className='rw-pw-preWord'> u med a </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> frakt</span>,
		precedingWord: <span className='rw-pw-preWord'> a med u</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> slipper</span>,
		precedingWord: <span className='rw-pw-preWord'> p med k </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> typer</span>,
		precedingWord: <span className='rw-pw-preWord'> y med a </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> last</span>,
		precedingWord: <span className='rw-pw-preWord'> a med ø</span>,
	},
];

export const session80 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> or</span>,
		precedingWord: <span className='rw-pw-preWord'> traktor</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> te</span>,
		precedingWord: <span className='rw-pw-preWord'> sete</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> g</span>,
		precedingWord: <span className='rw-pw-preWord'> treg</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> h</span>,
		precedingWord: <span className='rw-pw-preWord'> høy</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> trenger</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> feste</span>,
		precedingWord: <span className='rw-pw-preWord'> e med a </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> hester</span>,
		precedingWord: <span className='rw-pw-preWord'> e med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> baller</span>,
		precedingWord: <span className='rw-pw-preWord'> a med i </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> høy</span>,
		precedingWord: <span className='rw-pw-preWord'> øy med ai </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> små</span>,
		precedingWord: <span className='rw-pw-preWord'> m med l</span>,
	},
];

export const session81 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> reist</span>,
		precedingWord: <span className='rw-pw-preWord'> oppreist</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> e</span>,
		precedingWord: <span className='rw-pw-preWord'> laget</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> l</span>,
		precedingWord: <span className='rw-pw-preWord'> holde</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> skarpe</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> slås</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> slik</span>,
		precedingWord: <span className='rw-pw-preWord'> i med u </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> rom</span>,
		precedingWord: <span className='rw-pw-preWord'> r med s</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> rom</span>,
		precedingWord: <span className='rw-pw-preWord'> m med s </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> bære</span>,
		precedingWord: <span className='rw-pw-preWord'> æ med a </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> sammen</span>,
		precedingWord: <span className='rw-pw-preWord'> s med r</span>,
	},
];

export const session82 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> vann</span>,
		precedingWord: <span className='rw-pw-preWord'> vanncross</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> sport</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> t</span>,
		precedingWord: <span className='rw-pw-preWord'> står</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> snø</span>,
		precedingWord: <span className='rw-pw-preWord'> snøskuter</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> b</span>,
		precedingWord: <span className='rw-pw-preWord'> belte</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> kulde</span>,
		precedingWord: <span className='rw-pw-preWord'> u med a </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> ski</span>,
		precedingWord: <span className='rw-pw-preWord'> i med y</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> frakt</span>,
		precedingWord: <span className='rw-pw-preWord'> a med u </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> reise</span>,
		precedingWord: <span className='rw-pw-preWord'> r med h </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> gress</span>,
		precedingWord: <span className='rw-pw-preWord'> e med ø</span>,
	},
];

export const session83 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> stang</span>,
		precedingWord: <span className='rw-pw-preWord'> flaggstang</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> hei</span>,
		precedingWord: <span className='rw-pw-preWord'> heiser</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> sko</span>,
		precedingWord: <span className='rw-pw-preWord'> skoler</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> f</span>,
		precedingWord: <span className='rw-pw-preWord'> vifte</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> t</span>,
		precedingWord: <span className='rw-pw-preWord'> vårt</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> pynte</span>,
		precedingWord: <span className='rw-pw-preWord'> y med a </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> synger</span>,
		precedingWord: <span className='rw-pw-preWord'> y med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> leker</span>,
		precedingWord: <span className='rw-pw-preWord'> k med s </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> setter</span>,
		precedingWord: <span className='rw-pw-preWord'> t med k </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> ofte</span>,
		precedingWord: <span className='rw-pw-preWord'> t med r</span>,
	},
];

export const session84 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> selv</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> be</span>,
		precedingWord: <span className='rw-pw-preWord'> bestemme</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> b</span>,
		precedingWord: <span className='rw-pw-preWord'> blitt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> dan</span>,
		precedingWord: <span className='rw-pw-preWord'> Danmark</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> v</span>,
		precedingWord: <span className='rw-pw-preWord'> ville</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> styrt</span>,
		precedingWord: <span className='rw-pw-preWord'> y med a </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> måtte</span>,
		precedingWord: <span className='rw-pw-preWord'> å med ø</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> lage</span>,
		precedingWord: <span className='rw-pw-preWord'> a med e </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> nå</span>,
		precedingWord: <span className='rw-pw-preWord'> å med y </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> blitt</span>,
		precedingWord: <span className='rw-pw-preWord'> i med å</span>,
	},
];

export const session85 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> års</span>,
		precedingWord: <span className='rw-pw-preWord'> årstider</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> l</span>,
		precedingWord: <span className='rw-pw-preWord'> planter</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> t</span>,
		precedingWord: <span className='rw-pw-preWord'> tror</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> ber</span>,
		precedingWord: <span className='rw-pw-preWord'> feber</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> h</span>,
		precedingWord: <span className='rw-pw-preWord'> høst</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> vinter</span>,
		precedingWord: <span className='rw-pw-preWord'> i med a </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> våren</span>,
		precedingWord: <span className='rw-pw-preWord'> å med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> gul</span>,
		precedingWord: <span className='rw-pw-preWord'> g med k </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> rekke</span>,
		precedingWord: <span className='rw-pw-preWord'> r med d </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> blitt</span>,
		precedingWord: <span className='rw-pw-preWord'> i med å</span>,
	},
];

export const session86 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> først</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> n</span>,
		precedingWord: <span className='rw-pw-preWord'> våkner</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> vintre</span>,
		precedingWord: <span className='rw-pw-preWord'> overvintre</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> sanke</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> starter</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> bol</span>,
		precedingWord: <span className='rw-pw-preWord'> o med å </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> være</span>,
		precedingWord: <span className='rw-pw-preWord'> æ med a</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> blir</span>,
		precedingWord: <span className='rw-pw-preWord'> i med a </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> hunner</span>,
		precedingWord: <span className='rw-pw-preWord'> u med a </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> fort</span>,
		precedingWord: <span className='rw-pw-preWord'> o med a</span>,
	},
];
export const session87 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> insekt</span>,
		precedingWord: <span className='rw-pw-preWord'> insekttype</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> trenger</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> g</span>,
		precedingWord: <span className='rw-pw-preWord'> trenger</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> for</span>,
		precedingWord: <span className='rw-pw-preWord'> forandre</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> vikler</span>,
		precedingWord: <span className='rw-pw-preWord'> utvikler</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> bodde</span>,
		precedingWord: <span className='rw-pw-preWord'> b med r </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> gang</span>,
		precedingWord: <span className='rw-pw-preWord'> g med l</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> frø</span>,
		precedingWord: <span className='rw-pw-preWord'> ø med i </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> pollen</span>,
		precedingWord: <span className='rw-pw-preWord'> o med i </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> lage</span>,
		precedingWord: <span className='rw-pw-preWord'> l med s</span>,
	},
];

export const session88 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> ne</span>,
		precedingWord: <span className='rw-pw-preWord'> årene</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> husker</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> hval</span>,
		precedingWord: <span className='rw-pw-preWord'> plasthval</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> del</span>,
		precedingWord: <span className='rw-pw-preWord'> deltar</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> dyr</span>,
		precedingWord: <span className='rw-pw-preWord'> y med ø </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> opp</span>,
		precedingWord: <span className='rw-pw-preWord'> p med s </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> vekk</span>,
		precedingWord: <span className='rw-pw-preWord'> v med s </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> grunn</span>,
		precedingWord: <span className='rw-pw-preWord'> u med ø </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> havner</span>,
		precedingWord: <span className='rw-pw-preWord'> h med s </span>,
	},
];

export const session89 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> n</span>,
		precedingWord: <span className='rw-pw-preWord'> når</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> gj</span>,
		precedingWord: <span className='rw-pw-preWord'> gjøre</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> p</span>,
		precedingWord: <span className='rw-pw-preWord'> plast</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> branner</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> ned</span>,
		precedingWord: <span className='rw-pw-preWord'> nedbrutt</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> fordi</span>,
		precedingWord: <span className='rw-pw-preWord'> d med b </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> lufte</span>,
		precedingWord: <span className='rw-pw-preWord'> k med f </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> bor</span>,
		precedingWord: <span className='rw-pw-preWord'> o med u </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> kvele</span>,
		precedingWord: <span className='rw-pw-preWord'> v med n </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> natt</span>,
		precedingWord: <span className='rw-pw-preWord'> y med a </span>,
	},
];

export const session90 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> n</span>,
		precedingWord: <span className='rw-pw-preWord'> nisen</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> hval</span>,
		precedingWord: <span className='rw-pw-preWord'> tannhval</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> u</span>,
		precedingWord: <span className='rw-pw-preWord'> uhell</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> av</span>,
		precedingWord: <span className='rw-pw-preWord'> avrunde</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> l</span>,
		precedingWord: <span className='rw-pw-preWord'> fleste</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> spiss</span>,
		precedingWord: <span className='rw-pw-preWord'> sp med kl </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> nise</span>,
		precedingWord: <span className='rw-pw-preWord'> i med e </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> når</span>,
		precedingWord: <span className='rw-pw-preWord'> n med h </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> hai</span>,
		precedingWord: <span className='rw-pw-preWord'> h med k </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> nepe</span>,
		precedingWord: <span className='rw-pw-preWord'> s med p </span>,
	},
];

export const session91 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> v</span>,
		precedingWord: <span className='rw-pw-preWord'> svelge</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> sterke</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> skarpe</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> tenner</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> en</span>,
		precedingWord: <span className='rw-pw-preWord'> enorm</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> hai</span>,
		precedingWord: <span className='rw-pw-preWord'> h med p </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> veie</span>,
		precedingWord: <span className='rw-pw-preWord'> ei med ai </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> jakter</span>,
		precedingWord: <span className='rw-pw-preWord'> t med k </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> puster</span>,
		precedingWord: <span className='rw-pw-preWord'> p med r </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> lever</span>,
		precedingWord: <span className='rw-pw-preWord'> l med r </span>,
	},
];

export const session92 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> finer</span>,
		precedingWord: <span className='rw-pw-preWord'> delfiner</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> u</span>,
		precedingWord: <span className='rw-pw-preWord'> uhell</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> h</span>,
		precedingWord: <span className='rw-pw-preWord'> hav</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> ste</span>,
		precedingWord: <span className='rw-pw-preWord'> minste</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> a</span>,
		precedingWord: <span className='rw-pw-preWord'> rosa</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> gråt</span>,
		precedingWord: <span className='rw-pw-preWord'> å med ø </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> vite</span>,
		precedingWord: <span className='rw-pw-preWord'> v med b </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> mest</span>,
		precedingWord: <span className='rw-pw-preWord'> m med gj </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> puste</span>,
		precedingWord: <span className='rw-pw-preWord'> p med r </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> finne</span>,
		precedingWord: <span className='rw-pw-preWord'> f med v </span>,
	},
];

export const session93 = [
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> s</span>,
		precedingWord: <span className='rw-pw-preWord'> slekt</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> rygg</span>,
		precedingWord: <span className='rw-pw-preWord'> ryggrad</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> r</span>,
		precedingWord: <span className='rw-pw-preWord'> kropper</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> unn</span>,
		precedingWord: <span className='rw-pw-preWord'> unnslippe</span>,
	},
	{
		precedingText: 'Hva blir igjen av ordet',
		leadingText: ' når du tar bort ',
		leadingWord: <span className='rw-pw-preWord'> t</span>,
		precedingWord: <span className='rw-pw-preWord'> biter</span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> slipper</span>,
		precedingWord: <span className='rw-pw-preWord'> s med k </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> biter</span>,
		precedingWord: <span className='rw-pw-preWord'> t med l </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> fort</span>,
		precedingWord: <span className='rw-pw-preWord'> f med l </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> skyter</span>,
		precedingWord: <span className='rw-pw-preWord'> y med øy </span>,
	},
	{
		precedingText: 'Hvilket ord får du når du bytter',
		leadingText: ' i ordet  ',
		leadingWord: <span className='rw-pw-preWord'> bytte</span>,
		precedingWord: <span className='rw-pw-preWord'> b med h </span>,
	},
];
