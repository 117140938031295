import ball from '../../../../../assets/Leksjon_2/leksjon2_badeball.png';
import pillow from '../../../../../assets/Leksjon_2/leksjon2_pute.png';
import skinnet from '../../../../../assets/Leksjon_2/leksjon2_saueskinn.png';

import dog from '../../../../../assets/Leksjon_0/leksjon0_storhund.png';
import biggerDog from '../../../../../assets/Leksjon_0/leksjon0_størrehund.png';
import biggestDog from '../../../../../assets/Leksjon_0/leksjon0_størsthund.png';

import egg from '../../../../../assets/Leksjon_3/leksjon3_egget.png';
import dinosaur from '../../../../../assets/Leksjon_3/leksjon3_dinosaur.png';

import angryFrog from '../../../../../assets/Leksjon_4/leksjon4_frosk_sint.png';
import angryFido from '../../../../../assets/Leksjon_4/leksjon4_fido_sintere.png';
import someonShouting from '../../../../../assets/Leksjon_4/leksjon4_jente_som_roper.png';

import sickGiraffe from '../../../../../assets/Leksjon_5/leksjon5_sjiraff_syk.png';
import sickerGiraffe from '../../../../../assets/Leksjon_5/leksjon5_sjiraff_sykere.png';
import sickestGiraffe from '../../../../../assets/Leksjon_5/leksjon5_sjiraff_sykest.png';

import lowBee from '../../../../../assets/Leksjon_6/leksjon6_lav_fe.png';
import lowerBee from '../../../../../assets/Leksjon_6/leksjon6_lavere_fe.png';
import lowestBee from '../../../../../assets/Leksjon_6/leksjon6_laveste_feen.png';

import bad from '../../../../../assets/Leksjon_7/leksjon7_ille.png';
import worse from '../../../../../assets/Leksjon_7/leksjon7_verre.png';
import worst from '../../../../../assets/Leksjon_7/leksjon7_verste.png';

import sweet from '../../../../../assets/Leksjon_8/leksjon8_søte.png';
import sweeter from '../../../../../assets/Leksjon_8/leksjon8_søtere.png';
import sweetest from '../../../../../assets/Leksjon_8/leksjon8_søteste.png';

import full from '../../../../../assets/Leksjon_9/leksjon9_full.png';
import fuller from '../../../../../assets/Leksjon_9/leksjon9_fullere.png';
import fullest from '../../../../../assets/Leksjon_9/leksjon9_fullest.png';

import long from '../../../../../assets/Leksjon_10/leksjon10_planet_langt_unna.png';
import longer from '../../../../../assets/Leksjon_10/leksjon10_planet_lengre_unna.png';
import longest from '../../../../../assets/Leksjon_10/leksjon10_planet_lengst_unna.png';

import nervous from '../../../../../assets/Leksjon_11/leksjon11_gutt_nervøs.png';
import moreNervous from '../../../../../assets/Leksjon_11/leksjon11_gutt_nervøsere.png';
import mostNervous from '../../../../../assets/Leksjon_11/leksjon11_nervøsest.png';

import big from '../../../../../assets/Leksjon_12/leksjon12_stor_pirat.png';
import bigger from '../../../../../assets/Leksjon_12/leksjon12_større_pirat.png';
import biggest from '../../../../../assets/Leksjon_12/leksjon12_største_piraten.png';

import strange from '../../../../../assets/Leksjon_13/leksjon13_rar_runestein.png';
import stranger from '../../../../../assets/Leksjon_13/leksjon13_rarere_runestein.png';
import strangest from '../../../../../assets/Leksjon_13/leksjon13_rareste_runestein.png';

import coward from '../../../../../assets/Leksjon_14/leksjon14_ridder_feig.png';
import moreCoward from '../../../../../assets/Leksjon_14/leksjon14_ridder_feigere.png';
import mostCoward from '../../../../../assets/Leksjon_14/leksjon14_ridder_feigest.png';

import rotten from '../../../../../assets/Leksjon_15/leksjon15_råtten_tann.png';
import moreRotten from '../../../../../assets/Leksjon_15/leksjon15_mer_råtten_tann.png';
import mostRotten from '../../../../../assets/Leksjon_15/leksjon15_mest_råtten_tann.png';

import weak from '../../../../../assets/Leksjon_16/leksjon16_gutt_veik.png';
import weaker from '../../../../../assets/Leksjon_16/leksjon16_gutt_veikere.png';
import weakest from '../../../../../assets/Leksjon_16/leksjon16_gutt_veikest.png';
import volcano from '../../../../../assets/Leksjon_3/leksjon3_størrevulkan.png';

export const session1 = [
	{
		superlatives: ['størst', 'større'],
		solvedExample: { img: dog, text: 'Unni er', correctSuperlative: ' stor' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Åge er *',
				img: biggestDog,
				defaultClass: 'large',
				correct: 'størst',
				isAnswerd: null,
				isCorrect: false,
			},
			{
				id: 2,
				questionText: 'Ylvis er *',
				img: biggerDog,
				defaultClass: 'medium',
				correct: 'større',
				isAnswerd: null,
				isCorrect: false,
			},
		],
	},
];
export const session2 = [
	{
		superlatives: ['minst', 'mindre '],
		solvedExample: { img: biggestDog, text: 'Unni er', correctSuperlative: 'liten' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Åge er *',
				img: dog,
				defaultClass: 'large',
				correct: 'minst',
				isAnswerd: null,
				isCorrect: false,
			},
			{
				id: 2,
				questionText: 'Ylvis er *',
				img: biggerDog,
				defaultClass: 'medium',
				correct: 'mindre',
				isAnswerd: null,
				isCorrect: false,
			},
		],
	},
];
export const session3 = [
	{
		superlatives: ['mykest', 'mykere'],
		solvedExample: { img: ball, text: 'Ballen er', correctSuperlative: 'myk' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Puta er *',
				img: pillow,
				defaultClass: 'medium',
				correct: 'mykest',
				isAnswerd: null,
				isCorrect: false,
			},
			{
				id: 2,
				questionText: ' Skinnet er* ',
				img: skinnet,
				defaultClass: 'large',
				correct: 'mykere',
				isAnswerd: null,
				isCorrect: false,
			},
		],
	},
];
export const session4 = [
	{
		superlatives: ['størst', 'større'],
		solvedExample: { img: egg, text: 'Egget er ', correctSuperlative: 'stort' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Dinosauren er *',
				img: dinosaur,
				defaultClass: 'medium',
				correct: 'større',
				isAnswerd: null,
				isCorrect: false,
			},
			{
				id: 2,
				questionText: 'Vulkanen er *',
				img: volcano,
				defaultClass: 'large',
				correct: 'størst',
				isAnswerd: null,
				isCorrect: false,
			},
		],
	},
];
export const session5 = [
	{
		superlatives: ['sintere', 'sintest'],
		solvedExample: { img: angryFrog, text: 'Frosken er ', correctSuperlative: 'sint.' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Fido er *',
				img: angryFido,
				defaultClass: 'medium',
				correct: 'sintere',
				isAnswerd: null,
				isCorrect: false,
			},
			{
				id: 2,
				questionText: 'Frida er *',
				img: someonShouting,
				defaultClass: 'large',
				correct: 'sintest',
				isAnswerd: null,
				isCorrect: false,
			},
		],
	},
];
export const session6 = [
	{
		superlatives: ['sykere', 'sykest'],
		solvedExample: { img: sickGiraffe, text: 'Geir er ', correctSuperlative: 'syk.' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Gunnar er *',
				img: sickerGiraffe,
				defaultClass: 'medium',
				correct: 'sykere',
				isAnswerd: null,
				isCorrect: false,
			},
			{
				id: 2,
				questionText: 'Guri er *',
				img: sickestGiraffe,
				defaultClass: 'large',
				correct: 'sykest',
				isAnswerd: null,
				isCorrect: false,
			},
		],
	},
];
export const session7 = [
	{
		superlatives: ['lavere', 'lavest'],
		solvedExample: { img: lowBee, text: 'Denne feen er ', correctSuperlative: 'lav.' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Denne feen er*',
				img: lowerBee,
				defaultClass: 'medium',
				correct: 'lavere',
				isAnswerd: null,
				isCorrect: false,
			},
			{
				id: 2,
				questionText: 'Denne feen er*',
				img: lowestBee,
				defaultClass: 'large',
				correct: 'lavest',
				isAnswerd: null,
				isCorrect: false,
			},
		],
	},
];
export const session8 = [
	{
		superlatives: ['verre', 'verst'],
		solvedExample: { img: bad, text: 'Dette er', correctSuperlative: 'ille.' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Dette er *',
				img: worse,
				defaultClass: 'medium',
				correct: 'verre',
				isAnswerd: null,
				isCorrect: false,
			},
			{
				id: 2,
				questionText: 'Dette er *',
				img: worst,
				defaultClass: 'medium',
				correct: 'verst',
				isAnswerd: null,
				isCorrect: false,
			},
		],
	},
];
export const session9 = [
	{
		superlatives: ['søtere', 'søtest'],
		solvedExample: { img: sweet, text: 'Jordbær er', correctSuperlative: 'søte.' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Kaken er *',
				img: sweeter,
				defaultClass: 'medium',
				correct: 'søtere',
				isAnswerd: null,
				isCorrect: false,
			},
			{
				id: 2,
				questionText: 'Sukker er *',
				img: sweetest,
				defaultClass: 'medium',
				correct: 'søtest',
				isAnswerd: null,
				isCorrect: false,
			},
		],
	},
];
export const session10 = [
	{
		superlatives: ['fullere', 'fullest'],
		solvedExample: { img: full, text: 'Denne vognen er', correctSuperlative: 'full.' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Denne vognen er*',
				img: fuller,
				defaultClass: 'medium',
				correct: 'fullere',
				isAnswerd: null,
				isCorrect: false,
			},
			{
				id: 2,
				questionText: 'Denne vognen er*',
				img: fullest,
				defaultClass: 'medium',
				correct: 'fullest',
				isAnswerd: null,
				isCorrect: false,
			},
		],
	},
];
export const session11 = [
	{
		superlatives: ['lengre', 'lengst'],
		solvedExample: { img: long, text: 'Denne planeten er', correctSuperlative: 'langt.', remainder: ' unna' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Denne planeten er *',
				img: longer,
				defaultClass: 'medium',
				correct: 'lengre',
				isAnswerd: null,
				isCorrect: false,
				remainder: 'unna',
			},
			{
				id: 2,
				questionText: 'Denne planeten er *',
				img: longest,
				defaultClass: 'medium',
				correct: 'lengst',
				isAnswerd: null,
				isCorrect: false,
				remainder: 'unna',
			},
		],
	},
];
export const session12 = [
	{
		superlatives: ['reddere', 'reddest'],
		solvedExample: { img: nervous, text: 'Han er ', correctSuperlative: 'redd.', remainder: ' ' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Han er *',
				img: moreNervous,
				defaultClass: 'medium',
				correct: 'reddere',
				isAnswerd: null,
				isCorrect: false,
				remainder: ' ',
			},
			{
				id: 2,
				questionText: 'Han er *',
				img: mostNervous,
				defaultClass: 'medium',
				correct: 'reddest',
				isAnswerd: null,
				isCorrect: false,
				remainder: ' ',
			},
		],
	},
];
export const session13 = [
	{
		superlatives: ['større', 'størst'],
		solvedExample: { img: big, text: 'Denne piraten er', correctSuperlative: 'stor.', remainder: ' ' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Denne piraten er *',
				img: bigger,
				defaultClass: 'medium',
				correct: 'større',
				isAnswerd: null,
				isCorrect: false,
				remainder: '',
			},
			{
				id: 2,
				questionText: 'Her er den*',
				img: biggest,
				defaultClass: 'medium',
				correct: 'størst',
				isAnswerd: null,
				isCorrect: false,
				remainder: ' piraten',
			},
		],
	},
];
export const session14 = [
	{
		superlatives: ['rarere', 'rarest'],
		solvedExample: { img: strange, text: 'Denne er', correctSuperlative: 'rar', remainder: '' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Denne er *',
				img: stranger,
				defaultClass: 'medium',
				correct: 'rarere',
				isAnswerd: null,
				isCorrect: false,
				remainder: '',
			},
			{
				id: 2,
				questionText: 'Denne er *',
				img: strangest,
				defaultClass: 'medium',
				correct: 'rarest',
				isAnswerd: null,
				isCorrect: false,
				remainder: '',
			},
		],
	},
];
export const session15 = [
	{
		superlatives: ['feigere', 'feigest'],
		solvedExample: { img: coward, text: 'Han er', correctSuperlative: 'feig.', remainder: '' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Han er *',
				img: moreCoward,
				defaultClass: 'medium',
				correct: 'feigere',
				isAnswerd: null,
				isCorrect: false,
				remainder: '',
			},
			{
				id: 2,
				questionText: 'Han er *',
				img: mostCoward,
				defaultClass: 'medium',
				correct: 'feigest',
				isAnswerd: null,
				isCorrect: false,
				remainder: '',
			},
		],
	},
];
export const session16 = [
	{
		superlatives: ['mer råtten', 'mest råtten'],
		solvedExample: { img: rotten, text: 'Denne tanna er', correctSuperlative: 'råtten.', remainder: '' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Denne tanna er *',
				img: moreRotten,
				defaultClass: 'medium',
				correct: 'mer råtten',
				isAnswerd: null,
				isCorrect: false,
				remainder: '',
			},
			{
				id: 2,
				questionText: 'Denne tanna er *',
				img: mostRotten,
				defaultClass: 'medium',
				correct: 'mest råtten',
				isAnswerd: null,
				isCorrect: false,
				remainder: '',
			},
		],
	},
];
export const session17 = [
	{
		superlatives: ['veikere', 'veikest'],
		solvedExample: { img: weak, text: 'Denne gutten er', correctSuperlative: 'veik.', remainder: '' },
		testQuestions: [
			{
				id: 1,
				questionText: 'Denne gutten er *',
				img: weaker,
				defaultClass: 'medium',
				correct: 'veikere',
				isAnswerd: null,
				isCorrect: false,
				remainder: '',
			},
			{
				id: 2,
				questionText: 'Denne gutten er *',
				img: weakest,
				defaultClass: 'medium',
				correct: 'veikest',
				isAnswerd: null,
				isCorrect: false,
				remainder: '',
			},
		],
	},
];
