import React from 'react';
import moment from 'moment';
import props from 'prop-types';
import { goToNextStep, setCandidateSession } from '../../../actions';
import './styles.scss';
import { updateObject, updateItemInArray } from '../../../utils/reducerUtils';
import { connect } from 'react-redux';
const genders = [
	{
		label: 'Jente',
		value: 'f',
	},
	{
		label: 'Gutt',
		value: 'm',
	},
	{
		label: 'Annet',
		value: 'u',
	},
];
const yesNoAndMaybe = [
	{
		label: 'Nei',
		value: '0',
	},
	{
		label: 'Ja',
		value: '1',
	},
	{
		label: 'Vet ikke',
		value: '2',
	},
];

const now = moment();

class Introduction extends React.Component {
	constructor(props) {
		super(props);
		this.updateSessionUser = this.updateSessionUser.bind(this);
		this.state = {
			submitEnabled: true,
			candidateSession: null,
			isFormValid: false,
			sessionUserInfo: {
				gender: '',
				education: {
					youthGrade: 0,
				},
			},
		};
	}
	updateSessionUser(categoryKey, key, value) {
		const { sessionUserInfo } = this.state;
		if (categoryKey) {
			const category = updateObject(sessionUserInfo[categoryKey], {
				[key]: value,
			});
			this.setState({
				sessionUserInfo: updateObject(sessionUserInfo, {
					[categoryKey]: category,
				}),
			});
		} else {
			this.setState({
				sessionUserInfo: updateObject(sessionUserInfo, {
					[key]: value,
				}),
			});
		}
	}
	createSessionUser = (event, sessionUser) => {
		event.preventDefault();
		if (!sessionUser.education.youthGrade || !sessionUser.gender) return;
		this.props.onSendInfo(sessionUser);
	};

	render() {
		const { isFormValid, birthDate, sessionUserInfo } = this.state;

		const allGrades = [
			{ id: 2, value: 2, label: '2. Trinn' },
			{ id: 3, value: 3, label: '3. Trinn' },
		];
		let formRef;
		const { gender } = sessionUserInfo;
		return (
			<div className='rw-modal'>
				<div
					className='rw-modal__island'
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div className='rw-candidate-container'>
						<br></br>
						<h2 className='rw-pagetitle-white'>Introduksjon</h2>
						<form
							className='rw-input-candidate'
							onChange={() => {
								const fromValidity = formRef.checkValidity();
								if (isFormValid !== fromValidity) {
									this.setState({
										isFormValid: fromValidity,
									});
								}
							}}
							onSubmit={(event) => {
								event.preventDefault();
								this.createSessionUser(
									event,
									this.state.sessionUserInfo
								);
							}}
							ref={(ref) => {
								formRef = ref;
							}}
						>
							<div className='rw-info__main-container'>
								<div className='rw-info__field'>
									<div className='rw-candidate-label'>
										<label className='rw-test__heading'>
											Trinn
										</label>
										<div>
											<select
												className='rw-input__field-grade'
												id={`candidate`}
												name={`youthGrade`}
												required='required'
												onChange={(
													event
												) => {
													const education =
														{
															youthGrade: event
																.target
																.value,
														};
													this.updateSessionUser(
														null,
														'education',
														education
													);
												}}
											>
												<option
													id={
														0
													}
													key={
														0
													}
													value=''
													disabled
													selected
												>
													{
														'-Velg trinn'
													}
												</option>
												{allGrades.map(
													(
														grade
													) => (
														<option
															id={
																grade.id
															}
															key={
																grade.id
															}
															value={
																grade.value
															}
														>
															{
																grade.label
															}
														</option>
													)
												)}
											</select>
										</div>
									</div>
									<br />
									<div className='rw-candidate-label-gender'>
										<label className='rw-test__heading'>
											Kjøn
										</label>
										<div className='rw-options-gender'>
											{genders.map(
												(
													genderOption
												) => {
													const {
														label: genderLabel,
														value,
													} =
														genderOption;
													return (
														<span
															className='rw-radio'
															key={
																value
															}
														>
															<input
																type='radio'
																checked={
																	value ===
																	gender
																}
																className='rw-radio-candidate'
																id={`genders${value}`}
																name='gender'
																onChange={() => {
																	this.updateSessionUser(
																		null,
																		'gender',
																		value
																	);
																}}
																required
															/>
															<label
																className='rw-radio__lable'
																htmlFor={`genders${value}`}
															>
																{
																	genderLabel
																}
															</label>
														</span>
													);
												}
											)}
										</div>
									</div>
								</div>
							</div>
							<div className='rw-btn-submit-form'>
								<button
									className={
										isFormValid
											? 'bg-primary-pass'
											: 'bg-primary-stop'
									}
								>
									Start
								</button>
							</div>
							<br />
						</form>
					</div>
				</div>
			</div>
		);
	}
}

export default Introduction;
