import React from 'react';
import * as data from './data/no-data.js';
import TimerCount from '../../../TimerCount';
import { updateObject } from '../../../../utils/reducerUtils';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
import { connect } from 'react-redux';
import introAudio from '../../../../assets/sounds/singular-plural-intro.m4a';
import AudioPlayer from '../../../AudioPlayer';

import './styles.scss';

export const getSessionData = (session) => {
	const indexValue = `session${session}`;
	const sessionItems = data[indexValue];
	return sessionItems;
};

const wordIncorrectClassName = 'rw-sp-word-wrong';
let currentId;
const resultsGloabl = [];
class SingluarPlural extends React.Component {
	constructor(props) {
		super(props);
		this.audio = introAudio;
		this.sessionData = getSessionData(this.props.selectedSession);
		const results = {};

		for (let i = 0; i < this.sessionData.length; i++) {
			for (let j = 0; j < this.sessionData[i].matchingImages.length; j++) {
				if (this.sessionData[i].matchingImages[j].words) {
					let questions = this.sessionData[i].matchingImages[j].words;
					for (let k = 0; k < questions.length; k++) {
						let id = questions[k].id;
						results[id] = '';
					}
				}
			}
		}
		this.state = {
			sessionItems: this.sessionData,
			activeItem: false,
			timeRanOut: false,
			results,
		};
	}
	handleWrongAnswer(mainIndex, index, wordIndex, currentId, matchingImages, wordInnerIndex) {
		this.sessionData[mainIndex].matchingImages[index].words[wordIndex].incorrectClassName = 'rw-fb-word-wrong';
		const id = wordInnerIndex.id;
		this.updateResults(id, false);
		this.setState({
			sessionItems: this.sessionData,
			timeRanOut: false,
		});

		setTimeout(() => {
			this.sessionData[mainIndex].matchingImages[index].words[wordIndex].incorrectClassName = null;

			this.setState({
				sessionItems: this.sessionData,
			});
		}, 2000);
	}

	updateResults(id, status) {
		resultsGloabl.push({ id: id, status: { isCorrect: status } });
	}
	updateResultsCount(wordIndex) {
		const { results } = this.state;
		const value = { isAnswerd: true };
		const id = wordIndex.id;
		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
		this.updateResults(id, true);
	}
	render() {
		const { onNextStep } = this.props;
		const { sessionItems, results } = this.state;
		const allQuestionsAnswered = Object.keys(results).every((key) => results[key]);
		const isNextButtonVisible = allQuestionsAnswered;
		let audioPlayerRef;
		return (
			<div>
				<div className='rw-sp-intro'>
					<div className='rw-infobar'>
						<TimerCount
							duration={130}
							onComplete={() => {
								this.setState({
									timeRanOut: true,
								});
							}}
						/>
					</div>
					<div className='rw-intro-text'>
						<p>
							<AudioPlayer
								autoPlay
								inline
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								src={this.audio}
							/>
							Trykk først på bildet og deretter på riktig ord.
						</p>
					</div>
					<br></br>
					<div className='rw-sap'>
						{sessionItems.map((item, mainIndex) => {
							const { matchingImages } = item;

							return (
								<div>
									<div
										className='rw-sp-content'
										key={`rw-lsp-content-${mainIndex}`}
									>
										{matchingImages.map(
											(image, index) => {
												const {
													id,
													words,
													img,
													defaultClassName,
													currentClassName,
												} = image;

												return (
													<div
														key={`rw-lsp-content-id-${id}`}
													>
														<div
															className='rw-sp-content__question'
															key={`rw-sp-content__question-${mainIndex}`}
														>
															{img && (
																<span
																	className='rw-sp-img'
																	key={`rw-sp-img-${id}`}
																	onClick={() => {
																		if (
																			!this
																				.state
																				.activeItem &&
																			!matchingImages[
																				index
																			]
																				.isAnswerd
																		) {
																			const target =
																				id ===
																				0
																					? index +
																					  1
																					: index -
																					  1;

																			currentId =
																				index;
																			matchingImages[
																				index
																			].currentClassName =
																				matchingImages[
																					index
																				].selectClassName;
																			matchingImages[
																				index
																			].disable = false;
																			let newWords =
																				matchingImages[
																					target
																				]
																					.words;

																			for (
																				let i = 0;
																				i <
																				newWords.length;
																				i++
																			) {
																				newWords[
																					i
																				].disable = false;
																			}
																			matchingImages[
																				target
																			].words =
																				newWords;
																			this.setState(
																				{
																					sessionItems: this
																						.sessionData,
																					activeItem: true,
																				}
																			);
																		}
																	}}
																	onMouseEnter={() => {
																		if (
																			!this
																				.state
																				.activeItem &&
																			!matchingImages[
																				index
																			]
																				.isAnswerd
																		) {
																			matchingImages[
																				index
																			].currentClassName =
																				matchingImages[
																					index
																				].hoverClassName;
																			this.setState(
																				{
																					sessionItems: this
																						.sessionData,
																				}
																			);
																		}
																	}}
																	onMouseLeave={() => {
																		if (
																			matchingImages[
																				index
																			]
																				.disable &&
																			!matchingImages[
																				index
																			]
																				.isAnswerd
																		) {
																			matchingImages[
																				index
																			].currentClassName =
																				matchingImages[
																					index
																				].defaultClassName;
																		} else if (
																			matchingImages[
																				index
																			]
																				.disable &&
																			matchingImages[
																				index
																			]
																				.isAnswerd
																		) {
																			matchingImages[
																				index
																			].currentClassName =
																				matchingImages[
																					index
																				].correctClassName;
																		} else {
																			matchingImages[
																				index
																			].currentClassName =
																				matchingImages[
																					index
																				].selectClassName;
																		}

																		this.setState(
																			{
																				sessionItems: this
																					.sessionData,
																			}
																		);
																	}}
																>
																	<img
																		className={
																			currentClassName
																		}
																		key={`rw-sp-img__small-${index}`}
																		src={
																			img
																		}
																	/>
																</span>
															)}

															{words &&
																words.map(
																	(
																		wordItem,
																		wordIndex
																	) => {
																		const {
																			word,
																			disable,
																			isAnswered,
																			incorrectClassName,
																			isCorrect,
																		} =
																			wordItem;
																		return (
																			<div className='rw-sp-words-container'>
																				<div
																					className={
																						isAnswered
																							? 'rw-sp-word-correct'
																							: disable
																							? 'rw-sp-word-disabled'
																							: incorrectClassName
																							? 'rw-sp-word-wrong'
																							: 'rw-sp-word'
																					}
																					key={`rw-sp-word-${wordIndex}`}
																					onClick={() => {
																						if (
																							!disable &&
																							!isAnswered
																						) {
																							if (
																								matchingImages[
																									currentId
																								].correctAnswer.toLowerCase() ===
																									word.toLowerCase() &&
																								this
																									.state
																									.activeItem
																							) {
																								this.props.flipCoin();
																								let newWords =
																									matchingImages[1]
																										.words;

																								newWords[
																									wordIndex
																								].isAnswered = true;

																								newWords[
																									wordIndex
																								].disable = true;
																								matchingImages[1].words =
																									newWords;
																								matchingImages[
																									currentId
																								].isAnswerd = true;
																								matchingImages[
																									currentId
																								].disable = true;

																								matchingImages[
																									currentId
																								].currentClassName =
																									matchingImages[
																										currentId
																									].correctClassName;
																								this.setState(
																									{
																										sessionItems: this
																											.sessionData,
																										activeItem: false,
																									}
																								);
																								this.updateResultsCount(
																									newWords[
																										wordIndex
																									]
																								);
																							} else {
																								let newWords =
																									matchingImages[1]
																										.words;
																								this.handleWrongAnswer(
																									mainIndex,
																									index,
																									wordIndex,
																									currentId,
																									matchingImages,
																									newWords[
																										wordIndex
																									]
																								);
																							}
																						}
																					}}
																				>
																					<span>
																						{
																							word
																						}
																					</span>
																				</div>
																			</div>
																			//
																		);
																	}
																)}
														</div>
														<div className='rw-fb-content__options'></div>
														{words && (
															<span>
																{' '}
																<br></br>
																<div className='sp-scroller-roof'></div>
															</span>
														)}
													</div>
												);
											}
										)}
									</div>
								</div>
							);
						})}
					</div>
				</div>

				{(this.state.timeRanOut || isNextButtonVisible || this.props.isSuperTeacher) && (
					<div className='rw-next-btn'>
						<button
							className='rw-btn bg-primary'
							onClick={() =>
								onNextStep(
									resultsGloabl,
									ALL_SUBTESTS.SINGLUARPLURAL
								)
							}
						>
							{'Neste'}
						</button>
					</div>
				)}
			</div>
		);
	}
}
export default SingluarPlural;
