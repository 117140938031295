import { INIT_AUTH, SET_AUTH_ERROR, SET_AUTH_IFRAME_URL, RESET_AUTH } from '../actions/index';
import { updateObject } from '../utils/reducerUtils';

const defaultState = {
	error: '',
	iframeUrl: '',
	isInit: false,
};

export default function auth(state = defaultState, action) {
	switch (action.type) {
		case INIT_AUTH:
			return updateObject(state, {
				isInit: true,
			});
		case SET_AUTH_ERROR:
			return updateObject(state, {
				error: action.error,
			});
		case SET_AUTH_IFRAME_URL:
			return updateObject(state, {
				iframeUrl: action.iframeUrl,
			});
		case RESET_AUTH:
			return defaultState;
		default:
			return state;
	}
}
