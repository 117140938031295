const beforeunloadHandler = (e) => {
	const text = 'Hvis du går ut av testen vil du trenge å begynne på nytt!';
	e.returnValue = text;
	return text;
};

const BEFORE_UNLOAD = 'beforeunload';
export function begin() {
	window.addEventListener(BEFORE_UNLOAD, beforeunloadHandler);
}
export function end() {
	window.removeEventListener(BEFORE_UNLOAD, beforeunloadHandler);
}
