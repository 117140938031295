const defaultClassName = 'rw-mw-container__Questions';
const selectClassName = 'rw-mw-container__Questions__select';
export const Session1 = [
	{
		id: 0,
		answers: ['juletre', 'kattehale', 'hundebånd', 'utenfor'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'Jule',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: '',
				word: 'For',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'Katte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'Bånd',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'Uten',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'Hale',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'Hunde',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'Tre',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'jule',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['tre'],
				whole: 'juletre',
				isCorrect: false,
			},

			{
				id: 1,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['uten'],
				whole: 'utenfor',
				isCorrect: false,
			},

			{
				id: 2,
				word: 'katte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['hale'],
				whole: 'kattehale',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'bånd',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['hunde'],
				whole: 'hundebånd',
				isCorrect: false,
			},
			{
				id: 4,
				word: 'uten',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['for'],
				whole: 'utenfor',
				isCorrect: false,
			},

			{
				id: 5,
				word: 'hale',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['katte'],
				whole: 'kattehale',
				isCorrect: false,
			},
			{
				id: 6,
				word: 'hunde',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['bånd'],
				whole: 'hundebånd',
				isCorrect: false,
			},

			{
				id: 7,
				word: 'tre',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['jule'],
				whole: 'juletre',
				isCorrect: false,
			},
		],
	},
];
export const Session2 = [
	{
		id: 2,
		answers: ['luktesans', 'støvsuger', 'hundemat'],
		totalMatches: 3,
		matchingWord: [
			{
				id: 0,
				word: 'lukte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'suger',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'mat',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'hunde',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 4,
				word: 'støv',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'sans',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'lukte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['sans'],
				whole: 'luktesans',
				isCorrect: false,
			},

			{
				id: 1,
				word: 'suger',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['støv'],
				whole: 'støvsuger',
				isCorrect: false,
			},

			{
				id: 2,
				word: 'mat',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['hunde'],
				whole: 'hundemat',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'hunde',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['mat'],
				whole: 'hundemat',
				isCorrect: false,
			},

			{
				id: 4,
				word: 'støv',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['suger'],
				whole: 'støvsuger',
				isCorrect: false,
			},

			{
				id: 5,
				word: 'sans',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['lukte'],
				whole: 'luktesans',
				isCorrect: false,
			},
		],
	},
];
export const Session3 = [
	{
		id: 3,
		answers: ['badeand', 'hvorfor', 'ønskeliste'],
		totalMatches: 3,
		matchingWord: [
			{
				id: 0,
				word: 'bade',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'ønske',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'liste',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'hvor',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'and',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'bade',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['and'],
				whole: 'badeand',
				isCorrect: false,
			},

			{
				id: 1,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['hvor'],
				whole: 'hvorfor',
				isCorrect: false,
			},

			{
				id: 2,
				word: 'ønske',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['liste'],
				whole: 'ønskeliste',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'liste',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['ønske'],
				whole: 'ønskeliste',
				isCorrect: false,
			},

			{
				id: 4,
				word: 'hvor',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['for'],
				whole: 'hvorfor',
				isCorrect: false,
			},

			{
				id: 5,
				word: 'and',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['bade'],
				whole: 'badeand',
				isCorrect: false,
			},
		],
	},
];
export const Session4 = [
	{
		id: 4,
		answers: ['danseskole', 'utbrudd', 'dinosaurunger'],
		totalMatches: 3,
		matchingWord: [
			{
				id: 0,
				word: 'danse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'brudd',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'dinosaur',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'skole',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 4,
				word: 'ut',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'unger',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'danse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['skole'],
				whole: 'danseskole',
				isCorrect: false,
			},
			{
				id: 1,
				word: 'brudd',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['ut'],
				whole: 'utbrudd',
				isCorrect: false,
			},
			{
				id: 2,
				word: 'dinosaur',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['unger'],
				whole: 'dinosaurunger',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'skole',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['danse'],
				whole: 'danseskole',
				isCorrect: false,
			},

			{
				id: 4,
				word: 'ut',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['brudd'],
				whole: 'utbrudd',
				isCorrect: false,
			},

			{
				id: 5,
				word: 'unger',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['dinosaur'],
				whole: 'dinosaurunger',
				isCorrect: false,
			},
		],
	},
];
export const Session5 = [
	{
		id: 5,
		answers: ['hårtuster', 'fisefin', 'heldigvis'],
		totalMatches: 3,
		matchingWord: [
			{
				id: 0,
				word: 'hår',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'fin',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'heldig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'tuster',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 4,
				word: 'fise',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'vis',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'hår',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['tuster'],
				whole: 'hårtuster',
				isCorrect: false,
			},
			{
				id: 1,
				word: 'fin',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['fise'],
				whole: 'fisefin',
				isCorrect: false,
			},
			{
				id: 2,
				word: 'heldig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['vis'],
				whole: 'heldigvis',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'tuster',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['hår'],
				whole: 'hårtuster',
				isCorrect: false,
			},

			{
				id: 4,
				word: 'fise',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['fin'],
				whole: 'fisefin',
				isCorrect: false,
			},

			{
				id: 5,
				word: 'vis',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['heldig'],
				whole: 'heldigvis',
				isCorrect: false,
			},
		],
	},
];
export const Session6 = [
	{
		id: 6,
		answers: ['forbi', 'forelsket', 'bukseknapp'],
		totalMatches: 3,
		matchingWord: [
			{
				id: 0,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'knapp',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'elsket',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 4,
				word: 'bukse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'bi',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['bi', 'elsket'],
				whole: 'forbi',
				isCorrect: false,
			},

			{
				id: 1,
				word: 'knapp',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['bukse'],
				whole: 'bukseknapp',
				isCorrect: false,
			},

			{
				id: 2,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['elsket', 'bi'],
				whole: 'forelsket',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'elsket',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['for'],
				whole: 'forelsket',
				isCorrect: false,
			},

			{
				id: 4,
				word: 'bukse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['knapp'],
				whole: 'bukseknapp',
				isCorrect: false,
			},

			{
				id: 5,
				word: 'bi',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['for'],
				whole: 'forbi',
				isCorrect: false,
			},
		],
	},
];
export const Session7 = [
	{
		id: 7,
		answers: ['hestemøkk', 'høstferie', 'honningfelle'],
		totalMatches: 3,
		matchingWord: [
			{
				id: 0,
				word: 'heste',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'ferie',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'honning',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'møkk',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 4,
				word: 'høst',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'felle',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'heste',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['møkk'],
				whole: 'hestemøkk',
				isCorrect: false,
			},

			{
				id: 1,
				word: 'ferie',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['høst'],
				whole: 'høstferie',
				isCorrect: false,
			},

			{
				id: 2,
				word: 'honning',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['felle'],
				whole: 'honningfelle',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'møkk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['heste'],
				whole: 'hestemøkk',
				isCorrect: false,
			},

			{
				id: 4,
				word: 'høst',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['ferie'],
				whole: 'høstferie',
				isCorrect: false,
			},

			{
				id: 5,
				word: 'felle',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['honning'],
				whole: 'honningfelle',
				isCorrect: false,
			},
		],
	},
];
export const Session8 = [
	{
		id: 0,
		answers: ['forsvant', 'kattedyr', 'jungelbeist', 'tryllestav'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'trylle',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'katte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'beist',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'stav',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'dyr',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'jungel',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'svant',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['svant'],
				whole: 'forsvant',
				isCorrect: false,
			},
			{
				id: 1,
				word: 'trylle',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['stav'],
				whole: 'tryllestav',
				isCorrect: false,
			},
			{
				id: 2,
				word: 'katte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['dyr'],
				whole: 'kattedyr',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'beist',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['jungel'],
				whole: 'jungelbeist',
				isCorrect: false,
			},
			{
				id: 4,
				word: 'stav',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['trylle'],
				whole: 'tryllestav',
				isCorrect: false,
			},
			{
				id: 5,
				word: 'dyr',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['katte'],
				whole: 'kattedyr',
				isCorrect: false,
			},
			{
				id: 6,
				word: 'jungel',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['beist'],
				whole: 'jungelbeist',
				isCorrect: false,
			},

			{
				id: 7,
				word: 'svant',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['for'],
				whole: 'forsvant',
				isCorrect: false,
			},
		],
	},
];
export const Session9 = [
	{
		id: 9,
		answers: ['kakeland', 'hvorfor', 'tannlege'],
		totalMatches: 3,
		matchingWord: [
			{
				id: 0,
				word: 'kake',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'tann',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'lege',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 4,
				word: 'hvor',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'land',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'kake',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['land'],
				whole: 'kakeland',
				isCorrect: false,
			},

			{
				id: 1,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['hvor'],
				whole: 'hvorfor',
				isCorrect: false,
			},

			{
				id: 2,
				word: 'tann',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['lege'],
				whole: 'tannlege',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'lege',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['tann'],
				whole: 'tannlege',
				isCorrect: false,
			},

			{
				id: 4,
				word: 'hvor',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['for'],
				whole: 'hvorfor',
				isCorrect: false,
			},

			{
				id: 5,
				word: 'land',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['kake'],
				whole: 'kakeland',
				isCorrect: false,
			},
		],
	},
];
export const Session10 = [
	{
		id: 0,
		answers: ['latterlig', 'tilbake', 'pålegg', 'langbukse'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'latter',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'bukse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'legg',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 4,
				word: 'lang',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'bake',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'på',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'latter',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['lig'],
				whole: 'latterlig',
				isCorrect: false,
			},

			{
				id: 1,
				word: 'bukse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['lang'],
				whole: 'langbukse',
				isCorrect: false,
			},

			{
				id: 2,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['bake'],
				whole: 'tilbake',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'legg',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['på'],
				whole: 'pålegg',
				isCorrect: false,
			},

			{
				id: 4,
				word: 'lang',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['bukse'],
				whole: 'langbukse',
				isCorrect: false,
			},

			{
				id: 5,
				word: 'bake',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['til'],
				whole: 'tilbake',
				isCorrect: false,
			},
			{
				id: 6,
				word: 'på',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['legg'],
				whole: 'pålegg',
				isCorrect: false,
			},

			{
				id: 7,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['latter'],
				whole: 'latterlig',
				isCorrect: false,
			},
		],
	},
];
export const Session11 = [
	{
		id: 0,
		answers: ['melkeveien', 'solsystemet', 'måneraketten', 'utenfor'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'melke',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'sol',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'raketten',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 4,
				word: 'uten',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'systemet',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'måne',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'veien',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'melke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['veien'],
				whole: 'melkeveien',
				isCorrect: false,
			},
			{
				id: 1,
				word: 'uten',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['for'],
				whole: 'utenfor',
				isCorrect: false,
			},
			{
				id: 2,
				word: 'sol',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['systemet'],
				whole: 'solsystemet',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'raketten',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['måne'],
				whole: 'måneraketten',
				isCorrect: false,
			},
			{
				id: 4,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['uten'],
				whole: 'utenfor',
				isCorrect: false,
			},
			{
				id: 5,
				word: 'systemet',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['sol'],
				whole: 'solsystem',
				isCorrect: false,
			},
			{
				id: 6,
				word: 'måne',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['raketten'],
				whole: 'måneraketten',
				isCorrect: false,
			},

			{
				id: 7,
				word: 'veien',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['melke'],
				whole: 'melkeveien',
				isCorrect: false,
			},
		],
	},
];
export const Session12 = [
	{
		id: 2,
		answers: ['æresord', 'nistepakke', 'også'],
		totalMatches: 3,
		matchingWord: [
			{
				id: 0,
				word: 'æres',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'pakke',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'og',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'ord',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 4,
				word: 'niste',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'så',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'æres',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['ord'],
				whole: 'æresord',
				isCorrect: false,
			},

			{
				id: 1,
				word: 'pakke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['niste'],
				whole: 'nistepakke',
				isCorrect: false,
			},

			{
				id: 2,
				word: 'og',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['så'],
				whole: 'også',
				isCorrect: false,
			},

			{
				id: 3,
				word: 'ord',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['æres'],
				whole: 'æresord',
				isCorrect: false,
			},

			{
				id: 4,
				word: 'niste',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['pakke'],
				whole: 'nistepakke',
				isCorrect: false,
			},

			{
				id: 5,
				word: 'så',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['og'],
				whole: 'også',
				isCorrect: false,
			},
		],
	},
];
export const Session13 = [
	{
		id: 13,
		answers: ['pannebånd', 'skattekiste', 'skattekart'],
		totalMatches: 3,
		matchingWord: [
			{
				id: 0,
				word: 'panne',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'kiste',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'skatte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'bånd',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 4,
				word: 'skatte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'kart',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'panne',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['bånd'],
				whole: 'pannebånd',
				isCorrect: false,
			},

			{
				id: 1,
				word: 'kiste',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: 'skatte',
				whole: 'skattekiste',
				isCorrect: false,
			},

			{
				id: 2,
				word: 'skatte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['kart', 'kiste'],
				whole: 'skattekart',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'bånd',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['panne'],
				whole: 'pannebånd',
				isCorrect: false,
			},

			{
				id: 4,
				word: 'skatte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['kiste', 'kart'],
				whole: 'skattekiste',
				isCorrect: false,
			},

			{
				id: 5,
				word: 'kart',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: 'skatte',
				whole: 'skattekart',
				isCorrect: false,
			},
		],
	},
];
export const Session14 = [
	{
		id: 4,
		answers: ['runepinne', 'runestein', 'omringet'],
		totalMatches: 3,
		matchingWord: [
			{
				id: 0,
				word: 'rune',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'ringet',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'rune',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'pinne',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'om',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'stein',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'rune',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['pinne', 'stein'],
				whole: 'runepinne',
				isCorrect: false,
			},

			{
				id: 1,
				word: 'ringet',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['om'],
				whole: 'omringet',
				isCorrect: false,
			},

			{
				id: 2,
				word: 'rune',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['stein', 'pinne'],
				whole: 'runestein',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'pinne',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['rune'],
				whole: 'runepinne',
				isCorrect: false,
			},

			{
				id: 4,
				word: 'om',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['ringet'],
				whole: 'omringet',
				isCorrect: false,
			},

			{
				id: 5,
				word: 'stein',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['rune'],
				whole: 'runestein',
				isCorrect: false,
			},
		],
	},
];
export const Session15 = [
	{
		id: 3,
		answers: ['sutrekopp', 'spisested', 'spisebord'],
		totalMatches: 3,
		matchingWord: [
			{
				id: 0,
				word: 'sutre',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'spise',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'spise',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'bord',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'sted',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'kopp',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'sutre',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['kopp'],
				whole: 'sutrekopp',
				isCorrect: false,
			},
			{
				id: 1,
				word: 'spise',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['sted', 'bord'],
				whole: 'spisested',
				isCorrect: false,
			},
			{
				id: 2,
				word: 'spise',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['bord', 'sted'],
				whole: 'spisebord',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'bord',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['spise'],
				whole: 'spisebord',
				isCorrect: false,
			},
			{
				id: 4,
				word: 'sted',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['spise'],
				whole: 'spisested',
				isCorrect: false,
			},
			{
				id: 5,
				word: 'kopp',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['sutre'],
				whole: 'sutrekopp',
				isCorrect: false,
			},
		],
	},
];
export const Session16 = [
	{
		id: 0,
		answers: ['tannfe', 'tannhelse', 'tannkrem', 'tannlege'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'tann',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'tann',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'tann',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'helse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'fe',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'krem',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'lege',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 7,
				word: 'tann',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'tann',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['lege', 'krem', 'fe', 'helse'],
				whole: 'tannlege',
				isCorrect: false,
			},
			{
				id: 1,
				word: 'tann',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['lege', 'krem', 'fe', 'helse'],
				whole: 'tannfe',
				isCorrect: false,
			},
			{
				id: 2,
				word: 'tann',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['lege', 'krem', 'fe', 'helse'],
				whole: 'tannkrem',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'helse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['tann'],
				whole: 'tannhelse',
				isCorrect: false,
			},
			{
				id: 4,
				word: 'fe',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['tann'],
				whole: 'tannfe',
				isCorrect: false,
			},
			{
				id: 5,
				word: 'krem',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['tann'],
				whole: 'tannkrem',
				isCorrect: false,
			},

			{
				id: 6,
				word: 'lege',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['tann'],
				whole: 'tannlege',
				isCorrect: false,
			},
			{
				id: 7,
				word: 'tann',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['lege', 'krem', 'fe', 'helse'],
				whole: 'tannhelse',
				isCorrect: false,
			},
		],
	},
];
export const Session17 = [
	{
		id: 0,
		answers: ['vattnisse', 'viktigper', 'veslevoksen', 'vikingskip'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'vatt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'per',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'viking',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'voksen',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'viktig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'skip',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'vesle',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'nisse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'vatt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['nisse'],
				whole: 'vattnisse',
				isCorrect: false,
			},
			{
				id: 1,
				word: 'per',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['viktig'],
				whole: 'viktigper',
				isCorrect: false,
			},
			{
				id: 2,
				word: 'viking',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['skip'],
				whole: 'vikingskip',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'voksen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['vesle'],
				whole: 'veslevoksen',
				isCorrect: false,
			},
			{
				id: 4,
				word: 'viktig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['per'],
				whole: 'viktigper',
				isCorrect: false,
			},
			{
				id: 5,
				word: 'skip',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['viking'],
				whole: 'vikingskip',
				isCorrect: false,
			},
			{
				id: 6,
				word: 'vesle',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['voksen'],
				whole: 'veslevoksen',
				isCorrect: false,
			},

			{
				id: 7,
				word: 'nisse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['vatt'],
				whole: 'vattnisse',
				isCorrect: false,
			},
		],
	},
];
export const Session18 = [
	{
		id: 0,
		answers: ['superhelt', 'utelys', 'gresskar', 'allehelgen'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'super',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'ute',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'gress',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'helt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'lys',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'alle',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 6,
				word: 'helgen',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 7,
				word: 'kar',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'super',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['helt'],
				whole: 'superhelt',
				isCorrect: false,
			},
			{
				id: 1,
				word: 'ute',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['lys'],
				whole: 'utelys',
				isCorrect: false,
			},
			{
				id: 2,
				word: 'gress',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['kar'],
				whole: 'gresskar',
				isCorrect: false,
			},
			{
				id: 3,
				word: 'helt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['super'],
				whole: 'superhelt',
				isCorrect: false,
			},
			{
				id: 4,
				word: 'lys',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['ute'],
				whole: 'utelys',
				isCorrect: false,
			},
			{
				id: 5,
				word: 'alle',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['helgen'],
				whole: 'allehelgen',
				isCorrect: false,
			},
			{
				id: 6,
				word: 'helgen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['alle'],
				whole: 'allehelgen',
				isCorrect: false,
			},

			{
				id: 7,
				word: 'kar',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['gress'],
				whole: 'gresskar',
				isCorrect: false,
			},
		],
	},
];
export const Session19 = [
	{
		id: 19,
		answers: ['trekkfugler', 'sørover', 'fugletrekk', 'overleve'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'trekk',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'fugle',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'sør',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'over',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'trekk',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'over',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'leve',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'fugler',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'trekk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['fugler', 'fugle'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'fugle',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['trekk'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'sør',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['over'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'over',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['sør', 'leve'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'trekk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['fugle', 'fulger'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'over',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['leve', 'sør'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'leve',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: 'over',
				isCorrect: false,
			},
			{
				id: 7,
				word: 'fugler',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['trekk'],
				isCorrect: false,
			},
		],
	},
];
export const Session20 = [
	{
		id: 20,
		answers: ['hvorfor', 'nordover', 'tilbake', 'ledefuglen'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'hvor',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'fuglen',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'nord',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'over',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'lede',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'bake',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'hvor',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['for'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'fuglen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['lede'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'nord',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['over'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['hvor'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['bake'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'over',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['nord'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'lede',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['fuglen'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'bake',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['til'],
				isCorrect: false,
			},
		],
	},
];
export const Session21 = [
	{
		id: 21,
		answers: ['oppgaver', 'bevare', 'medlemsland', 'hovedkontor'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'opp',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'medlems',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'hoved',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'kontor',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'gaver',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'land',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'vare',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'opp',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['gaver'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'medlems',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['land'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['vare'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'hoved',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['kontor'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'kontor',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['hoved'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'gaver',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['opp'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'land',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['medlems'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'vare',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['be'],
				isCorrect: false,
			},
		],
	},
];
export const Session22 = [
	{
		id: 22,
		answers: ['legekontor', 'helsesykepleier', 'istedenfor', 'farlige'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'lege',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'isteden',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'helse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'far',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'kontor',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'lige',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'sykepleier',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'lege',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['kontor'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'isteden',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['for'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'helse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['sykepleier'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'far',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['lige'],
				isCorrect: false,
			},

			{
				id: 4,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['isteden'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'kontor',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['lege'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'lige',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['far'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'sykepleier',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['helse'],
				isCorrect: false,
			},
		],
	},
];
export const Session23 = [
	{
		id: 23,
		answers: ['påvirke', 'solskinn', 'vanndråper', 'fordamper'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'på',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'vann',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'skinn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 4,
				word: 'dråper',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'virke',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'damper',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'sol',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'på',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['virke'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'vann',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['dråper'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'skinn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['sol'],
				isCorrect: false,
			},

			{
				id: 3,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['damper'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'dråper',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['vann'],
				isCorrect: false,
			},

			{
				id: 5,
				word: 'virke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['på'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'damper',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['for'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'sol',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['skinn'],
				isCorrect: false,
			},
		],
	},
];
export const Session24 = [
	{
		id: 24,
		answers: ['solfylt', 'motsatt', 'landskapet', 'årstid'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'sol',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'års',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'tid',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'fylt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'mot',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'skapet',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'land',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'satt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'sol',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['fylt'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'års',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['tid'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'tid',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['års'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'fylt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['sol'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'mot',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['satt'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'skapet',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['land'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'land',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['skapet'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'satt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['mot'],
				isCorrect: false,
			},
		],
	},
];
export const Session25 = [
	{
		id: 25,
		answers: ['avslutning', 'bestemor', 'scenekanten', 'eplepai'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'av',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'eple',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'pai',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'mor',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'beste',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'slutning',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'kanten',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'scene',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'av',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['slutning'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'eple',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['pai'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'pai',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['eple'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'mor',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['beste'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'beste',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['mor'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'slutning',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['av'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'kanten',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['scene'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'scene',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['kanten'],
				isCorrect: false,
			},
		],
	},
];
export const Session26 = [
	{
		id: 26,
		answers: ['snøhaug', 'nålepute', 'piggsvinet', 'maursuppe'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'snø',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'maur',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'suppe',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'haug',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'nåle',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'svinet',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'pigg',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'pute',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'snø',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['haug'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'maur',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['suppe'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'suppe',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['maur'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'haug',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['snø'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'nåle',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['pute'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'svinet',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['pigg'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'pigg',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['svinet'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'pute',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['nåle'],
				isCorrect: false,
			},
		],
	},
];
export const Session27 = [
	{
		id: 27,
		answers: ['steintårnet', 'tilbake', 'forhekset', 'eiketre'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'stein',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'tre',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'eike',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'tårnet',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'bake',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'hekset',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'stein',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['tårnet'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'tre',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['eike'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'eike',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['tre'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'tårnet',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['stein'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['hekset'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'bake',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['til'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['bake'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'hekset',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['for'],
				isCorrect: false,
			},
		],
	},
];
export const Session28 = [
	{
		id: 28,
		answers: ['øyhoppere', 'ferieøy', 'brøytebil', 'nakenhund'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'øy',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'hund',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'naken',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'hoppere',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'ferie',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'bil',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'brøyte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'øy',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'øy',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['hoppere', 'ferie'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'hund',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['naken'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'naken',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['hund'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'hoppere',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['øy'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'ferie',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['øy'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'bil',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['brøyte'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'brøyte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['bil'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'øy',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['ferie', 'hoppere'],
				isCorrect: false,
			},
		],
	},
];
export const Session29 = [
	{
		id: 29,
		answers: ['Luciadagen', 'julaften', 'pinnekjøtt', 'sløyfebånd'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'Lucia',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'bånd',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'sløyfe',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'dagen',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'pinne',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'aften',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'jul',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'kjøtt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'Lucia',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['dagen'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'bånd',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['sløyfe'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'sløyfe',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['bånd'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'dagen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['Lucia'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'pinne',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['kjøtt'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'aften',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['jul'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'jul',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['aften'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'kjøtt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['pinne'],
				isCorrect: false,
			},
		],
	},
];
export const Session30 = [
	{
		id: 29,
		answers: ['kristtorn', 'julepudding', 'julaften', 'bongbong'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'krist',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'bong',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'bong',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'torn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'jul',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'pudding',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'jule',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'aften',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'krist',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['torn'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'bong',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['bong'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'bong',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['bong'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'torn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['krist'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'jul',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['aften'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'pudding',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['jule'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'jule',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['pudding'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'aften',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['jul'],
				isCorrect: false,
			},
		],
	},
];
export const Session31 = [
	{
		id: 29,
		answers: ['sjømat', 'kjempegod', 'vaniljekrem', 'julebusker'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'sjø',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'busker',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'jule',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'mat',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'vanilje',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'god',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'kjempe',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'krem',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'sjø',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['mat'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'busker',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['jule'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'jule',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['busker'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'mat',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['sjø'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'vanilje',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['krem'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'god',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['kjempe'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'kjempe',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['god'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'krem',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['vanilje'],
				isCorrect: false,
			},
		],
	},
];
export const Session32 = [
	{
		id: 29,
		answers: ['julebukk', 'tilbake', 'fjøsnisse', 'julenisse'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'jule',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'nisse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'jule',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'bukk',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'nisse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'fjøs',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'bake',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'jule',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['bukk', 'nisse'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'nisse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['jule', 'fjøs'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'jule',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['nisse', 'bukk'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'bukk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['jule'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['bake'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'nisse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['fjøs', 'jule'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'fjøs',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['nisse'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'bake',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['til'],
				isCorrect: false,
			},
		],
	},
];
export const Session33 = [
	{
		id: 29,
		answers: ['nyttår', 'vårfestivalen', 'godsaker', 'dragekostymer'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'nytt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'kostymer',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'drage',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'år',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'vår',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'saker',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'god',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'festivalen',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'nytt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['år'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'kostymer',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['drage'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'drage',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['kostymer'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'år',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['nytt'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'vår',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['festivalen'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'saker',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['god'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'god',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['saker'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'festivalen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['vår'],
				isCorrect: false,
			},
		],
	},
];
export const Session34 = [
	{
		id: 34,
		answers: ['blomsterbil', 'politibil', 'alltid', 'blåklokker'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'blomster',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'klokker',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'blå',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'bil',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'politi',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'tid',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'all',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'bil',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'blomster',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['bil'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'klokker',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['blå'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'blå',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['klokker'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'bil',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['politi', 'blomster'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'politi',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['bil'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'tid',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['all'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'all',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['tid'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'bil',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['blomster', 'politi'],
				isCorrect: false,
			},
		],
	},
];
export const Session35 = [
	{
		id: 34,
		answers: ['bråbremse', 'bråkjekke', 'brannvesenet', 'tyvlånte'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'brå',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'lånte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'bremse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'brå',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'tyv',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'vesenet',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'brann',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'kjekke',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'brå',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['bremse', 'kjekke'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'lånte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['tyv'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'bremse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['brå'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'brå',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['kjekke', 'bremse'],
				isCorrect: false,
			},

			{
				id: 4,
				word: 'tyv',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['lånte'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'vesenet',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['brann'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'brann',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['vesenet'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'kjekke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['brå'],
				isCorrect: false,
			},
		],
	},
];
export const Session36 = [
	{
		id: 34,
		answers: ['drømmefangeren', 'nattdrakt', 'drittvær', 'lukkøye'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'drømme',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'øye',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'lukk',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'fangeren',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'natt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'vær',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'dritt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'drakt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'drømme',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['fangeren'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'øye',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['lukk'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'lukk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['øye'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'fangeren',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['drømme'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'natt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['drakt'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'vær',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['dritt'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'dritt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['vær'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'drakt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['natt'],
				isCorrect: false,
			},
		],
	},
];
export const Session37 = [
	{
		id: 29,
		answers: ['flammefest', 'utflukt', 'glassflaske', 'fridag'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'flamme',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'dag',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'fri',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'fest',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'ut',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'flaske',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'glass',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'flukt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'flamme',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['fest'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'dag',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['fri'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'fri',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['dag'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'fest',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['flamme'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'ut',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['flukt'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'flaske',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['glass'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'glass',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['flaske'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'flukt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['ut'],
				isCorrect: false,
			},
		],
	},
];
export const Session38 = [
	{
		id: 29,
		answers: ['verdensrommet', 'solsystemet', 'tyngdekraft', 'kjempestor'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'verdens',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'stor',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'kjempe',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'rommet',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'sol',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'kraft',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'tyngde',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'systemet',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'verdens',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['rommet'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'stor',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['kjempe'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'kjempe',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['stor'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'rommet',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['verdens'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'sol',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['systemet'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'kraft',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['tyngde'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'tyngde',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['kraft'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'systemet',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['sol'],
				isCorrect: false,
			},
		],
	},
];
export const Session39 = [
	{
		id: 29,
		answers: ['steinplanet', 'overflater', 'grunnlaget', 'jernrike'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'stein',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'rike',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'jern',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'planet',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'over',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'laget',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'grunn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'flater',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'stein',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['planet'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'rike',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['jern'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'jern',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['rike'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'planet',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['stein'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'over',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['flater'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'laget',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['grunn'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'grunn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['laget'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'flater',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['over'],
				isCorrect: false,
			},
		],
	},
];
export const Session40 = [
	{
		id: 29,
		answers: ['gasskjemper', 'kjennetegnet', 'hundrevis', 'supersonisk'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'gass',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'sonisk',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'super',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'kjemper',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'hundre',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'tegnet',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'kjenne',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'vis',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'gass',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['kjemper'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'sonisk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['super'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'super',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['sonisk'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'kjemper',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['gass'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'hundre',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['vis'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'tegnet',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['kjenne'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'kjenne',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['tegnet'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'vis',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['hundre'],
				isCorrect: false,
			},
		],
	},
];

export const Session41 = [
	{
		id: 29,
		answers: ['byttedyr', 'grønnsaker', 'elgokse', 'pattedyr'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'bytte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'dyr',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'dyr',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'patte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'grønn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'okse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'elg',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'saker',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'bytte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['dyr'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'dyr',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['patte', 'bytte'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'dyr',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['bytte', 'patte'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'patte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['dyr'],
				isCorrect: false,
			},

			{
				id: 4,
				word: 'grønn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['saker'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'okse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['elg'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'elg',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['okse'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'saker',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['grønn'],
				isCorrect: false,
			},
		],
	},
];
export const Session42 = [
	{
		id: 42,
		answers: ['steinalderen', 'våpenskjold', 'gjennomsnitt', 'hulemaleri'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'stein',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'maleri',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'hule',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'alderen',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'våpen',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'snitt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'gjennom',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'skjold',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'stein',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['alderen'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'maleri',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['hule'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'hule',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['maleri'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'alderen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['stein'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'våpen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['skjold'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'snitt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['gjennom'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'gjennom',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['snitt'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'skjold',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['våpen'],
				isCorrect: false,
			},
		],
	},
];
export const Session43 = [
	{
		id: 42,
		answers: ['unntatt', 'altetende', 'beskytter', 'Isbjørn'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'unn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'bjørn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'Is',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'tatt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'alt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'skytter',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'etende',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'unn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['tatt'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'bjørn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['Is'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'Is',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['bjørn'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'tatt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['unn'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'alt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['skytter'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'skytter',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['be'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['skytter'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'etende',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['alt'],
				isCorrect: false,
			},
		],
	},
];

export const Session44 = [
	{
		id: 42,
		answers: ['rovdyr', 'kjøtteter', 'vinterpels', 'villsvin'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'rov',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'svin',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'vill',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'dyr',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'vinter',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'eter',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'kjøtt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'pels',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'rov',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['dyr'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'svin',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['vill'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'vill',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['svin'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'dyr',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['rov'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'vinter',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['pels'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'eter',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['kjøtt'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'kjøtt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['eter'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'pels',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['vinter'],
				isCorrect: false,
			},
		],
	},
];

export const Session45 = [
	{
		id: 42,
		answers: ['rødrev', 'vanligvis', 'utmerket', 'kostholdet'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'rød',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'holdet',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'kost',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'rev',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'ut',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'vis',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'vanlig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'merket',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'rød',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['rev'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'holdet',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['kost'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'kost',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['holdet'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'rev',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['rød'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'ut',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['merket'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'vis',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['vanlig'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'vanlig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['vis'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'merket',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['ut'],
				isCorrect: false,
			},
		],
	},
];

export const Session46 = [
	{
		id: 42,
		answers: ['nordsamisk', 'reinskinn', 'folkedrakt', 'samekofte'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'nord',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'kofte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'same',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'samisk',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'rein',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'drakt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'folke',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'skinn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'nord',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['samisk'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'kofte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['same'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'same',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['kofte'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'samisk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['nord'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'rein',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['skinn'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'drakt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['folke'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'folke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['drakt'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'skinn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['rein'],
				isCorrect: false,
			},
		],
	},
];

export const Session47 = [
	{
		id: 42,
		answers: ['sjøsamer', 'innlandet', 'reindrift', 'urfolk'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'sjø',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'folk',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'ur',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'samer',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'rein',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'landet',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'inn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'drift',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'sjø',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['samer'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'folk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['ur'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'ur',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['folk'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'samer',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['sjø'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'rein',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['drift'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'landet',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['inn'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'inn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['landet'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'drift',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['rein'],
				isCorrect: false,
			},
		],
	},
];

export const Session48 = [
	{
		id: 42,
		answers: ['internatskole', 'lovendring', 'sameting', 'likeverdig'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'internat',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'verdig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'like',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'skole',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'lov',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'ting',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'same',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'endring',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'internat',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['skole'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'verdig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['like'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'like',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['verdig'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'skole',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['internat'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'lov',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['endring'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'ting',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['same'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'same',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['ting'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'endring',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['lov'],
				isCorrect: false,
			},
		],
	},
];

export const Session49 = [
	{
		id: 42,
		answers: ['landsmøte', 'nasjonaldag', 'samefolket', 'festklær'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'lands',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'klær',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'fest',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'møte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'nasjonal',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'folket',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'same',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'dag',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'lands',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['møte'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'klær',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['fest'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'fest',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['klær'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'møte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['lands'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'nasjonal',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['dag'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'folket',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['same'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'same',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['folket'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'dag',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['nasjonal'],
				isCorrect: false,
			},
		],
	},
];

export const Session50 = [
	{
		id: 42,
		answers: ['tidsepoke', 'steinvåpen', 'steinalderen', 'landsbyer'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'tids',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'våpen',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'lands',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'epoke',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'stein',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'alderen',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'stein',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 7,
				word: 'byer',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'tids',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['epoke'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'våpen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['stein'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'lands',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['byer'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'epoke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['tids'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'stein',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['våpen', 'alderen'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'alderen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['stein'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'stein',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['alderen', 'våpen'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'byer',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['lands'],
				isCorrect: false,
			},
		],
	},
];

export const Session51 = [
	{
		id: 42,
		answers: ['steinaldermennesker', 'hverdagen', 'redskapene', 'verktøy'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'steinalder',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'tøy',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'verk',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'mennesker',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'hver',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'skapene',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'red',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'dagen',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'steinalder',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['mennesker'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'tøy',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['verk'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'verk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['tøy'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'mennesker',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['steinalder'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'hver',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['dagen'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'skapene',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['red'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'red',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['skapene'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'dagen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['hver'],
				isCorrect: false,
			},
		],
	},
];

export const Session52 = [
	{
		id: 42,
		answers: ['fordele', 'anslå', 'oppbevare', 'gitarist'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 1,
				word: 'ist',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 2,
				word: 'gitar',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'slå',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'opp',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'dele',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'an',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'bevare',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['dele'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'ist',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['gitar'],
				isCorrect: false,
			},
			{
				id: 2,
				word: 'gitar',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['ist'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'slå',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['an'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'opp',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['bevare'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'dele',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['for'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'an',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['slå'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'bevare',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['opp'],
				isCorrect: false,
			},
		],
	},
];

export const Session53 = [
	{
		id: 42,
		answers: ['fortalt', 'ulike', 'utvinne', 'avkjølt', 'forkjølt', 'avtalt', 'uttalt', 'forlike'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disableCounter: 3,
				disable: false,
			},

			{
				id: 1,
				word: 'kjølt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'av',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'talt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'u',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'vinne',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'ut',
				className: defaultClassName,
				selectClassName: selectClassName,
				disableCounter: 3,
				disable: false,
			},

			{
				id: 7,
				word: 'like',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['talt', 'kjølt', 'like'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'kjølt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['av', 'for'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'av',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['kjølt', 'talt'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'talt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['for', 'ut', 'av'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'u',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['like'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'vinne',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['ut'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'ut',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['vinne', 'talt'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'like',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['u', 'for'],
				isCorrect: false,
			},
		],
	},
];

export const Session54 = [
	{
		id: 42,
		answers: ['såing', 'riktig', 'vanskelig', 'redskap'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'så',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'skap',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'red',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'ing',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'rikt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'vanske',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 7,
				word: 'ig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'så',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['ing'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'skap',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['red'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'red',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['skap'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'ing',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['så'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'rikt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['ig'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['vanske'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'vanske',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['lig'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'ig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['rikt'],
				isCorrect: false,
			},
		],
	},
];
export const Session55 = [
	{
		id: 42,
		answers: ['blanding', 'forhistorisk', 'oppfinne', 'omverden'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'bland',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'verden',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'om',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'ing',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'opp',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'historisk',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 7,
				word: 'finne',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'bland',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['ing'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'verden',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['om'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'om',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['verden'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'ing',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['bland'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'opp',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['finne'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'historisk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['for'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['historisk'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'finne',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['opp'],
				isCorrect: false,
			},
		],
	},
];

export const Session56 = [
	{
		id: 42,
		answers: ['innføre', 'overleve', 'tilbake', 'beskytte', 'overføre', 'tilføre'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'inn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'skytte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'føre',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'over',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'bake',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,
				disableCounter: 3,
				disable: false,
			},

			{
				id: 7,
				word: 'leve',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'inn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['føre'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'skytte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['be'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['skytte'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'føre',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['inn', 'over', 'til'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'over',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['leve', 'føre'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'bake',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['til'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['bake', 'føre'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'leve',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['over'],
				isCorrect: false,
			},
		],
	},
];

export const Session57 = [
	{
		id: 42,
		answers: ['ulike', 'redskap', 'oppdagelse', 'viking'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'u',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'ing',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'vik',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'like',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'oppdag',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'skap',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'red',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 7,
				word: 'else',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'u',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['like'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'ing',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['vik'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'vik',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['ing'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'like',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['u'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'oppdag',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['else'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'skap',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['red'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'red',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['skap'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'else',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['oppdag'],
				isCorrect: false,
			},
		],
	},
];

export const Session58 = [
	{
		id: 42,
		answers: ['utforske', 'viking', 'gjenta', 'innbrudd', 'utbrudd', 'innta'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'ut',
				className: defaultClassName,
				selectClassName: selectClassName,
				disableCounter: 3,
				disable: false,
			},

			{
				id: 1,
				word: 'brudd',
				className: defaultClassName,
				disableCounter: 3,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'inn',
				disableCounter: 3,
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'forske',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'vik',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'ta',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'gjen',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'ing',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'ut',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['forske', 'brudd'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'brudd',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['inn', 'ut'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'inn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['brudd', 'ta'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'forske',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['ut'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'vik',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['ing'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'ta',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['gjen', 'inn'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'gjen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['ta'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'ing',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['vik'],
				isCorrect: false,
			},
		],
	},
];

export const Session59 = [
	{
		id: 42,
		answers: ['utmerke', 'oppdra', 'betyr', 'tilpasninger'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'ut',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'pasninger',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'merke',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'opp',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'tyr',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'dra',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'ut',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['merke'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'pasninger',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['til'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['pasninger'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'merke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['ut'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'opp',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['dra'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'tyr',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['be'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['tyr'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'dra',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['opp'],
				isCorrect: false,
			},
		],
	},
];

export const Session60 = [
	{
		id: 42,
		answers: ['betyr', 'område', 'vegetasjon', 'silkeaktig'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'silke',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'tyr',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'aktig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'om',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'sjon',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'vegeta',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'råde',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'silke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['aktig'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'tyr',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['be'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['tyr'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'aktig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['silke'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'om',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['råde'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'sjon',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['vegeta'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'vegeta',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['sjon'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'råde',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['om'],
				isCorrect: false,
			},
		],
	},
];

export const Session61 = [
	{
		id: 42,
		answers: ['tilhøre', 'varmblodig', 'vanligvis', 'farlig'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'varmblod',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'høre',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'ig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'vanlig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'far',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'vis',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'varmblod',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['ig'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'høre',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['til'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['høre'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'ig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['varmblod'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'vanlig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['vis'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['far'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'far',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['lig'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'vis',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['vanlig'],
				isCorrect: false,
			},
		],
	},
];

export const Session62 = [
	{
		id: 42,
		answers: ['ettersom', 'angripe', 'vanligvis', 'forskjellig'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'etter',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'skjellig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'an',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'vis',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'vanlig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'som',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'gripe',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'etter',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['som'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'skjellig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['for'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'an',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['gripe'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'vis',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['vanlig'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'vanlig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['vis'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'som',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['etter'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['skjellig'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'gripe',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['an'],
				isCorrect: false,
			},
		],
	},
];

export const Session63 = [
	{
		id: 42,
		answers: ['innbygger', 'forbrytelse', 'nødvendig', 'ettertanke'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'inn',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'tanke',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'ig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'nødvend',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'brytelse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'etter',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'bygger',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'inn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['bygger'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'tanke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['etter'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['brytelse'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'ig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['nødvend'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'nødvend',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['ig'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'brytelse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['for'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'etter',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['tanke'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'bygger',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['inn'],
				isCorrect: false,
			},
		],
	},
];

export const Session64 = [
	{
		id: 42,
		answers: ['bekjempe', 'utsette', 'brannøvelse', 'situasjon', 'besette', 'utkjempe'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'brannøv',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'kjempe',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'sette',
				className: defaultClassName,
				selectClassName: selectClassName,
				disableCounter: 2,
				disable: false,
			},
			{
				id: 4,
				word: 'ut',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
				disableCounter: 2,
			},
			{
				id: 5,
				word: 'sjon',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'situa',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'else',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'brannøv',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['else'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'kjempe',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['be', 'ut'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['kjempe', 'sette'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'sette',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['ut', 'be'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'ut',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['sette', 'kjempe'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'sjon',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['situa'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'situa',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['sjon'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'else',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['brannøv'],
				isCorrect: false,
			},
		],
	},
];

export const Session65 = [
	{
		id: 42,
		answers: ['fornøyd', 'bestille', 'erfaring', 'kolorist'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'ist',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'kolor',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'nøyd',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'ing',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'erfar',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'stille',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['nøyd'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'ist',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['kolor'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'kolor',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['ist'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'nøyd',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['for'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['stille'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'ing',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['erfar'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'erfar',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['ing'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'stille',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['be'],
				isCorrect: false,
			},
		],
	},
];

export const Session66 = [
	{
		id: 42,
		answers: ['veldig', 'vennskap', 'benytte', 'oppleve'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'veld',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'leve',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'opp',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'ig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'skap',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'venn',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'nytte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'veld',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['ig'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'leve',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['opp'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'opp',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['leve'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'ig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['veld'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['nytte'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'skap',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['venn'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'venn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['skap'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'nytte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['be'],
				isCorrect: false,
			},
		],
	},
];

export const Session67 = [
	{
		id: 42,
		answers: ['understreke', 'behov', 'spesialist', 'produksjon'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'under',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'sjon',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'spesial',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'ist',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'hov',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'produk',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'streke',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'under',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['streke'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'sjon',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['produk'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['hov'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'spesial',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['ist'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'ist',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['spesial'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'hov',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['be'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'produk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['sjon'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'streke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['under'],
				isCorrect: false,
			},
		],
	},
];

export const Session68 = [
	{
		id: 42,
		answers: ['ansvar', 'innkjøp', 'tiltalende', 'renslig'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'an',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'talende',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'inn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'rens',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'kjøp',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'svar',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'an',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['svar'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'talende',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['til'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'inn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['kjøp'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['rens'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'rens',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['lig'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'kjøp',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['inn'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['talende'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'svar',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['an'],
				isCorrect: false,
			},
		],
	},
];

export const Session69 = [
	{
		id: 42,
		answers: ['avfall', 'utrygt', 'sikkerhet', 'fiolinist', 'usikker'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'av',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'trygt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'sikker',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'ist',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'u',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'het',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'fiolin',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'fall',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'av',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['fall'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'trygt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['u'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'sikker',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['het', 'u'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'ist',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['fiolin'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'u',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['sikker', 'trygt'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'het',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['sikker'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'fiolin',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['ist'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'fall',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['av'],
				isCorrect: false,
			},
		],
	},
];

export const Session70 = [
	{
		id: 42,
		answers: ['gjenåpne', 'tilbringe', 'vennskap', 'bakeri'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'gjen',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'eri',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'bak',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 3,
				word: 'åpne',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'venn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'bringe',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'skap',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'gjen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['åpne'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'eri',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['bak'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'bak',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['eri'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'åpne',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['gjen'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'venn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['skap'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'bringe',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['til'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['bringe'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'skap',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['venn'],
				isCorrect: false,
			},
		],
	},
];

export const Session71 = [
	{
		id: 42,
		answers: ['lignende', 'omtale', 'oppdage', 'modig'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'opp',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'ende',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'lign',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'ig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'om',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'dage',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'mod',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'tale',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'opp',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['dage'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'ende',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['lign'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'lign',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['ende'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'ig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['mod'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'om',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['tale'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'dage',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['opp'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'mod',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['ig'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'tale',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['om'],
				isCorrect: false,
			},
		],
	},
];

export const Session72 = [
	{
		id: 42,
		answers: ['avstand', 'erfaring', 'fornøyd', 'overstått'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'av',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'stått',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'over',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'stand',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'erfar',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'nøyd',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'ing',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'av',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['stand'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'stått',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['over'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'over',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['stått'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'stand',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['av'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'erfar',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['ing'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'nøyd',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['for'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['nøyd'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'ing',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['erfar'],
				isCorrect: false,
			},
		],
	},
];

export const Session73 = [
	{
		id: 42,
		answers: ['flyselskap', 'foran', 'utstyr', 'slukking'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'flysel',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'ing',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'styr',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'ut',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'an',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'slukk',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'skap',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'flysel',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['skap'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'ing',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['slukk'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['an'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'styr',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['ut'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'ut',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['styr'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'an',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['for'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'slukk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['ing'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'skap',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['flysel'],
				isCorrect: false,
			},
		],
	},
];

export const Session74 = [
	{
		id: 42,
		answers: ['motorsyklist', 'kjøretøy', 'tilhenger', 'øvelse'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'motorsykl',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'else',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'øv',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'ist',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'kjøre',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'henger',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 6,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 7,
				word: 'tøy',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'motorsykl',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['ist'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'else',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['øv'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'øv',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['else'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'ist',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['motorsykl'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'kjøre',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['tøy'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'henger',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['til'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['henger'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'tøy',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['kjøre'],
				isCorrect: false,
			},
		],
	},
];

export const Session75 = [
	{
		id: 42,
		answers: ['tauing', 'størrelse', 'vanligvis', 'framover'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'tau',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'else',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'vanlig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'over',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'fram',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'vis',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'størr',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'ing',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'tau',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['ing'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'else',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['størr'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'vanlig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['vis'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'over',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['fram'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'fram',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['over'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'vis',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['vanlig'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'størr',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['else'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'ing',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['tau'],
				isCorrect: false,
			},
		],
	},
];

export const Session76 = [
	{
		id: 42,
		answers: ['gjenstand', 'ulike', 'vanligvis', 'nødvendig'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'gjen',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'ig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'nødvend',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'stand',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'u',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'vis',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 6,
				word: 'vanlig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 7,
				word: 'like',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'gjen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['stand'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'ig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['nødvend'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'nødvend',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['ig'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'stand',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['gjen'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'u',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['like'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'vis',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['vanlig'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'vanlig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['vis'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'like',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['u'],
				isCorrect: false,
			},
		],
	},
];

export const Session77 = [
	{
		id: 42,
		answers: ['stasjon', 'bestå', 'veldig', 'monorails'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'sta',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
			{
				id: 2,
				word: 'mono',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'rails',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'sjon',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 6,
				word: 'veld',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'ig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 7,
				word: 'stå',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'sta',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['sjon'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'rails',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['mono'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'mono',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['rails'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'sjon',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['sta'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['stå'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'ig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['veld'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'veld',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['ig'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'stå',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['be'],
				isCorrect: false,
			},
		],
	},
];

export const Session78 = [
	{
		id: 42,
		answers: ['ulykke', 'sikkerhet', 'tilfelle', 'foreldreløs'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'u',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'het',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'sikker',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'felle',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 4,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'løs',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 6,
				word: 'foreldre',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 7,
				word: 'lykke',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'u',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['lykke'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'het',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['sikker'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'sikker',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['het'],
				isCorrect: false,
			},

			{
				id: 3,
				word: 'felle',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['til'],
				isCorrect: false,
			},

			{
				id: 4,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['felle'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'løs',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['foreldre'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'foreldre',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['løs'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'lykke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['u'],
				isCorrect: false,
			},
		],
	},
];
export const Session79 = [
	{
		id: 42,
		answers: ['tilgjengelig', 'funksjon', 'mulighet', 'betyr'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'tyr',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'het',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 4,
				word: 'mulig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'sjon',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 6,
				word: 'funk',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 7,
				word: 'gjengelig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['gjengelig'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'tyr',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['be'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['tyr'],
				isCorrect: false,
			},

			{
				id: 3,
				word: 'het',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['mulig'],
				isCorrect: false,
			},

			{
				id: 4,
				word: 'mulig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['het'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'sjon',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['funk'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'funk',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['sjon'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'gjengelig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['til'],
				isCorrect: false,
			},
		],
	},
];

export const Session80 = [
	{
		id: 42,
		answers: ['glassaktig', 'offentlig', 'avling', 'utstyr'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'glass',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'offent',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'styr',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 4,
				word: 'avl',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 5,
				word: 'aktig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'ut',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 7,
				word: 'ing',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'glass',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['aktig'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['offent'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'offent',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['lig'],
				isCorrect: false,
			},

			{
				id: 3,
				word: 'styr',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['ut'],
				isCorrect: false,
			},

			{
				id: 4,
				word: 'avl',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['ing'],
				isCorrect: false,
			},

			{
				id: 5,
				word: 'aktig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['glass'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'ut',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['styr'],
				isCorrect: false,
			},
			{
				id: 7,
				word: 'ing',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['avl'],
				isCorrect: false,
			},
		],
	},
];

export const Session81 = [
	{
		id: 42,
		answers: ['tillatelse', 'tidlig', 'mislike', 'avtale'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 1,
				word: 'tale',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'av',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 4,
				word: 'tid',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'like',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 6,
				word: 'mis',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 7,
				word: 'latelse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['latelse'],
				isCorrect: false,
			},
			{
				id: 1,
				word: 'tale',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['av'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'av',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['tale'],
				isCorrect: false,
			},

			{
				id: 3,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['tid'],
				isCorrect: false,
			},

			{
				id: 4,
				word: 'tid',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['lig'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'like',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['mis'],
				isCorrect: false,
			},
			{
				id: 6,
				word: 'mis',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['like'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'latelse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['til'],
				isCorrect: false,
			},
		],
	},
];

export const Session82 = [
	{
		id: 42,
		answers: ['åpning', 'veldig', 'forby', 'utforske'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'ig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'veld',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'ning',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'ut',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'by',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'åp',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'forske',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['by'],
				isCorrect: false,
			},

			{
				id: 1,
				word: 'ig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['veld'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'veld',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['ig'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'ning',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['åp'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'ut',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['forske'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'by',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['for'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'åp',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['ning'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'forske',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['ut'],
				isCorrect: false,
			},
		],
	},
];

export const Session83 = [
	{
		id: 42,
		answers: ['avslutte', 'vanlig', 'mottatt', 'veldig'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'av',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'tatt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'mot',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'slutte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'van',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'ig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'veld',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'av',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['slutte'],
				isCorrect: false,
			},

			{
				id: 1,
				word: 'tatt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['mot'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'mot',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['tatt'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'slutte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['av'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'van',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['lig'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'ig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['veld'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'veld',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['ig'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['van'],
				isCorrect: false,
			},
		],
	},
];

export const Session84 = [
	{
		id: 42,
		answers: ['forent', 'godkjent', 'tradisjon', 'endring'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'endr',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'ent',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'tradi',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'kjent',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'god',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'sjon',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'ing',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'endr',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['ing'],
				isCorrect: false,
			},

			{
				id: 1,
				word: 'ent',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['for'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'tradi',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['sjon'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'kjent',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['god'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'god',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['kjent'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'sjon',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['tradi'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['ent'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'ing',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['endr'],
				isCorrect: false,
			},
		],
	},
];

export const Session85 = [
	{
		id: 42,
		answers: ['benytte', 'forkjølelse', 'naturlig', 'såing'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'kjølelse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'nytte',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'natur',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'ing',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'så',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['nytte'],
				isCorrect: false,
			},

			{
				id: 1,
				word: 'kjølelse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['for'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['kjølelse'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'nytte',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['be'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'natur',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['lig'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'ing',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['så'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'så',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['ing'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['natur'],
				isCorrect: false,
			},
		],
	},
];

export const Session86 = [
	{
		id: 42,
		answers: ['tidlig', 'forlatt', 'bestøve', 'kunnskapsrik'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'tid',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'rik',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'støve',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'latt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'kunnskaps',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'tid',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['lig'],
				isCorrect: false,
			},

			{
				id: 1,
				word: 'rik',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['kunnskaps'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['latt'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'støve',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['be'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['støve'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'latt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['for'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'kunnskaps',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['rik'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['tid'],
				isCorrect: false,
			},
		],
	},
];

export const Session87 = [
	{
		id: 42,
		answers: ['utvikle', 'bijobb', 'søtaktig', 'tankefull'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'ut',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'jobb',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'bi',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'vikle',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'søt',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'full',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'tanke',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'aktig',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'ut',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['vikle'],
				isCorrect: false,
			},

			{
				id: 1,
				word: 'jobb',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['bi'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'bi',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['jobb'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'vikle',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['ut'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'søt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['aktig'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'full',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['tanke'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'tanke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['full'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'aktig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['søt'],
				isCorrect: false,
			},
		],
	},
];

export const Session88 = [
	{
		id: 42,
		answers: ['bevare', 'bismak', 'veldig', 'kjølig'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'be',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'kjø',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'vare',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'vel',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'smak',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'bi',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'dig',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'be',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['vare'],
				isCorrect: false,
			},

			{
				id: 1,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['kjø'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'kjø',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['lig'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'vare',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['be'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'vel',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['dig'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'smak',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['bi'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'bi',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['smak'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'dig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['vel'],
				isCorrect: false,
			},
		],
	},
];

export const Session89 = [
	{
		id: 42,
		answers: ['skadelig', 'gjenbruke', 'avfall', 'advarsel'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'skade',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'sel',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'gjen',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'av',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'bruke',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'advar',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'fall',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'skade',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['lig'],
				isCorrect: false,
			},

			{
				id: 1,
				word: 'sel',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['advar'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'gjen',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['bruke'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['skade'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'av',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['fall'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'bruke',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['gjen'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'advar',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['sel'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'fall',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['av'],
				isCorrect: false,
			},
		],
	},
];

export const Session90 = [
	{
		id: 42,
		answers: ['unngå', 'avrundet', 'overflaten', 'medlemmer'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'unn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'flaten',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'over',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'gå',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'av',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'lemmer',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'med',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'rundt',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'unn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['gå'],
				isCorrect: false,
			},

			{
				id: 1,
				word: 'flaten',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['over'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'over',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['flaten'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'gå',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['unn'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'av',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['rundet'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'lemmer',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['med'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'med',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['lemmer'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'rundt',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['av'],
				isCorrect: false,
			},
		],
	},
];

export const Session91 = [
	{
		id: 42,
		answers: ['opptil', 'vanlig', 'lureri', 'angripe'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'opp',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'van',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'lur',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'gripe',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'an',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'eri',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'opp',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['til'],
				isCorrect: false,
			},

			{
				id: 1,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['van'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'van',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['lig'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['opp'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'lur',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['eri'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'gripe',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['an'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'an',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['gripe'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'eri',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['lur'],
				isCorrect: false,
			},
		],
	},
];

export const Session92 = [
	{
		id: 42,
		answers: ['tilbringe', 'uhell', 'vanskelig', 'forvirret'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'til',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'for',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'vanske',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'bringe',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'virret',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'hell',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'u',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'lig',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'til',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['bringe'],
				isCorrect: false,
			},

			{
				id: 1,
				word: 'for',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['virret'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'vanske',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['lig'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'bringe',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['til'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'virret',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['for'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'hell',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['u'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'u',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['hell'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'lig',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['vanske'],
				isCorrect: false,
			},
		],
	},
];

export const Session93 = [
	{
		id: 42,
		answers: ['virvelløse', 'fremover', 'unnslippe', 'frigjør'],
		totalMatches: 4,
		matchingWord: [
			{
				id: 0,
				word: 'virvel',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 1,
				word: 'slippe',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 2,
				word: 'frem',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 3,
				word: 'løse',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 4,
				word: 'unn',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},
			{
				id: 5,
				word: 'gjør',
				className: defaultClassName,
				selectClassName: selectClassName,
				disable: false,
			},

			{
				id: 6,
				word: 'fri',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},

			{
				id: 7,
				word: 'over',
				className: defaultClassName,
				selectClassName: selectClassName,

				disable: false,
			},
		],
		defaultMatchingWordsAnime: [
			{
				id: 0,
				word: 'virvel',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 3,
				matchingWord: ['løse'],
				isCorrect: false,
			},

			{
				id: 1,
				word: 'slippe',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 4,
				matchingWord: ['unn'],
				isCorrect: false,
			},

			{
				id: 2,
				word: 'frem',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 7,
				matchingWord: ['over'],
				isCorrect: false,
			},
			{
				id: 3,
				word: 'løse',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 0,
				matchingWord: ['virvel'],
				isCorrect: false,
			},
			{
				id: 4,
				word: 'unn',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 1,
				matchingWord: ['slippe'],
				isCorrect: false,
			},
			{
				id: 5,
				word: 'gjør',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 6,
				matchingWord: ['fri'],
				isCorrect: false,
			},

			{
				id: 6,
				word: 'fri',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 5,
				matchingWord: ['gjør'],
				isCorrect: false,
			},

			{
				id: 7,
				word: 'over',
				correct: 'rw-mw-container__Questions__correct',
				wrong: 'rw-mw-container__Questions__wrong',
				matchingId: 2,
				matchingWord: ['frem'],
				isCorrect: false,
			},
		],
	},
];
