import getFetchOptions from '../utils/getFetchOptions';
import { ENPOINT_URL } from '../constants/index';

export default function updateCandidateInfo(info) {
	const userInfo = { user: info };

	return fetch(
		`${ENPOINT_URL}/updateuser`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify(userInfo),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			console.log(' Error has occured ', err);
		});
}
