import React from 'react';
import fireworks from '../../assets/fireworksGif.gif';

import { end } from '../../utils/beforeunload';

class Finish extends React.Component {
	componentDidMount() {
		end();
		localStorage.removeItem('authtoken-readwell');
		setTimeout(function () {
			window.location = '/';
		}, 10000);
	}
	render() {
		return (
			<div>
				<h1 className='rw-page-title'></h1>
				<p className='rw-page-description'>
					{`«Gratulerer! Du har nå gjennomført hele leksjonen!»`}{' '}
				</p>

				<div>
					<div className='rw-fw-fireworks'>
						<img className='rw-fw-fireworks-img' src={fireworks} />
					</div>
				</div>
			</div>
		);
	}
}
export default Finish;
