import React from 'react';
import { connect } from 'react-redux';
import * as data from './data/no-data';
import introAudio from '../../../../assets/sounds/word-synonyms-intro.m4a';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
import TimerCount from '../../../TimerCount';
import AudioPlayer from '../../../AudioPlayer';
import './styles.scss';

const getAllSessionData = (session) => {
	const sessionIndex = `session${session}`;
	const sessionData = data[sessionIndex][0];
	return sessionData;
};
let clicks = 0;
const wordsArray = [];
const resultsGloabl = [];
let correctInFirstAttempt = true;
class WordSynonymous extends React.Component {
	constructor(props) {
		super(props);
		this.audio = introAudio;
		this.sessionData = getAllSessionData(this.props.selectedSession);
		this.state = {
			sessionsData: this.sessionData,
			currentClick: null,
			previousClick: null,
			rendernext: false,
		};
	}
	handleAction(index, single) {
		clicks = clicks + 1;
		if (clicks % 2 === 0) {
			if (this.state.previousClick === single.word) {
				if (!this.state.rendernext)
					correctInFirstAttempt
						? this.props.flipCoin(3, true)
						: this.props.flipCoin(1, false);
				this.updateResults(this.state.previousClick, single.word, { isCorrect: true });
				for (let i = 0; i < this.sessionData.allWords.length; i++) {
					if (this.sessionData.allWords[i].synonym) {
						this.sessionData.allWords[i].isCorrect = true;
						this.sessionData.allWords[i].isAnswered = true;
					}
				}
				this.setState({ sessionsData: this.sessionData, rendernext: true });
			} else {
				if (this.state.rendernext) return;
				correctInFirstAttempt = false;
				this.props.recordMistake();
				this.updateResults(this.state.previousClick, single.word, { isCorrect: false });

				for (let i = 0; i < this.sessionData.allWords.length; i++) {
					this.sessionData.allWords[i].isCorrect = false;
					this.sessionData.allWords[i].isAnswered = true;
					this.sessionData.allWords[i].isSelected = false;
				}
				this.setState({ sessionsData: this.sessionData, rendernext: false });

				setTimeout(() => {
					for (let i = 0; i < this.sessionData.allWords.length; i++) {
						this.sessionData.allWords[i].isCorrect = false;
						this.sessionData.allWords[i].isAnswered = false;
						this.sessionData.allWords[i].isSelected = false;
					}
					this.setState({ sessionsData: this.sessionData, rendernext: false });
				}, 1200);
			}
		} else {
			if (this.state.rendernext) return;
			this.sessionData.allWords[index].isSelected = true;
			this.setState({ previousClick: single.synonym, sessionsData: this.sessionData });
		}
	}
	updateResults(firstWord, secondWord, status) {
		resultsGloabl.push({ firstWord: firstWord, secondWord: secondWord, status });
	}
	render() {
		const { onNextStep } = this.props;
		const { sessionsData } = this.state;
		const { allWords } = sessionsData;
		const { answers } = allWords;
		let audioPlayerRef;
		return (
			<div>
				<div className='rw-lp-intro'>
					<div className='rw-infobar'>
						<TimerCount
							duration={64}
							onComplete={() => {
								this.setState({ rendernext: true });
							}}
						/>
					</div>
					<div className='rw-intro-text'>
						<p>
							<AudioPlayer
								autoPlay
								inline
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								src={this.audio}
							/>
							Trykk på ordene som betyr de samme.
						</p>
					</div>

					<br></br>
					<br></br>
					<div key={`rw-lk-${'mainIndex'}`} className='rw-ws-taskWrapper'>
						{allWords.map((single, index) => {
							let word = single.word + '      ';

							return (
								<span
									className={
										allWords[index].isCorrect &&
										allWords[index].isAnswered
											? 'rw-ws-taskWrapper__correct'
											: allWords[index]
													.isAnswered
											? 'rw-ws-taskWrapper__wrong'
											: allWords[index]
													.isSelected
											? 'rw-ws-taskWrapper__select'
											: 'rw-ws-taskWrapper__task'
									}
									id={index}
									key={`index-${index}`}
									onClick={() => {
										this.handleAction(
											index,
											single,
											answers
										);
									}}
								>
									<p> {`${word} `}</p>
								</span>
							);
						})}
					</div>
				</div>
				<br></br>
				{(this.state.rendernext || this.props.isSuperTeacher) && (
					<div className='rw-next-btn'>
						<button
							className='rw-btn bg-primary'
							onClick={() =>
								onNextStep(
									resultsGloabl,
									ALL_SUBTESTS.WORDSYNONYMOUS
								)
							}
						>
							{'Neste'}
						</button>
					</div>
				)}
			</div>
		);
	}
}
export default WordSynonymous;
