import React from 'react';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
import { connect } from 'react-redux';
import * as data from './data/no-data';
import AudioPlayer from '../../../AudioPlayer';
import printIcon from '../../../../assets/printerIcon.svg';
import printIconAnime from '../../../../assets/printerIconAnime.svg';
import './styles.scss';

const getOpeningIntro = (session) => {
	const indexValue = `session${session}`;

	const openingIntro = data[indexValue][0];
	return openingIntro;
};
const maxNavigation = 3;
let currentNavigation = 1;
class OpeningIntro extends React.Component {
	constructor(props) {
		super(props);
		this.openingIntroData = getOpeningIntro(this.props.selectedSession);
		this.audio_intro = this.openingIntroData.introAudio;
		this.audio_questions = this.openingIntroData.questionAudio;
		this.audio_words = this.openingIntroData.wordsAudio;
		this.myRef = React.createRef();
		this.state = {
			currentPageStatus: 1,
			hasReadIntroText: true,
			hasListenedToQuestions: false,
			isReadingWords: false,
			currentPrintIcon: printIcon,

			showFinalButton: true,
			renderNext: false,
		};
	}

	render() {
		const { onNextStep } = this.props;
		const {
			mainHeading,
			subHeading,
			phonemeData,
			DaysTextWords,
			easySummary,
			summary,
			para,
			repeatParaHeading,
			helpingQuestionHeading,
			helpingQuestions,
			revisionParaHeading,
			mainImg,
			revisionWordsAndInstruction,
		} = this.openingIntroData;
		let audioPlayerRef;
		return (
			<div className='rw-lk-introWrapper' ref={this.myRef}>
				{!isInProduction && (
					<div
						className='print-icon'
						onMouseEnter={() => {
							this.setState({
								currentPrintIcon: printIconAnime,
							});
						}}
						onMouseLeave={() => {
							this.setState({
								currentPrintIcon: printIcon,
							});
						}}
						onClick={() => {
							window.print();
						}}
					>
						<img src={this.state.currentPrintIcon}></img>
					</div>
				)}

				<br></br>
				{this.state.currentPageStatus === 1 && (
					<div className={'rw-lk-paragraph'}>
						<div className='rw-lk-intro-testImg'>
							<img className='rw-front-page-img' src={mainImg}></img>
						</div>
						<div className='rw-blog'>
							<p className='rw-lk-Heading'>{mainHeading}</p>
						</div>
						<div className='rw-blog-heading-main'>
							<p className='rw-lk-paraHeading'>
								<AudioPlayer
									inline
									ref={(ref) => {
										audioPlayerRef = ref;
									}}
									src={this.audio_intro}
									onPlay={() => {}}
								/>
								{subHeading}
							</p>
						</div>
						<span className='rw-lk-EntirePara'>{para}</span>
					</div>
				)}

				<div>
					{this.state.currentPageStatus === 2 && (
						<div className={'rw-lk-paragraph'}>
							<div className='rw-blog-heading-main'>
								<p className='rw-lk-paraHeading'>
									<AudioPlayer
										inline
										ref={(ref) => {
											audioPlayerRef =
												ref;
										}}
										src={this.audio_intro}
										onPlay={() => {}}
									/>
									{subHeading}
								</p>
							</div>
							<span className='rw-lk-EntirePara'>{para}</span>

							<div>
								<div className={'rw-blog-heading'}>
									<p className='rw-lk-paraHeading'>
										<AudioPlayer
											inline
											ref={(ref) => {
												audioPlayerRef =
													ref;
											}}
											src={
												this
													.audio_questions
											}
											onPlay={() => {}}
										/>
										{repeatParaHeading}
									</p>
								</div>
								<div className={'rw-blog-para-summary__right'}>
									<p className='rw-lk-para__heavy'>
										{helpingQuestionHeading}
									</p>
									{helpingQuestions.map(
										(question, index) => {
											return (
												<p
													key={`key-${index}`}
													className='rw-lk-para'
												>
													{
														question
													}
												</p>
											);
										}
									)}
								</div>
							</div>
						</div>
					)}
				</div>

				<br></br>

				{this.state.currentPageStatus === 3 && (
					<div className='rw-lk-paragraph'>
						<div className='rw-blog-heading-main'>
							<p className='rw-lk-paraHeading'>
								<AudioPlayer
									inline
									ref={(ref) => {
										audioPlayerRef = ref;
									}}
									src={this.audio_intro}
									onPlay={() => {}}
								/>
								{subHeading}
							</p>
						</div>
						<span className='rw-lk-EntirePara'>{para}</span>

						<div className={'rw-blog-heading'}>
							<p className='rw-lk-paraHeading'>
								<AudioPlayer
									inline
									ref={(ref) => {
										audioPlayerRef = ref;
									}}
									src={this.audio_words}
									onPlay={() => {}}
									onComplete={() => {
										this.setState({
											hasReadIntroText: true,
										});
									}}
								/>
								{revisionWordsAndInstruction.revisionParaHeading}
							</p>
						</div>
						<div className={'rw-lk-revision'}>
							<div className='rw-lk-revision__points'>
								{revisionWordsAndInstruction.words.map(
									(word, index) => {
										return (
											<p
												key={`rw-lk-${index}`}
												className='rw-lk-para__forwords'
											>
												{word}
											</p>
										);
									}
								)}
							</div>
						</div>
						<div className={'rw-blog-heading'}>
							<p className='rw-lk-paraHeading'>
								{revisionWordsAndInstruction.revisionParaData}
							</p>
						</div>
						<br></br>
					</div>
				)}
				<div className={'rw-next-btn-bg-more'}>
					<button className='rw-page-pushable'>
						<span
							className={
								this.state.currentPageStatus === 1
									? 'rw-page-press-pressed'
									: 'rw-page-press'
							}
							onClick={() => {
								if (currentNavigation < maxNavigation) {
									this.props.onChangeProgressCounter();
									currentNavigation =
										currentNavigation + 1;
								}
								this.setState(
									{
										currentPageStatus: 1,
									},
									() => {
										this.myRef.current.scrollIntoView(
											{
												behavior: 'auto',
												block: 'end',
											}
										);
									}
								);
							}}
						>
							{this.state.currentPageStatus === 1 ? (
								<span style={{ marginLeft: '-2px' }}>{1}</span>
							) : this.state.currentPageStatus === 3 ? (
								<span style={{ marginLeft: '-5px' }}>{'<1'}</span>
							) : (
								<span style={{ marginLeft: '-5px' }}>{'<1'}</span>
							)}
						</span>
					</button>
					<button className='rw-page-pushable2'>
						<span
							className={
								this.state.currentPageStatus === 2
									? 'rw-page-press2-pressed'
									: 'rw-page-press2'
							}
							onClick={() => {
								if (currentNavigation < maxNavigation) {
									this.props.onChangeProgressCounter();
									currentNavigation =
										currentNavigation + 1;
								}

								this.setState(
									{
										currentPageStatus: 2,
										hasListenedToQuestions: true,
									},
									() => {
										this.myRef.current.scrollIntoView(
											{
												behavior: 'auto',
												block: 'end',
											}
										);
									}
								);
							}}
						>
							{this.state.currentPageStatus === 2 ? (
								<span style={{ marginRight: '10px' }}>2</span>
							) : this.state.currentPageStatus === 1 ? (
								<span style={{ marginLeft: '-5px' }}>{'2>'}</span>
							) : (
								<span style={{ marginLeft: '-5px' }}>{'<2'}</span>
							)}
						</span>
					</button>
					<button className='rw-page-pushable3'>
						<span
							className={
								this.state.currentPageStatus === 3
									? 'rw-page-press3-pressed'
									: 'rw-page-press3'
							}
							onClick={() => {
								if (currentNavigation < maxNavigation) {
									this.props.onChangeProgressCounter();
									currentNavigation =
										currentNavigation + 1;
								}
								this.setState(
									{
										currentPageStatus: 3,
										isReadingWords: true,
									},
									() => {
										this.myRef.current.scrollIntoView(
											{
												behavior: 'auto',
												block: 'end',
											}
										);
									}
								);
							}}
						>
							{this.state.currentPageStatus === 3 ? (
								'3'
							) : this.state.currentPageStatus === 1 ? (
								<span style={{ marginLeft: '-6px' }}>{'3>'}</span>
							) : (
								<span style={{ marginLeft: '-6px' }}>{'3>'}</span>
							)}
						</span>
					</button>
					{this.state.isReadingWords &&
						this.state.hasListenedToQuestions &&
						this.state.showFinalButton && (
							<span className='rw-btn bg-more'>
								<button
									className={'rw-btn bg-more'}
									onClick={() => {
										this.setState({
											renderNext: true,
											showFinalButton: false,
										});
									}}
								>
									{'>>'}
								</button>
							</span>
						)}
				</div>

				<div className={'rw-lk-paragraph-for-print'}>
					{Boolean(easySummary.length) && (
						<div className='rw-lk-intro-testImg'>
							<img className='rw-front-page-img' src={mainImg}></img>
						</div>
					)}

					{Boolean(easySummary.length) && (
						<div className='rw-blog'>
							<p className='rw-lk-Heading'>{mainHeading}</p>
						</div>
					)}

					<div className='rw-lk-intro__mainContent-for-print'>
						{Boolean(easySummary.length) && (
							<div className={'rw-blog-heading'}>
								<p className='rw-lk-paraHeading'>{`0. ${subHeading}`}</p>
							</div>
						)}
						{Boolean(easySummary.length) && (
							<div className='rw-blog-para-easysummary'>
								{easySummary.map((text, index) => {
									return (
										<p
											key={index}
											className='rw-lk-para'
										>
											{text}
										</p>
									);
								})}
							</div>
						)}

						<div className='rw-lk-intro-testImg'>
							<img className='rw-front-page-img' src={mainImg}></img>
						</div>
						<div className='rw-blog'>
							<p className='rw-lk-Heading'>{mainHeading}</p>
						</div>
						<div className={'rw-blog-heading'}>
							<p className='rw-lk-paraHeading'>{`1. ${subHeading}`}</p>
						</div>

						<div className='rw-blog-para-summary'>
							{summary.map((text, index) => {
								return (
									<p
										key={index}
										className='rw-lk-para-forPrint'
									>
										{text}
									</p>
								);
							})}
						</div>
					</div>

					<div className='rw-lk-intro-testImg'>
						<img className='rw-front-page-img' src={mainImg}></img>
					</div>
					<div className='rw-blog'>
						<p className='rw-lk-Heading'>{mainHeading}</p>
					</div>
					<div className='rw-blog-heading-main-2'>
						<p className='rw-lk-paraHeading'>{`2. ${subHeading}`}</p>
					</div>
					<div className='rw-blog-para-summary'>
						<p className='rw-lk-para'>{para}</p>
					</div>

					<div>
						<div className={'rw-blog-heading-for-print'}>
							<p className='rw-lk-paraHeading'>{repeatParaHeading}</p>
						</div>
						<div className={'rw-blog-para-summary__right'}>
							<p className='rw-lk-para__heavy'>
								{helpingQuestionHeading}
							</p>
							{helpingQuestions.map((question, index) => {
								return (
									<p
										key={`key-${index}`}
										className='rw-lk-para'
									>
										{question}
									</p>
								);
							})}
						</div>
					</div>

					<span className='rw-lk-EntirePara'></span>
					<div className={'rw-blog-heading'}>
						<p className='rw-lk-paraHeading'>
							{revisionWordsAndInstruction.revisionParaHeading}
						</p>
					</div>
					<div className={'rw-lk-revision'}>
						<div className='rw-lk-revision__points'>
							{revisionWordsAndInstruction.words.map((word, index) => {
								return (
									<p
										key={`rw-lk-${index}`}
										className='rw-lk-para'
									>
										{word}
									</p>
								);
							})}
						</div>
					</div>
					<div className={'rw-blog-heading'}>
						<p className='rw-lk-paraHeading'>
							{revisionWordsAndInstruction.revisionParaData}
						</p>
					</div>
					{Boolean(phonemeData.length) && (
						<div className={'rw-foneme-heading'}>
							<p className='rw-lk-paraHeading'>{'4. Fonemebevisthet'}</p>
						</div>
					)}
					{phonemeData.map((data, sessionIndex) => {
						const { precedingText, precedingWord, leadingText, leadingWord } =
							data;
						return (
							<div className='rw-blog-para-phonem'>
								<p>
									<span>{precedingText}</span>
									<span className='rw-pw-preWord'>
										{precedingWord}
									</span>
									<span>{leadingText}</span>
									<span className='rw-pw-leadingword'>
										{leadingWord}
									</span>
									<span>{' ?'}</span>
								</p>
							</div>
						);
					})}

					{Boolean(DaysTextWords.length) && (
						<div>
							<div className={'rw-foneme-heading'}>
								<p className='rw-lk-paraHeading'>
									{'5. Lesing av dagens ord'}
								</p>
							</div>
							<div className={'rw-lk-revision'}>
								<div className='rw-lk-revision__points'>
									{DaysTextWords.map((word, index) => {
										return (
											<p
												key={`rw-lk-${index}`}
												className='rw-lk-para'
											>
												{word}
											</p>
										);
									})}
								</div>
							</div>
						</div>
					)}
				</div>

				<br></br>
				{(this.state.renderNext || this.props.isSuperTeacher) && (
					<div className={'rw-next-btn'}>
						<button
							className='rw-btn bg-primary'
							onClick={() => onNextStep('', ALL_SUBTESTS.OPENINGINTRO)}
						>
							{'Neste'}
						</button>
					</div>
				)}
			</div>
		);
	}
}
const mapStateToProps = () => {
	return {};
};
export default connect(mapStateToProps)(OpeningIntro);
