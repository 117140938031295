import React from 'react';
import './styles.scss';

const SessionIndicator = (sessionObject) => {
	const userSession = `Leksjon - ${sessionObject.session - 1}`;

	return (
		<div className='rw-session-indicator info__col rw-session-status'>
			<h4>{userSession ? userSession : ''}</h4>
		</div>
	);
};

export default SessionIndicator;
