export const session1 = [
	{
		total: 7,
		id: 1,
		word: ['L', 'o', 'g', 'r', 'e', 'r'],
		possibilites: ['lo', 'og', 'gre', 're', 'er', 'logre', 'grer'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 7,
		id: 2,
		word: ['U', 's', 'i', 'k', 'k', 'e', 'r', 't'],
		possibilites: ['si', 'ikke', 'er', 'ert', 'sikker', 'usikker', 'sikkert'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session2 = [
	{
		total: 8,
		id: 1,
		word: ['S', 't', 'ø', 'v', 's', 'u', 'g', 'e', 'r'],
		possibilites: ['støv', 'tøv', 'øv', 'suge', 'er', 'suger', 'støvsuge', 'sug'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 7,
		id: 2,
		word: ['L', 'u', 'k', 't', 'e', 's', 'a', 'n', 's'],
		possibilites: ['lukt', 'te', 'sa', 'sans', 'luk', 'lukte', 'es'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session3 = [
	{
		total: 6,
		id: 1,
		word: ['B', 'a', 'd', 'e', 'a', 'n', 'd'],
		possibilites: ['ba', 'bad', 'bade', 'de', 'and', 'ad'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 9,
		id: 2,
		word: ['Ø', 'n', 's', 'k', 'e', 'l', 'i', 's', 't', 'e'],
		possibilites: ['ønske', 'li', 'liste', 'is', 'iste', 'te', 'i', 'list', 'ønsk'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session4 = [
	{
		total: 9,
		id: 1,
		word: ['D', 'a', 'n', 's', 'e', 's', 'k', 'o', 'l', 'e'],
		possibilites: ['da', 'dans', 'danse', 'se', 'sko', 'ole', 'le', 'skole', 'anse'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 9,
		id: 2,
		word: ['D', 'i', 'n', 'o', 's', 'a', 'u', 'r'],
		possibilites: ['di', 'din', 'in', 'os', 'sa', 'au', 'sau', 'ur', 'i'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session5 = [
	{
		total: 9,
		id: 1,
		word: ['S', 'p', 'a', 'r', 'k', 'e', 't'],
		possibilites: ['spa', 'spar', 'spark', 'sparke', 'par', 'park', 'ark', 'arket', 'et'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 4,
		id: 2,
		word: ['R', 'e', 'v', 'e', 't'],
		possibilites: ['re', 'rev', 'vet', 'et'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session6 = [
	{
		total: 11,
		id: 1,
		word: ['G', 'e', 'n', 's', 'e', 'r', 'e', 'n'],
		possibilites: ['gen', 'enser', 'se', 'ser', 'er', 'en', 'ren', 'genser', 'gensere', 'ense', 're'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 9,
		id: 2,
		word: ['S', 't', 'r', 'a', 'm', 'm', 'e', 'r'],
		possibilites: ['stram', 'stramme', 'ram', 'tram', 'ramme', 'rammer', 'mer', 'er', 'trammer'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session7 = [
	{
		total: 9,
		id: 1,
		word: ['H', 'ø', 's', 't', 'f', 'e', 'r', 'i', 'e'],
		possibilites: ['høst', 'øst', 'fe', 'ferie', 'er', 'ri', 'øs', 'rie', 'i'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 11,
		id: 2,
		word: ['H', 'e', 's', 't', 'e', 'm', 'ø', 'k', 'k'],
		possibilites: ['Hes', 'hest', 'te', 'mø', 'møkk', 'øk', 'es', 'este', 'ste', 'stem', 'tem'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session8 = [
	{
		total: 8,
		id: 1,
		word: ['K', 'a', 't', 't', 'e', 'd', 'y', 'r'],
		possibilites: ['katt', 'katte', 'att', 'dy', 'at', 'te', 'dyr', 'yr'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 12,
		id: 2,
		word: ['T', 'r', 'y', 'l', 'l', 'e', 's', 't', 'a', 'v'],
		possibilites: ['trylle', 'ry', 'le', 'les', 'sta', 'stave', 'av', 'lest', 'est', 'es', 'stav', 'ta'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session9 = [
	{
		total: 6,
		id: 1,
		word: ['K', 'a', 'k', 'e', 'l', 'a', 'n', 'd'],
		possibilites: ['kake', 'la', 'land', 'and', 'ake', 'al'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 8,
		id: 2,
		word: ['F', 'o', 'r', 's', 't', 'å', 't'],
		possibilites: ['for', 'or', 'stå', 'tå', 'stått', 'åt', 'å', 'forstå'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session10 = [
	{
		total: 8,
		id: 1,
		word: ['P', 'å', 'l', 'e', 'g', 'g'],
		possibilites: ['på', 'ål', 'påle', 'åle', 'le', 'legg', 'egg', 'å'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 10,
		id: 2,
		word: ['T', 'i', 'l', 'b', 'a', 'k', 'e'],
		possibilites: ['ti', 'til', 'ba', 'ake', 'bake', 'i', 'il', 'bak', 'ak', 'tilba'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session11 = [
	{
		total: 11,
		id: 1,
		word: ['M', 'e', 'l', 'k', 'e', 'v', 'e', 'i', 'e', 'n'],
		possibilites: ['mel', 'melk', 'melke', 'el', 'eie', 'veie', 'vei', 'veien', 'ei', 'en', 'i'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 15,
		id: 2,
		word: ['M', 'å', 'n', 'e', 'r', 'a', 'k', 'e', 't', 't', 'e', 'n'],
		possibilites: [
			'må',
			'måne',
			'er',
			'rakett',
			'et',
			'te',
			'en',
			'raketten',
			'å',
			'rak',
			'rake',
			'ake',
			'ak',
			'ett',
			'måner',
		],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session12 = [
	{
		total: 15,
		id: 1,
		word: ['N', 'i', 's', 't', 'e', 'p', 'a', 'k', 'k', 'e', 'n'],
		possibilites: [
			'ak',
			'ake',
			'ni',
			'is',
			'niste',
			'iste',
			'te',
			'pakke',
			'pakken',
			'en',
			'i',
			'ste',
			'pakk',
			'akk',
			'ak',
		],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 7,
		id: 2,
		word: ['B', 'ø', 'l', 'l', 'e', 'r'],
		possibilites: ['bø', 'øl', 'bølle', 'le', 'ler', 'er', 'bøll'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session13 = [
	{
		total: 13,
		id: 1,
		word: ['P', 'r', 'i', 'k', 'k', 'e', 't', 'e'],
		possibilites: [
			'prikket',
			'rikk',
			'rikket',
			'prikk',
			'prikke',
			'ri',
			'rik',
			'rikke',
			'ikke',
			'et',
			'ete',
			'te',
			'i',
		],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 8,
		id: 2,
		word: ['S', 'a', 'n', 'd', 'e', 'n'],
		possibilites: ['sa', 'sand', 'and', 'anden', 'de', 'den', 'en', 'an'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session14 = [
	{
		total: 9,
		id: 1,
		word: ['r', 'ø', 'v', 'e', 'r', 'n', 'e'],
		possibilites: ['røve', 'røver', 'øver', 'verne', 'vern', 'øv', 'øve', 'er', 'ne'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 10,
		id: 2,
		word: ['r', 'i', 's', 's', 'e', 'r'],
		possibilites: ['ri', 'ris', 'is', 'isse', 'ser', 'er', 'risse', 'riss', 'isser', 'se'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session15 = [
	{
		total: 9,
		id: 1,
		word: ['L', 'a', 'n', 'd', 'e', 'r'],
		possibilites: ['land', 'and', 'de', 'der', 'er', 'la', 'lande', 'la', 'an'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 11,
		id: 2,
		word: ['H', 'o', 's', 't', 'e', 'r'],
		possibilites: ['hos', 'ost', 'te', 'er', 'hoste', 'host', 'oster', 'oste', 'os', 'ste', 'ter'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session16 = [
	{
		total: 6,
		id: 1,
		word: ['t', 'a', 'n', 'n', 'l', 'e', 'g', 'e'],
		possibilites: ['ta', 'tann', 'ann', 'le', 'lege', 'an'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 20,
		id: 2,
		word: ['p', 'l', 'e', 'i', 'e', 'r', 'e', 'n'],
		possibilites: [
			'pleie',
			'leie',
			'leieren',
			'le',
			'eie',
			'eieren',
			'er',
			'ren',
			'en',
			'i',
			'pleiere',
			'pleier',
			'leiere',
			'leier',
			'lei',
			'eiere',
			'eier',
			'ei',
			're',
		],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session17 = [
	{
		total: 6,
		id: 1,
		word: ['v', 'i', 'k', 'i', 'n', 'g', 's', 'k', 'i', 'p'],
		possibilites: ['vi', 'vik', 'viking', 'ski', 'skip', 'i'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 10,
		id: 2,
		word: ['V', 'e', 'i', 'k', 'i', 'n', 'g', 'e', 'n'],
		possibilites: ['vei', 'ei', 'veik', 'eik', 'ingen', 'en', 'i', 'veiking', 'gen', 've'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session18 = [
	{
		total: 9,
		id: 1,
		word: ['S', 'p', 'ø', 'k', 'e', 'l', 's', 'e', 't'],
		possibilites: ['spøk', 'øk', 'øke', 'else', 'se', 'et', 'spøkelse', 'el', 'spøke'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 11,
		id: 2,
		word: ['A', 'l', 'l', 'e', 'h', 'e', 'l', 'g', 'e', 'n'],
		possibilites: ['all', 'helg', 'gen', 'alle', 'le', 'hel', 'helgen', 'elg', 'elgen', 'en', 'el'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session19 = [
	{
		total: 12,
		id: 1,
		word: ['t', 'r', 'e', 'k', 'k', 'f', 'u', 'g', 'l', 'e', 'r'],
		possibilites: ['fugler', 'tre', 're', 'trekk', 'rekk', 'fugl', 'ugle', 'ugler', 'le', 'ler', 'er', 'rek'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: '10',
		id: 2,
		word: ['e', 'n', 'd', 'r', 'i', 'n', 'g', 'e', 'r'],
		possibilites: ['in', 'ri', 'en', 'endring', 'ring', 'ringe', 'ringer', 'inger', 'er', 'i'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session20 = [
	{
		total: 11,
		id: 1,
		word: ['L', 'e', 't', 't', 'e', 'r', 'e'],
		possibilites: ['ett', 'let', 'le', 'et', 'lett', 'lette', 'etter', 'te', 'er', 're', 'letter'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 15,
		id: 2,
		word: ['L', 'e', 'd', 'e', 'f', 'u', 'g', 'l', 'e', 'n'],
		possibilites: [
			'len',
			'ed',
			'led',
			'le',
			'lede',
			'de',
			'fugl',
			'fuglen',
			'ugle',
			'uglen',
			'le',
			'en',
			'fug',
			'ledefugl',
			'len',
		],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session21 = [
	{
		total: 8,
		id: 1,
		word: ['S', 't', 'a', 't', 'e', 'r'],
		possibilites: ['sta', 'ta', 'at', 'te', 'er', 'stat', 'tater'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 11,
		id: 2,
		word: ['M', 'e', 'n', 'n', 'e', 's', 'k', 'e', 'r'],
		possibilites: ['eske', 'menn', 'men', 'en', 'enn', 'ne', 'nes', 'esker', 'er', 'menneske', 'es'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session22 = [
	{
		total: 8,
		id: 1,
		word: ['L', 'e', 'g', 'e', 'k', 'o', 'n', 't', 'o', 'r'],
		possibilites: ['leg', 'to', 'le', 'lege', 'konto', 'kontor', 'tor', 'or'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 13,
		id: 2,
		word: ['B', 'a', 'k', 't', 'e', 'r', 'i', 'e'],
		possibilites: ['rie', 'ak', 'bakt', 'bakte', 'ba', 'bak', 'akt', 'akte', 'akter', 'er', 'te', 'ri', 'i'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session23 = [
	{
		total: 7,
		id: 1,
		word: ['S', 'o', 'l', 's', 'k', 'i', 'n', 'n'],
		possibilites: ['in', 'sol', 'ski', 'skinn', 'kinn', 'inn', 'i'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 8,
		id: 2,
		word: ['V', 'a', 'r', 'm', 'e', 'r'],
		possibilites: ['var', 'varm', 'varme', 'arm', 'arme', 'armer', 'mer', 'er'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session24 = [
	{
		total: 13,
		id: 1,
		word: ['V', 'a', 'r', 'm', 'e', 'r', 'e'],
		possibilites: [
			'var',
			'varme',
			'varmer',
			'var',
			'arm',
			'arme',
			'armer',
			'mer',
			'mere',
			'er',
			're',
			'varm',
			'armere',
		],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 8,
		id: 2,
		word: ['V', 'å', 'r', 'e', 'n'],
		possibilites: ['våre', 'vår', 'år', 'åre', 'åren', 're', 'ren', 'en'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session25 = [
	{
		total: 5,
		id: 1,
		word: ['E', 'p', 'l', 'e', 'p', 'a', 'i'],
		possibilites: ['eple', 'le', 'pai', 'i', 'ai'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 7,
		id: 2,
		word: ['T', 'r', 'o', 'd', 'd', 'e'],
		possibilites: ['tro', 'ro', 'rodde', 'odde', 'de', 'trodd', 'rodd'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session26 = [
	{
		total: 11,
		id: 1,
		word: ['N', 'å', 'l', 'e', 'p', 'u', 't', 'e'],
		possibilites: ['nå', 'nål', 'ål', 'åle', 'le', 'pute', 'ut', 'ute', 'te', 'å', 'nåle'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 5,
		id: 2,
		word: ['L', 'e', 'n', 'g', 'e'],
		possibilites: ['le', 'en', 'eng', 'len', 'enge'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session27 = [
	{
		total: 8,
		id: 1,
		word: ['V', 'e', 'i', 'v', 'e', 't'],
		possibilites: ['vei', 'ei', 'veiv', 'veive', 'vet', 'et', 'i', 've'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 12,
		id: 2,
		word: ['L', 'e', 'i', 'r', 'e', 'n'],
		possibilites: ['le', 'lei', 'ei', 're', 'ren', 'en', 'leire', 'i', 'leir', 'eire', 'eir', 'iren'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session28 = [
	{
		total: 8,
		id: 1,
		word: ['F', 'i', 'n', 'n', 'e', 'r'],
		possibilites: ['fin', 'inn', 'inne', 'er', 'finn', 'finne', 'i', 'in'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 8,
		id: 2,
		word: ['S', 'e', 't', 't', 'e', 'r'],
		possibilites: ['se', 'et', 'sett', 'sette', 'etter', 'er', 'ett', 'te'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session29 = [
	{
		total: 7,
		id: 1,
		word: ['B', 'a', 'k', 'e', 'r'],
		possibilites: ['ak', 'ba', 'bak', 'bake', 'ake', 'aker', 'er'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 9,
		id: 2,
		word: ['j', 'u', 'l', 'e', 't', 'r', 'e'],
		possibilites: ['ul', 'let', 'jul', 'jule', 'ule', 'le', 'et', 'tre', 're'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session30 = [
	{
		total: 7,
		id: 1,
		word: ['S', 'n', 'i', 'l', 'l', 'e'],
		possibilites: ['snill', 'ni', 'ille', 'il', 'le', 'i'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 8,
		id: 2,
		word: ['S', 'a', 'm', 'l', 'e', 's'],
		possibilites: ['sa', 'samle', 'le', 'les', 'saml', 'sam', 'am', 'es'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session31 = [
	{
		total: 5,
		id: 1,
		word: ['P', 'a', 'r', 'k', 'e', 'n'],
		possibilites: ['par', 'park', 'ark', 'arken', 'en'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 4,
		id: 2,
		word: ['S', 'i', 'd', 'e'],
		possibilites: ['si', 'ide', 'de', 'i'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session32 = [
	{
		total: 6,
		id: 1,
		word: ['J', 'u', 'l', 'e', 'n'],
		possibilites: ['jul', 'ul', 'ule', 'le', 'en', 'len'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 8,
		id: 2,
		word: ['H', 'a', 'n', 'd', 'l', 'e', 't'],
		possibilites: ['ha', 'han', 'hand', 'and', 'let', 'le', 'et', 'handle'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session33 = [
	{
		total: 9,
		id: 1,
		word: ['N', 'y', 't', 't', 'å', 'r'],
		possibilites: ['ny', 'nyt', 'nytt', 'yt', 'tår', 'år', 'tå', 'a', 'ytt'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 8,
		id: 2,
		word: ['s', 'p', 'i', 's', 'e', 'r'],
		possibilites: ['spis', 'spise', 'is', 'iser', 'ser', 'er', 'i', 'ise', 'se'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session34 = [
	{
		total: 7,
		id: 1,
		word: ['V', 'e', 'i', 'e', 'n'],
		possibilites: ['vei', 'veie', 'ei', 'eie', 'en', 'i', 've'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 9,
		id: 2,
		word: ['B', 'l', 'e', 'i', 'k'],
		possibilites: ['blei', 'ble', 'le', 'lei', 'leik', 'eik', 'ei', 'i', 'ik'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session35 = [
	{
		total: 9,
		id: 1,
		word: ['B', 'r', 'a', 's', 't', 'e'],
		possibilites: ['bra', 'bras', 'ra', 'ras', 'rast', 'raste', 'te', 'brast', 'ste'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 5,
		id: 2,
		word: ['B', 'r', 'o', 'e', 'n'],
		possibilites: ['bro', 'ro', 'roe', 'roen', 'en'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session36 = [
	{
		total: 9,
		id: 1,
		word: ['S', 'l', 'u', 'k', 'e', 'r'],
		possibilites: ['slu', 'sluk', 'sluke', 'luker', 'luke', 'uke', 'uker', 'er', 'luk'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 10,
		id: 2,
		word: ['D', 'r', 'i', 'v', 'e', 'r'],
		possibilites: ['driv', 'drive', 'ri', 'riv', 'rive', 'river', 'iver', 'er', 'i', 've'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session37 = [
	{
		total: 4,
		id: 1,
		word: ['U', 't', 'f', 'l', 'u', 'k', 't'],
		possibilites: ['ut', 'flukt', 'lukt', 'luk'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 9,
		id: 2,
		word: ['F', 'l', 'å', 's', 'e'],
		possibilites: ['flå', 'låse', 'lås', 'lå', 'åse', 'ås', 'se', 'flås', 'å'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session38 = [
	{
		total: 8,
		id: 1,
		word: ['B', 'e', 's', 't', 'å'],
		possibilites: ['be', 'best', 'stå', 'tå', 'bes', 'es', 'est', 'å'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 6,
		id: 2,
		word: ['S', 'o', 'l', 'e', 'n'],
		possibilites: ['sole', 'sol', 'ole', 'le', 'len', 'en'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session39 = [
	{
		total: 3,
		id: 1,
		word: ['T', 'e', 'l', 'l', 'u', 's'],
		possibilites: ['tell', 'te', 'lus'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 6,
		id: 2,
		word: ['L', 'i', 'v', 'e', 't'],
		possibilites: ['live', 'liv', 'li', 'vet', 'et', 'i'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session40 = [
	{
		total: 5,
		id: 1,
		word: ['E', 'n', 'o', 'r', 'm', 't'],
		possibilites: ['enorm', 'en', 'norm', 'orm', 'or'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 5,
		id: 2,
		word: ['F', 'o', 'r', 'd', 'i'],
		possibilites: ['ford', 'for', 'ord', 'or', 'i'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session41 = [
	{
		total: 3,
		id: 1,
		word: ['E', 'l', 'g', 'o', 'k', 's', 'e'],
		possibilites: ['elg', 'okse', 'se'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 3,
		id: 2,
		word: ['B', 'j', 'ø', 'r', 'n'],
		possibilites: ['jørn', 'ørn', 'ør'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session42 = [
	{
		total: 7,
		id: 1,
		word: ['V', 'e', 'i', 'e', 'r'],
		possibilites: ['veie', 'vei', 'eier', 'eie', 'ei', 'er', 'i'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 5,
		id: 2,
		word: ['S', 'p', 'a', 'r', 'e'],
		possibilites: ['spar', 'spa', 'par', 'are', 're'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session43 = [
	{
		total: 6,
		id: 1,
		word: ['I', 's', 'b', 'j', 'ø', 'r', 'n'],
		possibilites: ['is', 'bjørn', 'ørn', 'jørn', 'i', 'ør'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 4,
		id: 2,
		word: ['K', 'r', 'y', 'p', 'e'],
		possibilites: ['kryp', 'kry', 'rype', 'ry'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session44 = [
	{
		total: 4,
		id: 1,
		word: ['R', 'o', 'v', 'd', 'y', 'r'],
		possibilites: ['rov', 'ro', 'dyr', 'yr'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 4,
		id: 2,
		word: ['U', 'l', 'v', 'e', 'r'],
		possibilites: ['ulv', 'er', 'ul', 've'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session45 = [
	{
		total: 8,
		id: 1,
		word: ['U', 't', 'm', 'e', 'r', 'k', 'e', 't'],
		possibilites: ['utmerke', 'ut', 'merke', 'merk', 'mer', 'et', 'merket', 'er'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 13,
		id: 2,
		word: ['H', 'u', 'l', 'e', 'n', 'e'],
		possibilites: ['hulen', 'hule', 'hul', 'le', 'en', 'ne', 'lene', 'ul', 'ule', 'hu', 'ulene', 'len', 'ene'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session46 = [
	{
		total: 7,
		id: 1,
		word: ['B', 'r', 'u', 'k', 'e', 's'],
		possibilites: ['bruke', 'bruk', 'bru', 'uke', 'ruke', 'ru', 'es'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 6,
		id: 2,
		word: ['B', 'e', 't', 'y', 'r'],
		possibilites: ['bety', 'bet', 'be', 'et', 'tyr', 'yr'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
export const session47 = [
	{
		total: 3,
		id: 1,
		word: ['S', 'k', 'o', 'l', 'e'],
		possibilites: ['sko', 'ole', 'le'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 2,
		id: 2,
		word: ['U', 'r', 'f', 'o', 'l', 'k'],
		possibilites: ['ur', 'folk'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session48 = [
	{
		total: 8,
		id: 1,
		word: ['K', 'u', 'l', 't', 'u', 'r'],
		possibilites: ['kul', 'ul', 'tur', 'ur', 'ku', 'kult', 'ult', 'tu'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 6,
		id: 2,
		word: ['S', 'a', 'm', 'e', 'n'],
		possibilites: ['same', 'sam', 'sa', 'amen', 'men', 'en'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session49 = [
	{
		total: 4,
		id: 1,
		word: ['K', 'o', 'f', 't', 'e', 'r'],
		possibilites: ['kofte', 'ofte', 'te', 'er'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 4,
		id: 2,
		word: ['L', 'a', 'g', 'e', 't'],
		possibilites: ['lage', 'lag', 'la', 'et'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session50 = [
	{
		total: 6,
		id: 1,
		word: ['B', 'e', 't', 'y', 'r'],
		possibilites: ['be', 'bet', 'bety', 'tyr', 'yr', 'et'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 7,
		id: 2,
		word: ['F', 'i', 'n', 'n', 'e'],
		possibilites: ['finn', 'fin', 'inne', 'inn', 'ne', 'i', 'in'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];

export const session51 = [
	{
		total: 6,
		id: 1,
		word: ['J', 'a', 'k', 't', 'e'],
		possibilites: ['jakt', 'ja', 'akte', 'akt', 'ak', 'te'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
	{
		total: 4,
		id: 2,
		word: ['L', 'a', 'g', 'e', 't'],
		possibilites: ['lage', 'lag', 'la', 'et'],
		defaultClass: 'rw-fw-input',
		incorrectClass: 'rw-fw-input-shake',
		answers: [],
		unAnswered: [],
	},
];
