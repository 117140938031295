import React from 'react';
import './styles.scss';
import Logo from '../../assets/readwell_logo_tekst.png';
import Introduction from './Introduksjon';
import DashBoard from '../../components/DashBoard';
import ReadingComprehension from '../../components/Session/ReadinComprehension';
import getUserInfo from '../../api/getUserInfo';
import updateCandidateInfo from '../../api/updateCandidateInfo';
import SessionIndicator from '../../components/SessionIndicator/index';
import { begin } from '../../utils/beforeunload';
import { goToNextStep, setCandidateSession } from '../../actions';
import { connect } from 'react-redux';
import { isInProduction } from '../../constants/index';
class ReadWellTest extends React.Component {
	constructor() {
		super();
		this.state = {
			isLoggedIn: false,
			candidateInfo: false,
			isSessionSelected: false,
			renderSession: false,
			selectedSession: null,
			completedSessions: [],
			isSuperTeacher: false,
		};
	}
	componentDidMount() {
		getUserInfo().then((response) => {
			const authData = response.json();
			const allCompletedSessions = [];
			let isSuper = false;
			if (response.ok) {
				authData.then((data) => {
					if (data.completedSessions.length) {
						for (let i = 0; i < data.completedSessions.length; i++) {
							allCompletedSessions.push(
								data.completedSessions[i].session
							);
						}
					}

					isSuper = !isInProduction && data.id === 2;
					if (data.info) {
						this.setState({
							isLoggedIn: true,
							candidateInfo: true,
							completedSessions: allCompletedSessions,
							isSuperTeacher: isSuper,
						});
					} else {
						this.setState({
							isLoggedIn: true,
							candidateInfo: false,
							isSuperTeacher: isSuper,
						});
					}
				});
			} else {
				localStorage.removeItem('authtoken-readwell');
				window.location.href = `/`;
			}
		});
	}
	renderSelectedSession(session) {
		this.setState({
			selectedSession: session,
			isSessionSelected: true,
		});
	}
	sendCandidateInfo(info) {
		this.setState({
			candidateInfo: true,
		});
		updateCandidateInfo(info).then((response) => {});
	}
	render() {
		const { onSetCandidateSession } = this.props;
		const { candidateInfo, isSessionSelected, selectedSession } = this.state;
		return (
			<div>
				<header className='rw-header'>
					<div className='rw-logo'>
						<img className='rw-logo__img' src={Logo} alt='Readwell Logo' />
						<div className='rw-header-info'>
							{isSessionSelected && selectedSession && (
								<SessionIndicator session={selectedSession} />
							)}
						</div>
					</div>
				</header>
				{!candidateInfo && this.state.isLoggedIn && (
					<div className='rw-pageWrapper_component'>
						<Introduction
							onSendInfo={(info) => {
								this.sendCandidateInfo(info);
							}}
						/>
					</div>
				)}
				{!isSessionSelected && this.state.isLoggedIn && (
					<div className='rw-component-container'>
						<DashBoard
							completedSessions={this.state.completedSessions}
							isSuperTeacher={this.state.isSuperTeacher}
							onSelectingSessions={(session) => {
								this.setState({
									selectedSession: session,
								});
								onSetCandidateSession(this.state.selectedSession);

								this.renderSelectedSession(session);
							}}
						/>
					</div>
				)}
				{isSessionSelected && selectedSession && (
					<div className='rw-component-container'>
						<ReadingComprehension
							selectedSession={selectedSession}
							isSuperTeacher={this.state.isSuperTeacher}
						/>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { candidateSession } = state;
	return { candidateSession };
};
const mapDispatchToProps = {
	onSetCandidateSession: setCandidateSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadWellTest);
