import React from 'react';
import { connect } from 'react-redux';
import * as data from './data/no-data.js';
import TimerCount from '../../../TimerCount';
import { SUBTEST_IDS, ALL_SUBTESTS, isInProduction } from '../../../../constants/index';
import { updateObject } from '../../../../utils/reducerUtils';
import introAudio from '../../../../assets/sounds/fill-blanks-intro.m4a';
import AudioPlayer from '../../../AudioPlayer';
import './styles.scss';

const getSessionItems = (session) => {
	const indexValue = `session${session}`;
	const sessionItems = data[indexValue];
	return sessionItems;
};
let currentId;
let currentCounter = 0;
const resultsGloabl = [];
class FillinTheBlancks extends React.Component {
	constructor(props) {
		super(props);
		this.audio = introAudio;
		this.sessionData = getSessionItems(this.props.selectedSession);
		const results = {};
		for (let i = 0; i < this.sessionData.length; i++) {
			for (let j = 0; j < this.sessionData[i].matchingImages.length; j++) {
				if (this.sessionData[i].matchingImages[j].words) {
					let questions = this.sessionData[i].matchingImages[j].words;
					for (let k = 0; k < questions.length; k++) {
						let id = questions[k].id;
						results[id] = '';
					}
				}
			}
		}

		this.state = {
			sessionItems: this.sessionData,
			timeRanOut: false,
			results,
		};
	}
	handleWrongAnswer(mainIndex, index, wordIndex, currentId, matchingImages, wordInnerIndex) {
		this.props.recordMistake();
		const id = wordInnerIndex.id;
		this.updateResults(id, false);
		this.sessionData[mainIndex].matchingImages[index].words[wordIndex].incorrectClassName = 'rw-fb-word-wrong';

		this.setState({
			sessionItems: this.sessionData,
		});

		setTimeout(() => {
			this.sessionData[mainIndex].matchingImages[index].words[wordIndex].incorrectClassName = null;

			this.setState({
				sessionItems: this.sessionData,
			});
		}, 2000);
	}
	updateResults(id, status) {
		resultsGloabl.push({ id: id, status: { isCorrect: status } });
	}
	updateResultsCount(wordIndex) {
		const { results } = this.state;
		const value = { isAnswerd: true };
		const id = wordIndex.id;
		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
		this.updateResults(id, true);
	}
	render() {
		const { onNextStep } = this.props;
		const { sessionItems, results } = this.state;
		const allQuestionsAnswered = Object.keys(results).every((key) => results[key]);
		const isNextButtonVisible = allQuestionsAnswered;
		let audioPlayerRef;

		return (
			<div>
				<div className='rw-fb-intro'>
					<div className='rw-infobar'>
						<TimerCount
							duration={130}
							onComplete={() => {
								this.setState({
									timeRanOut: true,
								});
							}}
						/>
					</div>
					<div className='rw-intro-text'>
						<p>
							<AudioPlayer
								autoPlay
								inline
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								src={this.audio}
							/>
							Trykk på bildet og riktig ord.
						</p>
					</div>
					<br></br>
					<div className='rw-fb'>
						{sessionItems.map((item, mainIndex) => {
							const { matchingImages } = item;

							return (
								<div
									className='rw-fb-content'
									key={`rw-fb-content-${mainIndex}`}
								>
									{matchingImages.map((image, index) => {
										const {
											id,
											words,
											img,
											question,

											defaultClassName,
											currentClassName,
										} = image;

										return (
											<div
												key={`rw-fb-content-inner-${index}`}
											>
												<div
													className='rw-fb-content__question'
													key={`rw-fb-content__question-${mainIndex}`}
												>
													{img && (
														<span
															className='rw-fb-img'
															key={`rw-fb-img-${id}`}
															onClick={() => {
																if (
																	!this
																		.state
																		.activeItem &&
																	!matchingImages[
																		index
																	]
																		.isAnswered
																) {
																	const target =
																		id ===
																		0
																			? index +
																			  1
																			: index -
																			  1;

																	currentId =
																		index;
																	matchingImages[
																		index
																	].currentClassName =
																		matchingImages[
																			index
																		].selectClassName;
																	matchingImages[
																		index
																	].disable = false;
																	let newWords =
																		matchingImages[
																			target
																		]
																			.words;

																	for (
																		let i = 0;
																		i <
																		newWords.length;
																		i++
																	) {
																		newWords[
																			i
																		].disable = false;
																	}
																	matchingImages[
																		target
																	].words =
																		newWords;
																	this.setState(
																		{
																			sessionItems: this
																				.sessionData,
																			activeItem: true,
																		}
																	);
																}
															}}
															onMouseEnter={() => {
																if (
																	!this
																		.state
																		.activeItem &&
																	!matchingImages[
																		index
																	]
																		.isAnswered
																) {
																	matchingImages[
																		index
																	].currentClassName =
																		matchingImages[
																			index
																		].hoverClassName;
																	this.setState(
																		{
																			sessionItems: this
																				.sessionData,
																		}
																	);
																}
															}}
															onMouseLeave={() => {
																if (
																	matchingImages[
																		index
																	]
																		.disable &&
																	!matchingImages[
																		index
																	]
																		.isAnswered
																) {
																	matchingImages[
																		index
																	].currentClassName =
																		matchingImages[
																			index
																		].defaultClassName;
																} else if (
																	matchingImages[
																		index
																	]
																		.disable &&
																	matchingImages[
																		index
																	]
																		.isAnswered
																) {
																	matchingImages[
																		index
																	].currentClassName =
																		matchingImages[
																			index
																		].correctClassName;
																} else {
																	matchingImages[
																		index
																	].currentClassName =
																		matchingImages[
																			index
																		].selectClassName;
																}

																this.setState(
																	{
																		sessionItems: this
																			.sessionData,
																	}
																);
															}}
														>
															<img
																className={
																	currentClassName
																}
																key={`rw-fb-img__small-${index}`}
																src={
																	img
																}
															/>
														</span>
													)}
													{words &&
														words.map(
															(
																wordItem,
																wordIndex
															) => {
																const {
																	word,
																	disable,
																	isAnswered,
																	incorrectClassName,
																} =
																	wordItem;
																return (
																	<div className='rw-fb-words-container'>
																		<div
																			className={
																				isAnswered
																					? 'rw-fb-word-correct'
																					: disable
																					? 'rw-fb-word-disabled'
																					: incorrectClassName
																					? 'rw-fb-word-wrong'
																					: 'rw-fb-word'
																			}
																			key={`rw-fb-word-${wordIndex}`}
																			onClick={() => {
																				if (
																					!disable &&
																					!isAnswered
																				) {
																					if (
																						matchingImages[
																							currentId
																						].correctAnswer.toLowerCase() ===
																							word.toLowerCase() &&
																						this
																							.state
																							.activeItem
																					) {
																						this.props.flipCoin();
																						let newWords =
																							matchingImages[1]
																								.words;

																						newWords[
																							wordIndex
																						].isAnswered = true;
																						newWords[
																							wordIndex
																						].disable = true;
																						matchingImages[1].words =
																							newWords;
																						matchingImages[
																							currentId
																						].isAnswered = true;
																						matchingImages[
																							currentId
																						].disable = true;
																						let newQuestion =
																							matchingImages[
																								currentId
																							]
																								.question;
																						let questionResult =
																							newQuestion.replace(
																								/_ _ _/g,
																								matchingImages[
																									currentId
																								]
																									.correctAnswer
																							);
																						matchingImages[
																							currentId
																						].question =
																							questionResult;
																						matchingImages[
																							currentId
																						].currentClassName =
																							matchingImages[
																								currentId
																							].correctClassName;
																						this.setState(
																							{
																								sessionItems: this
																									.sessionData,
																								activeItem: false,
																							}
																						);
																						this.updateResultsCount(
																							newWords[
																								wordIndex
																							]
																						);
																					} else {
																						let newWords =
																							matchingImages[1]
																								.words;
																						this.handleWrongAnswer(
																							mainIndex,
																							index,
																							wordIndex,
																							currentId,
																							matchingImages,
																							newWords[
																								wordIndex
																							]
																						);
																					}
																				}
																			}}
																		>
																			<span>
																				{
																					word
																				}
																			</span>
																		</div>
																	</div>
																);
															}
														)}
													<div
														className={
															matchingImages[
																index
															]
																.isAnswered
																? 'rw-fb-desc-answered'
																: 'rw-fb-desc'
														}
													>
														<p>
															{
																question
															}
														</p>
													</div>
												</div>
												<div className='rw-fb-content__options'></div>
												{words && (
													<span>
														<br></br>
														<div className='sp-scroller-roof'></div>
													</span>
												)}
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				</div>

				{(this.state.timeRanOut || isNextButtonVisible || this.props.isSuperTeacher) && (
					<div className='rw-next-btn'>
						<button
							className='rw-btn bg-primary'
							onClick={() =>
								onNextStep(
									resultsGloabl,
									ALL_SUBTESTS.FILLINTHEBLANCKS
								)
							}
						>
							{'Neste'}
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default FillinTheBlancks;
